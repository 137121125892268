import React from "react";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { editorContextStore } from "contexts/editor-context";
import { classNames } from "@/core/utils/classname-utils";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { LeftPanelImageGridItem } from "./image-grid-item";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { PlusIcon } from "@radix-ui/react-icons";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { LEFT_PANEL_WIDTH } from "components/constants/left-panels";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";

export type HorizontalImageGridImageItem = {
  imgPathType?: string;
  imgPath?: string;
  alt?: string;
  id?: string;
  prompt?: string;
};

function HorizontalImageGridItem({
  item,
  className,
  onClick,
  getHoverCardContent,
  draggable,
  onDragEnd,
}: {
  item: HorizontalImageGridImageItem;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  getHoverCardContent?: (item: HorizontalImageGridImageItem) => React.ReactNode;
  draggable?: boolean;
  onDragEnd?: React.DragEventHandler<HTMLDivElement>;
}) {
  const editor = editorContextStore((state) => state.editor);
  const backend = editorContextStore((state) => state.backend);
  const [imgSrc, setImgSrc] = React.useState("");
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    if (!item.imgPath) {
      return;
    }
    if (item.imgPathType === "image-id") {
      const imageObject = editor?.objects.findOneById(item.imgPath);
      if (isStaticImageObject(imageObject)) {
        setImgSrc(imageObject.getSrc());
      }
    } else if (item.imgPathType === "image-storage") {
      editor?.assets
        .loadAsset({
          path: item.imgPath,
          type: "image-storage",
        })
        .then((src) => {
          if (!src) {
            setIsError(true);
          }
          setImgSrc(src ?? "");
        });
    } else {
      setImgSrc(item.imgPath ?? "");
    }
  }, [editor, backend, item]);

  if (isError) {
    return null;
  }

  return (
    <LeftPanelImageGridItem
      id={item.id}
      ratio={1}
      delayDuration={0}
      imgSrc={imgSrc}
      imgAlt={item.alt}
      imageProps={{
        draggable: false,
      }}
      className={className}
      imgClassName="max-h-full max-w-full"
      onClick={onClick}
      hoverCardContent={getHoverCardContent?.(item)}
      draggable={false}
    />
  );
}

const chevronContainerClassName =
  "absolute top-0 px-1.5 h-full flex items-center justify-center text-zinc-100 from-zinc-900 to-transparent cursor-pointer";

export function HorizontalImageGrid({
  items,
  onSelectItem,
  onClickViewAll,
  getHoverCardContent,
  numItemsLimit = 4,
}: {
  items: Record<string, HorizontalImageGridImageItem>;
  onSelectItem?: (item: HorizontalImageGridImageItem, event?: Event) => void;
  onClickViewAll?: () => void;
  getHoverCardContent?: (item: HorizontalImageGridImageItem) => React.ReactNode;
  numItemsLimit?: number;
}) {
  const scrollAreaContainerRef = React.useRef<HTMLDivElement | null>(null);
  const scrollAreaViewportRef = React.useRef<HTMLDivElement | null>(null);
  const leftArrowRef = React.useRef<HTMLDivElement | null>(null);
  const rightArrowRef = React.useRef<HTMLDivElement | null>(null);
  const isDraggingRef = React.useRef(false);
  const dragStartRef = React.useRef(0);
  const dragStartScrollLeftRef = React.useRef(0);
  const dragDistanceRef = React.useRef(0);

  const updateArrows = React.useCallback(() => {
    if (!scrollAreaViewportRef.current) {
      return;
    }
    const scrollLeft = scrollAreaViewportRef.current.scrollLeft ?? 0;
    if (leftArrowRef.current) {
      leftArrowRef.current.style.display = scrollLeft > 0 ? "flex" : "none";
    }
    if (rightArrowRef.current) {
      const width = scrollAreaViewportRef.current.getBoundingClientRect().width;
      rightArrowRef.current.style.display = scrollLeft < width ? "flex" : "none";
    }
  }, []);

  return (
    <ScrollAreaContainer
      ref={scrollAreaContainerRef}
      viewportRef={scrollAreaViewportRef}
      orientation="horizontal"
      hideScrollbar
      className={classNames("relative w-full")}
      onScroll={() => {
        updateArrows();
      }}
      onPointerOver={() => {
        updateArrows();
      }}
      onPointerLeave={() => {
        isDraggingRef.current = false;
      }}
      onPointerDown={(e) => {
        if (!scrollAreaViewportRef.current) {
          return;
        }
        isDraggingRef.current = true;
        dragStartRef.current = e.clientX;
        dragStartScrollLeftRef.current = scrollAreaViewportRef.current.scrollLeft;
        dragDistanceRef.current = 0;
      }}
      onPointerUp={(e) => {
        isDraggingRef.current = false;
      }}
      onPointerMove={(e) => {
        if (!isDraggingRef.current || !scrollAreaViewportRef.current) {
          return;
        }
        const x = e.clientX;
        const dx = x - dragStartRef.current;
        scrollAreaViewportRef.current.scrollLeft = Math.max(0, dragStartScrollLeftRef.current - dx);
        dragDistanceRef.current = dx;
      }}
    >
      <div
        className="w-[200%] grid gap-2"
        style={{
          gridTemplateColumns: `repeat(${numItemsLimit + 1}, minmax(0, 1fr))`,
        }}
      >
        {Object.entries(items)
          .slice(0, numItemsLimit)
          .map(([id, item]) => (
            <HorizontalImageGridItem
              key={id}
              item={item}
              onClick={() => {
                if (Math.abs(dragDistanceRef.current) > 10) {
                  return;
                }
                onSelectItem?.(item);
              }}
              getHoverCardContent={getHoverCardContent}
              draggable
              onDragEnd={(event) => {
                if (Math.abs(dragDistanceRef.current) > 10) {
                  return;
                }
                onSelectItem?.(item, event.nativeEvent);
              }}
            />
          ))}
        <AspectRatio.Root
          ratio={1}
          className={classNames(
            SecondaryButtonClassNameInactive,
            "flex flex-col items-center justify-center",
          )}
          onClick={onClickViewAll}
        >
          <PlusIcon width={18} height={18} />
          <div className="font-semibold">View All</div>
        </AspectRatio.Root>
      </div>
      <div
        ref={leftArrowRef}
        className={classNames(chevronContainerClassName, "flex left-0 bg-gradient-to-r")}
        style={{
          display: "none",
        }}
        onClick={() => {
          scrollAreaViewportRef.current?.scrollTo({
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        <ChevronLeft size={20} />
      </div>
      <div
        ref={rightArrowRef}
        className={classNames(chevronContainerClassName, "right-0 bg-gradient-to-l")}
        onClick={() => {
          scrollAreaViewportRef.current?.scrollBy({
            left: LEFT_PANEL_WIDTH,
            behavior: "smooth",
          });
        }}
      >
        <ChevronRight size={20} />
      </div>
    </ScrollAreaContainer>
  );
}
