export function debugLog(message?: any, ...optionalParams: any[]) {
  if (process.env.NODE_ENV === "development") {
    console.log(message, ...optionalParams);
  }
}

export function debugError(message?: any, ...optionalParams: any[]) {
  if (process.env.NODE_ENV === "development") {
    console.error(message, ...optionalParams);
  }
}

export function debugWarn(message?: any, ...optionalParams: any[]) {
  if (process.env.NODE_ENV === "development") {
    console.warn(message, ...optionalParams);
  }
}
