import React from "react";
import { classNames } from "@/core/utils/classname-utils";
import { AppUserSubscriptionTierV2 } from "@/core/common/types";
import { MessageDialogClassName } from "components/constants/class-names";
import { MessageDialog, MessageDialogTitle } from "./message-dialog";
import { AppSubscriptionPlanType } from "@/core/common/types/subscription";
import { ManagePlanPage, ManageSubscriptionDialogContent } from "./manage-subscription-dialog";
import {
  useUserHighestStripeSubscription,
  useUserRecommendedSubscriptionPlans,
} from "hooks/use-user-stripe-subscription-update";

export const subscriptionTierV2ToRecommendedPlans: Record<
  AppUserSubscriptionTierV2,
  AppSubscriptionPlanType[]
> = {
  [AppUserSubscriptionTierV2.Free]: [
    AppSubscriptionPlanType.Pro,
    AppSubscriptionPlanType.ProPlus,
    AppSubscriptionPlanType.Enterprise,
  ],
  [AppUserSubscriptionTierV2.Pro]: [
    AppSubscriptionPlanType.ProPlus,
    AppSubscriptionPlanType.Enterprise,
  ],
  [AppUserSubscriptionTierV2.ProPlus]: [AppSubscriptionPlanType.Enterprise],
};

export interface QuotaSubscribeDialogContentProps {
  title?: string;
  header?: string;
}

export const QuotaSubscribeDialogContent = React.forwardRef(function QuotaSubscribeDialogContent(
  { title = "No quota left.", header }: QuotaSubscribeDialogContentProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const userSubscriptionPlan = useUserHighestStripeSubscription();
  const recommendedPlans = useUserRecommendedSubscriptionPlans();

  const managePlanPage = React.useMemo(
    () =>
      userSubscriptionPlan === AppSubscriptionPlanType.Scale ||
      userSubscriptionPlan === AppSubscriptionPlanType.Enterprise
        ? ManagePlanPage.OneTimePayment
        : ManagePlanPage.Default,
    [userSubscriptionPlan],
  );

  return (
    <ManageSubscriptionDialogContent
      ref={forwardedRef}
      className={classNames(MessageDialogClassName, "w-[90vw] xl:w-[1020px]")}
      tabsListProps={{
        className: "hidden",
      }}
      managePlanPage={managePlanPage}
      subscriptionPlans={recommendedPlans}
    >
      <MessageDialogTitle className="w-full text-zinc-500">{title}</MessageDialogTitle>
      <div className="w-full flex flex-col items-center gap-8 text-2xl my-6">{header}</div>
    </ManageSubscriptionDialogContent>
  );
});

export interface QuotaSubscribeDialogProps extends QuotaSubscribeDialogContentProps {
  onClose?: () => void;
}

export function QuotaSubscribeDialog({ onClose, ...props }: QuotaSubscribeDialogProps) {
  return (
    <MessageDialog
      defaultOpen={true}
      onOpenChange={(value) => {
        if (!value) {
          onClose?.();
        }
      }}
      contentChildren={<QuotaSubscribeDialogContent {...props} />}
    />
  );
}
