import { AnalyticsConfig } from "@/analytics/config";
import { emptyProject } from "@/components/constants/sample-projects";
import EditorLoading from "@/components/editor/editor-loading";
import { UserProjectType } from "@/core/common/types";
import { isUserProjectQuotaValid } from "@/core/utils/quota-utils";
import { generateUUID } from "@/core/utils/uuid-utils";
import { editorContextStore } from "contexts/editor-context";
import { useProjectInitSceneContext } from "contexts/project-init-scene-context";
import React from "react";
import { useNavigate } from "react-router-dom";
import { backendCreateNewProject } from "./create-new-project-utils";

function getTemporaryProjectId() {
  return `tmp_${generateUUID()}`;
}

export function isTemporaryProjectId(id: string) {
  return id?.slice?.(0, 4) === "tmp_";
}

export function CreateNewTryOnProject() {
  const user = editorContextStore((state) => state.user);
  const backend = editorContextStore((state) => state.backend);
  const projectId = editorContextStore((state) => state.projectId);
  const displayNameRef = React.useRef<string>();
  const isProjectCreatedRef = React.useRef(false);
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("Loading ...");
  const { setInitScene } = useProjectInitSceneContext();
  const setProjectDisplayName = editorContextStore((state) => state.setProjectDisplayName);

  const handleCreateNewProject = React.useCallback(
    (displayName: string) => {
      if (projectId != null && !isTemporaryProjectId(projectId)) {
        console.log(`Project ${projectId} is already created, no need to create again`);
        return null;
      }
      if (isProjectCreatedRef.current || displayNameRef.current === displayName) {
        console.log(
          `Project ${projectId} with name ${displayName} is already created, no need to create again`,
        );
        return null;
      }
      displayNameRef.current = displayName;
      isProjectCreatedRef.current = true;

      editorContextStore.getState().analytics.track(AnalyticsConfig.ProjectCreate, {
        projectId,
        name: displayName,
        reason: "Page Open",
      });

      return backendCreateNewProject({
        displayName,
        projectType: UserProjectType.TryOn,
      });
    },
    [projectId],
  );

  React.useEffect(() => {
    if (user?.uid && isProjectCreatedRef.current === false) {
      const displayName = "Untitled Fashion Project";
      console.log(`Create new project ${displayName}`);

      handleCreateNewProject(displayName)?.then((res) => {
        if ((res as any)?.data?.code === 400) {
          // User not found on the server, try again later
          console.log("User id is invalid, cannot create project.");
          setMessage("Cannot find your projects. Signing you up ...");
          console.warn((res as any).data);

          editorContextStore.getState().analytics.track(AnalyticsConfig.ProjectCreateError, {
            name: displayName,
            data: (res as any).data,
            error: "User id is invalid, cannot create project.",
          });

          backend?.onUserQuotasUpdate({
            userId: user.uid,
            onUpdate: (quotas) => {
              if (isUserProjectQuotaValid(quotas)) {
                console.log("User registered in the database, try create the project again");

                editorContextStore.getState().analytics.track(AnalyticsConfig.ProjectCreate, {
                  name: displayName,
                  quotas,
                  reason: "Quota Update",
                });

                handleCreateNewProject(displayName);
              }
            },
          });
        }
      });

      setMessage("Loading initial scene ...");

      const sampleProjectScene = emptyProject;

      if (sampleProjectScene) {
        console.log("Sample project scene is valid");
      } else {
        console.log("Sample project scene is invalid");
      }

      setInitScene(sampleProjectScene ?? undefined);
      const randomProjectId = getTemporaryProjectId();
      // Redirect to the temporary project
      editorContextStore.getState().setProjectType(UserProjectType.TryOn);
      navigate?.(`/projects/${randomProjectId}`, {
        replace: true,
        state: {
          isTempProject: true,
          displayName: displayNameRef.current,
          projectType: UserProjectType.TryOn,
        },
      });

      setProjectDisplayName(displayNameRef.current);
    } else if (isProjectCreatedRef.current) {
      console.log("Project is already created.");
    }
  }, [user, backend, navigate, handleCreateNewProject, setInitScene, setProjectDisplayName]);

  return <EditorLoading message={message} />;
}
