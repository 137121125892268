import type { StateUpdater } from "@/core/common/types";
import type { IEditorContext } from "./editor-context";

export type SetEditorStateFunction = (state: StateUpdater<Partial<IEditorContext>>) => void;

export function getUpdaterFunction<K extends keyof IEditorContext>(
  set: SetEditorStateFunction,
  key: K,
) {
  return (updater: StateUpdater<IEditorContext[K]>) =>
    typeof updater === "function"
      ? set((state) => ({
          [key]: (updater as (value: IEditorContext[K]) => IEditorContext[K])((state as any)[key]),
        }))
      : set({ [key]: updater });
}

export function getUpdaterFunctionWithCleanup<K extends keyof IEditorContext>(
  set: SetEditorStateFunction,
  key: K,
  cleanup: (object: IEditorContext[K]) => void,
) {
  return (updater: StateUpdater<IEditorContext[K]>) => {
    set((state) => {
      const prevValue = state[key] as IEditorContext[K];

      cleanup(prevValue);

      return typeof updater === "function"
        ? { [key]: (updater as (value: IEditorContext[K]) => IEditorContext[K])(prevValue) }
        : { [key]: updater };
    });
  };
}

export function getUpdaterFunctionWithUpdateCheck<K extends keyof IEditorContext>(
  set: SetEditorStateFunction,
  key: K,
) {
  return (updater: StateUpdater<IEditorContext[K]>) => {
    set((state) => {
      const prevValue = state[key] as IEditorContext[K];

      const editor = state.editor;
      if (editor) {
        const canUpdate = editor.canUpdateState(key, updater);
        if (!canUpdate) {
          return state;
        }
      }

      return typeof updater === "function"
        ? { [key]: (updater as (value: IEditorContext[K]) => IEditorContext[K])(prevValue) }
        : { [key]: updater };
    });
  };
}

export function cleanupHtmlElementCallback(object?: HTMLElement) {
  return object?.remove();
}
