import { UserAssetInfo, UserAssetInfoType, isUserAssetInfo } from "@/core/common/types";
import {
  DocumentData,
  DocumentSnapshot,
  Query,
  QueryDocumentSnapshot,
  getDocs,
} from "firebase/firestore";

export type GetUserAssetInfoQueryCallback = (
  uid: string,
  assetType: UserAssetInfoType,
  limitSize?: number,
  lastVisible?: DocumentSnapshot<unknown>,
) => Query<DocumentData>;

export function getAssetInfoFromFirestoreDoc(doc: QueryDocumentSnapshot<DocumentData>) {
  const data = doc.data() || {};
  return {
    ...data,
    id: doc.id,
  };
}

function isValidUserAssetInfo(data: any): data is UserAssetInfo {
  return isUserAssetInfo(data) && data.isDeleted !== true;
}

export class FirebaseUserAssetInfoGenerator {
  batchSize: number;

  private isFinished = false;

  private lastVisible: DocumentSnapshot<unknown> | undefined = undefined;

  private uid: string;

  private assetType: UserAssetInfoType;

  private getUserAssetInfoQuery: GetUserAssetInfoQueryCallback;

  constructor({
    uid,
    batchSize,
    assetType,
    getUserAssetInfoQuery,
  }: {
    uid: string;
    batchSize: number;
    assetType: UserAssetInfoType;
    getUserAssetInfoQuery: GetUserAssetInfoQueryCallback;
  }) {
    this.uid = uid;
    this.batchSize = batchSize;
    this.assetType = assetType;
    this.getUserAssetInfoQuery = getUserAssetInfoQuery;
  }

  async getNextBatch() {
    if (this.isFinished) {
      return [];
    }

    const docSnapshots = await getDocs(
      this.getUserAssetInfoQuery(this.uid, this.assetType, this.batchSize, this.lastVisible),
    );

    this.lastVisible = docSnapshots.docs[docSnapshots.docs.length - 1];

    if (docSnapshots.empty) {
      this.isFinished = true;
    }

    return docSnapshots.docs.map(getAssetInfoFromFirestoreDoc).filter(isValidUserAssetInfo);
  }
}
