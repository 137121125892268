import { OrbitControls } from "@/core/3d/controls/OrbitControls";
import {
  Scene3dData,
  isCamera3dJson,
  isPerspectiveCamera,
  isScene3dJson,
} from "@/core/common/types/3d";
// import { ObjectLoader } from '@/core/3d/loaders/ObjectLoader';
import {
  Camera,
  ObjectLoader,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  WebGLRendererParameters,
} from "three";

export class Scene3dController {
  private container: HTMLDivElement;

  private scene?: Scene;

  private _isInitialized = false;

  private _isRendering = false;

  private renderer?: WebGLRenderer;

  private camera?: Camera;

  private orbitControls?: OrbitControls;

  constructor({ container }: { container: HTMLDivElement }) {
    this.container = container;
  }

  destroy() {
    if (!this.container) {
      return;
    }

    if (!this.renderer) {
      return;
    }

    const domElement = this.renderer.domElement;

    if (domElement) {
      this.container.removeChild(domElement);
    }

    this.scene?.clear();
    this.camera?.clear();
    this.removeOrbitControlsEventListeners();

    this.scene = undefined;
    this.camera = undefined;
    this.renderer = undefined;
    this.orbitControls = undefined;
    this._isRendering = false;
    this._isInitialized = false;
  }

  private render = () => {
    if (!this._isRendering || !this.renderer || !this.scene || !this.camera) {
      return;
    }

    requestAnimationFrame(this.render);

    if (this.orbitControls?.enabled) {
      this.orbitControls.update();
    }

    this.renderer.render(this.scene, this.camera);
  };

  startRenderLoop() {
    this.render();
  }

  set isRendering(value: boolean) {
    if (value === this.isRendering) {
      return;
    }
    this._isRendering = value;
    if (value) {
      this.startRenderLoop();
    }
  }

  private hanldeOrbitControlsStart = () => {
    this.isRendering = true;
  };

  private handleOrbitControlsEnd = () => {
    this.isRendering = false;

    if (this.orbitControls?.enabled) {
      this.orbitControls.update();
    }

    if (this.renderer && this.scene && this.camera) {
      this.renderer.render(this.scene, this.camera);
    }
  };

  private addOrbitControlsEventListeners() {
    if (!this.orbitControls) {
      return;
    }
    this.orbitControls.addEventListener("start", this.hanldeOrbitControlsStart);
    this.orbitControls.addEventListener("end", this.handleOrbitControlsEnd);
  }

  private removeOrbitControlsEventListeners() {
    if (!this.orbitControls) {
      return;
    }
    this.orbitControls.removeEventListener("start", this.hanldeOrbitControlsStart);
    this.orbitControls.removeEventListener("end", this.handleOrbitControlsEnd);
  }

  initScene({
    sceneJson,
    cameraJson,
    rendererParams = {},
  }: Scene3dData & {
    rendererParams?: WebGLRendererParameters;
  }) {
    try {
      if (this._isInitialized) {
        return;
      }

      if (!isScene3dJson(sceneJson) || !isCamera3dJson(cameraJson)) {
        return;
      }

      const rect = this.container.getBoundingClientRect();

      if (!rect) {
        return;
      }

      const objectLoader = new ObjectLoader(undefined);

      this.scene = objectLoader.parse(sceneJson);

      if (!this.scene) {
        return;
      }

      this.camera = objectLoader.parse(cameraJson);

      if (!this.camera) {
        return;
      }

      const { width, height } = rect;

      this.renderer = new WebGLRenderer({
        ...rendererParams,
        antialias: true,
        alpha: true,
      });

      this.renderer.shadowMap.enabled = true;
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setClearColor(0x000, 0);
      this.renderer.setSize(width, height);

      this.orbitControls = new OrbitControls(
        this.camera as PerspectiveCamera,
        this.renderer.domElement,
      );
      this.orbitControls.enableDamping = false;
      // this.orbitControls.enableZoom = false;

      this.addOrbitControlsEventListeners();

      this.container.appendChild(this.renderer.domElement);

      this.renderer.render(this.scene, this.camera);

      this.startRenderLoop();

      this._isInitialized = true;
    } catch (error) {
      console.error(error);

      this.destroy();
    }
  }

  saveScene(): Scene3dData | undefined {
    if (!this.scene || !this.camera || !this.renderer || !this._isInitialized) {
      return;
    }
    return {
      sceneJson: this.scene.toJSON(),
      cameraJson: this.camera.toJSON(),
    };
  }

  toDataURL({ width, height }: { width: number; height: number }) {
    if (!this.scene || !this.camera || !this.renderer || !this._isInitialized) {
      return;
    }
    if (width && height) {
      // Resize the canvas first
      this.renderer.domElement.width = width;
      this.renderer.domElement.height = height;

      if (isPerspectiveCamera(this.camera.type)) {
        (this.camera as PerspectiveCamera).aspect = width / height;
        (this.camera as PerspectiveCamera).updateProjectionMatrix();
      }

      this.renderer.setSize(width, height);

      this.renderer.render(this.scene, this.camera);
    }
    // Update camera
    return this.renderer.domElement.toDataURL();
  }
}
