import React from "react";
import { fabric } from "fabric";
import { editorContextStore } from "contexts/editor-context";
import { OUTPAINT_CANVAS_ID } from "components/constants/ids";

import { removeLastFromImmutableList } from "@/core/utils/array-utils";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
} from "components/constants/class-names";
import { EditorCanvasModalContainer } from "../editor-modal/editor-canvas-modal";
import { OutpaintEditor, OutpaintInputImage } from "components/outpaint/outpaint-editor";
import { ShortcutsUtils } from "@/core/utils/shortcuts-utils";
import { RedoOutpaintImageEventHandler, UndoOutpaintImageEventHandler } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { useOutpaintContext } from "contexts/outpaint-context";
import { OutpaintStatus } from "@/core/common/types/outpaint";
import { addRenderImageResultToCanvas } from "components/utils/render";
import { Plus } from "lucide-react";
import { EditorActiveObject } from "@/core/common/interfaces";
import { debugLog } from "@/core/utils/print-utilts";

export async function addOutpaintImageToCanvas({
  sourceObject,
  outputImageUrl,
  outputWidth,
  outputHeight,
}: {
  sourceObject?: EditorActiveObject;
  outputImageUrl: string;
  outputWidth: number;
  outputHeight: number;
}) {
  const startLocation = sourceObject
    ? new fabric.Point(
        (sourceObject.left ?? 0) + sourceObject.getScaledWidth() + Math.round(outputWidth / 2),
        sourceObject.top ?? 0 + sourceObject.getScaledHeight() + Math.round(outputHeight / 2),
        // sourceObject.top ?? 0,
      )
    : undefined;

  debugLog(
    `Add outpaint image to canvas at [${startLocation?.x}, ${startLocation?.y}]; source size [${sourceObject?.getScaledWidth()}, ${sourceObject?.getScaledHeight()}]; output size: [${outputWidth}, ${outputHeight}]`,
  );

  const addedObject = await addRenderImageResultToCanvas({
    imageUrl: outputImageUrl,
    index: 0,
    startLocation,
    width: outputWidth,
    height: outputHeight,
  });

  const { editor } = editorContextStore.getState();

  if (editor && addedObject) {
    editor.objects?.select(addedObject.id);
    editor.canvas?.requestRenderAll();
  }

  return addedObject;
}

export interface OutpaintObjectDialogProps {
  image?: OutpaintInputImage;
}

function OutpaintObjectDialogInner({ image }: OutpaintObjectDialogProps) {
  const eventEmitter = editorContextStore((state) => state.eventEmitter);

  const activeObject = editorContextStore((state) => state.activeObject);

  const { status, outputImageUrls, outpaintWidth, outpaintHeight } = useOutpaintContext();

  const hasOutputImage = React.useMemo(() => outputImageUrls.length > 0, [outputImageUrls]);

  const handleExitModal = React.useCallback((addImages = false) => {
    const { setEditingObjectId, setActiveLeftPanels } = editorContextStore.getState();

    setEditingObjectId(undefined);

    setActiveLeftPanels((prevLeftPanels) => {
      return removeLastFromImmutableList(prevLeftPanels, "Outpaint");
    });
  }, []);

  return (
    <EditorCanvasModalContainer
      onExit={() => handleExitModal()}
      className="gap-2"
      onKeyDown={(e) => {
        if (ShortcutsUtils.isCtrlZ(e.nativeEvent)) {
          eventEmitter.emit<UndoOutpaintImageEventHandler>("outpaint-image:undo");
        } else if (
          ShortcutsUtils.isCtrlShiftZ(e.nativeEvent) ||
          ShortcutsUtils.isCtrlY(e.nativeEvent)
        ) {
          eventEmitter.emit<RedoOutpaintImageEventHandler>("outpaint-image:redo");
        }
      }}
    >
      <div className="flex-1 min-h-0 relative gap-2 overflow-hidden rounded">
        <OutpaintEditor canvasId={OUTPAINT_CANVAS_ID} image={image} className="w-full h-full" />
      </div>
      <div className="flex flex-row items-center">
        <div className="truncate flex-1 min-w-0 text-xs text-zinc-500" />
        <button
          className={classNames(
            status === OutpaintStatus.Idle && hasOutputImage
              ? PrimaryButtonClassName
              : status === OutpaintStatus.Rendering
                ? PrimaryButtonClassNameLoading
                : PrimaryButtonClassNameDisabled,
            "flex flex-row items-center justify-center gap-2",
          )}
          onClick={() => {
            if (status !== OutpaintStatus.Idle || !hasOutputImage) {
              return;
            }

            addOutpaintImageToCanvas({
              outputImageUrl: outputImageUrls[0],
              sourceObject: activeObject,
              outputWidth: outpaintWidth,
              outputHeight: outpaintHeight,
            });

            handleExitModal();
          }}
        >
          <Plus size={16} />
          <span className="truncate">Add Image to Canvas</span>
        </button>
      </div>
    </EditorCanvasModalContainer>
  );
}

export function OutpaintObjectDialog(props: OutpaintObjectDialogProps) {
  return <OutpaintObjectDialogInner {...props} />;
}
