import { ElementsSearchCache } from "@/backend/firebase/props-search";
import { getElementsSemanticSearchResultIDs } from "@/backend/firebase/semantic-search";
import { isProjectDashboardCarouselTemplate } from "@/components/constants/user-project-type-styles";
import { AssetLibraryItem } from "@/components/panels/panel-items/components/assets-library";
import {
  AssetItem,
  getAssetLibraryItemFromAssetItem,
} from "@/components/panels/panel-items/elements";
import { UserProjectType } from "@/core/common/types";
import { debugLog } from "@/core/utils/print-utilts";
import {
  DocumentData,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { cloneDeep } from "lodash";

export type GetTemplatesFirestoreProps = {
  limitSize?: number;
  lastVisible?: DocumentSnapshot<unknown>;
};

function isAssetItem(obj: any): obj is AssetItem {
  return (
    obj &&
    typeof obj.url === "object" &&
    typeof obj.name === "string" &&
    typeof obj.metadata === "object" &&
    Array.isArray(obj.tags)
  );
}

export class ElementsSearchManager {
  private firestore: Firestore;

  private pineconeSearchCache: Record<string, string[]> = {};

  private tagsCache: Record<string, AssetItem[]> = {};
  private firestoreElementsCache: Record<string, AssetItem> = {};
  private maxCacheSize: number;
  private elemSearchCache: ElementsSearchCache;

  constructor({ firestore, maxCacheSize = 100 }: { firestore: Firestore; maxCacheSize?: number }) {
    this.firestore = firestore;
    this.maxCacheSize = maxCacheSize;
    this.elemSearchCache = new ElementsSearchCache();
  }

  private checkPineconeCacheSize() {
    // Check cache size and remove the oldest entry if necessary
    if (Object.keys(this.pineconeSearchCache).length >= this.maxCacheSize) {
      const oldestKey = Object.keys(this.pineconeSearchCache)[0];
      delete this.pineconeSearchCache[oldestKey];
    }
  }

  getElementsSearchCache() {
    return this.elemSearchCache;
  }

  async pineconeSearch(searchString: string, amount: number) {
    const prevSearchResult = this.pineconeSearchCache[searchString];

    if (prevSearchResult) {
      const elems = prevSearchResult.map((id) => cloneDeep(this.firestoreElementsCache[id]));
      return elems;
    }

    const searchResultItems = await getElementsSemanticSearchResultIDs(searchString, amount);

    if (!searchResultItems) {
      return [];
    }

    this.checkPineconeCacheSize();
    const elems = [];
    for (const id of searchResultItems) {
      if (this.firestoreElementsCache[id]) {
        elems.push(cloneDeep(this.firestoreElementsCache[id]));
      }
    }

    return elems;
  }

  getTags() {
    const tags = Object.keys(this.tagsCache);
    return tags;
  }

  getElementsTagsCollectionRef() {
    return collection(this.firestore, "/assets/assetElements/tags");
  }
  elementsTagsCache: { data: DocumentData[] } | null = null;
  elementsTagsOrderCache: { data: string[] } | null = null;
  async getElementTagsInOrder(projectType?: UserProjectType) {
    // fetch all score sorted tags
    if (!this.elementsTagsCache) {
      const elementsTagsRef = this.getElementsTagsCollectionRef();
      const elementTagsQuery = query(elementsTagsRef, orderBy("score", "desc"));

      const querySnapshot = await getDocs(elementTagsQuery);

      // store in key val
      const unsortedTags = querySnapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => ({
        id: doc.id,
        data: doc.data(),
      }));
      this.elementsTagsCache = { data: unsortedTags };
    }

    return this.getSortedElementTagsByProjectType(projectType);
  }

  private getProjectTypeScoreKey(projectType: UserProjectType) {
    return projectType + "Score";
  }

  private getSortedElementTagsByProjectType(projectType?: UserProjectType): string[] {
    if (!this.elementsTagsCache) {
      return [];
    }
    if (!projectType || !isProjectDashboardCarouselTemplate(projectType)) {
      return this.elementsTagsCache.data.map((tagData) => tagData.id);
    } else {
      const scoreKey = this.getProjectTypeScoreKey(projectType);
      const specialTags = this.elementsTagsCache.data.filter(
        (tagData) => typeof tagData.data[scoreKey] !== "undefined",
      );
      specialTags.sort((a, b) => b.data[scoreKey] - a.data[scoreKey]);

      const otherTags = this.elementsTagsCache.data.filter(
        (tagData) => typeof tagData.data[scoreKey] === "undefined",
      );

      const combinedTags = [...specialTags, ...otherTags];

      debugLog({
        cache: this.elementsTagsCache.data,
        specialTags,
        otherTags,
      });

      return combinedTags.map((tagData) => tagData.id);
    }
  }

  async getFirestoreElementsByTag(tag: string) {
    const cachedTemplates = this.tagsCache[tag];
    if (cachedTemplates) {
      return cachedTemplates;
    }

    return cachedTemplates;
  }

  async getAllAssets() {
    return this.tagsCache;
  }
  async getAllAssetsArray() {
    const allAssets = Object.values(this.tagsCache).flat();
    return allAssets;
  }

  async initializeFirestoreTemplatesBatch() {
    const querySnapshot = await this.getAllElementsQuery();

    const elements: AssetItem[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      data.id = doc?.id;
      if (isAssetItem(data)) {
        if (doc.id !== undefined && !this.firestoreElementsCache[doc.id]) {
          this.firestoreElementsCache[doc.id] = data;
        }

        elements.push(data);
        if (data.tags) {
          data.tags.forEach((tag) => {
            if (!this.tagsCache[tag]) {
              this.tagsCache[tag] = [];
            }
            this.tagsCache[tag].push(data);
          });
        }
      }
    });
    const allAssets = await this.getAllAssetsArray();
    const assetLibraryItems: Record<string, AssetLibraryItem[]> = {};

    for (const asset of allAssets) {
      if (asset.tags) {
        for (const tag of asset.tags) {
          if (!assetLibraryItems[tag]) {
            assetLibraryItems[tag] = [];
          }
          const assetLibraryItem = getAssetLibraryItemFromAssetItem(asset);
          assetLibraryItems[tag].push(assetLibraryItem);
        }
      }
    }
    this.elemSearchCache.setAccessories(assetLibraryItems);

    return true;
  }

  async getAllElementsQuery() {
    const q = query(this.getElementsItemsCollectionRef(), orderBy("score", "desc"));

    const querySnapshot = await getDocs(q);

    return querySnapshot;
  }

  getElementsItemsCollectionRef() {
    return collection(this.firestore, "/assets/assetElements/items");
  }
}
