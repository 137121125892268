import React from "react";
import { LeftPanelTitle } from "./components/left-panel-title";
import { AssetLibrary, AssetLibraryItem } from "./components/assets-library";
import { editorContextStore } from "contexts/editor-context";
import handsRawJson from "components/constants/hands.json";
import humansRawJson from "components/constants/humans.json";

function HumansLibrary({
  assets,
  label = "Humans",
}: {
  label?: string;
  assets: AssetLibraryItem[];
}) {
  const editor = editorContextStore((state) => state.editor);
  return (
    <div id="left-panel-assets-container" className="flex flex-col">
      <LeftPanelTitle>Add Humans</LeftPanelTitle>
      <AssetLibrary editor={editor} assets={handsRawJson as any as AssetLibraryItem[]}>
        <div className="mb-2 text-zinc-400">Select a human model to add</div>
      </AssetLibrary>
      <LeftPanelTitle>{label}</LeftPanelTitle>
      <AssetLibrary editor={editor} assets={assets}>
        <div className="mb-2 text-zinc-400">Select a human model to add</div>
      </AssetLibrary>
    </div>
  );
}

export function Humans() {
  return <HumansLibrary assets={humansRawJson as any as AssetLibraryItem[]} label="Add Humans" />;
}
