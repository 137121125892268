import { setPromptTemplateSubject } from "@/core/common/prompt-template";
import {
  UiCloseMessageDialogEventHandler,
  UiCloseMessageDialogResponse,
  UiDisplayMessageDialogEventHandler,
} from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import {
  getHtmlImageElementFromUrlAsync,
  getImageElementFromFilesAsync,
} from "@/core/utils/image-utils";
import {
  PrimaryButtonClassName,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { ReplaceProductModal } from "components/popup/message-dialog/replace-product";
import { editorContextStore } from "contexts/editor-context";
import { UploadCloud } from "lucide-react";
import React from "react";
import { Navigate } from "../components/navigate";
import { UserAssetLibraryItem, useUserImageAssets } from "../components/user-assets";

function processUploadedImage({ image }: { image?: HTMLImageElement }) {
  if (!image) {
    return Promise.resolve();
  }

  const { editor } = editorContextStore.getState();

  if (!editor) {
    return Promise.resolve();
  }

  return new Promise<UiCloseMessageDialogResponse>((resolve) => {
    editor.emit<UiDisplayMessageDialogEventHandler>(
      "ui:display-message-dialog",
      "process-uploaded-image",
      {
        image,
      },
    );

    editor.once<UiCloseMessageDialogEventHandler>("ui:close-message-dialog", resolve);
  });
}

async function loadProductFromImageElement(
  imageElement: HTMLImageElement,
  processImage = true,
  caption = "",
) {
  if (!imageElement) {
    return;
  }

  let imageUrl = imageElement.src;

  if (processImage) {
    const response = await processUploadedImage({
      image: imageElement,
    });

    // Convert the uploaded file to an image

    imageUrl =
      // @ts-expect-error
      (response?.type === "process-uploaded-image" ? response?.imageUrl : imageElement.src) ||
      imageUrl;

    // @ts-expect-error
    caption = (response?.type === "process-uploaded-image" ? response.caption : "") || caption;
  }

  const { setReplaceProductInputImagePath, setRegenerateProductPromptTemplate } =
    editorContextStore.getState();

  setReplaceProductInputImagePath(imageUrl);

  setRegenerateProductPromptTemplate((template) => {
    return setPromptTemplateSubject(template, caption || "");
  });
}

function UserAssetLibrary() {
  const { userImageAssets, lastRowRef } = useUserImageAssets();

  return (
    <div className="flex flex-col">
      <div className="font-semibold mb-2">Uploaded Products</div>
      <div className="grid grid-cols-3 gap-2">
        {userImageAssets.map(
          (imageItem, index) =>
            imageItem.id && (
              <UserAssetLibraryItem
                ref={index === userImageAssets.length - 1 ? lastRowRef : undefined}
                key={imageItem.id}
                imageItem={imageItem}
                onAddItem={({ url, event }) => {
                  const caption = imageItem.caption || "";

                  // Add image asset to the library
                  getHtmlImageElementFromUrlAsync(url).then((imageElement) => {
                    return loadProductFromImageElement(imageElement, false, caption);
                  });
                }}
              />
            ),
        )}
      </div>
    </div>
  );
}

function UploadProductButton({
  className = "",
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const replaceProductInputImagePath = editorContextStore(
    (state) => state.replaceProductInputImagePath,
  );

  const handleUploadFiles = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;

    if (!files) {
      return;
    }

    getImageElementFromFilesAsync(files).then(async (imageElement) => {
      if (!imageElement) {
        return;
      }

      await loadProductFromImageElement(imageElement, true);

      // TODO: Save the object to asset
    });
  }, []);

  return (
    <>
      <input
        type="file"
        id="imgupload_replace_product"
        style={{
          display: "none",
        }}
        onChange={(e) => {
          handleUploadFiles(e);
        }}
      />
      <label htmlFor="imgupload_replace_product">
        <div
          id="left-panel-upload-replace-image-button"
          className={classNames(
            !replaceProductInputImagePath
              ? PrimaryButtonClassName
              : SecondaryButtonClassNameInactive,
            "flex items-center justify-center",
            className,
          )}
        >
          <UploadCloud className="select-none" size={18} />
          <div className="ml-2 select-none">Upload Product Photo</div>
        </div>
      </label>
    </>
  );
}

export function ReplaceProduct() {
  return (
    <div className="flex flex-col">
      <Navigate />
      <UploadProductButton />
      <div className="h-2" />
      <UserAssetLibrary />
      <ReplaceProductModal />
    </div>
  );
}
