import React from "react";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import styles from "./scroll-area.module.css";
import { classNames } from "@/core/utils/classname-utils";

export type ScrollAreaContainerProps = ScrollArea.ScrollAreaProps &
  React.RefAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    viewportRef?: React.ForwardedRef<HTMLDivElement>;
    viewportProps?: ScrollArea.ScrollAreaViewportProps;
    orientation?: "vertical" | "horizontal";
    hideScrollbar?: boolean;
  };

export const ScrollAreaContainer = React.forwardRef<HTMLDivElement, ScrollAreaContainerProps>(
  (
    {
      children,
      viewportRef,
      viewportProps = {},
      orientation = "vertical",
      className,
      style,
      onScroll,
      hideScrollbar = false,
      ...props
    }: ScrollAreaContainerProps,
    forwardRef,
  ) => {
    const viewportClassName = viewportProps?.className || "";
    return (
      <ScrollArea.Root
        ref={forwardRef}
        {...props}
        className={classNames(styles.ScrollAreaRoot, className ?? "")}
        style={style}
      >
        <ScrollArea.Viewport
          {...viewportProps}
          ref={viewportRef}
          className={classNames(styles.ScrollAreaViewport, viewportClassName)}
          onScroll={onScroll}
        >
          {children}
        </ScrollArea.Viewport>
        {!hideScrollbar && (
          <ScrollArea.Scrollbar className={styles.ScrollAreaScrollbar} orientation={orientation}>
            <ScrollArea.Thumb className={styles.ScrollAreaThumb} />
          </ScrollArea.Scrollbar>
        )}
        <ScrollArea.Corner className={styles.ScrollAreaCorner} />
      </ScrollArea.Root>
    );
  },
);
