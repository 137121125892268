import mixpanel from "mixpanel-browser";
import type { FlairAnalytics, TrackParams } from "./base-analytics";

export class FlairAnalyticsMixpanel implements FlairAnalytics {
  constructor() {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_API_KEY, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  identify(userId?: string) {
    mixpanel.identify(userId);
  }

  track(eventName: string, params?: TrackParams) {
    mixpanel.track(eventName, params);
  }
}

export function createMixpanelAnalytics() {
  return new FlairAnalyticsMixpanel();
}
