// Food workflow JSON
import { SampleProjectScene } from "@/core/common/scene";
import { EditorAssetContentType } from "@/core/common/types";

export const FoodProject: SampleProjectScene = {
  metadata: {
    animated: false,
  },
  objects: {
    y6sg_IaWwtDdx6WKG9YMY: {
      strokeWidth: 0,
      metadata: {
        surrounding: "brown teacupt and plate",
        surroundingPrefix: "on",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9fd0cf59-828e-4255-8da4-77daee2d8000/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 1,
      scaleY: 1,
      top: -1170.25,
      angle: 0,
      id: "y6sg_IaWwtDdx6WKG9YMY",
      flipX: false,
      flipY: false,
      height: 550,
      zIndex: 1,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 303.06,
      name: "StaticImage",
      width: 453,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9fd0cf59-828e-4255-8da4-77daee2d8000/public",
        type: "image-url",
      },
    },
    "oxxw828-R1ypIz1Yoe9gc": {
      strokeWidth: 0,
      metadata: {
        surrounding: "almonds",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4bf3956d-ef41-4c27-4d6a-989d5c9a4700/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.25,
      scaleY: 0.25,
      top: -952.6,
      angle: 0,
      id: "oxxw828-R1ypIz1Yoe9gc",
      flipX: false,
      flipY: false,
      height: 874,
      zIndex: 4,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 657.93,
      name: "StaticImage",
      width: 874,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4bf3956d-ef41-4c27-4d6a-989d5c9a4700/public",
        type: "image-url",
      },
    },
    RRlfX_Udiq53hrv2IFaEL: {
      strokeWidth: 0,
      metadata: {
        surrounding: "apple with leaves",
        surroundingPrefix: "on",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f28e1a59-3072-4900-fadb-d78876140500/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.77,
      scaleY: 0.77,
      top: -666.28,
      angle: 15,
      id: "RRlfX_Udiq53hrv2IFaEL",
      flipX: false,
      flipY: false,
      height: 533,
      zIndex: 3,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 645.44,
      name: "StaticImage",
      width: 468,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f28e1a59-3072-4900-fadb-d78876140500/public",
        type: "image-url",
      },
    },
    "TxKuMaUFB9G-v8c1bU2GY": {
      strokeWidth: 0,
      metadata: {
        surrounding: "plate with pesto",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e0b6b0bd-96ee-454b-ae1c-91c1ccdd2300/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.73,
      scaleY: 0.73,
      top: -1259.75,
      angle: 0,
      id: "TxKuMaUFB9G-v8c1bU2GY",
      flipX: false,
      flipY: false,
      height: 500,
      zIndex: 0,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: -61.44,
      name: "StaticImage",
      width: 500,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e0b6b0bd-96ee-454b-ae1c-91c1ccdd2300/public",
        type: "image-url",
      },
    },
    ayT8NkCWzjX6s7VRXoEAw: {
      strokeWidth: 0,
      metadata: {
        subject: "avocado toast",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.4,
      scaleY: 0.4,
      top: -875.66,
      angle: -40,
      id: "ayT8NkCWzjX6s7VRXoEAw",
      flipX: false,
      flipY: false,
      height: 677,
      zIndex: 5,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 315.06,
      name: "StaticImage",
      width: 368,
      opacity: 1,
      asset: {
        path: "sampleProjects/assets/fg5O3rUTQE9SbynrNBSa.png",
        type: "image-storage",
        contentType: EditorAssetContentType.jpeg,
      },
    },
    fCTw7c5aulIBUWr3NGvcY: {
      strokeWidth: 0,
      metadata: {
        surrounding: "cutting board with bread",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3080859f-0f4b-424d-3e89-09267b9ceb00/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 1.13,
      scaleY: 1.13,
      top: -854.76,
      angle: 0,
      id: "fCTw7c5aulIBUWr3NGvcY",
      flipX: false,
      flipY: false,
      height: 535,
      zIndex: 2,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 2.76,
      name: "StaticImage",
      width: 466,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3080859f-0f4b-424d-3e89-09267b9ceb00/public",
        type: "image-url",
      },
    },
  },
  name: "Untitled Project",
  generationFrame: {
    top: -1271.73,
    left: -61.44,
    referneceImage: {
      path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e135f77d-a24c-4cb1-ca62-1766e7a9dd00/1024",
      previewPath:
        "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e135f77d-a24c-4cb1-ca62-1766e7a9dd00/256",
      type: "image-url",
    },
    width: 1024,
    id: "generation-frame",
    type: "GenerationFrame",
    promptTemplate: {
      words: [
        {
          isAutoFilled: true,
          prefix: "",
          autocompleteType: "subject",
          type: "input",
          value: "avocado toast",
          valueBeforeAutoFill: "avocado toast",
          objectIds: ["ayT8NkCWzjX6s7VRXoEAw"],
        },
        {
          prefix: "on",
          autocompleteType: "placement",
          type: "input",
          value: "a wooden table",
        },
        {
          isAutoFilled: true,
          prefix: "with",
          autocompleteType: "surrounding",
          type: "input",
          value: "plate with pesto and cutting board with bread and almonds",
          objectIds: ["TxKuMaUFB9G-v8c1bU2GY", "fCTw7c5aulIBUWr3NGvcY", "oxxw828-R1ypIz1Yoe9gc"],
        },
        {
          isAutoFilled: true,
          prefix: "on",
          autocompleteType: "surrounding",
          type: "input",
          value: "brown teacupt and plate and apple with leaves",
          objectIds: ["y6sg_IaWwtDdx6WKG9YMY", "RRlfX_Udiq53hrv2IFaEL"],
        },
      ],
    },
    height: 1024,
  },
  id: "Mvu7vbc2opW4CFIOzBcZ",
  version: "93",
  frame: {
    width: 0,
    height: 0,
  },
};
