import { CustomModelPlaygroundPromptEditorState, StateUpdater } from "@/core/common/types";
import { ReferenceHumanDoc } from "@/core/common/types/reference-human";
import { PaintMaskCanvasControllerPaintBrushType } from "components/editor/paint-mask-canvas-controller";
import React, { createContext, useContext, useState } from "react";
import { getEmptyCustomModelPlaygroundPromptEditorState } from "./custom-model-playground-context";

export enum CustomModelRegenerateHumanEditorMode {
  Idle = "Idle",
  Loading = "Loading",
  Uploading = "Uploading",
}

interface CustomModelRegenerateHumanContextType {
  mode: CustomModelRegenerateHumanEditorMode;
  setMode: (value: StateUpdater<CustomModelRegenerateHumanEditorMode>) => void;

  imageUrl: string;
  setImageUrl: (value: StateUpdater<string>) => void;

  maskImageUrl: string;
  setMaskImageUrl: (value: StateUpdater<string>) => void;

  maskBrushSize: number;
  setMaskBrushSize: (value: StateUpdater<number>) => void;

  maskBrushType: PaintMaskCanvasControllerPaintBrushType;
  setMaskBrushType: (value: StateUpdater<PaintMaskCanvasControllerPaintBrushType>) => void;

  referenceHumanDocs: Record<string, ReferenceHumanDoc>;
  setReferenceHumanDocs: (value: StateUpdater<Record<string, ReferenceHumanDoc>>) => void;

  selectedReferenceHumanId: string;
  setSelectedReferenceHumanId: (value: StateUpdater<string>) => void;

  promptEditorState: CustomModelPlaygroundPromptEditorState;
  setPromptEditorState: (value: StateUpdater<CustomModelPlaygroundPromptEditorState>) => void;
}

const CustomModelRegenerateHumanContext = createContext<
  CustomModelRegenerateHumanContextType | undefined
>(undefined);

export function useCustomModelRegenerateHumanContext() {
  const context = useContext(CustomModelRegenerateHumanContext);

  if (!context) {
    throw new Error(
      "useCustomModelRegenerateHumanContext must be used within a CustomModelRegenerateHumanContextProvider",
    );
  }

  return context;
}

export function CustomModelRegenerateHumanContextProvider({ children }: React.PropsWithChildren) {
  const [mode, setMode] = useState<CustomModelRegenerateHumanEditorMode>(
    CustomModelRegenerateHumanEditorMode.Idle,
  );

  const [imageUrl, setImageUrl] = useState<string>("");
  const [maskImageUrl, setMaskImageUrl] = useState<string>("");

  const [maskBrushSize, setMaskBrushSize] = useState<number>(25);
  const [maskBrushType, setMaskBrushType] = useState<PaintMaskCanvasControllerPaintBrushType>(
    PaintMaskCanvasControllerPaintBrushType.Paint,
  );

  const [referenceHumanDocs, setReferenceHumanDocs] = useState<Record<string, ReferenceHumanDoc>>(
    {},
  );

  const [selectedReferenceHumanId, setSelectedReferenceHumanId] = useState<string>("");

  const [promptEditorState, setPromptEditorState] =
    useState<CustomModelPlaygroundPromptEditorState>(
      getEmptyCustomModelPlaygroundPromptEditorState(),
    );

  return (
    <CustomModelRegenerateHumanContext.Provider
      value={{
        mode,
        setMode,
        imageUrl,
        setImageUrl,
        maskImageUrl,
        setMaskImageUrl,
        maskBrushSize,
        setMaskBrushSize,
        maskBrushType,
        setMaskBrushType,
        referenceHumanDocs,
        promptEditorState,
        setPromptEditorState,
        setReferenceHumanDocs,
        selectedReferenceHumanId,
        setSelectedReferenceHumanId,
      }}
    >
      {children}
    </CustomModelRegenerateHumanContext.Provider>
  );
}
