import React from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { classNames } from "@/core/utils/classname-utils";

export type OptionItem<Value = string> = {
  name: React.ReactNode;
  value: Value;
  description?: React.ReactNode;
  icon?: React.ReactNode;
};

interface ToggleGroupInputPropsBase<Value = string> {
  options: OptionItem<Value>[];
  rootClassName?: string;
  itemClassName?: string;
}

interface ToggleGroupInputSingleProps<Value = string> extends ToggleGroupInputPropsBase<Value> {
  type: "single";
  value: Value;
  onValueChange: (value: Value) => void;
}

interface ToggleGroupInputMultipleProps<Value = string> extends ToggleGroupInputPropsBase<Value> {
  type: "multiple";
  value: Value[];
  onValueChange: (value: Value[]) => void;
}

export type ToggleGroupInputProps<Value = string> =
  | ToggleGroupInputSingleProps<Value>
  | ToggleGroupInputMultipleProps<Value>;

export function ToggleGroupInput<Value = string>(props: ToggleGroupInputProps<Value>) {
  const { options, rootClassName = "", itemClassName = "" } = props;

  const valueToOptionMap = React.useMemo(
    () => new Map(options.map((option) => [String(option.value), option])),
    [options],
  );

  const toggleGroupProps =
    props.type === "multiple"
      ? {
          type: "multiple" as const,
          value: props.value.map(String),
          onValueChange: (value: string[] | undefined) => {
            const newValue = (value ?? []).map((v) => valueToOptionMap.get(v)!.value);
            props.onValueChange(newValue);
          },
        }
      : {
          type: "single" as const,
          value: props.value !== undefined ? String(props.value) : undefined,
          onValueChange: (value: string | null) => {
            if (value !== null) {
              const newValue = valueToOptionMap.get(value)?.value;
              if (newValue !== undefined) {
                props.onValueChange(newValue);
              }
            }
          },
        };

  return (
    <ToggleGroup.Root
      {...toggleGroupProps}
      className={classNames("w-fit text-sm flex flex-row items-stretch gap-2", rootClassName)}
    >
      {options.map((option) => (
        <ToggleGroup.Item
          key={String(option.value)}
          value={String(option.value)}
          className={classNames(
            "data-[state=on]:bg-zinc-800 data-[state=on]:text-zinc-300 transition-colors",
            "hover:bg-zinc-800/50 hover:text-zinc-300",
            "text-zinc-500 text-center flex items-center justify-center flex-col",
            "rounded focus:outline-none focus-visible:ring-1 focus-visible:ring-lime-500 overflow-hidden",
            itemClassName,
          )}
          aria-label={String(option.name)}
        >
          <div className="p-6 flex flex-col items-start">
            <div className="flex flex-col gap-2">
              <div className="font-bold text-start flex flex-row items-center gap-2">
                {option.icon}
                {option.name}
              </div>
              {option.description && (
                <div className="font-normal text-start text-xs">{option.description}</div>
              )}
            </div>
          </div>
        </ToggleGroup.Item>
      ))}
    </ToggleGroup.Root>
  );
}
