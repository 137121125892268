import { AppRoleType, UserAuthChangedEventHandler } from "@/core/common/types";
import { PublicTeamId } from "@/core/common/types/team";
import { editorContextStore } from "contexts/editor-context";
import React from "react";

export function useAuthUpdateEffect() {
  const backend = editorContextStore((state) => state.backend);
  const user = editorContextStore((state) => state.user);
  const currentTeamId = editorContextStore((state) => state.currentTeamId);
  const teamRoles = editorContextStore((state) => state.teamRoles);
  const setCurrentTeamId = editorContextStore((state) => state.setCurrentTeamId);
  const setTeamRoles = editorContextStore((state) => state.setTeamRoles);
  const setUser = editorContextStore((state) => state.setUser);
  const setUserQuotas = editorContextStore((state) => state.setUserQuotas);
  const setUserOnboardData = editorContextStore((state) => state.setUserOnboardData);

  React.useEffect(() => {
    return backend?.onAuthStateChanged(async (user) => {
      const { setPublicUserId } = editorContextStore.getState();

      setUser(user);
      if (!user) {
        setUserQuotas(null);
        setUserOnboardData(null);
        setPublicUserId(null);
        setTeamRoles({});
        setCurrentTeamId(null);
        editorContextStore.getState().analytics.identify();
      } else {
        // Get token directly from the user object

        const token = await user.getIdTokenResult();
        console.log("token", token);
        const teamRoles = token?.claims?.teams || {};
        setTeamRoles(teamRoles);

        const initialTeamId = Object.entries(teamRoles).find(
          ([_, role]) => role === AppRoleType.Owner,
        )?.[0] as PublicTeamId | undefined;
        setCurrentTeamId(initialTeamId || null);
        console.log("current team id", initialTeamId);
        backend.getPublicUserId().then(async (publicUserId) => {
          setPublicUserId(publicUserId || null);
        });

        editorContextStore.getState().analytics.identify(user.uid);

        backend.onUserLogin({
          uid: user.uid,
        });
      }
      editorContextStore
        .getState()
        .editor?.emit<UserAuthChangedEventHandler>("user:auth-changed", user);
    });
  }, [backend, setUser, setUserQuotas, setUserOnboardData, setTeamRoles, setCurrentTeamId]);

  React.useEffect(() => {
    return backend?.onUserQuotasUpdate({
      userId: user?.uid,
      onUpdate: setUserQuotas,
    });
  }, [backend, user, setUserQuotas]);

  React.useEffect(() => {
    if (user) {
      backend?.getUserOnboardData(user?.uid).then((userOnboardData) => {
        setUserOnboardData(userOnboardData);
      });
    }
  }, [backend, user, setUserOnboardData]);
}
