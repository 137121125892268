import { useCustomModelTraininingInput } from "components/custom-model/custom-model-training-context";
import {
  customModelWorkflowData,
  defaultCustomModelTrainingInputSliderOptions,
} from "@/components/custom-model/custom-model-workflows-data";
import { editorContextStore } from "@/contexts/editor-context";
import { clamp } from "lodash";
import React from "react";

export function useCustomModelWorkflowSliderConfigUpdateEffect() {
  const { setTrainingStrengthPercent } = useCustomModelTraininingInput();

  const workflow = editorContextStore((state) => state.customModelWorkflow);

  const trainingSliderConfigs = React.useMemo(
    () =>
      customModelWorkflowData[workflow]?.trainingSliderConfigs ??
      defaultCustomModelTrainingInputSliderOptions,
    [workflow],
  );

  React.useEffect(() => {
    const step = trainingSliderConfigs.defaultValue;

    const percent = clamp(step / trainingSliderConfigs.maxNumSteps, 0.0, 1.0);

    setTrainingStrengthPercent(percent);
  }, [trainingSliderConfigs, setTrainingStrengthPercent]);
}
