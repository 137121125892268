import { classNames } from "@/core/utils/classname-utils";
import { LEFT_PANEL_DROPDOWN_WIDTH } from "./left-panels";

/* @tw */
export const InputBoxClassName =
  "w-full px-3 py-2 text-zinc-200 bg-zinc-500/10 rounded-md border border-solid border-zinc-500/20 hover:border-zinc-500/50 focus-visible:outline-none focus:border-lime-500 focus-visible:border-lime-500 transition-colors";

/* @tw */
export const InputBoxClassNameError =
  "w-full px-3 py-2 text-zinc-200 bg-zinc-500/10 rounded-md border border-solid border-red-700 hover:border-red-600 focus-visible:outline-none focus:border-red-500 focus-visible:border-red-500 transition-colors";

/* @tw */
export const InputUnderscoreClassName =
  "w-full px-2 py-1 text-zinc-200 bg-zinc-800 border-b border-solid border-zinc-500/20 hover:border-zinc-500/50 focus-visible:outline-none focus:border-lime-500 transition-colors";

/* @tw */
export const PrimaryButtonClassName =
  "px-3 py-2 rounded-md bg-lime-500 hover:bg-lime-400 active:bg-lime-600 select-none text-center text-zinc-900 font-semibold focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:outline-lime-100 transition-colors box-border";

/* @tw */
export const PrimaryButtonClassNameLoading =
  "px-3 py-2 rounded-md bg-lime-600 select-none text-center text-zinc-500 font-semibold cursor-wait";

/* @tw */
export const PrimaryButtonClassNameDisabled =
  "px-3 py-2 rounded-md bg-zinc-800 select-none text-center text-zinc-500 font-semibold transition-colors cursor-not-allowed";

/* @tw */
export const PrimaryButtonClassNameError =
  "px-3 py-2 rounded-md bg-red-500 select-none text-center text-zinc-500 font-semibold transition-colors cursor-not-allowed";

/* @tw */
export const SecondaryButtonClassName =
  "flex flex-row items-center bg-lime-900/20 p-2 rounded-md border border-solid border-lime-500 text-lime-400 hover:text-lime-500 active:text-lime-600 focus-visible:outline-none transition-colors select-none cursor-pointer shadow shadow-lime-500/20";

/* @tw */
export const SecondaryButtonClassNameInactive =
  "flex flex-row items-center bg-zinc-900 p-2 rounded-md border border-solid border-zinc-800 hover:border-lime-600 hover:text-lime-500 active:text-lime-600 focus:border-zinc-500 focus:hover:border-lime-500 focus-visible:outline-none transition-colors select-none cursor-pointer";

/* @tw */
export const SecondaryButtonClassNameDisabled =
  "flex flex-row items-center bg-zinc-900 text-zinc-700 p-2 rounded-md border border-solid border-zinc-800 focus-visible:outline-none transition-colors select-none cursor-not-allowed";

/* @tw */
export const TextButtonClassName =
  "flex flex-row items-center justify-center text-zinc-500 hover:text-lime-500 active:text-zinc-600 focus:text-zinc-300 transition-colors select-none cursor-pointer";

/* @tw */
export const TextButtonClassNameDisabled =
  "flex flex-row items-center justify-center text-zinc-700 transition-colors select-none cursor-not-allowed";

/* @tw */
export const LeftPanelTitleClassName =
  "w-full flex flex-row items-center justify-start select-none text-center py-2 text-ellipsis overflow-hidden";

/* @tw */
export const DropdownClassName = `bg-zinc-900 shadow-md text-zinc-300 border border-solid border-zinc-800 rounded-md px-3 py-2 z-100 focus:outline-none focus-visible:outline-none focus:border-zinc-700`;

/* @tw */
export const LeftPanelDropdownWidthClassName = `w-[${LEFT_PANEL_DROPDOWN_WIDTH}px]`;

/* @tw */
export const DashboardCardClassName =
  "outline-none border border-zinc-800 hover:border-lime-600 shadow-sm hover:shadow-xl hover:shadow-lime-500/5 rounded-md overflow-hidden cursor-pointer text-zinc-500 hover:text-zinc-300 transition";

/* @tw */
export const DashboardTitleClassName =
  "text-sm md:text-base 2xl:text-lg text-zinc-500 hover:text-zinc-300 font-medium transition-colors";

/* @tw */
export const MessageDialogClassName = classNames(
  DropdownClassName,
  "rounded-2xl max-h-[90vh] px-6 py-4 flex flex-col items-center overflow-hidden",
);

/* @tw */
export const frostedGlassClassName =
  "px-2.5 py-1 w-fit text-sm rounded-full text-zinc-300 backdrop-blur-sm bg-zinc-800/10 border border-zinc-100/10 truncate transition-all";

/* @tw */
export const CardClassName =
  "outline-none border border-zinc-800 hover:border-lime-600 shadow-sm hover:shadow-xl hover:shadow-lime-500/5 rounded-md overflow-hidden cursor-pointer text-zinc-500 hover:text-zinc-300 transition text-sm 2xl:text-base";

/* @tw */
export const CardInactiveClassName =
  "outline-none border border-zinc-800 shadow-sm rounded-md overflow-hidden cursor-pointer text-zinc-500 transition text-sm 2xl:text-base";

/* @tw */
export const PreviewImageContainerClassName =
  "flex items-center justify-center border border-zinc-800 bg-cover bg-center rounded-lg overflow-hidden";

/* @tw */
export const CommonButtonClassName = "px-4 py-2 flex flex-row items-center justify-center gap-2";

/* @tw */
export const OutputItemToolbarButtonClassName = classNames(
  SecondaryButtonClassNameInactive,
  "text-zinc-300 hover:bg-lime-500 active:bg-lime-300 hover:text-zinc-900 border-none hover:border-none shadow-xl cursor-pointer",
);
