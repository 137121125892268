import React from "react";
import { LeftPanelTitle } from "../components/left-panel-title";

export function EditNone() {
  return (
    <div className="flex flex-col">
      <LeftPanelTitle>Edit Object</LeftPanelTitle>
      <div className="text-zinc-500">Select an object to start editing.</div>
    </div>
  );
}
