import { IObject } from "../scene";
import { ObjectBounds2d } from "../types";

export interface SceneJSON {
  objects: Record<string, IObject>;
  generationFrameBounds: ObjectBounds2d;
}

export function isSceneJSON(sceneJson: any): sceneJson is SceneJSON {
  return sceneJson && sceneJson.objects != null && sceneJson.generationFrameBounds != null;
}
