import React from "react";
import { Navigate } from "../components/navigate";
import { classNames } from "@/core/utils/classname-utils";

import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { editorContextStore } from "contexts/editor-context";
import { ProgressBar } from "../components/progress-bar";
import { PromptTemplateFormField } from "components/text-editor/prompt-template-editor";
import {
  handleExitRegenrateProduct,
  useEditingObject,
  useRegenerateProductChangeEffect,
} from "hooks/use-regenerate-product";
import { Editor } from "@/core/editor";
import { EditorActiveObject } from "@/core/common/interfaces";
import { Plus } from "lucide-react";
import { RegenerateProductModelV2 } from "components/popup/message-dialog/regenerate-product-v2";
import { regenerateProductWithColorCorrectV2 } from "components/utils/regenerate-product-v2";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { debugLog } from "@/core/utils/print-utilts";
import { NumberSlider } from "../components/number-slider";
import { isColorCorrectV2QuotasAvaialable } from "@/core/utils/color-correct-v2-utils";

function GenerateButton() {
  const uid = editorContextStore((state) => state.user?.uid);
  const userQuotas = editorContextStore((state) => state.userQuotas);
  const editor = editorContextStore((state) => state.editor);
  const backend = editorContextStore((state) => state.backend);
  const editingObject = useEditingObject(editor);
  const regenerateProductRenderState = editorContextStore(
    (state) => state.regenerateProductRenderState,
  );

  const [progress, setProgress] = React.useState(0);
  const [disabled, setDisabled] = React.useState(false);
  const [isQuotaAvailable, setQuotaAvailable] = React.useState(true);

  React.useEffect(() => {
    if (regenerateProductRenderState === "rendering") {
      setProgress(0.1);
    } else {
      setProgress(0);
    }
  }, [regenerateProductRenderState]);

  React.useEffect(() => {
    setDisabled(
      !uid || !editor || !backend || !editingObject || !isStaticImageObject(editingObject),
    );
  }, [uid, editor, backend, editingObject]);

  React.useEffect(() => {
    setQuotaAvailable(isColorCorrectV2QuotasAvaialable(userQuotas));
  }, [userQuotas]);

  return regenerateProductRenderState === "rendering" ? (
    <ProgressBar
      progress={progress}
      className={classNames("cursor-pointer")}
      onClick={() => {
        const { regenerateRenderProcessController } = editorContextStore.getState();

        regenerateRenderProcessController?.abort();
      }}
    />
  ) : (
    <button
      className={classNames(
        disabled || !isQuotaAvailable ? PrimaryButtonClassNameDisabled : PrimaryButtonClassName,
        disabled || !isQuotaAvailable ? "cursor-not-allowed" : "cursor-pointer",
      )}
      onClick={() => {
        if (disabled) {
          debugLog("The regenerate product interface is disabled.");
          return;
        }

        if (!isQuotaAvailable) {
          debugLog("The regenerate product quota is not available.");
          return;
        }

        if (!uid || !editor || !backend || !editingObject || !isStaticImageObject(editingObject)) {
          debugLog(`The input conditions are invalid:\n`, {
            uid,
            editor,
            backend,
            editingObject,
          });
          return;
        }

        regenerateProductWithColorCorrectV2({
          uid,
          editor,
          backend,
          object: editingObject,
        });
      }}
    >
      {isQuotaAvailable ? (
        "Generate"
      ) : (
        <div className="flex flex-row items-center justify-center gap-2">
          Generate
          <span className="ml-2 text-xs px-2 py-px rounded-md bg-lime-600/50 text-lime-200">
            No Credit
          </span>
        </div>
      )}
    </button>
  );
}

function RegenerateProductPromptEditor() {
  const regenerateProductPromptTemplate = editorContextStore(
    (state) => state.regenerateProductPromptTemplate,
  );

  const setRegenerateProductPromptTemplate = editorContextStore(
    (state) => state.setRegenerateProductPromptTemplate,
  );

  const subjectWordIndex = React.useMemo(
    () =>
      regenerateProductPromptTemplate.words.findIndex(
        (word) => word.autocompleteType === "subject",
      ),
    [regenerateProductPromptTemplate],
  );

  const subjectWord = React.useMemo(
    () => regenerateProductPromptTemplate.words[subjectWordIndex],
    [regenerateProductPromptTemplate, subjectWordIndex],
  );

  return (
    <PromptTemplateFormField
      key={subjectWordIndex}
      word={subjectWord}
      index={subjectWordIndex}
      setPromptTemplate={setRegenerateProductPromptTemplate}
      canInsert={false}
    />
  );
}

function AddResultsButton({
  className = "",
  editor,
  editingObject,
  onClick,
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  editor?: Editor | null;
  editingObject?: EditorActiveObject;
}) {
  const generatedResults = editorContextStore((state) => state.regenerateProductResults);
  const numSelectedResults = React.useMemo(
    () => generatedResults.reduce((sum, { isSelected }) => (isSelected ? sum + 1 : sum), 0),
    [generatedResults],
  );
  return (
    <button
      className={classNames(
        numSelectedResults > 0
          ? SecondaryButtonClassNameInactive
          : SecondaryButtonClassNameDisabled,
        "items-center justify-center",
        className,
      )}
      onClick={(e) => {
        if (numSelectedResults > 0) {
          handleExitRegenrateProduct({
            editor,
            editingObject,
            addImages: true,
          });
        }

        onClick?.(e);
      }}
      {...props}
    >
      <Plus size={18} className="mr-2" />
      {numSelectedResults > 0 ? `Add ${numSelectedResults} images to canvas` : "No image to add"}
    </button>
  );
}

export function RegenerateProduct() {
  const editor = editorContextStore((state) => state.editor);
  const creativity = editorContextStore((state) => state.regenerateProductCreativity);
  const setCreativity = editorContextStore((state) => state.setRegenerateProductCreativity);

  const creativityPercent = creativity * 100;
  const setCreativityPercent = React.useCallback(
    (value: number) => {
      setCreativity(Math.round(value) * 0.01);
    },
    [setCreativity],
  );

  const editingObject = useEditingObject(editor);

  useRegenerateProductChangeEffect({
    editor,
    editingObject,
  });

  return (
    <div className="flex flex-col">
      <Navigate />
      <RegenerateProductPromptEditor />
      <NumberSlider
        name="Creativity"
        nameClassName="min-w-[95px] text-sm font-semibold"
        value={creativityPercent}
        setValue={setCreativityPercent}
        defaultValue={50}
        min={0}
        max={100}
        step={10}
      />
      <div className="h-4" />
      <GenerateButton />
      <div className="h-4" />
      <AddResultsButton editor={editor} editingObject={editingObject} />
      <RegenerateProductModelV2 />
    </div>
  );
}
