import React, { useEffect, useState } from "react";
import * as Slider from "@radix-ui/react-slider";
import { classNames } from "@/core/utils/classname-utils";

export function NumberSlider({
  name,
  value,
  setValue,
  defaultValue = 50,
  min = 0,
  max = 100,
  step = 1,
  thumbSize = 15,
  trackHeight = 3,
  className,
  nameClassName = "",
  inputClassName = "",
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  name: React.ReactNode;
  value: number;
  setValue: (value: number) => void;
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  thumbSize?: number;
  trackHeight?: number;
  nameClassName?: string;
  inputClassName?: string;
  slideRootClassName?: string;
}) {
  const [valueInput, setValueInput] = useState(String(value));
  useEffect(() => setValueInput(String(value)), [value]);
  thumbSize = Math.max(Math.min(thumbSize, 38), 8);
  trackHeight = Math.max(trackHeight, 2);
  return (
    <div className={`w-full flex flex-row flex-center items-center gap-4 ${className}`} {...props}>
      <div className={classNames("min-w-[50px] text-left text-sm font-semibold", nameClassName)}>
        {name}
      </div>
      <input
        type="number"
        className={classNames(
          "w-[80px] px-3 py-2 bg-zinc-800/50 rounded-md focus-visible:outline-none border border-zinc-800 focus:border-lime-500 transition-colors",
          inputClassName,
        )}
        value={valueInput}
        onChange={(e) => setValueInput(e.currentTarget.value)}
        onBlur={() => {
          const value = parseInt(valueInput);
          if (value && !isNaN(value)) {
            setValue(Math.max(Math.min(value, max), min));
          } else {
            setValueInput(String(value));
          }
        }}
      />
      <Slider.Root
        defaultValue={[defaultValue]}
        min={min}
        max={max}
        step={step}
        value={[value]}
        onValueChange={(v) => {
          setValue(v[0]);
        }}
        className="relative flex-1 flex items-center select-none touch-none"
        style={{
          height: trackHeight + 2,
        }}
      >
        <Slider.Track
          className="grow relative rounded-full bg-zinc-700"
          style={{
            height: trackHeight,
          }}
        >
          <Slider.Range className="absolute bg-lime-500 rounded-full h-full" />
        </Slider.Track>
        <Slider.Thumb
          className="block rounded-full bg-white border border-lime-200 focus:outline-none focus:border-lime-300 shadow shadow-lime-500/20 focus:shadow-lime-600/30 transition-colors"
          style={{
            width: thumbSize,
            height: thumbSize,
          }}
        />
      </Slider.Root>
    </div>
  );
}
