import { SampleProjectScene } from "@/core/common/scene";
import { EditorAssetContentType } from "@/core/common/types";

export const HumansProject: SampleProjectScene = {
  metadata: {
    animated: false,
  },
  objects: {
    QcttCYcJ9RtZz3OYLJ6VJ: {
      strokeWidth: 0,
      metadata: {
        surrounding: "girl with sleek dark hair in a ponytail and hazel eyes",
        surroundingPrefix: "with",
        colorDisplayType: "asset",
        imageType: "subject",
        hedUrl:
          "https://imagedelivery.net/i1XPW6iC_chU01_6tBPo8Q/11de15af-6553-44db-88c6-96f0f991d000/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.97,
      scaleY: 0.97,
      top: -93.57,
      angle: 0,
      id: "QcttCYcJ9RtZz3OYLJ6VJ",
      flipX: false,
      flipY: false,
      height: 942,
      zIndex: 4,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 36.25,
      name: "StaticImage",
      width: 942,
      opacity: 1,
      asset: {
        path: "https://imagedelivery.net/i1XPW6iC_chU01_6tBPo8Q/11de15af-6553-44db-88c6-96f0f991d000/public",
        type: "image-url",
      },
    },
    "-lvtmvvf3JwjBh85kHwF6": {
      strokeWidth: 0,
      metadata: {
        surrounding: "hands",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://imagedelivery.net/i1XPW6iC_chU01_6tBPo8Q/96a7d724-d8f6-4d47-604c-24bd96ccf200/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.93,
      scaleY: 0.93,
      top: 325.06,
      angle: 0,
      id: "-lvtmvvf3JwjBh85kHwF6",
      flipX: false,
      flipY: false,
      height: 533,
      zIndex: 0,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: -167.67,
      name: "StaticImage",
      width: 533,
      opacity: 1,
      asset: {
        path: "https://imagedelivery.net/i1XPW6iC_chU01_6tBPo8Q/96a7d724-d8f6-4d47-604c-24bd96ccf200/public",
        type: "image-url",
      },
    },
    Gx8jY_FrNAVDFTc3p2eRp: {
      strokeWidth: 0,
      metadata: {
        subject: "a tube bottle",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.81,
      scaleY: 0.79,
      top: 30.37,
      angle: 0,
      id: "Gx8jY_FrNAVDFTc3p2eRp",
      flipX: false,
      flipY: false,
      height: 788,
      zIndex: 1,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: -258.41,
      name: "StaticImage",
      width: 788,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4409ed74-750c-48b8-1dfa-80ddb2d0d600/public",
        type: "image-url",
      },
    },
  },
  name: "Untitled Project",
  generationFrame: {
    top: -204.5,
    left: -207,
    referneceImage: {
      path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5da370bb-a1d8-4a53-21fa-ae6557979600/1024",
      type: "image-url",
    },
    width: 1024,
    id: "generation-frame",
    type: "GenerationFrame",
    promptTemplate: {
      words: [
        {
          isAutoFilled: true,
          prefix: "",
          autocompleteType: "subject",
          type: "input",
          value: "a tube bottle",
          objectIds: ["Gx8jY_FrNAVDFTc3p2eRp"],
        },
        {
          prefix: "in front of",
          autocompleteType: "background",
          type: "input",
          value: "a natural beige background",
        },
        {
          isAutoFilled: false,
          prefix: "on",
          autocompleteType: "placement",
          type: "input",
          value: "circular platforms",
          objectIds: ["JTd-Ms9no1ka-ZElovq71"],
        },
        {
          isAutoFilled: true,
          prefix: "with",
          autocompleteType: "surrounding",
          type: "input",
          value: "hands and girl with sleek dark hair in a ponytail and hazel eyes",
          objectIds: ["-lvtmvvf3JwjBh85kHwF6", "QcttCYcJ9RtZz3OYLJ6VJ"],
        },
      ],
      style: "",
    },
    height: 1024,
  },
  id: "4IksuZmF98EvgY9ESaM1",
  version: "123",
  frame: {
    width: 0,
    height: 0,
  },
};
