import { fabric } from "fabric";
import { getBoundsOverlapArea } from "./bbox-utils";

type Point = fabric.Point;

type TLineDescriptor = {
  o: Point;
  d: Point;
};

type TBBoxLines = {
  topline: TLineDescriptor;
  leftline: TLineDescriptor;
  bottomline: TLineDescriptor;
  rightline: TLineDescriptor;
};

export type TCornerPoints = {
  tl: Point;
  tr: Point;
  bl: Point;
  br: Point;
};

/**
 * Method that returns an object with the object edges in it, given the coordinates of the corners
 * @private
 * @param {Object} lineCoords or aCoords Coordinates of the object corners
 */
function getImageLines({ tl, tr, bl, br }: TCornerPoints): TBBoxLines {
  const lines = {
    topline: {
      o: tl,
      d: tr,
    },
    rightline: {
      o: tr,
      d: br,
    },
    bottomline: {
      o: br,
      d: bl,
    },
    leftline: {
      o: bl,
      d: tl,
    },
  };

  return lines;
}

export function shrinkCornerPoints(points: TCornerPoints, dx: number, dy: number = dx) {
  const { tl, tr, bl, br } = points;
  return {
    tl: new fabric.Point(tl.x + dx, tl.y + dy),
    tr: new fabric.Point(tr.x - dx, tr.y + dy),
    bl: new fabric.Point(bl.x + dx, bl.y - dy),
    br: new fabric.Point(br.x - dx, br.y - dy),
  };
}

/**
 * Helper method to determine how many cross points are between the 4 object edges
 * and the horizontal line determined by a point on canvas
 * @private
 * @param {Point} point Point to check
 * @param {Object} lines Coordinates of the object being evaluated
 * @return {number} number of crossPoint
 */
export function findCrossPoints(point: Point, lines: TBBoxLines): number {
  let xcount = 0;

  for (const lineKey in lines) {
    let xi;
    const iLine = lines[lineKey as keyof TBBoxLines];
    // optimization 1: line below point. no cross
    if (iLine.o.y < point.y && iLine.d.y < point.y) {
      continue;
    }
    // optimization 2: line above point. no cross
    if (iLine.o.y >= point.y && iLine.d.y >= point.y) {
      continue;
    }
    // optimization 3: vertical line case
    if (iLine.o.x === iLine.d.x && iLine.o.x >= point.x) {
      xi = iLine.o.x;
    }
    // calculate the intersection point
    else {
      const b1 = 0;
      const b2 = (iLine.d.y - iLine.o.y) / (iLine.d.x - iLine.o.x);
      const a1 = point.y - b1 * point.x;
      const a2 = iLine.o.y - b2 * iLine.o.x;

      xi = -(a1 - a2) / (b1 - b2);
    }
    // don't count xi < point.x cases
    if (xi >= point.x) {
      xcount += 1;
    }
    // optimization 4: specific for square images
    if (xcount === 2) {
      break;
    }
  }
  return xcount;
}

export function coordsContainsPoint(
  coords: TCornerPoints,
  point: Point,
  lines: TBBoxLines | undefined,
) {
  const imageLines = lines || getImageLines(coords);
  const xPoints = findCrossPoints(point, imageLines);
  // if xPoints is odd then point is inside the object
  return xPoints !== 0 && xPoints % 2 === 1;
}

function getMinMaxFromPoints(points: fabric.Point[]) {
  return points.reduce(
    ({ min, max }, curr) => {
      return {
        min: min.min(curr),
        max: max.max(curr),
      };
    },
    {
      min: points[0].clone(),
      max: points[0].clone(),
    },
  );
}

function getCoordsFromMinMax({
  min,
  max,
}: {
  min: fabric.Point;
  max: fabric.Point;
}): [fabric.Point, fabric.Point, fabric.Point, fabric.Point] {
  const tl = new fabric.Point(min.x, min.y);
  const tr = new fabric.Point(max.x, min.y);
  const br = new fabric.Point(max.x, max.y);
  const bl = new fabric.Point(min.x, max.y);
  return [tl, tr, br, bl];
}

export function getObjectWorldCoords(
  object: fabric.Object,
  absolute: boolean = true,
  calculate?: boolean,
) {
  let coords = object.getCoords(absolute, calculate);
  const minmax = getMinMaxFromPoints(coords);
  coords = getCoordsFromMinMax(minmax);
  if (object.group) {
    // Group transformation matrix
    const t = object.group.calcTransformMatrix();
    coords = coords.map((p) => fabric.util.transformPoint(p, t)) as [
      fabric.Point,
      fabric.Point,
      fabric.Point,
      fabric.Point,
    ];
  }
  return coords;
}

export function removeObjectFromGroupTemporarily(
  object: fabric.Object,
  callback: (object: fabric.Object) => void,
) {
  const group = object.group;

  if (group) {
    group.removeWithUpdate(object);
  }

  callback(object);

  if (group) {
    group.addWithUpdate(object);
  }
}

export function getObjectCoordsOverlapArea(
  coords0: [fabric.Point, fabric.Point, fabric.Point, fabric.Point],
  coords1: [fabric.Point, fabric.Point, fabric.Point, fabric.Point],
) {
  const [tl0, tr0, br0, bl0] = coords0;
  const [tl1, tr1, br1, bl1] = coords1;
  return getBoundsOverlapArea(tl0, br0, tl1, br1);
}

export function getObjectsOverlapArea(object0: fabric.Object, object1: fabric.Object) {
  return getObjectCoordsOverlapArea(
    getObjectWorldCoords(object0, true),
    getObjectWorldCoords(object1, true),
  );
}

type AffineMatrix2 = [number, number, number, number, number, number];

export function inverseAffineMatrix2d(matrix: AffineMatrix2): AffineMatrix2 {
  const [a, b, c, d, e, f] = matrix;

  // Calculate the determinant of the top-left 2x2 sub-matrix
  const det = a * d - b * c;

  // Check if the matrix is invertible
  if (det === 0) {
    return [1, 0, 0, 1, 0, 0];
  }

  // Compute the elements of the inverse matrix
  const a_inv = d / det;
  const b_inv = -b / det;
  const c_inv = -c / det;
  const d_inv = a / det;
  const e_inv = (c * f - d * e) / det;
  const f_inv = (b * e - a * f) / det;

  return [a_inv, b_inv, c_inv, d_inv, e_inv, f_inv];
}

export function transformPoint2d(point: [number, number], matrix: AffineMatrix2) {
  const [a, b, c, d, e, f] = matrix;
  const [x, y] = point;
  return [a * x + c * y + e, b * x + d * y + f];
}

type Matrix3x3 = [number, number, number, number, number, number, number, number, number];

function getDefaultMatrix3x3(): Matrix3x3 {
  return [1, 0, 0, 0, 1, 0, 0, 0, 1];
}

export function getIdentityMatrix3x3(dst?: Matrix3x3) {
  if (!dst) {
    return getDefaultMatrix3x3();
  }

  dst[0] = 1;
  dst[1] = 0;
  dst[2] = 0;
  dst[3] = 0;
  dst[4] = 1;
  dst[5] = 0;
  dst[6] = 0;
  dst[7] = 0;
  dst[8] = 1;

  return dst;
}

/**
 * Creates a 2D projection matrix
 * @param {number} width width in pixels
 * @param {number} height height in pixels
 * @param {module:webgl-2d-math.Matrix4} [dst] optional matrix to store result
 * @return {module:webgl-2d-math.Matrix3} a projection matrix that converts from pixels to clipspace with Y = 0 at the top.
 * @memberOf module:webgl-2d-math
 */
export function getCanvasProjectionMatrix3x3(width: number, height: number, dst?: Matrix3x3) {
  // Note: This matrix flips the Y axis so 0 is at the top.

  dst = dst || getDefaultMatrix3x3();

  dst[0] = 2 / width;
  dst[1] = 0;
  dst[2] = 0;
  dst[3] = 0;
  dst[4] = -2 / height;
  dst[5] = 0;
  dst[6] = -1;
  dst[7] = 1;
  dst[8] = 1;

  return dst;
}

/**
 * Takes two Matrix3s, a and b, and computes the product in the order
 * that pre-composes b with a.  In other words, the matrix returned will
 * @param {module:webgl-2d-math.Matrix3} a A matrix.
 * @param {module:webgl-2d-math.Matrix3} b A matrix.
 * @param {module:webgl-2d-math.Matrix4} [dst] optional matrix to store result
 * @return {module:webgl-2d-math.Matrix3} the result.
 * @memberOf module:webgl-2d-math
 */
export function multiplyMatrix3x3(a: Matrix3x3, b: Matrix3x3, dst?: Matrix3x3) {
  dst = dst || getDefaultMatrix3x3();
  const a00 = a[0 * 3 + 0];
  const a01 = a[0 * 3 + 1];
  const a02 = a[0 * 3 + 2];
  const a10 = a[1 * 3 + 0];
  const a11 = a[1 * 3 + 1];
  const a12 = a[1 * 3 + 2];
  const a20 = a[2 * 3 + 0];
  const a21 = a[2 * 3 + 1];
  const a22 = a[2 * 3 + 2];
  const b00 = b[0 * 3 + 0];
  const b01 = b[0 * 3 + 1];
  const b02 = b[0 * 3 + 2];
  const b10 = b[1 * 3 + 0];
  const b11 = b[1 * 3 + 1];
  const b12 = b[1 * 3 + 2];
  const b20 = b[2 * 3 + 0];
  const b21 = b[2 * 3 + 1];
  const b22 = b[2 * 3 + 2];

  dst[0] = b00 * a00 + b01 * a10 + b02 * a20;
  dst[1] = b00 * a01 + b01 * a11 + b02 * a21;
  dst[2] = b00 * a02 + b01 * a12 + b02 * a22;
  dst[3] = b10 * a00 + b11 * a10 + b12 * a20;
  dst[4] = b10 * a01 + b11 * a11 + b12 * a21;
  dst[5] = b10 * a02 + b11 * a12 + b12 * a22;
  dst[6] = b20 * a00 + b21 * a10 + b22 * a20;
  dst[7] = b20 * a01 + b21 * a11 + b22 * a21;
  dst[8] = b20 * a02 + b21 * a12 + b22 * a22;

  return dst;
}

export function getInverseMatrix3x3(m: Matrix3x3, dst?: Matrix3x3) {
  dst = dst || getDefaultMatrix3x3();

  const m00 = m[0 * 3 + 0];
  const m01 = m[0 * 3 + 1];
  const m02 = m[0 * 3 + 2];
  const m10 = m[1 * 3 + 0];
  const m11 = m[1 * 3 + 1];
  const m12 = m[1 * 3 + 2];
  const m20 = m[2 * 3 + 0];
  const m21 = m[2 * 3 + 1];
  const m22 = m[2 * 3 + 2];

  const b01 = m22 * m11 - m12 * m21;
  const b11 = -m22 * m10 + m12 * m20;
  const b21 = m21 * m10 - m11 * m20;

  const det = m00 * b01 + m01 * b11 + m02 * b21;
  const invDet = 1.0 / det;

  dst[0] = b01 * invDet;
  dst[1] = (-m22 * m01 + m02 * m21) * invDet;
  dst[2] = (m12 * m01 - m02 * m11) * invDet;
  dst[3] = b11 * invDet;
  dst[4] = (m22 * m00 - m02 * m20) * invDet;
  dst[5] = (-m12 * m00 + m02 * m10) * invDet;
  dst[6] = b21 * invDet;
  dst[7] = (-m21 * m00 + m01 * m20) * invDet;
  dst[8] = (m11 * m00 - m01 * m10) * invDet;

  return dst;
}

export function isObjectInViewport(object: fabric.Object, canvas: fabric.Canvas) {
  const objectWorldCoords = getObjectWorldCoords(object);
  const viewport = canvas.calcViewportBoundaries();

  // Check if any of the object's points are within the viewport
  return objectWorldCoords.some((point) => {
    return (
      point.x >= viewport.tl.x &&
      point.x <= viewport.br.x &&
      point.y >= viewport.tl.y &&
      point.y <= viewport.br.y
    );
  });
}
