import React from "react";
import { editorContextStore } from "contexts/editor-context";
import { useNavigate } from "react-router-dom";
import { NavBarUserProfile } from "components-mobile/navbar/user-profile";
import { NavBarContainer } from "components-mobile/navbar/navbar-container";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ChevronLeft, Redo2, Undo2 } from "lucide-react";
import { Logo } from "components/logo";
import {
  NavbarDropdownContent,
  NavbarDropdownItem,
} from "components-mobile/navbar/navbar-dropdown";
import { classNames } from "@/core/utils/classname-utils";
import { HistoryChangedEditorEventHandler } from "@/core/common/types";
import { PROJECTS } from "components/constants/routes";

const sideOffset = 18;

function EditorNavBarLogo() {
  const navigate = useNavigate();
  const backend = editorContextStore((state) => state.backend);
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="px-3">
        <Logo />
      </DropdownMenu.Trigger>
      <NavbarDropdownContent sideOffset={sideOffset}>
        <NavbarDropdownItem
          onClick={() => {
            navigate(`/${PROJECTS}`);
          }}
        >
          <ChevronLeft size={16} />
          <span className="ml-3">Back to projects</span>
        </NavbarDropdownItem>
      </NavbarDropdownContent>
    </DropdownMenu.Root>
  );
}

function getZoomRatioDisplay(zoomRatio: number) {
  return Math.max(Math.round(zoomRatio * 100), 0);
}

/* @tw */
const navBarButtonClassNameBase = "px-3 py-2.5 rounded transition-colors";

/* @tw */
const navBarButtonClassName = classNames(
  navBarButtonClassNameBase,
  "text-zinc-300 active:bg-zinc-800 active:text-zinc-100",
);

/* @tw */
const navBarButtonClassNameDisabled = classNames(
  navBarButtonClassNameBase,
  "text-zinc-500 cursor-not-allowed",
);

const navBarButtonSize = 20;

function NavBarUndo({ disabled = false }: { disabled?: boolean }) {
  return (
    <button
      className={classNames(disabled ? navBarButtonClassNameDisabled : navBarButtonClassName)}
      onClick={() => {
        if (disabled) {
          return;
        }

        editorContextStore.getState().editor?.history.undo();
      }}
    >
      <Undo2 size={navBarButtonSize} />
    </button>
  );
}

function NavBarRedo({ disabled = false }: { disabled?: boolean }) {
  return (
    <button
      className={classNames(disabled ? navBarButtonClassNameDisabled : navBarButtonClassName)}
      onClick={() => {
        if (disabled) {
          return;
        }

        editorContextStore.getState().editor?.history.redo();
      }}
    >
      <Redo2 size={navBarButtonSize} />
    </button>
  );
}

export function EditorNavBar() {
  const editor = editorContextStore((state) => state.editor);
  const [hasUndo, setHasUndo] = React.useState(false);
  const [hasRedo, setHasRedo] = React.useState(false);

  React.useEffect(() => {
    if (!editor) {
      return;
    }

    const handleHistoryChanged = ({ hasUndo = false, hasRedo = false }) => {
      setHasUndo(hasUndo);
      setHasRedo(hasRedo);
    };

    editor.on<HistoryChangedEditorEventHandler>("history:changed", handleHistoryChanged);

    return () => {
      editor.off<HistoryChangedEditorEventHandler>("history:changed", handleHistoryChanged);
    };
  }, [editor]);

  return (
    <NavBarContainer className="gap-1">
      <EditorNavBarLogo />
      <NavBarUndo disabled={!hasUndo} />
      <NavBarRedo disabled={!hasRedo} />
      <div className="flex-1" />
      <NavBarUserProfile />
    </NavBarContainer>
  );
}
