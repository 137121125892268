import React from "react";
import { NavBarContainer } from "components/navbar/navbar-container";
import { editorContextStore } from "contexts/editor-context";
import { useNavigate } from "react-router-dom";
import { NavBarUserProfile } from "components/navbar/user-profile";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ChevronDown, ChevronLeft } from "lucide-react";
import { Logo } from "components/logo";
import { cleanupText } from "@/core/utils/string-utils";
import {
  NavbarDropdownContent,
  NavbarDropdownItem,
  NavbarDropdownItemRightSlot,
} from "components/navbar/navbar-dropdown";
import { NavBarUpgrade } from "components/navbar/upgrade";
import { PROJECTS } from "components/constants/routes";

const sideOffset = 18;

function EditorNavBarLogo() {
  const navigate = useNavigate();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="px-3">
        <Logo />
      </DropdownMenu.Trigger>
      <NavbarDropdownContent sideOffset={sideOffset}>
        <NavbarDropdownItem
          onClick={() => {
            navigate(`/${PROJECTS}`);
          }}
        >
          <ChevronLeft size={16} />
          <span className="ml-3">Back to projects</span>
        </NavbarDropdownItem>
      </NavbarDropdownContent>
    </DropdownMenu.Root>
  );
}

function getZoomRatioDisplay(zoomRatio: number) {
  return Math.max(Math.round(zoomRatio * 100), 0);
}

function NavbarZoom() {
  const editor = editorContextStore((state) => state.editor);
  const zoomRatio = editorContextStore((state) => state.zoomRatio);
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="w-[60px] h-full flex flex-row items-center justify-center transition-colors text-center select-none text-zinc-500 hover:text-zinc-200 focus:outline-none focus:text-zinc-300">
        <span className="mr-1">{getZoomRatioDisplay(zoomRatio)}%</span>
        <ChevronDown size={14} />
      </DropdownMenu.Trigger>
      <NavbarDropdownContent sideOffset={sideOffset}>
        <NavbarDropdownItem
          onClick={() => {
            editor?.zoom.zoomInGrid();
          }}
        >
          Zoom In
          <NavbarDropdownItemRightSlot>Ctrl + +</NavbarDropdownItemRightSlot>
        </NavbarDropdownItem>
        <NavbarDropdownItem
          onClick={() => {
            editor?.zoom.zoomOutGrid();
          }}
        >
          Zoom Out
          <NavbarDropdownItemRightSlot>Ctrl + -</NavbarDropdownItemRightSlot>
        </NavbarDropdownItem>
        <NavbarDropdownItem
          onClick={() => {
            editor?.zoom.zoomToFit();
          }}
        >
          Zoom to Fit
          <NavbarDropdownItemRightSlot>Shift + 1</NavbarDropdownItemRightSlot>
        </NavbarDropdownItem>
        <NavbarDropdownItem
          onClick={() => {
            editor?.zoom.zoom(0.5);
          }}
        >
          Zoom to 50%
        </NavbarDropdownItem>
        <NavbarDropdownItem
          onClick={() => {
            editor?.zoom.zoom(1);
          }}
        >
          Zoom to 100%
          <NavbarDropdownItemRightSlot>Ctrl + 0</NavbarDropdownItemRightSlot>
        </NavbarDropdownItem>
        <NavbarDropdownItem
          onClick={() => {
            editor?.zoom.zoom(1.5);
          }}
        >
          Zoom to 150%
        </NavbarDropdownItem>
      </NavbarDropdownContent>
    </DropdownMenu.Root>
  );
}

function ProjectNameInput() {
  const backend = editorContextStore((state) => state.backend);
  const projectId = editorContextStore((state) => state.projectId);
  const projectDisplayName =
    editorContextStore((state) => state.projectDisplayName) || "Untitled Project";
  const setProjectDisplayName = editorContextStore((state) => state.setProjectDisplayName);
  const [isEditing, setEditing] = React.useState(false);
  const [name, setName] = React.useState(projectDisplayName);
  const navigate = useNavigate();
  const textInputRef = React.useRef<HTMLInputElement | null>(null);
  const isEscapeRef = React.useRef(false);

  React.useEffect(() => {
    setName(projectDisplayName);
  }, [projectDisplayName]);

  React.useEffect(() => {
    if (isEditing) {
      textInputRef.current?.focus();
      textInputRef.current?.select?.();
    }
  }, [isEditing]);

  const updateProjectName = React.useCallback(
    (value: string) => {
      if (!backend || !projectId) {
        return;
      }
      setName(value);
      setProjectDisplayName(value);
      backend?.setProjectDisplayName(projectId, value);
    },
    [backend, projectId, setProjectDisplayName],
  );

  const commitProjectName = React.useCallback(() => {
    const nameCleanup = cleanupText(name);
    if (!isEscapeRef.current && nameCleanup?.length && nameCleanup !== projectDisplayName) {
      updateProjectName(nameCleanup);
    } else {
      setName(projectDisplayName);
    }
    isEscapeRef.current = false;
  }, [updateProjectName, name, projectDisplayName, setName]);

  return (
    <div className="flex-1 ml-8 flex flex-row justify-center items-center text-sm text-zinc-500">
      {isEditing ? (
        <input
          ref={textInputRef}
          type="text"
          name="filename"
          value={name}
          className="max-w-[50vw] outline outline-1 rounded-sm focus:outline-zinc-800 text-zinc-300 bg-transparent focus-visible:outline-none"
          onChange={(e) => {
            setName(e.currentTarget?.value || "");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setName(e.currentTarget?.value || "");
              setEditing(false);
              commitProjectName();
            } else if (e.key === "Escape") {
              setEditing(false);
              isEscapeRef.current = true;
            }
          }}
          onBlur={() => {
            setEditing(false);
            commitProjectName();
          }}
        />
      ) : (
        <>
          <div
            className="text-zinc-500 hover:text-zinc-200 select-none cursor-default transition-colors"
            onClick={() => {
              navigate(`/${PROJECTS}`);
            }}
          >
            Projects
          </div>
          <div className="px-1 select-none">/</div>
          <div
            className="text-zinc-300 hover:text-zinc-200 select-none cursor-default transition-colors"
            onDoubleClick={() => {
              setEditing(true);
            }}
          >
            {projectDisplayName}
          </div>
        </>
      )}
    </div>
  );
}

export function EditorNavBar() {
  return (
    <NavBarContainer>
      <EditorNavBarLogo />
      <ProjectNameInput />
      <NavbarZoom />
      <div className="ml-2.5 flex flex-row items-center justify-center gap-2">
        <NavBarUpgrade />
        <NavBarUserProfile />
      </div>
    </NavBarContainer>
  );
}
