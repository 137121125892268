import { editorContextStore } from "contexts/editor-context";
import { isActiveSelection } from "@/core/utils/type-guards";
import potpack from "potpack";

export function arrangeSelectionInGrid() {
  const { editor, activeObject } = editorContextStore.getState();
  if (editor && isActiveSelection(activeObject)) {
    const bboxs: {
      object: fabric.Object;
      w: number;
      h: number;
      x: number;
      y: number;
    }[] = [];

    let leftMin = 0;
    let topMin = 0;

    activeObject.getObjects().forEach((object) => {
      const left = object.left;
      const top = object.top;
      const w = object.getScaledWidth();
      const h = object.getScaledHeight();
      if (left !== undefined && top !== undefined && w && h) {
        leftMin = Math.min(leftMin, left);
        topMin = Math.min(topMin, top);
        bboxs.push({
          object,
          w,
          h,
          x: 0,
          y: 0,
        });
      }
    });

    potpack(bboxs);

    bboxs.forEach(({ object, x, y }) => {
      object.left = leftMin + x;
      object.top = topMin + y;
      object.setCoords();
    });

    activeObject.setCoords();

    editor.canvas.requestRenderAll();

    activeObject.addWithUpdate();

    editor.history.save();
  }
}
