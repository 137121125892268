import { noop } from "lodash";

export type TrackParams = Record<string, any>;

export interface FlairAnalytics {
  identify: (userId?: string) => void;

  track: (type: string, params?: TrackParams) => void;
}

export function getDummyAnalytics(): FlairAnalytics {
  return {
    track: noop,
    identify: noop,
  };
}
