import { SampleProjectScene } from "@/core/common/scene";
import React, { createContext } from "react";

const noop = () => {};

interface IProjectInitSceneContext {
  initScene: SampleProjectScene | undefined;
  setInitScene: (value: SampleProjectScene | undefined) => void;
}

const ProjectInitSceneContext = createContext<IProjectInitSceneContext>({
  initScene: undefined,
  setInitScene: noop,
});

export function ProjectInitSceneContextProvider({ children }: { children: React.ReactNode }) {
  const [initScene, setInitScene] = React.useState<SampleProjectScene | undefined>();
  return (
    <ProjectInitSceneContext.Provider
      value={{
        initScene,
        setInitScene,
      }}
    >
      {children}
    </ProjectInitSceneContext.Provider>
  );
}

export function useProjectInitSceneContext() {
  return React.useContext(ProjectInitSceneContext);
}
