import { fabric } from "fabric";
import { PerspectiveCamera } from "three";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { StaticImage3dElementMetadata, StaticImageElementType } from "./elements";

export type Scene3dJson = any;

export type Camera3dJson = any;

export type Scene3dData = {
  sceneJson: Scene3dJson;
  cameraJson: Camera3dJson;
};

export type StaticImageObject3d = fabric.StaticImage & {
  metadata: StaticImage3dElementMetadata;
};

export function isScene3dJson(data: any): data is Scene3dJson {
  return data?.object && typeof data.object.type === "string";
}

export function isCamera3dJson(data: any): data is Camera3dJson {
  return (
    data?.object &&
    typeof data.object.type === "string" &&
    (data.object.type as string).toLowerCase().includes("camera")
  );
}

export function isScene3dData(data: any): data is Scene3dData {
  return isScene3dJson(data?.sceneJson) && isCamera3dJson(data?.cameraJson);
}

export function isStaticImageObject3d(object: any): object is StaticImageObject3d {
  return (
    isStaticImageObject(object) &&
    object.metadata?.imageType === StaticImageElementType.ThreeD &&
    isScene3dData(object.metadata?.scene3dData)
  );
}

export function isPerspectiveCamera(object: any): object is PerspectiveCamera {
  return object?.isPerspectiveCamera === true;
}
