import { editorContextStore } from "contexts/editor-context";
import { DocsBatchGenerator } from "@/core/common/types";
import { ApiModelType, ApiUsageDoc } from "@/core/common/types/api";
import React from "react";
import { formatDistanceToNow } from "date-fns";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { classNames } from "@/core/utils/classname-utils";
import {
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";

export function UsageRecordHeader({ children }: { children: React.ReactNode }) {
  return <th className="text-sm text-left px-4 py-2 border-b border-zinc-800">{children}</th>;
}

const UsageRecordRowClassName = "h-[38px] px-4 py-2 border-b border-zinc-800";

function UsageRecordRowEmpty() {
  return (
    <tr className="text-sm text-zinc-300">
      <td className="h-[38px] px-4 py-2 border-b border-zinc-800 text-zinc-500 truncate max-w-[6vw]"></td>
      <td className={UsageRecordRowClassName}></td>
      <td className={UsageRecordRowClassName}></td>
      <td className={UsageRecordRowClassName}></td>
      <td className={UsageRecordRowClassName}></td>
    </tr>
  );
}

export function UsageRecordRow({ usageDoc }: { usageDoc: Partial<ApiUsageDoc> }) {
  // Format the date to a relative time string
  const timeAgo = React.useMemo(() => {
    if (!usageDoc.timestamp) {
      return "unknown";
    }
    return formatDistanceToNow(usageDoc.timestamp.toDate(), {
      addSuffix: true,
    });
  }, [usageDoc]);

  if (!usageDoc?.id) {
    return <UsageRecordRowEmpty />;
  }

  return (
    <tr className="text-sm text-zinc-300">
      <td className={classNames(UsageRecordRowClassName, "text-zinc-500 truncate max-w-[6vw]")}>
        {usageDoc.id}
      </td>
      <td className={UsageRecordRowClassName}>{usageDoc.pipeline_type || "unknown"}</td>
      <td className={UsageRecordRowClassName}>{usageDoc.result?.success ? "success" : "failed"}</td>
      <td className={UsageRecordRowClassName}>
        {`${usageDoc.result?.duration_seconds} seconds` || "unknown"}
      </td>
      <td className={UsageRecordRowClassName}>{timeAgo}</td>
    </tr>
  );
}

function sliceArrayWithPadding<T, E>(
  array: T[],
  page: number,
  pageSize: number,
  emptyElement: E,
): (T | E)[] {
  // Calculate start index
  const startIndex = page * pageSize;
  // Calculate end index
  const endIndex = startIndex + pageSize;
  // Slice the array from start index to end index
  const slicedArray = array.slice(startIndex, endIndex);
  // Calculate how many empty objects we need to add (if any)
  const paddingSize = Math.max(pageSize - slicedArray.length, 0);

  if (paddingSize <= 0) {
    return slicedArray;
  }

  // Create an array filled with empty objects
  const padding = Array(paddingSize).fill(emptyElement);
  // Concatenate the sliced array with the padding array
  return slicedArray.concat(padding);
}

export function GenerateImageApiUsage({ pageSize = 12 }: { pageSize?: number }) {
  const backend = editorContextStore((state) => state.backend);
  const [usageDocs, setUsageDocs] = React.useState<ApiUsageDoc[]>([]);
  const numUsageDocsRef = React.useRef(0);

  const [page, setPage] = React.useState(0);

  const [isFinished, setIsFinished] = React.useState(false);

  const apiUsageGeneratorRef = React.useRef<DocsBatchGenerator<ApiUsageDoc> | undefined>(undefined);

  React.useEffect(() => {
    numUsageDocsRef.current = usageDocs.length;
  }, [usageDocs]);

  const canGetNext = React.useMemo(
    () => (isFinished ? (page + 1) * pageSize <= usageDocs.length : true),
    [isFinished, page, pageSize, usageDocs],
  );

  React.useEffect(() => {
    apiUsageGeneratorRef.current = backend?.getApiUsageGenerator({
      batchSize: pageSize,
      modelType: ApiModelType.GenerateImage,
    });

    apiUsageGeneratorRef.current?.getNextBatch().then((docs) => {
      setUsageDocs(docs);
      setIsFinished(docs.length <= 0);
    });
  }, [backend, pageSize]);

  const getPrev = React.useCallback(() => {
    setPage((page) => Math.max(page - 1, 0));
  }, []);

  const getNext = () => {
    if (!canGetNext) {
      return;
    }

    if ((page + 2) * pageSize > usageDocs.length) {
      apiUsageGeneratorRef.current?.getNextBatch().then((docs) => {
        setUsageDocs((prevDocs) => [...prevDocs, ...docs]);
        setIsFinished(docs.length <= 0);
      });
    }

    setPage((page) => page + 1);
  };

  const usages = React.useMemo(
    () => sliceArrayWithPadding(usageDocs, page, pageSize, {}),
    [usageDocs, page, pageSize],
  );

  return (
    <div className="w-full flex flex-row justify-center">
      <div className="flex flex-col gap-4 w-full">
        <table className="">
          <thead>
            <tr>
              <UsageRecordHeader>ID</UsageRecordHeader>
              <UsageRecordHeader>Pipeline</UsageRecordHeader>
              <UsageRecordHeader>Status</UsageRecordHeader>
              <UsageRecordHeader>Run time</UsageRecordHeader>
              <UsageRecordHeader>Created</UsageRecordHeader>
            </tr>
          </thead>
          <tbody>
            {usages.map((usageDoc, index) => (
              <UsageRecordRow key={(usageDoc as any)?.id || index} usageDoc={usageDoc} />
            ))}
          </tbody>
        </table>
        <div className="flex flex-row items-center justify-center gap-4">
          <button
            className={classNames(
              page > 0 ? SecondaryButtonClassNameInactive : SecondaryButtonClassNameDisabled,
              "flex flex-row items-center justify-center gap-4",
            )}
            onClick={getPrev}
          >
            <ArrowLeft size={18} />
            Prev
          </button>
          <div className="px-8">Page {page + 1}</div>
          <button
            className={classNames(
              canGetNext ? SecondaryButtonClassNameInactive : SecondaryButtonClassNameDisabled,
              "flex flex-row items-center justify-center gap-4",
            )}
            onClick={getNext}
          >
            Next
            <ArrowRight size={18} />
          </button>
        </div>
      </div>
    </div>
  );
}
