import { StateUpdater } from "@/core/common/types";
import React, { createContext, useContext } from "react";

// Define the context type
export interface CustomModelCompareOutputContextType {
  sourceImageUrl: string;
  setSourceImageUrl: (value: StateUpdater<string>) => void;
  outputImageUrls: string[];
  setOutputImageUrls: (value: StateUpdater<string[]>) => void;
  activeOutputImageIndex: number;
  setActiveOutputImageIndex: (value: StateUpdater<number>) => void;
  width: number;
  setWidth: (value: StateUpdater<number>) => void;
  height: number;
  setHeight: (value: StateUpdater<number>) => void;
}

// Create the context with an undefined default value
const CustomModelCompareOutputContext = createContext<
  CustomModelCompareOutputContextType | undefined
>(undefined);

// Custom hook to use the context
export function useCustomModelCompareOutputContext() {
  const context = useContext(CustomModelCompareOutputContext);

  if (!context) {
    throw new Error(
      "useCustomModelCompareOutputContext must be used within a CustomModelCompareOutputContextProvider",
    );
  }

  return context;
}

// Provider component
export function CustomModelCompareOutputContextProvider({ children }: React.PropsWithChildren) {
  const [sourceImageUrl, setSourceImageUrl] = React.useState<string>("");
  const [outputImageUrls, setOutputImageUrls] = React.useState<string[]>([]);
  const [activeOutputImageIndex, setActiveOutputImageIndex] = React.useState<number>(0);
  const [width, setWidth] = React.useState<number>(1);
  const [height, setHeight] = React.useState<number>(1);

  return (
    <CustomModelCompareOutputContext.Provider
      value={{
        sourceImageUrl,
        setSourceImageUrl,
        outputImageUrls,
        setOutputImageUrls,
        activeOutputImageIndex,
        setActiveOutputImageIndex,
        width,
        setWidth,
        height,
        setHeight,
      }}
    >
      {children}
    </CustomModelCompareOutputContext.Provider>
  );
}
