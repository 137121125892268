import { classNames } from "@/core/utils/classname-utils";
import * as Dialog from "@radix-ui/react-dialog";
import { MessageDialogOverlayZIndex, MessageDialogZIndex } from "components/constants/zIndex";
import React from "react";
import styles from "./message-dialog.module.css";

export type MessageDialogProps = Dialog.DialogProps & {
  triggerProps?: Dialog.DialogTriggerProps;
  triggerChildren?: React.ReactNode;
  contentProps?: Dialog.DialogContentProps;
  contentChildren?: React.ReactNode;
  overlayProps?: Dialog.DialogOverlayProps;
};

export function MessageDialogClose({
  children,
  ...props
}: Dialog.DialogCloseProps & React.RefAttributes<HTMLButtonElement>) {
  return <Dialog.Close {...props}>{children}</Dialog.Close>;
}

export function MessageDialogTitle({
  children,
  ...props
}: Dialog.DialogTitleProps & React.RefAttributes<HTMLHeadingElement>) {
  return <Dialog.Title {...props}>{children}</Dialog.Title>;
}

export function MessageDialog({
  triggerProps,
  triggerChildren,
  contentProps,
  contentChildren,
  overlayProps,
  ...props
}: MessageDialogProps) {
  return (
    <Dialog.Root {...props}>
      <Dialog.Trigger {...triggerProps}>{triggerChildren}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          {...overlayProps}
          className={classNames(styles.DialogOverlay, overlayProps?.className ?? "")}
          style={{
            zIndex: MessageDialogOverlayZIndex,
          }}
        />
        <Dialog.Content
          {...contentProps}
          className={classNames(styles.DialogContent, contentProps?.className ?? "")}
          style={{
            ...contentProps?.style,
            zIndex: MessageDialogZIndex,
          }}
        >
          {contentChildren}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
