import { MAX_NUMBER_ASSET_UPLOADS } from "@/backend/firebase/firebase-backend";
import rightClickMenuStyles from "@/components/editor/right-click-context-menu/right-click-menu.module.css";
import {
  AppUserSubscriptionTier,
  UiDisplayMessageDialogEventHandler,
  UserAssetInfo,
  UserAssetInfoCollection,
} from "@/core/common/types";
import { StaticImageElementType } from "@/core/common/types/elements";
import { classNames } from "@/core/utils/classname-utils";
import { triggerContextMenuEvent } from "@/core/utils/event-utils";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { mergeRefs } from "components/utils/merge-refs";
import { Tooltip, TooltipProps } from "components/utils/tooltip";
import { getObjectTargetScale, uploadAndAddFiles } from "components/utils/upload";
import { editorContextStore } from "contexts/editor-context";
import { UploadCloud } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { AssetLibrary, AssetLibraryItem } from "./components/assets-library";
import { LeftPanelTitle } from "./components/left-panel-title";
import { PresetImageGridItem } from "./components/preset-image-grid";
import { onAddObjectToCanvas } from "./on-add-object";

type AssetItem = {
  url: string;
  previewUrl?: string;
  name?: string;
  metadata?: Record<string, string>;
};

const presetImages: AssetItem[] = [
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/55fbb185-e305-487b-7908-52b948f26e00/public",
    metadata: {
      subject: "a bottle",
    },
  },
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/39bdba7a-3c41-4e2a-8db0-c6a823ee5800/public",
    metadata: {
      subject: "a bottle",
    },
  },
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/63f9a510-8e56-4032-8e04-3d695d8bd600/public",
    metadata: {
      subject: "whiskey bottle",
    },
  },
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b8dc2c32-9e83-4590-50a8-9fe8d32ada00/public",
    metadata: {
      subject: "dropper bottle",
    },
  },
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c85533a0-5504-4487-e9c6-e421dce28d00/public",
    metadata: {
      subject: "a bottle",
    },
  },
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5ae0b626-f5a4-4968-f25a-956842fbd400/public",
    metadata: {
      subject: "tube bottle",
    },
  },
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c0af69fe-18eb-4272-7017-cf41e46f9900/public",
    metadata: {
      subject: "candle",
    },
  },
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1a11b1f5-e877-4a5d-e1ea-4e4dc4d08600/public",
    metadata: {
      subject: "paper cup",
    },
  },
  {
    url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/da9f0742-e911-494b-7239-93444055ad00/public",
    metadata: {
      subject: "perfume bottle",
    },
  },
];

function AssetButtonQuotaLimit({
  className = "",
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
  return (
    <button
      {...props}
      className={classNames(
        SecondaryButtonClassNameInactive,
        "box-border flex items-center justify-center w-full",
        className,
      )}
      onClick={() => {
        editorContextStore
          .getState()
          .editor?.emit<UiDisplayMessageDialogEventHandler>(
            "ui:display-message-dialog",
            "quota-subscribe",
            {
              title: "No asset storage space left.",
              header:
                "You have used all cloud storage space. Please subscribe to upload more images.",
            },
          );
      }}
    >
      <UploadCloud className="select-none" size={18} />
      <div className="ml-2 select-none">Upload Product Photo</div>
    </button>
  );
}

function UploadProductButton({ className = "" }: { className?: string }) {
  const editor = editorContextStore((state) => state.editor);

  const userQuotas = editorContextStore((state) => state.userQuotas);
  const backend = editorContextStore((state) => state.backend);
  const [isUploadAvailable, setIsUploadAvailable] = useState(true);

  const tier = userQuotas?.tier || AppUserSubscriptionTier.Free;
  useEffect(() => {
    if (!backend) {
      return;
    }
    const checkUploadAvailability = async () => {
      const numUploads = await backend.countTotalUserAssets();
      const isAvailable =
        numUploads < MAX_NUMBER_ASSET_UPLOADS || tier !== AppUserSubscriptionTier.Free;
      setIsUploadAvailable(isAvailable);
    };

    checkUploadAvailability();
  }, [backend, tier]);

  const handleUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    const editorObjects = editor?.objects;
    if (files && files.length > 0 && editorObjects) {
      uploadAndAddFiles({
        files,
        editorObjects,
        assets: editor.assets,
        userAssetInfoType: "images",
        removeBackgroundPopup: true,
      });
    }
  };

  return (
    <>
      <input
        type="file"
        id="imgupload_graphics_default"
        style={{
          display: "none",
        }}
        onChange={(e) => {
          handleUploadFiles(e);
        }}
      />
      <label htmlFor="imgupload_graphics_default">
        {isUploadAvailable ? (
          <div
            id="left-panel-assets-upload-image-button"
            className={`${SecondaryButtonClassNameInactive} flex items-center justify-center ${className}`}
          >
            <UploadCloud className="select-none" size={18} />
            <div className="ml-2 select-none">Upload Product Photo</div>
          </div>
        ) : (
          <AssetButtonQuotaLimit className={className} />
        )}
      </label>
    </>
  );
}

const UserAssetLibraryItem = React.memo(
  React.forwardRef(
    (
      {
        imageItem,
        isIdle = true,
        ...props
      }: TooltipProps & {
        imageItem: UserAssetInfo;
        isIdle?: boolean;
      },
      forwardedRef: React.ForwardedRef<HTMLDivElement>,
    ) => {
      const editor = editorContextStore((state) => state.editor);

      const [elementRef, inView] = useInView();

      const storagePath = imageItem.storagePath;

      const [url, setUrl] = React.useState("");

      const contextMenuTriggerRef = React.useRef<HTMLDivElement | null>(null);

      React.useEffect(() => {
        if (!editor) {
          return;
        }

        editor.assets
          .loadAsset({
            type: "image-storage",
            path: storagePath,
          })
          .then((dataUrl) => {
            if (!dataUrl) {
              return;
            }
            setUrl(dataUrl);
          });
      }, [editor, storagePath]);

      return (
        <ContextMenu.Root>
          <ContextMenu.Trigger ref={contextMenuTriggerRef}>
            <Tooltip
              {...props}
              triggerChildren={
                <PresetImageGridItem
                  // eslint-disable-next-line
                  ref={mergeRefs([forwardedRef, elementRef])}
                  url={inView ? url : ""}
                  className={classNames(
                    SecondaryButtonClassNameInactive,
                    "group",
                    url ? "cursor-pointer" : "cursor-wait",
                  )}
                  onAddItem={() => {
                    if (!editor || !url) {
                      return;
                    }

                    const caption = imageItem.caption;
                    editor.objects
                      .addImageFromUrl({
                        url,
                        asset: {
                          type: "image-storage",
                          path: storagePath,
                        },
                        uploadStorage: false,
                        metadata: {
                          imageType: StaticImageElementType.Subject,
                          subject: caption,
                        },
                      })
                      .then((object) => {
                        if (!object) {
                          return;
                        }
                        const scale = getObjectTargetScale(
                          // @ts-ignore
                          object,
                        );
                        object.scale(scale);

                        onAddObjectToCanvas();
                      });
                  }}
                >
                  <button
                    className="hidden group-hover:flex items-center justify-center absolute right-0 bottom-0 m-2 p-2 rounded-full bg-zinc-800 text-zinc-300 hover:text-lime-500 transition-colors"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      const triggerElement = contextMenuTriggerRef.current;

                      if (!triggerElement) {
                        return;
                      }

                      triggerContextMenuEvent({
                        element: triggerElement,
                        clientX: e.clientX,
                        clientY: e.clientY,
                      });
                    }}
                  >
                    <DotsVerticalIcon width={12} height={12} />
                  </button>
                </PresetImageGridItem>
              }
              contentClassName="pointer-events-none"
              contentChildren={
                <div>{url ? "Click to add this asset to the canvas." : "Loading asset ..."}</div>
              }
            />
          </ContextMenu.Trigger>
          <ContextMenu.Portal>
            <ContextMenu.Content className={rightClickMenuStyles.ContextMenuContent}>
              <ContextMenu.Item
                className={rightClickMenuStyles.ContextMenuItem}
                onClick={() => {
                  const { editor } = editorContextStore.getState();
                  if (!editor) {
                    return;
                  }

                  editor.assets.deleteUserImageAsset({
                    assetId: imageItem.id,
                    storagePath: imageItem.storagePath,
                  });
                }}
              >
                Delete Asset
              </ContextMenu.Item>
            </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>
      );
    },
  ),
);

export const UserAssetLibrary = React.memo(function UserAssetLibrary() {
  const userImageAssetInfoCollection = editorContextStore(
    (state) => state.userImageAssetInfoCollection,
  );
  const backend = editorContextStore((state) => state.backend);

  React.useEffect(() => {
    if (!backend) {
      return;
    }

    const { userImageAssetGeneratorRef } = editorContextStore.getState();

    if (!userImageAssetGeneratorRef.current) {
      userImageAssetGeneratorRef.current = backend.getUserAssetInfoGenerator({
        assetType: "images",
        batchSize: 12,
      });
      userImageAssetGeneratorRef.current?.getNextBatch().then((assetInfo) => {
        editorContextStore.getState().setUserImageAssetInfoCollection(
          assetInfo.reduce<UserAssetInfoCollection>((result, i) => {
            result[i.id] = i;
            return result;
          }, {}),
        );
      });
    }
  }, [backend]);

  const [lastRowRef, lastRowInView] = useInView();

  React.useEffect(() => {
    const { userImageAssetGeneratorRef, setUserImageAssetInfoCollection } =
      editorContextStore.getState();

    if (!lastRowInView || !userImageAssetGeneratorRef.current) {
      return;
    }

    userImageAssetGeneratorRef.current.getNextBatch().then((assetInfo) => {
      setUserImageAssetInfoCollection((prevCollection) => ({
        ...prevCollection,
        ...assetInfo.reduce<UserAssetInfoCollection>((result, i) => {
          result[i.id] = i;
          return result;
        }, {}),
      }));
    });
  }, [lastRowInView]);

  const userImageAssets = React.useMemo(
    () => Object.values(userImageAssetInfoCollection),
    [userImageAssetInfoCollection],
  );

  if (!userImageAssets?.length) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="font-semibold mb-2">Uploaded Products</div>
      <div className="grid grid-cols-3 gap-2">
        {userImageAssets.map(
          (imageItem, index) =>
            imageItem.id && (
              <UserAssetLibraryItem
                ref={index === userImageAssets.length - 1 ? lastRowRef : undefined}
                key={imageItem.id}
                imageItem={imageItem}
              />
            ),
        )}
      </div>
    </div>
  );
});

export function Assets() {
  const editor = editorContextStore((state) => state.editor);

  return (
    <div id="left-panel-assets-container" className="flex flex-col">
      <LeftPanelTitle>
        <span id="add-assets-left-panel-title">Add Products</span>
      </LeftPanelTitle>
      <AssetLibrary
        label="Products"
        editor={editor}
        assets={presetImages as any as AssetLibraryItem[]}
      >
        <UploadProductButton className="mb-2" />
      </AssetLibrary>
      <div className="h-4" />
      <UserAssetLibrary />
    </div>
  );
}
