import { StateUpdater } from "@/core/common/types";
import { ShortcutsUtils } from "@/core/utils/shortcuts-utils";
import { PaintMaskCanvasControllerPaintBrushType } from "components/editor/paint-mask-canvas-controller";
import {
  PaintBrushCursor,
  PaintBrushSizeAdjust,
  PaintMaskCanvasTaskBarButton,
  PaintMaskCanvasToolbarDividerVertical,
  PaintMaskCanvasToolBarRedoButton,
  PaintMaskCanvasToolBarUndoButton,
  UsePaintMaskCanvasControllerReturnType,
} from "components/editor/paint-mask-canvas-controller-utils";
import { PaintBrushIcon } from "components/icons/paint-brush-icon";
import { EraserIcon, TrashIcon } from "lucide-react";

export function CustomModelImageEditorInpaintCanvas({
  maskBrushSize,
  setMaskBrushSize,
  maskBrushType,
  setMaskBrushType,
  canvasContainerRef,
  canvasRef,
  maskCanvasRef,
  brushCursorRef,
  onPointerDown,
  onPointerMove,
  onPointerUp,
  onPointerEnter,
  onPointerLeave,
  onWheel,
  isCanvasMoving,
  isPointerOver,
  isBrushVisible,
  isPointerOverToolbarRef,
  undo,
  redo,
  numRedos,
  numUndos,
  clearMask,
  saveCanvas,
}: UsePaintMaskCanvasControllerReturnType & {
  maskBrushSize: number;
  setMaskBrushSize: (value: StateUpdater<number>) => void;
  maskBrushType: PaintMaskCanvasControllerPaintBrushType;
  setMaskBrushType: (value: StateUpdater<PaintMaskCanvasControllerPaintBrushType>) => void;
}) {
  return (
    <div
      tabIndex={0}
      ref={canvasContainerRef}
      className="relative w-full h-full rounded-md overflow-hidden bg-zinc-800/50 focus-visible:outline focus-visible:outline-zinc-500/50"
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onWheel={onWheel}
      onKeyDown={(e) => {
        const event = e.nativeEvent;
        if (ShortcutsUtils.isCtrlZ(event)) {
          undo();
        } else if (ShortcutsUtils.isCtrlShiftZ(event) || ShortcutsUtils.isCtrlY(event)) {
          redo();
        } else if (ShortcutsUtils.isCtrlS(event) && process.env.NODE_ENV === "development") {
          e.preventDefault();
          saveCanvas();
        }
      }}
    >
      <canvas ref={canvasRef} />
      <canvas
        ref={maskCanvasRef}
        className="absolute left-0 top-0 transition-opacity"
        style={{
          opacity: isCanvasMoving ? 0 : 0.5,
        }}
      />
      <PaintBrushCursor
        ref={brushCursorRef}
        className="bg-lime-500/30"
        style={{
          display: isBrushVisible ? "block" : "none",
          opacity: isPointerOver ? 1 : 0,
          width: `${maskBrushSize || 0}px`,
          height: `${maskBrushSize || 0}px`,
        }}
      />
      <div className="absolute left-0 bottom-0 p-2 w-full flex items-center justify-center pointer-events-none">
        <div
          className="relative w-fit px-1 py-1 rounded-lg flex flex-row items-center justify-center justify-items-stretch text-sm bg-zinc-800 shadow-md border border-zinc-700 transition-opacity pointer-events-auto"
          onPointerEnter={() => {
            // eslint-disable-next-line
            isPointerOverToolbarRef.current = true;
          }}
          onPointerLeave={() => {
            isPointerOverToolbarRef.current = false;
          }}
        >
          <div className="grid grid-rows-1 grid-cols-2 items-center justify-center">
            <PaintMaskCanvasToolBarUndoButton
              isDisabled={numUndos <= 0}
              size={16}
              onClick={() => {
                undo();
              }}
            />
            <PaintMaskCanvasToolBarRedoButton
              isDisabled={numRedos <= 0}
              size={16}
              onClick={() => {
                redo();
              }}
            />
          </div>
          <PaintMaskCanvasToolbarDividerVertical className="hidden md:block" />
          <div className="grid grid-rows-1 grid-cols-2 items-center justify-center gap-1">
            <PaintMaskCanvasTaskBarButton
              isActive={maskBrushType === PaintMaskCanvasControllerPaintBrushType.Paint}
              contentChildren={
                <div className="flex flex-col">
                  <div className="text-zinc-300 mb-1">Mask paint tool</div>
                </div>
              }
              onClick={() => {
                setMaskBrushType(PaintMaskCanvasControllerPaintBrushType.Paint);
              }}
            >
              <PaintBrushIcon size={16} brushColor="#84cc16" />
            </PaintMaskCanvasTaskBarButton>
            <PaintMaskCanvasTaskBarButton
              isActive={maskBrushType === PaintMaskCanvasControllerPaintBrushType.Erase}
              contentChildren={
                <div className="flex flex-col">
                  <div className="text-zinc-300 mb-1">Mask erase tool</div>
                </div>
              }
              onClick={() => {
                setMaskBrushType(PaintMaskCanvasControllerPaintBrushType.Erase);
              }}
            >
              <EraserIcon size={16} />
            </PaintMaskCanvasTaskBarButton>
          </div>
          <PaintMaskCanvasToolbarDividerVertical className="hidden md:block" />
          <PaintBrushSizeAdjust brushSize={maskBrushSize} setBrushSize={setMaskBrushSize} />
          <PaintMaskCanvasToolbarDividerVertical className="hidden md:block" />
          <PaintMaskCanvasTaskBarButton
            contentChildren={
              <div className="flex flex-col">
                <div className="text-zinc-300 mb-1">Clear all mask</div>
              </div>
            }
            onClick={() => {
              clearMask();
            }}
          >
            <TrashIcon size={16} />
          </PaintMaskCanvasTaskBarButton>
        </div>
      </div>
    </div>
  );
}
