import { CustomModelPlaygroundPromptEditorState, StateUpdater } from "@/core/common/types";
import React, { createContext, useContext, useState } from "react";
import { getEmptyCustomModelPlaygroundPromptEditorState } from "./custom-model-playground-context";

// Enum for backend types
export enum CustomModelImageVariationsBackendType {
  InContext = "IC",
  // Add other backend types here if needed
}

export enum CustomModelImageVariationsStatus {
  Idle = "Idle",
  Disabled = "Disabled",
  Loading = "Loading",
}

// Define the shape of the context
interface CustomModelImageVariationsContextType {
  backendType: CustomModelImageVariationsBackendType;
  setBackendType: (value: StateUpdater<CustomModelImageVariationsBackendType>) => void;

  status: CustomModelImageVariationsStatus;
  setStatus: (value: StateUpdater<CustomModelImageVariationsStatus>) => void;

  strength: number;
  setStrength: (value: StateUpdater<number>) => void;

  promptEditorState: CustomModelPlaygroundPromptEditorState;
  setPromptEditorState: (value: StateUpdater<CustomModelPlaygroundPromptEditorState>) => void;
}

// Create the context with an undefined default value
const CustomModelImageVariationsContext = createContext<
  CustomModelImageVariationsContextType | undefined
>(undefined);

// Custom hook to use the CustomModelImageVariationsContext
export function useCustomModelImageVariationsContext() {
  const context = useContext(CustomModelImageVariationsContext);

  if (!context) {
    throw new Error(
      "useCustomModelImageVariationsContext must be used within a CustomModelImageVariationsContextProvider",
    );
  }

  return context;
}

// Provider component
export function CustomModelImageVariationsContextProvider({ children }: React.PropsWithChildren) {
  const [status, setStatus] = useState(CustomModelImageVariationsStatus.Idle);

  const [backendType, setBackendType] = useState<CustomModelImageVariationsBackendType>(
    CustomModelImageVariationsBackendType.InContext,
  );

  const [strength, setStrength] = useState<number>(5); // Default strength value

  const [promptEditorState, setPromptEditorState] =
    useState<CustomModelPlaygroundPromptEditorState>(
      getEmptyCustomModelPlaygroundPromptEditorState(),
    );

  return (
    <CustomModelImageVariationsContext.Provider
      value={{
        status,
        setStatus,
        backendType,
        setBackendType,
        strength,
        setStrength,
        promptEditorState,
        setPromptEditorState,
      }}
    >
      {children}
    </CustomModelImageVariationsContext.Provider>
  );
}
