import React from "react";
import { useLeftPanelState } from "hooks/use-left-panel-state";
import {
  isPanelItemCustomized,
  panelFixedBottomItems,
  panelFixedTopItems,
  panelItems,
} from "./panel-items";
import { LEFT_PANEL_WIDTH } from "components/constants/left-panels";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { LeftPanelItemType } from "components/constants/editor-options";
import { editorContextStore } from "contexts/editor-context";
import {
  LeftPanelScrollEventHandler,
  LeftPanelTriggerScrollTopEventHandler,
} from "@/core/common/types";
import { LeftPanelFixedTopZIndex } from "components/constants/zIndex";
import { getObjectEntries } from "@/core/utils/type-utils";

function LeftPanelItemFixedTop({ activeLeftPanel }: { activeLeftPanel: LeftPanelItemType }) {
  const Component = panelFixedTopItems[activeLeftPanel];

  if (!Component) {
    return null;
  }

  return (
    <div
      className="w-full px-4 bg-zinc-900"
      style={{
        zIndex: LeftPanelFixedTopZIndex,
      }}
    >
      <Component />
    </div>
  );
}

function LeftPanelItemFixedBottom({ activeLeftPanel }: { activeLeftPanel: LeftPanelItemType }) {
  const Component = panelFixedBottomItems[activeLeftPanel];

  if (!Component) {
    return null;
  }
  return (
    <div className="w-full px-4 border-t border-zinc-800">
      <Component />
    </div>
  );
}

function LeftPanelItemDefault({
  activeLeftPanel,
  children,
  isCustomized = false,
}: {
  activeLeftPanel: LeftPanelItemType;
  children?: React.ReactNode;
  isCustomized?: boolean;
}) {
  const editor = editorContextStore((state) => state.editor);

  const scrollAreaContainerRef = React.useRef<HTMLDivElement | null>(null);

  const scrollAreaViewportRef = React.useRef<HTMLDivElement | null>(null);

  if (isCustomized) {
    return <>{children}</>;
  }

  return (
    <>
      <LeftPanelItemFixedTop activeLeftPanel={activeLeftPanel} />
      <ScrollAreaContainer
        ref={scrollAreaContainerRef}
        viewportRef={scrollAreaViewportRef}
        className="relative grow"
        onScroll={() => {
          if (!editor) {
            return;
          }
          if (!scrollAreaContainerRef.current) {
            return;
          }
          const containerBoundingRect = scrollAreaContainerRef.current.getBoundingClientRect();
          editor.emit<LeftPanelScrollEventHandler>("left-panel:scroll", containerBoundingRect);
        }}
      >
        <div className="w-full flex flex-col px-4 text-zinc-300">{children}</div>
      </ScrollAreaContainer>
      <LeftPanelItemFixedBottom activeLeftPanel={activeLeftPanel} />
    </>
  );
}

function isJSXElement(element: any): element is JSX.Element {
  return element !== null && typeof element === "object" && "props" in element && "type" in element;
}

function LeftPanelItemInternal({ leftPanel }: { leftPanel: LeftPanelItemType }) {
  const { isLeftPanelOpen, activeLeftPanel } = useLeftPanelState();

  const leftPanelWidth = isLeftPanelOpen ? LEFT_PANEL_WIDTH : 0;

  const isCustomized = isPanelItemCustomized[leftPanel];

  const Component = React.useMemo(() => {
    const Component = panelItems[leftPanel];

    if (isJSXElement(Component)) {
      // Cached component

      return Component;
    }

    if (leftPanel === activeLeftPanel) {
      return <Component />;
    }

    return null;
  }, [leftPanel, activeLeftPanel]);

  return (
    <div
      className="flex-col bg-zinc-900 border-r border-zinc-800"
      style={{
        display: leftPanel === activeLeftPanel ? "flex" : "none",
        width: `${leftPanelWidth}px`,
      }}
    >
      <LeftPanelItemDefault activeLeftPanel={leftPanel} isCustomized={isCustomized}>
        {Component}
      </LeftPanelItemDefault>
    </div>
  );
}

export function LeftPanelItem() {
  const { activeLeftPanel } = useLeftPanelState();

  return (
    <>
      {getObjectEntries(panelItems).map(([leftPanel]) => (
        <LeftPanelItemInternal key={leftPanel} leftPanel={leftPanel} />
      ))}
    </>
  );
}
