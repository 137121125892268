/* @tw */
export const imageEditorContainerClassName =
  "relative w-full h-full py-2.5 flex flex-col items-stretch md:flex-row gap-4 md:gap-2";

/* @tw */
export const mainPanelClassName = "w-full md:w-auto px-2.5 md:pl-0 order-1 md:order-2";

/* @tw */
export const toolbarPanelClassName =
  "px-2.5 md:pr-0 min-w-[200px] md:w-[35%] lg:w-[30%] order-2 md:order-1";
