import {
  autocompleteBackgroundPrefixes,
  autocompletePlacementPrefixes,
  autocompleteSurroundingsPrefixes,
  getPromptAutocompleteValues,
  promptAutocompleteTypeToValues,
} from "components/constants/prompt-autocomplete";
import { SimpleTextEditor } from "components/text-editor/simple-text-editor";
import { classNames } from "@/core/utils/classname-utils";
import React from "react";
import {
  DropdownOptionItem,
  DropdownOptions,
  DropdownOptionsProps,
  DropdownOptionsWithTrigger,
  DropdownOptionsWithTriggerBorder,
  getDropdownOptionItemsFromArray,
} from "components/utils/dropdown-options";

import { editorContextStore } from "contexts/editor-context";
import { Tooltip } from "components/utils/tooltip";
import {
  Cross1Icon,
  ChevronDownIcon,
  PlusIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import styles from "./prompt-editor-form.module.css";
import {
  GenerateToolEditorTab,
  PromptEditorType,
  PromptTemplate,
  PromptWord,
  StateUpdater,
} from "@/core/common/types";
import { PromptAutocompleteType } from "components/text-editor/prompt-autocomplete";
import { capitalizeFirstLetter } from "@/core/utils/string-utils";
import {
  getConnectionFromPromptWord,
  getStringFromPromptWord,
} from "@/core/common/prompt-template";
import { isStaticImageObject, isStaticImageObjectUploaded } from "@/core/utils/type-guards";
import { FeatureFlag } from "components/utils/featureFlag";

import { Editor } from "@/core/editor";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { getClosestNumber } from "@/core/utils/number-utils";
import { PanelSwitchRow } from "../components/switch";
import { ReferenceImage } from "./reference-image";
import {
  handlePromptWordValueChange,
  propagatePromptWordUpdateToObject,
} from "./use-update-prompt-from-objects";

const inputLabels: Record<PromptAutocompleteType, string> = {
  subject: "Product",
  background: "Background",
  custom: "Custom",
  placement: "Placement",
  surrounding: "Surrounding",
  template: "Template",
};

const inputTypeOptions: Record<string, DropdownOptionItem<PromptAutocompleteType>> = {
  placement: {
    name: "Placement",
    value: "placement",
  },
  surrounding: {
    name: "Surrounding",
    value: "surrounding",
  },
  background: {
    name: "Background",
    value: "background",
  },
  custom: {
    name: "Custom",
    value: "custom",
  },
};

function InsertTextInputButton({
  insertTextInput,
}: {
  insertTextInput: (type: PromptAutocompleteType) => void;
}) {
  return (
    <div
      className={classNames("relative w-full h-4 text-xs", styles.LabelDropdownContainerDivider)}
    >
      <div className="h-1" />
      <div
        className={classNames(
          "absolute w-full top-[80%] left-0 border-b border-zinc-800/50",
          styles.InsertButton,
        )}
      />
      <div
        className={classNames(
          styles.InsertButton,
          "absolute top-[2px] w-full flex items-center justify-center pointer-events-none",
        )}
      >
        <DropdownOptions
          asChild
          options={inputTypeOptions}
          contentProps={{
            sideOffset: 4,
          }}
          onSelectItem={(item) => {
            insertTextInput?.(item.value);
          }}
        >
          <div className="w-fit flex flex-row items-center justify-center px-2 py-1 rounded-full bg-zinc-900 text-zinc-500 hover:text-zinc-300 border border-zinc-800 shadow-md transition-colors cursor-pointer select-none pointer-events-auto">
            <PlusIcon fontSize={8} className="mr-1" />
            <span className="mr-1">Insert</span>
          </div>
        </DropdownOptions>
      </div>
    </div>
  );
}

const textInputTypeClassName =
  "flex flex-row items-center pr-2 pb-1.5 text-sm text-zinc-500 truncate select-none transition-colors";

function TextInputTypeLabel({
  label,
  labelClassName,
}: {
  label?: string;
  labelClassName?: string;
}) {
  return (
    <div className={classNames(textInputTypeClassName, labelClassName ?? "")}>
      <span className="mr-1">{capitalizeFirstLetter(label?.toLowerCase() ?? "")}</span>
    </div>
  );
}

function TextInputTypeSwitch({
  label,
  labelClassName,
  onSwitchType,
}: {
  label?: string;
  labelClassName?: string;
  onSwitchType?: (type: PromptAutocompleteType) => void;
}) {
  return (
    <DropdownOptions
      className={classNames(
        textInputTypeClassName,
        "hover:text-zinc-300 focus:text-zinc-400 focus:outline-none cursor-pointer",
        styles.LabelDropdownContainer,
        labelClassName ?? "",
      )}
      options={inputTypeOptions}
      onSelectItem={(item) => {
        onSwitchType?.(item.value);
      }}
    >
      <span className="mr-1">{capitalizeFirstLetter(label?.toLowerCase() ?? "")}</span>
      <ChevronDownIcon fontSize={16} className={styles.LabelDropdownShow} />
    </DropdownOptions>
  );
}

type TextInputLabelProps = {
  label: string;
  labelClassName?: string;
  canSwitchType?: boolean;
  onSwitchType?: (type: PromptAutocompleteType) => void;
  canDelete?: boolean;
  onDelete?: () => void;
};

function TextInputLabel({
  label,
  labelClassName,
  canSwitchType,
  onSwitchType,
  canDelete = false,
  onDelete,
}: TextInputLabelProps) {
  onDelete = canDelete ? onDelete : undefined;
  return (
    <div
      className={classNames(
        "w-full flex flex-row items-center justify-start",
        styles.LabelDropdownContainer,
      )}
    >
      {canSwitchType ? (
        <TextInputTypeSwitch
          label={label}
          labelClassName={labelClassName}
          onSwitchType={onSwitchType}
        />
      ) : (
        <TextInputTypeLabel label={label} labelClassName={labelClassName} />
      )}
      <div className="flex-1" />
      {onDelete && (
        <Tooltip
          triggerChildren={
            <Cross1Icon
              fontSize={16}
              className={classNames(
                "mx-2 mb-1 text-zinc-700 hover:text-zinc-500 cursor-pointer transition-colors",
                // styles.LabelDropdownShow,
              )}
              onClick={onDelete}
            />
          }
          contentChildren={`Delete ${label.toLowerCase()} prompt`}
        />
      )}
    </div>
  );
}

type TextInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  TextInputLabelProps & {
    autocompleteItems: string[];
    autocompleteLabel?: string;
    autocompleteWidth?: string | number;
    value: string;
    onValueChange: (value: string) => void;
    onInsert?: (type: PromptAutocompleteType) => void;
  };

function TextInput({
  id,
  label,
  labelClassName,
  canSwitchType = true,
  onSwitchType,
  canDelete,
  onDelete,
  onInsert,
  ...props
}: TextInputProps) {
  return (
    <div className="w-full flex flex-col">
      <TextInputLabel
        label={label}
        labelClassName={labelClassName}
        canSwitchType={canSwitchType}
        canDelete={canDelete}
        onDelete={onDelete}
        onSwitchType={onSwitchType}
      />
      <SimpleTextEditor
        {...props}
        id={id}
        className="flex-1 text-sm"
        description={`Select or enter ${label.toLowerCase()} prompt`}
        tooltipProps={{
          triggerProps: {
            asChild: true,
          },
          contentProps: {
            sideOffset: 18,
          },
        }}
      />
      {onInsert && <InsertTextInputButton insertTextInput={onInsert} />}
    </div>
  );
}

type TextDropdownWithInputProps = TextInputProps & {
  dropdownValue?: string;
  dropdownItems?: Record<string, DropdownOptionItem<string>>;
  onSelectDropdownItem?: (value: DropdownOptionItem<string>) => void;
};

function TextDropdownWithInput({
  id,
  label,
  labelClassName,
  canSwitchType = true,
  onSwitchType,
  canDelete,
  onDelete,
  onInsert,
  dropdownValue = "None",
  dropdownItems,
  onSelectDropdownItem,
  ...props
}: TextDropdownWithInputProps) {
  return (
    <div className="w-full flex flex-col">
      <TextInputLabel
        label={label}
        labelClassName={labelClassName}
        canSwitchType={canSwitchType}
        canDelete={canDelete}
        onDelete={onDelete}
        onSwitchType={onSwitchType}
      />
      <div className="w-full flex flex-row items-center">
        {dropdownItems && onSelectDropdownItem && (
          <DropdownOptionsWithTriggerBorder
            options={dropdownItems}
            onSelectItem={onSelectDropdownItem}
            value={dropdownValue}
            className="mr-2"
            style={{
              minWidth: 68,
            }}
          />
        )}
        <SimpleTextEditor
          {...props}
          id={id}
          className="flex-1 text-sm min-w-[10px]"
          description={`Select or enter ${label.toLowerCase()} prompt`}
          tooltipProps={{
            triggerProps: {
              asChild: true,
            },
            contentProps: {
              sideOffset: 18,
            },
          }}
        />
      </div>
      {onInsert && <InsertTextInputButton insertTextInput={onInsert} />}
    </div>
  );
}

function TextInputWrapper({
  dropdownValue,
  dropdownItems,
  onSelectDropdownItem,
  hasDropdown = false,
  ...props
}: TextDropdownWithInputProps & {
  hasDropdown?: boolean;
}) {
  if (hasDropdown && dropdownItems && onSelectDropdownItem) {
    return (
      <TextDropdownWithInput
        {...props}
        dropdownValue={dropdownValue}
        dropdownItems={dropdownItems}
        onSelectDropdownItem={onSelectDropdownItem}
      />
    );
  }
  return <TextInput {...props} />;
}

const dropdownContainerClassName = classNames(
  styles.PromptEditorConfigRow,
  "w-full mb-4 flex flex-row text-sm text-zinc-300",
);

const numImagesOptions = getDropdownOptionItemsFromArray([1, 2, 3, 4]);

function NumImages() {
  const numImages = editorContextStore((state) => state.generateToolNumImages);
  const setNumImages = editorContextStore((state) => state.setGenerateToolNumImages);
  return (
    <div className={dropdownContainerClassName}>
      <div className="mr-2 truncate">Number of results</div>
      <div className="flex-1" />
      <DropdownOptionsWithTrigger
        value={numImages.toString()}
        options={numImagesOptions}
        onSelectItem={(item) => {
          setNumImages(item.value);
        }}
      />
    </div>
  );
}

const guidanceScaleOptions: Record<string, DropdownOptionItem<number>> = {
  "extra-weak": {
    name: "Extra Weak",
    value: 1.5,
  },
  weak: {
    name: "Weak",
    value: 2.5,
  },
  default: {
    name: "Default",
    value: 3.5,
  },
  strong: {
    name: "Strong",
    value: 4.5,
  },
  "extra-strong": {
    name: "Extra Strong",
    value: 5.5,
  },
};

const guidanceScales = Object.values(guidanceScaleOptions).map((option) => option.value);

const guidanceScaleToName = Object.entries(guidanceScaleOptions).reduce(
  (prev, [key, item]) => ({
    ...prev,
    [item.value.toString()]: key,
  }),
  {},
);

function getGuidanceScaleName(strength: number) {
  const closest = getClosestNumber(strength, guidanceScales);
  // @ts-ignore
  return guidanceScaleToName[closest.toString()] || "default";
}

function GuidanceScale() {
  const generateToolGuidanceScale = editorContextStore((state) => state.generateToolGuidanceScale);
  const setGenerateToolGuidanceScale = editorContextStore(
    (state) => state.setGenerateToolGuidanceScale,
  );
  const name =
    guidanceScaleOptions[getGuidanceScaleName(generateToolGuidanceScale)]?.name || "Default";
  return (
    <div className={dropdownContainerClassName}>
      <div className="mr-2 truncate text-zinc-300">Render strength</div>
      <div className="flex-1" />
      <DropdownOptionsWithTrigger
        value={name}
        options={guidanceScaleOptions}
        onSelectItem={(item) => {
          setGenerateToolGuidanceScale(item.value);
        }}
      />
    </div>
  );
}

const colorControlStrengthOptions: Record<string, DropdownOptionItem<number>> = {
  none: {
    name: "None",
    value: 1.0,
  },
  weak: {
    name: "Weak",
    value: 0.9,
  },
  default: {
    name: "Default",
    value: 0.8,
  },
  strong: {
    name: "Strong",
    value: 0.7,
  },
  "extra-strong": {
    name: "Extra Strong",
    value: 0.6,
  },
};

const colorStrengthToName = {
  "1.0": "none",
  "0.9": "weak",
  "0.8": "default",
  "0.7": "strong",
  "0.6": "extra-strong",
};

function getColorStrengthName(strength: number) {
  const closest = getClosestNumber(strength, [1.0, 0.9, 0.8, 0.7, 0.6]);
  // @ts-ignore
  return colorStrengthToName[closest.toString()] || "none";
}

function ColorControlStrength() {
  const generateToolImg2ImgStrength = editorContextStore(
    (state) => state.generateToolImg2ImgStrength,
  );
  const setGenerateToolImg2ImgStrength = editorContextStore(
    (state) => state.setGenerateToolImg2ImgStrength,
  );
  const name =
    colorControlStrengthOptions[getColorStrengthName(generateToolImg2ImgStrength)]?.name || "None";
  return (
    <div className={dropdownContainerClassName}>
      <div className="mr-2 truncate text-zinc-300">Color strength</div>
      <div className="flex-1" />
      <DropdownOptionsWithTrigger
        value={name}
        options={colorControlStrengthOptions}
        onSelectItem={(item) => {
          setGenerateToolImg2ImgStrength(item.value);
        }}
      />
    </div>
  );
}

const outlineControlStrengthOptions: Record<string, DropdownOptionItem<[number, number]>> = {
  none: {
    name: "None",
    value: [0.5, 0.0],
  },
  "extra-weak": {
    name: "Extra Weak",
    value: [0.8, 0.1],
  },
  weak: {
    name: "Weak",
    value: [0.9, 0.2],
  },
  default: {
    name: "Default",
    value: [1.0, 0.5],
  },
  strong: {
    name: "Strong",
    value: [1.0, 0.8],
  },
  "extra-strong": {
    name: "Extra Strong",
    value: [1.0, 1.0],
  },
};

const outlineStrengthToName = Object.entries(outlineControlStrengthOptions).reduce<
  Record<string, string>
>((output, [name, { value }]) => {
  output[value[1].toString()] = name;
  return output;
}, {});

const outlineStrengthFinishValues = Object.values(outlineControlStrengthOptions).map(
  ({ value }) => value[1],
);

function getOutlineControlStrengthName(strengthFinish: number) {
  const name = getClosestNumber(strengthFinish, outlineStrengthFinishValues);
  // @ts-ignore
  return outlineStrengthToName[name.toString()] || "none";
}

function OutlineControlStrength() {
  const setGenerateToolControlNetStrengthStart = editorContextStore(
    (state) => state.setGenerateToolControlNetStrengthStart,
  );
  const generateToolControlNetStrengthFinish = editorContextStore(
    (state) => state.generateToolControlNetStrengthFinish,
  );
  const setGenerateToolControlNetStrengthFinish = editorContextStore(
    (state) => state.setGenerateToolControlNetStrengthFinish,
  );
  const name =
    outlineControlStrengthOptions[
      getOutlineControlStrengthName(generateToolControlNetStrengthFinish)
    ]?.name || "None";

  return (
    <div className={dropdownContainerClassName}>
      <div className="mr-2 truncate text-zinc-300">Outline strength</div>
      <div className="flex-1" />
      <DropdownOptionsWithTrigger
        value={name}
        options={outlineControlStrengthOptions}
        onSelectItem={(item) => {
          const [start, finish] = item.value;
          setGenerateToolControlNetStrengthStart(start);
          setGenerateToolControlNetStrengthFinish(finish);
        }}
      />
    </div>
  );
}

const autocompleteDropdownType: Record<
  PromptAutocompleteType,
  Record<string, DropdownOptionItem<string>> | undefined
> = {
  subject: undefined,
  placement: getDropdownOptionItemsFromArray(autocompletePlacementPrefixes),
  surrounding: getDropdownOptionItemsFromArray(autocompleteSurroundingsPrefixes),
  background: getDropdownOptionItemsFromArray(autocompleteBackgroundPrefixes),
  custom: undefined,
  template: undefined,
};

function typeHasDropdown(type: PromptAutocompleteType) {
  return type === "placement" || type === "surrounding" || type === "background";
}

function typeCanDelete(type: PromptAutocompleteType) {
  return type !== "subject";
}

function typeIsRequired(type: PromptAutocompleteType) {
  return type === "subject";
}

function createDefaultPromptWord(type: PromptAutocompleteType): PromptWord {
  if (type === "placement") {
    return {
      type: "fixed",
      prefix: "on",
      value: "a white platform",
      autocompleteType: type,
    };
  }
  if (type === "surrounding") {
    return {
      type: "fixed",
      prefix: "with",
      value: "wavy fabric",
      autocompleteType: type,
    };
  }
  if (type === "background") {
    return {
      type: "fixed",
      prefix: "in front of",
      value: "white background",
      autocompleteType: type,
    };
  }
  return {
    type: "fixed",
    value: "",
    autocompleteType: type,
  };
}

function getSubjectImageIntersectionGenerationFrame(editor: Editor) {
  const objects = editor.generationFrames.getObjectsIntersectingGenerationFrame((object) =>
    isStaticImageObjectUploaded(object),
  );
  if (objects.length !== 1) {
    return;
  }
  return objects[0];
}

function PromptTemplateFormField({
  word,
  index,
  setPromptTemplate,
}: {
  word: PromptWord;
  index: number;
  setPromptTemplate: (updater: StateUpdater<PromptTemplate>) => void;
}) {
  const type = word.autocompleteType ?? "custom";
  const dropdownValue = word.prefix;
  const dropdownItems = autocompleteDropdownType[type];
  const autocompleteItems = getPromptAutocompleteValues(type, dropdownValue);
  const editor = editorContextStore((state) => state.editor);

  const required = typeIsRequired(type);
  const canDelete = typeCanDelete(type);
  const hasDropdown = typeHasDropdown(type);

  const onDelete = React.useCallback(() => {
    setPromptTemplate((prevTemplate) => {
      const newTemplate = { ...prevTemplate };
      newTemplate.words.splice(index, 1);
      return newTemplate;
    });
  }, [index, setPromptTemplate]);

  const onInsert = React.useCallback(
    (type: PromptAutocompleteType) => {
      setPromptTemplate((prevTemplate) => {
        const newTemplate = { ...prevTemplate };
        newTemplate.words.splice(index + 1, 0, createDefaultPromptWord(type));
        return newTemplate;
      });
    },
    [index, setPromptTemplate],
  );

  const onSelectDropdownItem = React.useCallback(
    (item: DropdownOptionItem<string>) => {
      setPromptTemplate((prevTemplate) => {
        const newTemplate = { ...prevTemplate };
        newTemplate.words[index] = {
          ...word,
          prefix: item.value,
        };
        return newTemplate;
      });
    },
    [word, index, setPromptTemplate],
  );

  const onSwitchType = React.useCallback(
    (type: PromptAutocompleteType) => {
      if (type === word.autocompleteType) {
        return;
      }
      setPromptTemplate((prevTemplate) => {
        const newTemplate = { ...prevTemplate };
        const newWord = createDefaultPromptWord(type);
        if (type === "custom") {
          newWord.value = word.prefix
            ? word.prefix.trim() + " " + word.value.trim()
            : word.value.trim();
        }
        newTemplate.words[index] = newWord;
        return newTemplate;
      });
    },
    [word, index, setPromptTemplate],
  );

  return (
    <TextInputWrapper
      id={`prompt-editor-${type}-${index}`}
      label={inputLabels[type]}
      autocompleteItems={autocompleteItems}
      value={word.value}
      onValueChange={(value) => {
        handlePromptWordValueChange({
          index,
          word,
          value,
          setPromptTemplate,
        });
      }}
      canSwitchType={type !== "subject"}
      onSwitchType={onSwitchType}
      canDelete={canDelete}
      required={required}
      onDelete={onDelete}
      onInsert={onInsert}
      hasDropdown={hasDropdown}
      dropdownValue={word.prefix}
      dropdownItems={dropdownItems}
      onSelectDropdownItem={onSelectDropdownItem}
    />
  );
}

function PromptTemplateForm({
  promptTemplate,
  setPromptTemplate,
}: {
  promptTemplate: PromptTemplate;
  setPromptTemplate: (updater: StateUpdater<PromptTemplate>) => void;
}) {
  const words = promptTemplate.words;

  return (
    <>
      {words.map((word, index) => (
        <PromptTemplateFormField
          key={index}
          word={word}
          index={index}
          setPromptTemplate={setPromptTemplate}
        />
      ))}
    </>
  );
}

const generateEditorInstruction: Record<GenerateToolEditorTab, string> = {
  Editor: "Configure prompt in the form below.",
  Templates: "Configure prompt with templates below.",
};

export function PromptEditorFormPreviewTitle() {
  const generateToolEditorTab = editorContextStore((state) => state.generateToolEditorTab);
  return <div className="w-full pr-2 pb-2 text-sm"></div>;
}

function PromptEditorFormPreviewLabel({
  prefix,
  value,
  className = "",
  ...props
}: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
  prefix: string;
  value: string;
}) {
  return (
    <label
      {...props}
      className={classNames(
        "inline cursor-pointer transition-colors",
        styles.TextLimeBlink,
        value
          ? "text-zinc-400 hover:text-lime-500 focus:text-zinc-100"
          : "text-red-500 hover:text-red-400 focus:text-red-100",
        className,
      )}
    >
      {prefix + value || "[product]"}
    </label>
  );
}

export function PromptEditorFormPreview() {
  const promptTemplate = editorContextStore((state) => state.generateToolPromptTemplate);

  const [children, setChildren] = React.useState<React.ReactNode | null>(null);

  React.useEffect(() => {
    setChildren(
      promptTemplate.words.map((word, index) => {
        const prefix = index === 0 ? "" : getConnectionFromPromptWord(word);
        const value = getStringFromPromptWord(word);
        return (
          <PromptEditorFormPreviewLabel
            key={index + prefix + value}
            prefix={prefix}
            value={value}
            htmlFor={`prompt-editor-${word.autocompleteType}-${index}-input`}
            onClick={() => {
              editorContextStore.getState().setGenerateToolEditorTab("Editor");
            }}
          />
        );
      }),
    );
  }, [promptTemplate]);

  return (
    <div className="w-full px-3 py-2 border border-zinc-800 rounded-md font-semibold">
      "{children}"
    </div>
  );
}

function UseCorrectColorSwitch() {
  const generateToolOverlayUsePoisson = editorContextStore(
    (state) => state.generateToolOverlayUsePoisson,
  );
  const generateToolOverlayUseCanny = editorContextStore(
    (state) => state.generateToolOverlayUsePoisson,
  );

  const correctColor = generateToolOverlayUsePoisson && generateToolOverlayUseCanny;

  const handeChekedChange = React.useCallback((value: boolean) => {
    editorContextStore.getState().setGenerateToolOverlayUsePoisson(value);
    editorContextStore.getState().setGenerateToolOverlayUseCanny(value);
  }, []);

  return (
    <PanelSwitchRow
      id="prompt-editor-correct-color-switch"
      className={dropdownContainerClassName}
      rootProps={{
        checked: correctColor,
        onCheckedChange: handeChekedChange,
      }}
      labelProps={{
        className: "flex flex-row items-center",
      }}
    >
      <span className="mr-2">Correct Color</span>
      <Tooltip
        triggerChildren={<QuestionMarkCircledIcon className="text-zinc-500" />}
        contentChildren={
          <div>
            Correct the color of the subject to make the lighting look more natural. This may modify
            the apperance of the product.
          </div>
        }
      />
    </PanelSwitchRow>
  );
}

export function PromptEditorForm() {
  const promptTemplate = editorContextStore((state) => state.generateToolPromptTemplate);
  const setPromptTemplate = editorContextStore((state) => state.setGenerateToolPromptTemplate);

  return (
    <>
      <FeatureFlag flagKey="BigGenerateReferenceImage">
        <ReferenceImage big />
      </FeatureFlag>
      <div className="w-full flex flex-row justify-start items-center font-semibold">
        Edit the the prompt in the form below.
      </div>
      <div className="h-2" />
      <form id="left-panel-prompt-editor-form" className="w-full flex flex-col">
        <PromptTemplateForm promptTemplate={promptTemplate} setPromptTemplate={setPromptTemplate} />
        <NumImages />
        <FeatureFlag not flagKey="BigGenerateReferenceImage">
          <ReferenceImage />
        </FeatureFlag>
        <UseCorrectColorSwitch />
        <GuidanceScale />
        <ColorControlStrength />
        <OutlineControlStrength />
      </form>
    </>
  );
}
