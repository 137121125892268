import { editorContextStore } from "contexts/editor-context";
import { handleClothImageUpdate } from "contexts/tryon-editor-context";
import {
  UiCloseMessageDialogEventHandler,
  UiDisplayMessageDialogEventHandler,
} from "@/core/common/types";
import { Editor } from "@/core/editor";
import { getImageElementFromFilesAsync } from "@/core/utils/image-utils";

export function getClothImageElementFromFiles({
  editor,
  files,
}: {
  editor?: Editor | null;
  files: FileList;
}) {
  editor = editor || editorContextStore.getState().editor;

  if (!editor) {
    return Promise.resolve(undefined);
  }

  return getImageElementFromFilesAsync(files).then((image) => {
    return new Promise<HTMLImageElement | undefined>((resolve) => {
      if (!editor) {
        return resolve(undefined);
      }

      handleClothImageUpdate();

      editor.emit<UiDisplayMessageDialogEventHandler>("ui:display-message-dialog", "upload-cloth", {
        image,
      });

      editor.once<UiCloseMessageDialogEventHandler>("ui:close-message-dialog", () => {
        resolve(image);
      });
    });
  });
}
