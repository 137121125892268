import React from "react";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { classNames } from "@/core/utils/classname-utils";

import styles from "./right-click-menu.module.css";
import { editorContextStore } from "contexts/editor-context";
import { RealTimeRenderSaveResultEventHandler } from "@/core/common/types";
import { useRealtTimeRegenerationCallback } from "components/canvas-frames/generation-frame";

export function EditorRightClickMenuRealTimeRenderResult() {
  const { isRendering, realtimeRegenerateCallback } = useRealtTimeRegenerationCallback();

  return (
    <>
      <ContextMenu.Item
        className={classNames(styles.ContextMenuItem)}
        onSelect={() => {
          editorContextStore
            .getState()
            .editor?.emit<RealTimeRenderSaveResultEventHandler>("realtime-render:save-result");
        }}
      >
        Save Result
      </ContextMenu.Item>
      <ContextMenu.Item
        disabled={isRendering}
        className={classNames(styles.ContextMenuItem)}
        onSelect={() => {
          realtimeRegenerateCallback();
        }}
      >
        Re-Generate
      </ContextMenu.Item>
    </>
  );
}
