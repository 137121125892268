import { CustomModelPostProcessActionType } from "@/backend/custom-model-post-process";
import { PaintBrushCursor } from "@/components/canvas-frames/paint-brush-cursor";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
} from "@/components/constants/class-names";
import { LeftPanelDropdownZIndex } from "@/components/constants/zIndex";
import { PaintMaskCanvasControllerPaintBrushType } from "@/components/editor/paint-mask-canvas-controller";
import {
  PaintBrushSizeAdjust,
  PaintMaskCanvasTaskBarButton,
  PaintMaskCanvasToolBarRedoButton,
  PaintMaskCanvasToolBarUndoButton,
  usePaintMaskCanvasController,
} from "@/components/editor/paint-mask-canvas-controller-utils";
import { PaintBrushIcon } from "@/components/icons/paint-brush-icon";
import { ScrollAreaContainer } from "@/components/scroll-area/scroll-area";
import { editorContextStore } from "@/contexts/editor-context";
import { getImageSizeFromCustomModelPredictionInput } from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { lerp } from "@/core/utils/number-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { ShortcutsUtils } from "@/core/utils/shortcuts-utils";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { NumberSlider } from "components/panels/panel-items/components/number-slider";
import { clamp } from "lodash";
import { EraserIcon, TrashIcon } from "lucide-react";
import React, { useImperativeHandle } from "react";
import { SimpleSpinner } from "../icons/simple-spinner";
import { displayUiMessage } from "../utils/display-message";
import { InfoBox } from "../utils/info-box";
import { Tooltip } from "../utils/tooltip";
import {
  imageEditorContainerClassName,
  mainPanelClassName,
  toolbarPanelClassName,
} from "./classnames";
import { CustomModelImageEditorBackButton } from "./custom-model-editor-back-button";
import {
  CustomModelImageEditorMode,
  useCustomModelImageEditorContext,
} from "./custom-model-image-editor-context";
import { useCustomModelCompareOutputContext } from "./custom-model-image-output-context";
import { CustomModelPromptEditor } from "./custom-model-prompt-editor";
import {
  CustomModelUpscaleStatus,
  useCustomModelUpscaleContext,
} from "./custom-model-upscale-creative-context";

const maxCreativity = 10.0;
interface CustomModelUpscaleCreativeImageEditorCanvasImperativeHandle {
  undo: () => void;
  redo: () => void;
  clearMask: () => void;
  saveCanvas: () => void;
  getCanvasMaskUrl: () => string | undefined;
}

const CustomModelUpscaleCreativeEditor = React.forwardRef(function CustomModelUpscaleCreativeEditor(
  {
    children,
    className = "",
    tabIndex = 0,
    brushColor = "#84cc16",
    ...props
  }: React.HTMLAttributes<HTMLDivElement> &
    React.PropsWithChildren & {
      brushColor?: string;
    },
  imperativeRef: React.ForwardedRef<CustomModelUpscaleCreativeImageEditorCanvasImperativeHandle>,
) {
  const {
    imageUrl,
    maskImageUrl,
    maskBrushSize,
    setMaskBrushSize,
    maskBrushType,
    setMaskBrushType,
  } = useCustomModelUpscaleContext();

  const {
    canvasContainerRef,
    canvasRef,
    maskCanvasRef,
    brushCursorRef,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onPointerEnter,
    onPointerLeave,
    onWheel,
    isCanvasMoving,
    isPointerOver,
    isBrushVisible,
    isPointerOverToolbarRef,
    undo,
    redo,
    numRedos,
    numUndos,
    clearMask,
    saveCanvas,
    getCanvasMaskUrl,
  } = usePaintMaskCanvasController({
    imageUrl,
    maskImageUrl,
    brushSize: maskBrushSize,
    brushType: maskBrushType,
    brushColor,
  });

  useImperativeHandle<
    CustomModelUpscaleCreativeImageEditorCanvasImperativeHandle,
    CustomModelUpscaleCreativeImageEditorCanvasImperativeHandle
  >(imperativeRef, () => ({
    undo,
    redo,
    clearMask,
    saveCanvas,
    getCanvasMaskUrl,
  }));

  return (
    <div
      {...props}
      tabIndex={tabIndex}
      ref={canvasContainerRef}
      className={classNames(
        "relative w-full h-full rounded-md overflow-hidden bg-zinc-800/50 focus-visible:outline focus-visible:outline-zinc-500/50",
        className,
      )}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onWheel={onWheel}
      onKeyDown={(e) => {
        const event = e.nativeEvent;
        if (ShortcutsUtils.isCtrlZ(event)) {
          undo();
        } else if (ShortcutsUtils.isCtrlShiftZ(event) || ShortcutsUtils.isCtrlY(event)) {
          redo();
        } else if (ShortcutsUtils.isCtrlS(event) && process.env.NODE_ENV === "development") {
          e.preventDefault();
          saveCanvas();
        }
      }}
    >
      <canvas ref={canvasRef} />
      <canvas
        ref={maskCanvasRef}
        className="absolute left-0 top-0 transition-opacity"
        style={{
          opacity: isCanvasMoving ? 0 : 0.5,
        }}
      />
      <PaintBrushCursor
        ref={brushCursorRef}
        className={
          maskBrushType === PaintMaskCanvasControllerPaintBrushType.Erase
            ? "bg-transparent"
            : "bg-lime-500/30"
        }
        style={{
          display: isBrushVisible ? "block" : "none",
          opacity: isPointerOver ? 1 : 0,
          width: `${maskBrushSize || 0}px`,
          height: `${maskBrushSize || 0}px`,
          zIndex: LeftPanelDropdownZIndex,
        }}
      />
      <div className="absolute left-0 bottom-0 p-2 w-full flex items-center justify-center pointer-events-none">
        <div
          className="relative w-fit px-1 py-1 rounded-lg flex flex-row items-center justify-center justify-items-stretch text-sm bg-zinc-800 shadow-md border border-zinc-700 transition-opacity pointer-events-auto divide-x divide-zinc-700"
          onPointerEnter={() => {
            // eslint-disable-next-line
            isPointerOverToolbarRef.current = true;
          }}
          onPointerLeave={() => {
            isPointerOverToolbarRef.current = false;
          }}
        >
          <div className="pr-1 grid grid-rows-1 grid-cols-2 items-center justify-center">
            <PaintMaskCanvasToolBarUndoButton
              isDisabled={numUndos <= 0}
              size={16}
              onClick={() => {
                undo();
              }}
            />
            <PaintMaskCanvasToolBarRedoButton
              isDisabled={numRedos <= 0}
              size={16}
              onClick={() => {
                redo();
              }}
            />
          </div>
          <div className="px-1 grid grid-rows-1 grid-cols-2 items-center justify-center gap-1">
            <PaintMaskCanvasTaskBarButton
              isActive={maskBrushType === PaintMaskCanvasControllerPaintBrushType.Paint}
              contentChildren={
                <div className="flex flex-col">
                  <div className="text-zinc-300 mb-1">Product mask paint tool</div>
                </div>
              }
              onClick={() => {
                setMaskBrushType(PaintMaskCanvasControllerPaintBrushType.Paint);
              }}
            >
              <PaintBrushIcon size={16} brushColor={brushColor} />
            </PaintMaskCanvasTaskBarButton>
            <PaintMaskCanvasTaskBarButton
              isActive={maskBrushType === PaintMaskCanvasControllerPaintBrushType.Erase}
              contentChildren={
                <div className="flex flex-col">
                  <div className="text-zinc-300 mb-1">Product mask erase tool</div>
                </div>
              }
              onClick={() => {
                setMaskBrushType(PaintMaskCanvasControllerPaintBrushType.Erase);
              }}
            >
              <EraserIcon size={16} />
            </PaintMaskCanvasTaskBarButton>
          </div>
          <div className="hidden xl:block px-1">
            <PaintBrushSizeAdjust brushSize={maskBrushSize} setBrushSize={setMaskBrushSize} />
          </div>
          <div className="hidden lg:block pl-1">
            <PaintMaskCanvasTaskBarButton
              contentChildren={
                <div className="flex flex-col">
                  <div className="text-zinc-300 mb-1">Clear all product mask</div>
                </div>
              }
              onClick={() => {
                clearMask();
              }}
            >
              <TrashIcon size={16} />
            </PaintMaskCanvasTaskBarButton>
          </div>
        </div>
      </div>
    </div>
  );
});

function CustomModelUpscaleStrengthSlider() {
  const { creativity, setCreativity } = useCustomModelUpscaleContext();

  return (
    <NumberSlider
      name={
        <Tooltip
          triggerChildren={
            <div className="mr-0 lg:min-w-[8rem] group flex flex-row items-center gap-2">
              <span className="truncate">Creativity</span>
              <QuestionMarkCircledIcon className="text-zinc-500 group-hover:text-zinc-300 transition-colors" />
            </div>
          }
          contentChildren={
            <div className="flex flex-col gap-2">
              <div>Creativity controls how much details to add to the image after upscaling.</div>
            </div>
          }
        />
      }
      value={creativity}
      setValue={setCreativity}
      min={0}
      max={maxCreativity}
      step={1}
    />
  );
}

function CustomModelUpscaleButton({
  canvasControllerRef,
}: {
  canvasControllerRef: {
    current: CustomModelUpscaleCreativeImageEditorCanvasImperativeHandle | null;
  };
}) {
  const { setModes, prediction } = useCustomModelImageEditorContext();

  const { status, setStatus, imageUrl, upscaleFactor, promptEditorState, creativity } =
    useCustomModelUpscaleContext();

  const { setActiveOutputImageIndex, setSourceImageUrl, setOutputImageUrls, setWidth, setHeight } =
    useCustomModelCompareOutputContext();

  const backend = editorContextStore((state) => state.backend);

  const handleUpscale = async () => {
    try {
      if (!backend) {
        return;
      }

      if (status !== CustomModelUpscaleStatus.Idle) {
        return;
      }

      setStatus(CustomModelUpscaleStatus.Loading);

      const preserveMaskImageUrl = canvasControllerRef.current?.getCanvasMaskUrl();

      const upscaleFactorClamped = clamp(upscaleFactor, 1, 4);

      const { text, json, scaleConfigs } = promptEditorState;

      const creativityPercent = creativity / maxCreativity;
      const creativityInternal = lerp(0.1, 0.6, creativityPercent);
      const resemblanceInternal = lerp(0.95, 0.65, creativityPercent);

      debugLog("Upscale args: ", {
        upscale_factor: upscaleFactorClamped,
        creativity: creativityInternal,
        resemblance: resemblanceInternal,
      });

      const response = await backend.customModelUpscaleCreative({
        type: CustomModelPostProcessActionType.ClarityUpscale,
        inputImageUrl: imageUrl,
        prompt: text,
        promptJson: json ?? undefined,
        customModelScaleConfigs: scaleConfigs,
        preserveMaskImageUrl,
        upscale_factor: upscaleFactorClamped,
        creativity: creativityInternal,
        resemblance: resemblanceInternal,
        guidance_scale: 4.0,
        num_inference_steps: 18,
      });

      if (!response.ok) {
        displayUiMessage(response.message, "error");
        return;
      }

      const predictionId = response.predictionId;

      if (!predictionId) {
        return;
      }

      const outputImagePaths = await new Promise<string[]>((resolve) => {
        backend.onCustomModelPredictionUpdate({
          predictionId,
          callback: (predictionItem) => {
            const output = predictionItem?.output;
            if (!output) {
              return;
            }

            resolve(output);
          },
        });
      });

      const imageUrls = (
        await Promise.all(
          outputImagePaths.map((path) => {
            return Assets.loadAssetFromPath({
              path,
              backend,
            });
          }),
        )
      ).filter(Boolean) as string[];

      if (imageUrls.length > 0) {
        const { width, height } = prediction?.input
          ? getImageSizeFromCustomModelPredictionInput(prediction.input)
          : {
              width: 1024,
              height: 1024,
            };

        setSourceImageUrl(imageUrl);

        setOutputImageUrls(imageUrls);

        setActiveOutputImageIndex(0);

        setWidth(width);

        setHeight(height);

        setModes((modes) => [...modes, CustomModelImageEditorMode.CompareOutputs]);
      } else {
        debugError("Cannot load output images because they are invalid. ", outputImagePaths);
      }
    } catch (error) {
      debugError("Error upscaling image. ", error);
    } finally {
      setStatus(CustomModelUpscaleStatus.Idle);
    }
  };

  return (
    <button
      className={classNames(
        status === CustomModelUpscaleStatus.Idle
          ? PrimaryButtonClassName
          : status === CustomModelUpscaleStatus.Loading
            ? PrimaryButtonClassNameLoading
            : PrimaryButtonClassNameDisabled,
      )}
      onClick={() => {
        handleUpscale();
      }}
    >
      {status === CustomModelUpscaleStatus.Loading ? (
        <div className="flex flex-row items-center justify-center gap-2">
          <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
          <span>Loading ...</span>
        </div>
      ) : (
        "Upscale"
      )}
    </button>
  );
}

function UpscaleCreativePrompEditor() {
  const { promptEditorState, setPromptEditorState } = useCustomModelUpscaleContext();

  return (
    <div className="group flex flex-col items-stretch gap-2">
      <div className="flex flex-row items-center gap-2">
        <div className="flex-1 min-w-0 text-zinc-500 group-hover:text-zinc-300 transition-colors truncate">
          Prompt
        </div>
      </div>
      <CustomModelPromptEditor
        editorState={promptEditorState}
        setEditorState={setPromptEditorState}
      />
    </div>
  );
}

export function CustomModelUpscaleCreative() {
  const canvasControllerRef =
    React.useRef<CustomModelUpscaleCreativeImageEditorCanvasImperativeHandle | null>(null);

  return (
    <div className={imageEditorContainerClassName}>
      <div className={classNames(mainPanelClassName, "md:flex-1")}>
        <CustomModelUpscaleCreativeEditor ref={canvasControllerRef} />
      </div>
      <ScrollAreaContainer className={classNames(toolbarPanelClassName)}>
        <div className="flex flex-col items-stretch gap-4 pb-8">
          <CustomModelImageEditorBackButton />
          <div className="flex flex-col items-stretch gap-4">
            <InfoBox defaultValue="" className="w-full">
              <ol className="list-decimal list-outside pl-5 flex flex-col gap-2 text-lime-200 text-sm leading-relaxed">
                <li>
                  (Optional) On the image canvas, paint the region that you want to preserve after
                  upscaling.
                </li>
                <li>
                  (Optional) Use the "Creativity" slider to control how much detail you would like
                  to add to the image.
                </li>
                <li>Click "Upscale" button and wait for the result to appear.</li>
              </ol>
            </InfoBox>
            <CustomModelUpscaleStrengthSlider />
            <UpscaleCreativePrompEditor />
            <CustomModelUpscaleButton canvasControllerRef={canvasControllerRef} />
          </div>
        </div>
      </ScrollAreaContainer>
    </div>
  );
}
