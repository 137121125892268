import { StateUpdater } from "@/core/common/types";
import React, { createContext, useContext, useState } from "react";

export enum CustomModelUpscaleFaceStatus {
  Idle = "Idle",
  Loading = "Loading",
}

interface CustomModelUpscaleFaceContextType {
  status: CustomModelUpscaleFaceStatus;
  setStatus: (value: StateUpdater<CustomModelUpscaleFaceStatus>) => void;

  imageUrl: string;
  setImageUrl: (value: StateUpdater<string>) => void;

  upscale: number;
  setUpscale: (value: StateUpdater<number>) => void;

  faceUpsample: boolean;
  setFaceUpsample: (value: StateUpdater<boolean>) => void;

  backgroundEnhance: boolean;
  setBackgroundEnhance: (value: StateUpdater<boolean>) => void;

  creativity: number;
  setCreativity: (value: StateUpdater<number>) => void;
}

const CustomModelUpscaleFaceContext = createContext<CustomModelUpscaleFaceContextType | undefined>(
  undefined,
);

export function useCustomModelUpscaleFaceContext() {
  const context = useContext(CustomModelUpscaleFaceContext);
  if (!context) {
    throw new Error(
      "useCustomModelUpscaleFaceContext must be used within CustomModelUpscaleFaceContextProvider",
    );
  }
  return context;
}

export function CustomModelUpscaleFaceContextProvider({ children }: React.PropsWithChildren) {
  const [status, setStatus] = useState(CustomModelUpscaleFaceStatus.Idle);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [upscale, setUpscale] = useState<number>(2);
  const [faceUpsample, setFaceUpsample] = useState<boolean>(true);
  const [backgroundEnhance, setBackgroundEnhance] = useState<boolean>(true);
  const [creativity, setCreativity] = useState<number>(5); // Range from 0 to 10

  return (
    <CustomModelUpscaleFaceContext.Provider
      value={{
        status,
        setStatus,
        imageUrl,
        setImageUrl,
        upscale,
        setUpscale,
        faceUpsample,
        setFaceUpsample,
        backgroundEnhance,
        setBackgroundEnhance,
        creativity,
        setCreativity,
      }}
    >
      {children}
    </CustomModelUpscaleFaceContext.Provider>
  );
}
