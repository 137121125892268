import React from "react";
import { MessageDialog } from "./message-dialog";
import { QuotaSubscribeDialogContent } from "./quota-subscribe-dialog";

export function RealTimeSubscribeDialog({ onClose }: { onClose?: () => void }) {
  return (
    <MessageDialog
      defaultOpen={true}
      onOpenChange={(value) => {
        if (!value) {
          onClose?.();
        }
      }}
      contentChildren={
        <QuotaSubscribeDialogContent
          title="No real-time quota left."
          header="Upgrade subscription to get more real-time quota."
        />
      }
    />
  );
}
