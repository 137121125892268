import React from "react";
// import * as Toast from '@radix-ui/react-toast';
import { classNames } from "@/core/utils/classname-utils";
import {
  DropdownClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameError,
  PrimaryButtonClassNameLoading,
} from "components/constants/class-names";
import { DisplayMessageType, UiDisplayMessageEventHandler } from "@/core/common/types";
import { editorContextStore } from "contexts/editor-context";
import { capitalizeFirstLetter } from "@/core/utils/string-utils";
import toast, { Toast, Toaster } from "react-hot-toast";
import { SimpleSpinner } from "components/icons/simple-spinner";

function getTitleFromMessageType(type: DisplayMessageType) {
  if (type === "error") {
    return "Error";
  }
  if (type === "info") {
    return "Note";
  }
  return "Action";
}

function getActionMessageFromMessageType(type: DisplayMessageType) {
  if (type === "error") {
    return "Got it";
  }
  if (type === "info") {
    return "Got it";
  }
  return "Yes";
}

type CustomMessageToastProps = {
  type: DisplayMessageType;
  title?: string;
  message?: string;
  actionMessage?: string;
  onClick?: () => void | Promise<void>;
};

/* @tw */
const buttonCommonClassName =
  "min-w-[60px] h-[2.5rem] flex items-center justify-center gap-2 box-border";

function ActionButton({
  onClick,
  actionMessage,
}: {
  actionMessage?: string;
  onClick?: () => void;
}) {
  return (
    <button onClick={onClick} className={classNames(PrimaryButtonClassName, buttonCommonClassName)}>
      {actionMessage}
    </button>
  );
}

function LoadingButton({ actionMessage = null }: { actionMessage?: string | null }) {
  return (
    <button className={classNames(PrimaryButtonClassNameLoading, buttonCommonClassName)}>
      <SimpleSpinner width={18} height={18} pathClassName="fill-lime-100" />
      {actionMessage}
    </button>
  );
}

function DoneButton() {
  return (
    <button className={classNames(PrimaryButtonClassNameDisabled, buttonCommonClassName)}>
      Done
    </button>
  );
}

function ErrorButton() {
  return (
    <button className={classNames(PrimaryButtonClassNameError, buttonCommonClassName)}>
      Error
    </button>
  );
}

function MessageToastContainer({
  type,
  title,
  message = "",
  visible,
  children,
}: CustomMessageToastProps & {
  children?: React.ReactNode;
  visible: boolean;
}) {
  title = title || getTitleFromMessageType(type);
  return (
    <div
      className={classNames(
        visible ? "animate-enter" : "animate-leave",
        DropdownClassName,
        "flex flex-row min-w-[200px] max-w-[50vw] bg-black shadow-lg-center",
      )}
    >
      <div className="mr-4 flex-1 flex flex-col items-start">
        <p className="text-sm font-medium text-zinc-100">
          {capitalizeFirstLetter(title ?? "Note")}
        </p>
        <p className="mt-1 text-sm text-zinc-300">{message}</p>
      </div>
      <div className="flex items-center justify-center">{children}</div>
    </div>
  );
}

function CustomMessageToast({
  toast: t,
  actionMessage,
  onClick,
  ...props
}: CustomMessageToastProps & {
  toast: Toast;
}) {
  const [isRemoved, setIsRemoved] = React.useState(false);
  actionMessage = actionMessage || getActionMessageFromMessageType(props.type);
  return (
    <MessageToastContainer {...props} visible={t.visible}>
      {isRemoved ? (
        <DoneButton />
      ) : (
        <ActionButton
          onClick={() => {
            Promise.resolve(onClick?.())
              .catch(console.error)
              .finally(() => {
                toast.remove(t.id);
                setIsRemoved(true);
              });
          }}
          actionMessage={actionMessage}
        />
      )}
    </MessageToastContainer>
  );
}

function createActionMessageToast(
  props: CustomMessageToastProps,
  options:
    | Partial<
        Pick<
          Toast,
          | "style"
          | "className"
          | "id"
          | "icon"
          | "duration"
          | "ariaProps"
          | "position"
          | "iconTheme"
        >
      >
    | undefined = {
    position: "bottom-center",
  },
) {
  const type = props.type;
  const duration = getDefaultDuration(type);
  const idRef: { current?: string } = { current: undefined };
  const actionMessage = props.actionMessage || getActionMessageFromMessageType(props.type);

  const onClick = () => {
    const id = idRef.current;
    if (!id) {
      return;
    }

    if (!props.onClick) {
      return;
    }

    toast.custom(
      (t) => (
        <MessageToastContainer {...props} visible={t.visible}>
          <LoadingButton actionMessage={actionMessage} />
        </MessageToastContainer>
      ),
      {
        ...options,
        duration: Infinity,
        id,
      },
    );

    Promise.resolve(props.onClick())
      .then(() => {
        toast.custom(
          (t) => (
            <MessageToastContainer {...props} visible={t.visible}>
              <DoneButton />
            </MessageToastContainer>
          ),
          {
            ...options,
            duration: 100,
            id,
          },
        );
      })
      .catch((e) => {
        const message = e?.message || JSON.stringify(e) || "Unknown error";
        toast.custom(
          (t) => (
            <MessageToastContainer {...props} title="Error" message={message} visible={t.visible}>
              <ErrorButton />
            </MessageToastContainer>
          ),
          {
            ...options,
            duration: 4000,
            id,
          },
        );
      });
  };

  idRef.current = toast.custom(
    (t) => (
      <MessageToastContainer {...props} visible={t.visible}>
        <ActionButton onClick={onClick} actionMessage={actionMessage} />
      </MessageToastContainer>
    ),
    {
      ...options,
      duration,
    },
  );
}

function getDefaultDuration(type: DisplayMessageType) {
  if (type === "action") {
    return 8000;
  }
  if (type === "error") {
    return 4000;
  }
  return 2000;
}

function createCustomToast(
  props: CustomMessageToastProps,
  options:
    | Partial<
        Pick<
          Toast,
          | "style"
          | "className"
          | "id"
          | "icon"
          | "duration"
          | "ariaProps"
          | "position"
          | "iconTheme"
        >
      >
    | undefined = {
    position: "bottom-center",
  },
) {
  if (props.type === "action" && typeof props.onClick === "function") {
    return createActionMessageToast(props, options);
  }

  toast.custom((t) => <CustomMessageToast toast={t} {...props} />, {
    duration: getDefaultDuration(props.type),
    ...options,
  });
}

export function MessageToast() {
  const eventEmitter = editorContextStore((state) => state.eventEmitter);

  const handleDisplayMessage = React.useCallback(
    (
      type: DisplayMessageType,
      message: string,
      title?: string,
      actionMessage?: string,
      onAction?: () => void | Promise<void>,
    ) => {
      createCustomToast({
        type,
        title,
        message,
        actionMessage,
        onClick: onAction,
      });
    },
    [],
  );

  React.useEffect(() => {
    eventEmitter?.on<UiDisplayMessageEventHandler>("ui:display-message", handleDisplayMessage);
    return () => {
      eventEmitter?.off<UiDisplayMessageEventHandler>("ui:display-message", handleDisplayMessage);
    };
  }, [eventEmitter, handleDisplayMessage]);

  return <Toaster />;
}
