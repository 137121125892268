// Enums and Type Guards

// Enum for Supported Models
export enum ModelEnum {
  AnthropicsClaude35Sonnet = "anthropic/claude-3.5-sonnet",
  AnthropicsClaude3Haiku = "anthropic/claude-3-haiku",
  GoogleGeminiPro15 = "google/gemini-pro-1.5",
  GoogleGeminiFlash15 = "google/gemini-flash-1.5",
  GoogleGeminiFlash158B = "google/gemini-flash-1.5-8b",
  MetaLlama32Instruct1B = "meta-llama/llama-3.2-1b-instruct",
  MetaLlama32Instruct3B = "meta-llama/llama-3.2-3b-instruct",
  MetaLlama31Instruct8B = "meta-llama/llama-3.1-8b-instruct",
  MetaLlama31Instruct70B = "meta-llama/llama-3.1-70b-instruct",
  OpenAIGPT4OMini = "openai/gpt-4o-mini",
  OpenAIGPT4O = "openai/gpt-4o",
}

// Type Guard for ModelEnum
export function isModelEnum(value: any): value is ModelEnum {
  return Object.values(ModelEnum).includes(value);
}

// Union String Type for Request Status
export type RequestStatus = "QUEUED" | "IN_PROGRESS" | "COMPLETED" | "FAILED" | "CANCELLED";

// Array of valid RequestStatus values for type guarding
const validRequestStatuses: RequestStatus[] = [
  "QUEUED",
  "IN_PROGRESS",
  "COMPLETED",
  "FAILED",
  "CANCELLED",
];

// Type Guard for RequestStatus
export function isRequestStatus(value: any): value is RequestStatus {
  return validRequestStatuses.includes(value as RequestStatus);
}

// Interfaces for Arguments

// Input Schema
export interface FalAnyLLMInput {
  model?: ModelEnum; // Optional, defaults to "google/gemini-flash-1.5"
  prompt: string;
  system_prompt?: string;
}

// Log Interface
export interface Log {
  message: string;
  timestamp?: string; // ISO string
}

// Update Interface for Queue Updates
export interface QueueUpdate {
  status: RequestStatus;
  logs: Log[];
}

// Arguments Interface
export interface FalAnyLLMArgs {
  input: FalAnyLLMInput;
  logs?: boolean;
  webhookUrl?: string;
}

// Type Guard for FalAnyLLMInput
export function isFalAnyLLMInput(value: any): value is FalAnyLLMInput {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  const { model, prompt, system_prompt } = value;

  // Validate 'prompt'
  if (typeof prompt !== "string" || prompt.trim() === "") {
    return false;
  }

  // Validate 'model' if present
  if (model !== undefined && !isModelEnum(model)) {
    return false;
  }

  // Validate 'system_prompt' if present
  if (system_prompt !== undefined && typeof system_prompt !== "string") {
    return false;
  }

  return true;
}

// Type Guard for FalAnyLLMArgs
export function isFalAnyLLMArgs(value: any): value is FalAnyLLMArgs {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  const { input, logs, webhookUrl, onQueueUpdate } = value;

  // Validate 'input'
  if (!isFalAnyLLMInput(input)) {
    return false;
  }

  // Validate 'logs' if present
  if (logs !== undefined && typeof logs !== "boolean") {
    return false;
  }

  // Validate 'webhookUrl' if present
  if (webhookUrl !== undefined) {
    if (typeof webhookUrl !== "string") {
      return false;
    }
    try {
      new URL(webhookUrl);
    } catch {
      return false;
    }
  }

  // Validate 'onQueueUpdate' if present
  if (onQueueUpdate !== undefined && typeof onQueueUpdate !== "function") {
    return false;
  }

  return true;
}

// Interfaces for Response

export type FalAnyLLMResponse = string;

// Type Guards for Response
export function isFalAnyLLMResponse(response: any): response is FalAnyLLMResponse {
  return typeof response === "string";
}
