import { PublicUserRoles } from "./user-roles";
import { DocVisibility, isDocVisibility } from "./doc-visibility";
import { Timestamp } from "firebase/firestore";

export interface ReferenceHumanDoc {
  id: string;
  caption: string;
  roles: PublicUserRoles;
  faceImageStoragePath: string;
  visibility: DocVisibility;
  isDeleted: boolean;
  timeCreated: Timestamp;
  timeModified: Timestamp;
}

export function isReferenceHumanDoc(obj: any): obj is ReferenceHumanDoc {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.id === "string" &&
    typeof obj.caption === "string" &&
    obj.roles &&
    typeof obj.faceImageStoragePath === "string" &&
    isDocVisibility(obj.visibility) &&
    typeof obj.isDeleted === "boolean"
  );
}

export interface UploadReferenceHumanArgs {
  faceImageStoragePath: string;
}

export type UploadReferenceHumanResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      referenceHumanDoc: ReferenceHumanDoc;
    };
