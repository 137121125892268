import { EditorActiveObject } from "@/core/common/interfaces";
import { UpdateObjectPropsEventHandler } from "@/core/common/types";
import {
  StaticImageElement2dType,
  getStaticImageElement2dType,
} from "@/core/common/types/elements";
import Objects from "@/core/controllers/objects";
import { argmin } from "@/core/utils/array-utils";
import { classNames } from "@/core/utils/classname-utils";
import { distanceSquared } from "@/core/utils/fabric";
import { isStaticImageObjectGenerated } from "@/core/utils/type-guards";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { FloatTagZIndex } from "components-mobile/constants/zIndex";
import { LeftPanelItemType } from "components/constants/editor-options";
import { EDITOR_FLOAT_TAG_CONTAINER_ID } from "components/constants/ids";
import { mergeRefs } from "components/utils/merge-refs";
import { editorContextStore, editorContextVanillaStore } from "contexts/editor-context";
import { fabric } from "fabric";
import { IEvent } from "fabric/fabric-impl";
import { noop } from "lodash";
import React from "react";
import styles from "./float-tag.module.css";
import { ObjectFloatTag } from "./object-float-tag";

function canObjectHaveTag(object: EditorActiveObject): object is fabric.Object {
  return (
    object != null &&
    //@ts-ignore
    Objects.isMultiSelectableObjects(object)
  );
}

function doesLeftPanelsAllowFloatTag(leftPanels: LeftPanelItemType[]) {
  if (leftPanels.length <= 0) {
    return true;
  }

  const lastPanel = leftPanels[leftPanels.length - 1];
  return lastPanel !== "TransformProps3d";
}

function useIsFloatTagVisible() {
  const activeLeftPanels = editorContextStore((state) => state.activeLeftPanels);
  return React.useMemo(() => doesLeftPanelsAllowFloatTag(activeLeftPanels), [activeLeftPanels]);
}

function doesObjectHaveCenterTag(object: any) {
  return isStaticImageObjectGenerated(object);
}

const CenterFloatTag = React.forwardRef(function CenterFloatTag(
  {
    className = "",
    opacity = 1,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    opacity?: number;
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerWidthRef = React.useRef(0);
  const isVisibleRef = React.useRef(false);
  const isPointerOverRef = React.useRef(false);
  const isDropdownOpenRef = React.useRef(false);
  const hoveredObjectRef = React.useRef<fabric.Object | undefined>();

  const editor = editorContextStore((state) => state.editor);

  const setVisibleInternal = React.useCallback(
    (isVisible: boolean) => {
      if (!containerRef.current) {
        isVisibleRef.current = false;
        return;
      }

      isVisible = isDropdownOpenRef.current ? true : isVisible;

      isVisibleRef.current = isVisible;

      containerRef.current.style.opacity = isVisible ? opacity.toString() : "0";
      containerRef.current.style.pointerEvents = isVisible ? "auto" : "none";
    },
    [opacity],
  );

  const updatePosition = React.useCallback((object?: fabric.Object) => {
    if (!containerRef.current || !isVisibleRef.current || !object) {
      return;
    }

    object.setCoords();
    const oCoords = object.oCoords;

    if (!oCoords) {
      return;
    }

    const tl = oCoords.tl;
    const tr = oCoords.tr;
    const bl = oCoords.bl;
    const br = oCoords.br;
    const centerX = (tl.x + tr.x + bl.x + br.x) / 4;
    const centerY = (tl.y + tr.y + bl.y + br.y) / 4;
    const bottomY = Math.max(tl.y, tr.y, bl.y, br.y);

    containerRef.current.style.left = `${centerX - containerWidthRef.current / 2}px`;
    containerRef.current.style.top = `${centerY + (bottomY - centerY) * 0.7}px`;

    hoveredObjectRef.current = object;
  }, []);

  React.useEffect(() => {
    if (!editor) {
      return;
    }
    if (!containerRef.current) {
      return;
    }

    containerWidthRef.current = containerRef.current.getBoundingClientRect().width;

    const handleSelectionCleared = () => {
      setVisibleInternal(false);
    };

    const handleMouseDown = () => {
      setVisibleInternal(false);
    };

    const handleMouseWheel = () => {
      setVisibleInternal(false);
    };

    const handleMouseOver = (e: IEvent<Event>) => {
      const object = e.target;
      if (!doesObjectHaveCenterTag(object)) {
        setVisibleInternal(false);
        return;
      }

      setVisibleInternal(true);
      updatePosition(e.target);
    };

    const handleMouseOut = (e: IEvent<Event>) => {
      if (isPointerOverRef.current) {
        setVisibleInternal(true);
      } else {
        setVisibleInternal(false);
      }
    };

    const handleBeforeTransform = () => {
      setVisibleInternal(false);
    };

    editor.canvas.canvas.on("selection:cleared", handleSelectionCleared);
    editor.canvas.canvas.on("mouse:down", handleMouseDown);
    editor.canvas.canvas.on("mouse:wheel", handleMouseWheel);
    editor.canvas.canvas.on("mouse:over", handleMouseOver);
    editor.canvas.canvas.on("mouse:out", handleMouseOut);
    editor.canvas.canvas.on("before:transform", handleBeforeTransform);

    return () => {
      editor.canvas.canvas.off("selection:cleared", handleSelectionCleared);
      editor.canvas.canvas.off("mouse:down", handleMouseDown);
      editor.canvas.canvas.off("mouse:wheel", handleMouseWheel);
      editor.canvas.canvas.off("mouse:over", handleMouseOver);
      editor.canvas.canvas.off("mouse:out", handleMouseOut);
      editor.canvas.canvas.off("before:transform", handleBeforeTransform);
    };
  }, [editor, setVisibleInternal, updatePosition]);

  return (
    <DropdownMenu.Root
      defaultOpen={false}
      onOpenChange={(value) => {
        isDropdownOpenRef.current = value;
      }}
    >
      <DropdownMenu.Trigger asChild>
        <div
          // eslint-disable-next-line
          ref={mergeRefs([forwardedRef, containerRef])}
          {...props}
          className={classNames(
            "absolute min-w-[150px] p-1 rounded-full flex flex-row items-center justify-center text-xs bg-zinc-800/70 hover:bg-zinc-800 shadow-md border border-zinc-700 transition-opacity select-none cursor-pointer",
            className,
          )}
          style={{
            zIndex: FloatTagZIndex,
            opacity: 0,
          }}
          onPointerOver={() => {
            isPointerOverRef.current = true;
          }}
          onPointerLeave={() => {
            isPointerOverRef.current = false;
            setVisibleInternal(false);
          }}
        >
          Improve the image?
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="bg-zinc-900 border border-zinc-800 text-sm text-slate-700 rounded-lg shadow-md"
        sideOffset={4}
        style={{
          zIndex: FloatTagZIndex + 1,
        }}
      >
        <DropdownMenu.Item
          className={`${styles.dropdownItem} ${styles.dropdownSelectable} text-zinc-300 hover:bg-zinc-800`}
          onClick={(e) => {
            if (!hoveredObjectRef.current) {
              return;
            }

            const { setActiveLeftPanels, setEditingObjectId } = editorContextStore.getState();

            setEditingObjectId(hoveredObjectRef.current.id);

            setActiveLeftPanels((activeLeftPanels) => {
              return [...activeLeftPanels, "UpscaleV2"];
            });
          }}
        >
          <span className="mx-2">Upscale Image</span>
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={`${styles.dropdownItem} ${styles.dropdownSelectable} text-zinc-300 hover:bg-zinc-800`}
          onClick={(e) => {
            if (!hoveredObjectRef.current) {
              return;
            }

            const { setActiveLeftPanels, setEditingObjectId } = editorContextStore.getState();

            setEditingObjectId(hoveredObjectRef.current.id);

            setActiveLeftPanels((activeLeftPanels) => {
              return [...activeLeftPanels, "RegenerateProduct"];
            });
          }}
        >
          <span className="mx-2">Regenerate product</span>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
});

const computeVectorDisplacement = (
  tl: fabric.Point,
  bl: fabric.Point,
  scale: number,
): fabric.Point => {
  // Vector subtraction (tl - bl)
  const vectorX = tl.x - bl.x;
  const vectorY = tl.y - bl.y;

  // Magnitude of the vector (tl - bl)
  const magnitude = Math.sqrt(vectorX * vectorX + vectorY * vectorY);

  if (magnitude === 0) {
    return tl;
  }

  // Normalize (tl - bl) and scale
  const normalizedAndScaledX = (vectorX / magnitude) * scale;
  const normalizedAndScaledY = (vectorY / magnitude) * scale;

  // Compute the new point based on tl and the displacement
  const newX = tl.x + normalizedAndScaledX;
  const newY = tl.y + normalizedAndScaledY;

  return new fabric.Point(newX, newY);
};

const TopLeftFloatTagHeightPx = 32;

function BottomFloatTag() {
  const editor = editorContextStore((state) => state.editor);
  const activeObjectRef = React.useRef<EditorActiveObject>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerVisibleRef = React.useRef(false);
  const isFloatTagVisible = useIsFloatTagVisible();

  const topLeftFloatTagRef = React.useRef<HTMLDivElement | null>(null);
  const bottomFloatTagRef = React.useRef<HTMLDivElement>(null);
  const bottomFloatTagWidthRef = React.useRef(0);
  const [staticImageObjectType, setStaticImageObjectType] = React.useState<
    StaticImageElement2dType | undefined
  >();

  const ignoreUpdatePropEventRef = React.useRef(false);

  React.useEffect(() => {
    const handleUpdateObjectPropsEvent: UpdateObjectPropsEventHandler["handler"] = ({
      objectId,
    }) => {
      if (ignoreUpdatePropEventRef.current) {
        ignoreUpdatePropEventRef.current = false;

        return;
      }

      if (!activeObjectRef.current || objectId !== activeObjectRef.current?.id) {
        return;
      }

      setStaticImageObjectType(getStaticImageElement2dType(activeObjectRef.current));
    };

    editor?.on<UpdateObjectPropsEventHandler>("object:update-props", handleUpdateObjectPropsEvent);

    return () => {
      editor?.off<UpdateObjectPropsEventHandler>(
        "object:update-props",
        handleUpdateObjectPropsEvent,
      );
    };
  }, [editor]);

  const updatePosition = React.useCallback(() => {
    if (
      !containerVisibleRef.current ||
      !containerRef.current ||
      !activeObjectRef.current ||
      !bottomFloatTagRef.current ||
      !topLeftFloatTagRef.current
    ) {
      return;
    }
    const oCoords = activeObjectRef.current.oCoords;
    if (oCoords) {
      const tl = oCoords.tl;
      const tr = oCoords.tr;
      const bl = oCoords.bl;
      const br = oCoords.br;
      const centerX = (tl.x + tr.x + bl.x + br.x) / 4;
      const centerY = (tl.y + tr.y + bl.y + br.y) / 4;

      const bottomY = Math.max(tl.y, tr.y, bl.y, br.y);

      const handlePosition = computeVectorDisplacement(tl, bl, 50);

      const bottomTagPosition = new fabric.Point(centerX, Math.max(bottomY, handlePosition.y));

      const leftMostPoint =
        argmin([tl, tr, bl, br], (a, b) => {
          const centerA = a.x - centerX;
          const centerB = b.x - centerX;

          if (centerA > 0 && centerB < 0) {
            return false;
          } else if (centerA < 0 && centerB > 0) {
            return true;
          }

          const distanceA = distanceSquared(a, bottomTagPosition);
          const distanceB = distanceSquared(b, bottomTagPosition);

          return distanceA > distanceB;

          // // Primary comparison based on the 'x' property
          // if (a.x !== b.x) {
          //     return a.x < b.x;
          // }
          // // Secondary comparison based on the 'y' property when 'x' values are equal
          // // Note: To sort by 'y' in descending order when 'x' values are equal, we subtract 'a.y' from 'b.y'
          // // console.log(`Values are close`);
          // return a.y < b.y;
        }) || tl;

      containerRef.current.style.left = `${centerX}px`;
      containerRef.current.style.top = `${centerY}px`;

      topLeftFloatTagRef.current.style.left = `${leftMostPoint.x - centerX + 6}px`;
      topLeftFloatTagRef.current.style.top = `${leftMostPoint.y - centerY - TopLeftFloatTagHeightPx}px`;

      bottomFloatTagRef.current.style.left = `${-bottomFloatTagWidthRef.current / 2}px`;
      bottomFloatTagRef.current.style.top = `${Math.max(bottomY, handlePosition.y) - centerY + 18}px`;
    }
  }, []);

  const setActiveObject = React.useCallback((activeObject: EditorActiveObject) => {
    activeObjectRef.current = activeObject;
  }, []);

  const setVisibleInternal = React.useCallback(
    (isVisible: boolean) => {
      if (containerRef.current && isVisible !== containerVisibleRef.current) {
        containerVisibleRef.current = isVisible;
        containerRef.current.style.opacity = isVisible ? "1" : "0";
        containerRef.current.style.pointerEvents = isVisible ? "auto" : "none";
        if (!isVisible) {
          // activeObjectRef.current = undefined;
        } else {
          updatePosition();
        }
      }
    },
    [updatePosition],
  );

  const setVisible = React.useCallback(
    (isVisible: boolean) => {
      if (!isFloatTagVisible) {
        isVisible = false;
      }
      setVisibleInternal(isVisible);
    },
    [isFloatTagVisible, setVisibleInternal],
  );

  React.useEffect(() => {
    if (!isFloatTagVisible) {
      setVisibleInternal(false);
    }
  }, [isFloatTagVisible, setVisibleInternal]);

  const handleBottomFloatTagMount = React.useCallback(() => {
    if (!bottomFloatTagRef.current) {
      return;
    }
    const clientRect = bottomFloatTagRef.current.getBoundingClientRect();
    if (!clientRect) {
      return;
    }
    const { width = 0 } = clientRect;

    bottomFloatTagWidthRef.current = width;
    updatePosition();
    setVisible(true);
  }, [updatePosition, setVisible]);

  const handleComponentUnmount = noop;

  React.useEffect(() => {
    if (!editor) {
      return;
    }
    const handleActiveObjectChange = (activeObject: EditorActiveObject) => {
      if (!canObjectHaveTag(activeObject)) {
        setVisible(false);
        return;
      }
      setActiveObject(activeObject);
      setVisible(true);
    };

    const unsubscribeToStateChanges = editorContextVanillaStore.subscribe(
      (state) => state.activeObject?.id,
      (activeObjectId) => {
        if (!activeObjectId) {
          handleActiveObjectChange(null);
          setVisible(false);
          setStaticImageObjectType(undefined);
          return;
        }
        const object = editor.objects.findOneById(activeObjectId);
        handleActiveObjectChange(object);
        setStaticImageObjectType(getStaticImageElement2dType(object));
      },
    );

    const handleSelectionCleared = () => {
      setVisible(false);
    };
    const handleBeforeRender = () => {
      updatePosition();
    };
    const handleBeforeTransform = () => {
      setVisible(false);
    };

    editor.canvas.canvas.on("selection:cleared", handleSelectionCleared);
    editor.canvas.canvas.on("before:render", handleBeforeRender);
    editor.canvas.canvas.on("before:transform", handleBeforeTransform);
    return () => {
      editor.canvas.canvas.off("selection:cleared", handleSelectionCleared);
      editor.canvas.canvas.off("before:render", handleBeforeRender);
      editor.canvas.canvas.off("before:transform", handleBeforeTransform);
      unsubscribeToStateChanges?.();
    };
  }, [editor, setVisible, setActiveObject, updatePosition]);

  return (
    <div
      ref={containerRef}
      id={EDITOR_FLOAT_TAG_CONTAINER_ID}
      className="absolute"
      style={{
        display: "flex",
        zIndex: FloatTagZIndex,
        opacity: 0,
        pointerEvents: "none",
      }}
    >
      <div
        ref={topLeftFloatTagRef}
        className="absolute px-1 py-1 rounded-md flex flex-row items-center justify-center text-sm"
      ></div>
      <div
        ref={bottomFloatTagRef}
        className="absolute px-1 py-1 rounded-md flex flex-row items-center justify-center text-sm bg-zinc-800 shadow-md border border-zinc-700 transition-opacity"
      >
        <ObjectFloatTag
          onComponentMount={handleBottomFloatTagMount}
          onComponentUnmount={handleComponentUnmount}
        />
      </div>
    </div>
  );
}

export const EditorFloatTag = React.memo(function EditorFloatTag() {
  return (
    <>
      <CenterFloatTag />
      <BottomFloatTag />
    </>
  );
});
