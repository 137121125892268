import { editorContextStore } from "contexts/editor-context";
import React from "react";
import { Navigate } from "../components/navigate";

import { isStaticImageObject } from "@/core/utils/type-guards";
import { OutpaintContextProvider, useOutpaintContext } from "contexts/outpaint-context";
import {
  addOutpaintImageToCanvas,
  OutpaintObjectDialog,
} from "components/popup/message-dialog/outpaint-dialog";
import {
  InputBoxClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { OutpaintStatus } from "@/core/common/types/outpaint";
import { classNames } from "@/core/utils/classname-utils";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  RedoOutpaintImageEventHandler,
  ResetOutpaintImageEventHandler,
  SetOutpaintImageAspectRatioEventHandler,
  StartOutpaintImageJobEventHandler,
  UndoOutpaintImageEventHandler,
} from "@/core/common/types";
import { DropdownOptionItem, DropdownOptionsWithTrigger } from "components/utils/dropdown-options";
import { debugError } from "@/core/utils/print-utilts";
import { Expand, Info, Plus, Redo, RefreshCcw, Undo } from "lucide-react";
import { removeLastFromImmutableList } from "@/core/utils/array-utils";

enum AspectRatio {
  ONE_TO_ONE = "1:1",
  THREE_TO_FOUR = "3:4",
  FOUR_TO_THREE = "4:3",
  SIXTEEN_TO_NINE = "16:9",
  NINE_TO_SIXTEEN = "9:16",
  CUSTOM = "custom",
}

const aspectRatioOptions: Record<string, DropdownOptionItem<AspectRatio>> = {
  "1:1": {
    name: "1:1",
    value: AspectRatio.ONE_TO_ONE,
  },
  "3:4": {
    name: "3:4",
    value: AspectRatio.THREE_TO_FOUR,
  },
  "4:3": {
    name: "4:3",
    value: AspectRatio.FOUR_TO_THREE,
  },
  "16:9": {
    name: "16:9",
    value: AspectRatio.SIXTEEN_TO_NINE,
  },
  "9:16": {
    name: "9:16",
    value: AspectRatio.NINE_TO_SIXTEEN,
  },
  custom: {
    name: "Custom",
    value: AspectRatio.CUSTOM,
  },
};

function getAspectRatioEnum(width: number, height: number): AspectRatio {
  if (width <= 0 || height <= 0) {
    debugError("Width and height must be positive numbers.");
    return AspectRatio.CUSTOM;
  }

  const ratio = width / height;
  const tolerance = 0.01;

  const aspectRatios: [AspectRatio, number][] = [
    [AspectRatio.ONE_TO_ONE, 1],
    [AspectRatio.THREE_TO_FOUR, 3 / 4],
    [AspectRatio.FOUR_TO_THREE, 4 / 3],
    [AspectRatio.SIXTEEN_TO_NINE, 16 / 9],
    [AspectRatio.NINE_TO_SIXTEEN, 9 / 16],
  ];

  for (const [aspectRatio, value] of aspectRatios) {
    if (Math.abs(ratio - value) < tolerance) {
      return aspectRatio;
    }
  }

  return AspectRatio.CUSTOM;
}

function getAspectRatioValue(aspectRatio: AspectRatio): number {
  const ratioMap: Record<AspectRatio, number> = {
    [AspectRatio.ONE_TO_ONE]: 1,
    [AspectRatio.THREE_TO_FOUR]: 3 / 4,
    [AspectRatio.FOUR_TO_THREE]: 4 / 3,
    [AspectRatio.SIXTEEN_TO_NINE]: 16 / 9,
    [AspectRatio.NINE_TO_SIXTEEN]: 9 / 16,
    [AspectRatio.CUSTOM]: -1, // Return -1 or handle custom aspect ratio as needed
  };

  return ratioMap[aspectRatio];
}

const HistoryButtonClassName = classNames(
  SecondaryButtonClassNameInactive,
  "group flex-1 flex flex-row items-center gap-2",
);

const HistoryButtonIconClassName = classNames(
  "text-zinc-500 group-hover:text-lime-500 transition-colors",
);

function OutpaintImageInner() {
  const eventEmitter = editorContextStore((state) => state.eventEmitter);
  const activeObject = editorContextStore((state) => state.activeObject);

  const {
    status,
    setStatus,
    outpaintWidth,
    outpaintHeight,
    outputImageUrls,
    setOutpaintWidth,
    setOutpaintHeight,
  } = useOutpaintContext();

  const image = React.useMemo(
    () => (isStaticImageObject(activeObject) ? activeObject.getSrc() : undefined),
    [activeObject],
  );

  const [widthValue, setWidthValue] = React.useState("");
  const [heightValue, setHeightValue] = React.useState("");

  React.useEffect(() => {
    setWidthValue(outpaintWidth.toString());
  }, [outpaintWidth]);

  React.useEffect(() => {
    setHeightValue(outpaintHeight.toString());
  }, [outpaintHeight]);

  const aspectRatio = React.useMemo(() => {
    return getAspectRatioEnum(outpaintWidth, outpaintHeight);
  }, [outpaintWidth, outpaintHeight]);

  const hasOutputImage = React.useMemo(() => outputImageUrls.length > 0, [outputImageUrls]);

  return (
    <div className="flex flex-col gap-2">
      <Navigate />
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-3 items-center gap-1 min-h-[38px]">
          <button
            className={HistoryButtonClassName}
            onClick={() => {
              eventEmitter.emit<UndoOutpaintImageEventHandler>("outpaint-image:undo");
            }}
          >
            <Undo className={HistoryButtonIconClassName} size={14} />
            <span>Undo</span>
          </button>
          <button
            className={HistoryButtonClassName}
            onClick={() => {
              eventEmitter.emit<RedoOutpaintImageEventHandler>("outpaint-image:redo");
            }}
          >
            <Redo className={HistoryButtonIconClassName} size={14} />
            <span>Redo</span>
          </button>
          <button
            className={HistoryButtonClassName}
            onClick={() => {
              eventEmitter.emit<ResetOutpaintImageEventHandler>("outpaint-image:reset");
            }}
          >
            <RefreshCcw className={HistoryButtonIconClassName} size={14} />
            <span>Reset</span>
          </button>
        </div>
        <div className="flex flex-row items-center gap-1 min-h-[38px]">
          <div className="flex-1 truncate font-semibold select-none">Aspect Ratio</div>
          <DropdownOptionsWithTrigger
            value={aspectRatio}
            options={aspectRatioOptions}
            onSelectItem={(options) => {
              if (!options || options.disabled === true) {
                return;
              }
              // setAspectRatio(options.value);

              if (options.value === AspectRatio.CUSTOM) {
                return;
              }

              eventEmitter.emit<SetOutpaintImageAspectRatioEventHandler>(
                "outpaint-image:set-aspect-ratio",
                getAspectRatioValue(options.value),
              );
            }}
          />
        </div>
        <div className="flex flex-row items-center gap-1">
          <div className="flex-1 truncate font-semibold select-none">Size</div>
          <div className="relative flex flex-row items-center">
            <label htmlFor="outpaint-image-width" className="absolute left-2 text-sm text-zinc-500">
              W
            </label>
            <input
              id="outpaint-image-width"
              className={classNames(InputBoxClassName, "w-[72px] pl-[24px]")}
              value={widthValue}
              onChange={(e) => setWidthValue(e.currentTarget.value)}
              onBlur={(e) => {
                const value = parseInt(widthValue);
                if (isNaN(value)) {
                  setWidthValue(outpaintWidth.toString());
                  return;
                }

                setOutpaintWidth(value);
              }}
            />
          </div>
          <Cross2Icon className="hidden md:block text-zinc-700" />
          <div className="relative flex flex-row items-center">
            <label
              htmlFor="outpaint-image-height"
              className="absolute left-2 text-sm text-zinc-500"
            >
              H
            </label>
            <input
              id="outpaint-image-height"
              className={classNames(InputBoxClassName, "w-[72px] pl-[24px]")}
              value={heightValue}
              onChange={(e) => setHeightValue(e.currentTarget.value)}
              onBlur={(e) => {
                const value = parseInt(heightValue);

                if (isNaN(value)) {
                  setHeightValue(outpaintHeight.toString());
                  return;
                }

                setOutpaintHeight(value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <button
            className={classNames(
              status === OutpaintStatus.Idle
                ? PrimaryButtonClassName
                : status === OutpaintStatus.Rendering
                  ? PrimaryButtonClassNameLoading
                  : PrimaryButtonClassNameDisabled,
              "mt-4 flex flex-row items-center justify-center gap-2",
            )}
            onClick={() => {
              if (status !== OutpaintStatus.Idle) {
                return;
              }

              setStatus(OutpaintStatus.Rendering);

              eventEmitter?.emit<StartOutpaintImageJobEventHandler>("outpaint-image:start-job");
            }}
          >
            <Expand size={16} />
            <span className="truncate">Extend Image</span>
          </button>
          <button
            className={classNames(
              status === OutpaintStatus.Idle && hasOutputImage
                ? SecondaryButtonClassNameInactive
                : SecondaryButtonClassNameDisabled,
              "flex flex-row items-center justify-center gap-2",
            )}
            onClick={() => {
              if (status !== OutpaintStatus.Idle || !hasOutputImage) {
                return;
              }

              addOutpaintImageToCanvas({
                outputImageUrl: outputImageUrls[0],
                sourceObject: activeObject,
                outputWidth: outpaintWidth,
                outputHeight: outpaintHeight,
              });

              const { setActiveLeftPanels } = editorContextStore.getState();

              setActiveLeftPanels((prevLeftPanels) => {
                return removeLastFromImmutableList(prevLeftPanels, "Assets");
              });
            }}
          >
            <Plus size={16} />
            <span className="truncate">Add Image to Canvas</span>
          </button>
          <div
            className={classNames(
              "mt-2 flex flex-col items-stretch gap-1 bg-lime-900/20 border border-lime-900/30 p-2 rounded-lg shadow-md text-lime-400 text-sm transition-opacity",
            )}
          >
            <div className="flex flex-row items-center justify-start">
              <Info size={16} className="mr-2" />
              <div className="flex-1 truncate font-semibold ">Tips</div>
            </div>
            <ul className="ml-4 list-disc list-outside font-normal text-sm gap-2 [&>li]:pl-2 [&>li]:pb-2">
              <li>
                The box with green border on the canvas is a preview of the output image. The gray
                area will be generated.
              </li>
              <li>Drag the green handles on the box to change the output image size.</li>
              <li>Click the "Extend Image" button above to generate the output image.</li>
            </ul>
          </div>
        </div>
        <OutpaintObjectDialog image={image} />
      </div>
    </div>
  );
}

export function OutpaintImage() {
  return (
    <OutpaintContextProvider>
      <OutpaintImageInner />
    </OutpaintContextProvider>
  );
}
