import { Backend } from "@/backend/base";
import { getPromptFromTemplate } from "@/core/common/prompt-template";
import { CancelColorCorrectV2Job, UiDisplayMessageDialogEventHandler } from "@/core/common/types";
import {
  ColorCorrectV2Args,
  ColorCorrectV2JobStatus,
  ColorCorrectV2ResponseStatus,
  ColorCorrectV2Stage,
  ColorCorrectV2StartingPointImageType,
  getLatestIntermediateResult,
} from "@/core/common/types/color-correct-v2";
import { Editor } from "@/core/editor";
import { getDataUrlFromString } from "@/core/utils/asset-utils";
import { getRawDataUrlFromImageObject } from "@/core/utils/image-utils";
import { rescaleNumber, roundToNearestNumber } from "@/core/utils/number-utils";
import { getMaskImageFromPastGeneration } from "@/core/utils/past-generation-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { fabric } from "fabric";
import { WebRenderProcessController } from "./render-process-controller";

async function loadImageDataUrlFromStoragePath({
  editor,
  backend,
  imageStoragePath,
}: {
  editor: Editor;
  backend: Backend;
  imageStoragePath: string;
}) {
  try {
    const storagePath = backend.cleanupStoragePathURL(imageStoragePath);

    const currentStorageUrl = await editor.assets.loadAsset({
      path: storagePath,
      type: "image-storage",
    });

    if (!currentStorageUrl) {
      return undefined;
    }

    return await getDataUrlFromString(currentStorageUrl);
  } catch (error) {
    debugError(error);
  }

  return undefined;
}

async function loadRegenerateProductResultFromColorCorrectV2Stage({
  editor,
  backend,
  currentStageIndex,
  currentStoragePath,
}: {
  editor: Editor;
  backend: Backend;
  currentStageIndex: number;
  currentStoragePath: string;
}) {
  try {
    const currentImageUrl = await loadImageDataUrlFromStoragePath({
      editor,
      backend,
      imageStoragePath: currentStoragePath,
    });

    if (currentImageUrl) {
      editor.state.setRegenerateProductResults((results) => {
        const newResults = results.slice();

        if (currentStageIndex >= newResults.length) {
          newResults.push({
            imageUrl: currentImageUrl,
          });
        } else {
          newResults[currentStageIndex] = {
            imageUrl: currentImageUrl,
          };
        }

        return newResults;
      });
    }
  } catch (error) {
    console.error(error);
  }
}

async function handleColorCorrectV2JobStateUpdate({
  editor,
  backend,
  uid,
  jobId,
  stagesToRun,
}: {
  editor: Editor;
  backend: Backend;
  uid: string;
  jobId: string;
  stagesToRun: ColorCorrectV2Stage[];
}) {
  return new Promise<void>((resolve, reject) => {
    if (stagesToRun.length <= 0) {
      resolve();
      return;
    }

    const prevStageRef: {
      current?: ColorCorrectV2Stage;
    } = {
      current: undefined,
    };

    if (stagesToRun[stagesToRun.length - 1] !== ColorCorrectV2Stage.Final) {
      stagesToRun = [...stagesToRun, ColorCorrectV2Stage.Final];
    }

    const promises: Promise<void>[] = [];

    const unsubscribeColorCorrectV2Update = backend.onColorCorrectV2Update({
      uid,
      jobId,
      onUpdate: (renderJobDoc) => {
        if (!renderJobDoc) {
          reject("Document is invalid");
          return;
        }

        debugLog(`Handle render job ${jobId} doc update:\n`, renderJobDoc);

        try {
          const intermediateResults = renderJobDoc.intermediate_results;

          if (!intermediateResults) {
            return;
          }

          const latestResult = getLatestIntermediateResult({
            intermediateResults,
            stagesToRun,
          });

          if (!latestResult) {
            debugError(`Render job ${jobId} doc has no valid lastest result.`);
            return;
          }

          const [currentStage, currentUrl] = latestResult;

          const currentStageIndex = stagesToRun.findIndex((stage) => stage === currentStage) ?? 0;

          const nextStage = stagesToRun?.[currentStageIndex + 1];

          // Replace image url

          const currentStoragePath = backend.cleanupStoragePathURL(currentUrl);

          debugLog(
            `Current stage ${currentStage}; Current path: ${currentStoragePath}; Next stage: ${nextStage}`,
          );

          promises.push(
            loadRegenerateProductResultFromColorCorrectV2Stage({
              editor,
              backend,
              currentStageIndex,
              currentStoragePath,
            }),
          );

          prevStageRef.current = currentStage;
        } catch (error) {
          debugError(error);
        } finally {
          if (renderJobDoc.status !== ColorCorrectV2JobStatus.Active) {
            debugLog(`Color correct v2 job ${jobId} is completed.`);

            unsubscribeColorCorrectV2Update?.();

            Promise.all(promises).then(() => resolve());
          }
        }
      },
    });

    editor.once<CancelColorCorrectV2Job>("color-correct-v2:cancel-job", ({ jobId: inputJobId }) => {
      if (inputJobId !== jobId) {
        return;
      }

      debugLog(`Color correct v2 job ${jobId} is cancelled.`);
      unsubscribeColorCorrectV2Update?.();
      resolve();
    });
  });
}

function rescaleCreativity({
  creativity,
  min,
  max,
}: {
  creativity: number;
  min: number;
  max: number;
}) {
  return roundToNearestNumber(
    rescaleNumber({
      value: creativity,
      sourceMin: 0,
      sourceMax: 1,
      targetMin: min,
      targetMax: max,
    }),
    1,
  );
}

function getColorCorrectV2ArgsFromCreativity(creativity: number) {
  debugLog(`Regenerate product creativity: ${creativity}`);

  const skipRepasteProduct = creativity > 0.5;

  return {
    clarity_upscale_num_inference_steps: 25,
    clarity_upscale_creativity: rescaleCreativity({
      creativity,
      min: 0.15,
      max: 0.5,
    }),
    clarity_skip_repaste_product: skipRepasteProduct,
    post_background_paste_back_product_image: !skipRepasteProduct,
    smoothing_strength: rescaleCreativity({
      creativity,
      min: 0.05,
      max: 0.3,
    }),
    smoothing_blend_strength: rescaleCreativity({
      creativity,
      min: 0.8,
      max: 1.0,
    }),
    starting_point_image_type: ColorCorrectV2StartingPointImageType.InitialRenderImage,
  };
}

async function regenerateProductWithColorCorrectV2Internal({
  uid,
  editor,
  backend,
  object,
  targetLength = 1280,
  downscaleResolution = 768,
  renderProcessController,
  creativity = 0.5,
}: {
  uid: string;
  editor: Editor;
  backend: Backend;
  object: fabric.StaticImage;
  targetLength?: number;
  downscaleResolution?: number;
  renderProcessController: WebRenderProcessController;
  creativity?: number;
}) {
  const objectWidth = object.width || targetLength;
  const objectHeight = object.height || targetLength;
  const objectScale = targetLength / Math.max(objectWidth, objectHeight);
  const targetWidth = objectScale * objectWidth;
  const targetHeight = objectScale * objectHeight;

  const imageUrl = await getRawDataUrlFromImageObject({
    object,
    width: targetWidth,
    height: targetHeight,
  });

  if (!imageUrl) {
    debugLog("Image object url is invalid.");
    return;
  }

  if (renderProcessController.isCancelled()) {
    debugLog("Upscale job is already cancelled");
    return;
  }

  const generationId = object?.generationId;

  const { pastGenerations, regenerateProductPromptTemplate } = editor.state;

  const pastGeneration = generationId ? pastGenerations[generationId] : undefined;

  const prompt =
    getPromptFromTemplate(regenerateProductPromptTemplate) || pastGeneration?.prompt || "";

  const compositeImage = pastGeneration
    ? await getMaskImageFromPastGeneration({
        editor,
        backend,
        pastGeneration,
        targetWidth,
        targetHeight,
      })
    : undefined;

  if (renderProcessController.isCancelled()) {
    debugLog("Re-generate product job is already cancelled");
    return;
  }

  const upscaleFactor = Math.max(targetWidth, targetHeight) / downscaleResolution;

  const stagesToRun = [
    ColorCorrectV2Stage.Clarity,
    ColorCorrectV2Stage.PCT,
    ColorCorrectV2Stage.ObjectDrop,
    ColorCorrectV2Stage.SmoothBlend,
  ];

  const colorCorrectV2Args: ColorCorrectV2Args = {
    ...getColorCorrectV2ArgsFromCreativity(creativity),
    composite_image: compositeImage,
    initial_render_image: imageUrl,
    gpu_stages_to_run: stagesToRun,
    prompt,
    negative_prompt: "blurry, ugly, chaotic, fake, painting, drawing",
    width: targetWidth,
    height: targetHeight,
    clarity_upscale_downscaling: true,
    clarity_upscale_scale_factor: upscaleFactor,
    clarity_upscale_num_inference_steps: 25,
    clarity_upscale_seed: Math.round(Math.random() * 10000),
    return_without_smoothing: false,
    // starting_point_image_type: ColorCorrectV2StartingPointImageType.ForegroundOnBackground,
  };

  debugLog(colorCorrectV2Args);

  const {
    status,
    job_id: jobId,
    message,
  } = await backend.startColorCorrectV2({
    ...colorCorrectV2Args,
    renderProcessController,
  });

  const handleCancelJob = () => {};

  if (
    !jobId ||
    status !== ColorCorrectV2ResponseStatus.Rendering ||
    renderProcessController.isCancelled()
  ) {
    debugLog("Upscale job is cancelled with message: \n", message);

    handleCancelJob();

    editor?.emit<UiDisplayMessageDialogEventHandler>(
      "ui:display-message-dialog",
      "quota-subscribe",
      {
        title: "You have no quota left.",
        header: "Subscribe to get unlimited product renders.",
      },
    );

    return;
  }

  editor.once<CancelColorCorrectV2Job>("color-correct-v2:cancel-job", ({ jobId: targetJobId }) => {
    if (targetJobId !== jobId) {
      return;
    }

    renderProcessController.cancelJob();
  });

  try {
    await handleColorCorrectV2JobStateUpdate({
      uid,
      editor,
      backend,
      jobId,
      stagesToRun,
    });
  } catch (error) {
    debugError(error);
  } finally {
    await renderProcessController.cancelJob();
  }
}

export async function regenerateProductWithColorCorrectV2({
  uid,
  editor,
  backend,
  ...props
}: {
  uid: string;
  editor: Editor;
  backend: Backend;
  object: fabric.StaticImage;
  targetLength?: number;
  downscaleResolution?: number;
  creativity?: number;
}) {
  const {
    regenerateProductNumImages,
    regenerateProductCreativity,
    setRegenerateProductResults,
    setRegenerateProductRenderState,
    setRegenerateRenderProcessController,
  } = editor.state;

  try {
    setRegenerateProductRenderState("rendering");

    const renderProcessController = new WebRenderProcessController();

    setRegenerateRenderProcessController(renderProcessController);

    setRegenerateProductResults(
      new Array(regenerateProductNumImages).fill({
        imageUrl: undefined,
        isSelected: false,
      }),
    );

    await regenerateProductWithColorCorrectV2Internal({
      uid,
      backend,
      editor,
      renderProcessController,
      creativity: regenerateProductCreativity,
      ...props,
    });
  } catch (error) {
    console.error("Error regenerating product with color correct v2: ", error);
  } finally {
    setRegenerateProductRenderState("idle");
  }
}
