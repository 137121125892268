import { AppUserQuotas, AppUserSubscriptionTier } from "@/core/common/types";

export function isUserSubscriptionTierFree(userQuotas: AppUserQuotas | null) {
  const tier = userQuotas?.tier || AppUserSubscriptionTier.Free;
  return tier === AppUserSubscriptionTier.Free;
}

export function isRenderAvailable(userQuotas: AppUserQuotas | null) {
  const numRenders = userQuotas?.numRenders || 0;
  const tier = userQuotas?.tier || AppUserSubscriptionTier.Free;
  const maxNumRenders = userQuotas?.maxNumRenders || 0;
  return numRenders < maxNumRenders || tier !== AppUserSubscriptionTier.Free;
}

export function canUserCreateNewProject(userQuotas: AppUserQuotas | null) {
  if (!isUserSubscriptionTierFree(userQuotas)) {
    return true;
  }
  return (userQuotas?.numProjects ?? 0) < (userQuotas?.maxNumProjects ?? 0);
}

export function isUserProjectQuotaValid(userQuota: AppUserQuotas | null) {
  return (userQuota?.maxNumProjects ?? 0) > 0;
}

export function isRealTimeRenderAvailable(userQuotas: AppUserQuotas | null) {
  if (!userQuotas) {
    return false;
  }

  const { realTimeRenderTimeSec = 0, maxRealTimeRenderTimeSec = 600 } = userQuotas;

  return realTimeRenderTimeSec < maxRealTimeRenderTimeSec;
}

export function canUserUploadAsset(userQuotas: AppUserQuotas | null) {
  if (!userQuotas) {
    return false;
  }

  const { maxAssetSizeBytes = 0, totalAssetSizeBytes = 0 } = userQuotas;

  return totalAssetSizeBytes < maxAssetSizeBytes;
}
