export class ProgressHandler {
  static DEFAULT_SPEED = 0.05;

  private intervalId: string | number | undefined;

  private setProgressInternal: ((value: number) => void) | undefined;

  private interval = 100;

  private speed = ProgressHandler.DEFAULT_SPEED;

  private timeline = 0;

  constructor({
    setProgress,
    interval = 100,
    speed = ProgressHandler.DEFAULT_SPEED,
  }: {
    speed?: number;
    interval?: number;
    setProgress: (value: number) => void;
  }) {
    this.speed = speed || ProgressHandler.DEFAULT_SPEED;
    this.interval = interval || 100;
    this.setProgressInternal = setProgress;
  }

  reset() {
    clearInterval(this.intervalId);
    this.intervalId = undefined;
    this.timeline = 0;
  }

  setProgress(value: number) {
    if (value <= 0) {
      this.reset();
      this.setProgressInternal?.(0);
      return;
    }
    if (value >= 1) {
      this.reset();
      this.setProgressInternal?.(1);
      return;
    }

    this.timeline = Math.max(this.timeline, Math.ceil(value / (this.speed * (1 - value))));

    if (this.intervalId == null) {
      this.intervalId = setInterval(() => {
        const progress = -1 / (this.speed * this.timeline + 1) + 1;
        this.setProgressInternal?.(progress);
        this.timeline += 1;
      }, this.interval) as unknown as string | number;
    }
  }
}
