import React from "react";
import { clamp } from "lodash";
import { ProgressHandler } from "components/utils/progress-handler";
import { classNames } from "@/core/utils/classname-utils";

export function ProgressBar({
  progress = 0.5,
  className = "",
  isError = false,
  cancellable = false,
  errorMessage = "Render Error",
  progressMessage = "Progress",
  cancelMessage = "Stop Generating",
  onPointerEnter,
  onPointerLeave,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  progress?: number;
  isError?: boolean;
  cancellable?: boolean;
  errorMessage?: string;
  cancelMessage?: string;
  progressMessage?: string;
}) {
  const [progressInternal, setProgressInternal] = React.useState(0);
  const progressHandlerRef = React.useRef<ProgressHandler>(
    new ProgressHandler({
      setProgress: setProgressInternal,
    }),
  );
  React.useEffect(() => {
    progressHandlerRef.current.setProgress(progress);
  }, [progress]);

  const percent = isError ? 0 : clamp(Math.round(progressInternal * 100), 0, 100);

  const [isPointerOver, setPointerOver] = React.useState(false);

  return (
    <div
      {...props}
      onPointerEnter={(e) => {
        setPointerOver(true);
        onPointerEnter?.(e);
      }}
      onPointerLeave={(e) => {
        setPointerOver(false);
        onPointerLeave?.(e);
      }}
      className={classNames(
        "group px-3 py-2 relative overflow-hidden rounded-md text-zinc-900 transition-colors",
        isError
          ? "bg-red-500 cursor-not-allowed"
          : cancellable
            ? "bg-lime-500 hover:bg-red-500 cursor-pointer"
            : "bg-lime-500 hover:bg-lime-600 cursor-wait",
        className,
      )}
    >
      <div className="relative z-10 h-full w-full flex flex-row items-center justify-center text-center font-semibold select-none">
        <span>
          {isError ? errorMessage : cancellable && isPointerOver ? cancelMessage : progressMessage}
        </span>
        <span className="ml-2">
          {isError || (cancellable && isPointerOver) ? "" : `${percent} %`}
        </span>
      </div>
      <div
        className={classNames(
          "absolute left-0 top-0 bg-lime-400 z-0 h-full",
          cancellable ? "group-hover:bg-red-400" : "",
        )}
        style={{
          width: `${percent}%`,
          transitionProperty: "width",
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionDuration: "150ms",
        }}
      ></div>
    </div>
  );
}
