import React from "react";

type TryOnLeftPanelItemType = "TryOnUploadCloth" | "TryOnSelectPose" | "TryOnRender";

export type LeftPanelItemType =
  | "Assets"
  | "Text"
  | "Generate"
  | "WordMask"
  | "PaintPrompt"
  | "Edit"
  | "MagicErase"
  | "RemoveBackground"
  | "ImageVariations"
  | "Elements"
  | "Humans"
  | TryOnLeftPanelItemType
  | "TransformProps3d"
  | "RegenerateProduct"
  | "ReplaceProduct"
  | "UpscaleV2"
  | "Outpaint"
  | "GenerateVideo";

export function isTryOnLeftPanelItemType(type: LeftPanelItemType): type is TryOnLeftPanelItemType {
  return type === "TryOnUploadCloth" || type === "TryOnSelectPose" || type === "TryOnRender";
}

export const LEFT_PANELS_LIST_ITEMS: Record<
  Partial<LeftPanelItemType>,
  {
    name: string;
    title?: React.ReactNode;
  }
> = {
  Generate: {
    name: "Generate",
    title: "Generate Photoshoot",
  },
  Assets: {
    name: "Products",
  },
  Elements: {
    name: "Props",
  },
  Humans: {
    name: "Humans",
  },
  Text: {
    name: "Text",
  },
  WordMask: {
    name: "Word Mask",
  },
  PaintPrompt: {
    name: "Paint Prompt",
  },
  Edit: {
    name: "Edit",
  },
  MagicErase: {
    name: "Magic Erase",
  },
  RemoveBackground: {
    name: "Remove Background",
  },
  ImageVariations: {
    name: "Image Variations",
  },
  TryOnSelectPose: {
    name: "Select Pose",
  },
  TryOnRender: {
    name: "Generate Cloth",
  },
  TryOnUploadCloth: {
    name: "Try On",
  },
  TransformProps3d: {
    name: "Edit Props 3D",
  },
  RegenerateProduct: {
    name: "Regenerate Product",
  },
  ReplaceProduct: {
    name: "Replace Product",
  },
  UpscaleV2: {
    name: "Upscale Creative",
  },
  Outpaint: {
    name: "Extend Image",
  },
  GenerateVideo: {
    name: "Video",
    title: "Generate Video",
  },
};
