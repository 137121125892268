import {
  CustomModelPlaygroundPromptEditorState,
  CustomModelScaleConfig,
  StateUpdater,
} from "@/core/common/types";
import {
  PaintMaskCanvasControllerPaintBrushType,
  PaintMaskCanvasHistorySnapshot,
} from "components/editor/paint-mask-canvas-controller";
import React, { createContext, useContext, useState } from "react";
import { getEmptyCustomModelPlaygroundPromptEditorState } from "./custom-model-playground-context";

export enum CustomModelImageEditorRegenerateProductGenerateStatus {
  Idle = "Idle",
  Loading = "Loading",
}

export enum CustomModelImageEditorRegenerateProductMaskStatus {
  Idle = "Idle",
  Loading = "Loading",
}

export enum CustomModelImageEditorRegenerateProductInitStatus {
  None = "None",
  Initiailizing = "Initiailizing",
  Ready = "Ready",
}

interface CustomModelImageEditorRegenerateProductContextType {
  initStatus: CustomModelImageEditorRegenerateProductInitStatus;
  setInitStatus: (value: StateUpdater<CustomModelImageEditorRegenerateProductInitStatus>) => void;
  productPrompt: string;
  setProductPrompt: (value: StateUpdater<string>) => void;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  setPromptEditorState: (value: StateUpdater<CustomModelPlaygroundPromptEditorState>) => void;
  maskImageUrl: string | undefined;
  setMaskImageUrl: (value: StateUpdater<string | undefined>) => void;
  productModelScaleConfig: CustomModelScaleConfig | undefined;
  setProductModelScaleConfig: (value: StateUpdater<CustomModelScaleConfig | undefined>) => void;
  maskBrushType: PaintMaskCanvasControllerPaintBrushType;
  setMaskBrushType: (value: StateUpdater<PaintMaskCanvasControllerPaintBrushType>) => void;
  maskBrushSize: number;
  setMaskBrushSize: (value: StateUpdater<number>) => void;
  canvasHistorySnapshot: PaintMaskCanvasHistorySnapshot;
  setCanvasHistorySnapshot: (value: StateUpdater<PaintMaskCanvasHistorySnapshot>) => void;
}

const CustomModelImageEditorRegenerateProductContext = createContext<
  CustomModelImageEditorRegenerateProductContextType | undefined
>(undefined);

export function useCustomModelImageEditorRegenerateProductContext() {
  const context = useContext(CustomModelImageEditorRegenerateProductContext);

  if (!context) {
    throw new Error(
      "useCustomModelImageEditorRegenerateProductContext must be used within CustomModelImageEditorRegenerateProductContext",
    );
  }

  return context;
}

export function CustomModelImageEditorRegenerateProductProvider({
  children,
}: React.PropsWithChildren) {
  const [initStatus, setInitStatus] = useState<CustomModelImageEditorRegenerateProductInitStatus>(
    CustomModelImageEditorRegenerateProductInitStatus.None,
  );

  const [productPrompt, setProductPrompt] = useState<string>("");

  const [promptEditorState, setPromptEditorState] =
    useState<CustomModelPlaygroundPromptEditorState>(
      getEmptyCustomModelPlaygroundPromptEditorState(),
    );

  const [maskImageUrl, setMaskImageUrl] = useState<string | undefined>(undefined);

  const [productModelScaleConfig, setProductModelScaleConfig] = useState<
    CustomModelScaleConfig | undefined
  >(undefined);

  const [maskBrushType, setMaskBrushType] = useState<PaintMaskCanvasControllerPaintBrushType>(
    PaintMaskCanvasControllerPaintBrushType.Paint,
  );

  const [maskBrushSize, setMaskBrushSize] = useState<number>(25);

  // Initialize canvasHistorySnapshot with a default value
  const [canvasHistorySnapshot, setCanvasHistorySnapshot] =
    useState<PaintMaskCanvasHistorySnapshot>({
      actions: [],
      currentIndex: -1,
    });

  return (
    <CustomModelImageEditorRegenerateProductContext.Provider
      value={{
        initStatus,
        setInitStatus,
        productPrompt,
        setProductPrompt,
        promptEditorState,
        setPromptEditorState,
        maskImageUrl,
        setMaskImageUrl,
        productModelScaleConfig,
        setProductModelScaleConfig,
        maskBrushType,
        setMaskBrushType,
        maskBrushSize,
        setMaskBrushSize,
        canvasHistorySnapshot,
        setCanvasHistorySnapshot,
      }}
    >
      {children}
    </CustomModelImageEditorRegenerateProductContext.Provider>
  );
}
