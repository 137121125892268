export function loadImageFromURL(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = src;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (e) => reject(e);
  });
}

/**
 * Creates image element (works on client and node)
 * @static
 * @memberOf fabric.util
 * @return {HTMLImageElement} HTML image element
 */
export const createImage = (): HTMLImageElement => document.createElement("img");

type TCrossOrigin = "" | "anonymous" | "use-credentials" | null;

export type LoadImageOptions = {
  signal?: AbortSignal;
  crossOrigin?: TCrossOrigin;
};

export function loadImageElementFromURL(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = createImage();
    image.crossOrigin = "anonymous";
    image.src = src;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (e) => reject(e);
  });
}

export function loadImageElementFromBlob(blob: Blob | File) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = createImage();
    image.crossOrigin = "anonymous";
    image.src = URL.createObjectURL(blob);
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (e) => reject(e);
  });
}

/**
 * Loads image element from given url and resolve it, or catch.
 * @memberOf fabric.util
 * @param {String} url URL representing an image
 * @param {Object} [options] image loading options
 * @param {string} [options.crossOrigin] cors value for the image loading, default to anonymous
 * @param {AbortSignal} [options.signal] handle aborting, see https://developer.mozilla.org/en-US/docs/Web/API/AbortController/signal
 * @param {Promise<fabric.Image>} img the loaded image.
 */
export const loadImage = (
  url: string,
  { signal, crossOrigin = "anonymous" }: LoadImageOptions = {},
) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    if (signal && signal.aborted) {
      return reject(new Error("`options.signal` is in `aborted` state"));
    }
    const img = createImage();
    let abort: EventListenerOrEventListenerObject;
    if (signal) {
      abort = (err: Event) => {
        img.src = "";
        reject(err);
      };
      signal.addEventListener("abort", abort, { once: true });
    }
    const done = () => {
      img.onload = img.onerror = null;
      if (abort && signal) {
        signal.removeEventListener("abort", abort);
      }
      resolve(img);
    };
    if (!url) {
      done();
      return;
    }
    img.onload = done;
    img.onerror = () => {
      if (abort && signal) {
        signal.removeEventListener("abort", abort);
      }
      reject(new Error("Error loading " + img.src));
    };
    if (crossOrigin) {
      img.crossOrigin = crossOrigin;
    }
    img.src = url;
  });
