import React, { useEffect, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styles from "./dropdown-options.module.css";
import { classNames } from "@/core/utils/classname-utils";
import { DropdownClassName } from "components/constants/class-names";
import { ChevronDown } from "lucide-react";
import { LEFT_PANEL_DROPDOWN_WIDTH } from "components/constants/left-panels";
import { useMaxCollisionHeight } from "hooks/use-max-collision-height";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { LeftPanelDropdownZIndex } from "components/constants/zIndex";

export type DropdownOptionItem<T = any> = {
  name: string;
  value: T;
  disabled?: boolean;
};

export function getDropdownOptionItemsFromArray<T = any>(items: T[]) {
  const output: Record<string, DropdownOptionItem<T>> = {};
  items.forEach((item) => {
    const itemType = typeof item;
    if (!item || itemType === "function") {
      return;
    }
    const name = typeof item === "string" ? item : JSON.stringify(item);
    output[name] = {
      name,
      value: item,
    };
  });
  return output;
}

export type DropdownOptionsProps<T = any> = DropdownMenu.DropdownMenuTriggerProps &
  React.RefAttributes<HTMLButtonElement> & {
    options: Record<string, DropdownOptionItem<T>>;
    onSelectItem: (option: DropdownOptionItem<T>) => void;
    dropdownWidth?: number | string;
    contentProps?: DropdownMenu.DropdownMenuContentProps;
  };

export function DropdownOptions<T = any>({
  options,
  children,
  onSelectItem,
  dropdownWidth = LEFT_PANEL_DROPDOWN_WIDTH,
  contentProps = {},
  className,
  ...props
}: DropdownOptionsProps<T>) {
  const triggerRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        ref={triggerRef}
        className={classNames("focus:outline-none", className ?? "")}
        {...props}
      >
        {children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          {...contentProps}
          className={classNames(
            styles.DropdownMenuContent,
            DropdownClassName,
            "text-sm px-2 overflow-x-hidden overflow-y-auto",
          )}
          style={{
            maxWidth: dropdownWidth,
            zIndex: LeftPanelDropdownZIndex,
            ...(contentProps?.style ?? {}),
          }}
        >
          {Object.entries(options).map(([key, option]) => (
            <DropdownMenu.Item
              key={key}
              disabled={option.disabled || false}
              className="px-3 py-2 select-none hover:bg-zinc-800 rounded-md focus:outline-none focus-visible:outline-none border border-transparent focus:border-zinc-700"
              onSelect={() => {
                onSelectItem(option);
              }}
            >
              {option.name}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export function DropdownOptionsWithTrigger<T = any>({
  value,
  options,
  onSelectItem,
  className,
  dropdownWidth = LEFT_PANEL_DROPDOWN_WIDTH,
}: {
  value: string;
  options: Record<string, DropdownOptionItem<T>>;
  onSelectItem: (option: DropdownOptionItem<T>) => void;
  className?: string;
  dropdownWidth?: number | string;
}) {
  return (
    <DropdownOptions
      className={classNames(
        styles.DropdownMenuTrigger,
        "flex items-center rounded-md cursor-pointer text-zinc-300 hover:text-lime-500 focus:outline-none",
        className ?? "",
      )}
      options={options}
      onSelectItem={onSelectItem}
      dropdownWidth={dropdownWidth}
    >
      <span className="flex-1 mr-2 truncate transition-colors">{value}</span>
      <ChevronDown className={classNames(styles.DropdownIcon, "transition-colors")} size={18} />
    </DropdownOptions>
  );
}

export function DropdownOptionsWithTriggerBorder<T = any>({
  value,
  className,
  ...props
}: DropdownOptionsProps<T>) {
  return (
    <DropdownOptions
      className={classNames(
        styles.DropdownMenuTrigger,
        "flex flex-row items-center px-3 py-2 rounded-md border border-solid border-zinc-800 hover:border-zinc-700 focus:border-zinc-700 focus-visible:border-zinc-700 bg-zinc-900 text-sm text-zinc-300 transition-colors cursor-pointer",
        className ?? "",
      )}
      {...props}
    >
      <span className="flex-1 mr-1 truncate">{value}</span>
      <ChevronDown className={styles.DropdownIcon} size={18} />
    </DropdownOptions>
  );
}
