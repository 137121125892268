import { NEW_PROJECT } from "@/components/constants/routes";
import {
  Template,
  TemplateCategories,
  TemplateCategoryIcons,
  TemplateCategoryNamesAndOrder,
  Templates,
  TemplateType,
} from "@/components/constants/user-project-type-styles";
import { classNames } from "@/core/utils/classname-utils";
import { CirclePlus } from "lucide-react";
import { memo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { CardClassName } from "@/components/constants/class-names";
import {
  CreateGeneralProjectDialog,
  useCreateGeneralProjectDialogContext,
} from "@/components/popup/message-dialog/create-general-project-dialog";
import { GridCarousel } from "@/components/utils/grid-carousel";
import { trackTemplateAction } from "../panels/panel-items/components/TemplateCarousel";

/*
  ## Workflow Types ##
  export enum WorkflowType {
    Project = 'Project',
    ProjectWorkflow = 'ProjectWorkflow',
    CustomModel = 'CustomModel',
    CustomModelWorkflow = 'CustomModelWorkflow',
    CustomModelExample = 'CustomModelExample',
    CustomModelPlayground = 'CustomModelPlayground',
    Video = 'Video'
  }
*/

export function startTemplate(type: TemplateType, navigate: NavigateFunction, data?: {}): void;

export function startTemplate(type: TemplateType, navigate: NavigateFunction, data: any = {}) {
  const { projectWorkflow, customModelWorkflow, customModelExampleId } = data;
  switch (type) {
    case TemplateType.Project:
      navigate(`/${NEW_PROJECT}`, { replace: true });
      break;

    case TemplateType.ProjectWorkflow:
      navigate(`/${NEW_PROJECT}/${projectWorkflow}`, { replace: true });
      break;

    case TemplateType.CustomModel:
      navigate(`/newModel`, { replace: true });
      break;

    case TemplateType.CustomModelWorkflow:
      navigate(`/newModel/${customModelWorkflow}`, {
        replace: true,
      });
      break;

    case TemplateType.CustomModelExample:
      navigate(`/models/${customModelExampleId}`, { replace: true });
      break;

    case TemplateType.CustomModelPlayground:
      navigate(`/newModel/${"PlayGround"}`, { replace: true });
      break;

    case TemplateType.Video:
      navigate(`/videos`, { replace: true });
      break;
  }
}

function TemplateItem({
  template,
  navigate,
  type = "grid",
}: {
  template: Template;
  navigate: NavigateFunction;
  type?: "grid" | "carousel";
}) {
  return (
    <div
      className={classNames(
        type === "grid" ? "flex-none" : "",
        "flex items-center justify-start group transition-colors rounded-md",
        "cursor-pointer group-hover:border-neutral-700 group-hover:shadow-neutral-100",
        "pb-[75%] relative overflow-hidden",
      )}
      style={type === "grid" ? { aspectRatio: "4 / 3" } : {}}
      onClick={() => {
        // depending on type do stuff
        startTemplate(template.type, navigate, { ...(template.data || {}) });
        trackTemplateAction("WorkflowDashboardCardClick", {
          template: template.name,
        });
      }}
    >
      <div
        className="absolute pointer-events-none select-none inset-0 transition-all duration-300 bg-cover bg-center group-hover:scale-110"
        style={{
          backgroundImage: `url(${template.image})`,
        }}
      ></div>
      <div className="absolute inset-0 bg-black/30 transition-all duration-300 group-hover:bg-transparent"></div>
      <div
        className="absolute bottom-0 bg-gradient-to-t from-black to-transparent w-full text-center text-zinc-300 text-base font-medium p-2"
        style={{
          lineClamp: 2,
          WebkitLineClamp: 2,
        }}
      >
        {template.name}
      </div>
    </div>
  );
}

export function TemplatesCarousel({
  templates,
  navigate,
}: {
  templates: Template[];
  navigate: NavigateFunction;
}) {
  return (
    <GridCarousel columnsPerPage={5} className="mb-5">
      {templates.map((template, index) => {
        return <TemplateItem key={index} template={template} navigate={navigate} type="carousel" />;
      })}
    </GridCarousel>
  );
}

function CreateGeneralProjectDialogButton({ onClick }: { onClick: () => void }) {
  const colorsUsed = {
    text: "text-lime-500",
    textDisabled: "text-lime-300 group-hover:text-lime-100`",
    bg: `bg-lime-900/50`,
    hoverBorder: `hover:border-lime-700`,
    hoverShadow: `hover:shadow-lime-500/5`,
  };
  return (
    <div
      id="explore-workflows-card"
      className={classNames(
        CardClassName,
        "p-3 flex flex-row items-center justify-start group transition-colors",
        `cursor-pointer ${colorsUsed.hoverBorder} ${colorsUsed.hoverShadow}`,
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          `p-1.5 ${colorsUsed.bg} rounded transition-colors`,
          `${colorsUsed.text} group-hover:bg-${colorsUsed}-800 group-hover:text-${colorsUsed}-100`,
        )}
      >
        {<CirclePlus size={20} />}
      </div>
      <div className="flex flex-col min-w-0 px-3 truncate transition-colors">
        <div className="text-zinc-300 group-hover:text-zinc-100">Create General Project</div>
        <div className="text-sm">For projects beyond the scope of templates</div>
      </div>
    </div>
  );
}

export const DashboardTemplates = memo(function UpdatedDashboardTemplates({
  visible = true,
}: {
  visible?: boolean;
}) {
  const navigate = useNavigate();
  const { setOpen } = useCreateGeneralProjectDialogContext();

  return (
    <div className={classNames(visible ? "flex flex-col gap-4" : "hidden", "p-4")}>
      <div className="flex flex-col gap-4">
        <div className="w-fit">
          <CreateGeneralProjectDialogButton onClick={() => setOpen(true)} />
        </div>
      </div>
      <div>
        {Object.entries(TemplateCategoryNamesAndOrder).map(
          ([category, { name, description }], index) => {
            const templates = Templates.filter((template) =>
              template.categories.includes(category as TemplateCategories),
            );
            const TemplateIcon = TemplateCategoryIcons[category];

            return (
              templates.length > 0 &&
              TemplateCategoryNamesAndOrder[category].showInTemplatesDashboard && (
                <div key={index} className="flex flex-col gap-2">
                  <div className="flex flex-row text-base 2xl:text-lg text-zinc-500 font-medium gap-1 items-center">
                    {TemplateIcon && (
                      <TemplateIcon size={20} strokeWidth={2.5} className="mb-0.5" />
                    )}
                    {name}
                  </div>
                  <TemplatesCarousel templates={templates} navigate={navigate} />
                </div>
              )
            );
          },
        )}
      </div>
      <CreateGeneralProjectDialog />
    </div>
  );
});
