import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { classNames } from "@/core/utils/classname-utils";

import styles from "./navbar-dropdown.module.css";

export function NavbarDropdownContent({
  sideOffset = 18,
  className,
  children,
  ...props
}: DropdownMenu.DropdownMenuContentProps) {
  return (
    <DropdownMenu.Content
      {...props}
      sideOffset={sideOffset}
      className={classNames(
        "py-2 bg-zinc-900 shadow-md border border-zinc-800 rounded-md min-w-[160px] z-50 text-zinc-300 text-sm flex flex-col justify-center items-start",
        styles.DropdownMenuContent,
        className ?? "",
      )}
    >
      {children}
    </DropdownMenu.Content>
  );
}

export function NavbarDropdownItemRightSlot({
  className,
  children,
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return <div className={classNames(styles.RightSlot, className ?? "")}>{children}</div>;
}

export function NavbarDropdownItem({
  className,
  children,
  ...props
}: DropdownMenu.DropdownMenuItemProps) {
  return (
    <DropdownMenu.Item
      {...props}
      className={classNames(
        "w-full flex flex-row items-center justify-start px-4 py-1.5 text-zinc-300 hover:text-lime-500 hover:outline-none select-none cursor-pointer transition-colors",
        className ?? "",
      )}
    >
      {children}
    </DropdownMenu.Item>
  );
}
