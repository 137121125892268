import React from "react";
import {
  DropdownClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  SecondaryButtonClassName,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
  TextButtonClassName,
} from "components/constants/class-names";
import { classNames } from "@/core/utils/classname-utils";
import {
  CustomModelTrainingInputContextType,
  getDurationSecondsFromTrainingSteps,
  useCustomModelTraininingInput,
} from "./custom-model-training-context";
import { editorContextStore } from "contexts/editor-context";
import {
  customModelTrainingBackendToDisplayName,
  CustomModelTrainingBackendType,
  CustomModelTrainingContentOrStyle,
  CustomModelTrainingInput,
  CustomModelTrainingInputFalSpecific,
  CustomModelTrainingInputGenericHardwareSpecific,
  CustomModelType,
  getCustomModelTypeFromFrontendDisplayTemplateType,
  getModelTriggerWord,
  UiDisplayMessageEventHandler,
} from "@/core/common/types";
import { SimpleSpinner } from "components/icons/simple-spinner";
import * as Dialog from "@radix-ui/react-dialog";
import styles from "./custom-model.module.css";
import { FloatTagZIndex } from "components/constants/zIndex";
import { Cross1Icon } from "@radix-ui/react-icons";
import { Clock, Diamond, Gem, Palette, Rocket, TriangleAlert, Box } from "lucide-react";
import {
  customModelWorkflowData,
  defaultCustomModelTrainingInputSliderOptions,
  getCustomModelTrainingInputFromSliderValue,
} from "components/custom-model/custom-model-workflows-data";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { useCanUserEditCustomModel } from "hooks/use-custom-models-effect";
import { ToggleGroupInput } from "components/dashboard/api/toggle-group";
import { clamp } from "lodash";
import { SliderInput } from "components/dashboard/api/slider-input";
import { useCustomModelPlayground } from "components/custom-model/custom-model-playground-context";
import { ApiInputType } from "@/core/common/types/api";

function ConfigureStrengthButton() {
  const { trainingStrengthPercent, setTrainingStrengthPercent } = useCustomModelTraininingInput();

  const workflow = editorContextStore((state) => state.customModelWorkflow);

  const trainingSliderConfigs = React.useMemo(
    () =>
      customModelWorkflowData[workflow]?.trainingSliderConfigs ??
      defaultCustomModelTrainingInputSliderOptions,
    [workflow],
  );

  const sliderValue = React.useMemo(
    () => Math.floor(clamp(trainingStrengthPercent, 0, 1) * trainingSliderConfigs.maxNumSteps),
    [trainingStrengthPercent, trainingSliderConfigs.maxNumSteps],
  );

  const setTrainingInputFromStep = React.useCallback(
    (step: number) => {
      const percent = clamp(step / trainingSliderConfigs.maxNumSteps, 0.0, 1.0);
      setTrainingStrengthPercent(percent);
    },
    [trainingSliderConfigs.maxNumSteps, setTrainingStrengthPercent],
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className={classNames(TextButtonClassName)}>
          <span>Configure Strength</span>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={styles.DialogOverlay}
          style={{
            zIndex: FloatTagZIndex,
          }}
        />
        <Dialog.Content
          className={classNames(
            styles.DialogContent,
            DropdownClassName,
            "py-2 px-4 w-[90vw] md:w-[50vw] lg:w-[600px] rounded-xl text-sm",
          )}
          style={{
            zIndex: FloatTagZIndex,
          }}
        >
          <div className="mb-4 flex flex-row items-center text-base">
            <Dialog.Title className="flex-1 text-zinc-500 font-semibold truncate">
              Configure Strength
            </Dialog.Title>
            <Dialog.Close className="text-zinc-500 hover:text-zinc-300 transition-colors cursor-pointer">
              <Cross1Icon />
            </Dialog.Close>
          </div>
          <form
            className="flex flex-col gap-8"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {trainingStrengthPercent > 0.5 ? (
              <SliderInput
                type={ApiInputType.Slider}
                id="custom-model-product-strength"
                name="Product Strength"
                value={sliderValue}
                onValueChange={(value) => {
                  setTrainingInputFromStep(value);
                }}
                min={trainingSliderConfigs.maxNumSteps / 2 + 1}
                max={trainingSliderConfigs.maxNumSteps}
                step={1}
                description="The stronger the training strength, the closer to your product"
                descriptionClassName="hidden md:block md:max-w-[50vw] xl:max-w-[100vw]"
                parseStringValue={(value: string) => {
                  const number = parseInt(value);
                  if (isNaN(number)) {
                    return 0;
                  }
                  return number;
                }}
                required
                hideRequiredLabel
                numberInputEnabled={false}
                minLabel="Weak"
                maxLabel="Strong"
              />
            ) : (
              <SliderInput
                type={ApiInputType.Slider}
                id="custom-model-style-strength"
                name="Style Strength"
                value={sliderValue}
                onValueChange={(value) => {
                  setTrainingInputFromStep(value);
                }}
                min={0}
                max={trainingSliderConfigs.maxNumSteps / 2 - 1}
                step={1}
                description="The stronger the training strength, the closer to your style"
                descriptionClassName="hidden md:block md:max-w-[50vw] xl:max-w-[100vw]"
                parseStringValue={(value: string) => {
                  const number = parseInt(value);
                  if (isNaN(number)) {
                    return 0;
                  }
                  return number;
                }}
                required
                hideRequiredLabel
                numberInputEnabled={false}
                minLabel="Weak"
                maxLabel="Strong"
              />
            )}
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export function CustomModelTraininingInputEditor() {
  const {
    trainingStrengthPercent,
    trainingBackendType,
    setTrainingBackendType,
    setTrainingStrengthPercent,
  } = useCustomModelTraininingInput();

  const workflow = editorContextStore((state) => state.customModelWorkflow);

  const trainingSliderConfigs = React.useMemo(
    () =>
      customModelWorkflowData[workflow]?.trainingSliderConfigs ??
      defaultCustomModelTrainingInputSliderOptions,
    [workflow],
  );

  const setTrainingInputFromStep = React.useCallback(
    (value: number) => {
      debugLog("Set training strength directly: ", value);
      setTrainingStrengthPercent(value);
    },
    [setTrainingStrengthPercent],
  );

  const trainingStrengthValue = React.useMemo(() => {
    return trainingStrengthPercent > 0.5 ? 0.8 : 0.3;
  }, [trainingStrengthPercent]);

  function formatTrainingDuration(seconds: number): string {
    const hours = Math.max(1, Math.round(seconds / 60 / 60));
    return `${hours}${hours === 1 ? " hour" : " hours"}`;
  }

  const { steps } = React.useMemo(
    () =>
      getCustomModelTrainingInputFromSliderValue({
        percent: trainingStrengthPercent,
        sliderOptions: trainingSliderConfigs.options,
      }),
    [trainingStrengthPercent, trainingSliderConfigs.options],
  );

  return (
    <form
      className="flex flex-col gap-6 text-sm text-zinc-500"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="flex flex-col gap-1">
        <div className="text-base text-zinc-300 font-semibold">Custom Model Training</div>
        <div className="text-sm">
          <ul>
            <li>• For best results, upload 3 or more photos of your project</li>
            <li>• Images must be at least 384px × 384px in size</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="flex justify-between items-center">
          <div className="text-zinc-300 font-semibold">Model Type</div>
          <ConfigureStrengthButton />
        </div>
        <ToggleGroupInput
          type="single"
          value={trainingStrengthValue}
          onValueChange={setTrainingInputFromStep}
          rootClassName="w-full"
          itemClassName="min-h-[110px] rounded-md border border-solid border-zinc-800 data-[state=on]:bg-zinc-800 data-[state=on]:text-zinc-300 data-[state=on]:border-solid data-[state=on]:border-zinc-500 font-semibold w-full justify-start items-start"
          options={[
            {
              name: "Product",
              icon: <Box size={18} />,
              description: "Specifically train on a single product",
              value: 0.8,
            },
            {
              name: "Style",
              icon: <Palette size={18} />,
              description: "Generally train on a thematic aesthetic",
              value: 0.3,
            },
          ]}
        />
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="text-zinc-300 font-semibold">Training Speed</div>
        <ToggleGroupInput
          type="single"
          value={trainingBackendType}
          onValueChange={setTrainingBackendType}
          rootClassName="w-full"
          itemClassName="min-h-[110px] rounded-md border border-solid border-zinc-800 data-[state=on]:bg-zinc-800 data-[state=on]:text-zinc-300 data-[state=on]:border-solid data-[state=on]:border-zinc-500 font-semibold w-full justify-center items-start"
          options={[
            {
              name: customModelTrainingBackendToDisplayName[
                CustomModelTrainingBackendType.GenericHardware
              ],
              icon: <Clock size={18} />,
              description: `Takes about ${formatTrainingDuration(getDurationSecondsFromTrainingSteps(steps))}`,
              value: CustomModelTrainingBackendType.GenericHardware,
            },
            {
              name: customModelTrainingBackendToDisplayName[CustomModelTrainingBackendType.Fal],
              icon: <Rocket size={18} />,
              description: "Takes about 5 minutes",
              value: CustomModelTrainingBackendType.Fal,
            },
          ]}
        />
      </div>
    </form>
  );
}

function getCustomModelContentOrStyleFromWorkflow({
  customModelWorkflow = CustomModelType.Custom,
}: {
  customModelWorkflow?: CustomModelType;
}): CustomModelTrainingContentOrStyle {
  if (customModelWorkflow === CustomModelType.Style) {
    return CustomModelTrainingContentOrStyle.Style;
  } else if (customModelWorkflow === CustomModelType.Custom) {
    return CustomModelTrainingContentOrStyle.Balanced;
  } else if (customModelWorkflow === CustomModelType.Face) {
    return CustomModelTrainingContentOrStyle.Balanced;
  } else {
    return CustomModelTrainingContentOrStyle.Content;
  }
}

function getTrainingInputFromCustomModelTrainingInputContext({
  ...context
}: Partial<CustomModelTrainingInputContextType>): Omit<CustomModelTrainingInput, "trigger_word"> {
  const { customModelWorkflow } = editorContextStore.getState();

  const sliderConfigs =
    customModelWorkflowData[customModelWorkflow]?.trainingSliderConfigs ??
    defaultCustomModelTrainingInputSliderOptions;

  const {
    trainingStrengthPercent = 0.5,
    trainingBackendType = CustomModelTrainingBackendType.GenericHardware,
  } = context;

  const { steps, iterMultipler, learningRate } = getCustomModelTrainingInputFromSliderValue({
    percent: trainingStrengthPercent,
    sliderOptions: sliderConfigs.options,
  });

  debugLog(`Training backend type: ${trainingBackendType}`);

  if (trainingBackendType === CustomModelTrainingBackendType.Fal) {
    const input: Omit<CustomModelTrainingInputFalSpecific, "trigger_word"> = {
      backendType: CustomModelTrainingBackendType.Fal,
      trainingStrengthPercent,
      is_style:
        getCustomModelTypeFromFrontendDisplayTemplateType(customModelWorkflow) ===
        CustomModelType.Style,
      iter_multiplier: iterMultipler,
    };

    return input;
  } else {
    // const input: Omit<CustomModelTrainingInputFalSpecific, 'trigger_word'> = {
    //     backendType: CustomModelTrainingBackendType.Fal,
    //     trainingStrengthPercent,
    //     is_style: customModelWorkflow === CustomModelType.Style,
    //     iter_multiplier: clamp(Math.round(steps) * 0.0006, 1.0, 2.5),
    // };

    const input: Omit<CustomModelTrainingInputGenericHardwareSpecific, "trigger_word"> = {
      backendType: CustomModelTrainingBackendType.GenericHardware,
      steps,
      autocaption: true,
      batch_size: 4,
      learning_rate: learningRate,
      trainingStrengthPercent,
      content_or_style: getCustomModelContentOrStyleFromWorkflow({
        customModelWorkflow: getCustomModelTypeFromFrontendDisplayTemplateType(customModelWorkflow),
      }),
    };

    return input;
  }
}

export type CustomModelStartTrainEditorProps = {
  modelId?: string;
  onExit: () => void;
  isDialog?: boolean;
  className?: string;
  disabledStart?: boolean;
};

export function CustomModelStartTrainEditor({
  modelId,
  onExit,
  isDialog = true,
  className = "",
  disabledStart = false,
}: CustomModelStartTrainEditorProps) {
  const { trainingStrengthPercent, trainingBackendType } = useCustomModelTraininingInput();
  const dataset = editorContextStore((state) => state.customModelDataset);
  const customModelTrainings = editorContextStore((state) => state.customModelTrainings);
  const [isLoading, setIsLoading] = React.useState(false);

  const needDatasetInfo = React.useMemo(() => Object.entries(dataset || {}).length <= 0, [dataset]);

  const canUserEdit = useCanUserEditCustomModel();

  debugLog(`Custom model training strength percent: ${trainingStrengthPercent}`);

  const buttonClassName = React.useMemo(() => {
    if (disabledStart) {
      return "bg-zinc-800 select-none text-center text-zinc-500 font-semibold transition-colors cursor-not-allowed max-w-full flex flex-row items-center justify-center gap-2 px-3 py-2 rounded-lg";
    }

    if (!canUserEdit || needDatasetInfo) {
      return PrimaryButtonClassNameDisabled;
    }

    return customModelTrainings && Object.values(customModelTrainings).length > 0
      ? SecondaryButtonClassName
      : PrimaryButtonClassName;
  }, [disabledStart, canUserEdit, needDatasetInfo, customModelTrainings]);

  return (
    <div
      className={classNames(
        isDialog
          ? classNames(
              DropdownClassName,
              "md:min-w-[50vw] xl:min-w-[40vw] max-h-[80vh] px-4 py-3 rounded-xl",
            )
          : "",
        "flex flex-col gap-8",
        className,
      )}
    >
      {isDialog && (
        <div className="flex flex-row items-center">
          <Dialog.DialogTitle
            className="flex-1 text-base truncate font-semibold text-zinc-500"
            aria-describedby="Training configuration"
          >
            Training configuration
          </Dialog.DialogTitle>
          <Dialog.Close>
            <Cross1Icon className="text-zinc-500 hover:text-zinc-300 active:text-zinc-700 cursor-pointer transition-colors" />
          </Dialog.Close>
        </div>
      )}
      <CustomModelTraininingInputEditor />
      <div className="flex flex-col gap-2">
        {needDatasetInfo && (
          <div className="flex flex-row items-center bg-lime-900/20 border border-lime-900/30 p-2 rounded-lg shadow-md text-lime-400 text-sm font-semibold">
            <TriangleAlert size={18} className="mr-2" />
            {needDatasetInfo && <div>Please upload at least one image to start training</div>}
          </div>
        )}
        <button
          className={classNames(
            buttonClassName,
            "pointer-events-auto flex flex-row items-center justify-center gap-2",
          )}
          onClick={() => {
            if (disabledStart || isLoading || !modelId || !canUserEdit) {
              return;
            }

            setIsLoading(true);

            const { backend, eventEmitter } = editorContextStore.getState();

            if (!backend || needDatasetInfo) {
              setIsLoading(false);
              return;
            }

            const triggerWord = getModelTriggerWord({
              modelId,
            });

            backend
              .startCustomModelTraining({
                modelId,
                trainingInput: {
                  ...getTrainingInputFromCustomModelTrainingInputContext({
                    trainingStrengthPercent,
                    trainingBackendType,
                  }),
                  trigger_word: triggerWord,
                } as CustomModelTrainingInput,
              })
              .then((response) => {
                if (!response.ok) {
                  // @ts-expect-error
                  if (!response.message?.includes("quota left")) {
                    eventEmitter.emit<UiDisplayMessageEventHandler>(
                      "ui:display-message",
                      "error",
                      // @ts-expect-error
                      `Error starting training: ${response.message}`,
                    );
                  }

                  debugError("Error starting training: ", response);
                }
              })
              .finally(() => {
                setIsLoading(false);

                onExit();
              });
          }}
        >
          {disabledStart ? (
            <>Training in Progress</>
          ) : canUserEdit ? (
            <>
              {isLoading && <SimpleSpinner width={18} height={18} pathClassName="fill-lime-800" />}
              <span>{isLoading ? "Starting ..." : "Start Training"}</span>
            </>
          ) : (
            <>Cannot Train Example Model</>
          )}
        </button>
      </div>
    </div>
  );
}

export function CustomModelStartTrainEditorDialog({
  triggerChildren,
  triggerProps,
  customModelStartTrainEditorProps,
  ...dialogProps
}: Dialog.DialogProps & {
  triggerChildren: React.ReactNode;
  triggerProps?: Dialog.DialogTriggerProps;
  customModelStartTrainEditorProps: CustomModelStartTrainEditorProps;
}) {
  return (
    <Dialog.Root {...dialogProps}>
      <Dialog.Trigger {...triggerProps}>{triggerChildren}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={styles.DialogOverlay}
          style={{
            zIndex: FloatTagZIndex,
          }}
        />
        <Dialog.Content
          className={classNames(styles.DialogContent, "relative focus-visible:outline-lime-500")}
          style={{
            zIndex: FloatTagZIndex,
          }}
          aria-describedby="Training configuration"
        >
          <CustomModelStartTrainEditor {...customModelStartTrainEditorProps} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
