import { IHistory } from "@/core/common/interfaces";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { downloadImageDataUrl } from "components/utils/data";
import EventEmitter from "events";
import { cloneDeep, throttle } from "lodash";
import {
  Bounds,
  CanvasBrushStrokeData,
  CanvasImageData,
  getAffineTransformationMatrixForBounds,
  getBoundsFromCanvasImagePosition,
  HtmlCanvasController,
  HtmlCanvasControllerProps,
  HtmlCanvasObjectData,
  isBoundsValid,
} from "./html-canvas-controller";

export enum PaintMaskCanvasDataType {
  BrushStroke = "brush-stroke",
  BackgroundImage = "background-image",
  MaskImage = "mask-image",
  ClearMask = "clear-mask",
  // Any other necessary canvas actions to perform
}

export type PaintMaskCanvasAction =
  | { type: PaintMaskCanvasDataType.BrushStroke; data: CanvasBrushStrokeData }
  | { type: PaintMaskCanvasDataType.BackgroundImage; data: CanvasImageData }
  | { type: PaintMaskCanvasDataType.MaskImage; data: CanvasImageData }
  | { type: PaintMaskCanvasDataType.ClearMask };

export interface PaintMaskCanvasHistorySnapshot {
  actions: PaintMaskCanvasAction[];
  currentIndex: number;
}

export class PaintMaskCanvasHistory
  extends EventEmitter
  implements IHistory<PaintMaskCanvasAction>
{
  private actions: PaintMaskCanvasAction[] = [];
  private currentIndex: number = -1;

  initialize() {}

  destroy() {
    this.reset();
  }

  export(): PaintMaskCanvasHistorySnapshot {
    const { actions, currentIndex } = this;
    return cloneDeep({
      actions,
      currentIndex,
    });
  }

  import({ actions, currentIndex }: PaintMaskCanvasHistorySnapshot) {
    this.actions = cloneDeep(actions);
    this.currentIndex = currentIndex;
    this.emit("save");
  }

  save(action?: PaintMaskCanvasAction) {
    if (!action) {
      return;
    }
    this.actions = this.actions.slice(0, this.currentIndex + 1);
    this.actions.push(cloneDeep(action));
    this.currentIndex++;
    this.emit("save");
  }

  undo = throttle(() => {
    if (this.currentIndex >= 0) {
      this.currentIndex--;
      this.emit("undo");
    }
  }, 100);

  redo = throttle(() => {
    if (this.currentIndex < this.actions.length - 1) {
      this.currentIndex++;
      this.emit("redo");
    }
  }, 100);

  get currentActions(): PaintMaskCanvasAction[] {
    return this.actions.slice(0, this.currentIndex + 1);
  }

  numUndos = () => this.currentIndex + 1;
  numRedos = () => this.actions.length - (this.currentIndex + 1);

  reset() {
    this.actions.length = 0;
    this.currentIndex = -1;
  }
}

export enum PaintMaskCanvasControllerPaintBrushType {
  Paint = "paint",
  Erase = "erase",
}

export class PaintMaskCanvasController extends HtmlCanvasController {
  private isInitialized = false;
  private background: HtmlCanvasObjectData[] = [];
  private maskContext?: CanvasRenderingContext2D;
  private _isCanvasMoving = false;
  private tmpPoint0 = { x: 0, y: 0 };
  private currentBrushStrokeData?: CanvasBrushStrokeData;

  historyRef: { current: PaintMaskCanvasHistory };

  constructor({
    historyRef,
    ...props
  }: HtmlCanvasControllerProps & {
    historyRef: { current: PaintMaskCanvasHistory };
  }) {
    super(props);
    this.historyRef = historyRef;
    this.updateBackground();

    debugLog("Initialize paint mask canvas controller");

    this.history.on("save", this.render);
    this.history.on("undo", this.render);
    this.history.on("redo", this.render);
  }

  private updateBackground() {
    const { boundsWidth, boundsHeight } = this;
    this.background = [
      {
        type: "grid",
        color: "#27272a",
        gridSize: 100,
        width: boundsWidth,
        height: boundsHeight,
      },
      {
        type: "hollow-rect",
        strokeStyle: "#27272a",
        lineWidth: 1,
        x: 0,
        y: 0,
        width: boundsWidth,
        height: boundsHeight,
      },
    ];
  }

  get isCanvasMoving() {
    return this._isCanvasMoving;
  }

  set isCanvasMoving(value: boolean) {
    if (value !== this._isCanvasMoving) {
      this._isCanvasMoving = value;
    }
  }

  get history() {
    return this.historyRef.current;
  }

  setMaskContext(ctx: CanvasRenderingContext2D) {
    this.maskContext = ctx;
  }

  clearImages() {
    this.dirty = true;
  }

  destroy() {
    this.clearImages();
  }

  init() {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    this.center();
    this.dirty = true;
  }

  setBackgroundImage(image: HTMLImageElement) {
    const imageData = this.getImageData(image);
    if (imageData) {
      const action: PaintMaskCanvasAction = {
        type: PaintMaskCanvasDataType.BackgroundImage,
        data: imageData,
      };
      this.history.save(action);
      this.render();
    }
  }

  setMaskImage(image: HTMLImageElement) {
    const imageData = this.getImageData(image);
    if (imageData) {
      const action: PaintMaskCanvasAction = {
        type: PaintMaskCanvasDataType.MaskImage,
        data: imageData,
      };
      this.history.save(action);
      this.render();
    }
  }

  render = () => {
    const ctx = this.ctx;
    const maskCtx = this.maskContext;
    if (!ctx || !maskCtx) {
      return;
    }

    this.canvasDefault();
    this.clearCanvas();

    this.apply();

    this.background.forEach((object) => {
      HtmlCanvasController.drawObject({
        ctx,
        object,
      });
    });

    this.renderActions();

    this.canvasDefault();
  };

  private renderBackgroundActions(actions: PaintMaskCanvasAction[], ctx: CanvasRenderingContext2D) {
    const renderBackgroundImageActions = actions.filter(
      (action) => action.type === PaintMaskCanvasDataType.BackgroundImage,
    );

    if (renderBackgroundImageActions.length > 0) {
      const lastAction = renderBackgroundImageActions[renderBackgroundImageActions.length - 1];

      this.renderAction(lastAction, ctx);
    }

    // actions
    //     .filter((action) => action.type === PaintMaskCanvasDataType.BackgroundImage)
    //     .forEach((action) => this.renderAction(action, ctx));
  }

  private getMaskActionsAfterLastClear(actions: PaintMaskCanvasAction[]) {
    // Find the index of the last ClearMask action
    const lastClearIndex =
      actions
        .map((action, index) => ({ action, index }))
        .filter(({ action }) => action.type === PaintMaskCanvasDataType.ClearMask)
        .map(({ index }) => index)
        .pop() ?? -1;

    const shouldClearMask = lastClearIndex !== -1;

    // Get mask actions after the last ClearMask action
    const maskActions = actions
      .slice(lastClearIndex + 1)
      .filter(
        (action) =>
          action.type !== PaintMaskCanvasDataType.BackgroundImage &&
          action.type !== PaintMaskCanvasDataType.ClearMask,
      );

    return { maskActions, shouldClearMask };
  }

  private renderMaskActions(actions: PaintMaskCanvasAction[], ctx: CanvasRenderingContext2D) {
    // Get mask actions after the last ClearMask action
    const { maskActions, shouldClearMask } = this.getMaskActionsAfterLastClear(actions);

    // Clear the context if necessary
    if (shouldClearMask) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    // Render mask actions
    maskActions.forEach((action) => this.renderAction(action, ctx));
  }

  private renderActions() {
    const ctx = this.ctx;
    const maskCtx = this.maskContext;
    if (!ctx || !maskCtx) {
      return;
    }

    const actions = this.history.currentActions;

    // Render background actions
    this.renderBackgroundActions(actions, ctx);

    // Render mask actions
    this.renderMaskActions(actions, maskCtx);

    // Render the current brush stroke if it exists
    if (this.currentBrushStrokeData) {
      HtmlCanvasController.drawBrushStroke({
        ctx: maskCtx,
        object: this.currentBrushStrokeData,
      });
    }
  }

  private renderAction(action: PaintMaskCanvasAction, ctx: CanvasRenderingContext2D) {
    if (!ctx) {
      return;
    }

    switch (action.type) {
      case PaintMaskCanvasDataType.BackgroundImage:
      case PaintMaskCanvasDataType.MaskImage:
        if (action.data && action.data.image) {
          HtmlCanvasController.drawImage({
            ctx,
            object: action.data,
          });
        }
        break;
      case PaintMaskCanvasDataType.BrushStroke:
        HtmlCanvasController.drawBrushStroke({
          ctx,
          object: action.data,
        });
        break;
    }
  }

  getLocalCoordinateFromPixelCoordinate(point: { x: number; y: number }): {
    x: number;
    y: number;
  } {
    const { x, y } = this.toWorld(point, this.tmpPoint0);
    return { x, y };
  }

  startBrushStroke({
    point,
    brushType,
    color,
    lineWidth,
  }: {
    point: { x: number; y: number };
    brushType: PaintMaskCanvasControllerPaintBrushType;
    color: string;
    lineWidth: number;
  }) {
    const localPoint = this.getLocalCoordinateFromPixelCoordinate(point);
    const brushStrokeData: CanvasBrushStrokeData = {
      type: "brush-stroke",
      brushType,
      color,
      lineWidth: lineWidth / this.scale,
      points: [localPoint.x, localPoint.y],
    };
    this.currentBrushStrokeData = brushStrokeData;
    this.emit("brush-stroke:start");
  }

  moveBrushStroke(point: { x: number; y: number }) {
    if (!this.currentBrushStrokeData) {
      return;
    }
    const localPoint = this.getLocalCoordinateFromPixelCoordinate(point);
    this.currentBrushStrokeData.points.push(localPoint.x, localPoint.y);
  }

  endBrushStroke() {
    if (this.currentBrushStrokeData) {
      const action: PaintMaskCanvasAction = {
        type: PaintMaskCanvasDataType.BrushStroke,
        data: this.currentBrushStrokeData,
      };
      this.history.save(action);
      this.currentBrushStrokeData = undefined;
    }
    this.emit("brush-stroke:end");
  }

  clearCanvas() {
    if (!this.ctx) {
      return;
    }
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    this.maskContext?.clearRect(
      0,
      0,
      this.maskContext.canvas.width,
      this.maskContext.canvas.height,
    );
  }

  canvasDefault(): void {
    if (!this.ctx) {
      return;
    }

    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.maskContext?.setTransform(1, 0, 0, 1, 0, 0);
  }

  apply(): void {
    if (this.dirty) {
      this.update();
    }
    this.ctx?.setTransform(...this.matrix);
    this.maskContext?.setTransform(...this.matrix);
  }

  private getImageCanvasBoundingBox(): Bounds | null {
    const actions = this.history.currentActions;

    const imageActions = actions.filter(
      (action) => action.type === PaintMaskCanvasDataType.BackgroundImage,
    ) as {
      type: PaintMaskCanvasDataType.BackgroundImage;
      data: CanvasImageData;
    }[];

    if (imageActions.length === 0) {
      // No image actions found
      return null;
    }

    // Initialize bounds with extreme values
    const bounds: Bounds = {
      left: Infinity,
      top: Infinity,
      right: -Infinity,
      bottom: -Infinity,
    };

    // Compute the union of all image bounds
    imageActions.forEach((action) => {
      const imageData = action.data;
      const imageBounds = getBoundsFromCanvasImagePosition(imageData.target);

      bounds.left = Math.min(bounds.left, imageBounds.left);
      bounds.top = Math.min(bounds.top, imageBounds.top);
      bounds.right = Math.max(bounds.right, imageBounds.right);
      bounds.bottom = Math.max(bounds.bottom, imageBounds.bottom);
    });

    // Validate bounds
    if (
      bounds.left === Infinity ||
      bounds.top === Infinity ||
      bounds.right === -Infinity ||
      bounds.bottom === -Infinity
    ) {
      return null;
    }

    return bounds;
  }

  getCanvasDataUrl(
    { getImage, getMask } = {
      getImage: true,
      getMask: true,
    },
  ) {
    if (!this.ctx || !this.maskContext || (!getImage && !getMask)) {
      debugError("Paint mask canvas context is not initialized: ", {
        ctx: this.ctx,
        maskContext: this.maskContext,
        getImage,
        getMask,
      });
      return {
        imageDataUrl: undefined,
        maskDataUrl: undefined,
      };
    }

    // Get the bounding box of the image canvas
    const bounds = this.getImageCanvasBoundingBox();

    // Check if the bounding box is valid
    if (!bounds || !isBoundsValid(bounds)) {
      debugError("Paint image canvas bounds is invalid: ", bounds);
      return {
        imageDataUrl: undefined,
        maskDataUrl: undefined,
      };
    }

    // Calculate the width and height of the bounding box
    const width = bounds.right - bounds.left;
    const height = bounds.bottom - bounds.top;

    // Create temporary canvases matching the bounding box size
    const tempImageCanvas = document.createElement("canvas");
    const tempMaskCanvas = document.createElement("canvas");
    tempImageCanvas.width = width;
    tempImageCanvas.height = height;
    tempMaskCanvas.width = width;
    tempMaskCanvas.height = height;

    const tempImageCtx = tempImageCanvas.getContext("2d");
    const tempMaskCtx = tempMaskCanvas.getContext("2d");

    if (!tempImageCtx || !tempMaskCtx) {
      debugError("Temp image canvas context is invalid: ", {
        tempImageCtx,
        tempMaskCtx,
      });
      return {
        imageDataUrl: undefined,
        maskDataUrl: undefined,
      };
    }

    // Calculate the transformation matrix to fit the bounding box into the temporary canvas
    const { transformMatrix } = getAffineTransformationMatrixForBounds(bounds, width, height);

    // Apply the transformation to the temporary contexts
    tempImageCtx.setTransform(...transformMatrix);
    tempMaskCtx.setTransform(...transformMatrix);

    // Render actions onto the temporary contexts
    const actions = this.history.currentActions;

    // Render background actions onto tempImageCtx
    if (getImage) {
      this.renderBackgroundActions(actions, tempImageCtx);
    }

    // Render mask actions onto tempMaskCtx
    if (getMask) {
      this.renderMaskActions(actions, tempMaskCtx);
      // Also render current brush stroke if any
      if (this.currentBrushStrokeData) {
        HtmlCanvasController.drawBrushStroke({
          ctx: tempMaskCtx,
          object: this.currentBrushStrokeData,
        });
      }
    }

    // Get the data URLs from the temporary canvases
    const imageDataUrl = getImage ? tempImageCanvas.toDataURL() : undefined;
    const maskDataUrl = getMask ? tempMaskCanvas.toDataURL() : undefined;

    // Clean up temporary canvases and contexts
    tempImageCtx.setTransform(1, 0, 0, 1, 0, 0); // Reset transformations
    tempMaskCtx.setTransform(1, 0, 0, 1, 0, 0);

    tempImageCtx.clearRect(0, 0, tempImageCanvas.width, tempImageCanvas.height);
    tempMaskCtx.clearRect(0, 0, tempMaskCanvas.width, tempMaskCanvas.height);

    // Return the data URLs
    return {
      imageDataUrl,
      maskDataUrl,
    };
  }

  saveCanvas() {
    if (process.env.NODE_ENV !== "development") {
      return;
    }

    const { imageDataUrl, maskDataUrl } = this.getCanvasDataUrl();

    if (imageDataUrl) {
      downloadImageDataUrl(imageDataUrl, "paint-image");
    }

    if (maskDataUrl) {
      downloadImageDataUrl(maskDataUrl, "paint-mask");
    }
  }

  setBackgroundImageFromUrl(url: string) {
    return new Promise<void>((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = () => {
        this.setBackgroundImage(image);
        this.render();
        resolve();
      };
      image.onerror = () => {
        console.error("Failed to load background image from URL:", url);
        reject();
      };
      image.src = url;
    });
  }

  setMaskImageFromUrl(url: string) {
    return new Promise<void>((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = () => {
        this.setMaskImage(image);
        this.render();
        resolve();
      };
      image.onerror = () => {
        console.error("Failed to load mask image from URL:", url);
        reject();
      };
      image.src = url;
    });
  }

  handleKeyDown = (event: KeyboardEvent) => {
    let isHandled = false;
    if (this.isUndo(event)) {
      this.historyRef.current?.undo();
      isHandled = true;
    } else if (this.isRedo(event)) {
      this.historyRef.current?.redo();
      isHandled = true;
    } else if (this.isSave(event)) {
      this.saveCanvas();
      isHandled = true;
    }
    return {
      isHandled,
    };
  };

  isUndo(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && !event.shiftKey && event.key.toLowerCase() === "z";
  }

  isRedo(event: KeyboardEvent) {
    return (
      ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key.toLowerCase() === "z") ||
      ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "y")
    );
  }

  isSave(event: KeyboardEvent) {
    return (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "s";
  }

  clearMask() {
    const action: PaintMaskCanvasAction = {
      type: PaintMaskCanvasDataType.ClearMask,
    };
    this.history.save(action);
    this.render();
  }

  isMaskEmpty(): boolean {
    const actions = this.history.currentActions;

    // Find the index of the last ClearMask action
    const lastClearIndex =
      actions
        .map((action, index) => ({ action, index }))
        .filter(({ action }) => action.type === PaintMaskCanvasDataType.ClearMask)
        .map(({ index }) => index)
        .pop() ?? -1;

    // Get mask actions after the last ClearMask action
    const maskActions = actions
      .slice(lastClearIndex + 1)
      .filter(
        (action) =>
          action.type === PaintMaskCanvasDataType.BrushStroke ||
          action.type === PaintMaskCanvasDataType.MaskImage,
      );

    // Check if there are any mask actions or current brush stroke
    if (maskActions.length === 0 && !this.currentBrushStrokeData) {
      return true; // Mask is empty
    } else {
      return false; // Mask is not empty
    }
  }
}
