import { CustomModelPostProcessActionType } from "@/backend/custom-model-post-process";
import { CustomModelPlaygroundPromptEditorState, StateUpdater } from "@/core/common/types";
import React, { createContext, useContext, useState } from "react";
import { PaintMaskCanvasControllerPaintBrushType } from "../editor/paint-mask-canvas-controller";
import { getEmptyCustomModelPlaygroundPromptEditorState } from "./custom-model-playground-context";

export enum CustomModelUpscaleStatus {
  Idle = "Idle",
  Loading = "Loading",
}

export type UpscaleBackendType =
  | CustomModelPostProcessActionType.UpscaleCreative
  | CustomModelPostProcessActionType.ClarityUpscale;

interface CustomModelUpscaleContextType {
  backendType: UpscaleBackendType;
  setBackendType: (value: StateUpdater<UpscaleBackendType>) => void;

  status: CustomModelUpscaleStatus;
  setStatus: (value: StateUpdater<CustomModelUpscaleStatus>) => void;

  imageUrl: string;
  setImageUrl: (value: StateUpdater<string>) => void;

  maskImageUrl: string | undefined;
  setMaskImageUrl: (value: StateUpdater<string | undefined>) => void;

  creativity: number;
  setCreativity: (value: StateUpdater<number>) => void;

  upscaleFactor: number;
  setUpscaleFactor: (value: StateUpdater<number>) => void;

  maskBrushType: PaintMaskCanvasControllerPaintBrushType;
  setMaskBrushType: (value: StateUpdater<PaintMaskCanvasControllerPaintBrushType>) => void;

  maskBrushSize: number;
  setMaskBrushSize: (value: StateUpdater<number>) => void;

  promptEditorState: CustomModelPlaygroundPromptEditorState;
  setPromptEditorState: (value: StateUpdater<CustomModelPlaygroundPromptEditorState>) => void;
}

const CustomModelUpscaleContext = createContext<CustomModelUpscaleContextType | undefined>(
  undefined,
);

export function useCustomModelUpscaleContext() {
  const context = useContext(CustomModelUpscaleContext);

  if (!context) {
    throw new Error("useCustomModelUpscaleContext must be used with in CustomModelUpscaleContext");
  }

  return context;
}

export function CustomModelUpscaleContextProvider({ children }: React.PropsWithChildren) {
  const [backendType, setBackendType] = useState<UpscaleBackendType>(
    CustomModelPostProcessActionType.ClarityUpscale,
  );

  const [status, setStatus] = useState(CustomModelUpscaleStatus.Idle);

  const [imageUrl, setImageUrl] = useState<string>("");
  const [maskImageUrl, setMaskImageUrl] = useState<string | undefined>(undefined);

  const [upscaleFactor, setUpscaleFactor] = React.useState(2);

  const [maskBrushType, setMaskBrushType] = useState(PaintMaskCanvasControllerPaintBrushType.Paint);

  const [maskBrushSize, setMaskBrushSize] = useState(25);

  const [creativity, setCreativity] = useState(5);

  const [promptEditorState, setPromptEditorState] =
    useState<CustomModelPlaygroundPromptEditorState>(
      getEmptyCustomModelPlaygroundPromptEditorState(),
    );

  return (
    <CustomModelUpscaleContext.Provider
      value={{
        status,
        setStatus,
        imageUrl,
        setImageUrl,
        maskImageUrl,
        setMaskImageUrl,
        creativity,
        setCreativity,
        upscaleFactor,
        setUpscaleFactor,
        maskBrushType,
        setMaskBrushType,
        maskBrushSize,
        setMaskBrushSize,
        backendType,
        setBackendType,
        promptEditorState,
        setPromptEditorState,
      }}
    >
      {children}
    </CustomModelUpscaleContext.Provider>
  );
}
