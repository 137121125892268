import React from "react";

export function FullScreenAuthContainer({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className="w-screen h-screen flex justify-center items-center bg-zinc-900 text-zinc-500 overflow-y-auto">
      <div
        className={`w-[90%] md:w-[500px] px-8 md:px-12 py-6 rounded-lg flex flex-col bg-zinc-800/50 border border-zinc-700/30 items-center shadow-lg box-border ${className}`}
        {...props}
      >
        {children}
      </div>
    </div>
  );
}
