import { Backend } from "@/backend/base";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { ImageComponent } from "components/utils/image";
import { mergeRefs } from "components/utils/merge-refs";

import { isDataURL, isValidHttpsUrl } from "@/core/utils/string-utils";
import React from "react";

export interface VideoComponentProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
  thumbnailUrl: string;
  selected?: boolean;
  backend?: Backend;
}

function useVideoSrc({
  src,
  backend,
  isHovering,
}: {
  src: string;
  backend?: Backend;
  isHovering: boolean;
}) {
  const [videoSrc, setVideoSrc] = React.useState("");

  const isFirstLoadRef = React.useRef(true);

  React.useEffect(() => {
    if (!isHovering || !src) {
      return;
    }

    if (!isFirstLoadRef.current) {
      return;
    }

    isFirstLoadRef.current = true;

    if (isValidHttpsUrl(src) || isDataURL(src)) {
      setVideoSrc(src);
      return;
    }

    if (!backend) {
      return;
    }

    Assets.loadAssetFromPath({
      backend,
      path: src,
    }).then((videoSrc) => {
      setVideoSrc(videoSrc ?? "");
    });
  }, [src, backend, isHovering]);

  return videoSrc;
}

export const VideoComponent = React.forwardRef<HTMLVideoElement, VideoComponentProps>(
  (props, ref) => {
    const { src, thumbnailUrl, className = "", backend, ...rest } = props;
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const [isHovering, setIsHovering] = React.useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
    const videoSrc = useVideoSrc({
      src,
      backend,
      isHovering,
    });

    // eslint-disable-next-line react-compiler/react-compiler
    const combinedRef = React.useMemo(() => mergeRefs([videoRef, ref]), [videoRef, ref]);

    const handlePointerEnter = () => {
      setIsHovering(true);
    };

    const handlePointerLeave = () => {
      setIsHovering(false);
      setIsVideoLoaded(false); // Reset video loaded state when not hovering
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };

    const handleCanPlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }

      setIsVideoLoaded(true);
    };

    React.useEffect(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.addEventListener("canplay", handleCanPlay);
        return () => {
          videoElement.removeEventListener("canplay", handleCanPlay);
        };
      }
    }, [videoSrc, isHovering]);

    return (
      <div
        onMouseEnter={handlePointerEnter}
        onMouseLeave={handlePointerLeave}
        className={classNames("relative", className)}
      >
        <ImageComponent
          src={thumbnailUrl}
          alt=""
          className={classNames(
            "w-full h-full object-cover transition-opacity duration-500",
            isHovering && isVideoLoaded ? "opacity-0" : "opacity-100",
          )}
        />
        {videoSrc && isHovering && (
          <video
            {...rest}
            muted
            loop
            preload="auto"
            ref={combinedRef}
            className={classNames(
              "w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-500",
              isVideoLoaded ? "opacity-100" : "opacity-0",
            )}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        )}
      </div>
    );
  },
);
