export enum UserAssetType {
  UploadedProduct = "uploaded-product",
  GeneratedResult = "generated-result",
  GenerateTemplateScene = "generate-template-scene",
  RenderInput = "render-input",
  RenderInputMask = "render-input-mask",
  MagicEraseOutput = "magic-erase-output",
  UpscaleOutput = "upscale-output",
  RemoveBackgroundOutput = "remove-background-output",
  RegenerateProducteErasedImage = "regenerate-product-erased-image",
  RegenerateProductMaskImage = "regenerate-product-mask-image",
  Props3dCanvasSnapshot = "asset3d-canvas-snapshot",
  UploadedProps = "uploaded-props",
  ReferenceImage = "reference-image",
  TryOnClothImage = "tryon-cloth-image",
  TryOnRenderResult = "tryon-render-result",
  ImageTo3dResult = "image-to-3d-result",
  CustomModelDatasetItem = "custom-model-dataset-item",
  VideoGenerationKeyframe = "video-generation-keyframe",
  VideoGenerationResult = "video-generation-result",
  Unknown = "unknown",
}

export enum UserAssetTag {
  ReferenceFaceImage = "ReferenceFaceImage",
}
