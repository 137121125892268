import type { ControllerOptions, EditorState, FabricCanvas } from "@/core/common/interfaces";
import type { EditorConfig } from "@/core/common/types";
import type { Editor } from "@/core/editor";

export class Base {
  protected canvas: FabricCanvas<fabric.Canvas>;
  protected config: EditorConfig;
  protected editor: Editor;
  protected state: EditorState;
  constructor({ canvas, config, editor, state }: ControllerOptions) {
    this.canvas = canvas;
    this.config = config;
    this.editor = editor;
    this.state = state;
  }
}
