import React from "react";
import { fabric } from "fabric";
import { EditorCanvasModalContainer } from "../editor-modal/editor-canvas-modal";
import { editorContextStore } from "contexts/editor-context";
import {
  addRenderImageResultToCanvas,
  callEraseAndRegenerateProduct,
  onRenderImageResultAdded,
} from "components/utils/render";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { classNames } from "@/core/utils/classname-utils";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { ImageComponent } from "components/utils/image";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { RENDER_CANVAS_LEGNTH } from "@/core/common/constants";
import { getPromptFromTemplate, getSubjectFromPromptTemplate } from "@/core/common/prompt-template";
import { removeLastFromImmutableList } from "@/core/utils/array-utils";
import { resetRegenerateProductState } from "hooks/use-regenerate-product";
import { debugLog } from "@/core/utils/print-utilts";

function GenerationResultItem({
  imageUrl,
  imageAlt = "Generation",
  loadingMessage = "Loading ...",
  className = "",
  loadedClassName = "",
  placeholderUrl,
  showSpinner = true,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  imageUrl?: string;
  placeholderUrl?: string;
  imageAlt?: string;
  loadingMessage?: string;
  loadedClassName?: string;
  showSpinner?: boolean;
}) {
  const [isImageLoaded, setImageLoaded] = React.useState(false);

  React.useEffect(() => {
    setImageLoaded(false);
  }, [imageUrl]);

  return (
    <div
      {...props}
      className={classNames(
        "relative w-full h-full rounded-md border border-zinc-800 overflow-hidden",
        isImageLoaded ? "cursor-auto" : "cursor-wait",
        className,
        isImageLoaded ? loadedClassName : "",
      )}
    >
      {!isImageLoaded && (
        <div className="absolute w-full h-full flex items-center justify-center select-none pointer-events-none bg-zinc-900/80">
          {showSpinner && (
            <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" className="mr-2" />
          )}
          {loadingMessage}
        </div>
      )}
      {placeholderUrl && (
        <ImageComponent
          src={placeholderUrl}
          className="w-full h-full object-contain"
          alt={imageAlt}
          style={{
            display: isImageLoaded ? "none" : "block",
          }}
        />
      )}
      <img
        src={imageUrl}
        className="w-full h-full object-contain"
        alt={imageAlt}
        style={{
          display: isImageLoaded ? "block" : "none",
        }}
        onLoad={() => setImageLoaded(true)}
        onError={() => setImageLoaded(false)}
      />
      {children}
    </div>
  );
}

export function RegenerateProductModel() {
  const editor = editorContextStore((state) => state.editor);
  const backend = editorContextStore((state) => state.backend);

  const regenerateProductPromptTemplate = editorContextStore(
    (state) => state.regenerateProductPromptTemplate,
  );
  const editingObjectId = editorContextStore((state) => state.editingObjectId);
  const editingObject = React.useMemo(
    () =>
      editingObjectId &&
      (editor?.objects.findOneById(editingObjectId) as fabric.Object | undefined),
    [editor, editingObjectId],
  );
  const regenerateProductReferenceImagePath = editorContextStore(
    (state) => state.regenerateProductReferenceImagePath,
  );

  const editingObjectUrl = React.useMemo(
    () =>
      regenerateProductReferenceImagePath
        ? regenerateProductReferenceImagePath
        : isStaticImageObject(editingObject)
          ? editingObject.getSrc()
          : undefined,
    [editingObject, regenerateProductReferenceImagePath],
  );

  const regenerateProductEraseMaskImagePath = editorContextStore(
    (state) => state.regenerateProductEraseMaskImagePath,
  );

  const regenerateProductResults = editorContextStore((state) => state.regenerateProductResults);
  const regenerateProductNumImages = editorContextStore(
    (state) => state.regenerateProductNumImages,
  );
  const regenerateProductRenderState = editorContextStore(
    (state) => state.regenerateProductRenderState,
  );

  const generatedResults = React.useMemo(() => {
    if (regenerateProductRenderState === "rendering") {
      return regenerateProductResults;
    }

    while (regenerateProductResults.length < regenerateProductNumImages) {
      regenerateProductResults.push({
        imageUrl: undefined,
        isSelected: false,
      });
    }

    return regenerateProductResults;
  }, [regenerateProductResults, regenerateProductNumImages, regenerateProductRenderState]);

  const setGeneratedResults = editorContextStore((state) => state.setRegenerateProductResults);

  const numSelectedResults = React.useMemo(
    () => generatedResults.reduce((sum, { isSelected }) => (isSelected ? sum + 1 : sum), 0),
    [generatedResults],
  );

  const isExitingRef = React.useRef(false);

  const hasAutoRendered = React.useRef(false);

  const handleExitModal = React.useCallback(
    (addImages = false) => {
      debugLog(`Exit regenerate product modal; add images? ${addImages}`);

      if (isExitingRef.current) {
        debugLog("Regenerate product modal is already exiting.");

        return;
      }

      isExitingRef.current = true;

      const {
        activeLeftPanels,
        setEditingObjectId,
        setActiveLeftPanels,
        regenerateProductPromptTemplate,
      } = editorContextStore.getState();

      setEditingObjectId(undefined);

      if (
        activeLeftPanels.length >= 2 &&
        activeLeftPanels[activeLeftPanels.length - 2] === "ReplaceProduct"
      ) {
        // Do not reset the state
        setActiveLeftPanels((prevLeftPanels) => {
          return removeLastFromImmutableList(prevLeftPanels, "Assets", 2);
        });
      } else {
        setActiveLeftPanels((prevLeftPanels) => {
          return removeLastFromImmutableList(prevLeftPanels, "Assets");
        });
      }

      resetRegenerateProductState();

      if (addImages && editor && isStaticImageObject(editingObject)) {
        const width = editingObject.width || RENDER_CANVAS_LEGNTH;
        const height = editingObject.height || RENDER_CANVAS_LEGNTH;
        const startLocation = editingObject.getCenterPoint().add(new fabric.Point(width, 0));

        const generationId = editingObject.generationId;

        editor.assets.getPastGeneration(generationId).then((pastGeneration) => {
          if (!pastGeneration) {
            return;
          }

          const {
            inputImagePath,
            inputMaskImagePath,
            regenerateMaskImagePath,
            regenerateErasedImagePath,
          } = pastGeneration;

          Promise.all(
            generatedResults
              .filter(({ imageUrl, isSelected }) => imageUrl && isSelected)
              .map(async ({ imageUrl }, index) => {
                const renderImageObject = await addRenderImageResultToCanvas({
                  imageUrl,
                  index,
                  width,
                  height,
                  startLocation,
                });

                if (!renderImageObject) {
                  return;
                }

                return onRenderImageResultAdded({
                  outputImage: renderImageObject,
                  prompt: getPromptFromTemplate(regenerateProductPromptTemplate),
                  promptTemplate: regenerateProductPromptTemplate,
                  inputImagePath,
                  inputMaskImagePath,
                  regenerateMaskImagePath,
                  regenerateErasedImagePath,
                });
              }),
          ).finally(() => {
            editorContextStore.getState().setRegenerateProductResults([]);
          });
        });
      } else {
        editorContextStore.getState().setRegenerateProductResults([]);
      }
    },
    [editor, editingObject, generatedResults],
  );

  React.useEffect(() => {
    if (!editor || !backend || !editingObject || hasAutoRendered.current) {
      return;
    }

    const subjectPrompt = getSubjectFromPromptTemplate(regenerateProductPromptTemplate);

    if (!subjectPrompt) {
      return;
    }

    hasAutoRendered.current = true;

    callEraseAndRegenerateProduct({
      editor,
      backend,
      activeObject: editingObject,
    });
  }, [editor, backend, editingObject, regenerateProductPromptTemplate]);

  return (
    <EditorCanvasModalContainer onExit={() => handleExitModal(true)}>
      <div className="flex-1 min-h-0 relative grid grid-cols-2 auto-rows-fr gap-4 items-center justify-center">
        <GenerationResultItem imageUrl={editingObjectUrl} className="group">
          <div className="absolute left-0 top-0 m-2 px-3 py-1.5 rounded-full font-semibold bg-zinc-600/80 text-xs select-none pointer-events-none group-hover:bg-zinc-800 transition-colors">
            Original
          </div>
          <ImageComponent
            className="absolute left-0 top-0 w-full h-full object-contain pointer-events-none opacity-0 group-hover:opacity-20 transition-opacity"
            src={regenerateProductEraseMaskImagePath}
          />
        </GenerationResultItem>
        {generatedResults.map(({ imageUrl, isSelected }, index) => (
          <GenerationResultItem
            key={`Generated ${index}`}
            imageAlt={`Generated ${index}`}
            imageUrl={imageUrl}
            placeholderUrl={editingObjectUrl}
            showSpinner={regenerateProductRenderState === "rendering"}
            loadingMessage={
              regenerateProductRenderState === "rendering"
                ? "Regenerating the product ..."
                : "Click generate button to regenerate the product"
            }
            loadedClassName={classNames(
              "group shadow-lg-center cursor-pointer transition-colors active:border-lime-700",
              isSelected
                ? "border-lime-400 shadow-lime-500/20"
                : "hover:border-lime-500 shadow-lime-500/0",
            )}
            onClick={() => {
              if (!imageUrl) {
                return;
              }
              setGeneratedResults((results) => {
                const newResults = results.slice();
                newResults[index] = {
                  imageUrl,
                  isSelected: !isSelected,
                };
                return newResults;
              });
            }}
          >
            <div
              className={classNames(
                "absolute left-0 top-0 m-2 px-3 py-1.5 rounded-full font-semibold text-xs select-none pointer-events-none  transition-colors truncate",
                isSelected
                  ? "bg-lime-500 text-zinc-900 group-hover:bg-lime-500 shadow"
                  : "bg-zinc-800/80 text-zinc-300 group-hover:bg-zinc-800",
              )}
            >
              Generated {index + 1}
            </div>
          </GenerationResultItem>
        ))}
      </div>
      <div className="w-full mt-4 flex items-center justify-end">
        <button
          className={classNames(
            SecondaryButtonClassNameInactive,
            "min-w-[50px] items-center justify-center",
          )}
          onClick={() => handleExitModal(false)}
        >
          Exit
        </button>
        <div className="w-4" />
        <button
          className={classNames(
            numSelectedResults > 0 ? PrimaryButtonClassName : PrimaryButtonClassNameDisabled,
            "min-w-[50px] items-center justify-center",
          )}
          onClick={() => {
            if (numSelectedResults <= 0) {
              return;
            }
            handleExitModal(true);
          }}
        >
          {numSelectedResults > 0 ? `Add ${numSelectedResults} images` : "No image to add"}
        </button>
      </div>
    </EditorCanvasModalContainer>
  );
}
