import { defaultBackgroundOptions, defaultGenerationFrameOptions } from "@/core/common/constants";
import { ControllerOptions } from "@/core/common/interfaces";
import type {
  GenerateToolPaintTab,
  InpaintBrushType,
  InpaintCanvasSnapshot,
} from "@/core/common/types";
import { EngineType } from "@/core/common/types";
import { isBbox2dOverlapStrict } from "@/core/utils/bbox-utils";
import { getObjectWorldCoords, isObjectInViewport } from "@/core/utils/geometry-utils";
import { isGenerationFrame, isStaticImageObject } from "@/core/utils/type-guards";
import { BackgroundImageOptions } from "@/objects/background-image";
import { fabric } from "fabric";
import _ from "lodash";
import { GENERATION_FRAME_BOTTOM_FILL_DARK } from "../../components/constants/colors";
import { LayerType } from "./../common/layers";
import { Base } from "./base";
import Objects from "./objects";

function loadBackgroundImageFromURL(url: string, options: Partial<BackgroundImageOptions> = {}) {
  return new Promise<fabric.BackgroundImage>((resolve) => {
    fabric.BackgroundImage.fromURL(url, resolve, {
      ...defaultBackgroundOptions,
      ...options,
      crossOrigin: "anonymous",
    });
  });
}

export class GenerationFrames extends Base {
  private labelContainer?: HTMLDivElement;

  private _inpaintCanvasType: GenerateToolPaintTab = "Tags";

  private _visible = true;

  private cameraZoomInv = 1;

  private _inpaintCanvasSnapshots: Record<GenerateToolPaintTab, InpaintCanvasSnapshot> = {
    Tags: {
      type: "Tags",
      commands: [],
    },
    Inpaint: {
      type: "Inpaint",
      commands: [],
    },
  };

  private _getInpaintingCanvas: () => HTMLCanvasElement | null = () => null;

  constructor(props: ControllerOptions) {
    super(props);

    const generationFrame = new fabric.GenerationFrame({
      ...defaultGenerationFrameOptions,
      id: "generation-frame",
      name: "Generation Frame",
    });
    this.canvas?.add(generationFrame);

    // loadBackgroundImageFromURL(
    //     "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
    //     // "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/55fbb185-e305-487b-7908-52b948f26e00/public",
    //     {
    //         id: 'background-image',
    //         name: 'Background Image',
    //     },
    // ).then((backgroundImage) => {
    //     if (!this.canvas?.getContext()) {
    //         return;
    //     }

    //     this.editor.objects.setBackgroundImage(backgroundImage);
    // });

    this.center(true);
  }

  get inpaintCanvasSnapshot() {
    return this._inpaintCanvasSnapshots[this._inpaintCanvasType];
  }

  set inpaintCanvasSnapshot(value: InpaintCanvasSnapshot) {
    if (value) {
      this._inpaintCanvasSnapshots[value.type] = value;
    }
  }

  get generationFrame() {
    return this.canvas?.getObjects().find(isGenerationFrame);
  }

  get inpaintingCanvas() {
    return this._getInpaintingCanvas();
  }

  setInpaintingCanvasGetter(getter: () => HTMLCanvasElement | null) {
    this._getInpaintingCanvas = getter;
  }

  static objectsContainGenerationFrames(objects: fabric.Object[]) {
    return objects.findIndex((o) => o.type === LayerType.GENERATION_FRAME) >= 0;
  }

  getInpaintBrushLineWidth(brushSize: number) {
    return brushSize * this.cameraZoomInv;
  }

  static initInpaintCanvasContext(
    context: CanvasRenderingContext2D,
    brushType: InpaintBrushType,
    lineWidth: number,
    color: string = GENERATION_FRAME_BOTTOM_FILL_DARK,
  ) {
    if (brushType) {
      context.strokeStyle = color;
      context.fillStyle = color;
      context.lineCap = "round";
      context.globalCompositeOperation = brushType === "erase" ? "destination-out" : "source-over";
      context.lineWidth = lineWidth;
    }
  }

  public destroy() {}

  getInpaintCanvasSnapshot() {
    return _.cloneDeep(this.inpaintCanvasSnapshot);
  }

  get visible() {
    return this._visible;
  }

  set visible(value: boolean) {
    if (value !== this._visible) {
      this._visible = value;
      const { generationFrame } = this;
      if (generationFrame) {
        generationFrame.visible = value;
      }
      if (this.labelContainer) {
        this.labelContainer.style.display = value ? "block" : "none";
      }
      if (value) {
        this.editor?.objects?.onShuffledStack();
      } else {
        this.canvas?.discardActiveObject();
      }
      this.canvas?.requestRenderAll();
    }
  }

  center(toWorld = true) {
    const { generationFrame } = this;
    if (generationFrame) {
      this.canvas?.getCenter();
      if (toWorld) {
        generationFrame.center();
      } else {
        this.canvas?.viewportCenterObject(generationFrame);
      }
    }
  }

  centerToObject(object?: fabric.Object | null) {
    object = object || this.editor.canvas.canvas.getActiveObject();

    if (!object) {
      return;
    }

    const { generationFrame } = this;
    if (!generationFrame || generationFrame === object) {
      return;
    }

    object.setCoords();

    const { left, top } = object;
    const width = object.getScaledWidth();
    const height = object.getScaledHeight();

    const frameWidth = generationFrame.getScaledWidth();
    const frameHeight = generationFrame.getScaledHeight();

    if (
      typeof left !== "number" ||
      typeof top !== "number" ||
      typeof width !== "number" ||
      typeof height !== "number" ||
      typeof frameWidth !== "number" ||
      typeof frameHeight !== "number"
    ) {
      return;
    }

    const centerLeft = left + width * 0.5 - frameWidth * 0.5;
    const centerTop = top + height * 0.5 - frameHeight * 0.5;
    generationFrame.left = centerLeft;
    generationFrame.top = centerTop;
    generationFrame.setCoords();
    this.canvas?.requestRenderAll();
  }

  private getObjects(shouldSelectObject?: (object: fabric.Object) => boolean) {
    const objects = this.canvas?.getObjects();
    if (!shouldSelectObject) {
      return objects;
    }
    return objects.filter(shouldSelectObject);
  }

  getObjectsIntersectingGenerationFrame(shouldSelectObject?: (object: fabric.Object) => boolean) {
    const generationFrame = this.generationFrame;
    if (generationFrame) {
      generationFrame.setCoords();
      const frameCoords = getObjectWorldCoords(generationFrame, true);
      const frameCoordsTl = frameCoords[0];
      const frameCoordsBr = frameCoords[2];
      if (frameCoordsTl || frameCoordsBr) {
        return this.getObjects(shouldSelectObject).filter((object) => {
          if (object.type === LayerType.GENERATION_FRAME) {
            return false;
          }
          if (!Objects.isMultiSelectableObjects(object)) {
            return false;
          }
          object.setCoords();
          const objectCoords = getObjectWorldCoords(object, true);
          const objectCoordsTl = objectCoords[0];
          const objectCoordsBr = objectCoords[2];

          return isBbox2dOverlapStrict(
            objectCoordsTl,
            objectCoordsBr,
            frameCoordsTl,
            frameCoordsBr,
          );
        });
      } else {
        console.log("Generation frame ocoords is invalid");
      }
    } else {
      console.log("Cannot find valid generation frame");
    }
    return [];
  }

  intersectsGenerationFrame(object: fabric.Object) {
    const { generationFrame } = this;

    if (!generationFrame) {
      console.log("No active generation frame found.");
      return false;
    }

    generationFrame.setCoords();
    const frameCoords = getObjectWorldCoords(generationFrame, true);
    const frameCoordsTl = frameCoords[0];
    const frameCoordsBr = frameCoords[2];

    if (!frameCoordsTl || !frameCoordsBr) {
      console.log("Generation frame has invalid coordinates.");
      return false;
    }

    object.setCoords();
    const objectCoords = getObjectWorldCoords(object, true);
    const objectCoordsTl = objectCoords[0];
    const objectCoordsBr = objectCoords[2];

    return isBbox2dOverlapStrict(objectCoordsTl, objectCoordsBr, frameCoordsTl, frameCoordsBr);
  }

  isGenerationFrameInsideViewport() {
    const { generationFrame, canvas } = this;

    if (!generationFrame || !canvas) {
      return false;
    }

    return isObjectInViewport(generationFrame, canvas);
  }

  updateImagesIntersectingGenerationFrame() {
    const objects = this.getObjectsIntersectingGenerationFrame((object) =>
      isStaticImageObject(object),
    );
    this.editor.state.setObjectsInsideGenerationFrame(objects);
    return objects;
  }

  getPreferedRenderEngine(): EngineType {
    const objects = this.getObjectsIntersectingGenerationFrame();
    if (objects.length <= 0) {
      return EngineType.ProductPhotography;
    }
    const useHuman = objects.find(
      (object) => object.metadata?.preferedRenderEngine === EngineType.Humans,
    );
    if (useHuman) {
      return EngineType.Humans;
    }
    return EngineType.ProductPhotography;
  }
}
