import { StopRenderJobArgs } from "@/backend/base";
import { RenderPipelineArgs } from "@/core/common/types/render-args";
import {
  isUserRenderJobState,
  UserRenderJobState,
  UserRenderJobStatus,
} from "@/core/common/types/render-job";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { collection, doc, Firestore, onSnapshot, updateDoc } from "firebase/firestore";

const renderQueueProducerApiUrl = import.meta.env.VITE_RENDER_QUEUE_PRODUCER_API_URL;
const SECRET_API_KEY = import.meta.env.VITE_RENDER_QUEUE_PRODUCER_API_KEY;
const userRenderStateCollectionName = "userRenderState";
const userRenderJobCollectionName = "renderJobs";

interface AddRenderJobArgs {
  userId: string;
  numOutputs?: number;
  renderPipelineArgs: RenderPipelineArgs;
}

type AddRenderJobResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      jobIds: string[];
    };

function getUserRenderJobsCollectionRef({
  firestore,
  userId,
}: {
  firestore: Firestore;
  userId: string;
}) {
  return collection(
    doc(collection(firestore, userRenderStateCollectionName), userId),
    userRenderJobCollectionName,
  );
}

function getUserRenderJobDocRef({
  firestore,
  userId,
  jobId,
}: {
  firestore: Firestore;
  userId: string;
  jobId: string;
}) {
  return doc(
    getUserRenderJobsCollectionRef({
      firestore,
      userId,
    }),
    jobId,
  );
}

export class RenderStateManager {
  private firestore: Firestore;

  constructor({ firestore }: { firestore: Firestore }) {
    this.firestore = firestore;
  }

  async startRenderJob({
    userId,
    numOutputs = 1,
    renderPipelineArgs,
  }: AddRenderJobArgs): Promise<AddRenderJobResponse> {
    try {
      const response = await fetch(renderQueueProducerApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${SECRET_API_KEY}`,
          UserId: userId,
          NumOutputs: numOutputs.toString(),
        },
        body: JSON.stringify(renderPipelineArgs),
      });

      if (!response.ok) {
        const message = await response.text();

        debugError(`Error sending render job request:\n`, message);
        return {
          ok: false,
          message,
        };
      }

      const data = await response.json();

      const jobIds = data?.jobIds;
      if (Array.isArray(jobIds)) {
        return {
          ok: true,
          jobIds,
        };
      }

      return {
        ok: false,
        message: "Cannot start render job.",
      };
    } catch (error) {
      debugError("Error starting render job: ", error);
    }

    return {
      ok: false,
      message: "Unknown error when starting render job.",
    };
  }

  onRenderJobUpdate({
    userId,
    jobId,
    callback,
  }: {
    userId: string;
    jobId: string;
    callback: (renderJob: UserRenderJobState | null) => void;
  }) {
    return onSnapshot(
      getUserRenderJobDocRef({
        firestore: this.firestore,
        userId,
        jobId,
      }),
      (snapshot) => {
        const data = snapshot.data();

        if (isUserRenderJobState(data)) {
          callback(data);
        } else {
          debugLog(`Cannot parse user ${userId} render job ${jobId}: `, data);

          callback(null);
        }
      },
    );
  }

  async stopRenderJob({ userId, jobId }: StopRenderJobArgs) {
    try {
      debugLog(`Stop user ${userId} render job ${jobId}`);

      await updateDoc(
        getUserRenderJobDocRef({
          firestore: this.firestore,
          userId,
          jobId,
        }),
        {
          status: UserRenderJobStatus.Stopped,
        },
      );
    } catch (error) {
      debugError(`Error cancelling user ${userId} job ${jobId}: `, error);
    }
  }
}
