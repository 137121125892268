import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { PlusIcon } from "@radix-ui/react-icons";
import { UploadImageFormPopover } from "./upload-image";
import { CustomModelWorkflow } from "components/custom-model/custom-model-workflows-data";

function UploadButtonTrigger({ workflow }: { workflow?: CustomModelWorkflow }) {
  return (
    <AspectRatio.Root
      ratio={1}
      className="w-full flex flex-col items-center justify-center text-center rounded-md border-2 border-dashed border-zinc-800 p-4 overflow-hidden select-none cursor-pointer hover:text-lime-400 hover:border-lime-500 focus:border-lime-300 focus:outline-none focus-visible:outline-none transition-colors"
    >
      <PlusIcon className="mb-2" width={23} height={23} />
      {workflow?.moodboardUploadLabel?.length && workflow.moodboardUploadLabel.length > 0
        ? workflow.moodboardUploadLabel
        : "Upload Images"}
    </AspectRatio.Root>
  );
}

export function UploadButton({
  modelId,
  workflow,
  onDelete = () => {},
}: {
  modelId: string;
  workflow?: CustomModelWorkflow;
  onDelete?: (id: string) => void;
}) {
  return (
    <UploadImageFormPopover
      modelId={modelId}
      triggerProps={{
        className: "focus:outline-none focus:ring focus:ring-zinc-600",
      }}
      onDelete={onDelete}
    >
      <UploadButtonTrigger workflow={workflow} />
    </UploadImageFormPopover>
  );
}
