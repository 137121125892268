import { Timestamp } from "firebase/firestore";
import { StripeSubscriptionPriceId } from "./subscription";

export type StripeInvoice = {
  id: string; // Unique identifier
  object: string;
  account_country?: string | null;
  account_name?: string | null;
  account_tax_ids?: string[] | null;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  amount_shipping: number;
  application?: string | null;
  application_fee_amount?: number | null;
  attempt_count: number;
  attempted: boolean;
  auto_advance: boolean;
  automatic_tax: {
    enabled: boolean;
    status?: string | null;
  };
  billing_reason?: "manual" | "subscription" | null; // Assuming enum values
  charge?: string | null;
  collection_method: "charge_automatically" | "send_invoice";
  created: number; // Timestamp
  currency: string;
  custom_fields?: any | null;
  customer: string;
  customer_address?: any | null;
  customer_email?: string | null;
  customer_name?: string | null;
  customer_phone?: string | null;
  customer_shipping?: any | null;
  customer_tax_exempt?: "none" | "exempt" | "reverse" | null;
  customer_tax_ids?: any[] | null;
  default_payment_method?: string | null;
  default_source?: string | null;
  default_tax_rates: any[];
  description?: string | null;
  discount?: any | null;
  discounts?: string[] | null;
  due_date?: number | null; // Timestamp
  ending_balance?: number | null;
  footer?: string | null;
  from_invoice?: any | null;
  hosted_invoice_url?: string | null;
  invoice_pdf?: string | null;
  last_finalization_error?: any | null;
  latest_revision?: string | null;
  lines: {
    object: string;
    data: any[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  livemode: boolean;
  metadata: { [key: string]: string };
  next_payment_attempt?: number | null; // Timestamp
  number?: string | null;
  on_behalf_of?: string | null;
  paid: boolean;
  paid_out_of_band: boolean;
  payment_intent?: string | null;
  payment_settings: {
    default_mandate?: string | null;
    payment_method_options?: any | null;
    payment_method_types?: string[] | null;
  };
  period_end: number; // Timestamp
  period_start: number; // Timestamp
  post_payment_credit_notes_amount: number;
  pre_payment_credit_notes_amount: number;
  quote?: string | null;
  receipt_number?: string | null;
  rendering_options?: any | null;
  shipping_cost?: any | null;
  shipping_details?: any | null;
  starting_balance: number;
  statement_descriptor?: string | null;
  status?: "draft" | "open" | "paid" | "uncollectible" | "void" | null;
  status_transitions: {
    finalized_at?: number | null; // Timestamp
    marked_uncollectible_at?: number | null; // Timestamp
    paid_at?: number | null; // Timestamp
    voided_at?: number | null; // Timestamp
  };
  subscription?: string | null;
  subtotal: number;
  subtotal_excluding_tax?: number | null;
  tax?: number | null;
  test_clock?: string | null;
  total: number;
  total_discount_amounts?: any[] | null;
  total_excluding_tax?: number | null;
  total_tax_amounts: any[];
  transfer_data?: any | null;
  webhooks_delivered_at?: number | null; // Timestamp
};

export type StripeListInvoicesResponse = {
  object?: "list";
  url?: string;
  has_more?: boolean;
  data: StripeInvoice[];
};

export type StripeListInvoicesParams = {
  customer?: string; // Optional customer ID
  status?: "draft" | "open" | "paid" | "uncollectible" | "void"; // Optional status enum
  subscription?: string; // Optional subscription ID
  collection_method?: "charge_automatically" | "send_invoice"; // Optional collection method enum
  ending_before?: string; // Optional cursor for pagination
  limit?: number; // Optional limit on the number of objects to be returned
  starting_after?: string; // Optional cursor for pagination
};

export enum StripeSubscriptionStatus {
  Active = "active",
  PastDue = "past_due",
  Unpaid = "unpaid",
  Canceled = "canceled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  Trialing = "trialing",
  Paused = "paused",
}

export interface StripeProduct {
  images?: string[];
  metadata?: Record<string, string>;
  livemode: boolean;
  created: number;
  default_price?: string;
  active: boolean;
  description?: string;
  type: string;
  url?: string | null;
  statement_descriptor?: string | null;
  features?: string[];
  tax_code?: string;
  shippable?: boolean | null;
  name: string;
  attributes?: string[];
  id: string;
  unit_label?: string | null;
  updated: number;
  object: string;
}

export function isStripeProduct(obj: any): obj is StripeProduct {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  if (typeof obj.id !== "string") {
    return false;
  }
  if (typeof obj.active !== "boolean") {
    return false;
  }

  if ("metadata" in obj) {
    if (typeof obj.metadata !== "object" || obj.metadata === null) {
      return false;
    }
    for (const key of Object.keys(obj.metadata)) {
      if (typeof obj.metadata[key] !== "string") {
        return false;
      }
    }
  }

  if ("default_price" in obj && typeof obj.default_price !== "string") {
    return false;
  }

  if ("url" in obj && obj.url !== null && typeof obj.url !== "string") {
    return false;
  }

  if (
    "statement_descriptor" in obj &&
    obj.statement_descriptor !== null &&
    typeof obj.statement_descriptor !== "string"
  ) {
    return false;
  }

  if ("tax_code" in obj && typeof obj.tax_code !== "string") {
    return false;
  }

  if ("shippable" in obj && obj.shippable !== null && typeof obj.shippable !== "boolean") {
    return false;
  }

  if ("unit_label" in obj && obj.unit_label !== null && typeof obj.unit_label !== "string") {
    return false;
  }

  return true;
}

export interface StripePrice {
  tax_behavior?: string;
  metadata?: Record<string, string>;
  product?: StripeProduct;
  livemode: boolean;
  created: number;
  recurring?: {
    interval_count: number;
    usage_type: string;
    aggregate_usage?: number | null;
    interval: string;
    trial_period_days?: number | null;
  };
  active: boolean;
  unit_amount_decimal?: string;
  billing_scheme?: string;
  unit_amount?: number;
  type: string;
  transform_quantity?: number | null;
  tiers_mode?: string | null;
  nickname?: string | null;
  lookup_key?: string | null;
  currency: string;
  id: string;
  custom_unit_amount?: number | null;
  object: string;
}

export function isStripePrice(obj: any): obj is StripePrice {
  return (
    obj &&
    typeof obj.id === "string" &&
    typeof obj.active === "boolean" &&
    typeof obj.type === "string" &&
    typeof obj.currency === "string"
  );
}

interface Plan {
  interval_count: number;
  amount: number;
  metadata: Record<string, string>;
  product: string;
  livemode: boolean;
  created: number;
  amount_decimal: string;
  transform_usage?: number | null;
  active: boolean;
  aggregate_usage?: number | null;
  billing_scheme: string;
  trial_period_days?: number | null;
  tiers_mode?: string | null;
  usage_type: string;
  nickname?: string | null;
  currency: string;
  interval: string;
  id: string;
  object: string;
}

export interface StripeSubscriptionItem {
  metadata: Record<string, string>;
  quantity: number;
  created: number;
  price: StripePrice;
  billing_thresholds?: any | null;
  id: string;
  subscription: string;
  plan: Plan;
  tax_rates?: number[];
  object: string;
}

export interface StripeSubscriptionFirestoreDoc {
  id?: string;
  stripeLink: string;
  metadata: Record<string, string>;
  quantity: number;
  cancel_at_period_end: boolean;
  canceled_at?: Timestamp | null;
  created: Timestamp;
  trial_end?: Timestamp | null;
  current_period_end: Timestamp;
  trial_start?: Timestamp | null;
  items: StripeSubscriptionItem[];
  current_period_start: Timestamp;
  ended_at?: Timestamp | null;
  status: StripeSubscriptionStatus;
  cancel_at?: Timestamp | null;
}

export function isStripeSubscriptionFirestoreDoc(doc: any): doc is StripeSubscriptionFirestoreDoc {
  return doc && typeof doc.status === "string" && Array.isArray(doc.items) && doc.items.length > 0;
}

export type ActiveStripeSubscriptionStatus =
  | StripeSubscriptionStatus.Active
  | StripeSubscriptionStatus.Trialing;

export function isActiveStripeSubscriptionStatus(
  status: StripeSubscriptionStatus,
): status is ActiveStripeSubscriptionStatus {
  return status === StripeSubscriptionStatus.Active || status === StripeSubscriptionStatus.Trialing;
}

export interface StripeCheckoutSessionDiscount {
  coupon: string;
}

export interface StripeCheckoutSessionLineItem {
  price: string;
  quantity?: number;
  adjustable_quantity?: {
    enabled: boolean;
    minimum?: number;
    maximum?: number;
  };
  tax_rates?: string[];
}

export interface StripeCheckoutSessionShippingAddressCollection {
  allowed_countries: string[];
}

export interface StripeCheckoutSessionShippingOption {
  shipping_rate: string;
}

export interface StripeCheckoutSessionData {
  line_items: StripeCheckoutSessionLineItem[];
  mode: "payment" | "subscription" | "setup";
  success_url: string;
  cancel_url: string;
  customer_email?: string;
  client_reference_id?: string;
  allow_promotion_codes?: boolean;
  discounts?: StripeCheckoutSessionDiscount[];
  metadata?: { [key: string]: string };
  locale?: string;
  payment_method_types?: string[];
  billing_address_collection?: "auto" | "required";
  shipping_address_collection?: StripeCheckoutSessionShippingAddressCollection;
  shipping_options?: StripeCheckoutSessionShippingOption[];
}
