// @ts-nocheck
import { defaultObjectOptions } from "@/core/common/constants";
import { IGenerationFrame, ILayer, LayerType } from "@/core/common/layers";
import { getSceneLayers, IScene, SampleProjectScene } from "@/core/common/scene";
import ObjectExporter from "@/core/utils/object-exporter";
import { Base } from "./base";
import { fabric } from "fabric";
import { nanoid } from "nanoid";
import { getSelectionType } from "@/core/utils/get-selection-type";
import ObjectImporter from "@/core/utils/object-importer";
import { parseSVG } from "@/core/parser/shape";
import { isBigIntLessThanEqual } from "@/core/utils/number-utils";
import { addObjectsToCanvas } from "@/components/utils/add-to-canvas-utils";
import { removeUndefinedFromObject } from "@/core/utils/object-utils";
import { PromptEditorEventHandler } from "@/core/common/types";
import { defaultGenerateTemplate } from "@/components/constants/default-generate-template";
import { getPromptStateFromPromptTemplate } from "@/core/common/prompt-template";
import { debugError, debugLog } from "@/core/utils/print-utilts";

export class Scene extends Base {
  get id() {
    return this.editor.state.projectId;
  }

  get name() {
    return this.editor.state.projectDisplayName;
  }

  get version() {
    return this.editor.state.version;
  }

  private exportGenerationFrame(): IGenerationFrame | undefined {
    try {
      const generationFrame = this.editor.generationFrames.generationFrame;

      if (!generationFrame) {
        return;
      }

      const { generateToolPromptTemplate, generateToolReferenceImage } = this.editor.state;

      return removeUndefinedFromObject({
        id: generationFrame.id,
        type: LayerType.GENERATION_FRAME,
        left: generationFrame.left,
        top: generationFrame.top,
        width: generationFrame.width,
        height: generationFrame.height,
        promptTemplate: generateToolPromptTemplate,
        referneceImage: generateToolReferenceImage,
      }) as IGenerationFrame;
    } catch (error) {
      debugError(error);
    }
    return undefined;
  }

  private importGenerationFrame(generationFrameData: IGenerationFrame) {
    try {
      const generationFrame = this.editor.generationFrames.generationFrame;

      if (!generationFrame) {
        debugError("No generation frame found in the canvas.");
        return;
      }

      debugLog("Import generation frame from template:\n", generationFrameData);

      const { left = 0, top = 0, promptTemplate, referneceImage } = generationFrameData;

      generationFrame.left = left;
      generationFrame.top = top;

      const { setGenerateToolPromptTemplate, setGenerateToolReferenceImage } = this.editor.state;

      if (promptTemplate) {
        setGenerateToolPromptTemplate(promptTemplate);
      } else {
        this.editor.emit<PromptEditorEventHandler>(
          "prompt-editor:set-state",
          getPromptStateFromPromptTemplate(defaultGenerateTemplate.prompt),
        );
      }

      if (referneceImage) {
        setGenerateToolReferenceImage(referneceImage);
      }
    } catch (error) {
      debugError(error);
    }
  }

  public exportToJSON(version: string = this.version): IScene {
    const animated = false;

    const canvasJSON: any = this.canvas.toJSON(this.config.propertiesToInclude);
    const frame = this.editor.frame?.options;
    const template: IScene = {
      id: this.id ? this.id : nanoid(),
      version,
      name: this.name ? this.name : "Untitled Project",
      objects: {},
      frame: {
        width: frame?.width || 0,
        height: frame?.height || 0,
      },
      generationFrame: this.exportGenerationFrame(),
      metadata: {
        animated,
      },
    };

    const objects = canvasJSON.objects.filter(
      (object: any) =>
        object.type !== LayerType.FRAME && object.type !== LayerType.GENERATION_FRAME,
    ) as ILayer[];
    const objectExporter = new ObjectExporter();

    const options = (frame || defaultObjectOptions) as Required<ILayer>;

    objects.forEach((object: ILayer, zIndex) => {
      const exportedObject = objectExporter.export(object, options);
      template.objects[object.id] = { ...exportedObject, zIndex };
    });
    template.metadata = {
      ...template.metadata,
      animated,
    };
    return template;
  }

  public exportAsComponent = async () => {
    const activeObject = this.canvas.getActiveObject();
    const selectionType = getSelectionType(activeObject);
    const frame = this.editor.frame?.options;
    const objectExporter = new ObjectExporter();
    if (activeObject && selectionType) {
      const isMixed = selectionType.length > 1;

      if (activeObject.type === "activeSelection" || activeObject.type === "group") {
        let clonedObjects: any[] = [];
        // @ts-ignore
        const objects = activeObject._objects;
        for (const object of objects!) {
          const cloned = await new Promise((resolve) => {
            object.clone((c: fabric.Object) => {
              c.clipPath = undefined;
              resolve(c);
            }, this.editor.config.propertiesToInclude);
          });
          clonedObjects = clonedObjects.concat(cloned);
        }

        const options = (frame || defaultObjectOptions) as Required<ILayer>;

        const group = new fabric.Group(clonedObjects);
        // @ts-ignore
        const component = objectExporter.export(
          group.toJSON(this.editor.config.propertiesToInclude),
          options,
        ) as any;
        const metadata = component.metadata ? component.metadata : {};

        return {
          ...component,
          top: 0,
          left: 0,
          metadata: {
            ...metadata,
            category: isMixed ? "mixed" : "single",
            types: selectionType,
          },
        };
      } else {
        const options = (frame || defaultObjectOptions) as Required<ILayer>;
        // @ts-ignore
        const component = objectExporter.export(
          activeObject.toJSON(this.editor.config.propertiesToInclude),
          options,
        );
        const metadata = component.metadata ? component.metadata : {};
        return {
          ...component,
          top: 0,
          left: 0,
          metadata: {
            ...metadata,
            category: isMixed ? "mixed" : "single",
            types: selectionType,
          },
        };
      }
    }
  };

  /**
   * Deserializes JSON data
   * @returns Json Template
   */
  private async importFromJsonInternal(template: SampleProjectScene) {
    debugLog(`Scene version ${template.version}; This version ${this.version};`);

    if (isBigIntLessThanEqual(template.version, this.version)) {
      return;
    }

    this.editor.objects.clear();

    this.editor.state.setVersion(template.version);

    // const frameParams = template.frame
    // this.editor.frame?.resize({
    //   width: frameParams.width,
    //   height: frameParams.height,
    // })

    const frame = (this.editor.frame?.frame || defaultObjectOptions) as Required<ILayer>;
    const objectImporter = new ObjectImporter(this.editor);
    const layers = getSceneLayers(template);
    const updatedTemplateLayers = layers.map((layer) => {
      if (layer.type === LayerType.BACKGROUND) {
        return {
          ...layer,
          shadow: this.config.shadow,
        };
      }
      return layer;
    });

    const importObjectPromises: Promise<void>[] = [];

    const canvasObjects: (fabric.Object | undefined | null)[] = new Array(
      updatedTemplateLayers.length,
    );

    for (const layer of updatedTemplateLayers as Required<(ILayer & { zIndex: number })[]>) {
      importObjectPromises.push(
        objectImporter
          .import(layer, frame)
          .then((element) => {
            if (element) {
              // this.canvas.add(element);
              if (layer.zIndex >= 0 && layer.zIndex < canvasObjects.length) {
                canvasObjects[layer.zIndex] = element;
              } else {
                canvasObjects.push(element);
              }
            } else {
              debugLog("UNABLE TO LOAD OBJECT: ", layer);
            }
          })
          .catch((e) => {
            debugError(e);
          }),
      );
    }

    await Promise.all(importObjectPromises);

    const canvasObjectsFiltered: fabric.Object[] = canvasObjects.filter((o) =>
      Boolean(o),
    ) as fabric.Object[];

    addObjectsToCanvas({
      canvas: this.canvas,
      objects: canvasObjectsFiltered,
    });

    if (template.generationFrame) {
      this.importGenerationFrame(template.generationFrame);
      this.editor.zoom.zoomToFitAll();
    } else {
      debugError("Template has no valid generation frame", template);
    }

    // debugLog(`Finish importing scene data from json with ${this.canvas.getObjects().length} objects:`);
    // this.canvas.getObjects().forEach(object => {
    //   debugLog(`    Object ${object.id} type ${object.type}`);
    // })

    // this.editor.zoom.zoomToFit();
    this.editor.objects.updateContextObjects();
    this.editor.objects?.onShuffledStack();
    this.editor.history.save();
  }

  private _importFromJsonPromise?: Promise<void>;

  /**
   * Deserializes JSON data
   * @returns Json Template
   */
  public importFromJSON = async (template: SampleProjectScene) => {
    if (this._importFromJsonPromise) {
      await this._importFromJsonPromise;
    }

    this._importFromJsonPromise = this.importFromJsonInternal(template);

    await this._importFromJsonPromise;
  };

  public async importFromSVG(url: string) {
    const design = await parseSVG(url);
    // @ts-ignore
    await this.importFromJSON(design);
  }
}
