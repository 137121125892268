import { CANVAS_CONTAINER_ID } from "components/constants/ids";
import { EditorCanvasModelZIndex } from "components/constants/zIndex";
import { classNames } from "@/core/utils/classname-utils";
import React from "react";
import { createPortal } from "react-dom";
import styles from "./editor-canvas-modal.module.css";
import { ShortcutsUtils } from "@/core/utils/shortcuts-utils";

export function EditorCanvasModalContainerBase({
  className = "",
  style = {},
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const canvasContainer = React.useMemo(() => document.getElementById(CANVAS_CONTAINER_ID), []);

  if (!canvasContainer) {
    return null;
  }
  return createPortal(
    <div
      {...props}
      className={classNames(
        styles.EditorCanvasModalContainer,
        "relative flex items-center justify-center w-full h-full bg-black/30",
        className,
      )}
      style={{
        ...style,
        zIndex: EditorCanvasModelZIndex,
      }}
    >
      {children}
    </div>,
    canvasContainer,
  );
}

export const EditorCanvasModalContainer = React.forwardRef(function EditorCanvasModalContainer(
  {
    tabIndex = 0,
    className = "",
    children,
    onExit,
    onKeyDown,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    onExit?: () => void;
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const isClickedOnContainerRef = React.useRef(false);

  return (
    <EditorCanvasModalContainerBase
      tabIndex={tabIndex}
      className="px-2 py-2 md:px-[2vw] md:py-[2vw] lg:px-[4vw] lg:py-[2vw] focus:outline-none focus-visible:outline-none"
      onClick={() => {
        if (!isClickedOnContainerRef.current) {
          onExit?.();
        }
        isClickedOnContainerRef.current = false;
      }}
      onKeyDown={(e) => {
        if (ShortcutsUtils.isEscape(e.nativeEvent)) {
          onExit?.();
        } else {
          onKeyDown?.(e);
        }
      }}
    >
      <div
        {...props}
        ref={forwardedRef}
        className={classNames(
          "relative p-4 w-full h-full 2xl:max-w-[1300px] flex flex-col bg-zinc-900 border border-zinc-800 rounded-xl shadow-lg",
          className,
        )}
        onClick={() => {
          isClickedOnContainerRef.current = true;
        }}
      >
        {children}
      </div>
    </EditorCanvasModalContainerBase>
  );
});
