import {
  AppUserSubscriptionTier,
  EditorConfig,
  UiDisplayMessageDialogEventHandler,
} from "@/core/common/types";
import { Editor } from "@/core/editor";
import React from "react";

import { MAX_NUMBER_ASSET_UPLOADS } from "@/backend/firebase/firebase-backend";
import { CANVAS_CONTAINER_ID, CANVAS_ID } from "@/components-mobile/constants/ids";
import { EditorFloatTag } from "@/components-mobile/editor/float-tag/float-tag";
import { CanvasScrollBars } from "@/components/editor/canvas-scroll-bars";
import { mergeRefs } from "@/components/utils/merge-refs";
import { uploadAndAddFiles } from "@/components/utils/upload";
import { editorContextStore, resetEditorContextState } from "@/contexts/editor-context";
import { MobileEditor } from "@/core-mobile/mobile-editor";
import { SampleProjectScene } from "@/core/common/scene";
import { useTryOnEditorVisible } from "contexts/tryon-editor-context";
import { useFileDrop } from "hooks/use-file-drop";
import { GenerationFrame } from "../canvas-frames/generation-frame";

export const Canvas = React.forwardRef(
  (
    {
      id,
      style,
      config,
      initScene,
      ...props
    }: {
      config: Partial<EditorConfig>;
      initScene?: SampleProjectScene;
    } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwardRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    const resizeObserverRef = React.useRef<ResizeObserver>();
    const editorRef = React.useRef<Editor>();
    const isMobile = editorContextStore((state) => state.isMobile);

    const [isUploadAvailable, setIsUploadAvailable] = React.useState(true);

    const tryOnEditorVisible = useTryOnEditorVisible();

    const userQuotas = editorContextStore((state) => state.userQuotas);
    const backend = editorContextStore((state) => state.backend);
    const tier = userQuotas?.tier || AppUserSubscriptionTier.Free;
    React.useEffect(() => {
      if (!backend) {
        return;
      }
      const checkUploadAvailability = async () => {
        const numUploads = await backend.countTotalUserAssets();
        const isAvailable =
          numUploads < MAX_NUMBER_ASSET_UPLOADS || tier !== AppUserSubscriptionTier.Free;
        setIsUploadAvailable(isAvailable);
      };

      checkUploadAvailability();
    }, [backend, tier]);

    React.useEffect(() => {
      const container = containerRef.current as HTMLDivElement;
      if (!editorRef.current) {
        const { clientHeight, clientWidth } = container;

        if (isMobile) {
          console.log("Initialize mobile editor");
          editorRef.current = new MobileEditor({
            id: CANVAS_ID,
            config: {
              ...(config || {}),
              size: {
                width: clientWidth,
                height: clientHeight,
              },
            },
            initScene,
          }) as any as Editor;
        } else {
          editorRef.current = new Editor({
            id: CANVAS_ID,
            config: {
              ...(config || {}),
              size: {
                width: clientWidth,
                height: clientHeight,
              },
            },
            initScene,
          });
        }
      }
      return () => {
        editorRef.current?.destroy();
        editorRef.current = undefined;
        resetEditorContextState(editorContextStore.getState());
      };
      // eslint-disable-next-line
    }, [isMobile]);

    React.useEffect(() => {
      const container = containerRef.current as HTMLDivElement;
      resizeObserverRef.current = new ResizeObserver((entries) => {
        const { width, height } = (entries[0] && entries[0].contentRect) || {};
        editorRef.current?.canvas.resize({
          width,
          height,
        });
      });
      resizeObserverRef.current.observe(container);
      return () => {
        if (container) {
          resizeObserverRef.current?.unobserve(container);
        }
      };
    }, []);

    const addDroppedFiles = React.useCallback(
      (files: FileList, e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (files && editorRef.current?.objects) {
          return uploadAndAddFiles({
            files,
            editorObjects: editorRef.current?.objects,
            location: editorRef.current?.canvas.canvas.getPointer(e as any as Event),
            assets: editorRef.current?.assets,
            removeBackgroundPopup: true,
            userAssetInfoType: "images",
          });
        }
        return Promise.resolve();
      },
      [],
    );

    const { onDragEnter, onDragLeave, onDragOver, onDrop } = useFileDrop({
      handleDropFiles: addDroppedFiles,
    });
    function emptyDropFunction(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      editorContextStore
        .getState()
        .editor?.emit<UiDisplayMessageDialogEventHandler>(
          "ui:display-message-dialog",
          "quota-subscribe",
          {
            message:
              "No uploads left. Please subscribe to upload more images, or delete some of your existing uploaded assets.",
          },
        );
    }

    return (
      <div
        id={CANVAS_CONTAINER_ID}
        // eslint-disable-next-line
        ref={mergeRefs([containerRef, forwardRef])}
        style={{
          flex: 1,
          position: "relative",
          overflow: "hidden",
        }}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={isUploadAvailable ? onDrop : emptyDropFunction}
        {...props}
      >
        {!tryOnEditorVisible && <CanvasScrollBars />}
        <GenerationFrame />
        {!tryOnEditorVisible && <EditorFloatTag />}
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <canvas id={CANVAS_ID} />
        </div>
      </div>
    );
  },
);
