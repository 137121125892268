import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { Functions, HttpsCallable, httpsCallable } from "firebase/functions";
import {
  PublicUserId,
  AppRoleType,
  UserAssetType,
  UiDisplayMessageEventHandler,
} from "@/core/common/types";
import {
  GenerateVideoKeyFrameWithImage,
  isGenerateVideoKeyFrameWithImage,
  isVideoCameraMotion,
  isVideoGenerationDoc,
  VideoGenerationDoc,
  VideoGenerationRequest,
  VideoGenerationResponse,
} from "@/core/common/types/video";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import {
  GenerateVideoPromptArgs,
  GenerateVideoPromptResponse,
  OnUserVideoGenerationsUpdateArgs,
} from "@/backend/base";
import {
  createGenerateUserAssetUploadUrlFunction,
  GenerateUserAssetUploadUrlFunction,
  getStoragePathFromSignedUrl,
  parseDirtyJSON,
  uploadAssetDataWithMetadata,
} from "./asset-upload-utils";
import { ChatWithImagesFunction, createChatWithImagesFunction } from "./chat-with-images-utils";
import { editorContextStore } from "@/contexts/editor-context";

// Assuming these imports are available
const videoGenerationsCollectionName = "videoGenerations";

export type GenericVideoGenerationDocPath =
  `${typeof videoGenerationsCollectionName}/{generationId}`;

export function getGenericVideoGenerationDocPath(): GenericVideoGenerationDocPath {
  return `${videoGenerationsCollectionName}/{generationId}`;
}

export function getVideoGenerationCollectionRef(firestore: Firestore) {
  return collection(firestore, videoGenerationsCollectionName);
}

export function getVideoGenerationDocRef({
  firestore,
  generationId,
}: {
  generationId: string;
  firestore: Firestore;
}) {
  return doc(getVideoGenerationCollectionRef(firestore), generationId);
}

interface GenerateVideoPromptResponseBody {
  caption: string;
  camera_motion?: string;
}

function isGenerateVideoPromptResponseBody(body: any): body is GenerateVideoPromptResponseBody {
  return body && typeof body.caption === "string";
}

export class FirebaseVideoManager {
  private firestore: Firestore;

  private videoGenerationEntrypoint: HttpsCallable<VideoGenerationRequest, VideoGenerationResponse>;

  private generateUserAssetUploadUrl: GenerateUserAssetUploadUrlFunction;

  private chatWithImages: ChatWithImagesFunction;

  constructor({
    firestore,
    firebaseFunctions,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;
    this.videoGenerationEntrypoint = httpsCallable(
      firebaseFunctions,
      "videoGenerationEntrypointColabJuly24",
    );
    this.generateUserAssetUploadUrl = createGenerateUserAssetUploadUrlFunction({
      firebaseFunctions,
    });
    this.chatWithImages = createChatWithImagesFunction({
      firebaseFunctions,
    });
  }

  private getVideoGenerationCollectionRef() {
    return getVideoGenerationCollectionRef(this.firestore);
  }

  private getVideoGenerationDocRef(generationId: string) {
    return getVideoGenerationDocRef({
      firestore: this.firestore,
      generationId,
    });
  }

  async getVideoGenerationDoc(generationId: string): Promise<VideoGenerationDoc | undefined> {
    const snapshot = await getDoc(this.getVideoGenerationDocRef(generationId));

    const data = snapshot.data();

    if (!isVideoGenerationDoc(data)) {
      return undefined;
    }

    return data;
  }

  private getUserVideoGenerationsQuery(publicUserId: PublicUserId) {
    return query(
      this.getVideoGenerationCollectionRef(),
      where(`roles.${publicUserId}`, "in", Object.values(AppRoleType)),
    );
  }

  onVideoGenerationDocUpdate({
    generationId,
    callback,
  }: {
    generationId: string;
    callback: (generation: VideoGenerationDoc | undefined) => void;
  }) {
    return onSnapshot(this.getVideoGenerationDocRef(generationId), (snapshot) => {
      const data = snapshot.data();

      if (isVideoGenerationDoc(data)) {
        callback(data);
      } else {
        callback(undefined);
      }
    });
  }

  async generateVideo(request: VideoGenerationRequest): Promise<VideoGenerationResponse> {
    try {
      // if (process.env.NODE_ENV != null) {

      //     // debugLog(request);

      //     return {
      //         ok: false,
      //         message: "Video generation service is under high traffic. Please give us a few hours to scale it up.",
      //     };
      // }

      const response = await this.videoGenerationEntrypoint(request);

      return response.data;
    } catch (error) {
      debugError("Error generating video:\n", error);
      return {
        ok: false,
        message:
          ((error as any)?.message as string) ||
          "Unknown error when generating video. Please try again later.",
      };
    }
  }

  async getUserVideoGenerations({
    publicUserId,
  }: {
    publicUserId: PublicUserId;
  }): Promise<Record<string, VideoGenerationDoc>> {
    try {
      const q = this.getUserVideoGenerationsQuery(publicUserId);
      const snapshot = await getDocs(q);
      const generations: Record<string, VideoGenerationDoc> = {};
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (isVideoGenerationDoc(data)) {
          generations[doc.id] = data;
        }
      });
      return generations;
    } catch (error) {
      debugError("Cannot get user video generations: ", error);
      return {};
    }
  }

  onUserVideoGenerationsUpdate({
    publicUserId,
    callback,
  }: OnUserVideoGenerationsUpdateArgs): () => void {
    return onSnapshot(this.getUserVideoGenerationsQuery(publicUserId), (snapshot) => {
      const generations: Record<string, VideoGenerationDoc> = {};
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (isVideoGenerationDoc(data)) {
          generations[doc.id] = data;
        }
      });
      callback(generations);
    });
  }

  private async generateKeyFrameUploadUrl({ contentType }: { contentType: string }) {
    const response = await this.generateUserAssetUploadUrl({
      contentType,
      assetType: UserAssetType.VideoGenerationKeyframe,
    });

    const { assetId, signedUrl, extensionHeaders } = response.data ?? {};

    return {
      assetId,
      signedUrl,
      extensionHeaders,
    };
  }

  async uploadVideoKeyFrameToStorage({ data }: { data: File | Blob }) {
    try {
      const contentType = data.type;

      const {
        assetId,
        signedUrl,
        extensionHeaders = {},
      } = await this.generateKeyFrameUploadUrl({
        contentType,
      });

      if (!signedUrl || !assetId) {
        debugError(`Cannot generate upload url for asset ${assetId} of type ${data.type}`);
        return undefined;
      }

      const storagePath = getStoragePathFromSignedUrl(signedUrl);

      debugLog(`Upload data to storage path ${storagePath} with signed url ${signedUrl}`);

      if (!storagePath) {
        return undefined;
      }

      // extensionHeaders['x-goog-meta-asset_id'] = assetId;

      const metadata = Object.fromEntries(
        Object.entries(extensionHeaders).filter(([, value]) => value != null),
      ) as Record<string, string>;

      await uploadAssetDataWithMetadata({
        signedUrl,
        data,
        contentType,
        metadata,
      });

      return storagePath;
    } catch (error) {
      debugError("Error uploading keyframe to storage: ", error);
    }
  }

  async generateVideoPrompt({
    keyFrames,
    cameraMotion,
  }: GenerateVideoPromptArgs): Promise<GenerateVideoPromptResponse> {
    try {
      const imageUrls = keyFrames
        .filter((keyFrame) => {
          return isGenerateVideoKeyFrameWithImage(keyFrame);
        })
        .map((keyFrame) => {
          return (keyFrame as GenerateVideoKeyFrameWithImage)?.asset?.path;
        })
        .filter(Boolean);

      if (imageUrls.length <= 0) {
        const { eventEmitter } = editorContextStore.getState();

        eventEmitter.emit<UiDisplayMessageEventHandler>(
          "ui:display-message",
          "info",
          "Please upload at least one keyframe before running auto-caption.",
        );
        return null;
      }

      const hasStartFrame = isGenerateVideoKeyFrameWithImage(keyFrames[0]);
      const hasEndFrame =
        keyFrames.length > 0 && isGenerateVideoKeyFrameWithImage(keyFrames[keyFrames.length - 1]);

      const keyframePrompt =
        hasStartFrame && hasEndFrame
          ? "with these keyframes as start and end frames IN ORDER"
          : hasStartFrame
            ? "with this image as the start frame"
            : hasEndFrame
              ? "with this image as the end frame"
              : "";

      const llmPrompt = cameraMotion
        ? `Describe in detail a video ${keyframePrompt}. Make sure the camera motion is ${cameraMotion}.Describe all the subject, surroundings, background, lighting, movements, and camera motion in detail. Start the caption with 'a video of'. Return plain object of type {"caption": string}`
        : `Describe in detail a video ${keyframePrompt}. Describe all the subject, surroundings, background, lighting, movements, and camera motion in detail. Start the caption with 'a video of'. Also pick the most suitable camera motion from this list: ['Move Left', 'Move Right', 'Move Up', 'Move Down', 'Push In', 'Pull Out', 'Zoom In', 'Zoom Out', 'Pan Left', 'Pan Right', 'Orbit Left', 'Orbit Right', 'Crane Up', 'Crane Down']. Return plain object of type {"caption": string, "camera_motion": string}`;

      debugLog(
        "Chat with images llm prompt: ",
        llmPrompt,
        "\nKey frames: ",
        keyFrames,
        `\nHas start frame ${hasStartFrame}; has end frame: ${hasEndFrame}`,
      );

      const response = await this.chatWithImages({
        imageUrls,
        llmPrompt,
      });

      const responseText = response.data?.responseText;

      if (!responseText) {
        return null;
      }

      debugLog("Input text: ", responseText);

      const responseParsed = parseDirtyJSON(responseText);

      debugLog("Parsed JSON: ", responseParsed);

      if (isGenerateVideoPromptResponseBody(responseParsed)) {
        const { caption: prompt, camera_motion: generatedCameraMotion } = responseParsed;

        return {
          prompt,
          cameraMotion: isVideoCameraMotion(generatedCameraMotion)
            ? generatedCameraMotion
            : cameraMotion,
        };
      }

      return null;
    } catch (error) {
      debugError("Error generating video prompt ", error);
      return null;
    }
  }
}
