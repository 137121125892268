import React from "react";
import { classNames } from "@/core/utils/classname-utils";
import {
  HorizontalImageGrid,
  HorizontalImageGridImageItem,
} from "components/panels/panel-items/components/horizontal-image-grid";
import { getPromptFromTemplate } from "@/core/common/prompt-template";
import type { GenerateTemplateItem } from "@/core/common/types";
import { Editor } from "@/core/editor";
import { handleSelectFlairGenerateTemplateItem } from "components/panels/panel-items/components/generate-templates/generate-templates-grid";
import { LeftPanelImageGridItem } from "components/panels/panel-items/components/image-grid-item";
import { PastGenerationsEmptyGridItem } from "components/panels/panel-items/generate/past-generations";
import { useInView } from "react-intersection-observer";
import { editorContextStore } from "contexts/editor-context";
import { isGenerateTemplateItemV2 } from "@/core/utils/type-guards";

function getHorizontalImageGridItemsFromGenerateTemplateItem(
  generateTemplateItem: GenerateTemplateItem,
): HorizontalImageGridImageItem | undefined {
  try {
    if (isGenerateTemplateItemV2(generateTemplateItem)) {
      return {
        imgPath: generateTemplateItem.previewImagePath,
        id: generateTemplateItem.id,
        prompt: generateTemplateItem.prompt,
        alt: generateTemplateItem.prompt,
      };
    } else {
      const url = generateTemplateItem.imgSrc;

      return {
        imgPath: url,
        alt: generateTemplateItem.alt,
        id: generateTemplateItem.id,
        prompt: getPromptFromTemplate(generateTemplateItem.template.prompt),
      };
    }
  } catch (error) {
    console.error(error);
  }

  return undefined;
}

export function getHorizontalImageGridItemsFromGenerateTemplateItems(
  templateItems: GenerateTemplateItem[],
) {
  const output: Record<string, HorizontalImageGridImageItem & { index: number }> = {};
  templateItems.forEach((generateTemplateItem, index) => {
    const item = getHorizontalImageGridItemsFromGenerateTemplateItem(generateTemplateItem);

    if (!item) {
      return;
    }

    output[index] = {
      ...item,
      index,
    };
  });
  return output;
}

function onClickImage(item: any, templateItems: GenerateTemplateItem[]) {
  const index = (item as any)?.index;
  if (typeof index !== "number") {
    return;
  }
  const templateItem = templateItems[index];
  if (!templateItem) {
    return;
  }
  const newTemplateObject = {
    id: item.id as string,
    prompt: item.prompt as string,
    image_src: item.imgPath as string,
    tags: [],
    score: 0,
    is_tags_null: true,
  };

  handleSelectFlairGenerateTemplateItem(newTemplateObject);
}

export function GenerateTemplatesHorizontalImageGrid({
  templateItems,
  editor,
  onClickViewAll,
  tagName,
}: {
  templateItems: GenerateTemplateItem[];
  editor: Editor | null;
  onClickViewAll?: () => void;
  tagName: string;
}) {
  const horizontalItems = getHorizontalImageGridItemsFromGenerateTemplateItems(templateItems);
  return (
    <HorizontalImageGrid
      items={horizontalItems}
      onClickViewAll={onClickViewAll}
      onSelectItem={(item, event) => {
        onClickImage(item, templateItems);
      }}
      getHoverCardContent={(item) => (
        <>
          <span>{item.prompt}</span>
          <span className="mt-2 text-zinc-500">Click to use this prompt template.</span>
        </>
      )}
    />
  );
}

export function GenerateTemplatesExpandedHorizontalImageGrid({
  limit = 8,
  className,
  setIsAnyExpanded,
  templateItems,
  templatesByTag,
  setTemplatesByTag,
  tag,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  limit?: number;
  setIsAnyExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  templateItems: GenerateTemplateItem[];
  templatesByTag: Record<string, GenerateTemplateItem[]>;
  setTemplatesByTag: React.Dispatch<React.SetStateAction<Record<string, GenerateTemplateItem[]>>>;
  tag: string;
}) {
  const [lastRowRef, lastRowInView] = useInView();

  React.useEffect(() => {
    const fetchData = async () => {
      if (lastRowInView && tag) {
        await editorContextStore.getState().backend?.setFirestoreTemplatesByTagNextBatch(tag, 10);
        const temps = await editorContextStore.getState().backend?.getFirestoreTemplatesByTag(tag);
        if (temps) {
          const updatedTemplatesByTag = {
            ...templatesByTag,
            [tag]: temps,
          };

          setTemplatesByTag(updatedTemplatesByTag);
        }
      }
    };
    fetchData();
  }, [tag, lastRowInView, setTemplatesByTag, templatesByTag]);

  const horizontalItems = templatesByTag[tag]
    ? getHorizontalImageGridItemsFromGenerateTemplateItems(templatesByTag[tag])
    : {};
  const horizontalItemsArray = Object.entries(horizontalItems).map(([id, item]) => ({
    id,
    item,
  }));

  return (
    <div className={classNames("flex flex-col")} {...props}>
      <div className="flex justify-end"></div>
      <div className="grid grid-cols-2 gap-2">
        {horizontalItemsArray.map((gridItem, index) => (
          <LeftPanelImageGridItem
            key={gridItem.id}
            ratio={1}
            ref={index === horizontalItemsArray.length - 1 ? lastRowRef : undefined}
            delayDuration={0}
            imgSrc={gridItem.item.imgPath}
            imgAlt={gridItem.item.alt}
            imageProps={{
              draggable: false,
            }}
            className={className}
            onClick={() => {
              onClickImage(gridItem.item, templateItems);
            }}
            hoverCardContent={
              <>
                <span>{gridItem.item.prompt}</span>
                <span className="mt-2 text-zinc-500">Click to use this prompt.</span>
              </>
            }
          />
        ))}
        {horizontalItemsArray.length === 0 && <PastGenerationsEmptyGridItem key="empty-grid" />}
      </div>
    </div>
  );
}
