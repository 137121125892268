import { cloneDeep } from "lodash";
import { Editor } from "@/core/editor";
import { Assets } from "@/core/controllers/assets";
import {
  GenerateTemplateItem,
  GenerateTemplateItemV1,
  GenerateTemplateItemV2,
  GenerateToolReferenceImage,
  PromptEditorEventHandler,
} from "@/core/common/types";
import {
  getPromptStateFromPromptTemplate,
  mergePromptTemplateSubjects,
} from "@/core/common/prompt-template";
import { isDataURL, isValidHttpsUrl } from "./string-utils";
import { isSceneJSON, SceneJSON } from "@/core/common/types/scene-json";
import { DEFAULT_CANVAS_LENGTH } from "@/core/common/constants";
import { addSceneJsonToCanvas } from "./scene-json-utils";
import { isGenerateTemplateItemV2 } from "@/core/utils/type-guards";
import { Backend } from "@/backend/base";

function modifyReferenceImageUrl(url: string): string {
  // Define the regular expression to match the specified format
  const regex =
    /^https:\/\/imagedelivery\.net\/i1XPW6iC_chU01_6tBPo8Q\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/;

  // Check if the URL matches the pattern
  if (regex.test(url)) {
    // Replace the variant part with '1024'
    return url.replace(/\/[a-zA-Z0-9-]+$/, "/1024");
  } else {
    // If the URL doesn't match, return the original URL
    return url;
  }
}

function getEditorAssetFromReferenceImage(
  referenceImageSrc?: string,
  previewPath?: string,
): GenerateToolReferenceImage | null {
  if (!referenceImageSrc) {
    return null;
  }

  referenceImageSrc = modifyReferenceImageUrl(referenceImageSrc);

  const editorAsset = Assets.getEditorAssetFromPath(referenceImageSrc);

  if (!editorAsset) {
    return null;
  }

  return {
    ...editorAsset,
    previewPath,
  };
}

async function getSceneJsonFromGenerateTemplateV2({
  editor,
  generateTemplate,
}: {
  editor: Editor;
  generateTemplate: GenerateTemplateItemV2;
}) {
  const sceneJSONFilePath = generateTemplate.sceneJSONFilePath;

  const sceneJSONDownloadUrl = await editor.assets.loadAsset({
    path: sceneJSONFilePath,
  });

  if (!sceneJSONDownloadUrl) {
    return;
  }

  const response = await fetch(sceneJSONDownloadUrl);

  if (!response.ok) {
    throw new Error(`Fail to fetch file from ${sceneJSONDownloadUrl}: ${response.statusText}`);
  }

  const sceneJSON = await response.json();

  if (!isSceneJSON(sceneJSON)) {
    return;
  }

  return sceneJSON;
}

async function loadSceneJsonFromGenerateTemplateV2({
  editor,
  generateTemplate,
  sceneJSON,
}: {
  editor: Editor;
  generateTemplate: GenerateTemplateItemV2;
  sceneJSON?: SceneJSON;
}) {
  try {
    const generationFrame = editor.generationFrames.generationFrame;
    const { left = 0, top = 0, height = DEFAULT_CANVAS_LENGTH } = generationFrame ?? {};

    sceneJSON =
      sceneJSON ||
      (await getSceneJsonFromGenerateTemplateV2({
        editor,
        generateTemplate,
      }));

    if (!sceneJSON) {
      return;
    }

    const objectsInsideGenerationFrame =
      editor.generationFrames.getObjectsIntersectingGenerationFrame();
    const isGenerationFrameEmpty = objectsInsideGenerationFrame.length <= 0;

    await addSceneJsonToCanvas({
      editor,
      sceneJSON,
      targetGenerationFrameTopLeft: {
        top: isGenerationFrameEmpty ? top : top + height,
        left,
      },
      // skipOffset: true,
    });
  } catch (error) {
    console.error(error);
  }
}

async function applyGenerateTemplateV2({
  editor,
  generateTemplate,
}: {
  editor: Editor;
  generateTemplate: GenerateTemplateItemV2;
}) {
  const {
    setGenerateToolReferenceImage,
    setGenerateToolPromptTemplate,
    setGenerateToolPromptEditorType,
  } = editor.state;

  const referenceImagePath = generateTemplate.referenceImagePath;

  if (referenceImagePath) {
    setGenerateToolReferenceImage({
      type:
        isValidHttpsUrl(referenceImagePath) || isDataURL(referenceImagePath)
          ? "image-url"
          : "image-storage",
      path: referenceImagePath,
    });
  } else {
    setGenerateToolReferenceImage(undefined);
  }

  await loadSceneJsonFromGenerateTemplateV2({
    editor,
    generateTemplate,
  });

  const promptTemplate = generateTemplate.promptTemplate;

  setGenerateToolPromptTemplate(promptTemplate);

  setGenerateToolPromptEditorType("Template");

  editor?.emit<PromptEditorEventHandler>(
    "prompt-editor:set-state",
    getPromptStateFromPromptTemplate(promptTemplate),
  );
}

async function applyGenerateTemplateV1({
  editor,
  generateTemplate,
}: {
  editor: Editor;
  generateTemplate: GenerateTemplateItemV1;
}) {
  const {
    setGenerateToolReferenceImage,
    setGenerateToolPromptTemplate,
    setGenerateToolPromptEditorType,
  } = editor.state;

  const templateTo = cloneDeep(generateTemplate.template.prompt);

  const referenceImage = generateTemplate.template.referenceImage;

  const referenceImageEditorAsset = getEditorAssetFromReferenceImage(
    referenceImage,
    generateTemplate.imgSrc,
  );

  if (referenceImageEditorAsset) {
    setGenerateToolReferenceImage(referenceImageEditorAsset);
  } else {
    setGenerateToolReferenceImage(undefined);
  }

  setGenerateToolPromptTemplate((templateFrom) => {
    if (templateFrom) {
      // Merge the subjects
      return mergePromptTemplateSubjects(templateFrom, templateTo);
    }
    return templateTo;
  });

  setGenerateToolPromptEditorType("Template");

  editor?.emit<PromptEditorEventHandler>(
    "prompt-editor:set-state",
    getPromptStateFromPromptTemplate(templateTo),
  );
}

export async function applyGenerateTemplateItem({
  editor,
  generateTemplate,
}: {
  editor: Editor;
  generateTemplate: GenerateTemplateItem;
}) {
  try {
    if (isGenerateTemplateItemV2(generateTemplate)) {
      await applyGenerateTemplateV2({
        editor,
        generateTemplate,
      });
    } else {
      await applyGenerateTemplateV1({
        editor,
        generateTemplate,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export async function handleSelectGenerateTemplateItem({
  editor,
  backend,
  id,
}: {
  editor: Editor;
  backend: Backend;
  id: string;
}) {
  try {
    const generateTemplate = await backend.getFirestoreTemplate(id);

    if (!generateTemplate) {
      return;
    }

    await applyGenerateTemplateItem({
      editor,
      generateTemplate,
    });
  } catch (error) {
    console.error(error);
  }
}
