import { AppUserSubscriptionTierV2 } from "../types";

export enum AppSubscriptionPlanType {
  Free = "Free",
  Pro = "Pro",
  ProPlus = "Pro+",
  Scale = "Scale",
  Enterprise = "Enterprise",
}

export enum StripeSubscriptionPriceId {
  IndividualProPlan = "price_1MdzaWLI14x6JehmXZPh9zlE",
  IndividualProPlanAnnually = "price_1Q8bunLI14x6JehmKB6Azvqr",
  IndividualProPlusPlan = "price_1OmNjPLI14x6Jehm1iGkRijd",
  IndividualProPlusPlanAnnually = "price_1Q8btgLI14x6JehmRZwv8VUL",
  IndividualScalePlanMonthly = "price_1Q77NFLI14x6JehmYKUnT2rj",
  IndividualScalePlanAnnualy = "price_1Q8bsGLI14x6JehmPmMhiJP4",
  ApiStandardPlan = "price_1O9AD7LI14x6Jehmobvvbqro",
  OneTimePayment = "price_1Q8DW1LI14x6JehmYZNuztrW",
}

export type StripeSubscriptionPriceIdAnnually =
  | StripeSubscriptionPriceId.IndividualProPlanAnnually
  | StripeSubscriptionPriceId.IndividualProPlusPlanAnnually
  | StripeSubscriptionPriceId.IndividualScalePlanAnnualy;

export function isStripeSubscriptionPriceIdAnnually(
  priceId: any,
): priceId is StripeSubscriptionPriceIdAnnually {
  return (
    priceId === StripeSubscriptionPriceId.IndividualProPlanAnnually ||
    priceId === StripeSubscriptionPriceId.IndividualProPlusPlanAnnually ||
    priceId === StripeSubscriptionPriceId.IndividualScalePlanAnnualy
  );
}

export enum StripeSubscriptionProductId {
  IndividualProPlan = "prod_NBRGNzZxW8R533",
  IndividualProPlusPlan = "prod_PbavOeZrnOyekM",
  IndividualScalePlan = "prod_Qz5XUjJhGKvi09",
  ApiStandardPlan = "prod_Ox4LrgcmOcKUig",
  TeamStandardPlan = "prod_QTs0FsJABxpXME",
  OneTimePayment = "prod_QzfxZ3cXQBMnBt",
}

export interface StripeSubscriptionLineItem {
  price: StripeSubscriptionPriceId;
  quantity?: number;
}

export function isStripeSubscriptionPriceId(value: any): value is StripeSubscriptionPriceId {
  return Object.values(StripeSubscriptionPriceId).includes(value);
}

export function isStripeSubscriptionProductId(value: any): value is StripeSubscriptionProductId {
  return Object.values(StripeSubscriptionProductId).includes(value);
}

export const subscriptionPlanToProduct: Record<
  AppSubscriptionPlanType,
  StripeSubscriptionProductId | undefined
> = {
  [AppSubscriptionPlanType.Free]: undefined,
  [AppSubscriptionPlanType.Pro]: StripeSubscriptionProductId.IndividualProPlan,
  [AppSubscriptionPlanType.ProPlus]: StripeSubscriptionProductId.IndividualProPlusPlan,
  [AppSubscriptionPlanType.Scale]: StripeSubscriptionProductId.IndividualScalePlan,
  [AppSubscriptionPlanType.Enterprise]: undefined,
};

export const subscriptionProductToPlan: Record<
  StripeSubscriptionProductId,
  AppSubscriptionPlanType | undefined
> = {
  [StripeSubscriptionProductId.IndividualProPlan]: AppSubscriptionPlanType.Pro,
  [StripeSubscriptionProductId.IndividualProPlusPlan]: AppSubscriptionPlanType.ProPlus,
  [StripeSubscriptionProductId.IndividualScalePlan]: AppSubscriptionPlanType.Scale,
  [StripeSubscriptionProductId.ApiStandardPlan]: undefined,
  [StripeSubscriptionProductId.TeamStandardPlan]: AppSubscriptionPlanType.Pro,
  [StripeSubscriptionProductId.OneTimePayment]: undefined,
};

export const subscriptionPlanToPriceMonthly = {
  [AppSubscriptionPlanType.Free]: undefined,
  [AppSubscriptionPlanType.Pro]: StripeSubscriptionPriceId.IndividualProPlan,
  [AppSubscriptionPlanType.ProPlus]: StripeSubscriptionPriceId.IndividualProPlusPlan,
  [AppSubscriptionPlanType.Scale]: StripeSubscriptionPriceId.IndividualScalePlanMonthly,
  [AppSubscriptionPlanType.Enterprise]: undefined,
};

export const subscriptionPlanToPriceAnnually = {
  [AppSubscriptionPlanType.Free]: undefined,
  [AppSubscriptionPlanType.Pro]: StripeSubscriptionPriceId.IndividualProPlanAnnually,
  [AppSubscriptionPlanType.ProPlus]: StripeSubscriptionPriceId.IndividualProPlusPlanAnnually,
  [AppSubscriptionPlanType.Scale]: StripeSubscriptionPriceId.IndividualScalePlanAnnualy,
  [AppSubscriptionPlanType.Enterprise]: undefined,
};

export const subscriptionPlanRank = {
  [AppSubscriptionPlanType.Free]: 0,
  [AppSubscriptionPlanType.Pro]: 1,
  [AppSubscriptionPlanType.ProPlus]: 2,
  [AppSubscriptionPlanType.Scale]: 3,
  [AppSubscriptionPlanType.Enterprise]: 4,
};

export function isSubscriptionPlanBetterThan(
  planA: AppSubscriptionPlanType,
  planB: AppSubscriptionPlanType,
) {
  return subscriptionPlanRank[planA] > subscriptionPlanRank[planB];
}

export const subscriptionTierV2ToRecommendedPlan: Record<
  AppUserSubscriptionTierV2,
  AppSubscriptionPlanType
> = {
  [AppUserSubscriptionTierV2.Free]: AppSubscriptionPlanType.ProPlus,
  [AppUserSubscriptionTierV2.Pro]: AppSubscriptionPlanType.ProPlus,
  [AppUserSubscriptionTierV2.ProPlus]: AppSubscriptionPlanType.ProPlus,
};

export const subscriptionPlanToRecommendedPlans: Record<
  AppSubscriptionPlanType,
  AppSubscriptionPlanType[]
> = {
  [AppSubscriptionPlanType.Free]: [
    AppSubscriptionPlanType.ProPlus,
    AppSubscriptionPlanType.Scale,
    AppSubscriptionPlanType.Enterprise,
  ],
  [AppSubscriptionPlanType.Pro]: [
    AppSubscriptionPlanType.ProPlus,
    AppSubscriptionPlanType.Scale,
    AppSubscriptionPlanType.Enterprise,
  ],
  [AppSubscriptionPlanType.ProPlus]: [
    AppSubscriptionPlanType.Scale,
    AppSubscriptionPlanType.Enterprise,
  ],
  [AppSubscriptionPlanType.Scale]: [AppSubscriptionPlanType.Enterprise],
  [AppSubscriptionPlanType.Enterprise]: [],
};

export const subscriptionPlanToRecommendedPlan: Record<
  AppSubscriptionPlanType,
  AppSubscriptionPlanType | undefined
> = Object.fromEntries(
  Object.entries(subscriptionPlanToRecommendedPlans).map(([plan, recommendedPlans]) => [
    plan,
    recommendedPlans[0],
  ]),
) as Record<AppSubscriptionPlanType, AppSubscriptionPlanType | undefined>;

export const subscriptionTierV2ToPlan: Record<AppUserSubscriptionTierV2, AppSubscriptionPlanType> =
  {
    [AppUserSubscriptionTierV2.Free]: AppSubscriptionPlanType.Free,
    [AppUserSubscriptionTierV2.Pro]: AppSubscriptionPlanType.Pro,
    [AppUserSubscriptionTierV2.ProPlus]: AppSubscriptionPlanType.ProPlus,
  };
