export function getColorHexDisplay(colorHex: string) {
  if (colorHex.startsWith("#")) {
    return colorHex.substring(1).toUpperCase();
  }
  return colorHex.toUpperCase();
}

export function isColorHexValid(colorHex: string) {
  return /^#[0-9A-F]{6}$/i.test(colorHex);
}

export function getColorRGBTupleFromHex(colorHex: string): [number, number, number] {
  // Remove the leading '#' if present
  if (colorHex.startsWith("#")) {
    colorHex = colorHex.slice(1);
  }

  // Expand shorthand form (e.g., "03F") to full form ("0033FF")
  if (colorHex.length === 3) {
    colorHex = colorHex
      .split("")
      .map((c) => c + c)
      .join("");
  }

  // Now colorHex should be 6 characters long
  if (colorHex.length !== 6) {
    throw new Error("Invalid hex color string");
  }

  // Validate that all characters are hex digits
  if (!/^[0-9A-Fa-f]{6}$/.test(colorHex)) {
    throw new Error("Invalid hex color string");
  }

  // Extract R, G, B components
  const r = parseInt(colorHex.slice(0, 2), 16);
  const g = parseInt(colorHex.slice(2, 4), 16);
  const b = parseInt(colorHex.slice(4, 6), 16);

  return [r, g, b];
}
