import React from "react";
import { editorContextStore } from "contexts/editor-context";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import {
  CustomModelType,
  FrontendDisplayTemplateType,
  getCustomModelTypeFromFrontendDisplayTemplateType,
  getCustomModelWorkflowFromCustomModelInfo,
  isCustomModelType,
  isFrontendDisplayTemplateType,
  UiDisplayMessageEventHandler,
} from "@/core/common/types";

async function handleCreateCustomModel({
  navigate,
  customModelType = CustomModelType.Custom,
  frontendDisplayTemplateType,
}: {
  navigate: NavigateFunction;
  customModelType?: CustomModelType;
  frontendDisplayTemplateType?: FrontendDisplayTemplateType;
}) {
  const { eventEmitter, backend, setCustomModelId, setCustomModelInfo, setCustomModelWorkflow } =
    editorContextStore.getState();

  if (!eventEmitter || !backend) {
    debugError(
      "Cannot create custom model because the event emitter and backend is not initialized.",
    );
    return;
  }

  try {
    const response = await backend.createCustomModel({
      customModelType,
      frontendDisplayTemplateType,
    });

    if (!response.ok) {
      eventEmitter.emit<UiDisplayMessageEventHandler>(
        "ui:display-message",
        "error",
        // @ts-expect-error
        response.message,
      );
      return;
    }

    const { id, customModelInfo } = response;

    debugLog("Created custom model:\n", customModelInfo);

    if (id && typeof id === "string") {
      setCustomModelId(id);
      setCustomModelInfo(customModelInfo);
      setCustomModelWorkflow(
        getCustomModelWorkflowFromCustomModelInfo({
          frontendDisplayTemplateType,
          customModelType,
        }),
      );
      navigate(`/models/${id}`, {
        replace: true,
      });
      return;
    }
  } catch (error) {
    navigate("/models");
  }
}

export function CreateCustomModel() {
  const navigate = useNavigate();
  const { workflow } = useParams();
  const isModelCreatedRef = React.useRef(false);

  React.useEffect(() => {
    if (isModelCreatedRef.current) {
      return;
    }

    isModelCreatedRef.current = true;
    const frontendDisplayTemplateType = isFrontendDisplayTemplateType(workflow)
      ? workflow
      : undefined;
    const customModelType = isFrontendDisplayTemplateType(workflow)
      ? getCustomModelTypeFromFrontendDisplayTemplateType(workflow)
      : isCustomModelType(workflow)
        ? workflow
        : CustomModelType.Custom;

    handleCreateCustomModel({
      navigate,
      customModelType,
      frontendDisplayTemplateType,
    });
  }, [navigate, workflow]);

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center text-base text-zinc-500 bg-zinc-900">
      <SimpleSpinner pathClassName="fill-lime-500" />
      Initializing custom model ...
    </div>
  );
}
