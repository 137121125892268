import { CANVAS_CONTAINER_ID } from "components/constants/ids";
import { editorContextStore } from "contexts/editor-context";
import {
  DEFAULT_CANVAS_LENGTH,
  getMouseButtonFromPointerEvent,
  MouseButton,
} from "@/core/common/constants";
import React from "react";
import { createPortal } from "react-dom";
import {
  commitInpaintCommand,
  drawCircle,
  getInpaintBrushLineWidth,
  getLocalCoordinateFromCanvasPointerEvent,
  initInpaintCanvasContext,
  commitInpaintSnapshot,
  clearCanvas,
} from "./utils";

import type { InpaintCommandData } from "@/core/command";
import {
  EditorObjectJson,
  InpaintBrushType,
  MagicEraseCanvasSnapshot,
  SetActiveHistoryHandler,
} from "@/core/common/types";
import { PaintBrushCursor } from "./paint-brush-cursor";
import { hideObjectControls, showObjectControls } from "@/core/utils/fabric";
import { isFabricObject, isStaticImageObject } from "@/core/utils/type-guards";
import { MagicEraseHistory } from "@/core/controllers/history/magic-erase-history";
import _ from "lodash";
import { updateObjectFromJson } from "@/core/utils/history-utils";

export type MagicEraseFrameImperativeHandle = {
  getCanvasElement: () => HTMLCanvasElement | null;
  getCanvasSnapshot: () => MagicEraseCanvasSnapshot;
  clearCanvas: (commit?: boolean) => void;
  restoreCanvas: (snapshot: MagicEraseCanvasSnapshot) => void;
};

function renderCommand(
  context: CanvasRenderingContext2D | undefined | null,
  command: InpaintCommandData,
) {
  if (!command || !context) {
    return;
  }
  const { type, points, lineWidth, color } = command;
  const numPoints = (points.length || 0) / 2;
  if (type && numPoints > 0 && lineWidth > 0) {
    initInpaintCanvasContext(context, type, lineWidth, color);
    let prevPointerX = points[0];
    let prevPointerY = points[1];
    drawCircle(context, {
      x: prevPointerX,
      y: prevPointerY,
      r: lineWidth * 0.5,
    });
    for (let i = 1; i < numPoints; ++i) {
      const currentX = points[2 * i + 0];
      const currentY = points[2 * i + 1];

      context.beginPath();
      context.moveTo(prevPointerX, prevPointerY);
      context.lineTo(currentX, currentY);
      context.stroke();

      prevPointerX = currentX;
      prevPointerY = currentY;
    }
  }
}

export const MagicEraseFrame = React.forwardRef(
  (
    {
      historyRef,
      onCanvasSnapshotUpdate,
    }: {
      historyRef: { current?: MagicEraseHistory };
      onCanvasSnapshotUpdate?: (canvasSnapshot: MagicEraseCanvasSnapshot) => void;
    },
    ref: React.ForwardedRef<MagicEraseFrameImperativeHandle>,
  ) => {
    const container = React.useMemo(() => document.getElementById(CANVAS_CONTAINER_ID), []);
    const editor = editorContextStore((state) => state.editor);
    const inpaintBrushSize = editorContextStore((state) => state.inpaintBrushSize);
    const setInpaintBrushSize = editorContextStore((state) => state.setInpaintBrushSize);
    const inpaintBrushColor = editorContextStore((state) => state.inpaintBrushColor);
    const setInpaintBrushColor = editorContextStore((state) => state.setInpaintBrushColor);
    const _setIsInpaintPointerDown = editorContextStore((state) => state.setIsInpaintPointerDown);
    const activeLeftPanels = editorContextStore((state) => state.activeLeftPanels);
    const activeInpaintMode = editorContextStore((state) => state.activeInpaintMode);
    const inpaintBrushType = editorContextStore((state) => state.activeInpaintBrush);
    const activeObject = editorContextStore((state) => state.activeObject);

    const [inpaintPointerOver, setInpaintPointerOver] = React.useState(false);
    const canvasContainerRef = React.useRef<HTMLDivElement | null>(null);
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const brushCursorRef = React.useRef<HTMLDivElement | null>(null);
    const canvasRenderingContextRef = React.useRef<CanvasRenderingContext2D | null>(null);
    const cameraZoomInvRef = React.useRef(1);
    const prevPointerXRef = React.useRef(0);
    const prevPointerYRef = React.useRef(0);
    const currentInpaintCommandRef = React.useRef<InpaintCommandData | null>(null);
    const isInpaintPointerDownRef = React.useRef(false);
    const inpaintCanvasSnapshotRef = React.useRef<MagicEraseCanvasSnapshot>({
      commands: [],
    });

    const [canvasSize, setCanvasSize] = React.useState({
      width: DEFAULT_CANVAS_LENGTH,
      height: DEFAULT_CANVAS_LENGTH,
    });

    const setIsInpaintPointerDown = React.useCallback(
      (value: boolean) => {
        isInpaintPointerDownRef.current = value;
        _setIsInpaintPointerDown(value);
      },
      [_setIsInpaintPointerDown],
    );

    const exitRender = React.useMemo(
      () =>
        !container ||
        !activeObject ||
        activeInpaintMode !== "magic-eraser" ||
        !activeLeftPanels.includes("MagicErase"),
      [container, activeObject, activeInpaintMode, activeLeftPanels],
    );

    React.useImperativeHandle(
      ref,
      () => ({
        getCanvasElement: () => canvasRef.current,
        getCanvasSnapshot: () => {
          const { activeObject } = editorContextStore.getState();

          if (isStaticImageObject(activeObject)) {
            inpaintCanvasSnapshotRef.current.imageObject = activeObject.toJSON(
              editor?.config.propertiesToInclude,
            ) as any as EditorObjectJson;
          } else {
            inpaintCanvasSnapshotRef.current.imageObject = undefined;
          }

          return inpaintCanvasSnapshotRef.current;
        },
        clearCanvas: (commit = true) => {
          if (
            editor &&
            !isInpaintPointerDownRef.current &&
            canvasRenderingContextRef.current &&
            canvasRef.current
          ) {
            canvasRenderingContextRef.current.clearRect(
              0,
              0,
              canvasRef.current.width || 0,
              canvasRef.current.height || 0,
            );
            if (commit) {
              historyRef.current?.save();
            }
          } else {
            if (!editor) {
              console.log("Editor is invalid");
            }
            if (isInpaintPointerDownRef.current) {
              console.log("Inpaint pointer down");
            }
            if (!canvasRenderingContextRef.current) {
              console.log("Canvas rendering context is none");
            }
            if (!canvasRef.current) {
              console.log("Canvas is invalid");
            }
            console.log("Canont clear canvas");
          }
        },
        restoreCanvas: (snapshot) => {
          if (!snapshot) {
            return;
          }

          if (!canvasRenderingContextRef.current) {
            return;
          }

          clearCanvas(canvasRenderingContextRef.current);
          inpaintCanvasSnapshotRef.current = {
            commands: [],
          };

          if (!snapshot.commands) {
            console.log("Canvas snapshot command is empty.");
            return;
          }

          snapshot.commands.forEach((command) => {
            renderCommand(canvasRenderingContextRef.current, command);
          });

          inpaintCanvasSnapshotRef.current = _.cloneDeep(snapshot);

          const { activeObject } = editorContextStore.getState();

          if (snapshot.imageObject && isStaticImageObject(activeObject)) {
            updateObjectFromJson({
              object: activeObject,
              objectJson: snapshot.imageObject,
            })?.then(() => {
              editor?.canvas.requestRenderAll();
            });
          }
        },
      }),
      [editor, historyRef],
    );

    React.useEffect(() => {
      if (isFabricObject(activeObject)) {
        hideObjectControls(activeObject);
        if (activeObject?.aCoords) {
          const { tl, br } = activeObject.aCoords;
          const width = br.x - tl.x;
          const height = br.y - tl.y;
          setCanvasSize({
            width,
            height,
          });
        }
      }
      return () => {
        if (isFabricObject(activeObject)) {
          showObjectControls(activeObject);
          editor?.canvas.requestRenderAll();
        }
      };
    }, [editor, activeObject]);

    const onBeforeRender = React.useCallback(() => {
      if (activeObject && canvasContainerRef.current) {
        activeObject.setCoords();
        const tl = activeObject.oCoords?.tl;
        const br = activeObject.oCoords?.br;
        canvasContainerRef.current.style.left = `${tl?.x || 0}px`;
        canvasContainerRef.current.style.top = `${tl?.y || 0}px`;
        if (tl && br) {
          const displayWidth = br.x - tl.x;
          const displayHeight = br.y - tl.y;
          if (displayWidth > 0) {
            canvasContainerRef.current.style.width = `${displayWidth}px`;
            canvasContainerRef.current.style.height = `${displayHeight}px`;
            if (canvasRef.current) {
              canvasRef.current.style.width = `${displayWidth}px`;
              canvasRef.current.style.height = `${displayHeight}px`;
              cameraZoomInvRef.current = canvasRef.current.width / displayWidth;
            }
          }
        }
      }
    }, [activeObject]);

    const onPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
      if (inpaintBrushType == null) {
        return;
      }
      const mouseButton = getMouseButtonFromPointerEvent(e.nativeEvent);
      if (canvasContainerRef.current && canvasRef.current) {
        if (mouseButton === MouseButton.Left) {
          setIsInpaintPointerDown(true);
          const { x, y } = getLocalCoordinateFromCanvasPointerEvent({
            e: e.nativeEvent,
            canvas: canvasRef.current,
            cameraZoomInv: cameraZoomInvRef.current,
          });
          prevPointerXRef.current = x;
          prevPointerYRef.current = y;
          if (canvasRenderingContextRef.current) {
            const lineWidth = getInpaintBrushLineWidth(inpaintBrushSize, cameraZoomInvRef.current);
            initInpaintCanvasContext(
              canvasRenderingContextRef.current,
              inpaintBrushType,
              lineWidth,
              inpaintBrushColor,
            );
            drawCircle(canvasRenderingContextRef.current, {
              x,
              y,
              r: lineWidth * 0.5,
            });
            currentInpaintCommandRef.current = {
              type: inpaintBrushType,
              points: [x, y],
              lineWidth,
              color: inpaintBrushColor,
            };
          }
        }
      }
      if (!isInpaintPointerDownRef.current) {
        editor?.events.onMouseDown({ button: mouseButton, e });
      }
    };
    const onPointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
      if (!canvasRef.current) {
        return;
      }
      if (isInpaintPointerDownRef.current) {
        if (canvasRenderingContextRef.current) {
          const { x: currentX, y: currentY } = getLocalCoordinateFromCanvasPointerEvent({
            e: e.nativeEvent,
            canvas: canvasRef.current,
            cameraZoomInv: cameraZoomInvRef.current,
          });

          canvasRenderingContextRef.current.beginPath();
          canvasRenderingContextRef.current.moveTo(
            prevPointerXRef.current,
            prevPointerYRef.current,
          );
          canvasRenderingContextRef.current.lineTo(currentX, currentY);
          canvasRenderingContextRef.current.stroke();

          prevPointerXRef.current = currentX;
          prevPointerYRef.current = currentY;

          currentInpaintCommandRef.current?.points?.push(currentX, currentY);
        }
      } else {
        const { x, y } = getLocalCoordinateFromCanvasPointerEvent({
          e: e.nativeEvent,
          canvas: canvasRef.current,
          cameraZoomInv: cameraZoomInvRef.current,
        });
        prevPointerXRef.current = x;
        prevPointerYRef.current = y;
        const mouseButton = getMouseButtonFromPointerEvent(e.nativeEvent);
        editor?.events.onMouseMove({ button: mouseButton, e });
      }
      if (brushCursorRef.current) {
        const { clientX, clientY } = e;
        brushCursorRef.current.style.left = `${clientX - inpaintBrushSize * 0.5}px`;
        brushCursorRef.current.style.top = `${clientY - inpaintBrushSize * 0.5}px`;
      }
    };
    const onPointerUp = (e: React.PointerEvent<HTMLDivElement>) => {
      if (isInpaintPointerDownRef.current) {
        if (editor && currentInpaintCommandRef.current) {
          inpaintCanvasSnapshotRef.current.commands?.push(currentInpaintCommandRef.current);
          historyRef.current?.save();
          onCanvasSnapshotUpdate?.(inpaintCanvasSnapshotRef.current);
          currentInpaintCommandRef.current = null;
        }
      } else {
        const mouseButton = getMouseButtonFromPointerEvent(e.nativeEvent);
        editor?.events.onMouseUp({ button: mouseButton, e: e.nativeEvent });
      }
      setIsInpaintPointerDown(false);
    };
    const onKeyDown = (event: React.KeyboardEvent) => {
      if (isInpaintPointerDownRef.current) {
        return;
      }
      editor?.events.onKeyDown(event.nativeEvent);
    };

    const handleCanvasContainerMount = React.useCallback(
      (canvasContainer: HTMLDivElement) => {
        canvasContainerRef.current = canvasContainer;
        if (canvasContainer) {
          canvasContainer?.addEventListener(
            "wheel",
            (e) => {
              if (!isInpaintPointerDownRef.current) {
                editor?.events.onMouseWheel({ e });
              }
            },
            { passive: false },
          );
        }
      },
      [editor],
    );

    const handleCanvasMount = React.useCallback((canvas: HTMLCanvasElement) => {
      canvasRef.current = canvas;
      canvasRenderingContextRef.current = canvas?.getContext("2d");
    }, []);

    React.useEffect(() => {
      if (exitRender) {
        editor?.canvas.canvas.off("before:render", onBeforeRender);
      } else {
        editor?.canvas.canvas.on("before:render", onBeforeRender);
      }
      return () => {
        editor?.canvas.canvas.off("before:render", onBeforeRender);
      };
    }, [editor, exitRender, onBeforeRender]);

    if (exitRender) {
      return null;
    }

    return createPortal(
      <div
        tabIndex={0}
        ref={handleCanvasContainerMount}
        className="absolute outline-none focus:outline-none focus-active:outline-none"
        onPointerOver={() => {
          if (inpaintBrushType != null) {
            setInpaintPointerOver(true);
          } else {
            setInpaintPointerOver(false);
          }
        }}
        onPointerLeave={() => {
          setInpaintPointerOver(false);
        }}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        onKeyDown={onKeyDown}
      >
        <PaintBrushCursor
          ref={brushCursorRef}
          style={{
            display: inpaintPointerOver ? "block" : "none",
            width: `${inpaintBrushSize || 0}px`,
            height: `${inpaintBrushSize || 0}px`,
          }}
        />
        <canvas
          className=""
          ref={handleCanvasMount}
          width={canvasSize.width}
          height={canvasSize.height}
          style={{
            cursor: inpaintPointerOver ? "none" : "default",
            opacity: 0.8,
          }}
        />
      </div>,
      // @ts-ignore
      container,
    );
  },
);
