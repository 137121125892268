import React from "react";
import { editorContextStore } from "contexts/editor-context";
import { EditNone } from "./edit-none";
import { isImageObject, isActiveSelection } from "@/core/utils/type-guards";
import { EditImagePanel } from "./edit-image";
import { EditSelectionPanel } from "./edit-selection";
import { EditorActiveObject } from "@/core/common/interfaces";

export function isEditLeftPanelDisabled(activeObject: EditorActiveObject) {
  if (isImageObject(activeObject)) {
    return false;
  }

  if (isActiveSelection(activeObject)) {
    return false;
  }

  return true;
}

export function EditLeftPanel() {
  const activeObject = editorContextStore((state) => state.activeObject);

  if (isImageObject(activeObject)) {
    return <EditImagePanel />;
  }

  if (isActiveSelection(activeObject)) {
    return <EditSelectionPanel />;
  }

  return <EditNone />;
}
