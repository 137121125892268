import React from "react";
import styles from "./documentation.module.css";
import { classNames } from "@/core/utils/classname-utils";
import * as Tabs from "@radix-ui/react-tabs";
import {
  ApiPipelineType,
  ENDPOINT_PREFIX,
  GenerateImageApiDocType,
  JobManagementTab,
} from "@/core/common/types/api";
import { GenerateImagePlaygroundContext } from "./generate-image-api-context";
import { getObjectEntries } from "@/core/utils/type-utils";
// import { GetGenerateImageApiPricingProps, getGenerateImageApiPricing } from "./api-pricing-utils";

function DocFieldHeaderName({ children }: { children: React.ReactNode }) {
  if (!children) {
    return null;
  }
  return <div className="text-sm text-zinc-200 font-semibold">{children}</div>;
}

function DocFieldHeaderTag({ children }: { children: React.ReactNode }) {
  if (!children) {
    return null;
  }
  return <div className="text-sm text-zinc-500">{children}</div>;
}

function DocFieldHeader({
  children,
  tags = [],
}: {
  children: React.ReactNode;
  tags?: React.ReactNode[];
}) {
  if (!children) {
    return null;
  }
  return (
    <div
      className={classNames(
        styles.ContainerWithVerticalDividers,
        "flex flex-row justify-start items-center",
      )}
    >
      <DocFieldHeaderName>{children}</DocFieldHeaderName>
      {tags.map((tag, index) => (
        <DocFieldHeaderTag key={index}>{tag}</DocFieldHeaderTag>
      ))}
    </div>
  );
}

function CodeInline({ children }: { children: React.ReactNode }) {
  if (!children) {
    return null;
  }
  return (
    <span className="p-1 rounded bg-zinc-800/50 text-zinc-200 border border-zinc-800">
      {children}
    </span>
  );
}

function DocFieldContent({ children }: { children: React.ReactNode }) {
  if (!children) {
    return null;
  }
  return <div className="text-sm text-zinc-400 leading-relaxed">{children}</div>;
}

type DocFieldProps = {
  headerName: React.ReactNode;
  headerTags: React.ReactNode[];
  content: React.ReactNode;
};

function DocField({ headerName, headerTags, content }: DocFieldProps) {
  return (
    <div className="flex flex-col justify-start gap-4">
      <DocFieldHeader tags={headerTags}>{headerName}</DocFieldHeader>
      <DocFieldContent>{content}</DocFieldContent>
    </div>
  );
}

function DocFieldGroupHeader({ children }: { children: React.ReactNode }) {
  if (!children) {
    return null;
  }
  return <div className="text-xl font-semibold text-zinc-200">{children}</div>;
}

function DocFieldGroupDescription({ children }: { children: React.ReactNode }) {
  if (!children) {
    return null;
  }
  return <div className="text-sm text-zinc-400">{children}</div>;
}

type DocFieldGroupConfig = {
  name: React.ReactNode;
  description: React.ReactNode;
  fields: Record<string, DocFieldProps>;
};

function DocFieldGroup({ config }: { config: DocFieldGroupConfig }) {
  const { name, description, fields } = config;
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <DocFieldGroupHeader>{name}</DocFieldGroupHeader>
        <DocFieldGroupDescription>{description}</DocFieldGroupDescription>
      </div>
      <div className={classNames(styles.ContainerWithHorizontalDividers, "flex flex-col")}>
        {getObjectEntries(fields).map(([key, props], index) => (
          <DocField key={key} {...props} />
        ))}
      </div>
    </div>
  );
}

function DocHeader({ children }: { children: React.ReactNode }) {
  if (!children) {
    return null;
  }
  return <div className="text-2xl font-semibold text-zinc-200">{children}</div>;
}

function DocDescription({ children }: { children: React.ReactNode }) {
  if (!children) {
    return null;
  }
  return <div className="text-sm text-zinc-400">{children}</div>;
}

/** fairly generic way of recording a particular arg for an HTTP request */
type DocConfig = {
  name: React.ReactNode;
  description: React.ReactNode;
  fieldGroups: Record<string, DocFieldGroupConfig>;
};

function Doc({ config }: { config: DocConfig }) {
  const { name, description, fieldGroups } = config;
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <DocHeader>{name}</DocHeader>
        <DocDescription>{description}</DocDescription>
      </div>
      <div className={classNames(styles.ContainerWithHorizontalDividers, "flex flex-col")}>
        {getObjectEntries(fieldGroups).map(([key, fieldGroupConfig]) => (
          <DocFieldGroup key={key} config={fieldGroupConfig} />
        ))}
      </div>
    </div>
  );
}

// Base documentation fields shared across all pipelines
const baseDocFields: Record<string, DocFieldProps> = {
  pipelineType: {
    headerName: "pipeline_type",
    headerTags: ["required"],
    content: (
      <span>
        The pipeline to use for image generation. Options include <CodeInline>default</CodeInline>,{" "}
        <CodeInline>ref_default</CodeInline>, <CodeInline>canny</CodeInline>, and{" "}
        <CodeInline>ref_canny</CodeInline>.
      </span>
    ),
  },
  prompt: {
    headerName: "prompt",
    headerTags: ["required"],
    content: (
      <span>
        A text description of the desired image(s). Prefer describing only what you <i>want</i> to
        see. If there's anything you want to avoid, describe that in{" "}
        <CodeInline>negative_prompt</CodeInline>.
      </span>
    ),
  },
  negativePrompt: {
    headerName: "negative_prompt",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>""</CodeInline>
      </span>,
    ],
    content: (
      <span>
        A text description of what you wish to avoid in the generated image(s). This helps guide the
        generation away from undesired elements.
      </span>
    ),
  },
  width: {
    headerName: "width",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>1024</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The width of the generated images in pixels. Allowed values range from{" "}
        <CodeInline>512</CodeInline> to <CodeInline>1536</CodeInline>, with increments of{" "}
        <CodeInline>64</CodeInline> pixels.
      </span>
    ),
  },
  height: {
    headerName: "height",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>1024</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The height of the generated images in pixels. Allowed values range from{" "}
        <CodeInline>512</CodeInline> to <CodeInline>1536</CodeInline>, with increments of{" "}
        <CodeInline>64</CodeInline> pixels.
      </span>
    ),
  },
  numInferenceSteps: {
    headerName: "num_inference_steps",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>25</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The number of inference steps to take when generating the image(s). Allowed values range
        from <CodeInline>1</CodeInline> to <CodeInline>50</CodeInline>. Higher values can lead to
        more detailed and refined images but will increase generation time.
      </span>
    ),
  },
  guidanceScale: {
    headerName: "guidance_scale",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>7.5</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The guidance scale used during image generation, influencing how closely the output adheres
        to the prompt. Allowed values range from <CodeInline>0.0</CodeInline> to{" "}
        <CodeInline>20.0</CodeInline>. Higher values result in stronger adherence to the prompt, but
        too high usually results in over-saturated images.
      </span>
    ),
  },
  seed: {
    headerName: "seed",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>-1</CodeInline> (indicating a random seed)
      </span>,
    ],
    content: (
      <span>
        A specific seed number to ensure the reproducibility of the images. Using the same seed with
        identical parameters will produce similar images, which is useful for consistent results
        across multiple generations.
      </span>
    ),
  },
};
const defaultDocFields: Record<string, DocFieldProps> = {
  ...baseDocFields,
  // No additional fields for the default pipeline
};
const refDefaultDocFields: Record<string, DocFieldProps> = {
  ...baseDocFields,
  refImage: {
    headerName: "ref_image",
    headerTags: ["required"],
    content: (
      <span>
        A data URL for the reference image (PNG, JPEG, or WEBP; max 4 MB). This image guides the
        style of the generated output. Use this when you want the generated image to adopt specific
        stylistic elements from an existing image.
      </span>
    ),
  },
  ipAdapterScaleStart: {
    headerName: "ip_adapter_scale_start",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>0.6</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The starting scale for the reference image's influence on the generation process. Ranges
        from <CodeInline>0.0</CodeInline> to <CodeInline>1.0</CodeInline>. Use higher values to make
        the reference image more influential at the beginning of the generation.
      </span>
    ),
  },
  ipAdapterScaleFinish: {
    headerName: "ip_adapter_scale_finish",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>0.0</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The ending scale for the reference image's influence on the generation process. Ranges from{" "}
        <CodeInline>0.0</CodeInline> to <CodeInline>1.0</CodeInline>. Adjust this to control how
        much the reference image influences the final stages of the generation.
      </span>
    ),
  },
  ipAdapterStart: {
    headerName: "ip_adapter_start",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>0.0</CodeInline>
      </span>,
    ],
    content: (
      <span>
        Specifies when to start applying the influence of the reference image. Ranges from{" "}
        <CodeInline>0.0</CodeInline> to <CodeInline>1.0</CodeInline>. Use this to delay the
        influence of the reference image until a certain point in the generation process.
      </span>
    ),
  },
  ipAdapterFinish: {
    headerName: "ip_adapter_finish",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>1.0</CodeInline>
      </span>,
    ],
    content: (
      <span>
        Specifies when to stop applying the influence of the reference image. Ranges from{" "}
        <CodeInline>0.0</CodeInline> to <CodeInline>1.0</CodeInline>. Use this to limit the duration
        of the reference image's influence.
      </span>
    ),
  },
};
const cannyDocFields: Record<string, DocFieldProps> = {
  ...baseDocFields,
  compositeImage: {
    headerName: "composite_image",
    headerTags: ["required"],
    content: (
      <span>
        A data URL for the composite image (RGB or RGBA; max 4 MB) containing all products and
        props. This image serves as the basis for generating edge signals that preserve the shapes
        of the products and props in the final image.
      </span>
    ),
  },
  compositeMaskImage: {
    headerName: "composite_mask_image",
    headerTags: ["required"],
    content: (
      <span>
        A data URL for the mask image. The <CodeInline>R</CodeInline> channel should be the mask for
        the product, the <CodeInline>B</CodeInline> channel should be the mask for both product and
        props, and the <CodeInline>G</CodeInline> channel should be the mask for the set of product
        and props where we want to preserve the color. Masks should be white where the respective
        elements are present and black elsewhere. This ensures that the canny edge signals are
        accurately derived from the specified areas.
      </span>
    ),
  },
  cannyControlnetConditioningScale: {
    headerName: "canny_controlnet_conditioning_scale",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>1.0</CodeInline>
      </span>,
    ],
    content: (
      <span>
        Controls the strength of the canny edge conditioning. Ranges from{" "}
        <CodeInline>0.0</CodeInline> to <CodeInline>1.0</CodeInline>. Higher values make the edge
        preservation more dominant, maintaining the shapes from the input edges. Adjust this to
        balance between adhering to edge outlines and creative freedom.
      </span>
    ),
  },
  cannyControlnetGuidanceStart: {
    headerName: "canny_controlnet_guidance_start",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>0.0</CodeInline>
      </span>,
    ],
    content: (
      <span>
        Specifies when to start applying the canny edge guidance during the generation process.
        Ranges from <CodeInline>0.0</CodeInline> to <CodeInline>1.0</CodeInline>. Use this to delay
        the influence of edge preservation until later stages of image generation.
      </span>
    ),
  },
  cannyControlnetGuidanceFinish: {
    headerName: "canny_controlnet_guidance_finish",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>1.0</CodeInline>
      </span>,
    ],
    content: (
      <span>
        Specifies when to stop applying the canny edge guidance during the generation process.
        Ranges from <CodeInline>0.0</CodeInline> to <CodeInline>1.0</CodeInline>. Use this to limit
        the duration of edge preservation effects.
      </span>
    ),
  },
  cannyControlnetConditioningScaleStart: {
    headerName: "canny_controlnet_conditioning_scale_start",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>1.0</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The starting scale for the canny edge conditioning. Ranges from <CodeInline>0.0</CodeInline>{" "}
        to <CodeInline>1.0</CodeInline>. Adjust this to control how strongly edge preservation
        begins at the start of the generation.
      </span>
    ),
  },
  cannyControlnetConditioningScaleFinish: {
    headerName: "canny_controlnet_conditioning_scale_finish",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>0.5</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The ending scale for the canny edge conditioning. Ranges from <CodeInline>0.0</CodeInline>{" "}
        to <CodeInline>1.0</CodeInline>. Adjust this to control the strength of edge preservation
        towards the end of the generation process.
      </span>
    ),
  },
  imageOverlayUseCanny: {
    headerName: "image_overlay_use_canny",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>true</CodeInline>
      </span>,
    ],
    content: (
      <span>
        Enables the use of canny edge overlay for color correction. Set to{" "}
        <CodeInline>false</CodeInline> to deactivate, which can affect the accuracy of text and
        product colors by not aligning them with edge outlines. Useful when precise color
        representation is required.
      </span>
    ),
  },
  imageOverlayUsePoisson: {
    headerName: "image_overlay_use_poisson",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>true</CodeInline>
      </span>,
    ],
    content: (
      <span>
        Enables the use of Poisson blending for color correction. Set to{" "}
        <CodeInline>false</CodeInline> to deactivate, which may affect seamless blending of colors
        and consistency in color transitions. Use this when you need smooth color integration
        between different image elements.
      </span>
    ),
  },
  applyExtraPoissonBlendingToColorCorrection: {
    headerName: "apply_extra_poisson_blending_to_color_correction",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>true</CodeInline>
      </span>,
    ],
    content: (
      <span>
        Applies additional Poisson blending to enhance color correction. Set to{" "}
        <CodeInline>true</CodeInline> for improved color accuracy and blending, especially in
        complex images with multiple color transitions. Note that this may increase processing time.
      </span>
    ),
  },
};

const refCannyDocFields = {
  ...baseDocFields,
  ...cannyDocFields,
  ...refDefaultDocFields,
  refImageCannyStrength: {
    headerName: "ref_image_canny_strength",
    headerTags: [
      "optional",
      <span>
        Defaults to <CodeInline>0.0</CodeInline>
      </span>,
    ],
    content: (
      <span>
        The strength of the reference image's canny edges in the final image. Ranges from{" "}
        <CodeInline>0.0</CodeInline> to <CodeInline>0.2</CodeInline>. Has a minor effect. If set to
        0, the only canny edge signal comes from the composite image product/prop as specified by
        composite_mask_image.
      </span>
    ),
  },
};

// const refCannyDocFields: Record<string, DocFieldProps> = {
//     ...

//     // composite_mask_image -> G channel also includes product and props, like B, but for only the things where you want to preserve the color. white for the color you preserve, black everywhere else. still a data url with the same restrictions as before

//     // ref_image_canny_strength: float, default 0.0, clamp (0.0, 0.2), The strength of the reference image's canny edges in the final image. Fairly minor effect. if set to 0, the only canny edge signal is coming from composite_image product/prop as specified by composite_mask_image as before.
// };

const responseDocFields: Record<string, DocFieldProps> = {
  jobIds: {
    headerName: "jobIds",
    headerTags: [<CodeInline>{"Array<string>"}</CodeInline>],
    content: <span>A list of job IDs associated with the request.</span>,
  },
};
const defaultDocConfig: DocConfig = {
  name: "Base pipeline",
  description: "default pipeline",
  fieldGroups: {
    Request: {
      name: "Request",
      description: "",
      fields: defaultDocFields,
    },
    Response: {
      name: "Response",
      description: "",
      fields: responseDocFields,
    },
  },
};
const refDefaultDocConfig: DocConfig = {
  name: "Reference style pipeline",
  description:
    "ref_default pipeline. Add a reference image to guide the style of the generated output.",
  fieldGroups: {
    Request: {
      name: "Request",
      description: "",
      fields: refDefaultDocFields,
    },
    Response: {
      name: "Response",
      description: "",
      fields: responseDocFields,
    },
  },
};

const cannyDocConfig: DocConfig = {
  name: "Outline control pipeline",
  description: "canny pipeline. Preserve the shapes of products and props in the final image.",
  fieldGroups: {
    Request: {
      name: "Request",
      description: "",
      fields: cannyDocFields,
    },
    Response: {
      name: "Response",
      description: "",
      fields: responseDocFields,
    },
  },
};

const refCannyDocConfig: DocConfig = {
  name: "Reference style + outline control pipeline",
  description:
    "ref_canny pipeline. Control the style, shapes, and colors of the generated output with a reference image and product/prop images.",
  fieldGroups: {
    Request: {
      name: "Request",
      description: "",
      fields: refCannyDocFields,
    },
    Response: {
      name: "Response",
      description: "",
      fields: responseDocFields,
    },
  },
};

export const generateImageApiDocConfig: Record<any, DocConfig> = {
  [ApiPipelineType.RefCanny]: refCannyDocConfig,
  [ApiPipelineType.Canny]: cannyDocConfig,
  [ApiPipelineType.RefDefault]: refDefaultDocConfig,
  [ApiPipelineType.Default]: defaultDocConfig,
};

function DocTabTrigger({
  className = "",
  children,
  ...props
}: Tabs.TabsTriggerProps & {
  value: GenerateImageApiDocType;
}) {
  return (
    <Tabs.Trigger
      {...props}
      className={classNames(
        "group pl-3 pr-3 xl:pr-6 py-2 truncate rounded text-sm text-zinc-500 focus:outline-none active:outline-none focus-visible:outline-none hover:bg-zinc-800 data-[state=active]:text-zinc-200 transition-colors",
        className,
      )}
    >
      {children}
    </Tabs.Trigger>
  );
}

function DocTabs() {
  const { docTab, setDocTab } = React.useContext(GenerateImagePlaygroundContext);

  return (
    <Tabs.Root
      value={docTab}
      // @ts-ignore
      onValueChange={setDocTab}
      className="flex flex-row justify-start gap-6 xl:gap-12 w-full xl:w-[1000px]"
    >
      <Tabs.List className="flex flex-col justify-start items-stretch gap-2">
        {getObjectEntries(generateImageApiDocConfig).map(([value, config]) => (
          <DocTabTrigger key={value} value={value as ApiPipelineType}>
            {config.name}
          </DocTabTrigger>
        ))}
      </Tabs.List>
      <div>
        {getObjectEntries(generateImageApiDocConfig).map(([value, config]) => (
          <Tabs.Content key={value} value={value}>
            <Doc config={config} />
          </Tabs.Content>
        ))}
      </div>
    </Tabs.Root>
  );
}

/* BEGIN job management docs */

/* add job specific docs */
const addJobDocFields: Record<string, DocFieldProps> = {
  apiKey: {
    headerName: "api-key",
    headerTags: ["required"],
    content: <span>Your API key in an HTTP header for authentication.</span>,
  },
  body: {
    headerName: "body",
    headerTags: ["required"],
    content: (
      <span>
        The request body containing the pipeline type and other parameters for the image generation
        job. See the docs on different pipeline types for more details.
      </span>
    ),
  },
};

const addJobResponseDocFields: Record<string, DocFieldProps> = {
  message: {
    headerName: "message",
    headerTags: [<CodeInline>{"string"}</CodeInline>],
    content: <span>A message indicating the status of the job addition.</span>,
  },
  success: {
    headerName: "success",
    headerTags: [<CodeInline>{"boolean"}</CodeInline>],
    content: <span>Indicates whether the job was added successfully.</span>,
  },
  status_code: {
    headerName: "status_code",
    headerTags: [<CodeInline>{"number"}</CodeInline>],
    content: <span>The HTTP status code of the response.</span>,
  },
  jobIds: {
    headerName: "jobIds",
    headerTags: [<CodeInline>{"Array<string>"}</CodeInline>],
    content: (
      <span>A list of job IDs associated with the newly created image generation job(s).</span>
    ),
  },
};
const addJobDocConfig: DocConfig = {
  name: "Add job",
  description: (
    <span>
      Create a new API render job for image generation. Send a POST request to{" "}
      <CodeInline>/{ENDPOINT_PREFIX}</CodeInline> with the pipeline type and other parameters in the
      request body.
    </span>
  ),
  fieldGroups: {
    Request: {
      name: "Request",
      description: "",
      fields: addJobDocFields,
    },
    Response: {
      name: "Response",
      description: "",
      fields: addJobResponseDocFields,
    },
  },
};

/* get job status specific docs */
const getJobStatusDocFields: Record<string, DocFieldProps> = {
  apiKey: {
    headerName: "api-key",
    headerTags: ["required"],
    content: <span>Your API key in an HTTP header for authentication.</span>,
  },
};
const getJobStatusResponseDocFields: Record<string, DocFieldProps> = {
  message: {
    headerName: "message",
    headerTags: [<CodeInline>{"string"}</CodeInline>],
    content: <span>A message indicating the status of the job check operation.</span>,
  },
  success: {
    headerName: "success",
    headerTags: [<CodeInline>{"boolean"}</CodeInline>],
    content: <span>Indicates whether the job status was retrieved successfully.</span>,
  },
  status_code: {
    headerName: "status_code",
    headerTags: [<CodeInline>{"number"}</CodeInline>],
    content: <span>The HTTP status code of the response.</span>,
  },
  image_urls: {
    headerName: "image_urls",
    headerTags: [<CodeInline>{"Array<string>"}</CodeInline>],
    content: (
      <span>An array of URLs for the generated images. Empty if the job is not completed.</span>
    ),
  },
  status: {
    headerName: "status",
    headerTags: [<CodeInline>{"string"}</CodeInline>],
    content: (
      <span>The current status of the job. Can be "Active", "Succeeded", or "Stopped".</span>
    ),
  },
};
const getJobStatusDocConfig: DocConfig = {
  name: "Get job status",
  description: (
    <span>
      Check the status of an existing API render job. Send a GET request to{" "}
      <CodeInline>
        /{ENDPOINT_PREFIX}/jobs/{"{"}job_id{"}"}
      </CodeInline>
      , where <CodeInline>job_id</CodeInline> was the ID returned when you created the job.
    </span>
  ),
  fieldGroups: {
    Request: {
      name: "Request",
      description: "",
      fields: getJobStatusDocFields,
    },
    Response: {
      name: "Response",
      description: "The response will vary based on the job status:",
      fields: getJobStatusResponseDocFields,
    },
  },
};

/* cancel job specific docs */
const cancelJobDocFields: Record<string, DocFieldProps> = {
  apiKey: {
    headerName: "api-key",
    headerTags: ["required"],
    content: <span>Your API key in an HTTP header for authentication.</span>,
  },
};

const cancelJobResponseDocFields: Record<string, DocFieldProps> = {
  message: {
    headerName: "message",
    headerTags: [<CodeInline>{"string"}</CodeInline>],
    content: <span>A message describing the result of the cancellation request.</span>,
  },
  success: {
    headerName: "success",
    headerTags: [<CodeInline>{"boolean"}</CodeInline>],
    content: <span>Indicates whether the job was successfully cancelled.</span>,
  },
  status_code: {
    headerName: "status_code",
    headerTags: [<CodeInline>{"number"}</CodeInline>],
    content: <span>The HTTP status code of the response.</span>,
  },
};

const cancelJobDocConfig: DocConfig = {
  name: "Cancel job",
  description: (
    <span>
      Cancel an existing API render job. Send a PUT request to{" "}
      <CodeInline>
        /{ENDPOINT_PREFIX}/jobs/{"{"}job_id{"}"}/cancel
      </CodeInline>
      , where <CodeInline>job_id</CodeInline> was the ID returned when you created the job.
    </span>
  ),
  fieldGroups: {
    Request: {
      name: "Request",
      description: "",
      fields: cancelJobDocFields,
    },
    Response: {
      name: "Response",
      description: "The response will contain information about the cancellation request:",
      fields: cancelJobResponseDocFields,
    },
  },
};

const jobManagementDocConfig: Record<JobManagementTab, DocConfig> = {
  [JobManagementTab.AddJob]: addJobDocConfig,
  [JobManagementTab.GetJobStatus]: getJobStatusDocConfig,
  [JobManagementTab.CancelJob]: cancelJobDocConfig,
};

function JobManagementTabTrigger({
  className = "",
  children,
  ...props
}: Tabs.TabsTriggerProps & {
  value: JobManagementTab;
}) {
  return (
    <Tabs.Trigger
      {...props}
      className={classNames(
        "group pl-3 pr-3 xl:pr-6 py-2 truncate rounded text-sm text-zinc-500 focus:outline-none active:outline-none focus-visible:outline-none hover:bg-zinc-800 data-[state=active]:text-zinc-200 transition-colors",
        className,
      )}
    >
      {children}
    </Tabs.Trigger>
  );
}

function JobManagementTabs() {
  const { jobManagementTab, setJobManagementTab } = React.useContext(
    GenerateImagePlaygroundContext,
  );

  return (
    <Tabs.Root
      value={jobManagementTab}
      // @ts-ignore
      onValueChange={setJobManagementTab}
      className="flex flex-row justify-start gap-6 xl:gap-12 w-full xl:w-[1000px]"
    >
      <Tabs.List className="flex flex-col justify-start items-stretch gap-2">
        {getObjectEntries(jobManagementDocConfig).map(([value, config]) => (
          <JobManagementTabTrigger key={value} value={value}>
            {config.name}
          </JobManagementTabTrigger>
        ))}
      </Tabs.List>
      <div>
        {getObjectEntries(jobManagementDocConfig).map(([value, config]) => (
          <Tabs.Content key={value} value={value}>
            <Doc config={config} />
          </Tabs.Content>
        ))}
      </div>
    </Tabs.Root>
  );
}

/* END job management docs */

export function DefaultJobManagementDocumentation() {
  return (
    <div className="w-full flex flex-row justify-start">
      <JobManagementTabs />
    </div>
  );
}

export function DefaultGenerateImageApiDocumentation() {
  return (
    <div className="w-full flex flex-row justify-start">
      <DocTabs />
    </div>
  );
}
