import {
  CustomModelPostProcessActionType,
  InContextVariationsInput,
} from "@/backend/custom-model-post-process";
import { editorContextStore } from "@/contexts/editor-context";
import {
  CustomModelPredictionItem,
  getImageSizeFromCustomModelPredictionInput,
  isCustomModelTrainingStatusActive,
} from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { rescaleNumber } from "@/core/utils/number-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { getPromptStateFromText } from "@/core/utils/text-utils";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { clamp } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
} from "../constants/class-names";
import { SimpleSpinner } from "../icons/simple-spinner";
import { NumberSlider } from "../panels/panel-items/components/number-slider";
import { ScrollAreaContainer } from "../scroll-area/scroll-area";
import { displayUiMessage } from "../utils/display-message";
import { ImageComponent } from "../utils/image";
import { Tooltip } from "../utils/tooltip";
import {
  imageEditorContainerClassName,
  mainPanelClassName,
  toolbarPanelClassName,
} from "./classnames";
import { CustomModelImageEditorBackButton } from "./custom-model-editor-back-button";
import {
  CustomModelImageEditorMode,
  loadImageFromCustomModelPredictionOutput,
  useCustomModelImageEditorContext,
} from "./custom-model-image-editor-context";
import { useCustomModelCompareOutputContext } from "./custom-model-image-output-context";
import {
  CustomModelImageVariationsStatus,
  useCustomModelImageVariationsContext,
} from "./custom-model-image-variations-context";
import { getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState } from "./custom-model-mention-plugin";
import { CustomModelPromptEditor } from "./custom-model-prompt-editor";

const minStrength = 0;

const maxStrength = 10;

function getInContextVariationsInput({
  strength,
}: {
  strength: number;
}): Partial<InContextVariationsInput> {
  try {
    strength = clamp(strength, 0, 10);
    const secondPassEndPercentage = rescaleNumber({
      value: strength,
      sourceMin: minStrength,
      sourceMax: maxStrength,
      targetMin: 0.9,
      targetMax: 0.5,
    });

    const secondPassConditioningScale = rescaleNumber({
      value: strength,
      sourceMin: minStrength,
      sourceMax: maxStrength,
      targetMin: 1.0,
      targetMax: 0.7,
    });

    const firstPassLoraScale = rescaleNumber({
      value: strength,
      sourceMin: minStrength,
      sourceMax: maxStrength,
      targetMin: 1.0,
      targetMax: 0.0,
    });

    return {
      // firstPassSkipLoras: strength > 5,
      firstPassLoraScale,
      firstPassSkipLoras: false,
      secondPassSkipLoras: false,
      cropMaxBorderLengthRatio: 0.2,
      secondPassInputImageSize: 384,
      secondPassConditioningScale,
      secondPassEndPercentage,
    };
  } catch (error) {
    debugError(`Error getting in-context variations from strength ${strength}: `, error);
    return {
      secondPassInputImageSize: 384,
    };
  }
}

function CustomModelImageVariationsStrengthSlider() {
  const { strength, setStrength } = useCustomModelImageVariationsContext();

  return (
    <NumberSlider
      name={
        <Tooltip
          triggerChildren={
            <div className="mr-0 lg:min-w-[8rem] group flex flex-row items-center gap-2">
              <span className="truncate">Creativity</span>
              <QuestionMarkCircledIcon className="text-zinc-500 group-hover:text-zinc-300 transition-colors" />
            </div>
          }
          contentChildren={
            <div className="flex flex-col gap-2">
              <div>Creativty controls how much the image will vary.</div>
              <div>
                The higher the creativty, the more the image will differ from the original image.
              </div>
            </div>
          }
        />
      }
      value={strength}
      setValue={setStrength}
      min={0}
      max={maxStrength}
      step={1}
    />
  );
}

function CustomModelImageVariationsPromptEditor() {
  const { promptEditorState, setPromptEditorState } = useCustomModelImageVariationsContext();

  return (
    <div className="group flex flex-col items-stretch gap-2">
      <div className="flex flex-row items-center gap-2">
        <div className="flex-1 min-w-0 text-zinc-500 group-hover:text-zinc-300 transition-colors truncate">
          Prompt
        </div>
      </div>
      <CustomModelPromptEditor
        editorState={promptEditorState}
        setEditorState={setPromptEditorState}
      />
    </div>
  );
}

function CustomModelImageVariationsButton() {
  const { status, setStatus, strength, promptEditorState } = useCustomModelImageVariationsContext();
  const { setModes, prediction, imageUrl: sourceImageUrl } = useCustomModelImageEditorContext();

  const { setActiveOutputImageIndex, setSourceImageUrl, setOutputImageUrls, setWidth, setHeight } =
    useCustomModelCompareOutputContext();

  const [buttonText, setButtonText] = useState("Generate Variations");

  useEffect(() => {
    try {
      if (!prediction) {
        setButtonText("Invalid image");
        setStatus((status) =>
          status === CustomModelImageVariationsStatus.Idle
            ? CustomModelImageVariationsStatus.Disabled
            : status,
        );
        return;
      }

      if (!promptEditorState?.text) {
        setButtonText("Prompt is empty");
        setStatus((status) =>
          status === CustomModelImageVariationsStatus.Idle
            ? CustomModelImageVariationsStatus.Disabled
            : status,
        );
        return;
      }

      setButtonText("Generate Variations");
      setStatus((status) =>
        status === CustomModelImageVariationsStatus.Disabled
          ? CustomModelImageVariationsStatus.Idle
          : status,
      );
    } catch (error) {
      debugError("Error retrieving button text from state", error);
    }
  }, [prediction, promptEditorState?.text, setStatus]);

  const sourceImagePrompt = useMemo(() => {
    try {
      if (!prediction) {
        return "";
      }

      const promptJson = prediction.input?.promptJson;
      if (!promptJson) {
        return "";
      }

      const promptEditorState = getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
        promptEditorState: JSON.parse(
          promptJson || getPromptStateFromText((prediction.input as any)?.prompt),
        ),
      });

      return promptEditorState.text;
    } catch (error) {
      debugError("Error loading the custom model image variations: ", error);
      return "";
    }
  }, [prediction]);

  const backend = editorContextStore((state) => state.backend);

  const handelGenerateVariations = useCallback(async () => {
    if (!backend || !sourceImageUrl) {
      displayUiMessage("The reference image URL is invalid.", "error");
      return;
    }

    if (status !== CustomModelImageVariationsStatus.Idle) {
      return;
    }

    setStatus(CustomModelImageVariationsStatus.Loading);

    try {
      const { text, json, scaleConfigs } = promptEditorState;

      if (!text) {
        return;
      }

      const inContextVariationsInput = getInContextVariationsInput({
        strength,
      });

      const customModelGenerateVariationsInput: InContextVariationsInput = {
        ...inContextVariationsInput,
        type: CustomModelPostProcessActionType.InContextVariations,
        prompt: text,
        promptJson: json ?? undefined,
        modelScaleConfigs: scaleConfigs,
        sourceImageUrl,
        sourceImagePrompt,
      };

      debugLog("Custom model geenrate variations input:\n", customModelGenerateVariationsInput);

      const response = await backend.customModelGenerateVariations(
        customModelGenerateVariationsInput,
      );

      if (!response.ok) {
        displayUiMessage(response.message, "error");
        return;
      }

      const predictionId = response.predictionId;

      if (!predictionId) {
        return;
      }

      const predictionItem = await new Promise<CustomModelPredictionItem>((resolve) => {
        backend.onCustomModelPredictionUpdate({
          predictionId,
          callback: (predictionItem) => {
            const status = predictionItem.status;

            if (!isCustomModelTrainingStatusActive(status)) {
              resolve(predictionItem);
            }
          },
        });
      });

      const outputImagePaths = predictionItem.output ?? [];

      const imageUrls = await Promise.all(
        outputImagePaths.map((path) => {
          return Assets.loadAssetFromPath({
            path,
            backend,
          });
        }),
      );

      if (imageUrls.length > 0) {
        const { width, height } = prediction?.input
          ? getImageSizeFromCustomModelPredictionInput(prediction.input)
          : {
              width: 1024,
              height: 1024,
            };

        setSourceImageUrl(sourceImageUrl);

        setOutputImageUrls(imageUrls.filter(Boolean) as string[]);

        setActiveOutputImageIndex(0);

        setWidth(width);

        setHeight(height);

        setModes((modes) => [...modes, CustomModelImageEditorMode.CompareOutputs]);
      }

      return response;
    } catch (error) {
      debugError("Error handling custom model generate variations: ", error);
    } finally {
      setStatus(CustomModelImageVariationsStatus.Idle);
    }
  }, [
    backend,
    status,
    setStatus,
    strength,
    prediction,
    promptEditorState,
    sourceImageUrl,
    sourceImagePrompt,
    setModes,
    setWidth,
    setHeight,
    setSourceImageUrl,
    setOutputImageUrls,
    setActiveOutputImageIndex,
  ]);

  return (
    <button
      className={classNames(
        status === CustomModelImageVariationsStatus.Idle
          ? PrimaryButtonClassName
          : status === CustomModelImageVariationsStatus.Loading
            ? PrimaryButtonClassNameLoading
            : PrimaryButtonClassNameDisabled,
      )}
      onClick={() => {
        handelGenerateVariations();
      }}
    >
      {status === CustomModelImageVariationsStatus.Loading ? (
        <div className="flex flex-row items-center justify-center gap-2">
          <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
          <span>Loading ...</span>
        </div>
      ) : (
        buttonText
      )}
    </button>
  );
}

export function CustomModelImageVariations() {
  const backend = editorContextStore((state) => state.backend);
  const { prediction, imageUrl, setImageUrl } = useCustomModelImageEditorContext();

  return (
    <div className={imageEditorContainerClassName}>
      <div
        className={classNames(
          mainPanelClassName,
          "w-full h-fit max-h-[50%] md:w-auto md:h-full md:max-h-none flex-1",
        )}
      >
        <div className="rounded-md w-full h-full overflow-hidden bg-zinc-800/50">
          <ImageComponent
            src={imageUrl}
            className="object-contain w-full h-full"
            onError={(e) => {
              debugError(`Error loading image from url ${imageUrl}: `, e);

              loadImageFromCustomModelPredictionOutput({
                backend,
                prediction,
              }).then((url) => {
                setImageUrl(url ?? "");
              });
            }}
          />
        </div>
      </div>
      <ScrollAreaContainer className={classNames(toolbarPanelClassName)}>
        <div className="h-fit flex flex-col items-stretch gap-4">
          <CustomModelImageEditorBackButton />
          <div className="flex flex-col items-stretch gap-4">
            <CustomModelImageVariationsStrengthSlider />
            <CustomModelImageVariationsPromptEditor />
            <CustomModelImageVariationsButton />
          </div>
        </div>
      </ScrollAreaContainer>
    </div>
  );
}
