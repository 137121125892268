import React from "react";
import { fabric } from "fabric";
import { Navigate } from "./components/navigate";
import { getHtmlImageElementFromUrlAsync, getImageUrlFromImageId } from "@/core/utils/image-utils";
import { editorContextStore } from "contexts/editor-context";
import {
  TryOnModelPreviewFilterQueryConstraints,
  UiDisplayMessageDialogEventHandler,
  UiDisplayMessageEventHandler,
} from "@/core/common/types";
import { LeftPanelSectionContainer } from "./base";
import { AssetLibraryItem } from "./components/assets-library";
import { PresetImageGridContainer, PresetImageGridItem } from "./components/preset-image-grid";
import {
  PrimaryButtonClassName,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { ArrowRight, UploadCloud } from "lucide-react";
import { classNames } from "@/core/utils/classname-utils";
import { TryOnExitClothEditorButton } from "./components/tryon-panel-button";
import { handleClothImageUpdate } from "contexts/tryon-editor-context";
import { getClothImageElementFromFiles } from "components/utils/tryon-upload-image";
import { StaticImageElementType } from "@/core/common/types/elements";

type TryOnClothAssetLibraryItem = AssetLibraryItem & {
  parsedMaskUrl?: string;
  bbox?: [number, number, number, number];
  tags?: TryOnModelPreviewFilterQueryConstraints;
  caption?: string;
};

type TryOnClothImageData = {
  imageId: string;
  parsedMaskId: string;
  bbox?: [number, number, number, number];
  tags?: TryOnModelPreviewFilterQueryConstraints;
  caption?: string;
};

function getClothImageAssetLibraryItemFromImageId(imageId: string): TryOnClothAssetLibraryItem {
  return {
    url: getImageUrlFromImageId({
      imageId,
      imageSize: "public",
    }),
    previewUrl: getImageUrlFromImageId({
      imageId,
      imageSize: "256",
    }),
    metadata: {
      imageType: StaticImageElementType.Subject,
      imageId,
    },
  };
}

function getClothImageAssetLibraryItemFromImageData(
  imageData: TryOnClothImageData,
): TryOnClothAssetLibraryItem {
  const { imageId, parsedMaskId, bbox, tags, caption = "" } = imageData;

  return {
    url: getImageUrlFromImageId({
      imageId,
      imageSize: "public",
    }),
    parsedMaskUrl: parsedMaskId
      ? getImageUrlFromImageId({
          imageId: parsedMaskId,
          imageSize: "public",
        })
      : undefined,
    previewUrl: getImageUrlFromImageId({
      imageId,
      imageSize: "256",
    }),
    bbox,
    tags,
    caption,
    metadata: {
      imageType: StaticImageElementType.Subject,
      imageId,
    },
  };
}

const tryOnClothImageData: TryOnClothImageData[] = [
  {
    imageId: "sample-cloth-0",
    parsedMaskId: "eb2dfe3f-42c8-45ef-76d5-93682d116800",
    bbox: [0, 0, 1, 1],
    tags: ["long-sleeve_shirt"],
    caption: "a black long-sleeve shirt",
  },
  {
    imageId: "sample-cloth-1",
    parsedMaskId: "950cd65c-5117-4a2c-4640-a2591374d700",
    bbox: [0, 0, 1, 1],
    tags: ["long-sleeve_shirt", "untucked_top"],
    caption: "a red long-sleeve sports t-shirt",
  },
  {
    imageId: "sample-cloth-2",
    parsedMaskId: "8f3859d2-1744-4a58-1656-a4f6cc124c00",
    bbox: [0, 0, 1, 1],
    tags: ["short-sleeve_shirt", "tucked-in_high-waist_pants"],
    caption: "a red t-shirt",
  },
  {
    imageId: "sample-cloth-3",
    parsedMaskId: "70a5b1fa-4517-4eb0-abcd-394c1d119f00",
    bbox: [0, 0, 1, 1],
    tags: ["long-sleeve_shirt"],
    caption: "a navy long-sleeve shirt with pattern",
  },
  {
    imageId: "sample-cloth-5",
    parsedMaskId: "134fbd01-50cc-4df2-ff64-c3e3a0d2af00",
    bbox: [0, 0, 1, 1],
    tags: ["short-sleeve_shirt"],
    caption: "a blue sports t-shirt",
  },
];

const tryOnClothAssets = tryOnClothImageData.map((imageId) =>
  getClothImageAssetLibraryItemFromImageData(imageId),
);

function UploadClothButton() {
  const handleUploadFiles = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { editor, setActiveLeftPanels, setTryOnClothImageElement } =
      editorContextStore.getState();

    if (!editor) {
      return;
    }

    const files = e.target?.files;

    if (!files || files.length <= 0) {
      editor.emit<UiDisplayMessageEventHandler>(
        "ui:display-message",
        "error",
        "No valid file is uploaded.",
      );
      return;
    }

    getClothImageElementFromFiles({ files })
      .then(async (clothImageElement) => {
        if (!clothImageElement) {
          editor.emit<UiDisplayMessageEventHandler>(
            "ui:display-message",
            "error",
            "Cannot process the uploaded image.",
          );
          return;
        }

        setTryOnClothImageElement(clothImageElement);

        setActiveLeftPanels((panels) => [...panels, "TryOnSelectPose"]);
      })
      .catch((error) => {
        console.error(error);

        editor.emit<UiDisplayMessageEventHandler>(
          "ui:display-message",
          "error",
          "Cannot process the uploaded image.",
        );
      });
  }, []);

  const tryonUploadId = "tryon-cloth-upload";

  const tryOnClothImageElement = editorContextStore((state) => state.tryOnClothImageElement);

  return (
    <>
      <input
        type="file"
        id={tryonUploadId}
        style={{
          display: "none",
        }}
        onChange={(e) => {
          handleUploadFiles(e);
        }}
      />
      <label htmlFor={tryonUploadId}>
        <div
          id="left-panel-assets-upload-image-button"
          className={classNames(
            "flex items-center justify-center cursor-pointer",
            tryOnClothImageElement ? SecondaryButtonClassNameInactive : PrimaryButtonClassName,
            "transition-colors",
          )}
        >
          <UploadCloud className="select-none" size={18} />
          <div className="ml-2 select-none">Upload Tops, T-Shirts, or Sweaters</div>
        </div>
      </label>
    </>
  );
}

function useTestTryonUploadEffect() {
  React.useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      return;
    }

    const { editor } = editorContextStore.getState();

    if (!editor) {
      return;
    }

    console.log("Display upload cloth");

    const imageElement = document.createElement("img");
    imageElement.src =
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/sample-cloth-2/256";

    imageElement.onload = () => {
      editor.emit<UiDisplayMessageDialogEventHandler>("ui:display-message-dialog", "upload-cloth", {
        image: imageElement,
      });
    };
  }, []);
}

export function TryOnUploadCloth() {
  const tryOnClothImageElement = editorContextStore((state) => state.tryOnClothImageElement);

  // useTestTryonUploadEffect();

  return (
    <div className="flex flex-col">
      <div className="h-2" />
      <Navigate>
        <span className="text-zinc-500 group-hover:text-lime-800 mr-2 transition-colors">
          Step 1:
        </span>
        <span>Upload T-Shirt or Sweater</span>
      </Navigate>
      <div className="h-4" />
      <LeftPanelSectionContainer label="Clothes">
        <UploadClothButton />
        <div className="h-4" />
        <PresetImageGridContainer>
          {tryOnClothAssets.map((assetItem) => (
            <PresetImageGridItem
              key={assetItem.url}
              url={assetItem.previewUrl || assetItem.url}
              onAddItem={async () => {
                const {
                  setActiveLeftPanels,
                  setTryOnClothImageElement,
                  setTryOnParsedClothImageBbox,
                  setTryOnParsedClothImageElement,
                  setTryOnParsedClothUpdated,
                  setTryOnModelPreviewFilterContraints,
                  setTryOnClothPrompt,
                } = editorContextStore.getState();

                const { url, parsedMaskUrl, bbox, tags = [], caption = "" } = assetItem;

                if (!url) {
                  return;
                }

                handleClothImageUpdate();

                setTryOnModelPreviewFilterContraints(tags);

                setTryOnClothPrompt(caption);

                if (parsedMaskUrl) {
                  await getHtmlImageElementFromUrlAsync(parsedMaskUrl).then((element) => {
                    setTryOnParsedClothUpdated(true);
                    setTryOnParsedClothImageElement(element);
                  });
                }

                if (bbox) {
                  setTryOnParsedClothImageBbox(bbox);
                }

                await getHtmlImageElementFromUrlAsync(url).then(setTryOnClothImageElement);

                setActiveLeftPanels((panels) => [...panels, "TryOnSelectPose"]);
              }}
            />
          ))}
        </PresetImageGridContainer>
      </LeftPanelSectionContainer>
      <div className="h-4" />
      <div className="w-full grid grid-cols-1 gap-2">
        <button
          className={classNames(
            tryOnClothImageElement ? PrimaryButtonClassName : SecondaryButtonClassNameDisabled,
            "flex flex-row items-center justify-center",
          )}
          onClick={() => {
            if (!tryOnClothImageElement) {
              return;
            }
            editorContextStore.getState().setActiveLeftPanels((v) => [...v, "TryOnSelectPose"]);
          }}
        >
          <ArrowRight width={18} height={18} className="mr-2" />
          <span className="text-left">Continue to Select Pose</span>
        </button>
        <TryOnExitClothEditorButton />
      </div>
    </div>
  );
}
