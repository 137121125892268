import React from "react";
import { Backend } from "@/backend/base";
import { getDataUrlFromImageElementResized } from "@/core/utils/image-utils";
import { GeneralObjectCategory, ClothSleeveCategory, ClothFitCategory } from "@/core/common/types";
import { fabric } from "fabric";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { editorContextStore } from "@/contexts/editor-context";

export async function getImageUrlForClassification(
  image: HTMLCanvasElement | HTMLImageElement,
  targetLength = 224,
) {
  return await getDataUrlFromImageElementResized({
    image,
    targetLength,
  });
}

export async function getImageCaption({
  image,
  backend,
}: {
  image: HTMLCanvasElement | HTMLImageElement | string;
  backend: Backend;
}) {
  const imageUrl = typeof image === "string" ? image : await getImageUrlForClassification(image);

  if (!imageUrl) {
    return;
  }

  return await backend.getImageCaption({
    imageUrl,
  });
}

export async function classifyImageByMultiTexts({
  image,
  backend,
  texts,
}: {
  image: HTMLCanvasElement | HTMLImageElement | string;
  backend: Backend;
  texts: string[][];
}) {
  const imageUrl = typeof image === "string" ? image : await getImageUrlForClassification(image);

  if (!imageUrl) {
    return;
  }

  return await backend.getImageMultiText({
    imageUrl,
    texts,
  });
}

export async function classifyImageByTexts({
  image,
  backend,
  texts,
}: {
  image: HTMLCanvasElement | HTMLImageElement;
  backend: Backend;
  texts: string[];
}) {
  const imageUrl = await getImageUrlForClassification(image);

  if (!imageUrl) {
    return;
  }

  return await backend.getImageText({
    imageUrl,
    texts,
  });
}

export async function classifyImageByCategories<T>({
  image,
  backend,
  categories: categoriesTyped,
}: {
  image: HTMLCanvasElement | HTMLImageElement;
  backend: Backend;
  categories: T[];
}) {
  const categories = categoriesTyped.map((c) => String(c));

  const category = await classifyImageByTexts({
    image,
    backend,
    texts: categories,
  });

  if (!category) {
    return;
  }

  if (categories.includes(category)) {
    return category as T;
  }

  return;
}

export async function getImageGeneralCategory({
  image,
  backend,
}: {
  image: HTMLCanvasElement | HTMLImageElement;
  backend: Backend;
}) {
  const categories = Object.values(GeneralObjectCategory);

  return await classifyImageByCategories({
    image,
    backend,
    categories,
  });
}

export function useImageGeneralCategory({
  image,
}: {
  image: HTMLCanvasElement | HTMLImageElement | fabric.StaticImage;
}) {
  const [category, setCategory] = React.useState<GeneralObjectCategory | undefined>();

  const isGeneratingRef = React.useRef(false);

  React.useEffect(() => {
    if (isGeneratingRef.current) {
      return;
    }

    const backend = editorContextStore.getState().backend;

    if (!backend) {
      return;
    }

    const imageElement = (
      isStaticImageObject(image) ? image.getElement() : image
    ) as HTMLImageElement;

    if (!imageElement) {
      return;
    }

    getImageGeneralCategory({
      image: imageElement,
      backend,
    }).then(setCategory);
  }, [image]);

  return category;
}

export async function getImageClothSleeveCategory({
  image,
  backend,
}: {
  image: HTMLCanvasElement | HTMLImageElement;
  backend: Backend;
}) {
  const categories = Object.values(ClothSleeveCategory);

  return await classifyImageByCategories({
    image,
    backend,
    categories,
  });
}

export type ImageClothMultiCategoryResult = {
  generalCategory?: GeneralObjectCategory;
  sleeveCategory?: ClothSleeveCategory;
  fitCategory?: ClothFitCategory;
};

export async function getImageClothMultiCategory({
  image,
  backend,
}: {
  image: HTMLCanvasElement | HTMLImageElement | string;
  backend: Backend;
}): Promise<ImageClothMultiCategoryResult> {
  const generalCategories = Object.values(GeneralObjectCategory);

  const sleeveCategories = Object.values(ClothSleeveCategory);

  const fitCategories = Object.values(ClothFitCategory);

  const categories = await classifyImageByMultiTexts({
    image,
    backend,
    texts: [generalCategories, sleeveCategories, fitCategories],
  });

  if (!categories) {
    return {};
  }

  const [generalCategory, sleeveCategory, fitCategory] = categories;

  return {
    generalCategory: generalCategory as GeneralObjectCategory,
    sleeveCategory: sleeveCategory as ClothSleeveCategory,
    fitCategory: fitCategory as ClothFitCategory,
  };
}

export async function getImageCaptionAndCategories() {}
