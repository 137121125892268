import React from "react";
import { Edit } from "./edit";
import { Default } from "./default";
import { editorContextStore } from "contexts/editor-context";
import { LeftPanelItemType } from "components/constants/editor-options";
import { EditorActiveObject } from "@/core/common/interfaces";
import { isActiveSelection, isImageObject } from "@/core/utils/type-guards";

enum PanelListType {
  Default = "Default",
  Edit = "Edit",
}

function getActivePanelListType(
  activeObject: EditorActiveObject,
  activeLeftPanels: LeftPanelItemType[],
) {
  if (isImageObject(activeObject) || isActiveSelection(activeObject)) {
    return PanelListType.Edit;
  }

  if (!Array.isArray(activeLeftPanels) || activeLeftPanels.length <= 0) {
    return PanelListType.Default;
  }

  const activeLeftPanel = activeLeftPanels[activeLeftPanels.length - 1];

  if (activeLeftPanel === "Edit") {
    return PanelListType.Edit;
  }

  return PanelListType.Default;
}

export function PanelList() {
  const activeObject = editorContextStore((state) => state.activeObject);
  const activeLeftPanels = editorContextStore((state) => state.activeLeftPanels);
  const activePanelType = React.useMemo(
    () => getActivePanelListType(activeObject, activeLeftPanels),
    [activeObject, activeLeftPanels],
  );

  return (
    <>
      <Default className={activePanelType === PanelListType.Default ? "flex" : "hidden"} />
      <Edit className={activePanelType === PanelListType.Edit ? "flex" : "hidden"} />
    </>
  );
}
