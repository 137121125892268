import { Backend } from "@/backend/base";
import { PROJECTS } from "@/components/constants/routes";
import { editorContextStore } from "@/contexts/editor-context";
import { useProjectInitSceneContext } from "@/contexts/project-init-scene-context";
import { EditorStatus } from "@/core/common/constants";
import { UserProjectType } from "@/core/common/types";
import { debugLog } from "@/core/utils/print-utilts";
import { isTemporaryProjectId } from "@/core/utils/project-utils";
import React from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Editor } from "./canvas-editor";
import EditorLoading from "./editor-loading";

async function doesUserHaveAccessToProject(
  backend?: Backend,
  currentUserId?: string,
  localProjectId?: string,
) {
  if (backend && currentUserId && localProjectId) {
    const projectUsers = await backend.getProjectUsers(localProjectId);
    return projectUsers?.[currentUserId] != null;
  }
  return false;
}

function handleCreateNewTemporaryProject() {
  const { projectType, setActiveLeftPanels } = editorContextStore.getState();

  if (projectType === UserProjectType.TryOn) {
    setActiveLeftPanels(["TryOnUploadCloth"]);
  } else if (
    projectType === UserProjectType.Accessories ||
    projectType === UserProjectType.Beauty ||
    projectType === UserProjectType.CPG ||
    projectType === UserProjectType.Fashion ||
    projectType === UserProjectType.Food ||
    projectType === UserProjectType.Furniture ||
    projectType === UserProjectType.Homegoods ||
    projectType === UserProjectType.Humans ||
    projectType === UserProjectType.ProductPhotography ||
    projectType === UserProjectType.Staging
  ) {
    setActiveLeftPanels(["Generate"]);
  }
}

function resetActiveLeftPanel(projectType?: UserProjectType) {
  const { setActiveLeftPanels } = editorContextStore.getState();

  if (projectType === UserProjectType.TryOn) {
    setActiveLeftPanels(["TryOnUploadCloth"]);
  } else {
    setActiveLeftPanels(["Generate"]);
  }
}

async function getProjectTypeFromBackend({
  backend,
  projectId,
}: {
  backend?: Backend | null;
  projectId?: string;
}) {
  if (!backend || !projectId || isTemporaryProjectId(projectId)) {
    return;
  }

  return backend.getProjectType(projectId);
}

export function EditorRouter() {
  const params = useParams();
  const { state } = useLocation();
  const [editorStatus, setEditorStatus] = React.useState<EditorStatus>(EditorStatus.Loading);
  const [localProjectId, setLocalProjectId] = React.useState(params?.projectId);
  const [isTempProject, setIsTempProject] = React.useState(
    ((state as any)?.isTempProject || false) && isTemporaryProjectId(localProjectId),
  );
  const user = editorContextStore((state) => state.user);
  const backend = editorContextStore((state) => state.backend);
  const { initScene, setInitScene } = useProjectInitSceneContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    setIsTempProject(
      ((state as any)?.isTempProject || false) && isTemporaryProjectId(localProjectId),
    );
  }, [state, localProjectId]);

  // Make sure that the user has the access to the project
  React.useEffect(() => {
    if (!localProjectId) {
      setEditorStatus(EditorStatus.Loading);
    } else if (isTempProject) {
      setEditorStatus(EditorStatus.Authorized);
      debugLog(`Log into temporary project ${localProjectId}`);
      handleCreateNewTemporaryProject();
    } else if (!user) {
      setEditorStatus(EditorStatus.Loading);
      const timeoutId = setTimeout(() => {
        debugLog("User is not logged in");
        setEditorStatus(EditorStatus.PermissionDenied);
      }, 1500);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      doesUserHaveAccessToProject(backend, user?.uid, localProjectId)?.then((isAuthorized) => {
        if (isAuthorized) {
          setEditorStatus(EditorStatus.Authorized);
          resetActiveLeftPanel(state?.projectType);
        } else {
          debugLog(`User ${user.uid} is not authorized to the project ${localProjectId}`);
          setEditorStatus(EditorStatus.PermissionDenied);
        }
      });
    }
  }, [user, backend, localProjectId, isTempProject, state?.projectType]);

  // When the firebase project id updates, we update the project id as well
  const projectId = editorContextStore((state) => state.projectId);
  React.useEffect(() => {
    if (projectId && isTempProject) {
      debugLog(`Change to new project id ${projectId} from firebase project`);
      setLocalProjectId(projectId);
      setIsTempProject(false);
      // Modify the route
      navigate(`/${PROJECTS}/${projectId}`);
    }
  }, [isTempProject, projectId, navigate]);

  React.useEffect(() => {
    if (state?.projectType) {
      editorContextStore.getState().setProjectType(state.projectType);
    } else if (backend && localProjectId) {
      getProjectTypeFromBackend({
        backend,
        projectId: localProjectId,
      })?.then((projectType) => {
        editorContextStore.getState().setProjectType(projectType);
      });
    } else {
      editorContextStore.getState().setProjectType(UserProjectType.ProductPhotography);
    }

    return () => {
      editorContextStore.getState().setProjectType(undefined);
    };
  }, [backend, localProjectId, state?.projectType]);

  if (editorStatus === EditorStatus.PermissionDenied) {
    return <Navigate to="/login" />;
  }

  if (editorStatus === EditorStatus.Loading) {
    return <EditorLoading />;
  }
  if (!localProjectId) {
    return null;
  }

  return <Editor initScene={initScene} />;
}
