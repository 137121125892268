import { editorContextStore } from "contexts/editor-context";
import type { LeftPanelItemType } from "components/constants/editor-options";
import { StateUpdater } from "@/core/common/types";

export function setActiveLeftPanel(
  activeLeftPanel: LeftPanelItemType,
  setActiveLeftPanels: (value: StateUpdater<LeftPanelItemType[]>) => void,
) {
  return setActiveLeftPanels((panels) => {
    panels.splice(panels.length - 1, 1, activeLeftPanel);
    return panels;
  });
}

export function useLeftPanelState() {
  return {
    isLeftPanelOpen: editorContextStore((state) => state.isLeftPanelOpen),
    setIsLeftPanelOpen: editorContextStore((state) => state.setIsLeftPanelOpen),
    activeLeftPanel: editorContextStore(
      (state) => state.activeLeftPanels?.[state.activeLeftPanels?.length - 1],
    ),
    activeLeftPanels: editorContextStore((state) => state.activeLeftPanels),
    setActiveLeftPanels: editorContextStore((state) => state.setActiveLeftPanels),
    generateToolPromptEditorType: editorContextStore((state) => state.generateToolPromptEditorType),
    setGenerateToolPromptEditorType: editorContextStore(
      (state) => state.setGenerateToolPromptEditorType,
    ),
  };
}
