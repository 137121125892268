import { Timestamp } from "firebase/firestore";
import { EditorImageAsset, PublicUserId, PublicUserRoles, StateUpdater } from "../types";
import { getUpdaterFunction, SetEditorStateFunction } from "@/contexts/editor-context-utils";
import { noop } from "lodash";
import { Backend } from "@/backend/base";
import { generateUUID } from "@/core/utils/uuid-utils";
import { isEditorImageAsset } from "@/core/utils/type-guards";

export const defaultVideoImageUrl =
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/637777e1-018e-40d4-09e2-a78ae8afa800/512";

export interface LumaGenerationReference {
  type: "generation";
  id: string;
  storagePath?: string;
}

// Reference to an image URL
export interface LumaImageReference {
  type: "image";
  url: string;
  storagePath?: string;
}

// Keyframe types
export type LumaGenerationKeyframe = LumaGenerationReference | LumaImageReference;

export enum VideoGenerationBackendType {
  Luma = "Luma",
}

export type LumaVideoGenerationRequestKeyframes = {
  frame0?: LumaGenerationKeyframe;
  frame1?: LumaGenerationKeyframe;
};

export type VideoGenerationAspectRatio = "1:1" | "16:9" | "9:16" | "4:3" | "3:4" | "21:9" | "9:21";

export const videoGenerationAspectRatioToNumber: Record<VideoGenerationAspectRatio, number> = {
  "1:1": 1,
  "16:9": 16 / 9,
  "9:16": 9 / 16,
  "4:3": 4 / 3,
  "3:4": 3 / 4,
  "21:9": 21 / 9,
  "9:21": 9 / 21,
};

export function getClosestVideoGenerationAspectRatio({
  width,
  height,
}: {
  width: number;
  height: number;
}): VideoGenerationAspectRatio {
  const inputRatio = width / height;
  const aspectRatios = Object.keys(
    videoGenerationAspectRatioToNumber,
  ) as VideoGenerationAspectRatio[];

  let closestAspectRatio = aspectRatios[0];
  let smallestDifference = Math.abs(
    inputRatio - videoGenerationAspectRatioToNumber[closestAspectRatio],
  );

  for (const aspectRatio of aspectRatios) {
    const ratio = videoGenerationAspectRatioToNumber[aspectRatio];
    const difference = Math.abs(inputRatio - ratio);

    if (difference < smallestDifference) {
      smallestDifference = difference;
      closestAspectRatio = aspectRatio;
    }
  }

  return closestAspectRatio;
}

export interface LumaVideoGenerationRequest {
  type: VideoGenerationBackendType.Luma;
  prompt?: string;
  aspect_ratio?: VideoGenerationAspectRatio;
  loop?: boolean;
  keyframes?: LumaVideoGenerationRequestKeyframes;
}

export function isLumaVideoGenerationRequest(obj: any): obj is LumaVideoGenerationRequest {
  return (
    obj &&
    typeof obj === "object" &&
    obj.type === VideoGenerationBackendType.Luma &&
    (typeof obj.prompt === "undefined" || typeof obj.prompt === "string") &&
    (typeof obj.aspect_ratio === "undefined" ||
      ["1:1", "16:9", "9:16", "4:3", "3:4", "21:9", "9:21"].includes(obj.aspect_ratio)) &&
    (typeof obj.loop === "undefined" || typeof obj.loop === "boolean")
  );
}

export type VideoGenerationRequest = LumaVideoGenerationRequest;

export function isVideoGenerationRequest(request: any): request is VideoGenerationRequest {
  return isLumaVideoGenerationRequest(request);
}

export type VideoGenerationResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      generationId: string;
    };

export enum VideoGenerationStatus {
  Starting = "starting",
  Processing = "processing",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}

export function isVideoGenerationStatus(value: any): value is VideoGenerationStatus {
  return Object.values(VideoGenerationStatus).includes(value);
}

export function getVideoGenerationStatusFromLumaGenerationState(
  state: "queued" | "dreaming" | "completed" | "failed",
) {
  if (state === "queued") {
    return VideoGenerationStatus.Starting;
  } else if (state === "dreaming") {
    return VideoGenerationStatus.Processing;
  } else if (state === "completed") {
    return VideoGenerationStatus.Succeeded;
  } else if (state === "failed") {
    return VideoGenerationStatus.Failed;
  }
  return VideoGenerationStatus.Failed;
}

export function isVideoGenerationStatusActive(status: VideoGenerationStatus) {
  return status === VideoGenerationStatus.Starting || status === VideoGenerationStatus.Processing;
}

export interface VideoGenerationDoc {
  id: string;
  callerPublicUserId: PublicUserId;
  roles: PublicUserRoles;
  status: VideoGenerationStatus;
  request: VideoGenerationRequest;
  outputVideoStoragePath?: string;
  thumbnailStoragePath?: string;
  isCheckingJob: boolean;
  timeCreated: Timestamp;
  timeModified?: Timestamp;
  errorMessage?: string;
}

export function isVideoGenerationDoc(doc: any): doc is VideoGenerationDoc {
  return (
    doc != null &&
    typeof doc.id === "string" &&
    typeof doc.callerPublicUserId === "string" && // Assuming PublicUserId is a string
    doc.roles != null &&
    typeof doc.status === "string" && // Assuming VideoGenerationStatus is a string, adjust as necessary
    doc.request != null &&
    (doc.outputVideoStoragePath === undefined || typeof doc.outputVideoStoragePath === "string") &&
    (doc.thumbnailStoragePath === undefined || typeof doc.thumbnailStoragePath === "string") &&
    doc.timeCreated != null
  );
}

export enum GenerateVideoKeyFrameType {
  ImageObject = "ImageObject",
  UploadedImage = "UploadedImage",
  UploadButton = "UploadButton",
}

export type GenerateVideoKeyFrameImageObject = {
  type: GenerateVideoKeyFrameType.ImageObject;
  id: string;
  objectId: string;
  src: string;
  width: number;
  height: number;
  generationId?: string;
  asset: EditorImageAsset;
};

export type GenerateVideoKeyFrameUploadedImage = {
  type: GenerateVideoKeyFrameType.UploadedImage;
  id: string;
  src: string;
  width: number;
  height: number;
  asset: EditorImageAsset;
};

export type GenerateVideoKeyFrameUploadButton = {
  type: GenerateVideoKeyFrameType.UploadButton;
  id: string;
};

export type GenerateVideoKeyFrameWithImage =
  | GenerateVideoKeyFrameImageObject
  | GenerateVideoKeyFrameUploadedImage;

export function isGenerateVideoKeyFrameTypeWithImage(
  type: GenerateVideoKeyFrameType,
): type is GenerateVideoKeyFrameType.ImageObject | GenerateVideoKeyFrameType.UploadedImage {
  return (
    type === GenerateVideoKeyFrameType.ImageObject ||
    type === GenerateVideoKeyFrameType.UploadedImage
  );
}

export type GenerateVideoKeyFrame =
  | GenerateVideoKeyFrameImageObject
  | GenerateVideoKeyFrameUploadedImage
  | GenerateVideoKeyFrameUploadButton;

export function isGenerateVideoKeyFrameWithImage(
  keyframe: GenerateVideoKeyFrame,
): keyframe is GenerateVideoKeyFrameWithImage {
  return isGenerateVideoKeyFrameTypeWithImage(keyframe?.type);
}

export function getKeyFrameFromGenerateVideoKeyFrame({
  backend,
  keyframe,
}: {
  backend?: Backend;
  keyframe?: GenerateVideoKeyFrame;
}): LumaImageReference | undefined {
  if (!keyframe || keyframe.type === GenerateVideoKeyFrameType.UploadButton) {
    return undefined;
  }

  const storagePath = backend?.cleanupStoragePathURL(keyframe.asset.path) || keyframe.asset.path;

  return {
    type: "image",
    url: storagePath,
    storagePath,
  };
}

export type GenerateVideoKeyFrames = GenerateVideoKeyFrame[];

/**
 * Type guard for GenerateVideoKeyFrameImageObject
 */
export function isGenerateVideoKeyFrameImageObject(
  obj: any,
): obj is GenerateVideoKeyFrameImageObject {
  return (
    obj &&
    typeof obj === "object" &&
    obj.type === GenerateVideoKeyFrameType.ImageObject &&
    typeof obj.id === "string" &&
    typeof obj.objectId === "string" &&
    typeof obj.src === "string" &&
    typeof obj.width === "number" &&
    typeof obj.height === "number" &&
    (typeof obj.generationId === "undefined" || typeof obj.generationId === "string") &&
    isEditorImageAsset(obj.asset)
  );
}

/**
 * Type guard for GenerateVideoKeyFrameUploadedImage
 */
export function isGenerateVideoKeyFrameUploadedImage(
  obj: any,
): obj is GenerateVideoKeyFrameUploadedImage {
  return (
    obj &&
    typeof obj === "object" &&
    obj.type === GenerateVideoKeyFrameType.UploadedImage &&
    typeof obj.id === "string" &&
    typeof obj.src === "string" &&
    typeof obj.width === "number" &&
    typeof obj.height === "number" &&
    isEditorImageAsset(obj.asset)
  );
}

/**
 * Type guard for GenerateVideoKeyFrameUploadButton
 */
export function isGenerateVideoKeyFrameUploadButton(
  obj: any,
): obj is GenerateVideoKeyFrameUploadButton {
  return (
    obj &&
    typeof obj === "object" &&
    obj.type === GenerateVideoKeyFrameType.UploadButton &&
    typeof obj.id === "string" &&
    Object.keys(obj).length === 2
  ); // Ensures no extra properties
}

/**
 * Type guard for GenerateVideoKeyFrame
 */
export function isGenerateVideoKeyFrame(obj: any): obj is GenerateVideoKeyFrame {
  return (
    isGenerateVideoKeyFrameImageObject(obj) ||
    isGenerateVideoKeyFrameUploadedImage(obj) ||
    isGenerateVideoKeyFrameUploadButton(obj)
  );
}

/**
 * Type guard for GenerateVideoKeyFrames
 */
export function isGenerateVideoKeyFrames(obj: any): obj is GenerateVideoKeyFrames {
  return Array.isArray(obj) && obj.every(isGenerateVideoKeyFrame);
}

export function getKeyFramesFromGenerateVideoKeyFrames({
  backend,
  keyframes,
}: {
  backend?: Backend;
  keyframes: GenerateVideoKeyFrames;
}): LumaVideoGenerationRequestKeyframes {
  return {
    frame0: getKeyFrameFromGenerateVideoKeyFrame({
      backend,
      keyframe: keyframes?.[0],
    }),
    frame1: getKeyFrameFromGenerateVideoKeyFrame({
      backend,
      keyframe: keyframes?.[1],
    }),
  };
}

export enum VideoCameraMotion {
  MoveLeft = "Move Left",
  MoveRight = "Move Right",
  MoveUp = "Move Up",
  MoveDown = "Move Down",
  PushIn = "Push In",
  PullOut = "Pull Out",
  ZoomIn = "Zoom In",
  ZoomOut = "Zoom Out",
  PanLeft = "Pan Left",
  PanRight = "Pan Right",
  OrbitLeft = "Orbit Left",
  OrbitRight = "Orbit Right",
  CraneUp = "Crane Up",
  CraneDown = "Crane Down",
}

export function isVideoCameraMotion(value: any): value is VideoCameraMotion {
  return Object.values(VideoCameraMotion).includes(value);
}

export interface VideoCameraMotionConfig {
  type: VideoCameraMotion;
  name: string;
  videoUrl: string;
  thumbnailUrl: string;
}

export const videoCameraMotionConfigs: Record<VideoCameraMotion, VideoCameraMotionConfig> = {
  [VideoCameraMotion.OrbitLeft]: {
    type: VideoCameraMotion.OrbitLeft,
    name: "Orbit Left",
    videoUrl: "https://video.flair.ai/OrbitLeft.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.OrbitRight]: {
    type: VideoCameraMotion.OrbitRight,
    name: "Orbit Right",
    videoUrl: "https://video.flair.ai/OrbitRight.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.CraneUp]: {
    type: VideoCameraMotion.CraneUp,
    name: "Crane Up",
    videoUrl: "https://video.flair.ai/CraneUp.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.CraneDown]: {
    type: VideoCameraMotion.CraneDown,
    name: "Crane Down",
    videoUrl: "https://video.flair.ai/CraneDown.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.MoveLeft]: {
    type: VideoCameraMotion.MoveLeft,
    name: "Move Left",
    videoUrl: "https://video.flair.ai/MoveLeft.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.MoveRight]: {
    type: VideoCameraMotion.MoveRight,
    name: "Move Right",
    videoUrl: "https://video.flair.ai/MoveRight.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.MoveUp]: {
    type: VideoCameraMotion.MoveUp,
    name: "Move Up",
    videoUrl: "https://video.flair.ai/MoveUp.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.MoveDown]: {
    type: VideoCameraMotion.MoveDown,
    name: "Move Down",
    videoUrl: "https://video.flair.ai/MoveDown.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.PushIn]: {
    type: VideoCameraMotion.PushIn,
    name: "Push In",
    videoUrl: "https://video.flair.ai/PushIn.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.PullOut]: {
    type: VideoCameraMotion.PullOut,
    name: "Pull Out",
    videoUrl: "https://video.flair.ai/PushOut.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.ZoomIn]: {
    type: VideoCameraMotion.ZoomIn,
    name: "Zoom In",
    videoUrl: "https://video.flair.ai/ZoomIn.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.ZoomOut]: {
    type: VideoCameraMotion.ZoomOut,
    name: "Zoom Out",
    videoUrl: "https://video.flair.ai/ZoomOut.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.PanLeft]: {
    type: VideoCameraMotion.PanLeft,
    name: "Pan Left",
    videoUrl: "https://video.flair.ai/PanLeft.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
  [VideoCameraMotion.PanRight]: {
    type: VideoCameraMotion.PanRight,
    name: "Pan Right",
    videoUrl: "https://video.flair.ai/PanRight.mp4",
    thumbnailUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a08ccda8-ea21-4565-1e4e-07b23ef7b600/256",
  },
};

export enum GenerateVideoLeftPanelTab {
  PastGenerations = "PastGenerations",
  Guide = "Guide",
}

export const GenerateVideoLeftPanelTabLabels: Record<GenerateVideoLeftPanelTab, React.ReactNode> = {
  [GenerateVideoLeftPanelTab.Guide]: "Guide",
  [GenerateVideoLeftPanelTab.PastGenerations]: "Past Generations",
};

export enum GenerateVideoEditorSection {
  Keyframe = "Keyframe",
  Prompt = "Prompt",
  CameraMotion = "CameraMotion",
}

export enum GenerateVideoPromptGenerationStatus {
  Idle = "Idle",
  Processing = "Processing",
}

export interface GenerateVideoEditorState {
  generateVideoPrompt: string;
  setGenerateVideoPrompt: (value: StateUpdater<string>) => void;
  generateVideoKeyFrames: GenerateVideoKeyFrames;
  setGenerateVideoKeyFrames: (value: StateUpdater<GenerateVideoKeyFrames>) => void;
  generateVideoCameraMotion: VideoCameraMotion | undefined;
  setGenerateVideoCameraMotion: (value: StateUpdater<VideoCameraMotion | undefined>) => void;
  generateVideoAspectRatio: VideoGenerationAspectRatio;
  setGenerateVideoAspectRatio: (value: VideoGenerationAspectRatio) => void;
  generateVideoPastGenerations: Record<string, VideoGenerationDoc>;
  setGenerateVideoPastGenerations: (value: Record<string, VideoGenerationDoc>) => void;
  generateVideoLeftPanelTab: GenerateVideoLeftPanelTab;
  setGenerateVideoLeftPanelTab: (value: StateUpdater<GenerateVideoLeftPanelTab>) => void;
  generateVideoEditorSection: GenerateVideoEditorSection;
  setGenerateVideoEditorSection: (value: StateUpdater<GenerateVideoEditorSection>) => void;
  generateVideoPromptGenerationStatus: GenerateVideoPromptGenerationStatus;
  setGenerateVideoPromptGenerationStatus: (
    value: StateUpdater<GenerateVideoPromptGenerationStatus>,
  ) => void;
}

export function getDummyGenerateVideoEditorState(): GenerateVideoEditorState {
  return {
    generateVideoPrompt: "",
    setGenerateVideoPrompt: noop,
    generateVideoKeyFrames: [],
    setGenerateVideoKeyFrames: noop,
    generateVideoCameraMotion: undefined,
    setGenerateVideoCameraMotion: noop,
    generateVideoAspectRatio: "1:1",
    setGenerateVideoAspectRatio: noop,
    generateVideoPastGenerations: {},
    setGenerateVideoPastGenerations: noop,
    generateVideoLeftPanelTab: GenerateVideoLeftPanelTab.Guide,
    setGenerateVideoLeftPanelTab: noop,
    generateVideoEditorSection: GenerateVideoEditorSection.Keyframe,
    setGenerateVideoEditorSection: noop,
    generateVideoPromptGenerationStatus: GenerateVideoPromptGenerationStatus.Idle,
    setGenerateVideoPromptGenerationStatus: noop,
  };
}

export function getDefaultGenerateVideoEditorState(
  set: SetEditorStateFunction,
): GenerateVideoEditorState {
  return {
    generateVideoPrompt: "",
    setGenerateVideoPrompt: getUpdaterFunction(set, "generateVideoPrompt"),
    generateVideoKeyFrames: [],
    setGenerateVideoKeyFrames: getUpdaterFunction(set, "generateVideoKeyFrames"),
    generateVideoCameraMotion: undefined,
    setGenerateVideoCameraMotion: getUpdaterFunction(set, "generateVideoCameraMotion"),
    generateVideoAspectRatio: "1:1",
    setGenerateVideoAspectRatio: getUpdaterFunction(set, "generateVideoAspectRatio"),
    generateVideoPastGenerations: {},
    setGenerateVideoPastGenerations: getUpdaterFunction(set, "generateVideoPastGenerations"),
    generateVideoLeftPanelTab: GenerateVideoLeftPanelTab.Guide,
    setGenerateVideoLeftPanelTab: getUpdaterFunction(set, "generateVideoLeftPanelTab"),
    generateVideoEditorSection: GenerateVideoEditorSection.Keyframe,
    setGenerateVideoEditorSection: getUpdaterFunction(set, "generateVideoEditorSection"),
    generateVideoPromptGenerationStatus: GenerateVideoPromptGenerationStatus.Idle,
    setGenerateVideoPromptGenerationStatus: getUpdaterFunction(
      set,
      "generateVideoPromptGenerationStatus",
    ),
  };
}

export function resetGenerateVideoEditorState(state: GenerateVideoEditorState) {
  state.setGenerateVideoPrompt("");
  state.setGenerateVideoKeyFrames([]);
  state.setGenerateVideoCameraMotion(undefined);
  state.setGenerateVideoAspectRatio("1:1");
  state.setGenerateVideoPastGenerations({});
  state.setGenerateVideoLeftPanelTab(GenerateVideoLeftPanelTab.Guide);
  state.setGenerateVideoEditorSection(GenerateVideoEditorSection.Keyframe);
}

export enum VideoPlayStatus {
  Idle = "Idle", // nothing laoded yet
  Stopped = "Stopped",
  Loading = "Loading",
  Playing = "Playing",
}

export function getGenerateVideoKeyFramesWithUploadButtons({
  keyFrames,
  maxNumKeyFrames = 2,
}: {
  keyFrames: GenerateVideoKeyFrames;
  maxNumKeyFrames?: number;
}): GenerateVideoKeyFrames {
  keyFrames = keyFrames.filter(
    (keyFrame) => keyFrame.type !== GenerateVideoKeyFrameType.UploadButton,
  );

  if (keyFrames.length >= maxNumKeyFrames) {
    return keyFrames.slice(0, maxNumKeyFrames);
  }

  if (keyFrames.length >= 1) {
    return [
      ...keyFrames,
      {
        type: GenerateVideoKeyFrameType.UploadButton,
        id: generateUUID(),
      },
    ];
  }

  return [
    {
      type: GenerateVideoKeyFrameType.UploadButton,
      id: generateUUID(),
    },
    {
      type: GenerateVideoKeyFrameType.UploadButton,
      id: generateUUID(),
    },
  ];
}

export type UserVideoGenerationQuotas = {
  numVideoGenerationCredits: number;
  maxNumVideoGenerationCredits: number;
  videoGenerationRenewPeriodSec: number;
  videoGenerationRenewTimestamp?: Timestamp;
};

export function isUserVideoGenerationQuotas(
  userQuotas: any,
): userQuotas is UserVideoGenerationQuotas {
  return (
    typeof userQuotas.numVideoGenerationCredits === "number" &&
    typeof userQuotas.maxNumVideoGenerationCredits === "number" &&
    typeof userQuotas.videoGenerationRenewPeriodSec === "number"
  );
}
