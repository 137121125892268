import { TextButtonClassName } from "@/components/constants/class-names";
import { trackTemplateAction } from "@/components/panels/panel-items/components/TemplateCarousel";
import { ScrollAreaContainer } from "@/components/scroll-area/scroll-area";
import { ImageToVideoIcon } from "@/components/video/image-to-video-icon";
import { editorContextStore } from "@/contexts/editor-context";
import {
  CustomModelType,
  DashboardType,
  getFrontendDisplayTemplateTypeFromCustomModelType,
} from "@/core/common/types";
import { getHighestUserStripeSubscriptionPlan } from "@/core/common/types/user-stripe-subscriptions";
import { classNames } from "@/core/utils/classname-utils";
import { Code2, HelpCircle, MousePointerClick, Rocket, Sparkles } from "lucide-react";

interface DashboardItem {
  name: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

interface DashboardSection {
  name: string;
  items: Partial<Record<DashboardType, DashboardItem>>;
}

const dashboardLeftPanelTabs: Record<string, DashboardSection> = {
  Tools: {
    name: "Tools",
    items: {
      templates: {
        name: "Templates",
        icon: <Rocket size={18} />,
        onClick: () => {
          trackTemplateAction("SidebarNavClick");
        },
      },
      projects: {
        name: "Drag & Drop Projects",
        icon: <MousePointerClick size={18} />,
      },
      models: {
        name: "Custom Models",
        icon: <Sparkles size={18} />,
      },
      videos: {
        name: "Generative Video",
        icon: <ImageToVideoIcon size={18} />,
      },
    },
  },
  More: {
    name: "More",
    items: {
      "manage-api": {
        name: "API",
        icon: <Code2 size={18} />,
      },
    },
  },
};

export default function DashboardLeftPanel() {
  const dashboardType = editorContextStore((state) => state.dashboardType);
  const setDashboardType = editorContextStore((state) => state.setDashboardType);
  const { setCustomModelWorkflow } = editorContextStore.getState();
  const userStripeSubscriptions = editorContextStore((state) => state.userStripeSubscriptions);
  const highestSubcriptionPlan = getHighestUserStripeSubscriptionPlan({
    userStripeSubscriptions,
  });

  return (
    <ScrollAreaContainer className="p-4 lg:w-[268px] hidden md:block md:flex-none border-r border-zinc-800">
      {Object.entries(dashboardLeftPanelTabs).map(([sectionKey, section]) => (
        <div key={sectionKey} className="mb-4">
          <div className="text-zinc-500 font-bold text-sm mb-2 px-4">{section.name}</div>
          {Object.entries(section.items).map(([itemKey, item]) => (
            <div
              key={itemKey}
              className={classNames(
                "w-full flex flex-row items-center justify-start select-none cursor-pointer px-4 py-2 mb-2 rounded-md transition-colors border active:border-zinc-700 active:text-white",
                dashboardType === itemKey
                  ? "text-zinc-100 bg-zinc-800 shadow-lg border-zinc-800 hover:border-zinc-700/50"
                  : "text-zinc-300 hover:text-zinc-200 hover:bg-zinc-800/80 border-zinc-900",
              )}
              onClick={() => {
                if (itemKey === "models") {
                  setCustomModelWorkflow(
                    getFrontendDisplayTemplateTypeFromCustomModelType(CustomModelType.Custom),
                  );
                }
                setDashboardType(itemKey as DashboardType);
                item.onClick?.();
              }}
            >
              <span className="mr-3">{item.icon}</span>
              <span>{item.name}</span>
            </div>
          ))}
          {sectionKey !== Object.keys(dashboardLeftPanelTabs).pop() && (
            <div className="h-px bg-zinc-800 my-2" />
          )}
        </div>
      ))}
      <div
        className={classNames(
          TextButtonClassName,
          "absolute flex flex-row flex-grow box-border items-center bottom-3 left-3 px-4 py-2  justify-start select-none cursor-pointer text-zinc-500 ",
        )}
      >
        <span className="mr-3">
          <HelpCircle size={18} />
        </span>
        <a
          href={`mailto:support@flair.ai?subject=${highestSubcriptionPlan}%20User%20-%20Customer%20Support`}
        >
          Need Help?
        </a>
      </div>
    </ScrollAreaContainer>
  );
}
