import { useState, useLayoutEffect, useRef, useCallback } from "react";

export interface ComponentSize {
  width: number;
  height: number;
}

export function useComponentSize<T extends HTMLElement>(): [ComponentSize, React.RefObject<T>] {
  const [size, setSize] = useState<ComponentSize>({ width: 0, height: 0 });
  const ref = useRef<T>(null);

  const updateSize = useCallback(() => {
    if (ref.current) {
      setSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [ref]);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      updateSize();
    });

    resizeObserver.observe(element);

    // Initial size update
    updateSize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [updateSize]);

  return [size, ref];
}
