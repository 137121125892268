import { isTemporaryProjectId } from "@/core/utils/project-utils";
import { editorContextStore } from "contexts/editor-context";
import { Base } from "@/core/controllers/base";
import { incrementBigInt } from "@/core/utils/number-utils";
import { ControllerOptions } from "../interfaces";
import { HistoryChangedEditorEventHandler } from "../types";
import { debugLog } from "@/core/utils/print-utilts";

export class SyncEdtorState extends Base {
  private unsubscribeCreatingProject: () => void;

  constructor(props: ControllerOptions) {
    super(props);

    // Initialize event listener
    this.editor.on<HistoryChangedEditorEventHandler>("history:changed", this.onHistoryChange);

    this.unsubscribeCreatingProject = editorContextStore.subscribe(
      (state) => state.isCreatingNewProject,
      this.onCreateProjectChange,
    );
  }

  public destroy() {
    this.editor.off<HistoryChangedEditorEventHandler>("history:changed", this.onHistoryChange);
    editorContextStore.getState().setVersion("0");
    this.unsubscribeCreatingProject();
  }

  private onCreateProjectChange = (
    isCreatingNewProject: boolean,
    prevIsCreatingNewProject?: boolean,
  ) => {
    const projectId = editorContextStore.getState().projectId;
    if (
      !isCreatingNewProject &&
      prevIsCreatingNewProject &&
      projectId &&
      !isTemporaryProjectId(projectId)
    ) {
      console.log("New project created. Upload the current scene.");
      const sceneJson = this.editor.scene.exportToJSON();
      editorContextStore.getState().backend?.setProjectSceneData(projectId, sceneJson);
    }
  };

  private onHistoryChange = () => {
    const backend = this.editor?.state?.backend;
    if (backend) {
      const projectId = this.editor.state.projectId;
      if (projectId && !isTemporaryProjectId(projectId)) {
        const currVersion = this.editor.state.version;
        const nextVersion = incrementBigInt(currVersion, 1);
        const sceneJson = this.editor.scene.exportToJSON(nextVersion);
        backend.setProjectSceneData(projectId, sceneJson)?.then(({ isUpdated, scene }) => {
          if (isUpdated) {
            this.editor.state.setVersion(nextVersion);
          } else {
            debugLog("Cannot update to the latest version");
            if (scene) {
              this.editor.scene.importFromJSON(scene);
            }
          }
        });
      } else {
        debugLog(`Project id ${projectId} is invalid; Cannot set project scene data`);
      }
    } else {
      debugLog(`Editor backend is invalid; Cannot set project scene data`);
    }
  };
}
