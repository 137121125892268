import { isGenerationFrame } from "@/core/utils/type-guards";
import { getNearestNumberInGrid } from "@/core/utils/number-utils";
import { fabric } from "fabric";
import { getCenterFromBounds } from "@/core/utils/bbox-utils";
import Zoom from "@/core/controllers/zoom";
import type MobileObjects from "./objects";

class MobileZoom extends Zoom {
  static getBBoxZoomFitRatio({
    width = 0,
    height = 0,
    canvas,
    frameMargin = 0,
  }: {
    width?: number;
    height?: number;
    canvas: fabric.Canvas;
    frameMargin?: number;
  }) {
    if (!width || !height) {
      return 1;
    }
    const canvasWidth = canvas.getWidth() - frameMargin;
    const canvasHeight = canvas.getHeight() - frameMargin;
    let scaleX = canvasWidth / width;
    const scaleY = canvasHeight / height;
    if (height >= width) {
      scaleX = scaleY;
      if (canvasWidth < width * scaleX) {
        scaleX = scaleX * (canvasWidth / (width * scaleX));
      }
    } else {
      if (canvasHeight < height * scaleX) {
        scaleX = scaleX * (canvasHeight / (height * scaleX));
      }
    }
    return scaleX;
  }

  static getObjectZoomFitRatio(canvas: fabric.Canvas, object: fabric.Object, frameMargin = 0) {
    const width = object.getScaledWidth() ?? 0;
    const height = object.getScaledHeight() ?? 0;
    if (width <= 0 || height <= 0) {
      return 1;
    }
    const canvasWidth = canvas.getWidth() - frameMargin;
    const canvasHeight = canvas.getHeight() - frameMargin;
    let scaleX = canvasWidth / width;
    const scaleY = canvasHeight / height;
    if (height >= width) {
      scaleX = scaleY;
      if (canvasWidth < width * scaleX) {
        scaleX = scaleX * (canvasWidth / (width * scaleX));
      }
    } else {
      if (canvasHeight < height * scaleX) {
        scaleX = scaleX * (canvasHeight / (height * scaleX));
      }
    }
    return scaleX;
  }

  get zoomFitRatio() {
    const frame = this.editor.frame;
    if (frame) {
      return frame.fitRatio;
    }
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      return MobileZoom.getObjectZoomFitRatio(this.canvas, activeObject);
    }
    return 1;
  }

  get viewportTransform(): [number, number, number, number, number, number] {
    return (this.canvas.viewportTransform as any) || [1, 0, 0, 1, 0, 0];
  }

  get zoomRatio() {
    return this.canvas.getZoom();
  }

  zoom(zoomRatio: number) {
    zoomRatio = MobileZoom.getZoomRatio(zoomRatio);
    const center = this.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    this.state.setZoomRatio(zoomRatio);
  }

  static getNearestZoomRatio(zoomRatio: number) {
    return getNearestNumberInGrid(zoomRatio, 0.05);
  }

  zoomInGrid() {
    this.zoom(MobileZoom.getNearestZoomRatio(this.canvas.getZoom() + 0.05));
  }

  zoomOutGrid() {
    this.zoom(MobileZoom.getNearestZoomRatio(this.canvas.getZoom() - 0.05));
  }

  zoomIn() {
    let zoomRatio = this.canvas.getZoom();
    zoomRatio += 0.05;
    const center = this.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    this.state.setZoomRatio(zoomRatio);
  }

  zoomOut() {
    let zoomRatio = this.canvas.getZoom();
    zoomRatio -= 0.05;
    const center = this.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    this.state.setZoomRatio(zoomRatio);
  }

  zoomToOne() {
    const center = this.canvas.getCenter();
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(new fabric.Point(center.left, center.top), 1);
    this.state.setZoomRatio(1);
  }

  get center() {
    const center = this.canvas.getCenter();
    return {
      x: center.left,
      y: center.top,
    };
  }

  static minZoom = 10;
  static maxZoom = 200;
  static defaultFrameMargin = 20;

  static getZoomRatio(zoom: number) {
    const minZoom = MobileZoom.minZoom;
    const maxZoom = MobileZoom.maxZoom;
    let zoomRatio = zoom;
    if (zoom <= minZoom / 100) {
      zoomRatio = minZoom / 100;
    } else if (zoom >= maxZoom / 100) {
      zoomRatio = maxZoom / 100;
    }
    return zoomRatio;
  }

  protected zoomInternal(center: { x: number; y: number }, zoomFitRatio: number) {
    const zoomRatio = MobileZoom.getZoomRatio(zoomFitRatio);
    const defaultTransform = [1, 0, 0, 1, 0, 0];
    defaultTransform[0] = zoomRatio;
    defaultTransform[3] = zoomRatio;
    defaultTransform[4] = (this.canvas.getWidth() / zoomRatio / 2 - center.x) * zoomRatio;
    defaultTransform[5] = (this.canvas.getHeight() / zoomRatio / 2 - center.y) * zoomRatio;
    this.canvas.setViewportTransform(defaultTransform);
    this.state.setZoomRatio(zoomFitRatio);
  }

  zoomToFitGenerationFrame(frameMargin = MobileZoom.defaultFrameMargin) {
    const bounds = (this.editor.objects as MobileObjects).getGenerationFrameBounds?.();
    if (!bounds) {
      return;
    }

    const zoomFitRatio = MobileZoom.getBBoxZoomFitRatio({
      ...bounds,
      canvas: this.canvas,
      frameMargin,
    });
    const center = getCenterFromBounds(bounds);
    this.zoomInternal(center, zoomFitRatio);
  }

  zoomToFitObjects(objects: fabric.Object[], frameMargin = MobileZoom.defaultFrameMargin) {
    if (!objects) {
      return;
    }

    const bounds = (this.editor.objects as MobileObjects).getBoundsOfObjects?.(objects);

    if (!bounds) {
      return;
    }

    const zoomFitRatio = MobileZoom.getBBoxZoomFitRatio({
      ...bounds,
      canvas: this.canvas,
      frameMargin,
    });
    const center = getCenterFromBounds(bounds);
    this.zoomInternal(center, zoomFitRatio);
  }

  zoomToFitAll(frameMargin = MobileZoom.defaultFrameMargin) {
    const bounds = this.editor.objects.getBoundsOfAll();
    if (!bounds) {
      return;
    }

    const zoomFitRatio = MobileZoom.getBBoxZoomFitRatio({
      ...bounds,
      canvas: this.canvas,
      frameMargin,
    });
    const center = getCenterFromBounds(bounds);
    this.zoomInternal(center, zoomFitRatio);
  }

  async zoomToFit(
    object?: fabric.Object | fabric.Frame | null,
    frameMargin = MobileZoom.defaultFrameMargin,
  ) {
    object = object || this.editor.frame?.frame || this.canvas.getActiveObject();

    if (!object) {
      return this.zoomToFitAll();
    }

    const bounds = {
      left: object.left,
      top: object.top,
      width: (object.width ?? 0) * (isGenerationFrame(object) ? 2 : 1),
      height: object.height,
    };

    const zoomFitRatio = MobileZoom.getBBoxZoomFitRatio({
      ...bounds,
      canvas: this.canvas,
      frameMargin,
    });
    const center = getCenterFromBounds(bounds);
    this.zoomInternal(center, zoomFitRatio);

    // const frameMargin = object?.type === 'Frame' ? (this.editor.frame?.frameMargin || 0) : MobileZoom.defaultFrameMargin;
    // const zoomFitRatio = object ? Zoom.getObjectZoomFitRatio(this.canvas, object, frameMargin) : 1;
    // const center = object?.getCenterPoint() || this.getCanvasCenterPoint();
    // this.zoomInternal(center, zoomFitRatio);
  }

  zoomToRatio(zoomRatio: number) {
    const center = this.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    this.state.setZoomRatio(zoomRatio);
  }

  zoomToPoint(point: fabric.Point, zoom: number) {
    const zoomRatio = MobileZoom.getZoomRatio(zoom);
    this.canvas.zoomToPoint(point, zoomRatio);
    this.state.setZoomRatio(zoomRatio);
  }
}

export default MobileZoom;
