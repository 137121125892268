import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { classNames } from "@/core/utils/classname-utils";
import { DropdownClassName } from "components/constants/class-names";
import { LeftPanelDropdownZIndex } from "components/constants/zIndex";
import styles from "./dropdown-base.module.css";

export type DropdownBaseProps = DropdownMenu.DropdownMenuProps & {
  triggerProps: DropdownMenu.DropdownMenuTriggerProps;
  contentProps: DropdownMenu.DropdownMenuContentProps;
};

export function DropdownBase({ triggerProps, contentProps, ...props }: DropdownBaseProps) {
  const triggerChildren = triggerProps.children;
  const triggerClassName = triggerProps.className || "";

  const contentChildren = contentProps.children;
  const contentClassName = contentProps.className || "";
  const contentStyle = contentProps.style;

  return (
    <DropdownMenu.Root {...props}>
      <DropdownMenu.Trigger
        className={classNames(styles.DropdownMenuTrigger, "focus:outline-none", triggerClassName)}
        {...props}
      >
        {triggerChildren}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          {...contentProps}
          className={classNames(
            styles.DropdownMenuContent,
            DropdownClassName,
            "text-sm px-2 overflow-x-hidden overflow-y-auto",
            contentClassName,
          )}
          style={{
            ...contentStyle,
            zIndex: LeftPanelDropdownZIndex,
          }}
        >
          {contentChildren}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
