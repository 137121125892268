import { Backend } from "@/backend/base";
import {
  CustomModelPlaygroundPromptEditorState,
  CustomModelPredictionItem,
  CustomModelScaleConfig,
  StateUpdater,
} from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import { debugError } from "@/core/utils/print-utilts";
import { PaintMaskCanvasControllerPaintBrushType } from "components/editor/paint-mask-canvas-controller";
import React, { createContext, useContext, useState } from "react";
import { getEmptyCustomModelPlaygroundPromptEditorState } from "./custom-model-playground-context";

export enum CustomModelImageEditorMode {
  Default = "Default",
  RegenerateProduct = "RegenerateProduct",
  RegenerateHuman = "RegenerateHuman",
  FixText = "FixText",
  CompareOutputs = "CompareOutputs",
  MagicErase = "MagicErase",
  Upscale = "Upscale",
  Inpainting = "Inpainting",
  VirtualTryOn = "VirtualTryOn",
  UpscaleCreative = "UpscaleCreative",
  GenerateVarations = "ICV",
  UpscaleFace = "UpscaleFace",
}

export const customModelImageEditorModeToNames: Record<CustomModelImageEditorMode, string> = {
  [CustomModelImageEditorMode.Default]: "",
  [CustomModelImageEditorMode.RegenerateHuman]: "Regenerate human",
  [CustomModelImageEditorMode.RegenerateProduct]: "Regenerate product",
  [CustomModelImageEditorMode.FixText]: "Fix logo & text",
  [CustomModelImageEditorMode.CompareOutputs]: "Outputs",
  [CustomModelImageEditorMode.MagicErase]: "Magic erase",
  [CustomModelImageEditorMode.Upscale]: "Upscale image",
  [CustomModelImageEditorMode.UpscaleFace]: "Upscale face",
  [CustomModelImageEditorMode.Inpainting]: "Inpaint",
  [CustomModelImageEditorMode.VirtualTryOn]: "Replace clothing",
  [CustomModelImageEditorMode.UpscaleCreative]: "Upscale creative",
  [CustomModelImageEditorMode.GenerateVarations]: "Generate Varations",
};

export enum CustomModelImageEditorFixProductDetailsGenerateStatus {
  Idle = "Idle",
  Loading = "Loading",
}

export enum CustomModelImageEditorFixProductDetailsMaskStatus {
  Idle = "Idle",
  Loading = "Loading",
}

interface CustomModelImageEditorFixProductDetailsContextType {
  productPrompt: string;
  setProductPrompt: (value: StateUpdater<string>) => void;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  setPromptEditorState: (value: StateUpdater<CustomModelPlaygroundPromptEditorState>) => void;
  maskImageUrl: string | undefined;
  setMaskImageUrl: (value: StateUpdater<string | undefined>) => void;
  productModelScaleConfig: CustomModelScaleConfig | undefined;
  setProductModelScaleConfig: (value: StateUpdater<CustomModelScaleConfig | undefined>) => void;
  outputImageUrls: string[];
  setOutputImageUrls: (value: StateUpdater<string[]>) => void;
  maskBrushType: PaintMaskCanvasControllerPaintBrushType;
  setMaskBrushType: (value: StateUpdater<PaintMaskCanvasControllerPaintBrushType>) => void;
  maskBrushSize: number;
  setMaskBrushSize: (value: StateUpdater<number>) => void;
}

const CustomModelImageEditorFixProductDetailsContext = createContext<
  CustomModelImageEditorFixProductDetailsContextType | undefined
>(undefined);

export function useCustomModelImageEditorFixProductDetailsContext() {
  const context = useContext(CustomModelImageEditorFixProductDetailsContext);

  if (!context) {
    throw new Error(
      "useCustomModelImageEditorFixDetailsContext must be used with in CustomModelImageEditorFixDetailsContext",
    );
  }

  return context;
}

export function CustomModelImageEditorFixProductDetailsProvider({
  children,
}: React.PropsWithChildren) {
  const [productPrompt, setProductPrompt] = useState("");

  const [promptEditorState, setPromptEditorState] =
    useState<CustomModelPlaygroundPromptEditorState>(
      getEmptyCustomModelPlaygroundPromptEditorState(),
    );

  const [maskImageUrl, setMaskImageUrl] = useState<string | undefined>(undefined);

  const [outputImageUrls, setOutputImageUrls] = useState<string[]>([]);

  const [productModelScaleConfig, setProductModelScaleConfig] = useState<
    CustomModelScaleConfig | undefined
  >(undefined);

  const [maskBrushType, setMaskBrushType] = useState(PaintMaskCanvasControllerPaintBrushType.Paint);

  const [maskBrushSize, setMaskBrushSize] = useState(25);

  return (
    <CustomModelImageEditorFixProductDetailsContext.Provider
      value={{
        productPrompt,
        setProductPrompt,
        promptEditorState,
        setPromptEditorState,
        maskImageUrl,
        setMaskImageUrl,
        productModelScaleConfig,
        setProductModelScaleConfig,
        outputImageUrls,
        setOutputImageUrls,
        maskBrushType,
        setMaskBrushType,
        maskBrushSize,
        setMaskBrushSize,
      }}
    >
      {children}
    </CustomModelImageEditorFixProductDetailsContext.Provider>
  );
}

interface CustomModelImageEditorContextType {
  prediction: CustomModelPredictionItem | undefined;
  setPrediction: (value: StateUpdater<CustomModelPredictionItem | undefined>) => void;
  imageIndex: number;
  setImageIndex: (value: StateUpdater<number>) => void;
  imageUrl: string;
  setImageUrl: (value: StateUpdater<string>) => void;
  modes: CustomModelImageEditorMode[];
  setModes: (value: StateUpdater<CustomModelImageEditorMode[]>) => void;
  width: number;
  setWidth: (value: StateUpdater<number>) => void;
  height: number;
  setHeight: (value: StateUpdater<number>) => void;
}

const CustomModelImageEditorContext = createContext<CustomModelImageEditorContextType | undefined>(
  undefined,
);

export function useCustomModelImageEditorContext() {
  const context = useContext(CustomModelImageEditorContext);
  if (!context) {
    throw new Error(
      "useCustomModelImageEditorContext must be used within a CustomModelImageEditorContext",
    );
  }

  return context;
}

export function useCustomModelImageEditorActiveMode() {
  const { modes = [] } = useCustomModelImageEditorContext();

  return React.useMemo(() => {
    const numModes = modes.length;

    if (numModes < 1) {
      return CustomModelImageEditorMode.Default;
    }

    return modes[numModes - 1] ?? CustomModelImageEditorMode.Default;
  }, [modes]);
}

export function usePrevCustomModelImageEditorActiveMode() {
  const { modes = [] } = useCustomModelImageEditorContext();

  return React.useMemo(() => {
    const numModes = modes.length;

    if (numModes < 2) {
      return undefined;
    }

    return modes[numModes - 2];
  }, [modes]);
}

export function useSetCustomModelImageEditorActiveModeCallback() {
  const { setModes } = useCustomModelImageEditorContext();

  return React.useCallback(
    (mode: CustomModelImageEditorMode) => {
      return setModes((modes) => [...modes, mode]);
    },
    [setModes],
  );
}

export function useBackCustomModelImageEditorActiveModeCallback() {
  const { setModes } = useCustomModelImageEditorContext();

  return React.useCallback(() => {
    setModes((modes) => {
      if (modes.length <= 0) {
        return modes; // No modes to pop, return as is
      }
      return modes.slice(0, -1); // Return all elements except the last one
    });
  }, [setModes]);
}

export function useCustomModelImageEditorActiveImageStoragePath() {
  const { prediction, imageIndex } = useCustomModelImageEditorContext();

  return React.useMemo(() => {
    if (!prediction) {
      return undefined;
    }

    return prediction.output?.[imageIndex];
  }, [prediction, imageIndex]);
}

export interface CustomModelImageEditorProps {
  prediction?: CustomModelPredictionItem;
  mode?: CustomModelImageEditorMode;
  imageIndex?: number;
  imageUrl?: string;
  width?: number;
  height?: number;
}

export function CustomModelImageEditorProvider({
  children,
  prediction: initPrediction,
  imageIndex: initImageIndex = 0,
  mode: initMode = CustomModelImageEditorMode.Default,
  width: initWidth = 0,
  height: initHeight = 0,
}: React.PropsWithChildren & CustomModelImageEditorProps) {
  const [modes, setModes] = useState([initMode]);
  const [imageIndex, setImageIndex] = useState(initImageIndex);
  const [imageUrl, setImageUrl] = useState("");
  const [width, setWidth] = useState(initWidth);
  const [height, setHeight] = useState(initHeight);
  const [prediction, setPrediction] = useState(initPrediction);

  return (
    <CustomModelImageEditorContext.Provider
      value={{
        prediction,
        setPrediction,
        modes,
        setModes,
        imageUrl,
        setImageUrl,
        imageIndex,
        setImageIndex,
        width,
        setWidth,
        height,
        setHeight,
      }}
    >
      {children}
    </CustomModelImageEditorContext.Provider>
  );
}

export async function loadImageFromCustomModelPredictionOutput({
  backend,
  prediction,
  imageIndex = 0,
}: {
  backend: Backend | undefined;
  prediction: CustomModelPredictionItem | undefined;
  imageIndex?: number;
}): Promise<string | undefined> {
  try {
    if (!backend) {
      return undefined;
    }

    const output = prediction?.output;

    if (!output || output.length <= 0) {
      return undefined;
    }

    const index = imageIndex % output.length;

    const path = output[index];

    if (!path) {
      return undefined;
    }

    const imageUrl = Assets.loadAssetFromPath({
      path,
      backend,
    });

    if (!imageUrl) {
      return undefined;
    }

    return imageUrl ?? undefined;
  } catch (error) {
    debugError("Error getting custom model prediction: ", error);
    return undefined;
  }
}
