import { editorContextStore } from "contexts/editor-context";
import { FeatureFlags, DefaultFeatureFlags } from "./featureFlags";

export class FeatureFlagController {
  private readonly storageKey = "FlairFeatureFlags";

  private flags: FeatureFlags = { ...DefaultFeatureFlags };
  private _isVIP: boolean = false;

  constructor() {
    this.loadFlags();
  }

  private loadFlags(): void {
    const storedFlags = localStorage.getItem(this.storageKey);
    if (storedFlags) {
      this.flags = { ...this.flags, ...JSON.parse(storedFlags) };
    }
  }

  private saveFlags(): void {
    localStorage.setItem(this.storageKey, JSON.stringify(this.flags));
  }

  public getFlag<K extends keyof FeatureFlags>(key: K): FeatureFlags[K] {
    return this.flags[key];
  }

  public setFlag<K extends keyof FeatureFlags>(key: K, value: FeatureFlags[K]): void {
    this.flags[key] = value;
    this.saveFlags();
  }

  public setFlags(flags: Partial<FeatureFlags>): void {
    Object.assign(this.flags, flags);
    editorContextStore.getState().setFeatureFlags(this.flags);
    this.saveFlags();
  }

  public setVIP(isVIP: boolean = false): void {
    this._isVIP = isVIP;
    editorContextStore.getState().setFeatureFlagVIP(this._isVIP);
  }

  public get isVIP(): boolean {
    return this._isVIP;
  }

  public isFeatureEnabled<K extends keyof FeatureFlags>(key: K): boolean {
    return !!this.flags[key];
  }

  public removeFlag<K extends keyof FeatureFlags>(key: K): void {
    delete this.flags[key];
    this.saveFlags();
  }

  public clearFlags(): void {
    this.flags = { ...DefaultFeatureFlags };
    editorContextStore.getState().setFeatureFlags(this.flags);
    this.saveFlags();
  }

  public clearVIP(): void {
    this._isVIP = false;
    editorContextStore.getState().setFeatureFlagVIP(this._isVIP);
  }

  public clear(): void {
    this.clearFlags();
    this.clearVIP();
  }
}
