import { cloneDeep } from "lodash";

import { SampleProjectScene } from "@/core/common/scene";
import { UserProjectType } from "@/core/common/types";
import { Templates } from "./user-project-type-styles";

import {
  AccessoriesProject,
  BeautyProject,
  CPGProject,
  FoodProject,
  FurnitureProject,
  HomegoodsProject,
  StagingProject,
  HumansProject,
  ProductPhotographyProject,
} from "components/constants/projects";

export const defaultProjectStoragePath = "sampleProjects/example-project-0.json";
export const defaultProjectPromptTemplateId = "foundation_flowers_beige";
export const emptyProject: SampleProjectScene = {
  name: "Example Project",
  metadata: {
    animated: false,
  },
  version: "1",
  objects: {},
  frame: {
    height: 0,
    width: 0,
  },
};

export const samplePlatformProjectRealTimeResultImageUrl =
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f98690f6-de44-4b63-b93c-b076c1690b00/1024";

export const samplePlatformProjectRealTimeReferenceImageUrl =
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5da370bb-a1d8-4a53-21fa-ae6557979600/1024";

export const samplePlatformProject: SampleProjectScene = ProductPhotographyProject;

export function getNewProjectScene(projectType?: UserProjectType) {
  switch (projectType) {
    case UserProjectType.Accessories:
      return cloneDeep(AccessoriesProject);
    case UserProjectType.Beauty:
      return cloneDeep(BeautyProject);
    case UserProjectType.CPG:
      return cloneDeep(CPGProject);
    case UserProjectType.Food:
      return cloneDeep(FoodProject);
    case UserProjectType.Furniture:
      return cloneDeep(FurnitureProject);
    case UserProjectType.Homegoods:
      return cloneDeep(HomegoodsProject);
    case UserProjectType.Staging:
      return cloneDeep(StagingProject);
    case UserProjectType.Humans:
      return cloneDeep(HumansProject);
    case UserProjectType.ProductPhotography:
    default:
      return cloneDeep(ProductPhotographyProject);
  }
}

export function getProjectRealTimeResultImageUrl(projectType?: UserProjectType) {
  if (!projectType) {
    return "";
  }

  return Templates.find(({ data }) => data.projectWorkflow === projectType)?.image ?? "";
}
