// Food workflow JSON
import { SampleProjectScene } from "@/core/common/scene";
import { EditorAssetContentType } from "@/core/common/types";

export const FurnitureProject: SampleProjectScene = {
  metadata: {
    animated: false,
  },
  objects: {
    gZgKFP3GPUrvTfhHqpKCB: {
      strokeWidth: 0,
      metadata: {
        surrounding: "wood table with vase",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4a99d84a-26b2-4993-d752-02cf3270e200/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.48,
      scaleY: 0.48,
      top: 891.67,
      angle: 0,
      id: "gZgKFP3GPUrvTfhHqpKCB",
      flipX: false,
      flipY: false,
      height: 948,
      zIndex: 2,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: -1384.53,
      name: "StaticImage",
      width: 948,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4a99d84a-26b2-4993-d752-02cf3270e200/public",
        type: "image-url",
      },
    },
    "MTt5is8KTAJlb-u1MOgy6": {
      strokeWidth: 0,
      metadata: {
        surrounding: "chair",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "subject",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2b9c5f02-0355-472b-060c-0803d13b6d00/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.58,
      scaleY: 0.58,
      top: 987.16,
      angle: 0,
      id: "MTt5is8KTAJlb-u1MOgy6",
      flipX: false,
      flipY: false,
      height: 538,
      zIndex: 1,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: -1546.38,
      name: "StaticImage",
      width: 464,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2b9c5f02-0355-472b-060c-0803d13b6d00/public",
        type: "image-url",
      },
    },
    Ztn0PY8LIESqBIEijgMNq: {
      strokeWidth: 0,
      metadata: {
        surrounding: "framed art",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e91d091b-d7db-416b-042b-4af258763e00/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.66,
      scaleY: 0.66,
      top: 522.18,
      angle: 0,
      id: "Ztn0PY8LIESqBIEijgMNq",
      flipX: false,
      flipY: false,
      height: 700,
      zIndex: 0,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: -1391.49,
      name: "StaticImage",
      width: 700,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e91d091b-d7db-416b-042b-4af258763e00/public",
        type: "image-url",
      },
    },
    SAvrp5MkPJFZa2R6add5D: {
      strokeWidth: 0,
      metadata: {
        surrounding: "flowers",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3f379c92-d896-4a82-4c96-1dc8e8474f00/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.42,
      scaleY: 0.42,
      top: 1054.8,
      angle: 0,
      id: "SAvrp5MkPJFZa2R6add5D",
      flipX: false,
      flipY: false,
      height: 695,
      zIndex: 3,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: -1061.96,
      name: "StaticImage",
      width: 849,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3f379c92-d896-4a82-4c96-1dc8e8474f00/public",
        type: "image-url",
      },
    },
  },
  name: "Untitled Project",
  generationFrame: {
    top: 421.94999999999993,
    left: -1638.38,
    referneceImage: {
      path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5da370bb-a1d8-4a53-21fa-ae6557979600/1024",
      type: "image-url",
    },
    width: 1024,
    id: "generation-frame",
    type: "GenerationFrame",
    promptTemplate: {
      words: [
        {
          autocompleteType: "subject",
          type: "input",
          value: "a white fluffy chair",
        },
        {
          isAutoFilled: true,
          prefix: "with",
          autocompleteType: "surrounding",
          type: "input",
          value: "framed art and chair and wood table with vase and flowers",
          objectIds: [
            "Ztn0PY8LIESqBIEijgMNq",
            "MTt5is8KTAJlb-u1MOgy6",
            "gZgKFP3GPUrvTfhHqpKCB",
            "SAvrp5MkPJFZa2R6add5D",
          ],
        },
        {
          prefix: "in front of",
          autocompleteType: "background",
          type: "input",
          value: "a natural beige background",
        },
        {
          prefix: "on",
          autocompleteType: "placement",
          type: "fixed",
          value: "circular platforms",
        },
      ],
      style: "",
    },
    height: 1024,
  },
  id: "CjVaa9aaYK1xUyaPRTp9",
  version: "278",
  frame: {
    width: 0,
    height: 0,
  },
};
