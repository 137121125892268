import React from "react";
import { useComponentSize } from "hooks/use-component-size";
import { classNames } from "@/core/utils/classname-utils";

function getNumColumns(width: number, sortedBreakpoints: Array<[number, number]>): number {
  for (const [minWidth, numColumns] of sortedBreakpoints) {
    if (width >= minWidth) {
      return numColumns;
    }
  }
  return 1; // Default to 1 column if no breakpoint matches
}

interface MasonryGridProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  items: T[];
  columnClassName?: string;
  renderItem: (item: T, index: number) => React.ReactNode;
  breakpoints?: Record<number, number>; // Map from minWidth to numColumns
}

export function MasonryGrid<T>({
  items,
  renderItem,
  breakpoints = {
    1200: 4, // 4 columns for widths >= 1200px
    1024: 3, // 3 columns for widths >= 1024px
    768: 2, // 2 columns for widths >= 768px
    0: 1, // 1 column for widths < 768px
  },
  className = "",
  columnClassName = "",
  style = {},
  ...props
}: MasonryGridProps<T>) {
  const [containerSize, containerRef] = useComponentSize<HTMLDivElement>();

  // Cache sorted breakpoints using useMemo
  const sortedBreakpoints = React.useMemo(() => {
    return Object.entries(breakpoints)
      .map(([minWidth, numColumns]) => [Number(minWidth), numColumns] as [number, number])
      .sort((a, b) => b[0] - a[0]); // Sort breakpoints by minWidth in descending order
  }, [breakpoints]);

  // Memoize the calculation of columns based on the container width
  const { columns, numColumns } = React.useMemo(() => {
    const numColumns = getNumColumns(containerSize.width, sortedBreakpoints);

    // Distribute items into columns
    const columns = Array.from({ length: numColumns }, (_, columnIndex) =>
      items.filter((_, index) => index % numColumns === columnIndex),
    );

    return { columns, numColumns };
  }, [containerSize.width, items, sortedBreakpoints]);

  return (
    <div
      {...props}
      ref={containerRef}
      className={classNames("grid gap-4", className)}
      style={{
        ...style,
        gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))`,
      }}
    >
      {columns.map((columnItems, columnIndex) => (
        <div key={columnIndex}>
          <div className={classNames("grid gap-4", columnClassName)}>
            {columnItems.map((item, itemIndex) => renderItem(item, itemIndex))}
          </div>
        </div>
      ))}
    </div>
  );
}
