import { debugError } from "@/core/utils/print-utilts";
import {
  CreateCheckoutSessionParams,
  CreateCheckoutSessionResult,
  CreateCustomOneTimePaymentCheckoutSessionArgs,
} from "@/backend/base";
import { editorContextStore } from "@/contexts/editor-context";
import { displayUiMessage } from "@/components/utils/display-message";
import { StripeSubscriptionPriceId } from "@/core/common/types/subscription";
import { appendPathToRootUrl } from "@/core/utils/url-utils";
import { QuotaOneTimePayment } from "@/core/common/types/quota-one-time-payment";
import { CreateStripePortalLinkParams } from "@/core/common/types/stripe-portal";

export async function subscribeToPlanInternal({
  openNewPage = false,
  ...props
}: CreateCheckoutSessionParams & {
  openNewPage?: boolean;
}): Promise<CreateCheckoutSessionResult> {
  const newWindow = openNewPage && window.open("", "_blank");

  const backend = editorContextStore.getState().backend;
  if (!backend) {
    return {};
  }

  return await backend
    ?.createCheckoutSession({
      ...props,
    })
    .then((response) => {
      const { url, message } = response;

      if (url) {
        if (newWindow) {
          newWindow.location.assign(url);
        } else {
          window.location.assign(url);
        }
      } else {
        if (message) {
          displayUiMessage(message);
        }
      }

      return response;
    })
    .catch((error) => {
      debugError(error);

      displayUiMessage("Unknown error occurred. Please refresh the page.", "error");

      return {};
    });
}

export async function subscribeToPlan(price: StripeSubscriptionPriceId, openNewPage = false) {
  return subscribeToPlanInternal({
    openNewPage,
    line_items: [
      {
        price,
        quantity: 1,
      },
    ],
  });
}

export async function subscribeToIndividualProPlan(openNewPage = false) {
  return await subscribeToPlan(StripeSubscriptionPriceId.IndividualProPlan, openNewPage);
}

export async function subscribeToApiStandardPlan() {
  const apiUrl = appendPathToRootUrl("api");

  return await subscribeToPlanInternal({
    line_items: [
      {
        price: StripeSubscriptionPriceId.ApiStandardPlan,
      },
    ],
    cancel_url: apiUrl,
    success_url: apiUrl,
  });
}

export interface SubscribeToOneTimePaymentArgs extends QuotaOneTimePayment {
  quantity: number;
  cancelUrl?: string;
  successUrl?: string;
  openNewPage?: boolean;
}

export async function subscribeToOneTimePayment({
  numCustomModelTrainings = 0,
  numCustomModelPredictions = 0,
  numVideoGenerations = 0,
  quantity,
  cancelUrl,
  successUrl,
  openNewPage = false,
}: SubscribeToOneTimePaymentArgs) {
  try {
    const backend = editorContextStore.getState()?.backend;

    if (!backend) {
      return;
    }

    const currentUrl = window.location.href;
    cancelUrl = cancelUrl || currentUrl;
    successUrl = successUrl || currentUrl;

    const newWindow = openNewPage && window.open("", "_blank");

    const response = await backend.createOneTimePaymentCheckoutSession({
      priceId: StripeSubscriptionPriceId.OneTimePayment,
      quantity,
      metadata: {
        numCustomModelTrainings,
        numCustomModelPredictions,
        numVideoGenerations,
      },
      successUrl,
      cancelUrl,
    });

    if (!response.ok) {
      displayUiMessage(
        // @ts-expect-error
        `Error creating payment portal: ${response.message}`,
        "error",
      );
      return;
    }

    const url = response.url;

    if (newWindow) {
      newWindow.location.assign(url);
    } else {
      window.location.assign(url);
    }
  } catch (error) {
    debugError("Error creating one-time payment: ", error);

    displayUiMessage("Error creating one-time payment. Please try again.", "error");
  }
}

export interface SubscribeToCustomOneTimePaymentArgs
  extends CreateCustomOneTimePaymentCheckoutSessionArgs {
  openNewPage?: boolean;
}

export async function subscribeToCustomOneTimePayment({
  openNewPage = false,
  ...args
}: SubscribeToCustomOneTimePaymentArgs) {
  try {
    const backend = editorContextStore.getState()?.backend;

    if (!backend) {
      return;
    }

    const newWindow = openNewPage && window.open("", "_blank");

    const response = await backend.createCustomOneTimePaymentCheckoutSession(args);

    if (!response.ok) {
      displayUiMessage(
        // @ts-expect-error
        `Error creating payment portal: ${response.message}`,
        "error",
      );
      return;
    }
    const url = response.url;
    if (newWindow) {
      newWindow.location.assign(url);
    } else {
      window.location.assign(url);
    }
  } catch (error) {
    debugError("Error creating custom one-time payment: ", error);

    displayUiMessage("Error creating custom one-time payment. Please try again.", "error");
  }
}

export async function openSubscriptionsLink(openNewPage = true) {
  return await editorContextStore
    .getState()
    .backend?.createSubscriptionsPortalLink({
      returnUrl: window.location.origin,
    })
    .then((url) => {
      if (!url) {
        console.error("Url is invalid");
        return;
      }
      const newWindow = openNewPage && window.open("", "_blank");
      if (newWindow) {
        newWindow.location.assign(url);
      } else {
        window.location.assign(url);
      }
    })
    .catch((error) => {
      console.error(error);

      displayUiMessage("Unknown error occurred. Please refresh the page.", "error");
    });
}

export async function openStripePortalLink({
  openNewPage = true,
  ...params
}: CreateStripePortalLinkParams & {
  openNewPage?: boolean;
} = {}) {
  try {
    const { backend } = editorContextStore.getState();

    if (!backend) {
      displayUiMessage("Backend not initialized. Please refresh the page.", "error");
      return;
    }

    const { url } = await backend.createStripePortalLink({
      return_url: window.location.origin,
      ...params,
    });

    if (!url) {
      displayUiMessage("Cannot open payment portal. Please refresh the page.", "error");
      return;
    }

    const newWindow = openNewPage && window.open("", "_blank");

    if (newWindow) {
      newWindow.location.assign(url);
    } else {
      window.location.assign(url);
    }
  } catch (error) {
    displayUiMessage(`Error opening payment portal: ${String(error)}`, "error");
  }
}
