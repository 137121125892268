export const CanvasFrameZIndex = 0;
export const GenerateSearchBarZIndex = 10;
export const CanvasScrollBarZIndex = 25;

export const FloatTagZIndex = 100;
export const LeftPanelFixedTopZIndex = 110;
export const BottomPanelZIndex = 115;

export const EditorCanvasModelZIndex = 120;

export const TryOnClothEditorZIndex = 125;

export const TryOnClothEditorPaintBrushZIndex = 128;
export const EditorLeftPanelTooltipZIndex = 130;

export const MessageDialogOverlayZIndex = 135;
export const MessageDialogZIndex = 136;
export const LeftPanelDropdownZIndex = 145;

export const AlertDialogOverlayZIndex = 150;
export const HighlighterZIndex = 200;
export const HelperWidgetZIndex = 300;
