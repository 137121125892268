import { Backend } from "@/backend/base";
import { CustomModelPostProcessActionType } from "@/backend/custom-model-post-process";
import {
  InputBoxClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
} from "@/components/constants/class-names";
import { usePaintMaskCanvasController } from "@/components/editor/paint-mask-canvas-controller-utils";
import { SimpleSpinner } from "@/components/icons/simple-spinner";
import { ScrollAreaContainer } from "@/components/scroll-area/scroll-area";
import { displayUiMessage } from "@/components/utils/display-message";
import { InfoBox } from "@/components/utils/info-box";
import { editorContextStore } from "@/contexts/editor-context";
import {
  customModelCaptionTrigger,
  CustomModelPredictionItem,
  CustomModelScaleConfig,
  CustomModelType,
  getCustomModelScaleConfigsFromCustomModelPredictionInput,
  getImageSizeFromCustomModelPredictionInput,
  isCustomModelPredictionInputFixDetails,
} from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { getNumWords } from "@/core/utils/string-utils";
import React from "react";
import {
  imageEditorContainerClassName,
  mainPanelClassName,
  toolbarPanelClassName,
} from "./classnames";
import { CustomModelImageEditorBackButton } from "./custom-model-editor-back-button";
import {
  CustomModelImageEditorMode,
  useCustomModelImageEditorActiveImageStoragePath,
  useCustomModelImageEditorActiveMode,
  useCustomModelImageEditorContext,
} from "./custom-model-image-editor-context";
import { CustomModelImageEditorInpaintCanvas } from "./custom-model-image-editor-inpaint";
import { useCustomModelCompareOutputContext } from "./custom-model-image-output-context";
import { CustomModelPromptEditor } from "./custom-model-prompt-editor";
import {
  CustomModelImageEditorRegenerateProductGenerateStatus,
  CustomModelImageEditorRegenerateProductInitStatus,
  CustomModelImageEditorRegenerateProductMaskStatus,
  useCustomModelImageEditorRegenerateProductContext,
} from "./custom-model-regenerate-product-context";

interface CustomModelRegenerateProductPrompts {
  shortPrompt?: string;
  fullPrompt?: string;
}

async function getCustomModelRegenerateProductPrompts({
  backend,
  caption,
  prediction,
}: {
  backend: Backend;
  caption: string;
  prediction: CustomModelPredictionItem;
}): Promise<CustomModelRegenerateProductPrompts> {
  try {
    const predictionInput = prediction.input;

    if (isCustomModelPredictionInputFixDetails(predictionInput)) {
      return {
        shortPrompt: predictionInput.shortCaption,
        fullPrompt: predictionInput.fullCaption,
      };
    }
    const captionCleaned = caption.replaceAll(customModelCaptionTrigger, "");

    const shortPrompt = await backend.shortenCaption({
      caption: captionCleaned,
    });
    debugLog(`Custom model fix product details short prompt ${captionCleaned} -> ${shortPrompt}`);

    return {
      shortPrompt,
      fullPrompt: predictionInput.prompt,
    };
  } catch (error) {
    debugError("Error getting custom model fix product details captions: ", error);
    return {};
  }
}

// Higher score means the model is more likely to be the main subject when using multiple models
const CustomModelTypeToSubjectScore: Record<CustomModelType, number> = {
  [CustomModelType.Product]: 1,
  [CustomModelType.Fashion]: 2,
  [CustomModelType.Style]: 0,
  [CustomModelType.Face]: 1,
  [CustomModelType.Custom]: 0.5,
  [CustomModelType.Furniture]: 2,
  [CustomModelType.Tech]: 1,
  [CustomModelType.Food]: 1,
  [CustomModelType.Vase]: 1,
  [CustomModelType.VirtualModel]: 1,
  [CustomModelType.Footwear]: 2,
  [CustomModelType.Jewelry]: 1,
  [CustomModelType.Bags]: 1,
  [CustomModelType.BrandA]: 1,
  [CustomModelType.BrandB]: 1,
};

interface CustomModelScaleConfigWithExtras extends CustomModelScaleConfig {
  caption?: string;
}

async function getSubjectModelScaleConfigFromPrediction({
  backend,
  prediction,
}: {
  backend: Backend;
  prediction: CustomModelPredictionItem;
}): Promise<CustomModelScaleConfigWithExtras | undefined> {
  try {
    const input = prediction.input;

    const scaleConfigs = getCustomModelScaleConfigsFromCustomModelPredictionInput(input);

    if (!scaleConfigs) {
      return undefined;
    }

    const configs = Object.values(scaleConfigs);

    if (configs.length <= 0) {
      return undefined;
    }

    if (configs.length === 1) {
      const config = configs[0];

      const { modelId, trainingId } = config;

      const trainingInfo = await backend.getCustomModelTraining({
        modelId,
        trainingId,
      });
      return {
        ...config,
        caption: trainingInfo?.caption ?? "",
      };
    }

    const configInfos = await Promise.all(
      configs.map(async (config) => {
        const { modelId, trainingId } = config;

        const [modelInfo, trainingInfo] = await Promise.all([
          backend.getCustomModelInfo(modelId),
          backend.getCustomModelTraining({
            modelId,
            trainingId,
          }),
        ]);

        const caption = trainingInfo?.caption ?? "";

        const customModelType = modelInfo?.customModelType ?? CustomModelType.Custom;
        const score = CustomModelTypeToSubjectScore[customModelType];

        return {
          ...config,
          customModelType,
          caption,
          score,
        };
      }),
    );

    configInfos.sort(({ score: scoreA }, { score: scoreB }) => {
      return scoreB - scoreA;
    });

    // Return the config with the highest score

    return configInfos[0];
  } catch (error) {
    debugError(
      `Error getting subject model from prediction: `,
      error,
      "\nPrediction: ",
      prediction,
    );
    return undefined;
  }
}

export function CustomModelImageEditorRegenerateProduct() {
  const backend = editorContextStore((state) => state.backend);

  const [localProductPrompt, setLocalProductPrompt] = React.useState("");
  const [productPromptMessage, setProductPromptMessage] = React.useState(
    "Describe the product in 1-2 words.",
  );

  const { prediction, imageUrl, setModes } = useCustomModelImageEditorContext();

  const activeMode = useCustomModelImageEditorActiveMode();

  const imageStoragePath = useCustomModelImageEditorActiveImageStoragePath();

  const {
    productPrompt,
    setProductPrompt,
    promptEditorState,
    setPromptEditorState,
    maskImageUrl,
    setMaskImageUrl,
    setProductModelScaleConfig,
    maskBrushSize,
    setMaskBrushSize,
    maskBrushType,
    setMaskBrushType,
    initStatus,
    setInitStatus,
    canvasHistorySnapshot,
    setCanvasHistorySnapshot,
  } = useCustomModelImageEditorRegenerateProductContext();

  const { setActiveOutputImageIndex, setSourceImageUrl, setOutputImageUrls, setWidth, setHeight } =
    useCustomModelCompareOutputContext();

  const [maskStatus, setMaskStatus] = React.useState(
    CustomModelImageEditorRegenerateProductMaskStatus.Idle,
  );
  const [generateStatus, setGenerateStatus] = React.useState(
    CustomModelImageEditorRegenerateProductGenerateStatus.Idle,
  );

  const { getCanvasMaskUrl, isMaskEmpty, exportHistory, importHistory, ...controllerProps } =
    usePaintMaskCanvasController({
      imageUrl,
      maskImageUrl,
      brushSize: maskBrushSize,
      brushType: maskBrushType,
      brushColor: "#84cc16",
    });

  const generateMaskImageFromPrompt = React.useCallback(
    ({ prompt, imageUrl }: { prompt: string; imageUrl: string }) => {
      if (!backend || !prompt || !imageUrl) {
        return;
      }

      setMaskStatus((maskStatus) => {
        if (maskStatus !== CustomModelImageEditorRegenerateProductMaskStatus.Idle) {
          return maskStatus;
        }

        prompt = prompt.trim();

        debugLog(`Get mask from prompt ${prompt} and image ${imageUrl}`);

        backend
          .customModelGetMaskFromPrompt({
            type: CustomModelPostProcessActionType.GetMaskFromPrompt,
            prompt,
            imageStoragePath: imageUrl,
          })
          .then((response) => {
            if (response.ok && response.maskImageUrl) {
              setMaskImageUrl(response.maskImageUrl);
            }
          })
          .finally(() => {
            setMaskStatus(CustomModelImageEditorRegenerateProductMaskStatus.Idle);
          });

        return CustomModelImageEditorRegenerateProductMaskStatus.Loading;
      });
    },
    [backend, setMaskImageUrl],
  );

  React.useEffect(() => {
    if (canvasHistorySnapshot?.actions?.length > 0) {
      importHistory(canvasHistorySnapshot);
    }
  }, [canvasHistorySnapshot, importHistory]);

  React.useEffect(() => {
    const initArgs = async () => {
      try {
        debugLog(`Custom model regenerate product init status: ${initStatus}`);

        if (initStatus !== CustomModelImageEditorRegenerateProductInitStatus.None) {
          return;
        }

        setInitStatus(CustomModelImageEditorRegenerateProductInitStatus.Initiailizing);

        setMaskImageUrl(undefined);

        setProductPrompt("");

        setProductModelScaleConfig(undefined);

        setProductPromptMessage("Describe the product in 1-2 words.");

        if (!backend) {
          displayUiMessage("Backend is not initialized. Please refresh the page.", "error");

          return;
        }

        const predictionInput = prediction?.input;

        debugLog("Init regenerate product args ", predictionInput);

        if (!predictionInput) {
          displayUiMessage("Please enter the product prompt.", "info");

          return;
        }

        const scaleConfigs = await getSubjectModelScaleConfigFromPrediction({
          backend,
          prediction,
        });

        if (!scaleConfigs) {
          return;
        }

        const { caption = "" } = scaleConfigs;

        setProductModelScaleConfig(scaleConfigs);

        if (!caption) {
          return;
        }

        const { shortPrompt = "" } = await getCustomModelRegenerateProductPrompts({
          backend,
          caption,
          prediction,
        });

        if (!shortPrompt) {
          displayUiMessage("Please enter the product prompt.", "info");

          return;
        }

        setProductPrompt(shortPrompt);

        setProductPromptMessage(" ");
      } catch (error) {
        debugError("Error initializing custom model fix product details args: ", error);

        displayUiMessage("Error initializing the arguments. Please enter them manually.", "error");

        setProductPromptMessage("Describe the product in 1-2 words.");
      } finally {
        setInitStatus(CustomModelImageEditorRegenerateProductInitStatus.Ready);
      }
    };

    initArgs();
    // eslint-disable-next-line
  }, [
    backend,
    prediction,
    // initStatus, // Do not make initStatus a dependency here otherwise we run into inifinte loop
    setInitStatus,
    setProductPrompt,
    setMaskImageUrl,
    setProductModelScaleConfig,
  ]);

  React.useEffect(() => {
    setLocalProductPrompt(productPrompt);

    const numWords = getNumWords(productPrompt);

    if (numWords > 2) {
      setProductPromptMessage("Product prompt should be 1-2 words.");
    } else if (numWords <= 0) {
      setProductPromptMessage("Product prompt should be 1-2 words.");
    }
  }, [productPrompt]);

  React.useEffect(() => {
    if (!imageStoragePath) {
      return;
    }

    generateMaskImageFromPrompt({
      prompt: productPrompt,
      imageUrl: imageStoragePath,
    });
  }, [productPrompt, imageStoragePath, generateMaskImageFromPrompt]);

  const handleClickFixProductEvent = async () => {
    if (!generateButtonEnabled || !backend || !imageStoragePath || !prediction) {
      return;
    }

    try {
      if (generateStatus !== CustomModelImageEditorRegenerateProductGenerateStatus.Idle) {
        return;
      }

      setOutputImageUrls([]);

      setGenerateStatus(CustomModelImageEditorRegenerateProductGenerateStatus.Loading);

      const { text, json, scaleConfigs } = promptEditorState;

      if (!text) {
        displayUiMessage("Please enter full prompt before fixing product details.", "info");
        return;
      }

      const canvasMaskImageUrl = getCanvasMaskUrl();

      const inputMaskImageUrl = canvasMaskImageUrl;

      const response = await backend.customModelFixProductDetails({
        type: CustomModelPostProcessActionType.FixProductDetails,
        inputImageUrl: imageStoragePath,
        inputMaskImageUrl,
        customModelScaleConfigs: scaleConfigs,
        shortPrompt: productPrompt,
        fullPrompt: text,
        extraPredictionArgs: {},
        promptJson: json,
      });

      if (!response.ok) {
        return;
      }
      const predictionId = response.prediction_id;

      if (!predictionId) {
        return;
      }

      const outputImagePaths = await new Promise<string[]>((resolve) => {
        backend.onCustomModelPredictionUpdate({
          predictionId,
          callback: (predictionItem) => {
            const output = predictionItem?.output;
            if (!output) {
              return;
            }

            resolve(output);
          },
        });
      });

      const imageUrls = (
        await Promise.all(
          outputImagePaths.map((path) => {
            return Assets.loadAssetFromPath({
              path,
              backend,
            });
          }),
        )
      ).filter(Boolean) as string[];

      if (imageUrls.length > 0) {
        const { width, height } = getImageSizeFromCustomModelPredictionInput(prediction.input);

        setSourceImageUrl(imageUrl);

        setOutputImageUrls(imageUrls);

        setActiveOutputImageIndex(0);

        setWidth(width);

        setHeight(height);

        setCanvasHistorySnapshot(exportHistory());

        setModes((modes) => [...modes, CustomModelImageEditorMode.CompareOutputs]);
      }
    } catch (error) {
      debugError("Error handling click fix product event: ", error);
    } finally {
      setGenerateStatus(CustomModelImageEditorRegenerateProductGenerateStatus.Idle);
    }
  };

  const { generateButtonEnabled, generateButtonMessage } = React.useMemo(() => {
    if (generateStatus !== CustomModelImageEditorRegenerateProductGenerateStatus.Idle) {
      return {
        generateButtonEnabled: false,
        generateButtonMessage: (
          <div className="flex flex-row items-center justify-center gap-2">
            <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
            <span className="flex-1 truncate">Fixing product details ...</span>
          </div>
        ),
      };
    }

    if (!imageUrl) {
      return {
        generateButtonEnabled: false,
        generateButtonMessage: "Image is invalid.",
      };
    }

    if (maskStatus === CustomModelImageEditorRegenerateProductMaskStatus.Loading) {
      return {
        generateButtonEnabled: false,
        generateButtonMessage: (
          <div className="flex flex-row items-center justify-center gap-2">
            <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
            <span className="flex-1 truncate">Loading product mask ...</span>
          </div>
        ),
      };
    }

    if (isMaskEmpty) {
      return {
        generateButtonEnabled: false,
        generateButtonMessage: "Product mask is empty",
      };
    }

    return {
      generateButtonEnabled: true,
      generateButtonMessage: "Regenerate product",
    };
  }, [maskStatus, generateStatus, imageUrl, isMaskEmpty]);

  return (
    <div className={imageEditorContainerClassName}>
      <div className={classNames(mainPanelClassName, "flex-1")}>
        <CustomModelImageEditorInpaintCanvas
          {...controllerProps}
          isMaskEmpty={isMaskEmpty}
          getCanvasMaskUrl={getCanvasMaskUrl}
          maskBrushSize={maskBrushSize}
          setMaskBrushSize={setMaskBrushSize}
          maskBrushType={maskBrushType}
          setMaskBrushType={setMaskBrushType}
          exportHistory={exportHistory}
          importHistory={importHistory}
        />
      </div>
      <ScrollAreaContainer className={classNames(toolbarPanelClassName)}>
        <div className="h-fit flex flex-col items-stretch gap-4">
          <CustomModelImageEditorBackButton
            onClick={() => {
              setCanvasHistorySnapshot(exportHistory());
            }}
          />
          <div className="flex flex-col gap-4">
            <InfoBox defaultValue="" className="w-full">
              <ol className="list-decimal list-outside pl-5 flex flex-col gap-2 text-lime-200 text-sm leading-relaxed">
                <li>Write a one-word product prompt and wait for the product mask to load.</li>
                <li>(Optional) Correct the product mask by painting on the canvas.</li>
                <li>
                  (Optional) Modify the full prompt to refine the result. For example, you can
                  describe the product in detail to achieve more accurate results; you can also tag
                  other custom models to replace the product.
                </li>
                <li>Click "Regenerate product" button and wait for the result.</li>
              </ol>
            </InfoBox>
            <div className="flex flex-col items-stretch gap-2">
              <div className="flex flex-row items-center gap-4">
                <span className="flex-1 font-semibold whitespace-nowrap">Product prompt</span>
                <span className="min-w-0 text-xs text-zinc-500 truncate">
                  Short prompt that identifies the product mask
                </span>
              </div>
              <input
                className={classNames(InputBoxClassName)}
                value={localProductPrompt}
                placeholder="Describe the product in 1-2 words."
                onChange={(e) => {
                  const value = e.currentTarget?.value ?? "";
                  setLocalProductPrompt(value);
                }}
                onBlur={(e) => {
                  const value = e.currentTarget?.value ?? "";
                  setProductPrompt(value);
                }}
              />
              <div className="min-h-[16px] truncate text-xs text-zinc-500">
                {productPromptMessage}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="h-4 mb-2 flex flex-col md:flex-row items-center gap-4">
                <span className="flex-1 font-semibold whitespace-nowrap">Full prompt</span>
                <span className="min-w-0 text-xs text-zinc-500 truncate">
                  Long prompt that describes the output images
                </span>
              </div>
              <CustomModelPromptEditor
                editorState={promptEditorState}
                setEditorState={setPromptEditorState}
              />
            </div>
          </div>
          <button
            className={classNames(
              generateButtonEnabled ? PrimaryButtonClassName : PrimaryButtonClassNameDisabled,
              "flex flex-row items-center justify-center mt-4",
              generateButtonEnabled ? "pointer-events-auto" : "pointer-events-none",
            )}
            onClick={() => {
              handleClickFixProductEvent();
            }}
          >
            {generateButtonMessage}
          </button>
        </div>
      </ScrollAreaContainer>
    </div>
  );
}
