import { classNames } from "@/core/utils/classname-utils";
import { debugError } from "@/core/utils/print-utilts";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as Dialog from "@radix-ui/react-dialog";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  SecondaryButtonClassName,
  SecondaryButtonClassNameDisabled,
} from "components/constants/class-names";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { downloadUrl } from "components/utils/data";
import { displayUiMessage } from "components/utils/display-message";
import { ImageComponent } from "components/utils/image";
import { useComponentSize } from "hooks/use-component-size";
import { Download } from "lucide-react";
import React from "react";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import {
  imageEditorContainerClassName,
  mainPanelClassName,
  toolbarPanelClassName,
} from "./classnames";
import { CustomModelImageEditorBackButton } from "./custom-model-editor-back-button";
import { CustomModelImageEditorDialogTrigger } from "./custom-model-image-editor";
import {
  CustomModelImageEditorMode,
  useCustomModelImageEditorContext,
  useSetCustomModelImageEditorActiveModeCallback,
} from "./custom-model-image-editor-context";
import { useCustomModelCompareOutputContext } from "./custom-model-image-output-context";

export function CustomModelCompareOutput() {
  const {
    width,
    height,
    sourceImageUrl,
    outputImageUrls,
    activeOutputImageIndex,
    setActiveOutputImageIndex,
  } = useCustomModelCompareOutputContext();

  const { prediction, setPrediction, setWidth, setHeight, setImageIndex, setImageUrl } =
    useCustomModelImageEditorContext();

  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  const activeImageUrl = React.useMemo(() => {
    if (!outputImageUrls) {
      return undefined;
    }
    return outputImageUrls[activeOutputImageIndex % outputImageUrls.length];
  }, [outputImageUrls, activeOutputImageIndex]);

  const handleOpenImageEditor = React.useCallback(() => {
    setPrediction(prediction);
    setWidth(width);
    setHeight(height);
    setMode(CustomModelImageEditorMode.Default);
    setImageIndex(activeOutputImageIndex);
    setImageUrl(activeImageUrl);
  }, [
    setPrediction,
    setWidth,
    setHeight,
    setMode,
    setImageIndex,
    setImageUrl,
    width,
    height,
    activeOutputImageIndex,
    prediction,
    activeImageUrl,
  ]);

  const originalPosition = sourceImageUrl ? 50 : 0;
  const resultPosition = activeImageUrl ? -80 : 0;
  const position = 50 + originalPosition + resultPosition;

  const aspectRatio = React.useMemo(() => {
    return width / (height ?? 1);
  }, [width, height]);

  const [imageContainerSize, imageContainerRef] = useComponentSize<HTMLDivElement>();

  const [imageWidth, imageHeight] = React.useMemo(() => {
    const widthFromHeight = aspectRatio * imageContainerSize.height;
    const heightFromWidth = imageContainerSize.width / aspectRatio;

    if (widthFromHeight <= imageContainerSize.width) {
      return [widthFromHeight, imageContainerSize.height];
    }

    return [imageContainerSize.width, heightFromWidth];
  }, [aspectRatio, imageContainerSize]);

  return (
    <div className={imageEditorContainerClassName}>
      <div
        ref={imageContainerRef}
        className={classNames(
          mainPanelClassName,
          "w-full h-fit md:w-[75%] lg:w-[70%] md:h-full md:max-h-none",
          "flex flex-row items-stretch justify-center",
        )}
      >
        <div
          style={{
            width: imageWidth,
            height: imageHeight,
          }}
        >
          <AspectRatio.Root ratio={aspectRatio}>
            <ReactCompareSlider
              className="w-full h-full rounded-md overflow-hidden"
              transition=".5s ease-in-out"
              position={position}
              disabled={!sourceImageUrl || outputImageUrls.length <= 0 || !activeImageUrl}
              itemOne={
                <div className="relative flex w-full h-full">
                  <ReactCompareSliderImage
                    src={sourceImageUrl}
                    onError={(error) => {
                      debugError(`Error loading source image url ${sourceImageUrl}: `, error);
                    }}
                  />
                </div>
              }
              itemTwo={
                <div className="relative flex w-full h-full">
                  <ReactCompareSliderImage src={activeImageUrl} />
                </div>
              }
            />
          </AspectRatio.Root>
        </div>
      </div>
      <ScrollAreaContainer className={classNames(toolbarPanelClassName)}>
        <div className="h-fit flex flex-col items-stretch gap-4">
          <CustomModelImageEditorBackButton />
          <div className="flex flex-col items-stretch gap-8">
            <div className="flex flex-col gap-2">
              <div className="h-4 mb-2 flex flex-row items-center justify-start gap-4">
                <span className="flex-1 font-semibold whitespace-nowrap">Output images</span>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 gap-2">
                {outputImageUrls.map((imageUrl, index) => {
                  return (
                    <AspectRatio.Root
                      key={index}
                      ratio={aspectRatio}
                      className={classNames(
                        "border transition-colors rounded-md overflow-hidden",
                        index === activeOutputImageIndex
                          ? "border-lime-500"
                          : "border-zinc-800 hover:border-lime-700 cursor-pointer",
                      )}
                      onClick={() => {
                        setActiveOutputImageIndex(index);
                      }}
                    >
                      <ImageComponent src={imageUrl} />
                    </AspectRatio.Root>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <button
                className={classNames(
                  activeImageUrl ? PrimaryButtonClassName : PrimaryButtonClassNameDisabled,
                  "flex flex-row items-center justify-center gap-2",
                )}
                onClick={() => {
                  if (!activeImageUrl) {
                    return;
                  }

                  downloadUrl(activeImageUrl, `fixed-image-${activeOutputImageIndex}`);
                }}
              >
                <Download size={16} />
                <span>Download</span>
              </button>
              <Dialog.Close asChild>
                <CustomModelImageEditorDialogTrigger>
                  <div
                    className={classNames(
                      activeImageUrl ? SecondaryButtonClassName : SecondaryButtonClassNameDisabled,
                      "justify-center w-full box-border",
                    )}
                    onClick={() => {
                      handleOpenImageEditor();
                      displayUiMessage("Image updated successfully", "info", "Saved image");
                    }}
                  >
                    Save and continue editing
                  </div>
                </CustomModelImageEditorDialogTrigger>
              </Dialog.Close>
            </div>
          </div>
        </div>
      </ScrollAreaContainer>
    </div>
  );
}
