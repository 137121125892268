// src/components/checkout/checkout-session.tsx

import {
  CheckoutSessionTypeMeteredSusbcription,
  CheckoutSessionTypeOneTimePayment,
  CheckoutSessionTypeSusbcription,
} from "@/core/common/checkout-session";
import { StripeCheckoutSessionLineItem } from "@/core/common/types/stripe";
import { debugLog } from "@/core/utils/print-utilts";
import { RequireAuth } from "components/auth/require-auth";
import { SimpleSpinner } from "components/icons/simple-spinner";
import {
  SubscribeToCustomOneTimePaymentArgs,
  subscribeToCustomOneTimePayment,
  subscribeToPlanInternal,
} from "components/subscription/checkout-portal";
import { displayUiMessage } from "components/utils/display-message";
import { editorContextStore } from "contexts/editor-context";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface SubscriptionParams {
  type: CheckoutSessionTypeSusbcription;
  priceId: string;
  coupon?: string;
}

interface MeteredSubscriptionParams {
  type: CheckoutSessionTypeMeteredSusbcription;
  priceId: string;
  coupon?: string;
}

interface OneTimePaymentParams extends SubscribeToCustomOneTimePaymentArgs {
  type: CheckoutSessionTypeOneTimePayment;
}

type CheckoutParams = SubscriptionParams | OneTimePaymentParams | MeteredSubscriptionParams;

// Utility function to parse and validate search params with verbose logging
const parseSearchParams = (search: string): CheckoutParams | null => {
  debugLog("parseSearchParams: Received search string:", search);
  const params = new URLSearchParams(search);

  const type = params.get("type") as CheckoutSessionTypeSusbcription;
  debugLog("parseSearchParams: Retrieved type:", type);

  if (!type) {
    console.warn("parseSearchParams: Type is missing or invalid.");
    return null;
  }

  if (type === "subscription") {
    debugLog("parseSearchParams: Handling subscription type.");
    const coupon = params.get("coupon") || undefined;
    const priceId = params.get("priceId");
    debugLog("parseSearchParams: Retrieved coupon:", coupon);
    debugLog("parseSearchParams: Retrieved priceId:", priceId);

    if (!priceId || priceId.trim() === "") {
      console.warn("parseSearchParams: priceId is missing or empty.");
      return null;
    }

    debugLog("parseSearchParams: Successfully parsed subscription parameters.");
    return {
      type: "subscription",
      priceId,
      coupon,
    };
  } else if (type === "one-time") {
    debugLog("parseSearchParams: Handling one-time type.");
    const priceId = params.get("priceId");
    const quantityStr = params.get("quantity");
    const quantity = quantityStr ? parseInt(quantityStr, 10) : NaN;
    debugLog("parseSearchParams: Retrieved priceId:", priceId);
    debugLog("parseSearchParams: Retrieved quantityStr:", quantityStr);
    debugLog("parseSearchParams: Parsed quantity:", quantity);

    if (isNaN(quantity) || quantity <= 0) {
      console.warn("parseSearchParams: Quantity is missing, invalid, or less than 1.");
      return null;
    }

    const openNewPageStr = params.get("openNewPage");
    const openNewPage = openNewPageStr === "true";
    debugLog("parseSearchParams: Retrieved openNewPageStr:", openNewPageStr);
    debugLog("parseSearchParams: Parsed openNewPage:", openNewPage);

    debugLog("parseSearchParams: Successfully parsed one-time parameters.");
    return {
      type: "one-time",
      priceId,
      quantity,
      openNewPage,
    };
  } else if (type == "metered-subscription") {
    debugLog("parseSearchParams: Handling subscription type.");
    const coupon = params.get("coupon") || undefined;
    const priceId = params.get("priceId");
    debugLog("parseSearchParams: Retrieved coupon:", coupon);
    debugLog("parseSearchParams: Retrieved priceId:", priceId);

    if (!priceId || priceId.trim() === "") {
      console.warn("parseSearchParams: priceId is missing or empty.");
      return null;
    }

    debugLog("parseSearchParams: Successfully parsed subscription parameters.");
    return {
      type: "metered-subscription",
      priceId,
      coupon,
    };
  }

  console.warn("parseSearchParams: Invalid type provided:", type);
  return null;
};

function getCheckoutSessionRedirectUrls() {
  return {
    success_url: `${window.location.origin}/?checkoutSessionSuccess=true`,
    cancel_url: `${window.location.origin}/?checkoutSessionCanceled=true`,
  };
}

type StatusType = "loading" | "error" | "success" | "info";

interface StatusMessage {
  status: StatusType;
  message: string;
}

function NewCheckoutSessionInternal() {
  const navigate = useNavigate();
  const location = useLocation();

  const [statusMessage, setStatusMessage] = useState<StatusMessage>({
    status: "loading",
    message: "Redirecting to checkout portal...",
  });

  useEffect(() => {
    const initiateCheckout = async () => {
      setStatusMessage({ status: "loading", message: "Initiating checkout..." });

      const checkoutParams = parseSearchParams(location.search);
      if (!checkoutParams) {
        setStatusMessage({ status: "error", message: "Invalid checkout parameters." });
        displayUiMessage("Invalid checkout parameters.", "error");
        setTimeout(() => navigate("/"), 50);
        return;
      }

      const { success_url, cancel_url } = getCheckoutSessionRedirectUrls();

      const userStripeSubscriptions = editorContextStore.getState().userStripeSubscriptions;

      if (
        checkoutParams.type === "subscription" ||
        checkoutParams.type === "metered-subscription"
      ) {
        const { priceId, coupon } = checkoutParams;

        setStatusMessage({ status: "loading", message: "Checking subscription status..." });

        // Check if user is already subscribed to this priceId
        const isAlreadySubscribed = userStripeSubscriptions.some((subscription) =>
          subscription.items.some((item) => item.price.id === priceId),
        );

        if (isAlreadySubscribed) {
          setStatusMessage({
            status: "info",
            message: "You are already subscribed to the selected plan.",
          });
          displayUiMessage("You are already subscribed to the product.", "info");
          setTimeout(() => navigate("/"), 50);
          return;
        }

        // Prepare discounts if coupon is provided
        const discounts = coupon ? [{ coupon }] : undefined;

        setStatusMessage({ status: "loading", message: "Creating subscription session..." });

        try {
          const lineItem: StripeCheckoutSessionLineItem =
            checkoutParams.type === "metered-subscription"
              ? {
                  price: priceId,
                }
              : {
                  price: priceId,
                  quantity: 1,
                };
          // Create subscription checkout session
          await subscribeToPlanInternal({
            line_items: [lineItem],
            mode: "subscription",
            success_url,
            cancel_url,
            allow_promotion_codes: !!coupon, // Enable promotion codes if coupon is provided
            discounts, // Apply discounts if any
          });

          setStatusMessage({
            status: "success",
            message: "Subscription session created successfully.",
          });
        } catch (error) {
          console.error(error);
          setStatusMessage({ status: "error", message: "Failed to create subscription session." });
          displayUiMessage("Failed to create subscription session.", "error");
          setTimeout(() => navigate("/"), 50);
        }
      } else if (checkoutParams.type === "one-time") {
        const { priceId, quantity, openNewPage = false } = checkoutParams;

        setStatusMessage({ status: "loading", message: "Creating one-time payment session..." });

        // Create one-time payment checkout session
        try {
          await subscribeToCustomOneTimePayment({
            priceId,
            quantity,
            cancelUrl: cancel_url,
            successUrl: success_url,
            openNewPage,
          });

          setStatusMessage({
            status: "success",
            message: "One-time payment session created successfully. Redirecting...",
          });
        } catch (error) {
          console.error(error);
          setStatusMessage({
            status: "error",
            message: "Failed to create one-time payment session.",
          });
          displayUiMessage("Failed to create one-time payment session.", "error");
          setTimeout(() => navigate("/"), 50);
        }
      } else {
        // Invalid type
        setStatusMessage({ status: "error", message: "Invalid checkout type." });
        displayUiMessage("Invalid checkout parameters.", "error");
        setTimeout(() => navigate("/"), 50);
      }
    };

    initiateCheckout();
  }, [location.search, navigate]);

  // Determine message color based on status
  const getMessageColor = () => {
    switch (statusMessage.status) {
      case "loading":
        return "text-zinc-500";
      case "error":
        return "text-red-500";
      default:
        return "text-zinc-500";
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center text-base bg-zinc-900">
      <div className="flex flex-col items-center justify-center gap-4">
        {/* Conditionally render the spinner only when loading */}
        {statusMessage.status === "loading" && (
          <div className="flex flex-row items-center justify-center gap-2">
            <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
            <span className={getMessageColor()}>{statusMessage.message}</span>
          </div>
        )}

        {/* Always display the message, even if not loading */}
        {statusMessage.status !== "loading" && (
          <div className="flex flex-col items-center justify-center gap-2">
            <span className={getMessageColor()}>{statusMessage.message}</span>
          </div>
        )}

        {/* Conditionally render buttons or additional messages based on status */}
        {statusMessage.status === "error" && (
          <button
            onClick={() => navigate("/")}
            className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
          >
            Go Back
          </button>
        )}
        {statusMessage.status === "info" && (
          <button
            onClick={() => navigate("/")}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
          >
            Go Back
          </button>
        )}
      </div>
    </div>
  );
}

export function NewCheckoutSession() {
  return (
    <RequireAuth>
      <NewCheckoutSessionInternal />
    </RequireAuth>
  );
}
