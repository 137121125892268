import { Backend } from "@/backend/base";
import {
  CancelUpscaleV2Job,
  RemoveObjectLoadingCover,
  SetObjectLoadingCover,
  UiDisplayMessageDialogEventHandler,
} from "@/core/common/types";
import {
  ColorCorrectV2Args,
  ColorCorrectV2JobStatus,
  ColorCorrectV2ResponseStatus,
  ColorCorrectV2Stage,
  ColorCorrectV2StageToMessage,
  ColorCorrectV2StartingPointImageType,
  getLatestIntermediateResult,
} from "@/core/common/types/color-correct-v2";
import { Editor } from "@/core/editor";
import { getDataUrlFromString } from "@/core/utils/asset-utils";
import { isColorCorrectV2QuotasAvaialable } from "@/core/utils/color-correct-v2-utils";
import { getRawDataUrlFromImageObject } from "@/core/utils/image-utils";
import { rescaleNumber, roundToNearestNumber } from "@/core/utils/number-utils";
import { getMaskImageFromPastGeneration } from "@/core/utils/past-generation-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { WebRenderProcessController } from "components/utils/render-process-controller";
import { fabric } from "fabric";
import { createObjectEditImageProgressController } from "./edit-image-process";

interface OnStageUpdateArgs {
  currentStoragePath: string;
  currentStage: ColorCorrectV2Stage;
  prevStage?: ColorCorrectV2Stage;
  nextStage?: ColorCorrectV2Stage;
}

async function handleJobStateUpdate({
  editor,
  backend,
  uid,
  jobId,
  stagesToRun,
  onStageUpdate,
}: {
  editor: Editor;
  backend: Backend;
  uid: string;
  jobId: string;
  stagesToRun: ColorCorrectV2Stage[];
  onStageUpdate: (args: OnStageUpdateArgs) => void;
}) {
  return new Promise<void>((resolve, reject) => {
    if (stagesToRun.length <= 0) {
      resolve();
      return;
    }

    const prevStageRef: {
      current?: ColorCorrectV2Stage;
    } = {
      current: undefined,
    };

    if (stagesToRun[stagesToRun.length - 1] !== ColorCorrectV2Stage.Final) {
      stagesToRun = [...stagesToRun, ColorCorrectV2Stage.Final];
    }

    const unsubscribeColorCorrectV2Update = backend.onColorCorrectV2Update({
      uid,
      jobId,
      onUpdate: async (renderJobDoc) => {
        if (!renderJobDoc) {
          reject("Document is invalid");
          return;
        }

        debugLog(`Handle render job ${jobId} doc update:\n`, renderJobDoc);

        try {
          const prevStage = prevStageRef.current;

          const intermediateResults = renderJobDoc.intermediate_results;

          if (!intermediateResults) {
            return;
          }

          const latestResult = getLatestIntermediateResult({
            intermediateResults,
            stagesToRun,
          });

          if (!latestResult) {
            debugError(`Render job ${jobId} doc has no valid lastest result.`);
            return;
          }

          const [currentStage, currentUrl] = latestResult;

          const currentStageIndex = stagesToRun.findIndex((stage) => stage === currentStage) ?? 0;

          const nextStage = stagesToRun?.[currentStageIndex + 1];

          // Replace image url

          const currentStoragePath = backend.cleanupStoragePathURL(currentUrl);

          debugLog(
            `Current stage ${currentStage}; Current path: ${currentStoragePath}; Next stage: ${nextStage}`,
          );

          onStageUpdate({
            currentStage,
            currentStoragePath,
            prevStage,
            nextStage,
          });

          prevStageRef.current = currentStage;
        } catch (error) {
          debugError(error);
        } finally {
          if (renderJobDoc.status !== ColorCorrectV2JobStatus.Active) {
            debugLog(`Color correct v2 job ${jobId} is completed.`);
            unsubscribeColorCorrectV2Update?.();
            resolve();
          }
        }
      },
    });

    editor.on<CancelUpscaleV2Job>("upscale-v2:cancel-job", ({ jobId: inputJobId }) => {
      if (inputJobId !== jobId) {
        return;
      }

      debugLog(`Color correct v2 job ${jobId} is cancelled.`);
      unsubscribeColorCorrectV2Update?.();
      resolve();
    });
  });
}

function getUpscaleStagesFromCreativity(creativity: number): ColorCorrectV2Stage[] {
  if (creativity >= 0.5) {
    return [ColorCorrectV2Stage.Clarity, ColorCorrectV2Stage.PCT, ColorCorrectV2Stage.SmoothBlend];
  } else if (creativity >= 0.3) {
    return [ColorCorrectV2Stage.Clarity, ColorCorrectV2Stage.SmoothBlend];
  } else {
    return [ColorCorrectV2Stage.Clarity, ColorCorrectV2Stage.SmoothBlend];
  }
}

function rescaleCreativity({
  creativity,
  min,
  max,
}: {
  creativity: number;
  min: number;
  max: number;
}) {
  return roundToNearestNumber(
    rescaleNumber({
      value: creativity,
      sourceMin: 0,
      sourceMax: 1,
      targetMin: min,
      targetMax: max,
    }),
    1,
  );
}

function getColorCorrectV2ArgsFromCreativity(creativity: number) {
  const skipRepasteProduct = creativity > 0.8 || creativity < 0.3;
  return {
    clarity_upscale_num_inference_steps: 25,
    clarity_upscale_creativity: rescaleCreativity({
      creativity,
      min: 0.2,
      max: 0.5,
    }),
    clarity_skip_repaste_product: skipRepasteProduct,
    post_background_paste_back_product_image: !skipRepasteProduct,
    smoothing_strength: 0.1,
    smoothing_blend_strength: rescaleCreativity({
      creativity,
      min: 0.8,
      max: 1.0,
    }),
    starting_point_image_type: ColorCorrectV2StartingPointImageType.InitialRenderImage,
  };
}

/**
 *
 * sigh.... this is a mess. originally the fucntion upscaleCreativeImageObjectV2 wchih was  for upscaling an object on the canvas and displaying the intermediate results and now we gotta display only the final result because this is for fashion and we only want the final. long-term we should seriously consider refactoring this, copying this is such a mess. but we got bigger things to worry about right now so here we are. good luck and godspeed to you.  - Leon aug 20 2024.
 * @returns
 */
export async function upscaleCreativeImageForFashionV2({
  editor,
  backend,
  prompt,
  renderProcessController,
  creativity = 0.2,
  imageUrl,
  maskImageUrl,
  stagesToRun = [ColorCorrectV2Stage.Clarity],
  targetWidth,
  targetHeight,
  upscaleFactor = 2,
  product_mask_channel = "A",
}: {
  editor: Editor;
  backend: Backend;
  prompt: string;
  imageUrl: string;
  maskImageUrl?: string;
  inputLength?: number;
  targetLength?: number;
  downscaleResolution?: number;
  renderProcessController: WebRenderProcessController;
  creativity?: number;
  stagesToRun?: ColorCorrectV2Stage[];
  targetWidth: number;
  targetHeight: number;
  upscaleFactor?: number;
  product_mask_channel?: "R" | "G" | "B" | "A";
}): Promise<string | null> {
  try {
    const { user } = editor.state;

    const uid = user?.uid;

    if (!uid) {
      return null;
    }

    const colorCorrectV2Args: ColorCorrectV2Args = {
      ...getColorCorrectV2ArgsFromCreativity(creativity),
      composite_image: maskImageUrl,
      initial_render_image: imageUrl,
      product_mask_channel,
      prompt,
      negative_prompt: "blurry, ugly, chaotic, fake, painting, drawing",
      gpu_stages_to_run: stagesToRun,
      width: targetWidth,
      height: targetHeight,
      clarity_upscale_downscaling: true,
      clarity_upscale_scale_factor: upscaleFactor,
      return_without_smoothing: false,
      clarity_upscale_seed: Math.round(Math.random() * 10000),
      post_background_paste_back_product_image: true,
    };

    debugLog(`Upscale v2 args:\n`, colorCorrectV2Args);

    const {
      status,
      job_id: jobId,
      message,
    } = await backend.startColorCorrectV2({
      ...colorCorrectV2Args,
      renderProcessController,
    });

    if (
      !jobId ||
      status !== ColorCorrectV2ResponseStatus.Rendering ||
      renderProcessController.isCancelled()
    ) {
      debugError(
        `Color correct job ${jobId} status ${status} is not rendering; message: `,
        message,
      );

      await renderProcessController.cancelJob();

      return null;
    }

    const currentStoragePathRef = { currrent: "" };

    await handleJobStateUpdate({
      editor,
      backend,
      uid,
      jobId,
      stagesToRun,
      onStageUpdate: async (args: OnStageUpdateArgs) => {
        debugLog(`Handle color correct v2 job update args: `, args);

        currentStoragePathRef.currrent = args.currentStoragePath;
      },
    });

    debugLog("Upscale image output storage path: ", currentStoragePathRef.currrent);

    const outputImageUrl = currentStoragePathRef.currrent
      ? await editor.assets.loadAsset({
          path: currentStoragePathRef.currrent,
        })
      : null;

    return outputImageUrl ?? null;
  } catch (error) {
    debugError("Cannot upscale creative image v2: ", error);
  } finally {
    await renderProcessController.cancelJob();
  }
  return null;
}

export async function upscaleCreativeImageObjectV2({
  editor,
  object,
  backend,
  inputLength = 1024,
  targetLength = 2048,
  downscaleResolution = 768,
  renderProcessController,
  creativity = 0.5,
}: {
  editor: Editor;
  backend: Backend;
  object: fabric.StaticImage;
  inputLength?: number;
  targetLength?: number;
  downscaleResolution?: number;
  renderProcessController: WebRenderProcessController;
  creativity?: number;
}) {
  try {
    const { user, userQuotas, pastGenerations } = editor.state;

    const uid = user?.uid;

    if (!uid) {
      return;
    }

    if (!isColorCorrectV2QuotasAvaialable(userQuotas)) {
      debugLog(`User cannot access color correct v2 feature.\n`, userQuotas);
      return;
    }

    const generationId = object.generationId;

    const objectIntrinsicWidth = object.width || inputLength;
    const objectIntrinsicHeight = object.height || inputLength;
    const objectScale = inputLength / Math.max(objectIntrinsicWidth, objectIntrinsicHeight);
    const inputWidth = Math.round(objectScale * objectIntrinsicWidth);
    const inputHeight = Math.round(objectScale * objectIntrinsicHeight);

    const targetScale = targetLength / Math.max(objectIntrinsicWidth, objectIntrinsicHeight);
    const targetWidth = Math.round(targetScale * objectIntrinsicWidth);
    const targetHeight = Math.round(targetScale * objectIntrinsicHeight);

    const imageUrl = await getRawDataUrlFromImageObject({
      object,
      width: inputWidth,
      height: inputHeight,
    });

    if (!imageUrl) {
      debugError(`Cannot get raw image from object ${object.id}`);
      return;
    }

    if (renderProcessController.isCancelled()) {
      debugLog("Upscale job is already cancelled");
      return;
    }

    const pastGeneration = generationId ? pastGenerations[generationId] : undefined;

    const prompt = pastGeneration
      ? pastGeneration.prompt
      : object.metadata?.subject ||
        (await backend.getImageCaption({
          imageUrl,
        })) ||
        "";

    if (renderProcessController.isCancelled()) {
      debugLog("Upscale job is already cancelled");
      return;
    }

    const compositeImage = pastGeneration
      ? await getMaskImageFromPastGeneration({
          editor,
          backend,
          pastGeneration,
          targetWidth,
          targetHeight,
        })
      : undefined;

    if (renderProcessController.isCancelled()) {
      debugLog("Upscale job is already cancelled");
      return;
    }

    const upscaleFactor = Math.max(targetWidth, targetHeight) / downscaleResolution;

    const stagesToRun = getUpscaleStagesFromCreativity(creativity);

    // Deuplicate the image object and add it to the right

    const { left, top } = object;

    const objectWidth = object.getScaledWidth();

    const duplicatedObjects = await new Promise<fabric.Object[]>((resolve) =>
      editor.objects.duplicate(
        object as any as fabric.Object,
        undefined,
        resolve,
        (outputObject, index = 0) => {
          outputObject.left = left! + objectWidth * (index + 1);
          outputObject.top = top!;
          createObjectEditImageProgressController({
            type: "generic-loading",
            object: outputObject as any as fabric.StaticImage,
            waitUntilFinish: () =>
              new Promise((resolve) => renderProcessController.setCancelJobCallback(resolve)),
          });
        },
      ),
    );

    if (renderProcessController.isCancelled()) {
      debugLog("Upscale job is already cancelled");
      return;
    }

    if (duplicatedObjects.length <= 0) {
      debugError(`Cannot duplicate input image object ${object.id}`);
      return;
    }

    const outputObject = duplicatedObjects[0] as any as fabric.StaticImage;

    if (!outputObject || !isStaticImageObject(outputObject)) {
      debugError("The output image object is invalid.");
      return;
    }

    editor.emit<SetObjectLoadingCover>("object:set-object-loading-cover", {
      objectId: outputObject.id,
      message: "Running upscale ...",
      callback: () =>
        new Promise((resolve) => renderProcessController.setCancelJobCallback(resolve)),
    });

    renderProcessController.setCancelJobCallback(() => {
      editor.emit<RemoveObjectLoadingCover>("object:remove-object-loading-cover", {
        objectId: outputObject.id,
      });
    });

    const colorCorrectV2Args: ColorCorrectV2Args = {
      ...getColorCorrectV2ArgsFromCreativity(creativity),
      composite_image: compositeImage,
      initial_render_image: imageUrl,
      prompt,
      negative_prompt: "blurry, ugly, chaotic, fake, painting, drawing",
      gpu_stages_to_run: stagesToRun,
      width: targetWidth,
      height: targetHeight,
      clarity_upscale_downscaling: true,
      clarity_upscale_scale_factor: upscaleFactor,
      return_without_smoothing: false,
      clarity_upscale_seed: Math.round(Math.random() * 10000),
      post_background_paste_back_product_image: false,
    };

    debugLog(`Upscale v2 args:\n`, colorCorrectV2Args);

    debugLog(`Upscale v2 creativity:\n`, creativity);

    const {
      status,
      job_id: jobId,
      message,
    } = await backend.startColorCorrectV2({
      ...colorCorrectV2Args,
      renderProcessController,
    });

    if (
      !jobId ||
      status !== ColorCorrectV2ResponseStatus.Rendering ||
      renderProcessController.isCancelled()
    ) {
      debugError(
        `Color correct job ${jobId} status ${status} is not rendering; message: `,
        message,
      );

      await renderProcessController.cancelJob();

      editor?.emit<UiDisplayMessageDialogEventHandler>(
        "ui:display-message-dialog",
        "quota-subscribe",
        {
          title: "You have no upscale quota left.",
          header: "Subscribe to get unlimited upscale quotas.",
        },
      );

      return;
    }

    const stageUpdatePromises: Promise<void>[] = [];
    try {
      debugLog("Set timeout to loading cover");

      const handleStageUpdate = async ({
        nextStage,
        currentStage,
        currentStoragePath,
      }: OnStageUpdateArgs) => {
        const imageUrl = await editor.assets.loadAsset({
          path: currentStoragePath,
          type: "image-storage",
        });

        if (!imageUrl) {
          debugError(`Image ${imageUrl} from path ${currentStoragePath} is invalid.`);
          return;
        }

        const imageDataUrl = await getDataUrlFromString(imageUrl);
        if (!imageDataUrl) {
          debugError(`Cannot load image from url ${imageUrl}`);
          return;
        }

        const message =
          (nextStage && ColorCorrectV2StageToMessage[nextStage]) ||
          ColorCorrectV2StageToMessage[currentStage] ||
          "Loading";

        await new Promise<void>((resolve) =>
          outputObject.setSrc(imageDataUrl, () => {
            const scale = objectWidth / targetWidth;

            outputObject.scaleX = scale;
            outputObject.scaleY = scale;

            editor.assets.setObjectAsset(outputObject.id, {
              path: currentStoragePath,
              type: "image-storage",
            });

            editor.emit<SetObjectLoadingCover>("object:set-object-loading-cover", {
              objectId: outputObject.id,
              message,
            });

            editor.canvas.requestRenderAll();

            resolve();
          }),
        );
      };

      await handleJobStateUpdate({
        editor,
        backend,
        uid,
        jobId,
        stagesToRun,
        onStageUpdate: async (args: OnStageUpdateArgs) => {
          stageUpdatePromises.push(handleStageUpdate(args));
        },
      });
    } catch (error) {
      debugError(error);
    } finally {
      await Promise.all(stageUpdatePromises);

      await renderProcessController.cancelJob();
    }
  } catch (error) {
    console.error(error);
  }
}

// export async function upscaleImageObjectV2() {

// }
