import React from "react";
import { editorContextStore } from "contexts/editor-context";
import { UserProjectType, UsedUserProjectType } from "@/core/common/types";
import { LEFT_PANELS_LIST_ITEMS, LeftPanelItemType } from "components/constants/editor-options";
import { useLeftPanelState } from "hooks/use-left-panel-state";
import { DEFAULT_ICON, PANELS_LIST_ICONS } from "../panels-list-icons";
import { classNames } from "@/core/utils/classname-utils";
import { EditorActiveObject } from "@/core/common/interfaces";
import { isEditLeftPanelDisabled } from "components/panels/panel-items/edit/edit";

const StandardProjectPanelList: LeftPanelItemType[] = ["Assets", "Generate", "Elements"];

export const projectLeftPanelListItems: Record<UsedUserProjectType, LeftPanelItemType[]> = {
  [UserProjectType.ProductPhotography]: StandardProjectPanelList,
  [UserProjectType.Accessories]: StandardProjectPanelList,
  [UserProjectType.Beauty]: StandardProjectPanelList,
  [UserProjectType.CPG]: StandardProjectPanelList,
  [UserProjectType.Food]: StandardProjectPanelList,
  [UserProjectType.Furniture]: StandardProjectPanelList,
  [UserProjectType.Homegoods]: StandardProjectPanelList,
  [UserProjectType.Staging]: StandardProjectPanelList,
  [UserProjectType.Humans]: StandardProjectPanelList,
  [UserProjectType.TryOn]: ["Assets", "Generate", "Elements"],
  [UserProjectType.Fashion]: ["Assets", "Generate", "Elements"],
};

function getPanelListItemClassName(isActive = false, isDisabled = false) {
  const names = [
    "w-full flex flex-col items-center justify-center select-none cursor-pointer p-2 mb-2 rounded-md transition-colors border",
  ];
  if (isDisabled) {
    names.push("border-zinc-900 text-zinc-700 cursor-not-allowed");
  } else {
    names.push(
      "active:border-zinc-700 active:text-white",
      isActive
        ? "text-zinc-100 bg-zinc-800 shadow-lg border-zinc-800"
        : "text-zinc-300 hover:text-zinc-200 hover:bg-zinc-800/80 border-zinc-900",
    );
  }
  return classNames(...names);
}

function isLeftPanelDisabled(type: LeftPanelItemType, activeObject: EditorActiveObject) {
  if (type === "Edit") {
    return isEditLeftPanelDisabled(activeObject);
  }
  return false;
}

function PanelListItem({ type, name }: { type: LeftPanelItemType; name: string }) {
  const { activeLeftPanel, setActiveLeftPanels } = useLeftPanelState();

  const Icon = PANELS_LIST_ICONS[type] || DEFAULT_ICON;

  const activeObject = editorContextStore((state) => state.activeObject);

  const isDisabled = React.useMemo(
    () => isLeftPanelDisabled(type, activeObject),
    [type, activeObject],
  );

  return (
    <div
      id={`panel-list-item-${type}`}
      className={classNames(
        getPanelListItemClassName(type === activeLeftPanel, isDisabled),
        "relative m-[2px] w-[16vw] h-[16vw] min-w-[70px] min-h-[70px] flex flex-col items-center justify-center",
      )}
      style={{
        gap: "max(1.5vw, 8px)",
        fontSize: "max(3vw, 12px)",
      }}
      onClick={() => {
        if (type && !isDisabled) {
          setActiveLeftPanels([type]);
          editorContextStore.getState().setIsLeftPanelOpen(true);
        }
      }}
    >
      <Icon size={18} />
      <div className={classNames()} style={{}}>
        {name}
      </div>
    </div>
  );
}

export const Default = React.forwardRef(function Default(
  {
    className = "",
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const projectType = editorContextStore((state) => state.projectType);

  const listItems = React.useMemo(() => {
    const type = projectType || UserProjectType.ProductPhotography;
    return type && type !== UserProjectType.CustomModels && projectLeftPanelListItems[type]
      ? projectLeftPanelListItems[type]
      : projectLeftPanelListItems[UserProjectType.ProductPhotography];
  }, [projectType]);

  return (
    <div
      ref={forwardedRef}
      className={classNames("flex flex-row items-center justify-start", className)}
    >
      {listItems.map((key) => (
        <PanelListItem
          key={key}
          type={key as LeftPanelItemType}
          name={LEFT_PANELS_LIST_ITEMS[key]?.name}
        />
      ))}
    </div>
  );
});
