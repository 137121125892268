import { StateUpdater } from "@/core/common/types";
import { noop } from "lodash";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { GenerateVideoEditorStatus } from "./generate-video-editor";

export enum DashboardVideosTab {
  Main = "Main",
  Generate = "Generate",
  PastGenerations = "PastGenerations",
}

export function isDashboardVideosTab(value: any): value is DashboardVideosTab {
  return Object.values(DashboardVideosTab).includes(value);
}

export interface DashboardVideosContext {
  tab: DashboardVideosTab;
  setTab: (value: StateUpdater<DashboardVideosTab>) => void;
  editorStatus: GenerateVideoEditorStatus;
  setEditorStatus: (value: StateUpdater<GenerateVideoEditorStatus>) => void;
}

const dashboardVideosContext = createContext<DashboardVideosContext>({
  tab: DashboardVideosTab.Generate,
  setTab: noop,
  editorStatus: GenerateVideoEditorStatus.Idle,
  setEditorStatus: noop,
});

export function useDashboardVideosContext() {
  return useContext(dashboardVideosContext);
}

export const DashboardVideosContextProvider = ({ children }: { children: ReactNode }) => {
  const [tab, setTab] = useState<DashboardVideosTab>(DashboardVideosTab.Generate);
  const [editorStatus, setEditorStatus] = React.useState(GenerateVideoEditorStatus.Idle);
  return (
    <dashboardVideosContext.Provider
      value={{
        tab,
        setTab,
        editorStatus,
        setEditorStatus,
      }}
    >
      {children}
    </dashboardVideosContext.Provider>
  );
};
