import { Backend } from "@/backend/base";
import { InputBoxClassName } from "@/components/constants/class-names";
import { editorContextStore } from "@/contexts/editor-context";
import {
  CustomModelPlaygroundPromptEditorState,
  CustomModelSetPromptEditorStateEventHandler,
} from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { debugError } from "@/core/utils/print-utilts";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { SerializedEditorState } from "lexical";
import { cloneDeep } from "lodash";
import React from "react";
import CustomModelsMentionsPlugin, {
  correctCustomModelPromptEditorStateDisplayNames,
  getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState,
  MentionNode,
} from "./custom-model-mention-plugin";

async function correctCustomModelPromptEditorStateJsonDisplayNames({
  backend,
  promptEditorStateJson,
}: {
  backend: Backend;
  promptEditorStateJson: string;
}) {
  try {
    const promptEditorState = await correctCustomModelPromptEditorStateDisplayNames({
      backend,
      promptEditorState: JSON.parse(promptEditorStateJson),
    });

    return JSON.stringify(promptEditorState);
  } catch (error) {
    debugError("Cannot parse prompt editor json: ", promptEditorStateJson, "Error:\n", error);
  }
}

interface CustomModelPromptEditorProps {
  editorState: CustomModelPlaygroundPromptEditorState;
  setEditorState: (editorState: CustomModelPlaygroundPromptEditorState) => void;
}

function CustomModelSetPromptEditorStateEventHandlerPlugin({
  initPromptEditorStateJson,
}: {
  initPromptEditorStateJson?: string | null;
}): JSX.Element | null {
  const backend = editorContextStore((state) => state.backend);

  const eventEmitter = editorContextStore((state) => state.eventEmitter);

  const [editor] = useLexicalComposerContext();

  const handleCustomModelSetPromptEditorStateEvent: CustomModelSetPromptEditorStateEventHandler["handler"] =
    React.useCallback(
      ({ promptEditorStateJson }) => {
        try {
          if (!backend) {
            return;
          }

          correctCustomModelPromptEditorStateJsonDisplayNames({
            backend,
            promptEditorStateJson,
          }).then((correctedPromptEditorStateJson) => {
            if (!correctedPromptEditorStateJson) {
              return;
            }

            editor.setEditorState(editor.parseEditorState(correctedPromptEditorStateJson));
          });
        } catch (error) {
          debugError(
            "Cannot parse prompt editor json: ",
            promptEditorStateJson,
            "\nError:\n",
            error,
          );
        }
      },
      [editor, backend],
    );

  React.useEffect(() => {
    eventEmitter.on<CustomModelSetPromptEditorStateEventHandler>(
      "custom-model:set-prompt-editor-state",
      handleCustomModelSetPromptEditorStateEvent,
    );

    return () => {
      eventEmitter.off<CustomModelSetPromptEditorStateEventHandler>(
        "custom-model:set-prompt-editor-state",
        handleCustomModelSetPromptEditorStateEvent,
      );
    };
  }, [handleCustomModelSetPromptEditorStateEvent, eventEmitter]);

  React.useEffect(() => {
    if (!initPromptEditorStateJson) {
      return;
    }

    handleCustomModelSetPromptEditorStateEvent({
      promptEditorStateJson: initPromptEditorStateJson,
    });
    // eslint-disable-next-line
  }, []);

  return null;
}

// TODO fix type
const plainEditorStateJSON: any = {
  root: {
    children: [
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: "",
            type: "text",
            version: 1,
          },
        ],
        direction: "ltr" as "ltr" | "rtl",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
        textStyle: "",
      },
    ],
    direction: "ltr" as "ltr" | "rtl",
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
};
export function getEditorStateJSONWithPrompt(prompt: string) {
  const json = cloneDeep(plainEditorStateJSON);
  if (prompt.length <= 0) {
    return json;
  }
  json.root.children[0].children[0].text = prompt;
  return json;
}
export function getPlainEditorStateFromPrompt(
  prompt: string,
): CustomModelPlaygroundPromptEditorState {
  const json = getEditorStateJSONWithPrompt(prompt);
  return {
    json: JSON.stringify(json),
    text: prompt,
    scaleConfigs: {},
  };
}

export function CustomModelPromptEditor({
  editorState,
  setEditorState,
}: CustomModelPromptEditorProps) {
  return (
    <LexicalComposer
      initialConfig={{
        editorState: null,
        editable: true,
        namespace: "CustomModelPromptEditor",
        onError: (error) => {
          debugError(error);
        },
        nodes: [MentionNode],
      }}
    >
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            className={classNames(
              InputBoxClassName,
              "min-h-[120px] max-h-[30vh] overflow-y-scroll",
            )}
            spellCheck={true}
          />
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      <OnChangePlugin
        onChange={(editorState) => {
          const promptEditorState = editorState.toJSON() as SerializedEditorState;

          const newPromptEditorState =
            getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
              promptEditorState,
            });

          setEditorState(newPromptEditorState);
        }}
      />
      <CustomModelsMentionsPlugin />
      <CustomModelSetPromptEditorStateEventHandlerPlugin
        initPromptEditorStateJson={editorState.json}
      />
    </LexicalComposer>
  );
}
