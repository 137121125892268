import { AppRoleType } from "@/core/common/types";
import { debugError } from "@/core/utils/print-utilts";
import { isAssetMetadata } from "@/core/common/types/assetV2";
import {
  GetAssetMetadataGeneratorArgs,
  GetThumbnailPathFromAssetStoragePathArgs,
} from "@/backend/base";
import { FirebaseDocQueryGenerator } from "./firebase-doc-query-generator";
import {
  collection,
  doc,
  Firestore,
  getDoc,
  where,
  QueryConstraint,
  DocumentData,
} from "firebase/firestore";
import { getUserAssetIdFromPath } from "@/core/utils/storage-path-utils";

const assetsMetadataCollectionName = "assetsMetadataV1";

function getAssetV2IdFromPath(path?: string): string | undefined {
  return getUserAssetIdFromPath(path);
}

function getAssetV2MetadataCollectionRef(firestore: Firestore) {
  return collection(firestore, assetsMetadataCollectionName);
}

function getAssetV2MetadataDocRef({
  firestore,
  assetId,
}: {
  firestore: Firestore;
  assetId: string;
}) {
  return doc(getAssetV2MetadataCollectionRef(firestore), assetId);
}

export class AssetV2Manager {
  private firestore: Firestore;

  constructor({ firestore }: { firestore: Firestore }) {
    this.firestore = firestore;
  }

  private getAssetV2MetadataCollectionRef() {
    return getAssetV2MetadataCollectionRef(this.firestore);
  }

  private getAssetV2MetadataDocRef(assetId: string) {
    return getAssetV2MetadataDocRef({
      firestore: this.firestore,
      assetId,
    });
  }

  async getAssetMetadata(assetId: string) {
    try {
      const assetMetadataDocRef = this.getAssetV2MetadataDocRef(assetId);

      const assetMetadataDocSnapshot = await getDoc(assetMetadataDocRef);

      if (!assetMetadataDocSnapshot.exists()) {
        return undefined;
      }

      const assetMetadataDoc = assetMetadataDocSnapshot.data();

      if (!isAssetMetadata(assetMetadataDoc)) {
        debugError(`Asset ${assetId} metadata doc is invalid: `, assetMetadataDoc);
        return undefined;
      }

      return assetMetadataDoc;
    } catch (error) {
      debugError(`Error retrieving asset ${assetId} metadata doc: `, error);
      return undefined;
    }
  }

  async getAssetMetadataFromStoragePath(storagePath: string) {
    const assetId = getAssetV2IdFromPath(storagePath);

    if (!assetId) {
      return undefined;
    }

    return await this.getAssetMetadata(assetId);
  }

  getAssetMetadataGenerator({
    publicUserId,
    assetType,
    tag,
    batchSize = 10,
  }: GetAssetMetadataGeneratorArgs) {
    return new FirebaseDocQueryGenerator<DocumentData>({
      baseQuery: this.getAssetV2MetadataCollectionRef(),
      batchSize,
      queryConstraints: [
        where("assetType", "==", assetType),
        where(`roles.${publicUserId}`, "in", Object.values(AppRoleType)),
        tag ? where("tags", "array-contains", tag) : undefined,
      ].filter(Boolean) as QueryConstraint[],
    });
  }

  async getThumbnailPathFromAssetStoragePath({
    storagePath,
  }: GetThumbnailPathFromAssetStoragePathArgs) {
    try {
      const assetId = getUserAssetIdFromPath(storagePath);

      if (assetId) {
        const assetMetadata = await this.getAssetMetadataFromStoragePath(storagePath);

        if (assetMetadata) {
          const { thumbnail512StoragePath, thumbnail256StoragePath, thumbnail128StoragePath } =
            assetMetadata;

          return (
            thumbnail512StoragePath ||
            thumbnail256StoragePath ||
            thumbnail128StoragePath ||
            storagePath
          );
        }
      }

      return storagePath;
    } catch (error) {
      debugError(`Error retrieving thumbnail path from dataset path ${storagePath}: `, error);
      return storagePath;
    }
  }
}
