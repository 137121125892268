import React from "react";
import { classNames } from "@/core/utils/classname-utils";

export const panelListButtonIconSize = 18;

export const PanelListButton = React.forwardRef(function PanelListButton(
  {
    className = "",
    style = {},
    children,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={forwardedRef}
      {...props}
      className={classNames(
        "relative m-[2px] w-[16vw] h-[16vw] min-w-[70px] min-h-[70px] flex flex-col items-center justify-center text-zinc-300 active:text-zinc-100 active:bg-zinc-800 rounded-md transition-colors",
        className,
      )}
      style={{
        gap: "max(1.5vw, 8px)",
        fontSize: "max(3vw, 12px)",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
});
