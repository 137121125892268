const StorageURLPrefixes = [
  "https://storage.googleapis.com/flair-ai.appspot.com/",
  "https://firebasestorage.googleapis.com/v0/b/flair-ai.appspot.com/",
];

const StoragePathPrefix = "flair-ai.appspot.com/";

const isStoragePathURL = (value: string) => {
  try {
    if (typeof value !== "string") {
      console.error("Invalid input: not a string");
      return false;
    }

    value = value.trim();

    return Boolean(
      StorageURLPrefixes.find((prefix) => {
        return value.startsWith(prefix);
      }),
    );
  } catch (error) {
    console.error(error);
  }
  return false;
};

function cleanupStoragePathURL(storagePath: string) {
  try {
    storagePath = storagePath.trim();

    if (isStoragePathURL(storagePath)) {
      const storageUrl = new URL(storagePath);

      storageUrl.search = "";

      const pathname = storageUrl.pathname;

      const path = decodeURIComponent(pathname);

      if (storageUrl.host === "storage.googleapis.com") {
        return path.split("/").slice(2).join("/");
      }

      if (storageUrl.host === "firebasestorage.googleapis.com") {
        return path.split("/").slice(5).join("/");
      }

      console.log("Splitted storage path", path);

      return path;
    } else if (storagePath.startsWith(StoragePathPrefix)) {
      return storagePath.slice(StoragePathPrefix.length);
    }

    return storagePath;
  } catch (error) {
    console.error(error);
  }
  return storagePath;
}

function regexExtractUserAssetIdFromUpscaleIntermediateResultPath(
  path: string,
): string | undefined {
  try {
    const regex = /^users\/[^\/]+\/assets\/intermediate\/([^\/]+)\/([^\/]+)\.[^\/]+$/;
    const match = path.match(regex);

    if (match) {
      const assetId = match[1];
      const fileName = match[2];
      return `${assetId}-${fileName}`;
    }
  } catch (error) {
    console.error(`Cannot extract user id from upsacle intermediate result path ${path}`);
  }

  return undefined;
}

function regexExtractUserAssetIdFromLegacyUserAssetPath(path: string): string | undefined {
  try {
    const pattern = /users\/[^\/]+\/assets\/(.*?)\.[^\.]+$/;

    const match = path.match(pattern);

    return match?.[1];
  } catch (error) {
    console.error(`Cannot extract user id from asset V1 path ${path}`);
  }

  return undefined;
}

function regexExtractUserAssetIdFromLegacyUserAssetV2(path: string): string | undefined {
  try {
    const patterns = [
      /assetsV2\/([^\/]+)\/[^\/]*$/, // Case 2
      /assetsV2\/([^\/]+)\/.+$/, // Case 3
    ];

    for (const pattern of patterns) {
      const match = path.match(pattern);
      if (match) {
        return match[1];
      }
    }
  } catch (error) {
    console.error(`Cannot extract user id from asset V2 path ${path}`);
  }

  return undefined;
}

function regexExtractUserAssetIdFromPath(path: string | undefined): string | undefined {
  // This regex pattern captures the 'id' portion in the specified path formats.
  // It assumes that 'id' can include dots but excludes any slashes.
  // The patterns cater to:
  // 1. /users/{uid}/assets/ijk.png -> captures "ijk"
  // 2. /assetsV2/{assetId}/* -> captures {assetId}
  // 3. /assetsV2/{assetId}/**/* -> captures {assetId}

  if (!path) {
    return undefined;
  }

  return (
    regexExtractUserAssetIdFromUpscaleIntermediateResultPath(path) ||
    regexExtractUserAssetIdFromLegacyUserAssetPath(path) ||
    regexExtractUserAssetIdFromLegacyUserAssetV2(path)
  );
}

function regexExtractAssetIdFromStoragePathLegacy(input: string): string | undefined {
  // Use a regular expression to match the given format and extract assetId
  const match = input.match(/users\/.*?\/assets\/(.*?)(\..+)?$/);

  // If the string matches the format, return the assetId
  if (match && match[1]) {
    return match[1];
  }

  // If no match, return undefined
  return undefined;
}

function getAssetIdFromStoragePathManual(storagePath: string): string | undefined {
  const pathSplitted = storagePath.split("/");
  if (pathSplitted.length <= 0) {
    return undefined;
  }
  const lastPath = pathSplitted[pathSplitted.length - 1];
  return lastPath.split(".")[0];
}

export function getUserAssetIdFromPath(path?: string): string | undefined {
  if (!path) {
    return undefined;
  }

  path = cleanupStoragePathURL(path);

  return (
    regexExtractUserAssetIdFromPath(path) ||
    regexExtractAssetIdFromStoragePathLegacy(path) ||
    getAssetIdFromStoragePathManual(path)
  );
}
