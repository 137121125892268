import { ImageInputProps } from "./image-input";
import { OptionsInputProps } from "./options-input";
import { SliderInputProps } from "./slider-input";
import { NumberInputProps, TextInputProps } from "./text-input";
import { ApiStateOfApiConfig } from "./api-input";
import { ApiInputType, ApiPipelineType } from "@/core/common/types/api";
import { cloneDeep, noop } from "lodash";
import { ref } from "firebase/database";

export const GENERATE_IMAGE_API_ENDPOINT = "https://api.flair.ai/generate-image/v1";

export enum ApiLanguage {
  Bash = "bash",
  Javascript = "javascript",
  JavascriptNodeJs = "javascript-nodejs",
  Typescript = "typescript",
  Python = "python",
}

/* create the types */
export type DefaultGenerateImageApiConfig = {
  pipeline_type: OptionsInputProps;
  prompt: TextInputProps;
  negative_prompt: TextInputProps;
  width: SliderInputProps;
  height: SliderInputProps;
  num_inference_steps: SliderInputProps;
  guidance_scale: SliderInputProps;
  seed: NumberInputProps;
};
export type RefDefaultGenerateImageApiConfig = DefaultGenerateImageApiConfig & {
  ref_image: ImageInputProps;
  ip_adapter_scale_start: SliderInputProps;
  ip_adapter_scale_finish: SliderInputProps;
  ip_adapter_start: SliderInputProps;
  ip_adapter_finish: SliderInputProps;
};
export type CannyGenerateImageApiConfig = DefaultGenerateImageApiConfig & {
  composite_image: ImageInputProps;
  composite_mask_image: ImageInputProps;
  canny_controlnet_conditioning_scale: SliderInputProps;
  canny_controlnet_guidance_start: SliderInputProps;
  canny_controlnet_guidance_finish: SliderInputProps;
  canny_controlnet_conditioning_scale_start: SliderInputProps;
  canny_controlnet_conditioning_scale_finish: SliderInputProps;
  image_overlay_use_canny: OptionsInputProps;
  image_overlay_use_poisson: OptionsInputProps;
  apply_extra_poisson_blending_to_color_correction: OptionsInputProps;
};
export type RefCannyGenerateImageApiConfig = RefDefaultGenerateImageApiConfig &
  CannyGenerateImageApiConfig & {
    ref_image_canny_strength: SliderInputProps;
  };
export type GenerateImageApiConfig =
  | DefaultGenerateImageApiConfig
  | CannyGenerateImageApiConfig
  | RefDefaultGenerateImageApiConfig
  | RefCannyGenerateImageApiConfig;

/* basically the same keys but extract ONLY the .value field ratehr than the whole OptionsInputProps or whatever other props you've got going on here.*/
export type DefaultGenerateImageApiState = ApiStateOfApiConfig<DefaultGenerateImageApiConfig>;
export type RefDefaultGenerateImageApiState = ApiStateOfApiConfig<RefDefaultGenerateImageApiConfig>;
export type CannyGenerateImageApiState = ApiStateOfApiConfig<CannyGenerateImageApiConfig>;
export type RefCannyGenerateImageApiState = ApiStateOfApiConfig<RefCannyGenerateImageApiConfig>;
export type GenerateImageApiState =
  | DefaultGenerateImageApiState
  | CannyGenerateImageApiState
  | RefDefaultGenerateImageApiState
  | RefCannyGenerateImageApiState;

/* type guards */
export function isDefaultGenerateImageApiState(
  apiState: GenerateImageApiState,
): apiState is DefaultGenerateImageApiState {
  return apiState && apiState.pipeline_type === ApiPipelineType.Default;
}
export function isCannyGenerateImageApiState(
  apiState: GenerateImageApiState,
): apiState is CannyGenerateImageApiState {
  return apiState && apiState.pipeline_type === ApiPipelineType.Canny;
}
export function isRefDefaultGenerateImageApiState(
  apiState: GenerateImageApiState,
): apiState is RefDefaultGenerateImageApiState {
  return apiState && apiState.pipeline_type === ApiPipelineType.RefDefault;
}
export function isRefCannyGenerateImageApiState(
  apiState: GenerateImageApiState,
): apiState is RefCannyGenerateImageApiState {
  return apiState && apiState.pipeline_type === ApiPipelineType.RefCanny;
}

function parseNonNegativeIntegerString(value: string) {
  try {
    return Math.max(parseInt(value), -1);
  } catch (error) {
    return -1;
  }
}

export const defaultGenerateImageApiConfig: DefaultGenerateImageApiConfig = {
  pipeline_type: {
    type: ApiInputType.Options,
    id: "pipelineType",
    name: "Pipeline Type",
    value: ApiPipelineType.Default,
    options: {
      Pipelines: Object.values(ApiPipelineType).map((type) => ({
        name: type,
        value: type,
      })),
    },
    onValueChange: noop,
    required: true,
  },
  prompt: {
    type: ApiInputType.Text,
    id: "prompt",
    name: "Prompt",
    value:
      "photography of a dropper bottle on a concrete platform with flower petals, highly detailed, best quality, editorial, award winning, minimalistic aesthetic",
    onValueChange: noop,
    required: true,
  },
  negative_prompt: {
    type: ApiInputType.Text,
    id: "negativePrompt",
    name: "Negative prompt",
    value:
      "blurry, cropped, ugly, chaotic, random, colorful, cluttered, distorted, drawing, painting, graphic design, 3d rendering, fake, plastic",
    onValueChange: noop,
  },
  width: {
    type: ApiInputType.Slider,
    id: "width",
    name: "Width",
    value: 1024,
    defaultValue: 1024,
    min: 512,
    max: 1536,
    step: 64,
    onValueChange: noop,
  },
  height: {
    type: ApiInputType.Slider,
    id: "height",
    name: "Height",
    value: 1024,
    defaultValue: 1024,
    min: 512,
    max: 1536,
    step: 64,
    onValueChange: noop,
  },
  num_inference_steps: {
    type: ApiInputType.Slider,
    id: "numInferenceSteps",
    name: "Number of inference steps",
    value: 25,
    defaultValue: 25,
    min: 1,
    max: 50,
    step: 1,
    onValueChange: noop,
  },
  guidance_scale: {
    type: ApiInputType.Slider,
    id: "guidanceScale",
    name: "Guidance scale",
    value: 7.5,
    defaultValue: 7.5,
    min: 0,
    max: 20,
    step: 0.5,
    onValueChange: noop,
  },
  seed: {
    type: ApiInputType.Number,
    id: "seed",
    name: "Seed",
    value: 42,
    onValueChange: noop,
    parseStringValue: parseNonNegativeIntegerString,
  },
};
export const refDefaultGenerateImageApiConfig: RefDefaultGenerateImageApiConfig = {
  ...defaultGenerateImageApiConfig,
  ref_image: {
    type: ApiInputType.Image,
    id: "refImage",
    name: "Reference image",
    value:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/249aa7d9-87b0-4b51-aa2d-a014fe0ea000/public",
    onValueChange: noop,
  },
  ip_adapter_scale_start: {
    type: ApiInputType.Slider,
    id: "ipAdapterScaleStart",
    name: "IP Adapter scale start",
    value: 0.6,
    defaultValue: 0.6,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
  ip_adapter_scale_finish: {
    type: ApiInputType.Slider,
    id: "ipAdapterScaleFinish",
    name: "IP Adapter scale finish",
    value: 0.0,
    defaultValue: 0.0,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
  ip_adapter_start: {
    type: ApiInputType.Slider,
    id: "ipAdapterStart",
    name: "IP Adapter start",
    value: 0.0,
    defaultValue: 0.0,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
  ip_adapter_finish: {
    type: ApiInputType.Slider,
    id: "ipAdapterFinish",
    name: "IP Adapter finish",
    value: 1.0,
    defaultValue: 1.0,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
};

export const cannyGenerateImageApiConfig: CannyGenerateImageApiConfig = {
  ...defaultGenerateImageApiConfig,
  composite_image: {
    type: ApiInputType.Image,
    id: "compositeImage",
    name: "Composite image",
    value:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f71d59a8-5944-4769-e4ad-54795fb2ec00/public",
    onValueChange: noop,
  },
  composite_mask_image: {
    type: ApiInputType.Image,
    id: "compositeMaskImage",
    name: "Composite mask image",
    value:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/68d0fa24-38bb-4508-df49-115afd867500/public",
    onValueChange: noop,
  },
  canny_controlnet_conditioning_scale: {
    type: ApiInputType.Slider,
    id: "cannyControlnetConditioningScale",
    name: "Canny controlnet conditioning scale",
    value: 1.0,
    defaultValue: 1.0,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
  canny_controlnet_guidance_start: {
    type: ApiInputType.Slider,
    id: "cannyControlnetGuidanceStart",
    name: "Canny controlnet guidance start",
    value: 0.0,
    defaultValue: 0.0,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
  canny_controlnet_guidance_finish: {
    type: ApiInputType.Slider,
    id: "cannyControlnetGuidanceFinish",
    name: "Canny controlnet guidance finish",
    value: 1.0,
    defaultValue: 1.0,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
  canny_controlnet_conditioning_scale_start: {
    type: ApiInputType.Slider,
    id: "cannyControlnetConditioningScaleStart",
    name: "Canny controlnet conditioning scale start",
    value: 1.0,
    defaultValue: 1.0,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
  canny_controlnet_conditioning_scale_finish: {
    type: ApiInputType.Slider,
    id: "cannyControlnetConditioningScaleFinish",
    name: "Canny controlnet conditioning scale finish",
    value: 0.5,
    defaultValue: 0.5,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
  image_overlay_use_canny: {
    type: ApiInputType.Options,
    id: "imageOverlayUseCanny",
    name: "Image overlay use Canny",
    value: "true",
    options: {
      Options: [
        { name: "true", value: "true" },
        { name: "false", value: "false" },
      ],
    },
    onValueChange: noop,
  },
  image_overlay_use_poisson: {
    type: ApiInputType.Options,
    id: "imageOverlayUsePoisson",
    name: "Image overlay use Poisson",
    value: "true",
    options: {
      Options: [
        { name: "true", value: "true" },
        { name: "false", value: "false" },
      ],
    },
    onValueChange: noop,
  },
  apply_extra_poisson_blending_to_color_correction: {
    type: ApiInputType.Options,
    id: "apply_extra_poisson_blending_to_color_correction",
    name: "Apply extra Poisson blending to color correction",
    value: "true",
    options: {
      Options: [
        { name: "true", value: "true" },
        { name: "false", value: "false" },
      ],
    },
    onValueChange: noop,
  },
};
export const refCannyGenerateImageApiConfig: RefCannyGenerateImageApiConfig = {
  ...cannyGenerateImageApiConfig,
  ...refDefaultGenerateImageApiConfig,
  ref_image_canny_strength: {
    type: ApiInputType.Slider,
    id: "refImageCannyStrength",
    name: "Reference image Canny strength",
    value: 0.2,
    defaultValue: 0.2,
    min: 0,
    max: 1.0,
    step: 0.1,
    onValueChange: noop,
  },
};

export function getGenerateImageApiConfigFromApiPipelineType(pipelineType?: ApiPipelineType) {
  if (pipelineType === ApiPipelineType.Canny) {
    return cloneDeep(cannyGenerateImageApiConfig);
  } else if (pipelineType === ApiPipelineType.RefDefault) {
    return cloneDeep(refDefaultGenerateImageApiConfig);
  } else if (pipelineType === ApiPipelineType.RefCanny) {
    return cloneDeep(refCannyGenerateImageApiConfig);
  } else {
    return cloneDeep(defaultGenerateImageApiConfig);
  }
}

/* canny args */
function getJavascriptCodeCanny(apiState: CannyGenerateImageApiState, apiKey = "YOUR API KEY") {
  return `\
async function getDataUrlFromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const canny_image_url = "https://your-outline-image.png"; // Set your outline image URL
    const canny_image = await getDataUrlFromUrl(canny_image_url);

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
            canny_base_image: canny_image,
            canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
            canny_controlnet_guidance_start: ${apiState.canny_controlnet_guidance_start},
            canny_controlnet_guidance_finish: ${apiState.canny_controlnet_guidance_finish},
            canny_controlnet_conditioning_scale_start: ${apiState.canny_controlnet_conditioning_scale_start},
            canny_controlnet_conditioning_scale_finish: ${apiState.canny_controlnet_conditioning_scale_finish},
            image_overlay_use_canny: ${apiState.image_overlay_use_canny},
            image_overlay_use_poisson: ${apiState.image_overlay_use_poisson},
            apply_extra_poisson_blending_to_color_correction: ${apiState.apply_extra_poisson_blending_to_color_correction},
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}

function getTypescriptCodeCanny(apiState: CannyGenerateImageApiState, apiKey = "YOUR API KEY") {
  return `\
async function getDataUrlFromUrl(url: string) {
    const blob = await fetch(url).then(r => r.blob());
    return new Promise<string | undefined | null>((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const canny_image_url = "https://your-outline-image.png"; // Set your reference image URL

    const canny_image = await getDataUrlFromUrl(canny_image_url);

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
            canny_base_image: canny_image,
            canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
            canny_controlnet_guidance_start: ${apiState.canny_controlnet_guidance_start},
            canny_controlnet_guidance_finish: ${apiState.canny_controlnet_guidance_finish},
            canny_controlnet_conditioning_scale_start: ${apiState.canny_controlnet_conditioning_scale_start},
            canny_controlnet_conditioning_scale_finish: ${apiState.canny_controlnet_conditioning_scale_finish},
            image_overlay_use_canny: ${apiState.image_overlay_use_canny},
            image_overlay_use_poisson: ${apiState.image_overlay_use_poisson},
            apply_extra_poisson_blending_to_color_correction: ${apiState.apply_extra_poisson_blending_to_color_correction},
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}

function getNodeJsCodeCanny(apiState: CannyGenerateImageApiState, apiKey = "YOUR API KEY") {
  return `\
const axios = require('axios');
const fs = require('fs').promises;

async function getImageDataUri(filePath) {
    try {
        const fileBuffer = await fs.readFile(filePath);
        const base64 = fileBuffer.toString('base64');
        const mimeType = 'image/png'; // This is assuming PNG images. Change mimeType according to your image files.
        return 'data:' + mimeType + ';base64,' + base64;
    } catch (error) {
        console.error('Error reading file:', error);
        throw error;
    }
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";

    try {
        const cannyImage = await getImageDataUri("/path/to/outline-image.png");

        const response = await axios.post(url, {
        pipeline_type: "${apiState.pipeline_type}",
        prompt: "${apiState.prompt}",
        negative_prompt: "${apiState.negative_prompt}",
        guidance_scale: ${apiState.guidance_scale},
        num_inference_steps: ${apiState.num_inference_steps},
        width: ${apiState.width},
        height: ${apiState.height},
        seed: ${apiState.seed},
        canny_base_image: cannyImage,
        canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
        canny_controlnet_guidance_start: ${apiState.canny_controlnet_guidance_start},
        canny_controlnet_guidance_finish: ${apiState.canny_controlnet_guidance_finish},
        canny_controlnet_conditioning_scale_start: ${apiState.canny_controlnet_conditioning_scale_start},
        canny_controlnet_conditioning_scale_finish: ${apiState.canny_controlnet_conditioning_scale_finish},
        image_overlay_use_canny: ${apiState.image_overlay_use_canny},
        image_overlay_use_poisson: ${apiState.image_overlay_use_poisson},
        apply_extra_poisson_blending_to_color_correction: ${apiState.apply_extra_poisson_blending_to_color_correction},
        }, {
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        }
        });

        return response.data.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
    }
}

// Example usage:
(async () => {
    try {
        const images = await callApi();
        console.log(images);
    } catch (error) {
        console.error(error);
    }
})();`;
}

function getPythonCodeCanny(apiState: CannyGenerateImageApiState, apiKey = "YOUR API KEY") {
  return `\
import base64
import requests

def get_image_data_uri(file_path):
    with open(file_path, "rb") as image_file:
        encoded_string = base64.b64encode(image_file.read()).decode()
        return f"data:image/png;base64,{encoded_string}"

def call_api():
    api_key = "${apiKey}"
    url = "${GENERATE_IMAGE_API_ENDPOINT}"

    canny_image_url = "/path/to/outline.png"
    canny_image = get_image_data_uri(canny_image_url)

    try:
        payload = {
            "pipeline_type": "${apiState.pipeline_type}",
            "prompt": "${apiState.prompt}",
            "negative_prompt": "${apiState.negative_prompt}",
            "guidance_scale": ${apiState.guidance_scale},
            "num_inference_steps": ${apiState.num_inference_steps},
            "width": ${apiState.width},
            "height": ${apiState.height},
            "seed": ${apiState.seed},
            "canny_base_image": canny_image,
            "canny_controlnet_conditioning_scale": ${apiState.canny_controlnet_conditioning_scale},
            "canny_controlnet_guidance_start": ${apiState.canny_controlnet_guidance_start},
            "canny_controlnet_guidance_finish": ${apiState.canny_controlnet_guidance_finish},
            "canny_controlnet_conditioning_scale_start": ${apiState.canny_controlnet_conditioning_scale_start},
            "canny_controlnet_conditioning_scale_finish": ${apiState.canny_controlnet_conditioning_scale_finish},
            "image_overlay_use_canny": ${apiState.image_overlay_use_canny},
            "image_overlay_use_poisson": ${apiState.image_overlay_use_poisson},
            "apply_extra_poisson_blending_to_color_correction": ${apiState.apply_extra_poisson_blending_to_color_correction}
        }
        headers = {
            'Content-Type': 'application/json',
            'API-Key': api_key
        }

        response = requests.post(url, json=payload, headers=headers)
        response.raise_for_status()

        return response.json()["images"]
    except requests.exceptions.RequestException as e:
        print(f"There has been a problem with your fetch operation: {e}")

# Example usage:
if __name__ == "__main__":
    try:
        images = call_api()
        print(images)
    except Exception as error:
        print(error)
`;
}

/* default args */
function getJavascriptCodeDefault(apiState: DefaultGenerateImageApiState, apiKey = "YOUR API KEY") {
  return `\
async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed}
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

const images = await callApi();`;
}

function getTypescriptCodeDefault(apiState: DefaultGenerateImageApiState, apiKey = "YOUR API KEY") {
  return `\
async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed}
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}

function getNodeJsCodeDefault(
  apiState: DefaultGenerateImageApiState,
  apiKey = "YOUR API KEY",
): string {
  return `\
const axios = require('axios');

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";

    try {
        const response = await axios.post(url, {
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
        }, {
            headers: {
                'Content-Type': 'application/json',
                'API-Key': api_key
            }
        });

        if (response.status === 200) {
            console.log(response.data.images);
        } else {
            console.error('Error:', response.status, response.data);
        }
    } catch (error) {
        console.error('Error:', error.response || error.request || error.message);
    }
}

callApi();`;
}

function getPythonCodeDefault(
  apiState: DefaultGenerateImageApiState,
  apiKey = "YOUR API KEY",
): string {
  return `\
import requests
import json

api_key = "${apiKey}"
url = "${GENERATE_IMAGE_API_ENDPOINT}"

payload = {
    "pipeline_type": "${apiState.pipeline_type}",
    "prompt": "${apiState.prompt}",
    "negative_prompt": "${apiState.negative_prompt}",
    "guidance_scale": ${apiState.guidance_scale},
    "num_inference_steps": ${apiState.num_inference_steps},
    "width": ${apiState.width},
    "height": ${apiState.height},
    "seed": ${apiState.seed}
}

headers = {
    "Content-Type": "application/json",
    "API-Key": api_key
}

response = requests.post(url, headers=headers, data=json.dumps(payload))

if response.status_code == 200:
    images = response.json().get('images')
    print(images)
else:
    print("Error:", response.status_code, response.text)
`;
}

/* ref default args */
function getJavascriptCodeRefDefault(
  apiState: RefDefaultGenerateImageApiState,
  apiKey = "YOUR API KEY",
) {
  return `\
async function getDataUrlFromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const ref_image_url = "https://your-reference-image.png"; // Set your reference image URL
    const ref_image = await getDataUrlFromUrl(ref_image_url);

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
            ref_image: ref_image,
            ip_adapter_scale_start: ${apiState.ip_adapter_scale_start},
            ip_adapter_scale_finish: ${apiState.ip_adapter_scale_finish},
            ip_adapter_start: ${apiState.ip_adapter_start},
            ip_adapter_finish: ${apiState.ip_adapter_finish},
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}
function getTypescriptCodeRefDefault(
  apiState: RefDefaultGenerateImageApiState,
  apiKey = "YOUR API KEY",
) {
  return `\
async function getDataUrlFromUrl(url: string) {
    const blob = await fetch(url).then(r => r.blob());
    return new Promise<string | undefined | null>((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const ref_image_url = "https://your-reference-image.png"; // Set your reference image URL

    const ref_image = await getDataUrlFromUrl(ref_image_url);

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
            ref_image: ref_image,
            ip_adapter_scale_start: ${apiState.ip_adapter_scale_start},
            ip_adapter_scale_finish: ${apiState.ip_adapter_scale_finish},
            ip_adapter_start: ${apiState.ip_adapter_start},
            ip_adapter_finish: ${apiState.ip_adapter_finish},
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}
function getNodeJsCodeRefDefault(
  apiState: RefDefaultGenerateImageApiState,
  apiKey = "YOUR API KEY",
): string {
  return `\
const axios = require('axios');
const fs = require('fs');

async function getDataUrlFromFile(filePath) {
    const data = await fs.promises.readFile(filePath);
    return \`data:image/png;base64,\${data.toString('base64')}\`;
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const ref_image_path = "./your-reference-image.png"; // Set your reference image path

    const ref_image = await getDataUrlFromFile(ref_image_path);

    try {
        const response = await axios.post(url, {
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
            ref_image: ref_image,
            ip_adapter_scale_start: ${apiState.ip_adapter_scale_start},
            ip_adapter_scale_finish: ${apiState.ip_adapter_scale_finish},
            ip_adapter_start: ${apiState.ip_adapter_start},
            ip_adapter_finish: ${apiState.ip_adapter_finish},
        }, {
            headers: {
                'Content-Type': 'application/json',
                'API-Key': api_key
            }
        });

        if (response.status === 200) {
            console.log(response.data.images);
        } else {
            console.error('Error:', response.status, response.data);
        }
    } catch (error) {
        console.error('Error:', error.response || error.request || error.message);
    }
}

callApi();`;
}

function getPythonCodeRefDefault(
  apiState: RefDefaultGenerateImageApiState,
  apiKey = "YOUR API KEY",
): string {
  return `\
import requests
import json
import base64

def get_data_url_from_file(file_path):
    with open(file_path, "rb") as image_file:
        encoded_string = base64.b64encode(image_file.read()).decode('utf-8')
    return f"data:image/png;base64,{encoded_string}"

api_key = "${apiKey}"
url = "${GENERATE_IMAGE_API_ENDPOINT}"
ref_image_path = "./your-reference-image.png"  # Set your reference image path

ref_image = get_data_url_from_file(ref_image_path)

payload = {
    "pipeline_type": "${apiState.pipeline_type}",
    "prompt": "${apiState.prompt}",
    "negative_prompt": "${apiState.negative_prompt}",
    "guidance_scale": ${apiState.guidance_scale},
    "num_inference_steps": ${apiState.num_inference_steps},
    "width": ${apiState.width},
    "height": ${apiState.height},
    "seed": ${apiState.seed},
    "ref_image": ref_image,
    "ip_adapter_scale_start": ${apiState.ip_adapter_scale_start},
    "ip_adapter_scale_finish": ${apiState.ip_adapter_scale_finish},
    "ip_adapter_start": ${apiState.ip_adapter_start},
    "ip_adapter_finish": ${apiState.ip_adapter_finish},
}

headers = {
    "Content-Type": "application/json",
    "API-Key": api_key
}

response = requests.post(url, headers=headers, data=json.dumps(payload))

if response.status_code == 200:
    images = response.json().get('images')
    print(images)
else:
    print("Error:", response.status_code, response.text)
`;
}

/* ref canny args */
function getJavascriptCodeRefCanny(
  apiState: RefCannyGenerateImageApiState,
  apiKey = "YOUR API KEY",
) {
  return `\
async function getDataUrlFromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const ref_image_url = "https://your-reference-image.png"; // Set your reference image URL
    const ref_image = await getDataUrlFromUrl(ref_image_url);
    const canny_image_url = "https://your-outline-image.png"; // Set your outline image URL
    const canny_image = await getDataUrlFromUrl(canny_image_url);

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
            ref_image: ref_image,
            ip_adapter_scale_start: ${apiState.ip_adapter_scale_start},
            ip_adapter_scale_finish: ${apiState.ip_adapter_scale_finish},
            ip_adapter_start: ${apiState.ip_adapter_start},
            ip_adapter_finish: ${apiState.ip_adapter_finish},
            canny_base_image: canny_image,
            canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
            canny_controlnet_guidance_start: ${apiState.canny_controlnet_guidance_start},
            canny_controlnet_guidance_finish: ${apiState.canny_controlnet_guidance_finish},
            canny_controlnet_conditioning_scale_start: ${apiState.canny_controlnet_conditioning_scale_start},
            canny_controlnet_conditioning_scale_finish: ${apiState.canny_controlnet_conditioning_scale_finish},
            image_overlay_use_canny: ${apiState.image_overlay_use_canny},
            image_overlay_use_poisson: ${apiState.image_overlay_use_poisson},
            apply_extra_poisson_blending_to_color_correction: ${apiState.apply_extra_poisson_blending_to_color_correction},
            ref_image_canny_strength: ${apiState.ref_image_canny_strength},
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}
function getTypescriptCodeRefCanny(
  apiState: RefCannyGenerateImageApiState,
  apiKey = "YOUR API KEY",
) {
  return `\
async function getDataUrlFromUrl(url: string) {
    const blob = await fetch(url).then(r => r.blob());
    return new Promise<string | undefined | null>((resolve, reject) => {
        if (!blob) {
            return resolve(undefined);
        }
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const ref_image_url = "https://your-reference-image.png"; // Set your reference image URL
    const ref_image = await getDataUrlFromUrl(ref_image_url);
    const canny_image_url = "https://your-outline-image.png"; // Set your outline image URL
    const canny_image = await getDataUrlFromUrl(canny_image_url);

    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-Key': api_key
        },
        body: JSON.stringify({
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
            ref_image: ref_image,
            ip_adapter_scale_start: ${apiState.ip_adapter_scale_start},
            ip_adapter_scale_finish: ${apiState.ip_adapter_scale_finish},
            ip_adapter_start: ${apiState.ip_adapter_start},
            ip_adapter_finish: ${apiState.ip_adapter_finish},
            canny_base_image: canny_image,
            canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
            canny_controlnet_guidance_start: ${apiState.canny_controlnet_guidance_start},
            canny_controlnet_guidance_finish: ${apiState.canny_controlnet_guidance_finish},
            canny_controlnet_conditioning_scale_start: ${apiState.canny_controlnet_conditioning_scale_start},
            canny_controlnet_conditioning_scale_finish: ${apiState.canny_controlnet_conditioning_scale_finish},
            image_overlay_use_canny: ${apiState.image_overlay_use_canny},
            image_overlay_use_poisson: ${apiState.image_overlay_use_poisson},
            apply_extra_poisson_blending_to_color_correction: ${apiState.apply_extra_poisson_blending_to_color_correction},
            ref_image_canny_strength: ${apiState.ref_image_canny_strength},
        })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const result = await response.json();
        return result.images;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

// Use within an async context or function
(async () => {
    const images = await callApi();
    console.log(images);
})();`;
}

function getNodeJsCodeRefCanny(
  apiState: RefCannyGenerateImageApiState,
  apiKey = "YOUR API KEY",
): string {
  return `\
const axios = require('axios');
const fs = require('fs');

async function getDataUrlFromFile(filePath) {
    const data = await fs.promises.readFile(filePath);
    return \`data:image/png;base64,\${data.toString('base64')}\`;
}

async function callApi() {
    const api_key = "${apiKey}";
    const url = "${GENERATE_IMAGE_API_ENDPOINT}";
    const ref_image_path = "./your-reference-image.png"; // Set your reference image path
    const canny_image_path = "./your-outline-image.png"; // Set your outline image path

    const ref_image = await getDataUrlFromFile(ref_image_path);
    const canny_image = await getDataUrlFromFile(canny_image_path);

    try {
        const response = await axios.post(url, {
            pipeline_type: "${apiState.pipeline_type}",
            prompt: "${apiState.prompt}",
            negative_prompt: "${apiState.negative_prompt}",
            guidance_scale: ${apiState.guidance_scale},
            num_inference_steps: ${apiState.num_inference_steps},
            width: ${apiState.width},
            height: ${apiState.height},
            seed: ${apiState.seed},
            ref_image: ref_image,
            ip_adapter_scale_start: ${apiState.ip_adapter_scale_start},
            ip_adapter_scale_finish: ${apiState.ip_adapter_scale_finish},
            ip_adapter_start: ${apiState.ip_adapter_start},
            ip_adapter_finish: ${apiState.ip_adapter_finish},
            canny_base_image: canny_image,
            canny_controlnet_conditioning_scale: ${apiState.canny_controlnet_conditioning_scale},
            canny_controlnet_guidance_start: ${apiState.canny_controlnet_guidance_start},
            canny_controlnet_guidance_finish: ${apiState.canny_controlnet_guidance_finish},
            canny_controlnet_conditioning_scale_start: ${apiState.canny_controlnet_conditioning_scale_start},
            canny_controlnet_conditioning_scale_finish: ${apiState.canny_controlnet_conditioning_scale_finish},
            image_overlay_use_canny: ${apiState.image_overlay_use_canny},
            image_overlay_use_poisson: ${apiState.image_overlay_use_poisson},
            apply_extra_poisson_blending_to_color_correction: ${apiState.apply_extra_poisson_blending_to_color_correction},
            ref_image_canny_strength: ${apiState.ref_image_canny_strength},
        }, {
            headers: {
                'Content-Type': 'application/json',
                'API-Key': api_key
            }
        });

        if (response.status === 200) {
            console.log(response.data.images);
        } else {
            console.error('Error:', response.status, response.data);
        }
    } catch (error) {
        console.error('Error:', error.response || error.request || error.message);
    }
}

callApi();`;
}

function getPythonCodeRefCanny(
  apiState: RefCannyGenerateImageApiState,
  apiKey = "YOUR API KEY",
): string {
  return `\
import requests
import json
import base64

def get_data_url_from_file(file_path):
    with open(file_path, "rb") as image_file:
        encoded_string = base64.b64encode(image_file.read()).decode('utf-8')
    return f"data:image/png;base64,{encoded_string}"

api_key = "${apiKey}"
url = "${GENERATE_IMAGE_API_ENDPOINT}"
ref_image_path = "./your-reference-image.png"  # Set your reference image path
canny_image_path = "./your-outline-image.png"  # Set your outline image path

ref_image = get_data_url_from_file(ref_image_path)
canny_image = get_data_url_from_file(canny_image_path)

payload = {
    "pipeline_type": "${apiState.pipeline_type}",
    "prompt": "${apiState.prompt}",
    "negative_prompt": "${apiState.negative_prompt}",
    "guidance_scale": ${apiState.guidance_scale},
    "num_inference_steps": ${apiState.num_inference_steps},
    "width": ${apiState.width},
    "height": ${apiState.height},
    "seed": ${apiState.seed},
    "ref_image": ref_image,
    "ip_adapter_scale_start": ${apiState.ip_adapter_scale_start},
    "ip_adapter_scale_finish": ${apiState.ip_adapter_scale_finish},
    "ip_adapter_start": ${apiState.ip_adapter_start},
    "ip_adapter_finish": ${apiState.ip_adapter_finish},
    "canny_base_image": canny_image,
    "canny_controlnet_conditioning_scale": ${apiState.canny_controlnet_conditioning_scale},
    "canny_controlnet_guidance_start": ${apiState.canny_controlnet_guidance_start},
    "canny_controlnet_guidance_finish": ${apiState.canny_controlnet_guidance_finish},
    "canny_controlnet_conditioning_scale_start": ${apiState.canny_controlnet_conditioning_scale_start},
    "canny_controlnet_conditioning_scale_finish": ${apiState.canny_controlnet_conditioning_scale_finish},
    "image_overlay_use_canny": ${apiState.image_overlay_use_canny},
    "image_overlay_use_poisson": ${apiState.image_overlay_use_poisson},
    "apply_extra_poisson_blending_to_color_correction": ${apiState.apply_extra_poisson_blending_to_color_correction},
    "ref_image_canny_strength": ${apiState.ref_image_canny_strength},
}

headers = {
    "Content-Type": "application/json",
    "API-Key": api_key
}

response = requests.post(url, headers=headers, data=json.dumps(payload))

if response.status_code == 200:
    images = response.json().get('images')
    print(images)
else:
    print("Error:", response.status_code, response.text)
`;
}

export type GetSampleApiRequestCodeProps = {
  language: ApiLanguage;
  apiState: GenerateImageApiState;
  apiKey?: string;
};

function getSampleApiRequestCodeDefault({
  language,
  apiState,
  apiKey,
}: GetSampleApiRequestCodeProps) {
  if (!isDefaultGenerateImageApiState(apiState)) {
    return "";
  }
  if (language === ApiLanguage.Javascript) {
    return getJavascriptCodeDefault(apiState, apiKey);
  } else if (language === ApiLanguage.Python) {
    return getPythonCodeDefault(apiState, apiKey);
  } else if (language === ApiLanguage.JavascriptNodeJs) {
    return getNodeJsCodeDefault(apiState, apiKey);
  } else if (language === ApiLanguage.Typescript) {
    return getTypescriptCodeDefault(apiState, apiKey);
  }
  return getJavascriptCodeDefault(apiState, apiKey);
}

function getSampleApiRequestCodeCanny({
  language,
  apiState,
  apiKey,
}: GetSampleApiRequestCodeProps) {
  if (!isCannyGenerateImageApiState(apiState)) {
    return "";
  }

  if (language === ApiLanguage.Javascript) {
    return getJavascriptCodeCanny(apiState, apiKey);
  } else if (language === ApiLanguage.Python) {
    return getPythonCodeCanny(apiState, apiKey);
  } else if (language === ApiLanguage.JavascriptNodeJs) {
    return getNodeJsCodeCanny(apiState, apiKey);
  } else if (language === ApiLanguage.Typescript) {
    return getTypescriptCodeCanny(apiState, apiKey);
  }
  return getJavascriptCodeCanny(apiState, apiKey);
}

function getSampleApiRequestCodeRefDefault({
  language,
  apiState,
  apiKey,
}: GetSampleApiRequestCodeProps) {
  if (!isRefDefaultGenerateImageApiState(apiState)) {
    return "";
  }

  if (language === ApiLanguage.Javascript) {
    return getJavascriptCodeRefDefault(apiState, apiKey);
  } else if (language === ApiLanguage.Python) {
    return getPythonCodeRefDefault(apiState, apiKey);
  } else if (language === ApiLanguage.JavascriptNodeJs) {
    return getNodeJsCodeRefDefault(apiState, apiKey);
  } else if (language === ApiLanguage.Typescript) {
    return getTypescriptCodeRefDefault(apiState, apiKey);
  }
  return getJavascriptCodeRefDefault(apiState, apiKey);
}

function getSampleApiRequestCodeRefCanny({
  language,
  apiState,
  apiKey,
}: GetSampleApiRequestCodeProps) {
  if (!isRefCannyGenerateImageApiState(apiState)) {
    return "";
  }

  if (language === ApiLanguage.Javascript) {
    return getJavascriptCodeRefCanny(apiState, apiKey);
  } else if (language === ApiLanguage.Python) {
    return getPythonCodeRefCanny(apiState, apiKey);
  } else if (language === ApiLanguage.JavascriptNodeJs) {
    return getNodeJsCodeRefCanny(apiState, apiKey);
  } else if (language === ApiLanguage.Typescript) {
    return getTypescriptCodeRefCanny(apiState, apiKey);
  }
  return getJavascriptCodeRefCanny(apiState, apiKey);
}

export function getSampleApiRequestCode({ apiState, ...props }: GetSampleApiRequestCodeProps) {
  const { pipeline_type: pipelineType } = apiState;

  if (pipelineType === ApiPipelineType.Default) {
    return getSampleApiRequestCodeDefault({
      apiState,
      ...props,
    });
  } else if (pipelineType === ApiPipelineType.Canny) {
    return getSampleApiRequestCodeCanny({
      apiState,
      ...props,
    });
  } else if (pipelineType === ApiPipelineType.RefDefault) {
    return getSampleApiRequestCodeRefDefault({
      apiState,
      ...props,
    });
  } else if (pipelineType === ApiPipelineType.RefCanny) {
    return getSampleApiRequestCodeRefCanny({
      apiState,
      ...props,
    });
  }

  return "";
}
