import React from "react";
import { Navigate } from "../components/navigate";
import { LeftPanelSectionContainer } from "../base";
import { NumberSlider } from "../components/number-slider";
import { editorContextStore } from "contexts/editor-context";
import {
  EditImageProcessType,
  StartUpscaleV2Job,
  UiDisplayMessageDialogEventHandler,
} from "@/core/common/types";
import { OtherProcessRunningButton } from "./buttons";
import { ProgressBar } from "../components/progress-bar";
import { classNames } from "@/core/utils/classname-utils";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
} from "@/components/constants/class-names";
import { isStaticImageObject } from "@/core/utils/type-guards";
import {
  createObjectEditImageProgressController,
  EditImageProgressController,
  ObjectWithProgress,
} from "./edit-image-process";
import { AnalyticsConfig } from "@/analytics/config";
import { isUserSubscriptionTierFree } from "@/core/utils/quota-utils";
import { isColorCorrectV2QuotasAvaialable } from "@/core/utils/color-correct-v2-utils";

function UpscaleV2PrimaryButton({
  isProcessing,
  isOtherProcessRunning,
  editImageProcessType,
  progress,
  onRenderStart,
  onRenderCancel,
  disabled = false,
}: {
  isProcessing: boolean;
  isOtherProcessRunning: boolean;
  editImageProcessType: EditImageProcessType | undefined;
  progress: number;
  onRenderStart: () => void;
  onRenderCancel: () => void;
  disabled?: boolean;
}) {
  if (isOtherProcessRunning) {
    return <OtherProcessRunningButton type={editImageProcessType} />;
  }

  if (isProcessing) {
    return (
      <ProgressBar
        progress={progress}
        cancellable
        cancelMessage="Stop upscale"
        onClick={onRenderCancel}
      />
    );
  }

  if (disabled) {
    return (
      <button className={classNames(PrimaryButtonClassNameDisabled, "cursor-not-allowed")}>
        Upscale Disabled
      </button>
    );
  }

  return (
    <button className={classNames(PrimaryButtonClassName)} onClick={onRenderStart}>
      Upscale Creative
    </button>
  );
}

const upscaleV2EditImageProcessType = "upscale-premium";

export function LeftPanelUpscaleV2() {
  const activeObject = editorContextStore((state) => state.activeObject);
  const upscaleV2Creativity = editorContextStore((state) => state.upscaleV2Creativity);
  const setUpscaleV2Creativity = editorContextStore((state) => state.setUpscaleV2Creativity);

  const upscaleV2CreativityPercent = upscaleV2Creativity * 100;
  const setUpscaleV2CreativityPercent = React.useCallback(
    (value: number) => {
      setUpscaleV2Creativity(Math.round(value) * 0.01);
    },
    [setUpscaleV2Creativity],
  );

  const [disabled, setDisabled] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [editImageProcessType, setEditImageProcessType] = React.useState<EditImageProcessType>();
  const [editImageProgressController, setEditImageProgressController] = React.useState<
    EditImageProgressController | undefined
  >(undefined);
  const [progress, setProgress] = React.useState(0);

  const isOtherProcessRunning = React.useMemo(
    () => editImageProcessType != null && editImageProcessType !== upscaleV2EditImageProcessType,
    [editImageProcessType],
  );
  const editor = editorContextStore((state) => state.editor);
  const userQuotas = editorContextStore((state) => state.userQuotas);

  const handleStartUpscaleV2Job = React.useCallback(() => {
    if (!isColorCorrectV2QuotasAvaialable(userQuotas)) {
      editor?.emit<UiDisplayMessageDialogEventHandler>(
        "ui:display-message-dialog",
        "quota-subscribe",
        {
          title: "You have no upscale quota left.",
          header: "Subscribe to get unlimited upscale quotas.",
        },
      );

      return;
    }

    if (!isStaticImageObject(activeObject)) {
      return;
    }

    setEditImageProcessType(upscaleV2EditImageProcessType);

    setIsProcessing(true);

    setEditImageProgressController(
      createObjectEditImageProgressController({
        type: upscaleV2EditImageProcessType,
        object: activeObject,
        creativity: upscaleV2Creativity,
      }),
    );

    editorContextStore.getState().analytics.track(AnalyticsConfig.EditImageToolButtonInteraction, {
      interactionTarget: "Upscale Creative Button",
    });
  }, [editor, userQuotas, upscaleV2Creativity, activeObject]);

  React.useEffect(() => {
    if (!editor) {
      return;
    }

    editor.on<StartUpscaleV2Job>("upscale-v2:start-job", handleStartUpscaleV2Job);

    return () => {
      editor.off<StartUpscaleV2Job>("upscale-v2:start-job", handleStartUpscaleV2Job);
    };
  }, [editor, handleStartUpscaleV2Job]);

  React.useEffect(() => {
    if (isStaticImageObject(activeObject)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    const editImageProgressController = (activeObject as ObjectWithProgress)
      ?.editImageProgressController;

    setEditImageProgressController(editImageProgressController);
  }, [activeObject]);

  React.useEffect(() => {
    if (!editImageProgressController || editImageProgressController.isDestroyed) {
      return;
    }

    setIsProcessing(true);

    setEditImageProcessType(editImageProgressController.type);

    return editImageProgressController.subscribeToProgress(
      setProgress,
      () => {
        setProgress(1);
      },
      () => {
        setIsProcessing(false);
      },
    );
  }, [editImageProgressController]);

  return (
    <div className="flex flex-col">
      <Navigate />
      <LeftPanelSectionContainer className="flex flex-col gap-4">
        <NumberSlider
          name="Creativity"
          nameClassName="min-w-[95px] text-sm font-semibold"
          value={upscaleV2CreativityPercent}
          setValue={setUpscaleV2CreativityPercent}
          defaultValue={50}
          min={0}
          max={100}
          step={10}
        />
        <UpscaleV2PrimaryButton
          disabled={disabled}
          progress={progress}
          isProcessing={isProcessing}
          editImageProcessType={editImageProcessType}
          isOtherProcessRunning={isOtherProcessRunning}
          onRenderStart={() => {
            handleStartUpscaleV2Job();
          }}
          onRenderCancel={() => {
            editImageProgressController?.destroy();
            setIsProcessing(false);
          }}
        />
      </LeftPanelSectionContainer>
    </div>
  );
}
