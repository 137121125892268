import React, { useEffect, useState, useCallback, useRef } from "react";
import { Upload } from "lucide-react";

import { classNames } from "@/core/utils/classname-utils";

export function useFileDrop({
  handleDropFiles,
}: {
  handleDropFiles: (files: FileList, e: React.DragEvent<HTMLDivElement>) => void;
}) {
  const [isDragging, setIsDragging] = useState(false);

  const dragCounterRef = React.useRef(0);

  const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const onDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    dragCounterRef.current++;
    if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);

  const onDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    dragCounterRef.current--;
    if (dragCounterRef.current > 0) {
      return;
    }

    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragging(false);
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleDropFiles?.(e.dataTransfer.files, e);
        e.dataTransfer.clearData();
        dragCounterRef.current = 0;
      }
    },
    [handleDropFiles],
  );

  return {
    isDragging,
    setIsDragging,
    onDragEnter,
    onDragLeave,
    onDragOver: handleDrag,
    onDrop: handleDrop,
  };
}

export function DragAndDropOverlay({
  bindToWindow = false,
  children,
  overlayClassName = "",
  containerClassName = "",
  disabled = false,
  handleDropFiles,
}: {
  bindToWindow?: boolean;
  children?: React.ReactNode;
  overlayClassName?: string;
  containerClassName?: string;
  disabled?: boolean;
  handleDropFiles: (files: FileList, e: React.DragEvent<HTMLDivElement>) => void;
}) {
  const { isDragging, onDragEnter, onDragLeave, onDragOver, onDrop } = useFileDrop({
    handleDropFiles: (files, e) => {
      handleDropFiles(files, e);
    },
  });
  const dropContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleWindowDragEnter = (e: Event) => {
      if (!disabled && e instanceof DragEvent) {
        onDragEnter(e as unknown as React.DragEvent<HTMLDivElement>);
      }
    };
    const handleWindowDragLeave = (e: Event) => {
      if (!disabled && e instanceof DragEvent) {
        onDragLeave(e as unknown as React.DragEvent<HTMLDivElement>);
      }
    };
    const handleWindowDragOver = (e: Event) => {
      if (!disabled && e instanceof DragEvent) {
        onDragOver(e as unknown as React.DragEvent<HTMLDivElement>);
      }
    };
    const handleWindowDrop = (e: Event) => {
      if (!disabled && e instanceof DragEvent) {
        onDrop(e as unknown as React.DragEvent<HTMLDivElement>);
      }
    };

    const bindContainer = bindToWindow ? window : dropContainer.current;

    if (bindContainer) {
      bindContainer.addEventListener("dragenter", handleWindowDragEnter);
      bindContainer.addEventListener("dragleave", handleWindowDragLeave);
      bindContainer.addEventListener("dragover", handleWindowDragOver);
      bindContainer.addEventListener("drop", handleWindowDrop);
    }

    return () => {
      if (bindContainer) {
        bindContainer.removeEventListener("dragenter", handleWindowDragEnter);
        bindContainer.removeEventListener("dragleave", handleWindowDragLeave);
        bindContainer.removeEventListener("dragover", handleWindowDragOver);
        bindContainer.removeEventListener("drop", handleWindowDrop);
      }
    };
  }, [handleDropFiles, disabled, bindToWindow, onDragEnter, onDragLeave, onDragOver, onDrop]);

  return (
    <div ref={dropContainer} className={classNames(containerClassName)}>
      {children}
      <div
        className={classNames(
          "pointer-events-none transition-all duration-300 border-lime-500 bg-lime-500 bg-opacity-20 z-[9999]",
          overlayClassName,
          isDragging ? "border-4 opacity-100" : "border-0 opacity-0",
        )}
      >
        <Upload
          className={classNames(
            "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/5 h-auto text-lime-500 opacity-40",
          )}
        />
      </div>
    </div>
  );
}
