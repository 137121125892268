export const DEFAULT_ORIGIN_URL = "https://app.flair.ai";
export const EMAIL_LINK_SIGNIN = "emailLinkSignin";

export const PROJECTS = "projects";
export const NEW_PROJECT = "newProject";
export const NEW_TRYON_PROJECT = "newTryOnProject";
export const CUSTOM_MODELS = "models";
export const NEW_CUSTOM_MODEL = "newModel";
export const TEMPLATES = "templates";
export const VIDEOS = "videos";
export const ONBOARDING = "onboarding";
