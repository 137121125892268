import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, Ref } from "react";

import { classNames } from "@/core/utils/classname-utils";

export interface MultiSelectListHandle {
  clearSelection: () => void;
  selectAll: () => void;
}

interface MultiSelectListProps {
  children: React.ReactNode[];
  listClassName?: string;
  itemClassName?: string;
  checkboxContainerClassName?: string;
  checkboxClassName?: string;
  onSelectionChange?: (selectedItems: React.ReactNode[], previousSelectedIndexes: number[]) => void;
}

export const MultiSelectList = forwardRef(function MultiSelectList<T>(
  {
    children,
    listClassName = "",
    itemClassName = "",
    checkboxContainerClassName = "",
    checkboxClassName = "",
    onSelectionChange,
  }: MultiSelectListProps,
  ref: Ref<MultiSelectListHandle>,
) {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const prevIndexes = useRef<number[]>([]);

  const handleSelectItem = (index: number) => {
    prevIndexes.current = [...selectedIndexes];
    setSelectedIndexes((prevSelectedIndexes) => {
      const newSelectedIndexes = prevSelectedIndexes.includes(index)
        ? prevSelectedIndexes.filter((i) => i !== index)
        : [...prevSelectedIndexes, index];

      return newSelectedIndexes;
    });
  };

  useEffect(() => {
    if (!onSelectionChange) {
      return;
    }

    const selectedItems = children.filter((_, index) => selectedIndexes.includes(index));
    onSelectionChange(selectedItems, prevIndexes.current);
  }, [selectedIndexes, children, onSelectionChange]);

  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      setSelectedIndexes([]);
    },
    selectAll: () => {
      setSelectedIndexes(children.map((_, index) => index));
    },
  }));

  return (
    <div>
      <ul className={classNames("list-none", listClassName)}>
        {children.map((child, index) => (
          <li key={index} className={classNames("relative", itemClassName)}>
            {child}
            <div
              className={classNames("relative", checkboxContainerClassName)}
              onClick={() => handleSelectItem(index)}
            >
              <input
                type="checkbox"
                className={classNames("absolute", checkboxClassName)}
                checked={selectedIndexes.includes(index)}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
});
