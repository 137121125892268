import React from "react";
import { NavBarContainer } from "components/navbar/navbar-container";
import { NavBarUserProfile } from "components/navbar/user-profile";
import { Logo } from "components/logo";
import { ChevronLeft, SquarePen } from "lucide-react";
import { NavBarUpgrade } from "components/navbar/upgrade";
import { useNavigate } from "react-router-dom";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { NavbarDropdownContent, NavbarDropdownItem } from "components/navbar/navbar-dropdown";
import { editorContextStore } from "contexts/editor-context";
import { CustomModelInfo, StateUpdater } from "@/core/common/types";
import { cleanupText } from "@/core/utils/string-utils";
import { useCanUserEditCustomModel } from "hooks/use-custom-models-effect";
import { CUSTOM_MODELS } from "components/constants/routes";

function CustomModelNameInput({
  modelId,
  modelInfo,
  setModelInfo,
  disableEdit = false,
}: {
  modelId?: string;
  modelInfo?: CustomModelInfo;
  setModelInfo: (value: StateUpdater<CustomModelInfo | undefined>) => void;
  disableEdit?: boolean;
}) {
  const backend = editorContextStore((state) => state.backend);
  const [isEditing, setEditing] = React.useState(false);

  const modelDisplayName = modelInfo?.displayName || "";

  const [name, setName] = React.useState(modelDisplayName);
  const navigate = useNavigate();
  const textInputRef = React.useRef<HTMLInputElement | null>(null);
  const isEscapeRef = React.useRef(false);

  React.useEffect(() => {
    setName(modelDisplayName);
  }, [modelDisplayName]);

  React.useEffect(() => {
    if (isEditing) {
      textInputRef.current?.focus();
      textInputRef.current?.select?.();
    }
  }, [isEditing]);

  const updateCustomModelModelName = React.useCallback(
    (value: string) => {
      if (!modelId) {
        return;
      }

      setName(value);

      setModelInfo((prevInfo) =>
        prevInfo
          ? {
              ...prevInfo,
              displayName: value,
            }
          : undefined,
      );

      backend?.updateCustomModelInfo({
        modelId,
        modelInfo: {
          displayName: value,
        },
      });
    },
    [backend, modelId, setModelInfo],
  );

  const commitModelName = React.useCallback(() => {
    const nameCleanup = cleanupText(name);
    if (!isEscapeRef.current && nameCleanup?.length && nameCleanup !== modelDisplayName) {
      updateCustomModelModelName(nameCleanup);
    } else {
      setName(modelDisplayName);
    }
    isEscapeRef.current = false;
  }, [updateCustomModelModelName, name, modelDisplayName, setName]);

  if (disableEdit) {
    return (
      <div className="flex flex-row justify-center items-center text-sm text-zinc-500">
        <div
          className="text-zinc-500 hover:text-zinc-200 select-none cursor-default transition-colors"
          onClick={() => {
            navigate(`/${CUSTOM_MODELS}`);
          }}
        >
          Models
        </div>
        <div className="px-1 select-none">/</div>
        <div className="text-zinc-300 hover:text-zinc-200 select-none cursor-default transition-colors">
          {modelInfo?.displayName || "Custom Model"}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row justify-center items-center text-sm text-zinc-500">
      {isEditing ? (
        <input
          ref={textInputRef}
          type="text"
          name="filename"
          value={name}
          className="max-w-[50vw] outline outline-1 rounded-sm focus:outline-zinc-800 text-zinc-300 bg-transparent focus-visible:outline-none"
          onChange={(e) => {
            setName(e.currentTarget?.value || "");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setName(e.currentTarget?.value || "");
              setEditing(false);
              commitModelName();
            } else if (e.key === "Escape") {
              setEditing(false);
              isEscapeRef.current = true;
            }
          }}
          onBlur={() => {
            setEditing(false);
            commitModelName();
          }}
        />
      ) : (
        <>
          <div
            className="text-zinc-500 hover:text-zinc-200 select-none cursor-default transition-colors"
            onClick={() => {
              navigate(`/${CUSTOM_MODELS}`);
            }}
          >
            Models
          </div>
          <div className="px-1 select-none">/</div>
          <div
            className="text-zinc-300 hover:text-zinc-200 select-none cursor-default transition-colors max-w-[40vw] truncate"
            onDoubleClick={() => {
              setEditing(true);
            }}
          >
            {modelInfo?.displayName || "Custom Model"}
          </div>
          <button
            className="ml-1 pt-px text-zinc-500 hover:text-zinc-300 active:text-zinc-700 transition-colors cursor-pointer"
            onClick={() => {
              setEditing(true);
            }}
          >
            <SquarePen size={16} />
          </button>
        </>
      )}
    </div>
  );
}

function EditorHeader() {
  const modelId = editorContextStore((state) => state.customModelId);
  const modelInfo = editorContextStore((state) => state.customModelInfo);
  const setModelInfo = editorContextStore((state) => state.setCustomModelInfo);
  const canUserEdit = useCanUserEditCustomModel();

  return (
    <div className="w-full flex flex-row items-center justify-center">
      <CustomModelNameInput
        modelId={modelId}
        modelInfo={modelInfo}
        setModelInfo={setModelInfo}
        disableEdit={!canUserEdit}
      />
    </div>
  );
}

export function CreateCustomModelNavbar() {
  const navigate = useNavigate();
  return (
    <NavBarContainer>
      <div className="px-3 flex flex-col items-center justify-center">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Logo />
          </DropdownMenu.Trigger>
          <NavbarDropdownContent sideOffset={18}>
            <NavbarDropdownItem
              onClick={() => {
                navigate(`/${CUSTOM_MODELS}`);
              }}
            >
              <ChevronLeft size={16} />
              <span className="ml-3">Back to custom models</span>
            </NavbarDropdownItem>
          </NavbarDropdownContent>
        </DropdownMenu.Root>
      </div>
      <div className="flex-1 ml-18 flex flex-row justify-center items-center text-zinc-500">
        <EditorHeader />
      </div>
      <div className="flex flex-row items-center justify-center gap-2.5">
        <NavBarUpgrade />
        <NavBarUserProfile />
      </div>
    </NavBarContainer>
  );
}
