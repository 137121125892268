import React from "react";
import { fabric } from "fabric";
import { EditorCanvasModalContainer } from "../editor-modal/editor-canvas-modal";
import { editorContextStore } from "contexts/editor-context";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { removeLastFromImmutableList } from "@/core/utils/array-utils";
import {
  handleExitRegenrateProduct,
  resetRegenerateProductState,
} from "hooks/use-regenerate-product";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { ImageComponent } from "components/utils/image";
import { classNames } from "@/core/utils/classname-utils";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import { regenerateProductWithColorCorrectV2 } from "components/utils/regenerate-product-v2";
import { EditorActiveObject } from "@/core/common/interfaces";

function GenerationResultCompareSlider({
  originalImageSrc,
  originalImageAlt = "Original image",
  resultImageSrc,
  resultImageAlt = "Result image",
}: {
  originalImageSrc?: string;
  originalImageAlt?: string;
  resultImageSrc?: string;
  resultImageAlt?: string;
}) {
  const originalPosition = originalImageSrc ? 50 : 0;
  const resultPosition = resultImageSrc ? -80 : 0;
  const position = 50 + originalPosition + resultPosition;

  return (
    <div className="w-full h-full flex items-center justify-center rounded-md border border-zinc-800">
      <ReactCompareSlider
        disabled={!originalImageSrc || !resultImageSrc}
        position={position}
        className="w-fit h-full"
        transition=".5s ease-in-out"
        itemOne={
          <div className="relative flex w-full h-full">
            <div
              className={classNames(
                "absolute left-0 top-0 m-2 px-3 py-1.5 rounded-full font-semibold text-xs select-none pointer-events-none  transition-colors",
                "bg-zinc-800/50 text-zinc-300",
              )}
            >
              {originalImageAlt}
            </div>
            <ReactCompareSliderImage src={originalImageSrc} alt={originalImageAlt} />
          </div>
        }
        itemTwo={
          <div className="relative flex w-full h-full">
            <div
              className={classNames(
                "absolute right-0 top-0 m-2 px-3 py-1.5 rounded-full font-semibold text-xs select-none pointer-events-none  transition-colors",
                "bg-zinc-800/50 text-zinc-300",
              )}
            >
              {resultImageAlt}
            </div>
            <ReactCompareSliderImage src={resultImageSrc} alt={resultImageAlt} />
          </div>
        }
      />
    </div>
  );
}

function GenerationResultItem({
  imageUrl,
  imageAlt = "Generation",
  loadingMessage = "Loading ...",
  className = "",
  loadedClassName = "",
  placeholderUrl,
  showSpinner = true,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  imageUrl?: string;
  placeholderUrl?: string;
  imageAlt?: string;
  loadingMessage?: string;
  loadedClassName?: string;
  showSpinner?: boolean;
}) {
  const [isImageLoaded, setImageLoaded] = React.useState(false);

  React.useEffect(() => {
    setImageLoaded(false);
  }, [imageUrl]);

  return (
    <div
      {...props}
      className={classNames(
        "relative rounded-md border border-zinc-800 overflow-hidden",
        isImageLoaded ? "cursor-auto" : "cursor-wait",
        className,
        isImageLoaded ? loadedClassName : "",
      )}
    >
      {!isImageLoaded && (
        <div className="absolute w-full h-full flex items-center justify-center select-none pointer-events-none bg-zinc-900/80">
          {showSpinner && (
            <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" className="mr-2" />
          )}
          <span className="text-xs truncate">{loadingMessage}</span>
        </div>
      )}
      {placeholderUrl && (
        <ImageComponent
          src={placeholderUrl}
          className="w-full h-full object-contain"
          alt={imageAlt}
          style={{
            display: isImageLoaded ? "none" : "block",
          }}
        />
      )}
      <img
        src={imageUrl}
        className="w-full h-full object-contain"
        alt={imageAlt}
        style={{
          display: isImageLoaded ? "block" : "none",
        }}
        onLoad={() => setImageLoaded(true)}
        onError={() => setImageLoaded(false)}
      />
      {children}
    </div>
  );
}

export function RegenerateProductModelV2() {
  const editor = editorContextStore((state) => state.editor);
  const backend = editorContextStore((state) => state.backend);
  const uid = editorContextStore((state) => state.user?.uid);

  const editingObjectId = editorContextStore((state) => state.editingObjectId);
  const editingObject = React.useMemo(
    () =>
      editingObjectId &&
      (editor?.objects.findOneById(editingObjectId) as fabric.Object | undefined),
    [editor, editingObjectId],
  );

  const regenerateProductReferenceImagePath = editorContextStore(
    (state) => state.regenerateProductReferenceImagePath,
  );

  const editingObjectUrl = React.useMemo(
    () =>
      regenerateProductReferenceImagePath
        ? regenerateProductReferenceImagePath
        : isStaticImageObject(editingObject)
          ? editingObject.getSrc()
          : undefined,
    [editingObject, regenerateProductReferenceImagePath],
  );

  const regenerateProductResults = editorContextStore((state) => state.regenerateProductResults);
  const regenerateProductNumImages = editorContextStore(
    (state) => state.regenerateProductNumImages,
  );
  const regenerateProductRenderState = editorContextStore(
    (state) => state.regenerateProductRenderState,
  );
  // const regenerateProductEraseMaskImagePath = editorContextStore(state => state.regenerateProductEraseMaskImagePath);
  const setGeneratedResults = editorContextStore((state) => state.setRegenerateProductResults);

  const generatedResults = React.useMemo(() => {
    if (regenerateProductRenderState === "rendering") {
      return regenerateProductResults;
    }

    while (regenerateProductResults.length < regenerateProductNumImages) {
      regenerateProductResults.push({
        imageUrl: undefined,
        isSelected: false,
      });
    }

    return regenerateProductResults;
  }, [regenerateProductResults, regenerateProductNumImages, regenerateProductRenderState]);

  React.useEffect(() => {
    if (!editor || !backend) {
      return;
    }

    if (!uid) {
      debugError("User is not logged in yet.");
      return;
    }

    if (!editingObject || !isStaticImageObject(editingObject)) {
      debugError("Editing object is invalid.");
      return;
    }

    regenerateProductWithColorCorrectV2({
      uid,
      backend,
      editor,
      object: editingObject,
    });
  }, [uid, editor, backend, editingObject]);

  const handleExitModal = (addImages = false) => {
    const {
      activeLeftPanels,
      setEditingObjectId,
      setActiveLeftPanels,
      setRegenerateProductResults,
    } = editorContextStore.getState();

    setEditingObjectId(undefined);

    if (
      activeLeftPanels.length >= 2 &&
      activeLeftPanels[activeLeftPanels.length - 2] === "ReplaceProduct"
    ) {
      // Do not reset the state
      setActiveLeftPanels((prevLeftPanels) => {
        return removeLastFromImmutableList(prevLeftPanels, "Assets", 2);
      });
    } else {
      setActiveLeftPanels((prevLeftPanels) => {
        return removeLastFromImmutableList(prevLeftPanels, "Assets");
      });
    }

    resetRegenerateProductState();
    setRegenerateProductResults([]);

    debugLog(`Exit regenerate product modal; add images? ${addImages}`);

    handleExitRegenrateProduct({
      editor,
      editingObject: editingObject as EditorActiveObject,
      addImages,
      generatedResults,
    });
  };

  const activeResult = React.useMemo(() => {
    const resultsWithLabel = generatedResults.map((result, index) => ({
      ...result,
      label: `Generated ${index + 1}`,
    }));
    const allResults = resultsWithLabel.filter((result) => result.imageUrl);
    const selectedResults = allResults.filter((result) => result.isSelected);

    const result = selectedResults[selectedResults.length - 1] || allResults[allResults.length - 1];

    return result;
  }, [generatedResults]);

  return (
    <EditorCanvasModalContainer onExit={() => handleExitModal(true)}>
      <div className="flex-1 min-h-0 relative flex flex-col gap-2 items-center justify-center">
        <div className="group w-full h-[80%] xl:h-[75%]">
          <GenerationResultCompareSlider
            originalImageSrc={editingObjectUrl}
            resultImageSrc={activeResult?.imageUrl}
            resultImageAlt={activeResult?.label}
          />
        </div>
        <div className="min-h-0 flex-1 flex flex-row items-center gap-2">
          {generatedResults.map(({ imageUrl, isSelected }, index) => (
            <GenerationResultItem
              key={`Generated ${index}`}
              imageAlt={`Generated ${index}`}
              className="h-full"
              imageUrl={imageUrl}
              placeholderUrl={editingObjectUrl}
              showSpinner={regenerateProductRenderState === "rendering"}
              loadingMessage={
                regenerateProductRenderState === "rendering"
                  ? "Regenerating ..."
                  : "Click generate button to regenerate the product"
              }
              loadedClassName={classNames(
                "group shadow-lg-center cursor-pointer transition-colors active:border-lime-700",
                isSelected
                  ? "border-lime-400 shadow-lime-500/20"
                  : "hover:border-lime-500 shadow-lime-500/0",
              )}
              onClick={() => {
                if (!imageUrl) {
                  return;
                }
                setGeneratedResults((results) => {
                  const newResults = results.slice();

                  return newResults.map((result, resultIndex) => {
                    if (resultIndex === index) {
                      return {
                        imageUrl: result.imageUrl,
                        isSelected: !result.isSelected,
                      };
                    } else {
                      return {
                        imageUrl: result.imageUrl,
                        isSelected: false,
                      };
                    }
                  });
                });
              }}
            >
              <div
                className={classNames(
                  "absolute left-0 top-0 m-2 px-3 py-1.5 rounded-full font-semibold text-xs select-none pointer-events-none  transition-colors",
                  isSelected
                    ? "bg-lime-500 text-zinc-900 group-hover:bg-lime-500 shadow"
                    : "bg-zinc-800/80 text-zinc-300 group-hover:bg-zinc-800",
                )}
              >
                Generated {index + 1}
              </div>
            </GenerationResultItem>
          ))}
        </div>
      </div>
    </EditorCanvasModalContainer>
  );
}
