import React from "react";
import { classNames } from "@/core/utils/classname-utils";
import { editorContextStore } from "contexts/editor-context";

function EmptyImage() {
  return (
    <div className="w-full h-full flex items-center justify-center text-xl text-zinc-500">
      Generated images will appear here
    </div>
  );
}

export function TryOnRenderResultViewer({
  className = "",
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const [activeImageIndex, setActiveImageIndex] = React.useState(0);

  const imageUrls = editorContextStore((state) => state.tryOnRenderResults);
  const tryOnEditorState = editorContextStore((state) => state.tryOnEditorState);

  const baseImageClasses = "object-contain w-full min-h-full transition-[filter]";

  const images = React.useMemo(
    () =>
      imageUrls.map(({ imageUrl }, index) => (
        <img key={imageUrl} src={imageUrl} alt={`Render ${index}`} className={baseImageClasses} />
      )),
    [imageUrls],
  );

  const activeImage = React.useMemo(() => {
    if (images.length <= 0 || !images[activeImageIndex]) {
      return null;
    }

    const blurClass = tryOnEditorState === "upscaling" ? "blur-sm" : "blur-none";
    return React.cloneElement(images[activeImageIndex], {
      className: `${baseImageClasses} ${blurClass}`,
    });
  }, [images, activeImageIndex, tryOnEditorState]);

  return (
    <div className={classNames(className, "relative")} {...props}>
      {children}
      {activeImage || <EmptyImage />}
    </div>
  );
}
