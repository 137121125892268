import React from "react";
import { classNames } from "@/core/utils/classname-utils";
import { PrimaryButtonClassNameDisabled } from "components/constants/class-names";

import { EditImageProcessType, EditImageProcessTypeName } from "@/core/common/types";

export function OtherProcessRunningButton({ type }: { type: EditImageProcessType | undefined }) {
  const name = type ? EditImageProcessTypeName[type].toLowerCase() : "undefined";
  return (
    <div className={classNames(PrimaryButtonClassNameDisabled, "cursor-wait")}>
      Waiting for {name} tool to finish
    </div>
  );
}
