import React from "react";
import { NavBarContainer } from "components/navbar/navbar-container";
import { NavBarUserProfile } from "components/navbar/user-profile";
import { Logo } from "components/logo";
import { NavBarUpgrade } from "components/navbar/upgrade";

export function DashboardNavBar() {
  return (
    <NavBarContainer>
      <div className="px-3">
        <Logo />
      </div>
      <div className="flex-1 ml-8 flex flex-row justify-center items-center text-sm text-zinc-500"></div>
      <div className="flex flex-row items-center justify-center gap-2">
        <NavBarUpgrade />
        <NavBarUserProfile />
      </div>
    </NavBarContainer>
  );
}
