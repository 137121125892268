// Homegoods workflow JSON
import { SampleProjectScene } from "@/core/common/scene";
import { EditorAssetContentType } from "@/core/common/types";

export const HomegoodsProject: SampleProjectScene = {
  metadata: {
    animated: false,
  },
  objects: {
    // "nPR1RZDERoydA5ps5Mna-": {
    //   "strokeWidth": 0,
    //   "metadata": {
    //     "surrounding": "vase with plant",
    //     "surroundingPrefix": "with",
    //     "colorDisplayType": "color-and-shape",
    //     "imageType": "hed",
    //     "hedUrl": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b35172ea-fe2e-4821-7607-e8cda5232900/public"
    //   },
    //   "skewX": 0,
    //   "skewY": 0,
    //   "type": "StaticImage",
    //   "scaleX": 0.82,
    //   "scaleY": 0.83,
    //   "top": -1265.86,
    //   "angle": 0,
    //   "id": "nPR1RZDERoydA5ps5Mna-",
    //   "flipX": false,
    //   "flipY": false,
    //   "height": 831,
    //   "zIndex": 4,
    //   "cropX": 0,
    //   "generationId": "",
    //   "cropY": 0,
    //   "visible": true,
    //   "originX": "left",
    //   "originY": "top",
    //   "left": -1768.5,
    //   "name": "StaticImage",
    //   "width": 300,
    //   "opacity": 1,
    //   "asset": {
    //     "path": "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b35172ea-fe2e-4821-7607-e8cda5232900/public",
    //     "type": "image-url"
    //   }
    // },
    Lubxq_Et8qk3fXYIdUWMJ: {
      strokeWidth: 0,
      metadata: {
        surrounding: "grey vase with white flower",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/dcd05f85-59d0-4f60-6e2d-e5b22d5a0f00/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.5,
      scaleY: 0.49,
      top: 338.7,
      angle: 180,
      id: "Lubxq_Et8qk3fXYIdUWMJ",
      flipX: false,
      flipY: true,
      height: 710,
      zIndex: 1,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 472.53,
      name: "StaticImage",
      width: 570,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/dcd05f85-59d0-4f60-6e2d-e5b22d5a0f00/public",
        type: "image-url",
      },
    },
    NB_sGShDHbfuxevU5Geom: {
      strokeWidth: 0,
      metadata: {
        surrounding: "white flower",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fef0d075-9067-4672-167c-ea61eaeb4f00/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.29,
      scaleY: 0.29,
      top: 226.65,
      angle: 0,
      id: "NB_sGShDHbfuxevU5Geom",
      flipX: false,
      flipY: false,
      height: 504,
      zIndex: 6,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 743.16,
      name: "StaticImage",
      width: 495,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fef0d075-9067-4672-167c-ea61eaeb4f00/public",
        type: "image-url",
      },
    },
    ZJeXp3Y_lvHGHFZnnzI2z: {
      strokeWidth: 0,
      metadata: {
        surrounding: "pale cluster of leaves",
        surroundingPrefix: "with",
        colorDisplayType: "rgb",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ab94ed9b-cbf9-4f45-6031-0d85b9355200/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.32,
      scaleY: 0.32,
      top: 338.78,
      angle: 0,
      id: "ZJeXp3Y_lvHGHFZnnzI2z",
      flipX: false,
      flipY: false,
      height: 500,
      zIndex: 7,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 71.06,
      name: "StaticImage",
      width: 500,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ab94ed9b-cbf9-4f45-6031-0d85b9355200/public",
        type: "image-url",
      },
    },
    z1du7uqxx4jVIrpIadPNa: {
      strokeWidth: 0,
      metadata: {
        surrounding: "branch of dried leaves",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b5633cb7-014e-401f-bdcb-da32a2900800/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.58,
      scaleY: 0.58,
      top: -321.85,
      angle: 5,
      id: "z1du7uqxx4jVIrpIadPNa",
      flipX: false,
      flipY: false,
      height: 612,
      zIndex: 2,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 501.68,
      name: "StaticImage",
      width: 408,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b5633cb7-014e-401f-bdcb-da32a2900800/public",
        type: "image-url",
      },
    },
    xW4rkkPWauMoOuf6JixVu: {
      strokeWidth: 0,
      metadata: {
        surrounding: "ceramic vase",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "subject",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fcc44137-acc0-4591-504e-6d537a9cf200/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.64,
      scaleY: 0.64,
      top: -84.55,
      angle: 0,
      id: "xW4rkkPWauMoOuf6JixVu",
      flipX: false,
      flipY: false,
      height: 700,
      zIndex: 5,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 291.55,
      name: "StaticImage",
      width: 700,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fcc44137-acc0-4591-504e-6d537a9cf200/public",
        type: "image-url",
      },
    },
    yosYFJ2hJRqbFarCB305e: {
      strokeWidth: 0,
      metadata: {
        placementPrefix: "on",
        colorDisplayType: "color-and-shape",
        placement: "sliced tree trunk",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/50ec63b2-c3ed-4782-0814-a374e1eb8a00/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 1.51,
      scaleY: 1.51,
      top: 262.39,
      angle: 0,
      id: "yosYFJ2hJRqbFarCB305e",
      flipX: false,
      flipY: false,
      height: 145,
      zIndex: 0,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 122.66,
      name: "StaticImage",
      width: 522,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/50ec63b2-c3ed-4782-0814-a374e1eb8a00/public",
        type: "image-url",
      },
    },
    YxA3YZLGLlwAzSI_pPwfk: {
      strokeWidth: 0,
      metadata: {
        surrounding: "wooden bowl with stir",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/89d0ae51-4586-4d61-73e6-e52bd0cac800/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.54,
      scaleY: 0.58,
      top: 74.72,
      angle: 0,
      id: "YxA3YZLGLlwAzSI_pPwfk",
      flipX: false,
      flipY: false,
      height: 500,
      zIndex: 3,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      filters: [
        {
          mode: "add",
          color: "#140505",
          alpha: 1,
          type: "BlendColor",
        },
      ],
      left: 562.03,
      name: "StaticImage",
      width: 500,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/89d0ae51-4586-4d61-73e6-e52bd0cac800/public",
        type: "image-url",
      },
    },
  },
  name: "Untitled Project",
  generationFrame: {
    top: -424.03485168951033,
    left: -2.8368764916667715,
    referneceImage: {
      path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5da370bb-a1d8-4a53-21fa-ae6557979600/1024",
      type: "image-url",
    },
    width: 1024,
    id: "generation-frame",
    type: "GenerationFrame",
    promptTemplate: {
      words: [
        {
          autocompleteType: "subject",
          type: "input",
          value: "a white vase with flower",
        },
        {
          isAutoFilled: true,
          prefix: "with",
          autocompleteType: "surrounding",
          type: "input",
          value:
            "grey vase with white flower and branch of dried leaves and wooden bowl with stir and ceramic vase and white flower and pale cluster of leaves",
          objectIds: [
            "Lubxq_Et8qk3fXYIdUWMJ",
            "z1du7uqxx4jVIrpIadPNa",
            "YxA3YZLGLlwAzSI_pPwfk",
            "xW4rkkPWauMoOuf6JixVu",
            "NB_sGShDHbfuxevU5Geom",
            "ZJeXp3Y_lvHGHFZnnzI2z",
          ],
        },
        {
          prefix: "in front of",
          autocompleteType: "background",
          type: "input",
          value: "a natural beige background",
        },
        {
          isAutoFilled: true,
          prefix: "on",
          autocompleteType: "placement",
          type: "input",
          value: "sliced tree trunk",
          valueBeforeAutoFill: "circular platforms",
          objectIds: ["yosYFJ2hJRqbFarCB305e"],
        },
      ],
      style: "",
    },
    height: 1024,
  },
  id: "nTXvSkRR9X1lHSESUtIl",
  version: "462",
  frame: {
    width: 0,
    height: 0,
  },
};
