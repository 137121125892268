import React from "react";
import { X } from "lucide-react";
import { classNames } from "@/core/utils/classname-utils";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { editorContextStore } from "contexts/editor-context";

export function TryOnExitClothEditorButton() {
  return (
    <button
      className={classNames(
        SecondaryButtonClassNameInactive,
        "flex flex-row items-center justify-center",
      )}
      onClick={() => {
        editorContextStore.getState().setActiveLeftPanels(["Generate"]);
      }}
    >
      <X width={18} height={18} className="mr-2" />
      <span className="min-w-[150px] text-left">Exit Fashion Editor</span>
    </button>
  );
}
