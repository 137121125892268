import { DownloadImageResizeOption } from "@/core/common/types/download-image";
import { StaticImageElementType } from "@/core/common/types/elements";
import { ImageFormat, imageFormatToExtension, imageFormatToName } from "@/core/common/types/image";
import { classNames } from "@/core/utils/classname-utils";
import { cleanupText } from "@/core/utils/string-utils";
import {
  InputBoxClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
} from "components/constants/class-names";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { fabric } from "fabric";
import { Download } from "lucide-react";
import React, { useEffect } from "react";
import {
  downloadImageBlob,
  downloadImageDataUrl,
  getImageFormatFromBlob,
  getStaticImageFileStem,
} from "./data";
import { displayUiMessage } from "./display-message";
import {
  SelectOptionIcon,
  SelectOptionItem,
  SelectOptionValue,
  SelectOptions,
} from "./select-options";

export const DownloadImageButton = React.forwardRef(function DownloadImageButton(
  {
    className = "",
    isDisabled = false,
    isLoading = false,
    children,
    ...props
  }: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    isLoading?: boolean;
    isDisabled?: boolean;
  },
  forwardedRef: React.ForwardedRef<HTMLButtonElement>,
) {
  return (
    <button
      ref={forwardedRef}
      className={classNames(
        isDisabled
          ? PrimaryButtonClassNameDisabled
          : isLoading
            ? PrimaryButtonClassNameLoading
            : PrimaryButtonClassName,
        "flex flex-row justify-center text-center gap-2",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
});

const downloadImageFormatOptionItems: Record<string, SelectOptionItem<ImageFormat>[]> = {
  Format: [
    {
      name: imageFormatToName[ImageFormat.JPEG],
      value: ImageFormat.JPEG,
    },
    {
      name: imageFormatToName[ImageFormat.PNG],
      value: ImageFormat.PNG,
    },
    {
      name: imageFormatToName[ImageFormat.WEBP],
      value: ImageFormat.WEBP,
    },
  ],
};

const downloadImageResizeOptionItems: Record<
  string,
  SelectOptionItem<DownloadImageResizeOption>[]
> = {
  Scale: [
    {
      name: DownloadImageResizeOption.PointFiveX,
      value: DownloadImageResizeOption.PointFiveX,
    },
    {
      name: DownloadImageResizeOption.PointSevenFiveX,
      value: DownloadImageResizeOption.PointSevenFiveX,
    },
    {
      name: DownloadImageResizeOption.OneX,
      value: DownloadImageResizeOption.OneX,
    },
    {
      name: DownloadImageResizeOption.OnePointFiveX,
      value: DownloadImageResizeOption.OnePointFiveX,
    },
    {
      name: DownloadImageResizeOption.TwoX,
      value: DownloadImageResizeOption.TwoX,
    },
    {
      name: DownloadImageResizeOption.Threex,
      value: DownloadImageResizeOption.Threex,
    },
    {
      name: DownloadImageResizeOption.Width512,
      value: DownloadImageResizeOption.Width512,
    },
    {
      name: DownloadImageResizeOption.Width1024,
      value: DownloadImageResizeOption.Width1024,
    },
    {
      name: DownloadImageResizeOption.Width2048,
      value: DownloadImageResizeOption.Width2048,
    },
    // {
    //     name: DownloadImageResizeOption.Custom,
    //     value: DownloadImageResizeOption.Custom,
    // },
  ],
};

const convertResizeImageEndpointUrl = import.meta.env.VITE_CONVERT_IMAGE_API_URL;

type BackendImageFormat = "webp" | "jpeg" | "png";

const formatOptionToBackendImageFormat: Record<ImageFormat, BackendImageFormat> = {
  [ImageFormat.JPEG]: "jpeg",
  [ImageFormat.PNG]: "png",
  [ImageFormat.WEBP]: "webp",
};

type BackendResizeOptions = {
  target_width?: number;
  target_height?: number;
  target_scale?: number;
};

const resizeOptionToBackendResizeOptions: Record<DownloadImageResizeOption, BackendResizeOptions> =
  {
    [DownloadImageResizeOption.PointFiveX]: {
      target_scale: 0.5,
    },
    [DownloadImageResizeOption.PointSevenFiveX]: {
      target_scale: 0.75,
    },
    [DownloadImageResizeOption.OneX]: {
      target_scale: 1,
    },
    [DownloadImageResizeOption.OnePointFiveX]: {
      target_scale: 1.5,
    },
    [DownloadImageResizeOption.TwoX]: {
      target_scale: 2,
    },
    [DownloadImageResizeOption.Threex]: {
      target_scale: 3,
    },
    [DownloadImageResizeOption.Width512]: {
      target_width: 512,
    },
    [DownloadImageResizeOption.Width1024]: {
      target_width: 1024,
    },
    [DownloadImageResizeOption.Width2048]: {
      target_width: 2048,
    },
    // [DownloadImageResizeOption.Custom]: {
    //     // Custom resize options can be set dynamically
    // },
  };

type ConvertResizeImageArgs = {
  imageUrl: string;
  resizeOption: DownloadImageResizeOption;
  formatOption: ImageFormat;
};

async function tryDownloadImageWithoutConversion({
  imageUrl,
  formatOption,
  resizeOption,
  imageFileStem,
}: ConvertResizeImageArgs & {
  imageFileStem: string;
}): Promise<{
  downloaded: boolean;
}> {
  try {
    if (resizeOption !== DownloadImageResizeOption.OneX) {
      return {
        downloaded: false,
      };
    }

    const response = await fetch(imageUrl);

    if (!response.ok) {
      return {
        downloaded: false,
      };
    }

    const contentType = response.headers.get("Content-Type");

    const blob = await response.blob();

    const imageFormat = await getImageFormatFromBlob(blob);
    if (imageFormat === formatOption) {
      // We can directly download the image
      const strictExtension = imageFormatToExtension[imageFormat];

      await downloadImageBlob({
        blob,
        contentType,
        strictExtension,
        filename: imageFileStem,
      });

      return {
        downloaded: true,
      };
    }

    return {
      downloaded: false,
    };
  } catch (error) {
    console.error(error);
  }

  return {
    downloaded: false,
  };
}

enum ImageApiRequestType {
  ResizeConvert = "resize_convert",
}

type ResizeConvertRequest = {
  type: ImageApiRequestType.ResizeConvert;
  output_format: BackendImageFormat;
  image: string;
  target_width?: number;
  target_height?: number;
  target_scale?: number;
};

async function convertResizeImage({
  imageUrl: image,
  resizeOption,
  formatOption,
}: ConvertResizeImageArgs) {
  try {
    const outputType = formatOptionToBackendImageFormat[formatOption];

    const resizeOptions = resizeOptionToBackendResizeOptions[resizeOption];

    const request: ResizeConvertRequest = {
      type: ImageApiRequestType.ResizeConvert,
      output_format: outputType,
      image,
      ...resizeOptions,
    };

    const response = await fetch(convertResizeImageEndpointUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    if (!response.ok) {
      console.error(await response.text());
      displayUiMessage("Cannot call image conversion backend", "error");
      return image;
    }

    const data = await response.json();

    const outputImageUrl = data?.image;

    if (!outputImageUrl || typeof outputImageUrl !== "string") {
      displayUiMessage("Unknown error in the image conversion backend", "error");
      return image;
    }

    return outputImageUrl;
  } catch (error) {
    console.error(error);

    displayUiMessage(`Unable to convert the image.`, "error");
  }

  return image;
}

export type HandleDownloageImageArgs = {
  imageFileStem?: string;
  suffix?: string;
} & ConvertResizeImageArgs;

export async function handleDownloadImage({
  imageFileStem = "flair_image",
  suffix = "",
  ...convertResizeArgs
}: HandleDownloageImageArgs) {
  try {
    imageFileStem = suffix ? `${imageFileStem}-${suffix}` : imageFileStem;

    const { downloaded } = await tryDownloadImageWithoutConversion({
      imageFileStem,
      ...convertResizeArgs,
    });

    if (downloaded) {
      return;
    }

    const image = await convertResizeImage(convertResizeArgs);

    const extension = formatOptionToBackendImageFormat[convertResizeArgs.formatOption];

    await downloadImageDataUrl(image, imageFileStem, extension);
  } catch (error) {
    console.error(error);

    displayUiMessage(`Unable to download the image.`, "error");
  }
}

export const DownloadImageGroup = React.forwardRef(function DownloadImagesGroup(
  {
    className = "",
    imageObject,
    onMetadataChange,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    imageObject: fabric.StaticImage;
    onMetadataChange?: (metadata: any) => void;
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const [resizeOption, setResizeOption] = React.useState<DownloadImageResizeOption>(
    DownloadImageResizeOption.OneX,
  );
  const [formatOption, setFormatOption] = React.useState<ImageFormat>(ImageFormat.WEBP);
  const [isLoading, setIsLoading] = React.useState(false);

  const suffixInputRef = React.useRef<HTMLInputElement | null>(null);

  const imageFileStem = React.useMemo(
    () => getStaticImageFileStem(imageObject as any as fabric.Object),
    [imageObject],
  );

  useEffect(() => {
    if (!imageObject) {
      return;
    }

    const downloadOptionResize =
      (imageObject.metadata?.downloadOptionResize as DownloadImageResizeOption) ||
      DownloadImageResizeOption.OneX;
    const downloadOptionFormat =
      (imageObject.metadata?.downloadOptionFormat as ImageFormat) || ImageFormat.WEBP;

    if (suffixInputRef.current) {
      suffixInputRef.current.value = imageObject.metadata?.downloadOptionSuffix || "";
    }

    setResizeOption(downloadOptionResize);
    setFormatOption(downloadOptionFormat);
  }, [imageObject]);

  const handleResizeChange = (value: any) => {
    setResizeOption(value as DownloadImageResizeOption);

    onMetadataChange?.({
      ...(imageObject.metadata || {}),
      imageType: StaticImageElementType.Subject,
      downloadOptionResize: value,
    });
  };

  const handleFormatChange = (value: any) => {
    setFormatOption(value as ImageFormat);

    onMetadataChange?.({
      ...(imageObject.metadata || {}),
      imageType: StaticImageElementType.Subject,
      downloadOptionFormat: value,
    });
  };

  const handleSuffixChange = (value: string) => {
    const suffix = cleanupText(value);

    onMetadataChange?.({
      ...(imageObject.metadata || {}),
      imageType: StaticImageElementType.Subject,
      downloadOptionSuffix: suffix,
    });
  };

  return (
    <div ref={forwardedRef} className={classNames("flex flex-col gap-2", className)} {...props}>
      <div className="flex flex-row items-stretch gap-2">
        <SelectOptions<DownloadImageResizeOption>
          value={resizeOption}
          onValueChange={handleResizeChange}
          options={downloadImageResizeOptionItems}
          triggerProps={{
            className:
              "w-[60px] inline-flex flex-row items-center justify-start rounded px-3 text-[13px] leading-none gap-[5px] bg-zinc-900 text-xs text-zinc-300 border border-solid border-zinc-800 focus:border-zinc-600 hover:bg-zinc-800/30 data-[placeholder]:text-zinc-500 outline-none transition-colors",
          }}
          triggerChildren={
            <>
              <SelectOptionValue className="truncate">{resizeOption}</SelectOptionValue>
              <SelectOptionIcon />
            </>
          }
        />
        <input
          ref={suffixInputRef}
          type="text"
          className={classNames(InputBoxClassName, "flex-1 text-xs")}
          placeholder="Suffix"
          onBlur={(e) => {
            const suffix = cleanupText(e.currentTarget.value);

            e.currentTarget.value = suffix;

            handleSuffixChange(suffix);
          }}
        />
        <SelectOptions<ImageFormat>
          value={formatOption}
          onValueChange={handleFormatChange}
          options={downloadImageFormatOptionItems}
          triggerProps={{
            className:
              "w-[60px] inline-flex flex-row items-center justify-start rounded px-3 text-[13px] leading-none gap-[5px] bg-zinc-900 text-xs text-zinc-300 border border-solid border-zinc-800 focus:border-zinc-600 hover:bg-zinc-800/30 data-[placeholder]:text-zinc-500 outline-none transition-colors",
          }}
          triggerChildren={
            <>
              <SelectOptionValue className="truncate">
                {imageFormatToName[formatOption] || ""}
              </SelectOptionValue>
              <SelectOptionIcon />
            </>
          }
        />
      </div>
      <DownloadImageButton
        isLoading={isLoading}
        onClick={() => {
          if (isLoading) {
            return;
          }

          setIsLoading(true);
          handleDownloadImage({
            imageUrl: imageObject.getSrc(),
            resizeOption,
            formatOption,
            imageFileStem,
            suffix: suffixInputRef.current?.value,
          })
            .then(() => {
              console.log("Finish download");
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        {isLoading ? (
          <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
        ) : (
          <Download size={18} className="mr-2" />
        )}
        <span>Download image</span>
      </DownloadImageButton>
    </div>
  );
});

export const DownloadImagesGroup = React.forwardRef(function DownloadImagesGroup(
  {
    className = "",
    imageObjects,
    ...props
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    imageObjects: fabric.StaticImage[];
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const [resizeOption, setResizeOption] = React.useState<DownloadImageResizeOption>(
    DownloadImageResizeOption.OneX,
  );
  const [formatOption, setFormatOption] = React.useState<ImageFormat>(ImageFormat.WEBP);
  const [isLoading, setIsLoading] = React.useState(false);

  const suffixInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleResizeChange = (value: any) => {
    setResizeOption(value as DownloadImageResizeOption);
  };

  const handleFormatChange = (value: any) => {
    setFormatOption(value as ImageFormat);
  };

  return (
    <div ref={forwardedRef} className={classNames("flex flex-col gap-2", className)} {...props}>
      <div className="flex flex-row items-stretch gap-2">
        <SelectOptions<DownloadImageResizeOption>
          value={resizeOption}
          onValueChange={handleResizeChange}
          options={downloadImageResizeOptionItems}
          triggerProps={{
            className:
              "w-[60px] inline-flex flex-row items-center justify-start rounded px-3 text-[13px] leading-none gap-[5px] bg-zinc-900 text-xs text-zinc-300 border border-solid border-zinc-800 focus:border-zinc-600 hover:bg-zinc-800/30 data-[placeholder]:text-zinc-500 outline-none transition-colors",
          }}
          triggerChildren={
            <>
              <SelectOptionValue className="truncate">{resizeOption}</SelectOptionValue>
              <SelectOptionIcon />
            </>
          }
        />
        <input
          ref={suffixInputRef}
          type="text"
          className={classNames(InputBoxClassName, "flex-1 text-xs")}
          placeholder="Suffix"
          onBlur={(e) => {
            const suffix = cleanupText(e.currentTarget.value);

            e.currentTarget.value = suffix;
          }}
        />
        <SelectOptions<ImageFormat>
          value={formatOption}
          onValueChange={handleFormatChange}
          options={downloadImageFormatOptionItems}
          triggerProps={{
            className:
              "w-[60px] inline-flex flex-row items-center justify-start rounded px-3 text-[13px] leading-none gap-[5px] bg-zinc-900 text-xs text-zinc-300 border border-solid border-zinc-800 focus:border-zinc-600 hover:bg-zinc-800/30 data-[placeholder]:text-zinc-500 outline-none transition-colors",
          }}
          triggerChildren={
            <>
              <SelectOptionValue className="truncate">
                {imageFormatToName[formatOption] || ""}
              </SelectOptionValue>
              <SelectOptionIcon />
            </>
          }
        />
      </div>
      <DownloadImageButton
        isLoading={isLoading}
        isDisabled={imageObjects.length <= 0}
        onClick={() => {
          if (isLoading) {
            return;
          }

          if (imageObjects.length <= 0) {
            return;
          }

          setIsLoading(true);

          Promise.all(
            imageObjects.map((imageObject) =>
              handleDownloadImage({
                imageUrl: imageObject.getSrc(),
                resizeOption,
                formatOption,
                imageFileStem: getStaticImageFileStem(imageObject as any as fabric.Object),
                suffix: suffixInputRef.current?.value,
              }),
            ),
          )
            .then(() => {
              console.log("Finish download");
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        {isLoading ? (
          <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
        ) : (
          <Download size={18} className="mr-2" />
        )}
        <span>
          {imageObjects.length > 0
            ? imageObjects.length > 1
              ? `Download ${imageObjects.length} images`
              : "Download image"
            : "No image to download"}
        </span>
      </DownloadImageButton>
    </div>
  );
});
