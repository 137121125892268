import React from "react";

export function GenerationFrameObjectIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 106 288" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M73.5 30.5C69.1 23.3 67.6667 9.5 67.5 3.5C64.8333 2.66667 60 0.999973 53.5 1C47.4867 1.00003 40.8333 2.33333 39 3.5C38.6667 8.33333 36.9 20.5 32.5 30.5C28.8283 38.8447 21.4196 45.2164 16.5495 48.4531C15.0176 49.4712 14 51.1471 14 52.9865V89.5C14 91.7091 15.7909 93.5 18 93.5H19.9829C21.0969 93.5 22 94.4031 22 95.5171C22 96.3993 21.426 97.1705 20.6063 97.4968C12.5792 100.692 1 110.787 1 124V263C1 275 7.5 287.5 20 287.5H84C101 287.5 104.5 273.5 104.5 263C104.667 223.667 104.9 140.8 104.5 124C104.127 108.315 92.3923 100.126 85.4109 97.4808C84.5822 97.1668 84 96.3931 84 95.5068C84 94.3985 84.8985 93.5 86.0068 93.5H88.25C90.5972 93.5 92.5 91.5972 92.5 89.25V52.4454C92.5 50.9025 91.7842 49.4511 90.5933 48.47C85.5873 44.3463 77.3554 36.8089 73.5 30.5Z"
        fill="url(#paint0_linear_55_324)"
        fillOpacity="0.5"
        stroke="currentColor"
        strokeDasharray="2 2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_55_324"
          x1="52.8604"
          y1="43.5"
          x2="52.8604"
          y2="287.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" stopOpacity="0.15" />
          <stop offset="0.4375" stopColor="currentColor" stopOpacity="0.2" />
          <stop offset="1" stopColor="currentColor" stopOpacity="0.35" />
        </linearGradient>
      </defs>
    </svg>
  );
}
