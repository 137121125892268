import React from "react";
import { PanelSwitchRow } from "../components/switch";
import { editorContextStore } from "contexts/editor-context";
import { RealTimeRenderMode } from "@/core/common/types/realtime-render";
import { Tooltip } from "components/utils/tooltip";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { ReGenerateIcon, SaveResultsIcon } from "components/canvas-frames/generation-frame";
import { RealTimeRenderController } from "components/editor/realtime-render-controller";

export function RealTimePreviewToggle() {
  const realtimeRenderMode = editorContextStore((state) => state.realtimeRenderMode);
  const handleCheckedChange = React.useCallback(() => {
    RealTimeRenderController.setRealtimeRenderMode((mode) => {
      if (mode === RealTimeRenderMode.Active) {
        return RealTimeRenderMode.Disabled;
      }
      return RealTimeRenderMode.Active;
    });
  }, []);

  return (
    <PanelSwitchRow
      id="real-time-preview-toggle"
      rootProps={{
        checked: realtimeRenderMode === RealTimeRenderMode.Active,
        onCheckedChange: handleCheckedChange,
      }}
      labelProps={{
        className: "flex flex-row items-center",
      }}
    >
      <span className="mr-2">Real-Time Generation</span>
      <Tooltip
        triggerChildren={
          <QuestionMarkCircledIcon className="text-zinc-500 hover:text-zinc-300 transition-colors" />
        }
        contentChildren={
          <div className="flex flex-col gap-2">
            <div className="">
              The preview will auto update as you change the scene inside the generation frame.
            </div>
            <div className="flex flex-row items-center gap-2">
              Save the output by clicking the button <SaveResultsIcon />.
            </div>
            <div className="flex flex-row items-center gap-2">
              Re-generate by clicking the button <ReGenerateIcon />.
            </div>
          </div>
        }
      />
    </PanelSwitchRow>
  );
}
