import { CUSTOM_MODELS, PROJECTS } from "@/components/constants/routes";
import { editorContextStore } from "@/contexts/editor-context";
import { FrontendDisplayTemplateType } from "@/core/common/types/custom-model-types";
import { classNames } from "@/core/utils/classname-utils";
import { ArrowRightIcon, Cross1Icon } from "@radix-ui/react-icons";
import { PrimaryButtonClassName } from "components/constants/class-names";
import React, { createContext } from "react";
import { useLocation } from "react-router-dom";
import { MessageDialog, MessageDialogClose, MessageDialogProps } from "./message-dialog";

type OnboardingOption = {
  videoUrl: string;
  title: string;
  subheading: string;
  description: string;
};

type OnboardingOptions = "DragAndDropCanvas" | "FashionCustomModels" | "ProductCustomModels";

const onboardingOptions: Record<OnboardingOptions, OnboardingOption> = {
  DragAndDropCanvas: {
    videoUrl: "https://video.flair.ai/Onboarding%20-%202D%20Editor.mp4",
    title: "Try the Drag & Drop Canvas",
    subheading: "Re-imagine products with backgrounds & props",
    description:
      "Instantly upload your product, arrange props, and let our tool generate lifelike images that showcase your products in perfect, professional settings.",
  },
  FashionCustomModels: {
    videoUrl: "https://video.flair.ai/Onboarding%20-%20Fashion%20Models.mp4",
    title: "Fashion Custom Models",
    subheading: "Customize AI models trained on your fashion",
    description:
      "Train custom AI models on your unique fashion and models to generate consistent, on-brand images across multiple angles and variations. Perfect for fashion, wearables and complex scenes.",
  },
  ProductCustomModels: {
    videoUrl: "https://video.flair.ai/Onboarding%20-%20Product%20Models.mp4",
    title: "Product Custom Models",
    subheading: "Customize AI models trained on your products",
    description:
      "Train custom AI models on your unique products and models to generate consistent, on-brand images across multiple angles and variations. Perfect for products, wearables and complex scenes.",
  },
};

interface OnboardingDialogContextType {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const OnboardingDialogContext = createContext<OnboardingDialogContextType>({
  open: false,
  setOpen: () => {},
});

export function useOnboardingDialogContext() {
  return React.useContext(OnboardingDialogContext);
}

interface OnboardingDialogProviderProps {
  children: React.ReactNode;
}

export const OnboardingDialogProvider: React.FC<OnboardingDialogProviderProps> = ({ children }) => {
  const location = useLocation();
  const userOnboardData = editorContextStore((state) => state.userOnboardData);
  const setUserOnboardData = editorContextStore((state) => state.setUserOnboardData);
  const { backend } = editorContextStore.getState();
  const [open, setOpen] = React.useState<boolean>(() => {
    const isModelsPath = location.pathname.includes(CUSTOM_MODELS);
    const isProjectsPath = location.pathname.includes(PROJECTS);
    const hasSeenCustomModelPopup = userOnboardData?.hasSeenCustomModelPopup;
    const hasSeenDragAndDropCanvasPopup = userOnboardData?.hasSeenDragAndDropCanvasPopup;

    if (isModelsPath && hasSeenCustomModelPopup) {
      return !hasSeenCustomModelPopup;
    } else if (isProjectsPath && hasSeenDragAndDropCanvasPopup) {
      return !hasSeenDragAndDropCanvasPopup;
    }
    return false;
  });

  const handleSetOpen = (value: boolean) => {
    setOpen(value);
    if (!value) {
      const isModelsPath = location.pathname.includes(CUSTOM_MODELS);
      const isProjectsPath = location.pathname.includes(PROJECTS);

      if (isModelsPath) {
        const updatedUserOnboardData = {
          ...userOnboardData,
          hasSeenCustomModelPopup: true,
        };
        backend?.setUserOnboardData({
          userOnboardData: updatedUserOnboardData,
        });
        setUserOnboardData(updatedUserOnboardData);
      } else if (isProjectsPath) {
        const updatedUserOnboardData = {
          ...userOnboardData,
          hasSeenDragAndDropCanvasPopup: true,
        };
        backend?.setUserOnboardData({
          userOnboardData: updatedUserOnboardData,
        });
        setUserOnboardData(updatedUserOnboardData);
      }
    }
  };

  const value = { open, setOpen: handleSetOpen };

  return (
    <OnboardingDialogContext.Provider value={value}>{children}</OnboardingDialogContext.Provider>
  );
};

export const OnboardingDialogContent = React.forwardRef(function OnboardingDialogContent(
  { className = "", ...props }: React.HTMLAttributes<HTMLDivElement>,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const { setOpen } = useOnboardingDialogContext();
  const location = useLocation();
  const isModelsPath = location.pathname.includes(CUSTOM_MODELS);
  const isProjectsPath = location.pathname.includes(PROJECTS);
  const customModelWorkflow = editorContextStore((state) => state.customModelWorkflow);
  const onboardingOption = React.useMemo(() => {
    return onboardingOptions[
      isModelsPath && customModelWorkflow === FrontendDisplayTemplateType.Fashion
        ? "FashionCustomModels"
        : isProjectsPath
          ? "DragAndDropCanvas"
          : "ProductCustomModels"
    ];
  }, [isModelsPath, isProjectsPath, customModelWorkflow]);

  return (
    <div
      {...props}
      ref={forwardedRef}
      className={classNames(
        "rounded-2xl w-[95vw] md:w-[1200px] h-auto md:h-[600px] max-h-[95vh] max-w-[95vw] p-8 flex flex-row items-stretch overflow-hidden bg-zinc-900",
        className,
      )}
    >
      <MessageDialogClose className="absolute right-4 top-4">
        <Cross1Icon className="text-zinc-500 hover:text-zinc-200 cursor-pointer transition-colors" />
      </MessageDialogClose>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
        <div className="col-span-1 md:col-span-4 flex flex-col justify-center gap-4 pb-4 md:pb-0">
          <div className="flex flex-col gap-0">
            <div className="text-xl font-bold text-zinc-300">{onboardingOption.title}</div>
            <div className="text-zinc-300 text-base">{onboardingOption.subheading}</div>
          </div>
          <div className="text-zinc-500 text-base">{onboardingOption.description}</div>
          <button
            className={classNames(
              PrimaryButtonClassName,
              "w-fit px-4 flex flex-row items-center gap-2",
            )}
            onClick={() => setOpen(false)}
          >
            Get Started
            <ArrowRightIcon className="w-4 h-4" />
          </button>
        </div>

        <div className="col-span-1 md:col-span-8 h-[300px] md:h-auto flex items-center justify-center">
          <video
            src={onboardingOption.videoUrl}
            autoPlay
            loop
            muted
            playsInline
            style={{ height: "auto", width: "100%", borderRadius: "0.375rem", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
});

export function OnboardingDialog(props: MessageDialogProps) {
  const { open, setOpen } = useOnboardingDialogContext();

  return (
    <MessageDialog
      {...props}
      open={open}
      onOpenChange={setOpen}
      contentChildren={<OnboardingDialogContent />}
    />
  );
}
