import React, {
  ReactNode,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  DetailedHTMLProps,
  HTMLAttributes,
  WheelEvent,
} from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

import { classNames } from "@/core/utils/classname-utils";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { useComponentSize } from "hooks/use-component-size";
import { clamp } from "lodash";
import { debugLog } from "@/core/utils/print-utilts";

type ArrowDirection = "left" | "right";

const ArrowClassName = `pointer-events-auto absolute top-0 h-full flex items-center justify-center text-zinc-200 from-zinc-900 to-transparent cursor-pointer`;

export type GridCarouselProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: ReactNode;
  columnsPerPage?: number;
  itemsPerScroll?: number;
  childrenContainerClassName?: string;
  minItemWidth?: number;
  maxItemWidth?: number;
};

interface WindowSize {
  width: number;
  height: number;
}

export function GridCarousel({
  children,
  columnsPerPage: initColumnsPerPage = 5,
  minItemWidth = 200,
  maxItemWidth = 500,
  itemsPerScroll,
  className,
  childrenContainerClassName = "gap-2",
  ...props
}: GridCarouselProps) {
  const [containerSize, containerRef] = useComponentSize<HTMLDivElement>();
  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const scrollAreaContainerRef = useRef<HTMLDivElement>(null);
  const scrollViewportRef = useRef<HTMLDivElement>(null);

  const totalItems = React.Children.count(children);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
  });

  const columnsPerPage = useMemo(() => {
    const containerWidth = containerSize.width || windowSize.width || 512;

    // Calculate the maximum possible columns based on minItemWidth
    const maxColumns = Math.floor(containerWidth / minItemWidth);

    // Calculate the minimum possible columns based on maxItemWidth
    const minColumns = Math.ceil(containerWidth / maxItemWidth);

    // Clamp the initial columns per page within the min and max columns
    const clampedColumns = clamp(initColumnsPerPage, minColumns, maxColumns);

    // Ensure at least 2 columns
    return Math.max(clampedColumns, 2);
  }, [containerSize.width, windowSize.width, minItemWidth, maxItemWidth, initColumnsPerPage]);

  const gridStyle = useMemo(() => {
    const gridWidthPercentage = Math.round((100 * totalItems) / Math.max(1, columnsPerPage));

    return {
      gridTemplateColumns: `repeat(${totalItems}, 1fr)`,
      width: `${gridWidthPercentage}%`,
    };
  }, [totalItems, columnsPerPage]);

  const onArrowClick = (dir: ArrowDirection) => {
    const pages = Math.floor(totalItems / columnsPerPage);
    const usedItemsToScroll =
      itemsPerScroll || (pages > 3 ? columnsPerPage : columnsPerPage > 3 ? 2 : 1);
    const scrollAmount =
      ((scrollViewportRef.current?.scrollWidth ?? 0) * usedItemsToScroll) / totalItems;
    scrollViewportRef.current?.scrollBy({
      left: dir === "left" ? -scrollAmount : scrollAmount,
      behavior: "smooth",
    });
  };

  const updateArrows = useCallback(() => {
    if (!scrollViewportRef.current) {
      return;
    }

    const scrollLeft = scrollViewportRef.current.scrollLeft;
    const containerWidth = scrollViewportRef.current.clientWidth;
    const scrollWidth = scrollViewportRef.current.scrollWidth;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(Math.round(scrollLeft + containerWidth) < scrollWidth);
  }, []);

  const handleWheel = useCallback(
    (event: WheelEvent<HTMLDivElement>) => {
      if (!scrollViewportRef.current) {
        return;
      }

      const scrollAmount = event.deltaX;
      scrollViewportRef.current.scrollLeft += scrollAmount;

      updateArrows();
    },
    [updateArrows],
  );

  useEffect(() => {
    updateArrows();
  }, [children, updateArrows]);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      updateArrows();

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateArrows]);

  if (totalItems === 0) {
    return null;
  }

  return (
    <div
      {...props}
      ref={containerRef}
      className={classNames(`flair-gridCarousel relative w-full`, className || "")}
    >
      <ScrollAreaContainer
        className="relative w-full"
        ref={scrollAreaContainerRef}
        viewportRef={scrollViewportRef}
        orientation="horizontal"
        hideScrollbar
        onScroll={updateArrows}
        onPointerOver={updateArrows}
        onWheel={handleWheel}
      >
        <div className="relative" ref={childrenContainerRef} onScroll={updateArrows}>
          <div
            className={classNames("relative grid items-center", childrenContainerClassName)}
            style={gridStyle}
          >
            {children}
          </div>
        </div>
      </ScrollAreaContainer>
      {(showLeftArrow || showRightArrow) && (
        <div className="w-full absolute pointer-events-none h-full top-0">
          {showLeftArrow && (
            <button
              className={`${ArrowClassName} left-0 bg-gradient-to-r pr-4 pl-1`}
              onClick={() => onArrowClick("left")}
              aria-label="Scroll Left"
            >
              <ChevronLeft size={25} />
            </button>
          )}
          {showRightArrow && (
            <button
              className={classNames(`right-0 bg-gradient-to-l pl-4 pr-1`, ArrowClassName)}
              onClick={() => onArrowClick("right")}
              aria-label="Scroll Right"
            >
              <ChevronRight size={25} />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
