import { debounce } from "lodash";
import { GenerateTemplateTypesenseDoc } from "@/core/common/types/template-generator";

const elementsSearchUrl = "https://getassetelementsresult-6fpjtxm2eq-uc.a.run.app";

const getSemanticSearchResultsDebounced = debounce(
  async (
    searchString: string,
    amount: number,
    callback: (results: GenerateTemplateTypesenseDoc[]) => void,
  ) => {
    const semanticSearchEndpointUrl = import.meta.env.VITE_SEMANTIC_SEARCH_API_URL;

    if (!semanticSearchEndpointUrl) {
      return [] as GenerateTemplateTypesenseDoc[];
    }
    const body = {
      indexKey: "image-only",
      search: searchString,
      amount,
    };
    const newMatches: GenerateTemplateTypesenseDoc[] = [];
    try {
      const vectorResponse = await fetch(semanticSearchEndpointUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const jsonResp = await vectorResponse.json();

      if (!jsonResp.matches) {
        callback(newMatches);
      }

      for (const match of jsonResp.matches) {
        const defaultObj: GenerateTemplateTypesenseDoc = {
          id: match.id as string,
          prompt: match.metadata.prompt as string,
          image_src: match.metadata.url as string,
          tags: [],
          score: 0,
          is_tags_null: true,
        };
        newMatches.push(defaultObj);
      }

      callback(newMatches);
    } catch (error) {
      console.error("Fetch failed:", error);
      callback(newMatches);
    }
  },
  100,
  { leading: true, trailing: true },
);

export const getSemanticSearchResults = (searchString: string, amount: number) => {
  return new Promise<GenerateTemplateTypesenseDoc[]>((resolve) => {
    getSemanticSearchResultsDebounced(searchString, amount, resolve);
  });
};

export const getElementsSemanticSearchResultIDs = (searchString: string, amount: number) => {
  return new Promise<string[]>((resolve) => {
    getElementsSemanticSearchResultsDebounced(searchString, amount, resolve);
  });
};

const getElementsSemanticSearchResultsDebounced = debounce(
  async (searchString: string, amount: number, callback: (results: string[]) => void) => {
    const semanticSearchEndpointUrl = elementsSearchUrl;

    if (!semanticSearchEndpointUrl) {
      return [] as string[];
    }
    const body = {
      indexKey: "asset-elements-props-index",
      search: searchString,
      amount,
    };
    const newMatches: string[] = [];
    try {
      const vectorResponse = await fetch(semanticSearchEndpointUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const jsonResp = await vectorResponse.json();

      if (!jsonResp.matches) {
        callback(newMatches);
      }

      for (const match of jsonResp.matches) {
        newMatches.push(match.id as string);
      }

      callback(newMatches);
    } catch (error) {
      console.error("Fetch failed:", error);
      callback(newMatches);
    }
  },
  100,
  { leading: false, trailing: true },
);
