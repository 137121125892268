import React from "react";
import potpack from "potpack";
import { fabric } from "fabric";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { editorContextStore } from "contexts/editor-context";
import { classNames } from "@/core/utils/classname-utils";
import { isActiveSelection, isStaticImageObject } from "@/core/utils/type-guards";
import { Download, LayoutGrid, Trash } from "lucide-react";
import { LeftPanelBarDividerSmall } from "../components/divider";
import { LeftPanelTitle } from "../components/left-panel-title";
import { downloadImageDataUrl, getStaticImageFileStem } from "components/utils/data";
import { displayUiMessage } from "components/utils/display-message";
import { DownloadImagesGroup } from "components/utils/download-image-button";
import { LeftPanelSectionContainer } from "../base";

function DownloadImages() {
  const activeObject = editorContextStore((state) => state.activeObject);

  const imageObjects = React.useMemo(() => {
    if (isActiveSelection(activeObject)) {
      return activeObject.getObjects().filter(isStaticImageObject) as any as fabric.StaticImage[];
    }
    return [];
  }, [activeObject]);

  return (
    <LeftPanelSectionContainer label="Download">
      <DownloadImagesGroup imageObjects={imageObjects} />
    </LeftPanelSectionContainer>
  );

  // const [isDownloading, setIsDownloading] = React.useState(false);

  // const images = React.useMemo(() => {
  //     if (isActiveSelection(activeObject)) {
  //         return activeObject.getObjects().filter(isStaticImageObject) as any as fabric.StaticImage[];
  //     }
  //     return [];
  // }, [activeObject]);

  // return (
  //     <button
  //         className={classNames(
  //             isDownloading ?
  //                 PrimaryButtonClassNameLoading :
  //                 images.length > 0 ?
  //                     PrimaryButtonClassName :
  //                     PrimaryButtonClassNameDisabled,
  //             'flex flex-row justify-center text-center',
  //         )}
  //         onClick={() => {
  //             setIsDownloading(true);
  //             Promise.all(images.map(image => downloadImageDataUrl(
  //                 image.getSrc(),
  //                 getStaticImageFileStem(image as any as fabric.Object)
  //             ))).catch((e) => {
  //                 console.error(e);
  //                 displayUiMessage(
  //                     "Cannot download images.",
  //                     "error",
  //                 );
  //             }).finally(() => {
  //                 setIsDownloading(false);
  //             });
  //         }}
  //     >
  //         <Download
  //             size={18}
  //             className="mr-2"
  //         />
  //         <span>
  //             {images.length > 0 ?
  //                 images.length > 1 ?
  //                     `Download ${images.length} images` :
  //                     'Download image' :
  //                 "No image to download"}
  //         </span>
  //     </button>
  // )
}

export function EditSelectionPanel() {
  return (
    <div className="flex flex-col">
      <LeftPanelTitle>Edit Selection</LeftPanelTitle>
      <div
        className={classNames(SecondaryButtonClassNameInactive, "shadow-md")}
        onClick={() => {
          const { editor, activeObject } = editorContextStore.getState();
          if (editor && isActiveSelection(activeObject)) {
            const bboxs: { object: fabric.Object; w: number; h: number; x: number; y: number }[] =
              [];

            let leftMin = 0;
            let topMin = 0;

            activeObject.getObjects().forEach((object) => {
              const left = object.left;
              const top = object.top;
              const w = object.getScaledWidth();
              const h = object.getScaledHeight();
              if (left !== undefined && top !== undefined && w && h) {
                leftMin = Math.min(leftMin, left);
                topMin = Math.min(topMin, top);
                bboxs.push({
                  object,
                  w,
                  h,
                  x: 0,
                  y: 0,
                });
              }
            });

            potpack(bboxs);

            bboxs.forEach(({ object, x, y }) => {
              object.left = leftMin + x;
              object.top = topMin + y;
              object.setCoords();
            });

            activeObject.setCoords();

            editor.canvas.requestRenderAll();

            activeObject.addWithUpdate();

            editor.history.save();
          }
        }}
      >
        <span className="grow">Arrange in a grid</span>
        <LayoutGrid size={16} />
      </div>
      <LeftPanelBarDividerSmall />
      <div
        className={classNames(SecondaryButtonClassNameInactive, "shadow-md")}
        onClick={() => {
          const { editor, activeObject } = editorContextStore.getState();
          if (editor && activeObject) {
            editor.objects.remove(activeObject.id);
          }
        }}
      >
        <span className="grow">Delete</span>
        <Trash size={16} />
      </div>
      <LeftPanelBarDividerSmall />
      <LeftPanelBarDividerSmall />
      <DownloadImages />
      <LeftPanelBarDividerSmall />
    </div>
  );
}
