import React from "react";
import { EditorMessageDialog } from "./message-dialog/editor-message-dialog";

export function EditorPopups() {
  return (
    <>
      <EditorMessageDialog />
    </>
  );
}
