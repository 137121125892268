import { classNames } from "@/core/utils/classname-utils";
import {
  useBackCustomModelImageEditorActiveModeCallback,
  usePrevCustomModelImageEditorActiveMode,
} from "./custom-model-image-editor-context";
import { TextButtonClassName } from "components/constants/class-names";
import { ChevronLeft } from "lucide-react";

export function CustomModelImageEditorBackButton({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
  const previousMode = usePrevCustomModelImageEditorActiveMode();
  const goBack = useBackCustomModelImageEditorActiveModeCallback();

  return (
    <div
      className={classNames(
        TextButtonClassName,
        "flex flex-row justify-start items-center gap-1 select-none",
        previousMode ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none",
      )}
      onClick={(e) => {
        onClick?.(e);

        goBack();
      }}
    >
      <ChevronLeft size={18} />
      <span className="font-base">Back</span>
    </div>
  );
}
