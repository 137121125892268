import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { TextButtonClassName } from "components/constants/class-names";

export function LeftPanelSectionImageGridPagination({
  hasPrev = false,
  hasNext = false,
  clickPrev,
  clickNext,
}: {
  hasPrev?: boolean;
  hasNext?: boolean;
  clickPrev?: () => void;
  clickNext?: () => void;
}) {
  hasPrev = hasPrev && clickPrev != null;
  hasNext = hasNext && clickNext != null;

  if (!hasPrev && !hasNext) {
    return null;
  }

  return (
    <div className="w-full py-4 flex flex-row items-center text-sm font-semibold">
      {hasPrev && (
        <div className={TextButtonClassName} onClick={clickPrev}>
          <ChevronLeft size={18} />
          <div className="w-1" />
          Prev
        </div>
      )}
      <div className="flex-1" />
      {hasNext && (
        <div className={TextButtonClassName} onClick={clickNext}>
          Next
          <div className="w-1" />
          <ChevronRight size={18} />
        </div>
      )}
    </div>
  );
}
