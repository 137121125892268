import { FullScreenAuthContainer } from "./auth/auth-conatiner";
import { SecondaryButtonClassName } from "@/components-mobile/constants/class-names";
import { useNavigate } from "react-router-dom";
import { ImageComponent } from "./utils/image";

export function NotFound() {
  const navigate = useNavigate();

  const NOT_FOUND_IMAGE =
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2e68a161-9dc0-42cd-9edc-d41ed9bc8600/1024";
  const NOT_FOUND_TITLE = "Page Not Found";
  const NOT_FOUND_MESSAGE =
    "Sorry, the page you are looking for does not exist or has been moved to a new location";

  return (
    <FullScreenAuthContainer>
      <div className="flex flex-col items-center gap-8 text-center group">
        <div className="w-full rounded-md overflow-hidden group-hover:border-neutral-700 group-hover:shadow-neutral-100">
          <div className="relative">
            <ImageComponent
              src={NOT_FOUND_IMAGE}
              alt="404 Not Found"
              className="group-hover:scale-110 duration-300 transition-all object-cover w-full h-full rounded-md"
            />
            <div className="absolute inset-0 bg-black/30 transition-all duration-300 group-hover:bg-transparent"></div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <h1 className="group-hover:text-zinc-300 transition-colors text-2xl font-bold">
            {NOT_FOUND_TITLE}
          </h1>
          <p className="group-hover:text-zinc-300 transition-colors">{NOT_FOUND_MESSAGE}</p>
        </div>
        <button className={SecondaryButtonClassName} onClick={() => navigate("/")}>
          Return Home
        </button>
      </div>
    </FullScreenAuthContainer>
  );
}
