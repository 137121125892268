import React from "react";
import { classNames } from "@/core/utils/classname-utils";
import { editorContextStore } from "contexts/editor-context";
import styles from "./prompt-editor-form.module.css";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { Cross1Icon, UploadIcon } from "@radix-ui/react-icons";
import { Tooltip } from "components/utils/tooltip";
import {
  getDataUrlFromImageElementResized,
  getImageElementFromFilesAsync,
} from "@/core/utils/image-utils";
import { EditorAssetContentType, GenerateToolReferenceImage } from "@/core/common/types";
import { debugError } from "@/core/utils/print-utilts";
import { ImageComponent } from "components/utils/image";

enum ImageInputState {
  Loading,
  Idle,
  Error,
  Empty,
}

function getImageInputStateFromImageSrc(src?: string) {
  return src ? ImageInputState.Loading : ImageInputState.Empty;
}

const imageInputClassName = "w-[20px] h-[20px] bg-zinc-800 rounded";

type ImageInputPreviewProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  state: ImageInputState;
  setState: (value: ImageInputState) => void;
  alt: string;
  src: string;
};

function ImageInputPreview({
  alt,
  src,
  className = "",
  children,
  state,
  setState,
  ...props
}: ImageInputPreviewProps) {
  return state === ImageInputState.Empty ? (
    <div className={classNames(imageInputClassName)} />
  ) : state === ImageInputState.Error ? (
    <div className={classNames(imageInputClassName)} />
  ) : (
    <ImageComponent
      alt={alt}
      src={src}
      className={classNames(imageInputClassName, "object-cover")}
      onLoad={() => {
        setState(ImageInputState.Idle);
      }}
      onError={(e) => {
        console.error(e);
        setState(ImageInputState.Error);
      }}
    />
  );
}

function UploadReferenceImageTrigger({
  id,
  children,
  onImageUpload,
  className = "",
  targetLength = 512,
  ...props
}: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
  id: string;
  onImageUpload: (imageDataUrl: string) => void;
  targetLength?: number;
}) {
  const handleUploadFiles = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      if (targetLength <= 0) {
        return;
      }

      const files = e.target.files;
      if (!files) {
        return;
      }

      const imageElement = await getImageElementFromFilesAsync(files);

      if (!imageElement) {
        return;
      }

      const imageDataUrl = await getDataUrlFromImageElementResized({
        image: imageElement,
        targetLength,
      });

      if (imageDataUrl) {
        onImageUpload(imageDataUrl);
      }
    },
    [targetLength, onImageUpload],
  );

  return (
    <>
      <input
        type="file"
        id={id}
        style={{
          display: "none",
        }}
        onChange={(e) => {
          handleUploadFiles(e);
        }}
      />
      <label {...props} htmlFor={id} className={className}>
        {children}
      </label>
    </>
  );
}

function handleReferenceImageUpload(imageDataUrl: string) {
  const { editor, setGenerateToolReferenceImage } = editorContextStore.getState();

  setGenerateToolReferenceImage({
    path: imageDataUrl,
    type: "image-url",
  });

  try {
    if (!editor) {
      return;
    }

    editor.assets
      .addAsset({
        data: imageDataUrl,
        contentType: EditorAssetContentType.png,
        saveToMemory: true,
      })
      .then((storagePath) => {
        if (!storagePath) {
          return;
        }

        setGenerateToolReferenceImage({
          type: "image-storage",
          path: storagePath,
        });
      });
  } catch (error) {
    debugError(error);
  }
}

function handleRemoveReferenceImage() {
  const { setGenerateToolReferenceImage } = editorContextStore.getState();

  setGenerateToolReferenceImage(undefined);
}

function UploadReferenceImageButton() {
  return (
    <UploadReferenceImageTrigger
      id="upload-reference-image"
      onImageUpload={handleReferenceImageUpload}
      className={classNames(SecondaryButtonClassNameInactive, "gap-2")}
      targetLength={512}
    >
      <UploadIcon width={18} height={18} />
      Upload Image
    </UploadReferenceImageTrigger>
  );
}

function ReplaceReferenceImageButton({ src, ...props }: ImageInputPreviewProps) {
  return (
    <div className="flex flex-row items-center gap-2">
      <Tooltip
        triggerProps={{
          asChild: false,
        }}
        triggerChildren={
          <UploadReferenceImageTrigger
            id="replace-reference-image"
            onImageUpload={handleReferenceImageUpload}
            className={classNames(
              SecondaryButtonClassNameInactive,
              "flex flex-row items-center gap-2 select-none",
            )}
            targetLength={512}
          >
            <ImageInputPreview {...props} alt="uploaded reference image" src={src} />
            <span>Replace</span>
          </UploadReferenceImageTrigger>
        }
        contentClassName="p-2"
        contentChildren={
          <div className="flex flex-col gap-2">
            <ImageComponent
              alt="input full preview"
              src={src}
              className="max-w-[20vw] object-cover rounded-sm"
            />
            <span className="text-zinc-500">Click to replace the reference image.</span>
          </div>
        }
      />
      <Tooltip
        triggerProps={{
          asChild: true,
        }}
        triggerChildren={
          <div
            className={classNames(SecondaryButtonClassNameInactive)}
            onClick={() => {
              handleRemoveReferenceImage();
            }}
          >
            <Cross1Icon width={18} height={18} />
          </div>
        }
        contentChildren="Remove the reference image"
      />
    </div>
  );
}

async function loadReferenceImagePreview(referenceImage: GenerateToolReferenceImage) {
  if (!referenceImage) {
    return;
  }

  const { editor } = editorContextStore.getState();

  if (!editor) {
    return;
  }

  const { previewPath } = referenceImage;

  let preview: string | undefined;

  if (previewPath) {
    preview = await editor.assets.loadAsset({
      path: previewPath,
    });
  }

  if (!preview) {
    preview = await editor.assets.loadAsset(referenceImage);
  }

  return preview;
}

export function ReferenceImage({
  className = "",
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const [imageInputState, setImageInputState] = React.useState(ImageInputState.Empty);
  const [referenceImageSrc, setReferenceImageSrc] = React.useState<string>("");

  const referenceImage = editorContextStore((state) => state.generateToolReferenceImage);

  React.useEffect(() => {
    if (!referenceImage) {
      setReferenceImageSrc("");
      setImageInputState(ImageInputState.Empty);
      return;
    }

    const { editor } = editorContextStore.getState();

    if (!editor) {
      return;
    }

    loadReferenceImagePreview(referenceImage).then((imageSrc = "") => {
      setReferenceImageSrc(imageSrc);
      setImageInputState(getImageInputStateFromImageSrc(imageSrc));
    });
  }, [referenceImage]);

  return (
    <div
      className={classNames(
        styles.PromptEditorConfigRow,
        className,
        "w-full mb-4 flex flex-row items-center justify-start text-sm text-zinc-300",
      )}
    >
      <div className="mr-2 truncate">Reference Image</div>
      <div className={classNames("flex-1 flex justify-end")}>
        {imageInputState === ImageInputState.Empty || imageInputState === ImageInputState.Error ? (
          <UploadReferenceImageButton />
        ) : (
          <ReplaceReferenceImageButton
            state={imageInputState}
            setState={setImageInputState}
            alt="input image preview"
            src={referenceImageSrc}
          />
        )}
      </div>
    </div>
  );
}
