import React, { createContext, ReactNode, useContext, useState } from "react";

type CustomModelEditorTab = "train" | "generate";

interface CustomModelEditorContextType {
  tab: CustomModelEditorTab;
  setTab: React.Dispatch<React.SetStateAction<CustomModelEditorTab>>;
}

// Create the context with a default value
const CustomModelEditorContext = createContext<CustomModelEditorContextType | undefined>(undefined);

// Custom hook to use the CustomModelEditorContext
export const useCustomModelEditor = () => {
  const context = useContext(CustomModelEditorContext);
  if (!context) {
    throw new Error("useCustomModelEditor must be used within a CustomModelEditorProvider");
  }
  return context;
};

// Provider component
export const CustomModelEditorProvider = ({ children }: { children: ReactNode }) => {
  const [tab, setTab] = useState<CustomModelEditorTab>();

  return (
    <CustomModelEditorContext.Provider value={{ tab, setTab }}>
      {children}
    </CustomModelEditorContext.Provider>
  );
};
