import React from "react";
import { EditorCanvasModalContainer } from "../editor-modal/editor-canvas-modal";
import { editorContextStore } from "contexts/editor-context";
import { removeLastFromImmutableList } from "@/core/utils/array-utils";
import {
  GenerateVideoEditor,
  getGenerateVideoKeyFrameFromImageObject,
} from "components/video/generate-video-editor";
import { classNames } from "@/core/utils/classname-utils";

import { DropdownClassName } from "components/constants/class-names";
import {
  isActiveSelection,
  isEditorImageAsset,
  isStaticImageObject,
} from "@/core/utils/type-guards";
import { EditorActiveObject } from "@/core/common/interfaces";
import {
  GenerateVideoKeyFrames,
  GenerateVideoKeyFrameType,
  GenerateVideoKeyFrameWithImage,
  getClosestVideoGenerationAspectRatio,
  getGenerateVideoKeyFramesWithUploadButtons,
  resetGenerateVideoEditorState,
} from "@/core/common/types/video";
import { debugLog } from "@/core/utils/print-utilts";

function getGenerateVideoKeyFramesFromEditorActiveObject({
  activeObject,
  limit,
}: {
  activeObject: EditorActiveObject;
  limit?: number;
}): GenerateVideoKeyFrames {
  if (!activeObject || limit === 0) {
    return [];
  }

  debugLog("Active object editor image asset: ", (activeObject as any)?.asset);

  if (isStaticImageObject(activeObject) && isEditorImageAsset(activeObject.asset)) {
    return [getGenerateVideoKeyFrameFromImageObject(activeObject)];
  } else if (isActiveSelection(activeObject)) {
    const keyframes = activeObject
      .getObjects()
      .map((object) => {
        if (!isStaticImageObject(object) || !isEditorImageAsset(object.asset)) {
          return undefined;
        }

        return getGenerateVideoKeyFrameFromImageObject(object);
      })
      .filter(Boolean) as GenerateVideoKeyFrames;

    if (typeof limit === "number") {
      return keyframes.slice(0, limit).filter(Boolean);
    }

    return keyframes;
  }

  return [];
}

export function GenerateVideoDialog() {
  const activeObject = editorContextStore((state) => state.activeObject);

  React.useEffect(() => {
    const {
      editor,
      setGenerateVideoPrompt,
      setGenerateVideoKeyFrames,
      setGenerateVideoAspectRatio,
    } = editorContextStore.getState();

    const keyframes = getGenerateVideoKeyFramesFromEditorActiveObject({
      activeObject,
      limit: 2,
    });

    const generatedKeyFrames = keyframes.filter(
      (keyframe) => keyframe && keyframe.type !== GenerateVideoKeyFrameType.UploadButton,
    );

    const firstKeyFrame = (generatedKeyFrames.find(
      (keyframe) => keyframe?.type === GenerateVideoKeyFrameType.ImageObject,
    ) || generatedKeyFrames[0]) as GenerateVideoKeyFrameWithImage;

    if (firstKeyFrame) {
      const aspectRatio = getClosestVideoGenerationAspectRatio({
        width: firstKeyFrame.width ?? 1,
        height: firstKeyFrame.height ?? 1,
      });

      setGenerateVideoAspectRatio(aspectRatio);

      if (
        editor &&
        firstKeyFrame.type === GenerateVideoKeyFrameType.ImageObject &&
        firstKeyFrame.generationId
      ) {
        editor.assets?.getPastGeneration(firstKeyFrame.generationId).then((generation) => {
          if (!generation?.prompt) {
            return;
          }

          setGenerateVideoPrompt(generation.prompt);
        });
      }
    }

    setGenerateVideoKeyFrames(
      getGenerateVideoKeyFramesWithUploadButtons({
        keyFrames: keyframes,
        maxNumKeyFrames: 2,
      }),
    );

    return () => {
      resetGenerateVideoEditorState(editorContextStore.getState());
    };
  }, [activeObject]);

  const handleExitModal = React.useCallback((addImages = false) => {
    const { setEditingObjectId, setActiveLeftPanels } = editorContextStore.getState();

    setEditingObjectId(undefined);
    setActiveLeftPanels((prevLeftPanels) => {
      return removeLastFromImmutableList(prevLeftPanels, "Assets");
    });
  }, []);

  return (
    <EditorCanvasModalContainer
      onExit={handleExitModal}
      className={classNames(
        DropdownClassName,
        "w-full p-0 lg:max-w-[1000px] 2xl:max-w-[1280px] h-fit flex flex-col bg-zinc-900 border border-zinc-800 rounded-xl shadow-lg",
      )}
    >
      <GenerateVideoEditor className="max-h-[85vh]" onExit={handleExitModal} />
    </EditorCanvasModalContainer>
  );
}
