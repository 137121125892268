import { GENERATION_FRAME_BOTTOM_FILL_DARK } from "components/constants/colors";
import { InpaintCommandData } from "@/core/command";
import {
  InpaintBrushType,
  InpaintCanvasSnapshot,
  InpaintStrokeEndEditorEventHandler,
} from "@/core/common/types";
import { Editor } from "@/core/editor";
import _ from "lodash";

export function clearCanvas(context: CanvasRenderingContext2D) {
  context.clearRect(0, 0, context.canvas.width || 0, context.canvas.height || 0);
}

export function getLocalCoordinateFromCanvasPointerEvent({
  e,
  canvas,
  cameraZoomInv,
}: {
  e: PointerEvent;
  canvas: HTMLElement;
  cameraZoomInv: number;
}) {
  const x = e.clientX;
  const y = e.clientY;
  const boundingRect = canvas?.getBoundingClientRect();
  let cameraOffsetX = 0;
  let cameraOffsetY = 0;
  if (boundingRect) {
    cameraOffsetX = boundingRect.left;
    cameraOffsetY = boundingRect.top;
  }
  return {
    x: (x - cameraOffsetX) * cameraZoomInv,
    y: (y - cameraOffsetY) * cameraZoomInv,
  };
}

export function getInpaintBrushLineWidth(brushSize: number, cameraZoomInv: number) {
  return brushSize * cameraZoomInv;
}

export function initInpaintCanvasContext(
  context: CanvasRenderingContext2D,
  brushType: InpaintBrushType,
  lineWidth: number,
  color: string = GENERATION_FRAME_BOTTOM_FILL_DARK,
) {
  if (brushType) {
    context.strokeStyle = color;
    context.fillStyle = color;
    context.lineCap = "round";
    context.globalCompositeOperation = brushType === "erase" ? "destination-out" : "source-over";
    context.lineWidth = lineWidth;
  }
}

export function drawCircle(
  ctx: CanvasRenderingContext2D,
  circle: { x: number; y: number; r: number },
) {
  ctx.beginPath();
  ctx.moveTo(circle.x + circle.r, circle.y);
  ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2);
  ctx.closePath();
  ctx.fill();
}

export function commitInpaintSnapshot(
  editor: Editor,
  inpaintCanvasSnapshot: InpaintCanvasSnapshot,
) {
  editor.emit<InpaintStrokeEndEditorEventHandler>(
    "inpaint:commit-snapshot",
    _.cloneDeep(inpaintCanvasSnapshot),
  );
}

export function commitInpaintCommand({
  editor,
  currentInpaintCommand,
  inpaintCanvasSnapshot,
}: {
  editor: Editor;
  currentInpaintCommand: InpaintCommandData;
  inpaintCanvasSnapshot: InpaintCanvasSnapshot;
}) {
  if (currentInpaintCommand) {
    inpaintCanvasSnapshot.commands?.push(currentInpaintCommand);
    commitInpaintSnapshot(editor, inpaintCanvasSnapshot);
    // currentInpaintCommand = undefined;
  }
}
