import React from "react";
import * as Select from "@radix-ui/react-select";
import { classNames } from "@/core/utils/classname-utils";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { DropdownClassName } from "components/constants/class-names";
import { ApiInputType } from "@/core/common/types/api";
import { InputLabel, isApiInputValueMissing } from "./input-utils";

const SelectItem = React.forwardRef(
  (
    { children, className = "", ...props }: Select.SelectItemProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <Select.Item
        className={classNames(
          "text-[13px] leading-none text-zinc-300 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-zinc-800 data-[highlighted]:text-lime-500 focus:outline-none focus-visible:outline-none transition-colors",
          className,
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  },
);

export type OptionItem<Value = string> = {
  name: string;
  value: Value;
};

export type OptionsInputProps<Value = string> = {
  type: ApiInputType.Options;
  id: string;
  name: React.ReactNode;
  description?: React.ReactNode;
  value: Value;
  onValueChange: (value: Value) => void;
  options: Record<string, OptionItem[]>;
  placeholder?: string;
  required?: boolean;
};

export function OptionsInput<Value = string>({
  id,
  name,
  description,
  value,
  onValueChange,
  options,
  required = false,
  placeholder = "Click to select ...",
}: OptionsInputProps<Value>) {
  const missingRequired =
    required &&
    isApiInputValueMissing({
      type: ApiInputType.Options,
      // @ts-ignore
      value,
      id,
      name,
      options,
      // @ts-ignore
      onValueChange,
    });

  return (
    <div className="flex flex-col gap-2">
      <InputLabel
        htmlFor={id}
        required={required}
        description={description}
        missingRequired={missingRequired}
      >
        {name}
      </InputLabel>
      <Select.Root
        // @ts-ignore
        value={value}
        // @ts-ignore
        onValueChange={onValueChange}
      >
        <Select.Trigger
          className="inline-flex flex-row items-center justify-start rounded px-3 h-[38px] text-[13px] leading-none gap-[5px] bg-zinc-900 text-zinc-300 border border-solid border-zinc-800 focus:border-zinc-600 hover:bg-zinc-800/30 data-[placeholder]:text-zinc-500 outline-none transition-colors"
          aria-label="Food"
        >
          <Select.Value placeholder={placeholder} className="flex-1" />
          <Select.Icon className="text-zinc-300">
            <ChevronDownIcon />
          </Select.Icon>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content
            className={classNames(
              DropdownClassName,
              "overflow-hidden p-1 border-zinc-800 focus-visible:border-zinc-800 pointer-events-none",
            )}
          >
            <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-zinc-900 text-zinc-300 cursor-default">
              <ChevronUpIcon />
            </Select.ScrollUpButton>
            <Select.Viewport className="p-[5px]">
              {Object.entries(options).map(([label, values], index) => (
                <div key={label}>
                  <Select.Group>
                    <Select.Label className="px-[25px] text-sm leading-[25px] text-zinc-500">
                      {label}
                    </Select.Label>
                    {values.map(({ name, value }) => (
                      <SelectItem key={value} value={value}>
                        {name}
                      </SelectItem>
                    ))}
                  </Select.Group>
                  {index < Object.entries(options).length - 1 && (
                    <Select.Separator className="h-[1px] text-sm  bg-zinc-800 m-[5px] pointer-events-none" />
                  )}
                </div>
              ))}
            </Select.Viewport>
            <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-zinc-900 text-zinc-300 cursor-default">
              <ChevronDownIcon />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
}
