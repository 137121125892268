// CPG workflow JSON
import { SampleProjectScene } from "@/core/common/scene";

export const CPGProject: SampleProjectScene = {
  metadata: {
    animated: false,
  },
  objects: {
    "2ErhzoLkuNIfZCVbyeH67": {
      strokeWidth: 0,
      metadata: {
        surrounding: "a yellow flower",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/637b8b89-e212-4193-f309-4d68fd961800/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.72,
      scaleY: 0.72,
      top: 454.07,
      angle: 0,
      id: "2ErhzoLkuNIfZCVbyeH67",
      flipX: false,
      flipY: false,
      height: 321,
      zIndex: 0,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 544.75,
      name: "StaticImage",
      width: 315,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/637b8b89-e212-4193-f309-4d68fd961800/public",
        type: "image-url",
      },
    },
    "8BBTUEjdYb93IuA6nRj3i": {
      strokeWidth: 0,
      metadata: {
        subject: "a yellow container",
        subjectPrefix: "",
        imageType: "subject",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 1.04,
      scaleY: 1.04,
      top: 101.57,
      angle: 0,
      id: "8BBTUEjdYb93IuA6nRj3i",
      flipX: false,
      flipY: false,
      height: 564,
      zIndex: 1,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 46.19,
      name: "StaticImage",
      width: 564,
      opacity: 1,
      asset: {
        path: "sampleProjects/assets/j3Ayj2h7oMC9bGNMxPPt.png",
        type: "image-storage",
      },
    },
    mlpFJRKvV79Yx1bV4rks0: {
      strokeWidth: 0,
      metadata: {
        surrounding: "cherries",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/16a666eb-857e-42f0-4a02-2d7b957bf000/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.49,
      scaleY: 0.49,
      top: 378.91,
      angle: 5,
      id: "mlpFJRKvV79Yx1bV4rks0",
      flipX: false,
      flipY: false,
      height: 599,
      zIndex: 2,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: 455.77,
      name: "StaticImage",
      width: 417,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/16a666eb-857e-42f0-4a02-2d7b957bf000/public",
        type: "image-url",
      },
    },
    "94QlZPTnADrJJiOiP5Wid": {
      strokeWidth: 0,
      metadata: {
        surrounding: "ice cream",
        surroundingPrefix: "with",
        colorDisplayType: "color-and-shape",
        imageType: "hed",
        hedUrl:
          "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c34bc3df-81ba-4ce2-432b-c7b7eb18d200/public",
      },
      skewX: 0,
      skewY: 0,
      type: "StaticImage",
      scaleX: 0.73,
      scaleY: 0.73,
      top: 221.57,
      angle: 0,
      id: "94QlZPTnADrJJiOiP5Wid",
      flipX: false,
      flipY: false,
      height: 637,
      zIndex: 3,
      cropX: 0,
      generationId: "",
      cropY: 0,
      visible: true,
      originX: "left",
      originY: "top",
      left: -102.67,
      name: "StaticImage",
      width: 323,
      opacity: 1,
      asset: {
        path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c34bc3df-81ba-4ce2-432b-c7b7eb18d200/public",
        type: "image-url",
      },
    },
  },
  name: "Untitled Project",
  generationFrame: {
    top: -155.84999999999997,
    left: -178.05999999999995,
    referneceImage: {
      path: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8cf92d0b-aa2d-4aaf-d948-06b256c1fa00/1024",
      previewPath:
        "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8aa5d072-8912-46be-9c4c-75b64776e200/256",
      type: "image-url",
    },
    width: 1024,
    id: "generation-frame",
    type: "GenerationFrame",
    promptTemplate: {
      words: [
        {
          isAutoFilled: true,
          prefix: "",
          autocompleteType: "subject",
          type: "input",
          value: "a yellow container",
          valueBeforeAutoFill: "a yellow container",
          objectIds: ["8BBTUEjdYb93IuA6nRj3i"],
        },
        {
          isAutoFilled: true,
          prefix: "with",
          autocompleteType: "surrounding",
          type: "input",
          value: "a yellow flower and cherries and ice cream",
          objectIds: ["2ErhzoLkuNIfZCVbyeH67", "mlpFJRKvV79Yx1bV4rks0", "94QlZPTnADrJJiOiP5Wid"],
        },
        {
          prefix: "in front of",
          autocompleteType: "background",
          type: "input",
          value: "a golden brown and yellow background",
        },
      ],
    },
    height: 1024,
  },
  id: "Up8ETfWBdPtFqtVDuukL",
  version: "259",
  frame: {
    width: 0,
    height: 0,
  },
};
