import { BBox2d, ObjectBounds2d } from "@/core/common/types";

export function isBbox1dOverlap(min1: number, max1: number, min2: number, max2: number) {
  return max1 >= min2 && max2 >= min1;
}

export function isBbox1dOverlapStrict(min1: number, max1: number, min2: number, max2: number) {
  return max1 > min2 && max2 > min1;
}

export function isBbox2dOverlap(
  tl1: { x: number; y: number },
  br1: { x: number; y: number },
  tl2: { x: number; y: number },
  br2: { x: number; y: number },
) {
  return isBbox1dOverlap(tl1.x, br1.x, tl2.x, br2.x) && isBbox1dOverlap(tl1.y, br1.y, tl2.y, br2.y);
}

export function isBbox2dOverlapStrict(
  tl1: { x: number; y: number },
  br1: { x: number; y: number },
  tl2: { x: number; y: number },
  br2: { x: number; y: number },
) {
  return (
    isBbox1dOverlapStrict(tl1.x, br1.x, tl2.x, br2.x) &&
    isBbox1dOverlapStrict(tl1.y, br1.y, tl2.y, br2.y)
  );
}

export function getBbox2dFromObjectBounds({
  left = 0,
  top = 0,
  width = 0,
  height = 0,
}: Partial<ObjectBounds2d>): BBox2d {
  return {
    xmin: left,
    xmax: left + width,
    ymin: top,
    ymax: top + height,
  };
}

export function getObjectBoundsFromBBox2d({ xmin, xmax, ymin, ymax }: BBox2d): ObjectBounds2d {
  return {
    left: xmin,
    top: ymin,
    width: xmax - xmin,
    height: ymax - ymin,
  };
}

export function mergeBbox2d(bbox1: BBox2d, bbox2?: BBox2d) {
  if (!bbox2) {
    return bbox1;
  }
  return {
    xmin: Math.min(bbox1.xmin, bbox2.xmin),
    ymin: Math.min(bbox1.ymin, bbox2.ymin),
    xmax: Math.max(bbox1.xmax, bbox2.xmax),
    ymax: Math.max(bbox1.ymax, bbox2.ymax),
  };
}

export function mergeObject(object1: ObjectBounds2d, object2: ObjectBounds2d) {
  const bbox1 = getBbox2dFromObjectBounds(object1);
  const bbox2 = getBbox2dFromObjectBounds(object2);
  return mergeBbox2d(bbox1, bbox2);
}

export function getCenterFromBounds({
  left = 0,
  top = 0,
  width = 0,
  height = 0,
}: Partial<ObjectBounds2d>) {
  return {
    x: left + width * 0.5,
    y: top + height * 0.5,
  };
}

export function getBoundsOverlapArea(
  tl0: { x: number; y: number },
  br0: { x: number; y: number },
  tl1: { x: number; y: number },
  br1: { x: number; y: number },
) {
  const width = Math.min(br0.x, br1.x) - Math.max(tl0.x, tl1.x);
  const height = Math.min(br0.y, br1.y) - Math.max(tl0.y, tl1.y);
  return width * height;
}

export function isPointInBounds(
  point: { x: number; y: number },
  tl: { x: number; y: number },
  br: { x: number; y: number },
  strict: boolean = false,
) {
  if (strict) {
    return point.x > tl.x && point.x < br.x && point.y > tl.y && point.y < br.y;
  } else {
    return point.x >= tl.x && point.x <= br.x && point.y >= tl.y && point.y <= br.y;
  }
}

export function getObjectsBounds(objects: fabric.Object[]) {
  let bbox: BBox2d | undefined;
  objects.forEach((object) => {
    bbox = mergeBbox2d(getBbox2dFromObjectBounds(object), bbox);
  });
  return bbox && getObjectBoundsFromBBox2d(bbox);
}
