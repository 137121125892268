import { PublicUserId } from "./public-user-id";

export enum AppRoleType {
  Owner = "owner",
  Writer = "writer",
  Reader = "reader",
  Commenter = "commenter",
}

const rolesCanWrite = new Set([AppRoleType.Owner, AppRoleType.Writer]);

const rolesCanRead = new Set([
  AppRoleType.Owner,
  AppRoleType.Writer,
  AppRoleType.Reader,
  AppRoleType.Commenter,
]);

export function canRoleWrite(type: AppRoleType) {
  return rolesCanWrite.has(type);
}

export function canRoleRead(type: AppRoleType) {
  return rolesCanRead.has(type);
}

export type PublicUserRoles = Record<PublicUserId, AppRoleType>;
