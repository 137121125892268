import React from "react";
import { Navigate } from "./components/navigate";
import { classNames } from "@/core/utils/classname-utils";
import { editorContextStore } from "@/contexts/editor-context";
import {
  InputBoxClassName,
  PrimaryButtonClassName,
  SecondaryButtonClassName,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "@/components/constants/class-names";
import { TryOnPromptType, AppUserSubscriptionTierV2 } from "@/core/common/types";
import { cleanupPrompt } from "@/components/utils/prompt";
import { removeLineBreaks } from "@/core/utils/string-utils";
import { RefreshCcw, Download, Expand } from "lucide-react";
import { generateClothPrompt } from "@/contexts/tryon-editor-context";
import { ProgressBar } from "./components/progress-bar";
import { downloadImageDataUrl } from "@/components/utils/data";
import { displayUiMessage } from "@/components/utils/display-message";
import { useManageSubscriptionDialogContext } from "@/components/popup/message-dialog/manage-subscription-dialog";
import { AnalyticsConfig } from "@/analytics/config";

import { ImageComponent } from "@/components/utils/image";

function TryOnRenderButton() {
  const tryOnEditorState = editorContextStore((state) => state.tryOnEditorState);
  const tryOnRenderProgress = editorContextStore((state) => state.tryOnRenderProgress);

  const isRendering = tryOnEditorState === "rendering" || tryOnEditorState === "upscaling";

  const [isError, setIsError] = React.useState(false);

  const onClick = React.useCallback(() => {
    const {
      editor,
      backend,
      tryOnEditorState,
      tryOnEditorController,
      setTryOnEditorState,
      setTryOnRenderProgress,
      tryOnWarpedClothImageElement,
      tryOnWarpedHumanMaskImageElement,
    } = editorContextStore.getState();

    const tryOnPersonCanvasController =
      tryOnEditorController?.tryOnPersonCanvasControllerRef.current;

    if (!editor || !backend || !tryOnPersonCanvasController) {
      return;
    }

    if (tryOnEditorState !== "idle") {
      return;
    }

    if (!tryOnWarpedClothImageElement || !tryOnWarpedHumanMaskImageElement) {
      return;
    }

    tryOnPersonCanvasController
      .renderClothImage()
      ?.catch((error) => {
        console.error(error);

        setIsError(true);

        return new Promise<void>((resolve) => {
          setTimeout(() => {
            setIsError(false);
            resolve();
          }, 1000);
        });
      })
      .finally(() => {
        setTryOnRenderProgress(1);
        setTryOnEditorState("idle");
      });
  }, []);

  if (isRendering) {
    return (
      <ProgressBar
        className="w-full pointer-events-auto"
        progress={tryOnRenderProgress}
        isError={isError}
      />
    );
  }

  return (
    <button
      className={classNames(PrimaryButtonClassName, "flex flex-row items-center justify-center")}
      onClick={onClick}
    >
      Generate
    </button>
  );
}

type Showcase = {
  url: string;
  enabled?: boolean;
  alt?: string;
};
function UpscaleShowcases() {
  const showcases: Showcase[] = [
    {
      url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c0124947-a82d-4bf4-938f-0695f2534800/512",
      alt: "Upscale Showcase",
    },
    {
      url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c384266c-f3f8-4640-53db-9b0b7dc4ad00/512",
      alt: "Upscale Showcase",
    },
    {
      enabled: true,
      url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8d79053b-7be9-4395-2f38-d59a4f25cf00/512",
      alt: "Upscale Showcase",
    },
    {
      url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/7b3abe72-280e-4270-479e-a3caff514f00/512",
      alt: "Upscale Showcase",
    },
    {
      url: "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4df30258-7935-45df-28a8-80ce34d63600/512",
      alt: "Upscale Showcase",
    },
  ];

  const { setOpen } = useManageSubscriptionDialogContext();

  const enabledShowcases = showcases.filter((showcase) => showcase.enabled);
  const isLoopEnabled = enabledShowcases.length > 1;

  const [showcaseIndex, setShowcaseIndex] = React.useState(() =>
    Math.floor(Math.random() * enabledShowcases.length),
  );

  const onClick = React.useCallback(() => {
    if (!isLoopEnabled) {
      editorContextStore
        .getState()
        .analytics.track(AnalyticsConfig.TryOnUpscaleInteraction, { interactionTarget: "Image" });
      setOpen(true);
    } else {
      setShowcaseIndex((prevIndex) => (prevIndex + 1) % enabledShowcases.length);
    }
  }, [enabledShowcases.length, isLoopEnabled, setOpen]);

  return (
    <div className="relative w-full aspect-square mt-3 cursor-pointer" onClick={onClick}>
      {enabledShowcases.map((showcase, index) => (
        <div
          key={index}
          className={`absolute w-full h-full overflow-hidden top-0 left-0  ${index === showcaseIndex ? "block" : "hidden"}`}
        >
          <ImageComponent
            src={showcase.url}
            alt={showcase.alt || "Upscale Showcase"}
            className="inline-block w-full h-full object-cover"
          />
        </div>
      ))}
    </div>
  );
}

function TryOnUpscaleButton() {
  const { setOpen } = useManageSubscriptionDialogContext();
  const userQuotas = editorContextStore((state) => state.userQuotas);
  const imageUrls = editorContextStore((state) => state.tryOnRenderResults);

  const visible = React.useMemo(() => {
    if (imageUrls?.length <= 0) {
      return false;
    }

    if (!userQuotas) {
      return true;
    }

    if (process.env.NODE_ENV === "development") {
      return true;
    }

    return userQuotas.tierV2 === AppUserSubscriptionTierV2.Free;
  }, [userQuotas, imageUrls]);

  const onClick = React.useCallback(() => {
    editorContextStore
      .getState()
      .analytics.track(AnalyticsConfig.TryOnUpscaleInteraction, { interactionTarget: "Button" });
    setOpen(true);
  }, [setOpen]);

  if (!visible) {
    return null;
  }

  return (
    <div className="text-center">
      <button
        className={classNames(
          PrimaryButtonClassName,
          "flex flex-row w-full items-center justify-center cursor-pointer",
        )}
        onClick={onClick}
      >
        <Expand size={18} className="mr-2" /> Upscale model image
      </button>
      <UpscaleShowcases />
    </div>
  );
}

function useTryOnPrompt(promptType: TryOnPromptType) {
  if (promptType === "cloth") {
    return {
      prompt: editorContextStore((state) => state.tryOnClothPrompt),
      setPrompt: editorContextStore((state) => state.setTryOnClothPrompt),
    };
  }
  if (promptType === "model") {
    return {
      prompt: editorContextStore((state) => state.tryOnModelPrompt),
      setPrompt: editorContextStore((state) => state.setTryOnModelPrompt),
    };
  }
  if (promptType === "background") {
    return {
      prompt: editorContextStore((state) => state.tryOnBackgroundPrompt),
      setPrompt: editorContextStore((state) => state.setTryOnBackgroundPrompt),
    };
  }
  return {
    prompt: editorContextStore((state) => state.tryOnClothPrompt),
    setPrompt: editorContextStore((state) => state.setTryOnClothPrompt),
  };
}

function getIdFromPromptType(promptType: TryOnPromptType) {
  return `tryon-${promptType}-prompt-input-div`;
}

function getInputFieldId(id: string) {
  return `${id}-input`;
}

function TryOnPromptInputField({
  promptType,
  id,
  inputId,
  className = "",
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  inputId?: string;
  promptType: TryOnPromptType;
}) {
  id = id || getIdFromPromptType(promptType);
  inputId = inputId || getInputFieldId(id);

  const { prompt, setPrompt } = useTryOnPrompt(promptType);

  const [inputValue, setInputValue] = React.useState(prompt);

  React.useEffect(() => {
    setInputValue(prompt);
  }, [prompt]);

  return (
    <div {...props} id={id} className={classNames("flex flex-col", className)}>
      {children}
      <input
        id={inputId}
        className={InputBoxClassName}
        value={inputValue}
        type="text"
        onChange={(e) => {
          setInputValue(removeLineBreaks(e.currentTarget.value));
        }}
        onBlur={(e) => {
          setPrompt(cleanupPrompt(e.currentTarget.value));
        }}
      />
    </div>
  );
}

function TryOnPromptInput({
  promptType,
  labelContent,
  id,
  inputId,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  inputId?: string;
  promptType: TryOnPromptType;
  labelContent: React.ReactNode;
}) {
  id = id || getIdFromPromptType(promptType);
  inputId = inputId || getInputFieldId(id);

  return (
    <TryOnPromptInputField promptType={promptType} id={id} inputId={inputId} {...props}>
      <label htmlFor={inputId} className="mb-2 text-zinc-300">
        {labelContent}
      </label>
    </TryOnPromptInputField>
  );
}

function TryOnClothPromptInput(
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) {
  const id = getIdFromPromptType("cloth");
  const inputId = getInputFieldId(id);
  const tryOnClothPromptState = editorContextStore((state) => state.tryOnClothPromptState);
  const isGenerating = tryOnClothPromptState === "generating";
  return (
    <TryOnPromptInputField promptType="cloth" id={id} inputId={inputId} {...props}>
      <div className="w-full flex flex-row items-center justify-center">
        <label htmlFor={inputId} className="mb-2 text-zinc-300">
          Cloth
        </label>
        <div className="flex-1" />
        <button
          className={classNames(
            "flex flex-row items-center justify-start text-xs  transition-colors truncate",
            isGenerating
              ? "text-zinc-600 cursor-wait"
              : "text-zinc-500  hover:text-lime-500 active:text-lime-800 cursor-pointer",
          )}
          onClick={() => {
            if (isGenerating) {
              return;
            }

            console.log(`Clicked auto caption`);

            generateClothPrompt();
          }}
        >
          <RefreshCcw
            width={12}
            height={12}
            className={classNames("mr-1", isGenerating ? "animate-spin" : "")}
          />
          {isGenerating ? "Generating" : "Auto caption"}
        </button>
      </div>
    </TryOnPromptInputField>
  );
}

function TryOnModelPromptInput(
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) {
  return <TryOnPromptInput promptType="model" labelContent="Model" {...props} />;
}

function TryOnBackgroundPromptInput(
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) {
  return <TryOnPromptInput promptType="background" labelContent="Background" {...props} />;
}

enum DownloadButtonState {
  Idle = "idle",
  Downloading = "downloading",
  Inactive = "inactive",
}

function DownloadButton() {
  const imageUrls = editorContextStore((state) => state.tryOnRenderResults);

  const [buttonState, setButtonState] = React.useState(DownloadButtonState.Inactive);

  React.useEffect(() => {
    setButtonState(imageUrls?.length > 0 ? DownloadButtonState.Idle : DownloadButtonState.Inactive);
  }, [imageUrls]);

  return (
    <button
      className={classNames(
        buttonState === DownloadButtonState.Inactive
          ? SecondaryButtonClassNameDisabled
          : buttonState === DownloadButtonState.Downloading
            ? SecondaryButtonClassName
            : SecondaryButtonClassNameInactive,
        buttonState === DownloadButtonState.Downloading ? "cursor-wait" : "",
        "flex flex-row justify-center text-center transition-colors",
      )}
      onClick={() => {
        if (buttonState !== DownloadButtonState.Idle) {
          return;
        }

        setButtonState(DownloadButtonState.Downloading);

        Promise.all(
          imageUrls.map(({ imageUrl }, index) => {
            return downloadImageDataUrl(imageUrl, `fashion-result-${index}`);
          }),
        )
          .catch((error) => {
            console.error(error);
            displayUiMessage("Cannot download fashion output images.", "error");
          })
          .finally(() => {
            setButtonState(DownloadButtonState.Idle);
          });
      }}
    >
      <Download size={18} className="mr-2" />
      <span>
        {buttonState === DownloadButtonState.Downloading
          ? "Downloading ..."
          : "Download result image"}
      </span>
    </button>
  );
}

export function TryOnRender() {
  const setActiveLeftPanels = editorContextStore((state) => state.setActiveLeftPanels);

  React.useEffect(() => {
    // Make sure that the previous panel of is "TryOnSelectPose"

    setActiveLeftPanels((prevPanels) => {
      if (prevPanels.length < 1) {
        return prevPanels;
      }

      if (prevPanels.length === 1) {
        return ["TryOnSelectPose", prevPanels[0]];
      }

      if (prevPanels[prevPanels.length - 2] !== "TryOnSelectPose") {
        const newPanels = [...prevPanels];
        newPanels[prevPanels.length - 2] = "TryOnSelectPose";
        return newPanels;
      }

      return prevPanels;
    });
  }, [setActiveLeftPanels]);

  return (
    <div className="flex flex-col gap-4 pt-2">
      <Navigate>
        <span className="text-zinc-500 group-hover:text-lime-800 mr-2 transition-colors">
          Step 3:
        </span>
        <span>Edit Prompt & Generate</span>
      </Navigate>
      <TryOnClothPromptInput />
      <TryOnModelPromptInput />
      <TryOnBackgroundPromptInput />
      <TryOnRenderButton />
      <DownloadButton />
      <TryOnUpscaleButton />
    </div>
  );
}
