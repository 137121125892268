import { Backend } from "@/backend/base";
import { CustomModelPostProcessActionType } from "@/backend/custom-model-post-process";
import { displayUiMessage } from "@/components/utils/display-message";
import { InfoBox } from "@/components/utils/info-box";
import { editorContextStore } from "@/contexts/editor-context";
import {
  CustomModelPlaygroundPromptEditorState,
  CustomModelSetPromptEditorStateEventHandler,
  getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput,
  getImageSizeFromCustomModelPredictionInput,
  getModelTriggerWord,
} from "@/core/common/types";
import { ModelEnum } from "@/core/common/types/any-llm";
import { isReferenceHumanDoc, ReferenceHumanDoc } from "@/core/common/types/reference-human";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { isImageFile } from "@/core/utils/image-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { ShortcutsUtils } from "@/core/utils/shortcuts-utils";
import { getPromptStateFromText } from "@/core/utils/text-utils";
import { sortByTimeModified } from "@/core/utils/time-utils";
import { generateUUID } from "@/core/utils/uuid-utils";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { PaintBrushCursor } from "components/canvas-frames/paint-brush-cursor";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameLoading,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { LeftPanelDropdownZIndex } from "components/constants/zIndex";
import { PaintMaskCanvasControllerPaintBrushType } from "components/editor/paint-mask-canvas-controller";
import { usePaintMaskCanvasController } from "components/editor/paint-mask-canvas-controller-utils";
import { SimpleSpinner } from "components/icons/simple-spinner";
import LoadingCardStyles from "components/panels/panel-items/components/loading-card.module.css";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { ImageComponent } from "components/utils/image";
import { Timestamp } from "firebase/firestore";
import { SerializedEditorState } from "lexical";
import { RotateCcw, UploadCloud } from "lucide-react";
import React from "react";
import { useInView } from "react-intersection-observer";
import {
  imageEditorContainerClassName,
  mainPanelClassName,
  toolbarPanelClassName,
} from "./classnames";
import { CustomModelImageEditorBackButton } from "./custom-model-editor-back-button";
import {
  CustomModelImageEditorMode,
  useCustomModelImageEditorActiveImageStoragePath,
  useCustomModelImageEditorContext,
} from "./custom-model-image-editor-context";
import { useCustomModelCompareOutputContext } from "./custom-model-image-output-context";
import {
  getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState,
  getMentionNodesFromSerializedPromptEditorState,
  getSerializedPromptEditorStateFromText,
} from "./custom-model-mention-plugin";
import { CustomModelPromptEditor } from "./custom-model-prompt-editor";
import {
  CustomModelRegenerateHumanEditorMode,
  useCustomModelRegenerateHumanContext,
} from "./custom-model-regenerate-human-context";

function CustomModelRegenerateHumanImageEditor({
  children,
  className = "",
  tabIndex = 0,
  brushColor = "#84cc16",
  ...props
}: React.HTMLAttributes<HTMLDivElement> &
  React.PropsWithChildren & {
    brushColor?: string;
  }) {
  const { imageUrl, maskImageUrl, maskBrushSize, maskBrushType } =
    useCustomModelRegenerateHumanContext();

  const {
    canvasContainerRef,
    canvasRef,
    maskCanvasRef,
    brushCursorRef,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onPointerEnter,
    onPointerLeave,
    onWheel,
    isCanvasMoving,
    isPointerOver,
    isBrushVisible,
    isPointerOverToolbarRef,
    undo,
    redo,
    numRedos,
    numUndos,
    clearMask,
    saveCanvas,
    getCanvasMaskUrl,
  } = usePaintMaskCanvasController({
    imageUrl,
    maskImageUrl,
    brushSize: maskBrushSize,
    brushType: maskBrushType,
    brushColor,
  });

  return (
    <div
      {...props}
      tabIndex={tabIndex}
      ref={canvasContainerRef}
      className={classNames(
        "relative w-full h-full rounded-md overflow-hidden bg-zinc-800/50 focus-visible:outline focus-visible:outline-zinc-500/50",
        className,
      )}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onWheel={onWheel}
      onKeyDown={(e) => {
        const event = e.nativeEvent;
        if (ShortcutsUtils.isCtrlZ(event)) {
          undo();
        } else if (ShortcutsUtils.isCtrlShiftZ(event) || ShortcutsUtils.isCtrlY(event)) {
          redo();
        } else if (ShortcutsUtils.isCtrlS(event) && process.env.NODE_ENV === "development") {
          e.preventDefault();
          saveCanvas();
        }
      }}
    >
      <canvas ref={canvasRef} />
      <canvas
        ref={maskCanvasRef}
        className="absolute left-0 top-0 transition-opacity"
        style={{
          opacity: isCanvasMoving ? 0 : 0.5,
        }}
      />
      <PaintBrushCursor
        ref={brushCursorRef}
        className={
          maskBrushType === PaintMaskCanvasControllerPaintBrushType.Erase
            ? "bg-transparent"
            : "bg-lime-500/30"
        }
        style={{
          display: isBrushVisible ? "block" : "none",
          opacity: isPointerOver ? 1 : 0,
          width: `${maskBrushSize || 0}px`,
          height: `${maskBrushSize || 0}px`,
          zIndex: LeftPanelDropdownZIndex,
        }}
      />
    </div>
  );
}

function RegenerateHumanButton() {
  const {
    mode,
    setMode,
    imageUrl,
    promptEditorState,
    referenceHumanDocs,
    selectedReferenceHumanId,
  } = useCustomModelRegenerateHumanContext();

  const { setModes, prediction } = useCustomModelImageEditorContext();

  const backend = editorContextStore((state) => state.backend);

  const imageStoragePath = useCustomModelImageEditorActiveImageStoragePath();

  const { setActiveOutputImageIndex, setSourceImageUrl, setOutputImageUrls, setWidth, setHeight } =
    useCustomModelCompareOutputContext();

  const selectedReferenceHumanDoc = React.useMemo(() => {
    return referenceHumanDocs?.[selectedReferenceHumanId];
  }, [referenceHumanDocs, selectedReferenceHumanId]);

  const handleRegenerateHuman = React.useCallback(async () => {
    if (!backend) {
      return;
    }

    if (mode !== CustomModelRegenerateHumanEditorMode.Idle) {
      displayUiMessage("Backend is not initialized. Please refresh the page.", "error");
      return;
    }

    if (!imageStoragePath) {
      displayUiMessage("No image selected.", "error");
      return;
    }

    setMode(CustomModelRegenerateHumanEditorMode.Loading);

    try {
      const { text, json, scaleConfigs } = promptEditorState;

      const response = await backend.customModelSwapFace({
        type: CustomModelPostProcessActionType.RegenerateHuman,
        inputImageUrl: imageStoragePath,
        faceImageUrl: selectedReferenceHumanDoc?.faceImageStoragePath,
        prompt: text,
        promptJson: json || undefined,
        scaleConfigs,
      });

      if (!response.ok) {
        return;
      }

      const predictionId = response.predictionId;

      if (!predictionId) {
        return;
      }

      const outputImagePaths = await new Promise<string[]>((resolve) => {
        backend.onCustomModelPredictionUpdate({
          predictionId,
          callback: (predictionItem) => {
            const output = predictionItem?.output;
            if (!output) {
              return;
            }

            resolve(output);
          },
        });
      });

      const imageUrls = (
        await Promise.all(
          outputImagePaths.map((path) => {
            return Assets.loadAssetFromPath({
              path,
              backend,
            });
          }),
        )
      ).filter(Boolean) as string[];

      if (imageUrls.length > 0) {
        const { width, height } = prediction?.input
          ? getImageSizeFromCustomModelPredictionInput(prediction.input)
          : {
              width: 1024,
              height: 1024,
            };

        setSourceImageUrl(imageUrl);

        setOutputImageUrls(imageUrls);

        setActiveOutputImageIndex(0);

        setWidth(width);

        setHeight(height);

        setModes((modes) => [...modes, CustomModelImageEditorMode.CompareOutputs]);
      } else {
        debugError("Cannot load output images because they are invalid. ", outputImagePaths);
      }
    } catch (error) {
      displayUiMessage(`Error regenerating human: ${error}`, "error");
    } finally {
      setMode(CustomModelRegenerateHumanEditorMode.Idle);
    }
  }, [
    mode,
    imageUrl,
    backend,
    promptEditorState,
    imageStoragePath,
    prediction?.input,
    selectedReferenceHumanDoc,
    setMode,
    setSourceImageUrl,
    setOutputImageUrls,
    setWidth,
    setHeight,
    setModes,
    setActiveOutputImageIndex,
  ]);

  React.useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      return;
    }

    const text =
      "A Caucasian man with a blonde comb-over and blue eyes wearing a beige DOG5R jacket in front of soft background, 35mm lens, professional editorial photography";

    const promptEditorState = getSerializedPromptEditorStateFromText({
      text,
      triggerWordToMentionNode: {
        DOG5R: {
          detail: 1,
          format: 0,
          mode: "segmented",
          style: "",
          text: "@Fashion-dog5R/Training-6xBxR",
          type: "mention",
          version: 1,
          trainingId: "6xBxRJM9CRzcfk1QHjzx",
          trainingDisplayName: "",
          modelId: "dog5RosqnMxW65hDZ1HK",
          modelDisplayName: "Fashion-dog5R",
        },
      },
    });

    debugLog("Serialized prompt editor state from text: ", promptEditorState);
  }, []);

  return (
    <button
      className={classNames(
        mode === CustomModelRegenerateHumanEditorMode.Loading
          ? PrimaryButtonClassNameLoading
          : PrimaryButtonClassName,
        "flex flex-row items-center justify-center gap-2",
      )}
      onClick={() => {
        handleRegenerateHuman();
      }}
    >
      {mode === CustomModelRegenerateHumanEditorMode.Loading && (
        <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
      )}
      <span>Regenerate human</span>
    </button>
  );
}

function getImageFilesFromFileList(fileList: FileList) {
  const outputFiles: File[] = [];

  for (let i = 0; i < fileList.length; ++i) {
    const file = fileList[i];
    if (!file) {
      continue;
    }

    if (isImageFile(file)) {
      outputFiles.push(file);
    }
  }

  return outputFiles;
}

function UploadReferenceFaceButton() {
  const backend = editorContextStore((state) => state.backend);
  const tryonUploadId = React.useMemo(() => `human-face-upload-${generateUUID()}`, []);

  const { mode, setMode, setReferenceHumanDocs, setSelectedReferenceHumanId } =
    useCustomModelRegenerateHumanContext();

  const uploadFiles = React.useCallback(
    async (newFiles: FileList) => {
      if (!backend) {
        return;
      }

      if (mode !== CustomModelRegenerateHumanEditorMode.Idle) {
        return;
      }

      try {
        setMode(CustomModelRegenerateHumanEditorMode.Uploading);

        const imageFiles = getImageFilesFromFileList(newFiles);

        await Promise.all(
          imageFiles.map(async (file) => {
            const response = await backend.uploadReferenceHumanFile({
              faceImage: file,
            });

            if (!response.ok) {
              return;
            }

            const referenceHumanDoc = response.referenceHumanDoc;

            setReferenceHumanDocs((prevDocs) => ({
              ...prevDocs,
              [referenceHumanDoc.id]: referenceHumanDoc,
            }));

            setSelectedReferenceHumanId(referenceHumanDoc.id);
          }),
        );
      } catch (error) {
        debugError("Error uploading virtual try-on input image: ", error);
      } finally {
        setMode(CustomModelRegenerateHumanEditorMode.Idle);
      }
    },
    [backend, mode, setMode, setSelectedReferenceHumanId, setReferenceHumanDocs],
  );

  return (
    <>
      <input
        type="file"
        id={tryonUploadId}
        style={{
          display: "none",
        }}
        onChange={(e) => {
          const files = e.target?.files;

          if (!files) {
            return;
          }

          uploadFiles(files);
        }}
      />
      <label htmlFor={tryonUploadId}>
        <div
          id="left-panel-assets-upload-image-button"
          className={classNames(
            "flex items-center justify-center cursor-pointer transition-colors gap-2",
            mode !== CustomModelRegenerateHumanEditorMode.Idle
              ? SecondaryButtonClassNameDisabled
              : SecondaryButtonClassNameInactive,
          )}
        >
          {mode === CustomModelRegenerateHumanEditorMode.Uploading ? (
            <>
              <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
              <div className="select-none truncate">Uploading reference face</div>
            </>
          ) : (
            <>
              <UploadCloud className="select-none" size={18} />
              <div className="select-none truncate">Upload reference face</div>
            </>
          )}
        </div>
      </label>
    </>
  );
}

function HumanFacesGridItem({
  backend,
  referenceHumanDoc,
}: {
  backend?: Backend;
  referenceHumanDoc: ReferenceHumanDoc;
}) {
  const [imageUrl, setImageUrl] = React.useState("");

  const { selectedReferenceHumanId, setSelectedReferenceHumanId } =
    useCustomModelRegenerateHumanContext();

  React.useEffect(() => {
    if (!backend) {
      return;
    }

    Assets.loadAssetFromPath({
      backend,
      path: referenceHumanDoc.faceImageStoragePath,
    }).then((imageUrl) => {
      setImageUrl(imageUrl ?? "");
    });
  }, [backend, referenceHumanDoc]);

  return (
    <div
      className={classNames(
        "rounded overflow-hidden outline outline-2 transition-colors cursor-pointer",
        selectedReferenceHumanId === referenceHumanDoc.id
          ? "outline-lime-500"
          : "outline-zinc-800 hover:outline-lime-600",
      )}
      onClick={() => {
        if (!backend) {
          return;
        }

        setSelectedReferenceHumanId((selectedReferenceHumanId) =>
          selectedReferenceHumanId === referenceHumanDoc.id ? "" : referenceHumanDoc.id,
        );
      }}
    >
      <AspectRatio.Root
        ratio={1}
        className={classNames(
          LoadingCardStyles.Card,
          imageUrl ? "" : LoadingCardStyles.IsLoading,
          "relative items-center justify-center",
        )}
      >
        <ImageComponent src={imageUrl} />
      </AspectRatio.Root>
    </div>
  );
}

function HumanFacesGrid() {
  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const { referenceHumanDocs, setReferenceHumanDocs } = useCustomModelRegenerateHumanContext();

  const generator = React.useMemo(() => {
    if (!backend || !publicUserId) {
      return;
    }

    return backend.getReferenceHumanGenerator({
      batchSize: 10,
      publicUserId,
    });
  }, [backend, publicUserId]);

  const docs = React.useMemo(() => {
    return Object.values(referenceHumanDocs).sort(sortByTimeModified);
  }, [referenceHumanDocs]);

  const [lastRowRef, lastRowInView] = useInView();

  React.useEffect(() => {
    if (!generator || !lastRowInView) {
      return;
    }

    generator.getNextBatch().then((docs) => {
      const humanDocs = docs.filter(isReferenceHumanDoc);

      const newDocs = Object.fromEntries(
        humanDocs.map((doc) => [
          doc.id,
          {
            ...doc,
            timeCreated: Timestamp.now(),
            timeModified: Timestamp.now(),
          },
        ]),
      );

      setReferenceHumanDocs((prevDocs) => ({
        ...prevDocs,
        ...newDocs,
      }));
    });
  }, [lastRowInView, generator, setReferenceHumanDocs]);

  return (
    <div className="group flex flex-col items-stretch gap-2">
      <div className="text-zinc-500 group-hover:text-zinc-300 transition-colors">
        Reference face
      </div>
      <UploadReferenceFaceButton />
      <div className="grid grid-cols-2 gap-2">
        {docs.map((doc) => (
          <HumanFacesGridItem key={doc.id} backend={backend} referenceHumanDoc={doc} />
        ))}
      </div>
      <div ref={lastRowRef} className="w-full h-12" />
    </div>
  );
}

/**
 * Cleans up a language model response string by removing specified starting labels,
 * stripping leading and trailing punctuations and whitespaces, and removing
 * starting and ending quotes while preserving internal quotes.
 *
 * @param input - The input string to be cleaned.
 * @returns The cleaned-up string.
 */
function cleanupLanguageModelResponse(input: string): string {
  // Step 1: Remove starting labels like "Caption: ", "Prompt: ", "Response: ", etc.
  // Define the labels to remove. Add more labels to the array as needed.
  const labels = ["caption", "prompt", "response"];
  const labelPattern = new RegExp(`^(?:${labels.join("|")}):\\s*`, "i");
  let cleaned = input.replace(labelPattern, "");

  // Step 2: Remove leading and trailing punctuation (excluding quotes) and whitespace
  // The regex matches any combination of specified punctuation and whitespace at the start (^)
  // or end ($) of the string and replaces them with an empty string.
  cleaned = cleaned.replace(/^[\s.,!?;:()\-\[\]{}]+|[\s.,!?;:()\-\[\]{}]+$/g, "");

  // Step 3: Remove starting and ending quotes if they are the same and either single or double quotes
  if (
    (cleaned.startsWith('"') && cleaned.endsWith('"')) ||
    (cleaned.startsWith("'") && cleaned.endsWith("'"))
  ) {
    cleaned = cleaned.substring(1, cleaned.length - 1);
  }

  return cleaned;
}

function getSerializedMentionNodesFromSerializedEditorState({
  promptEditorState,
}: {
  promptEditorState?: SerializedEditorState;
}) {
  try {
    if (!promptEditorState?.root) {
      return [];
    }
    return getMentionNodesFromSerializedPromptEditorState(promptEditorState.root);
  } catch (error) {
    debugError("Error getting mention nodes from serialized editor state: ", error);
    return [];
  }
}

interface ReplaceHumanCustomModelPromptEditorStateArgs {
  backend: Backend;
  humanCaption: string;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
}

async function replaceHumanCustomModelPromptEditorState({
  backend,
  humanCaption,
  promptEditorState,
}: ReplaceHumanCustomModelPromptEditorStateArgs) {
  try {
    const { text, json } = promptEditorState;

    const promptJson = json ? JSON.parse(json) : {};

    const mentionNodes = getSerializedMentionNodesFromSerializedEditorState({
      promptEditorState: promptJson as SerializedEditorState,
    });

    const triggerWordToMentionNode = Object.fromEntries(
      mentionNodes.map((mentionNode) => [
        getModelTriggerWord({ modelId: mentionNode.modelId }),
        mentionNode,
      ]),
    );

    const response = await backend.callAnyLanguageModel({
      input: {
        model: ModelEnum.AnthropicsClaude3Haiku,
        prompt: `Replace the person in the following prompt with caption: '${humanCaption}'. Use the person caption as the subject with a verb if there is no person, e.g. wearing, holding, standing etc. Prompt: '${text}'.`,
      },
    });

    if (!response) {
      return undefined;
    }

    const replacedPrompt = cleanupLanguageModelResponse(response);

    if (!replacedPrompt) {
      return undefined;
    }

    const replacePromptEditorState = getSerializedPromptEditorStateFromText({
      text: replacedPrompt,
      triggerWordToMentionNode,
    });

    return replacePromptEditorState;
  } catch (error) {
    debugError("Error replacing human custom model prompt: ", error);
    return undefined;
  }
}

function RegenerateHumanPromptEditor() {
  const { promptEditorState, setPromptEditorState, selectedReferenceHumanId, referenceHumanDocs } =
    useCustomModelRegenerateHumanContext();

  const backend = editorContextStore((state) => state.backend);
  const eventEmitter = editorContextStore((state) => state.eventEmitter);

  const selectedReferenceHumanDoc = React.useMemo(() => {
    return referenceHumanDocs?.[selectedReferenceHumanId];
  }, [referenceHumanDocs, selectedReferenceHumanId]);

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!backend || !selectedReferenceHumanDoc) {
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    debugLog("Start loading reference human doc.");

    replaceHumanCustomModelPromptEditorState({
      backend,
      humanCaption: selectedReferenceHumanDoc.caption,
      promptEditorState,
    })
      .then((newPromptEditorState) => {
        if (!newPromptEditorState) {
          return;
        }

        debugLog("Finish loading reference human doc.\n", newPromptEditorState);

        eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
          "custom-model:set-prompt-editor-state",
          {
            promptEditorStateJson: JSON.stringify(newPromptEditorState),
          },
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [backend, eventEmitter, isLoading, promptEditorState, selectedReferenceHumanDoc]);

  return (
    <div className="group flex flex-col items-stretch gap-2">
      <div className="flex flex-row items-center gap-2">
        <div className="flex-1 min-w-0 text-zinc-500 group-hover:text-zinc-300 transition-colors truncate">
          Prompt
        </div>
        {isLoading && (
          <div className="flex flex-row items-center gap-1 text-xs text-zinc-500">
            <RotateCcw size={14} className="animate-spin" />
            <span className="truncate">Captioning</span>
          </div>
        )}
      </div>
      <CustomModelPromptEditor
        editorState={promptEditorState}
        setEditorState={setPromptEditorState}
      />
    </div>
  );
}

export function CustomModelRegenerateHuman() {
  const { imageUrl, prediction } = useCustomModelImageEditorContext();
  const { setImageUrl, setPromptEditorState } = useCustomModelRegenerateHumanContext();

  React.useEffect(() => {
    setImageUrl(imageUrl);
  }, [imageUrl, setImageUrl]);

  const predictionInput = React.useMemo(
    () =>
      prediction?.input &&
      getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction?.input),
    [prediction?.input],
  );

  React.useEffect(() => {
    if (!prediction) {
      return;
    }

    const promptJson = predictionInput?.promptJson;

    if (promptJson) {
      try {
        const promptEditorState =
          getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
            promptEditorState: JSON.parse(
              promptJson || getPromptStateFromText(predictionInput.prompt),
            ),
          });

        console.log("Prompt editor state: ", promptEditorState);

        setPromptEditorState(promptEditorState);
      } catch (error) {
        debugError(
          "Error setting prompt editor state with prediction input ",
          predictionInput,
          "\n",
          error,
        );
      }
    }
  }, [prediction, predictionInput, setPromptEditorState]);

  return (
    <div className={imageEditorContainerClassName}>
      <div className={classNames(mainPanelClassName, "md:flex-1")}>
        <CustomModelRegenerateHumanImageEditor tabIndex={0} />
      </div>
      <ScrollAreaContainer className={classNames(toolbarPanelClassName)}>
        <div className="flex flex-col items-stretch gap-4 pb-8">
          <CustomModelImageEditorBackButton />
          <div className="flex flex-col items-stretch gap-4">
            <InfoBox defaultValue="" className="w-full">
              <ol className="list-decimal list-outside pl-5 flex flex-col gap-2 text-lime-200 text-sm leading-relaxed">
                <li>
                  Describe the person in the prompt below to regenerate the person. For example, "a
                  25-year-old African American man with short hair".
                </li>
                <li>Click "Regenerate human" button and wait for the result to appear.</li>
                <li>
                  (Optional) Upload a reference face image and wait for the caption to automatically
                  update. Then click "Regenerate human" to generate an output image with a person
                  that resembles the reference face.
                </li>
                <li>
                  If the result person does not resemble the reference face, try describe the person
                  in detail in the prompt.
                </li>
              </ol>
            </InfoBox>
            <RegenerateHumanPromptEditor />
            <RegenerateHumanButton />
            <HumanFacesGrid />
          </div>
        </div>
      </ScrollAreaContainer>
    </div>
  );
}
