import React, { useState } from "react";
import { Navigate } from "components/panels/panel-items/components/navigate";
import { LeftPanelRenderButton } from "./render-button";
import {
  GenerateTemplatesSearchRoot,
  LeftPanelSectionGenerateTemplate,
  useGenerateTemplatesContext,
} from "components/panels/panel-items/components/generate-templates";
import {
  PrimaryButtonClassNameDisabled,
  TextButtonClassName,
} from "components/constants/class-names";
import { classNames } from "@/core/utils/classname-utils";
import { editorContextStore } from "contexts/editor-context";
import {
  PromptEditorForm,
  PromptEditorFormPreview,
  PromptEditorFormPreviewTitle,
} from "./prompt-editor-form";
import { GenerateToolEditorTab, GenerateToolEditorTabLabels } from "@/core/common/types";
import { useTabsListPlaceholder } from "../components/tabs";
import {
  isSearchingRecentlyUsed,
  LeftPanelPastGenerations,
  LeftPanelSectionPastGenerations,
} from "./past-generations";
import { RealTimePreviewToggle } from "./realtime-preview-toggle";
import { useUpdatePromptFromObjectsEffect } from "./use-update-prompt-from-objects";
import { displayUiMessage } from "components/utils/display-message";
import { Tooltip } from "components/utils/tooltip";

function GeneratePromptEditor() {
  return <PromptEditorForm />;
}

function ErrorButton({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={classNames(PrimaryButtonClassNameDisabled, className ?? "")}>
      {children}
    </div>
  );
}

function RenderButton() {
  const [canRender, setCanRender] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const generateToolPromptEditorType = editorContextStore(
    (state) => state.generateToolPromptEditorType,
  );
  const generateToolPromptTemplate = editorContextStore(
    (state) => state.generateToolPromptTemplate,
  );

  React.useEffect(() => {
    if (generateToolPromptEditorType === "Template") {
      const hasSubject =
        generateToolPromptTemplate.words.find(
          (word) => word.autocompleteType === "subject" && word.value.length > 0,
        ) != null;
      if (!hasSubject) {
        setCanRender(false);
        setErrorMessage("Please describe your product");
        return;
      }
    }
    setCanRender(true);
  }, [generateToolPromptEditorType, generateToolPromptTemplate]);

  if (!canRender) {
    return <ErrorButton>{errorMessage}</ErrorButton>;
  }

  return <LeftPanelRenderButton />;
}

function GenerateExamplesInner() {
  const { searchString } = useGenerateTemplatesContext();
  const isRecentlyUsed = isSearchingRecentlyUsed(searchString);
  return (
    <>
      <div
        style={{
          display: isRecentlyUsed ? "block" : "none",
        }}
      >
        <LeftPanelSectionPastGenerations />
      </div>
      <div
        style={{
          display: isRecentlyUsed ? "none" : "block",
        }}
      >
        <LeftPanelPastGenerations />
        <div className="h-2" />
        <LeftPanelSectionGenerateTemplate />
      </div>
    </>
  );
}

function GenerateExamples() {
  return (
    <GenerateTemplatesSearchRoot>
      <GenerateExamplesInner />
    </GenerateTemplatesSearchRoot>
  );
}

const leftPanelGenerateEditorTabs: Record<GenerateToolEditorTab, React.ReactNode> = {
  Templates: <GenerateExamples key="templates" />,
  Editor: <GeneratePromptEditor key="editor" />,
};

function LeftPanelGenerateEditor() {
  const generateToolEditorTab = editorContextStore((state) => state.generateToolEditorTab);
  const tabsListRef = React.useRef<HTMLDivElement | null>(null);
  const tabsListPlaceholderRef = React.useRef<HTMLDivElement | null>(null);

  useTabsListPlaceholder({
    tabsListRef,
    tabsListPlaceholderRef,
  });
  return (
    <div className="my-3">
      {Object.entries(leftPanelGenerateEditorTabs).map(([tab, node]) => (
        <div
          key={tab}
          className="w-full"
          style={{
            display: tab === generateToolEditorTab ? "block" : "none",
          }}
        >
          {node}
        </div>
      ))}
    </div>
  );
}

function GenerateEditorTabTrigger({ value }: { value: GenerateToolEditorTab }) {
  const activeTab = editorContextStore((state) => state.generateToolEditorTab);

  const setGenerateToolEditorTab = editorContextStore((state) => state.setGenerateToolEditorTab);

  return (
    <button
      id={`left-panel-generate-tab-${value}`}
      className={classNames(
        "py-2 text-center hover:text-lime-500 active:text-lime-600 focus:outline-none active:outline-none focus-visible:outline-none border-solid border-0 border-b transition-colors font-semibold cursor-pointer",
        value === activeTab
          ? "border-lime-500 hover:border-lime-500 text-zinc-300"
          : "border-zinc-800 text-zinc-400",
      )}
      onClick={() => setGenerateToolEditorTab(value)}
    >
      {GenerateToolEditorTabLabels[value]}
    </button>
  );
}

function GenerateEditorTabList() {
  return (
    <div className="grid grid-cols-2 gaps-2 shadow-md bg-zinc-900">
      <GenerateEditorTabTrigger value="Templates" />
      <GenerateEditorTabTrigger value="Editor" />
    </div>
  );
}

export function GenerateSimpleFixedTop() {
  return (
    <>
      <Navigate />
    </>
  );
}

export function GenerateSimpleFixedBottom() {
  return (
    <>
      <div className="h-4" />
      <RenderButton />
      <div className="h-4" />
    </>
  );
}

function WhereIsGenerateButton() {
  const zoomToGenerateButton = React.useCallback(() => {
    try {
      const { editor } = editorContextStore.getState();

      if (!editor) {
        displayUiMessage("The editor is not initailized yet.", "error");
        return;
      }

      const generationFrame = editor?.generationFrames?.generationFrame;

      if (!generationFrame) {
        displayUiMessage("The generation frame is not initailized yet.", "error");
        return;
      }

      editor.zoom.zoomToFit(generationFrame);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Tooltip
      triggerChildren={
        <div
          className={classNames(
            TextButtonClassName,
            "w-full justify-start text-xs text-zinc-600 pt-2",
          )}
          onClick={() => {
            zoomToGenerateButton();
          }}
        >
          Where is the generate button?
        </div>
      }
      contentChildren={
        <div className="flex flex-col gap-2">
          <div>
            The <span className="text-lime-500">Generate Final</span> button is located at the
            bottom of the generation box.
          </div>
          <button
            className={classNames(TextButtonClassName, "justify-start")}
            onClick={() => {
              zoomToGenerateButton();
            }}
          >
            Click to show the button.
          </button>
        </div>
      }
    />
  );
}

export function GenerateSimple() {
  useUpdatePromptFromObjectsEffect();

  return (
    <>
      <PromptEditorFormPreviewTitle />
      <PromptEditorFormPreview />
      <div className="h-4" />
      <RenderButton />
      <div className="h-2" />
      <RealTimePreviewToggle />
      <div className="h-2" />
      <GenerateEditorTabList />
      <div id="left-panel-generate-simple" className="flex flex-col">
        <LeftPanelGenerateEditor />
      </div>
    </>
  );
}
