import { IHistory } from "@/core/common/interfaces";
import { drawCircle } from "components/canvas-frames/utils";
import EventEmitter from "events";
import _, { clamp, throttle } from "lodash";
import React from "react";

export type Point = {
  x: number;
  y: number;
};

export type Matrix2x3 = [number, number, number, number, number, number];

export type Bounds = {
  left: number;
  top: number;
  right: number;
  bottom: number;
};

export function getDefaultBounds(): Bounds {
  return {
    left: -1,
    right: -1,
    top: -1,
    bottom: -1,
  };
}

export function isBoundsValid(bounds: Bounds) {
  return bounds.left >= 0 && bounds.right >= 0 && bounds.top >= 0 && bounds.bottom >= 0;
}

export function mergeBounds(
  bounds0: Bounds,
  bounds1: Bounds,
  output: Bounds = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
): Bounds {
  const bounds0Valid = isBoundsValid(bounds0);
  const bounds1Valid = isBoundsValid(bounds1);

  if (!bounds0Valid || !bounds1Valid) {
    const bounds = bounds0Valid ? bounds0 : bounds1;
    if (bounds) {
      output.left = bounds.left;
      output.top = bounds.top;
      output.right = bounds.right;
      output.bottom = bounds.bottom;
    }
    return output;
  }

  output.left = Math.min(bounds0.left, bounds1.left);
  output.top = Math.min(bounds0.top, bounds1.top);
  output.right = Math.max(bounds0.right, bounds1.right);
  output.bottom = Math.max(bounds0.bottom, bounds1.bottom);
  return output;
}

export function getBoundsFromXYCoordinates(
  coordinates: number[],
  lineWidth: number,
): Bounds | null {
  if (coordinates.length % 2 !== 0 || coordinates.length === 0) {
    return null; // Coordinates should come in pairs and not be empty
  }

  let minX = coordinates[0];
  let minY = coordinates[1];
  let maxX = coordinates[0];
  let maxY = coordinates[1];

  for (let i = 2; i < coordinates.length; i += 2) {
    minX = Math.min(minX, coordinates[i]);
    maxX = Math.max(maxX, coordinates[i]);
    minY = Math.min(minY, coordinates[i + 1]);
    maxY = Math.max(maxY, coordinates[i + 1]);
  }

  return {
    left: Math.max(minX - lineWidth, 0),
    top: Math.max(minY - lineWidth, 0),
    right: maxX + lineWidth,
    bottom: maxY + lineWidth,
  };
}

export function getAffineTransformationMatrixForBounds(
  bounds: Bounds,
  canvasWidth: number,
  canvasHeight: number,
): {
  transformMatrix: Matrix2x3;
  boundsRelative: Bounds;
} {
  // Calculate dimensions of bounding box
  const boundsWidth = bounds.right - bounds.left;
  const boundsHeight = bounds.bottom - bounds.top;

  // Calculate center of bounding box
  const boundsCenterX = (bounds.left + bounds.right) / 2;
  const boundsCenterY = (bounds.top + bounds.bottom) / 2;

  // Calculate scale to fit bounding box within canvas
  const scale = Math.min(canvasWidth / boundsWidth, canvasHeight / boundsHeight);

  // Calculate translation to center bounding box within canvas
  const translateX = canvasWidth / 2 - boundsCenterX * scale;
  const translateY = canvasHeight / 2 - boundsCenterY * scale;

  // Calculate the bounds relative to the canvas after transformation
  const boundsRelative: Bounds = {
    left: (canvasWidth - boundsWidth * scale) / 2,
    right: (canvasWidth + boundsWidth * scale) / 2,
    top: (canvasHeight - boundsHeight * scale) / 2,
    bottom: (canvasHeight + boundsHeight * scale) / 2,
  };

  // Return affine transformation matrix
  // Format: [scaleX, skewY, skewX, scaleY, translateX, translateY]
  const transformMatrix: Matrix2x3 = [scale, 0, 0, scale, translateX, translateY];

  return {
    transformMatrix,
    boundsRelative,
  };
}

type CanvasImagePosition = {
  left: number;
  top: number;
  width: number;
  height: number;
};

export function getBoundsFromCanvasImagePosition(imagePosition: CanvasImagePosition): Bounds {
  return {
    left: imagePosition.left,
    top: imagePosition.top,
    right: imagePosition.left + imagePosition.width,
    bottom: imagePosition.top + imagePosition.height,
  };
}

export type CanvasGridData = {
  type: "grid";
  color: string;
  gridSize: number;
  width: number;
  height: number;
};

export type CanvasImageData = {
  type: "image";
  image?: CanvasImageSource;
  source: CanvasImagePosition;
  target: CanvasImagePosition;
  alpha?: number;
};

export type CanvasHollowRectData = {
  type: "hollow-rect";
  lineWidth: number;
  strokeStyle: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

export type CanvasBrushStrokeData = {
  type: "brush-stroke";
  brushType: "paint" | "erase";
  points: number[];
  lineWidth: number;
  color: string;
};

export type CanvasBrushStrokes = CanvasBrushStrokeData[];

export type HtmlCanvasObjectData =
  | CanvasImageData
  | CanvasGridData
  | CanvasHollowRectData
  | CanvasBrushStrokeData;

export type HtmlCanvasControllerProps = {
  ctx?: CanvasRenderingContext2D | null;
  bounds?: Bounds;
  scaleRange?: [number, number];
};

export class CanvasBrushStrokeHistory
  extends EventEmitter
  implements IHistory<CanvasBrushStrokeData>
{
  undos: CanvasBrushStrokes = [];

  redos: CanvasBrushStrokes = [];

  current?: CanvasBrushStrokeData;

  renderBrushStrokes?: () => void;

  initialize() {}

  destroy() {
    this.reset();
    this.renderBrushStrokes = undefined;
  }

  save(brushStroke?: CanvasBrushStrokeData) {
    if (!brushStroke) {
      return;
    }

    if (this.current) {
      this.undos.push(_.cloneDeep(this.current));
    }

    this.current = _.cloneDeep(brushStroke);

    this.redos.length = 0;
    this.emit("save");
  }

  undo = throttle(() => {
    if (!this.current) {
      return;
    }
    if (this.undos.length > 0) {
      const undoCommand = this.undos.pop();
      if (!undoCommand) {
        return;
      }
      this.redos.push(_.cloneDeep(this.current));
      this.current = undoCommand;
      this.renderBrushStrokes?.();
    } else {
      // Undo the current command
      this.undos.length = 0;
      this.redos.push(_.cloneDeep(this.current));
      this.current = undefined;
      this.renderBrushStrokes?.();
    }
    this.emit("undo");
  }, 100);

  redo = throttle(() => {
    const redoCommand = this.redos.pop();
    if (!redoCommand) {
      return;
    }
    if (this.current) {
      this.undos.push(_.cloneDeep(this.current));
    }
    this.current = redoCommand;
    this.renderBrushStrokes?.();
    this.emit("redo");
  }, 100);

  numUndos = () => this.undos.length + (this.current ? 1 : 0);

  numRedos = () => this.redos.length;

  reset() {
    this.undos.length = 0;
    this.redos.length = 0;
    this.current = undefined;
  }
}

export class HtmlCanvasController extends EventEmitter {
  static drawGrid({ ctx, object }: { ctx: CanvasRenderingContext2D; object: CanvasGridData }) {
    const { color, gridSize, width, height } = object;

    // Set the color for our grid lines
    ctx.strokeStyle = color;

    // Draw the horizontal lines
    for (let i = gridSize; i < height; i += gridSize) {
      ctx.beginPath();
      ctx.moveTo(0, i);
      ctx.lineTo(width, i);
      ctx.stroke();
    }

    // Draw the vertical lines
    for (let i = gridSize; i < width; i += gridSize) {
      ctx.beginPath();
      ctx.moveTo(i, 0);
      ctx.lineTo(i, height);
      ctx.stroke();
    }
  }

  static drawImage({ ctx, object }: { ctx: CanvasRenderingContext2D; object: CanvasImageData }) {
    const { image, source, target, alpha } = object;

    if (!image || alpha === 0) {
      return;
    }

    const prevGlobalAlpha = ctx.globalAlpha;

    if (alpha) {
      ctx.globalAlpha = alpha;
    }

    ctx.drawImage(
      image,
      source.left,
      source.top,
      source.width,
      source.height,
      target.left,
      target.top,
      target.width,
      target.height,
    );

    ctx.globalAlpha = prevGlobalAlpha;
  }

  static drawHollowRect({
    ctx,
    object,
  }: {
    ctx: CanvasRenderingContext2D;
    object: CanvasHollowRectData;
  }) {
    const { lineWidth, strokeStyle, x, y, width, height } = object;

    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = strokeStyle;
    ctx.rect(x, y, width, height);
    ctx.stroke();
  }

  static drawBrushStroke({
    ctx,
    object,
  }: {
    ctx: CanvasRenderingContext2D;
    object: CanvasBrushStrokeData;
  }) {
    const { brushType, color, lineWidth, points } = object;

    if (points.length < 2) {
      return;
    }

    const prevStrokeStyle = ctx.strokeStyle;
    const prevFillStyle = ctx.fillStyle;
    const prevLineCap = ctx.lineCap;
    const globalCompositeOperation = ctx.globalCompositeOperation;
    const prevLineWidth = ctx.lineWidth;

    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.lineCap = "round";
    ctx.lineWidth = lineWidth;
    ctx.globalCompositeOperation = brushType === "erase" ? "destination-out" : "source-over";

    const numPoints = points.length / 2;

    if (numPoints < 2) {
      const x = points[0];
      const y = points[1];
      drawCircle(ctx, {
        x,
        y,
        r: lineWidth * 0.5,
      });
    } else {
      let prevPointerX = points[0];
      let prevPointerY = points[1];
      for (let i = 1; i < numPoints; ++i) {
        const currentX = points[2 * i + 0];
        const currentY = points[2 * i + 1];

        ctx.beginPath();
        ctx.moveTo(prevPointerX, prevPointerY);
        ctx.lineTo(currentX, currentY);
        ctx.stroke();

        prevPointerX = currentX;
        prevPointerY = currentY;
      }
    }

    ctx.strokeStyle = prevStrokeStyle;
    ctx.fillStyle = prevFillStyle;
    ctx.lineCap = prevLineCap;
    ctx.globalCompositeOperation = globalCompositeOperation;
    ctx.lineWidth = prevLineWidth;
  }

  static drawObject({
    ctx,
    object,
  }: {
    ctx: CanvasRenderingContext2D;
    object: HtmlCanvasObjectData;
  }) {
    if (object.type === "grid") {
      HtmlCanvasController.drawGrid({
        ctx,
        object,
      });
    } else if (object.type === "image") {
      HtmlCanvasController.drawImage({
        ctx,
        object,
      });
    } else if (object.type === "hollow-rect") {
      HtmlCanvasController.drawHollowRect({
        ctx,
        object,
      });
    } else if (object.type === "brush-stroke") {
      HtmlCanvasController.drawBrushStroke({
        ctx,
        object,
      });
    }
  }

  static getPointerEventLocation(e: { clientX: number; clientY: number }, offset: Point) {
    return {
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    };
  }

  protected matrix: Matrix2x3;
  protected invMatrix: Matrix2x3;
  protected scale: number;
  protected bounds: Bounds;
  protected useConstraint: boolean;
  protected maxScale: number;
  protected workPoint1: Point;
  protected workPoint2: Point;
  protected pos: Point;
  protected dirty: boolean;
  protected ctx: CanvasRenderingContext2D | null;
  protected scaleRange: [number, number];

  constructor({
    ctx = null,
    bounds = {
      left: 0,
      top: 0,
      right: 1000,
      bottom: 1000,
    },
    scaleRange = [0.5, 2],
  }: HtmlCanvasControllerProps) {
    super();
    this.ctx = ctx;
    this.matrix = [1, 0, 0, 1, 0, 0]; // current view transform
    this.invMatrix = [1, 0, 0, 1, 0, 0]; // current inverse view transform
    this.scale = 1; // current scale
    this.scaleRange = [...scaleRange];
    this.bounds = {
      ...bounds,
    };
    this.useConstraint = true; // if true then limit pan and zoom to keep bounds within the current context
    this.maxScale = 1;
    this.workPoint1 = { x: 0, y: 0 };
    this.workPoint2 = { x: 0, y: 0 };
    this.pos = { x: 0, y: 0 }; // current position of origin
    this.dirty = true;
  }

  center() {
    if (!this.ctx?.canvas) {
      return;
    }

    const { boundsWidth, boundsHeight } = this;

    this.setOrigin(
      this.bounds.left - (boundsWidth - this.ctx.canvas.width) / 2,
      this.bounds.top - (boundsHeight - this.ctx.canvas.height) / 2,
    );

    this.dirty = true;
  }

  clearCanvas() {
    if (!this.ctx) {
      return;
    }

    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
  }

  canvasDefault(): void {
    if (!this.ctx) {
      return;
    }

    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  }

  apply(): void {
    if (this.dirty) {
      this.update();
    }
    this.ctx?.setTransform(...this.matrix);
  }

  getScale(): number {
    return this.scale;
  }

  getMaxScale(): number {
    return this.maxScale;
  }

  updateInverseMatrix() {
    // calculate the inverse transformation
    const cross = this.matrix[0] * this.matrix[3] - this.matrix[1] * this.matrix[2];
    this.invMatrix[0] = this.matrix[3] / cross;
    this.invMatrix[1] = -this.matrix[1] / cross;
    this.invMatrix[2] = -this.matrix[2] / cross;
    this.invMatrix[3] = this.matrix[0] / cross;
  }

  update(): void {
    this.dirty = false;
    this.matrix[3] = this.matrix[0] = this.scale;
    this.matrix[1] = this.matrix[2] = 0;
    this.matrix[4] = this.pos.x;
    this.matrix[5] = this.pos.y;

    if (this.useConstraint) {
      this.constrain();
    }

    this.updateInverseMatrix();
  }

  constrain(): void {
    if (!this.ctx) {
      return;
    }

    this.maxScale = Math.max(
      this.ctx.canvas.width / (this.bounds.right - this.bounds.left) || 0,
      this.ctx.canvas.height / (this.bounds.bottom - this.bounds.top) || 0,
    );
    if (this.scale < this.maxScale) {
      this.matrix[0] = this.matrix[3] = this.scale = this.maxScale;
    }

    this.workPoint1.x = this.bounds.left;
    this.workPoint1.y = this.bounds.top;
    this.toScreen(this.workPoint1, this.workPoint2);
    if (this.workPoint2.x > 0) {
      this.matrix[4] = this.pos.x -= this.workPoint2.x;
    }
    if (this.workPoint2.y > 0) {
      this.matrix[5] = this.pos.y -= this.workPoint2.y;
    }

    this.workPoint1.x = this.bounds.right;
    this.workPoint1.y = this.bounds.bottom;
    this.toScreen(this.workPoint1, this.workPoint2);
    if (this.workPoint2.x < this.ctx?.canvas.width || 0) {
      this.matrix[4] = this.pos.x -= this.workPoint2.x - (this.ctx?.canvas.width || 0);
    }
    if (this.workPoint2.y < this.ctx?.canvas.height || 0) {
      this.matrix[5] = this.pos.y -= this.workPoint2.y - (this.ctx?.canvas.height || 0);
    }
  }

  toWorld(from: Point, point: Point = { x: 0, y: 0 }): Point {
    if (this.dirty) {
      this.update();
    }
    const xx = from.x - this.matrix[4];
    const yy = from.y - this.matrix[5];
    point.x = xx * this.invMatrix[0] + yy * this.invMatrix[2];
    point.y = xx * this.invMatrix[1] + yy * this.invMatrix[3];
    return point;
  }

  toScreen(from: Point, point: Point = { x: 0, y: 0 }): Point {
    if (this.dirty) {
      this.update();
    }
    point.x = from.x * this.matrix[0] + from.y * this.matrix[2] + this.matrix[4];
    point.y = from.x * this.matrix[1] + from.y * this.matrix[3] + this.matrix[5];
    return point;
  }

  scaleAt(point: Point, amount: number, distance = 30): void {
    if (this.dirty) {
      this.update();
    }

    const [scaleMin, scaleMax] = this.scaleRange;

    if (this.scale >= scaleMax && amount > 1) {
      return;
    }

    const scaleInv = 1.0 / this.scale;

    amount = clamp(amount, scaleMin * scaleInv, scaleMax * scaleInv);

    this.scale *= amount;
    // this.scale = clamp(this.scale, scaleMin, scaleMax);

    this.pos.x = point.x - (point.x - this.pos.x) * amount;
    this.pos.y = point.y - (point.y - this.pos.y) * amount;

    this.dirty = true;
  }

  move(x: number, y: number): void {
    this.pos.x += x;
    this.pos.y += y;
    this.dirty = true;
  }

  getOrigin(point?: Point) {
    if (point) {
      point.x = this.pos.x;
      point.y = this.pos.y;
      return point;
    }
    return this.pos;
  }

  setOrigin(x: number, y: number) {
    this.pos.x = x;
    this.pos.y = y;
    this.dirty = true;
  }

  setContext(ctx: CanvasRenderingContext2D): void {
    this.ctx = ctx;
    this.dirty = true;
  }

  setBounds(bounds: Bounds): void {
    this.bounds = { ...bounds };
    this.dirty = true;
  }

  get boundsWidth() {
    return this.bounds.right - this.bounds.left;
  }

  get boundsHeight() {
    return this.bounds.bottom - this.bounds.top;
  }

  getImageData(image: HTMLImageElement, scaleRatio = 0.5): CanvasImageData | undefined {
    const { width, height } = image;

    if (width <= 0 || height <= 0 || scaleRatio === 0) {
      return;
    }

    const { boundsWidth, boundsHeight } = this;

    if (boundsWidth <= 0 || boundsHeight <= 0) {
      return;
    }

    const centerX = this.bounds.left + boundsWidth / 2;

    const centerY = this.bounds.top + boundsHeight / 2;

    let targetWidth = boundsWidth * scaleRatio;

    let targetHeight = boundsHeight * scaleRatio;

    const scale = Math.min(targetWidth / width, targetHeight / height);

    targetWidth = scale * width;

    targetHeight = scale * height;

    const targetLeft = centerX - targetWidth / 2;

    const targetTop = centerY - targetHeight / 2;

    return {
      type: "image",
      image,
      source: {
        left: 0,
        top: 0,
        width: image.width,
        height: image.height,
      },
      target: {
        left: targetLeft,
        top: targetTop,
        width: targetWidth,
        height: targetHeight,
      },
    };
  }
}

export function useNumUndoRedoCanvasBrushStrokesHistory({
  history,
}: {
  history?: CanvasBrushStrokeHistory;
}) {
  const [numUndos, setNumUndos] = React.useState(0);
  const [numRedos, setNumRedos] = React.useState(0);

  React.useEffect(() => {
    if (!history) {
      return;
    }
    const updateNumUndoRedo = () => {
      setNumUndos(history.numUndos());
      setNumRedos(history.numRedos());
    };
    history.on("undo", updateNumUndoRedo);
    history.on("redo", updateNumUndoRedo);
    history.on("save", updateNumUndoRedo);
    return () => {
      history.off("undo", updateNumUndoRedo);
      history.off("redo", updateNumUndoRedo);
      history.off("save", updateNumUndoRedo);
    };
  }, [history]);

  return {
    numUndos,
    numRedos,
  };
}
