import { classNames } from "@/core/utils/classname-utils";
import * as Switch from "@radix-ui/react-switch";
import React from "react";
import styles from "./switch.module.css";

export type PanelSwitchRootProps = Switch.SwitchProps &
  React.RefAttributes<HTMLButtonElement> & {
    thumbProps?: Switch.SwitchThumbProps & React.RefAttributes<HTMLSpanElement>;
  };

export const PanelSwitchRoot = React.forwardRef(
  (
    { className = "", thumbProps = {}, ...props }: PanelSwitchRootProps,
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { className: thumbClassName = "" } = thumbProps;
    return (
      <Switch.Root
        ref={forwardedRef}
        {...props}
        className={classNames(styles.SwitchRoot, className)}
      >
        <Switch.Thumb {...thumbProps} className={classNames(styles.SwitchThumb, thumbClassName)} />
      </Switch.Root>
    );
  },
);

export type PanelSwitchRowProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  id: string;
  rootProps?: PanelSwitchRootProps;
  labelProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
};

export const PanelSwitchRow = React.forwardRef(
  (
    {
      id,
      className = "",
      rootProps = {},
      labelProps = {},
      children,
      ...props
    }: PanelSwitchRowProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { className: labelClassName = "", children: _labelChildren } = labelProps;

    const labelChildren = _labelChildren || children;

    return (
      <div
        ref={forwardedRef}
        {...props}
        className={classNames("flex items-center justify-start", className)}
      >
        <div {...labelProps} id={id} className={classNames("flex-1", labelClassName)}>
          {labelChildren}
        </div>
        <PanelSwitchRoot {...rootProps} id={id} />
      </div>
    );
  },
);
