import {
  DEFAULT_ZOOM_SENSITIVITY,
  defaultControllerOptions,
  MouseButton,
} from "@/core/common/constants";
import { SegmentEventHandler } from "@/core/common/event-handler/segment";
import { SyncEdtorState } from "@/core/common/event-handler/sync-editor-state";
import Events from "@/core/common/events";
import { ControllerOptions, IShortcutsManager } from "@/core/common/interfaces";
import { LayerType } from "@/core/common/layers";
import { SetActiveShortcutsManagerHandler } from "@/core/common/types";
import { EditorShortcutsManager } from "@/core/controllers/shortcuts/editor-shortcuts-manager";
import { isTouchEvent } from "@/core/utils/type-guards";
import { fabric } from "fabric";

class MobileEvents extends Events {
  protected _isMiddleMouseDown = false;

  protected _isTouchDown = false;

  // @ts-expect-error
  protected lastPanPos?: fabric.Point;
  protected _pinchStartDistance?: number;

  protected previousActiveObjectIds: string[] = [];

  protected didMove = false;

  protected startPosition?: fabric.Point;

  // @ts-expect-error
  protected syncEditorState: SyncEdtorState;

  // @ts-expect-error
  protected segmentEvents: SegmentEventHandler;

  // @ts-expect-error
  protected editorShortcutsManager: EditorShortcutsManager;

  protected activeShortcutsManagerRef: {
    current?: IShortcutsManager;
  } = { current: undefined };

  constructor(props: ControllerOptions) {
    super(props);
    this.syncEditorState = new SyncEdtorState(props);
    this.segmentEvents = new SegmentEventHandler(props);
    this.editorShortcutsManager = new EditorShortcutsManager(props);

    this.initialize();
  }

  protected setActiveShortcutsManager = (shortcutsManagerRef: { current?: IShortcutsManager }) => {
    this.activeShortcutsManagerRef.current = shortcutsManagerRef?.current;
  };

  protected initialize() {
    this.canvas.fireMiddleClick = true;
    this.canvas.wrapperEl.tabIndex = 1;
    this.canvas.wrapperEl.style.outline = "none";
    // @ts-ignore
    this.canvas.on({
      "mouse:dblclick": this.onDoubleClick,
      "mouse:down": this.onMouseDown,
      "mouse:move": this.onMouseMove,
      "mouse:up": this.onMouseUp,
      "selection:cleared": this.handleDeselection,
      "selection:updated": this.handleSelection,
      "mouse:wheel": this.onMouseWheel,
      "mouse:out": this.onMouseOut,
      "object:modified": this.objectModified,
      "background:selected": this.onBackgroundSelected,
    });

    this.canvas.wrapperEl.addEventListener("keydown", this.onKeyDown.bind(this), false);

    this.editor.on<SetActiveShortcutsManagerHandler>(
      "shortcuts-manager:set",
      this.setActiveShortcutsManager,
    );
  }

  public destroy() {
    this.canvas.off({
      "mouse:dblclick": this.onDoubleClick,
      "mouse:down": this.onMouseDown,
      "mouse:up": this.handleSelection,
      "selection:cleared": this.handleDeselection,
      "selection:updated": this.handleSelection,
      "mouse:wheel": this.onMouseWheel,
      "mouse:out": this.onMouseOut,
      "object:modified": this.objectModified,
      "background:selected": this.onBackgroundSelected,
    });

    window.removeEventListener("keydown", this.onKeyDown.bind(this));

    this.syncEditorState.destroy();
    this.segmentEvents.destroy();

    this.editor.off<SetActiveShortcutsManagerHandler>(
      "shortcuts-manager:set",
      this.setActiveShortcutsManager,
    );
  }

  get isMiddleMouseDown() {
    return this._isMiddleMouseDown;
  }

  get isTouchDown() {
    return this._isTouchDown;
  }

  protected onDoubleClick = (event: fabric.IEvent<any>) => {
    const subTarget = event.subTargets![0];
    if (subTarget) {
      this.editor.objects.select(subTarget.id);
    }
  };

  static getMousePosFromEvent(e: fabric.IEvent<any>) {
    return new fabric.Point(e.e?.clientX || 0, e.e?.clientY || 0);
  }

  static getTouchPosFromEvent(e: fabric.IEvent<any>) {
    const touchEvent = e.e;

    if (isTouchEvent(touchEvent)) {
      return new fabric.Point(touchEvent.touches[0].clientX, touchEvent.touches[0].clientY);
    }

    return new fabric.Point(touchEvent.clientX, touchEvent.clientY);
  }

  static detectTrackpadUtil(
    e: WheelEvent & {
      wheelDeltaY?: number;
      wheelDeltaX?: number;
    },
  ) {
    let isTrackpad = false;
    if (e.wheelDeltaY) {
      if (e.wheelDeltaY === e.deltaY * -3) {
        isTrackpad = true;
      }
    } else if (e.deltaMode === 0) {
      isTrackpad = true;
    }

    if (e.wheelDeltaX) {
      if (e.wheelDeltaX === e.deltaX * -3) {
        isTrackpad = true;
      }
    } else if (e.deltaMode === 0) {
      isTrackpad = true;
    }

    return isTrackpad;
  }

  protected detectTrackpad(
    e: WheelEvent & {
      wheelDeltaY?: number;
      wheelDeltaX?: number;
    },
  ) {
    return MobileEvents.detectTrackpadUtil(e);
  }

  protected getDistanceBetweenTouches(touches: TouchList): number {
    const touch1 = touches[0];
    const touch2 = touches[1];
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  }

  public onMouseDown = (e: fabric.IEvent<any>) => {
    this.editor.objects.pasteStyle();

    if (isTouchEvent(e.e)) {
      this.onTouchDown(e);
      this.state.setContextMenuRequest(null);
      return;
    }

    if (e.button === MouseButton.Right) {
      this.state.setContextMenuRequest({
        left: e.e.offsetX,
        top: e.e.offsetY,
        target: e.target,
      });
    } else if (e.button === MouseButton.Middle) {
      this._isMiddleMouseDown = true;
      this.state.setContextMenuRequest(null);
      this.lastPanPos = MobileEvents.getMousePosFromEvent(e);
    } else {
      this.state.setContextMenuRequest(null);
    }
  };

  public onTouchDown = (e: fabric.IEvent<any>) => {
    const activeObject = this.canvas.getActiveObject();

    this._pinchStartDistance = undefined;
    if (e.e.touches.length >= 1) {
      this.canvas.selection = false;
      this._isTouchDown = true;
      this.lastPanPos = new fabric.Point(e.e.touches[0].clientX, e.e.touches[0].clientY);
      this.startPosition = new fabric.Point(e.e.touches[0].clientX, e.e.touches[0].clientY);

      if (activeObject) {
        const objs = activeObject._objects;
        this.previousActiveObjectIds.push(activeObject.id);
        if (objs) {
          objs.forEach((obj) => {
            if (!this.previousActiveObjectIds.includes(obj.id) && obj.id !== "generation-frame") {
              this.previousActiveObjectIds.push(obj.id);
            }
          });
        }
      }
    }
  };

  public onMouseMove = (e: fabric.IEvent<any>) => {
    if (isTouchEvent(e.e)) {
      this.onTouchMove(e);
      return;
    }
    if (this._isMiddleMouseDown && this.lastPanPos) {
      const viewportTransform = this.canvas.viewportTransform?.slice(0);
      const clientX = e.e?.clientX;
      const clientY = e.e?.clientY;
      if (viewportTransform && clientX != null && clientY != null) {
        viewportTransform[4] += clientX - this.lastPanPos.x;
        viewportTransform[5] += clientY - this.lastPanPos.y;
        this.lastPanPos.x = clientX;
        this.lastPanPos.y = clientY;
        this.canvas.setViewportTransform(viewportTransform);
        this.canvas.requestRenderAll();
      }
    }
  };

  public onTouchMove = (e: fabric.IEvent<any>) => {
    e.e.preventDefault();
    e.e.stopPropagation();
    const activeObject = this.canvas.getActiveObject();

    if (
      e.e.touches.length === 1 &&
      this._isTouchDown &&
      this.lastPanPos &&
      !this._pinchStartDistance &&
      !activeObject
    ) {
      const target = this.canvas.findTarget(e.e, false);
      if (target) {
        return;
      }

      const currentX = e.e.touches[0].clientX;
      const currentY = e.e.touches[0].clientY;
      const deltaX = currentX - this.lastPanPos.x;
      const deltaY = currentY - this.lastPanPos.y;
      this.canvas.relativePan(new fabric.Point(deltaX, deltaY));
      this.lastPanPos.x = currentX;
      this.lastPanPos.y = currentY;

      const currentPos = new fabric.Point(currentX, currentY);
      if (this.startPosition) {
        const distance =
          Math.pow(currentPos.x - this.startPosition.x, 2) +
          Math.pow(currentPos.y - this.startPosition.y, 2);
        if (distance > 50) {
          this.didMove = true;
        }
      }
    } else if (e.e.touches.length === 2) {
      e.e.preventDefault();
      e.e.stopPropagation();

      this.previousActiveObjectIds.forEach((id) => {
        this.editor.objects.lock(id);
      });

      if (this._pinchStartDistance == null) {
        this._pinchStartDistance = this.getDistanceBetweenTouches(e.e.touches);
      }

      const currentDistance = this.getDistanceBetweenTouches(e.e.touches);

      const zoomBy = currentDistance / this._pinchStartDistance;

      const potentialNewZoom = this.canvas.getZoom() * zoomBy;
      // const clampedZoomRatio = getZoomRatio(potentialNewZoom);

      const pinchMoveThreshold = 10; // Define a threshold for considering it a pinch move
      const pinchDistanceDelta = Math.abs(currentDistance - this._pinchStartDistance);

      if (pinchDistanceDelta > pinchMoveThreshold) {
        this.didMove = true;
      }

      this.didMove = true;

      // if (clampedZoomRatio !== potentialNewZoom) {
      //     console.log(`clampedZoomRatio ${clampedZoomRatio} !== potentialNewZoom ${potentialNewZoom}`);
      //     return;
      // }

      // console.log(`zoomRatio ${potentialNewZoom}`);

      const center = this.canvas.getCenter();

      this.editor.zoom.zoomToPoint(new fabric.Point(center.left, center.top), potentialNewZoom);

      // this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), potentialNewZoom);

      this._pinchStartDistance = currentDistance;
    }
  };

  public onMouseUp = (e: fabric.IEvent) => {
    if (isTouchEvent(e.e)) {
      this.onTouchUp(e);
      return;
    }

    this.handleSelection(e);
    if (e.button === MouseButton.Middle) {
      this._isMiddleMouseDown = false;
      this.lastPanPos = undefined;
    }
  };

  public onTouchUp = (e: fabric.IEvent<any>) => {
    if (e.e.touches.length <= 1) {
      this._isTouchDown = false;
      this.lastPanPos = undefined;
      this._pinchStartDistance = undefined;

      const activeObject = this.canvas.getActiveObject();

      this.canvas.discardActiveObject();
      if (activeObject) {
        this.canvas.setActiveObject(activeObject);
        this.state.setActiveObject(activeObject);
      }

      if (this.previousActiveObjectIds.length > 0 && this.didMove) {
        const selectionObj = createActiveObjectFromIds(this.previousActiveObjectIds, this.canvas);
        this.canvas.setActiveObject(selectionObj);
        this.state.setActiveObject(selectionObj);
        this.previousActiveObjectIds.forEach((id) => {
          this.editor.objects.unlock(id);
        });
      }

      this.didMove = false;

      this.previousActiveObjectIds = [];
    }
    if (e.e.touches.length === 0) {
      this.canvas.selection = true;
    }
  };

  objectModified = (event: fabric.IEvent) => {
    const { target } = event;
    if (target instanceof fabric.Textbox) {
      this.scaleTextbox(target);
    }
    this.editor.history.save();
  };

  onMouseOut = () => {
    this.canvas.renderAll();
  };

  onMouseWheel = (event: fabric.IEvent<any>) => {
    const isCtrlKey = event.e.ctrlKey;
    if (isCtrlKey) {
      this.handleZoom(event);
    } else {
      // const isTrackpad = this.detectTrackpad(event.e);
      // if (isTrackpad) {
      // }
      const viewportTransform = this.canvas.viewportTransform;
      const deltaX = event.e?.deltaX;
      const deltaY = event.e?.deltaY;
      if (viewportTransform && deltaX != null && deltaY != null) {
        viewportTransform[4] -= deltaX;
        viewportTransform[5] -= deltaY;
        this.canvas.setViewportTransform(viewportTransform);
        this.canvas.requestRenderAll();
      }
    }
  };

  handleZoom = (event: fabric.IEvent<any>) => {
    const delta = event.e.deltaY;
    let zoomRatio = this.canvas.getZoom();
    if (delta > 0) {
      zoomRatio -= DEFAULT_ZOOM_SENSITIVITY;
    } else {
      zoomRatio += DEFAULT_ZOOM_SENSITIVITY;
    }
    this.editor.zoom.zoomToPoint(
      new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2),
      zoomRatio,
    );
    event.e.preventDefault();
    event.e.stopPropagation();
  };

  onKeyDown(event: KeyboardEvent) {
    let isHandled = false;
    // By default, use the active shortcuts manager
    if (this.activeShortcutsManagerRef.current) {
      const handleKeyDownResult = this.activeShortcutsManagerRef.current.handleKeyDown(event);
      isHandled = handleKeyDownResult.isHandled;
    }
    if (isHandled) {
      return;
    }
    // Fallback to the default editor shortcuts manager
    return this.editorShortcutsManager.handleKeyDown(event);
  }

  onBackgroundSelected = () => {
    const objects = this.canvas.getObjects();
    const frame = objects[0];
    this.canvas.setActiveObject(objects[0]);
    this.state.setActiveObject(frame);
    this.canvas.requestRenderAll();
  };
  handleDeselection = (target: fabric.IEvent) => {
    const deselected = (target as any).deselected as fabric.Object[];
    if (Array.isArray(deselected)) {
      const decelectObjs = deselected
        .filter((obj) => obj.id !== "generation-frame")
        .map((obj) => obj.id);
      if (decelectObjs.length > 0) {
        this.previousActiveObjectIds = decelectObjs;
      }
    }
    return this.handleSelection(target);
  };

  handleSelection = (target: fabric.IEvent) => {
    if (target) {
      this.state.setActiveObject(null);
      const initialSelection = this.canvas.getActiveObject() as any;
      const isNotMultipleSelection =
        (initialSelection && initialSelection.type === LayerType.GROUP.toLowerCase()) ||
        (initialSelection && initialSelection.type === LayerType.STATIC_VECTOR);

      if (initialSelection && !isNotMultipleSelection && initialSelection._objects) {
        const filteredObjects = (initialSelection._objects as fabric.Object[]).filter((object) => {
          if (object.type === LayerType.BACKGROUND) {
            return false;
          }
          return !object.locked;
        });
        this.canvas.discardActiveObject();
        if (filteredObjects.length > 0) {
          if (filteredObjects.length === 1) {
            this.canvas.setActiveObject(filteredObjects[0]);
            this.state.setActiveObject(filteredObjects[0]);
          } else {
            const activeSelection = new fabric.ActiveSelection(filteredObjects, {
              ...defaultControllerOptions,
              canvas: this.canvas,
            }) as fabric.Object;
            this.canvas.setActiveObject(activeSelection);
            this.state.setActiveObject(activeSelection);
          }
        }
      } else {
        this.state.setActiveObject(initialSelection);
      }
    } else {
      this.state.setActiveObject(null);
    }
    this.canvas.requestRenderAll();
  };

  scaleTextbox = (target: fabric.Textbox) => {
    const { fontSize, width, scaleX } = target;
    target.set({
      fontSize: fontSize! * scaleX!,
      width: width! * scaleX!,
      scaleX: 1,
      scaleY: 1,
    });
  };
}

function createActiveObjectFromIds(ids: string[], canvas: fabric.Canvas) {
  const allObjects = canvas.getObjects();
  const objectsById = allObjects.filter((object) => ids.includes(object.id));
  const filteredObjects = objectsById.filter((object) => {
    if (object.type === LayerType.BACKGROUND) {
      return false;
    }
    return true;
  });

  if (filteredObjects.length === 1) {
    return filteredObjects[0];
  }

  const activeSelection = new fabric.ActiveSelection(filteredObjects, {
    ...defaultControllerOptions,
    canvas,
  }) as fabric.Object;
  return activeSelection;
}

export default MobileEvents;
