// TODO: Remove the typesense dependencies

import { debounce } from "lodash";

import {
  GenerateTemplateTypesenseDoc,
  GetGenerateTemplatesTypesenseProps,
  isGenerateTemplateTypesenseDoc,
} from "@/core/common/types/template-generator";

type SearchTemplatesProps = {
  type: "get-generate-templates";
  perPage?: number;
  page?: number;
  searchString?: string;
  tags?: string[];
  includeFields?: string[];
  excludeFields?: string[];
};

export const searchTemplatesDebounced = debounce(
  async function searchTemplates(
    props: GetGenerateTemplatesTypesenseProps,
    callback: (results: GenerateTemplateTypesenseDoc[]) => void,
  ) {
    try {
      const searchEndpointUrl = import.meta.env.VITE_SEARCH_TEMPLATES_API_URL;

      if (!searchEndpointUrl) {
        return callback([]);
      }

      const searchProps: SearchTemplatesProps = {
        ...props,
        type: "get-generate-templates",
      };

      const response = await fetch(searchEndpointUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(searchProps),
      });

      if (!response.ok) {
        console.log(await response.text());
        return callback([]);
      }

      const docs = await response.json();

      callback(docs.filter(isGenerateTemplateTypesenseDoc));
    } catch (error) {
      console.error(error);

      callback([]);
    }
  },
  100,
  { leading: true, trailing: true },
);

export const searchTemplates = (props: GetGenerateTemplatesTypesenseProps) => {
  return new Promise<GenerateTemplateTypesenseDoc[]>((resolve) => {
    searchTemplatesDebounced(props, resolve);
  });
};
