import { PromptAutocompleteType } from "components/text-editor/prompt-autocomplete";
import { getUniqueArray } from "@/core/utils/array-utils";

export const autocompleteSubjects = [
  "bottle",
  "perfume bottle",
  "wine bottle",
  "can",
  "packet",
  "box",
  "cylindrical box",
  "bag",
  "shoe",
  "device",
  "watch",
  "bag",
  "handbag",
];

type AutocompletePlacementPrefix =
  | "on"
  | "standing on"
  | "lying on"
  | "balancing on"
  | "buried in"
  | "emerging from"
  | "in";

export const autocompletePlacementPrefixes: AutocompletePlacementPrefix[] = [
  "on",
  "standing on",
  "lying on",
  "balancing on",
  "buried in",
  "emerging from",
  "in",
];

const autocompletePlacementTemplate: Record<AutocompletePlacementPrefix, string[]> = {
  on: [
    "circular platform",
    "circular marble platform",
    "circular beige platform",
    "circular reflective platform",
    "circular glass platform",
    "smooth circular platform",
    "white plastic platform",
    "marble surface",
    "reflective surface",
    "concrete surface",
    "white surface",
    "wooden surface",
    "wet surface",
    "wooden cutting board",
    "plate",
    "placemat",
    "kitchen counter",
    "bathroom counter",
    "a tree stump",
    "a dried wood",
    "a marble block",
    "flat stones",
    "rocks",
    "white rock",
    "mossy rock",
    "a mossy ledge",
    "mound of moss",
    "flower patch",
    "ice",
    "a natural hill",
    "grass",
    "a mound of grass",
    "a grassy hill",
    "glassy island on water",
    "glacier",
  ],
  "standing on": [
    "circular marble platform",
    "circular beige platform",
    "circular glass platform",
    "circular reflective platform",
    "white plastic platform",
    "smooth circular platform",
    "marble surface",
    "reflective surface",
    "concrete surface",
    "white surface",
    "wooden surface",
    "wet surface",
    "wooden cutting board",
    "plate",
    "placemat",
    "bathroom counter",
    "kitchen counter",
    "a tree stump",
    "a dried wood",
    "a marble block",
    "flat stones",
    "rocks",
    "white rock",
    "mossy rock",
    "a mossy ledge",
    "mound of moss",
    "flower patch",
    "ice",
    "a natural hill",
    "grass",
    "a mound of grass",
    "a grassy hill",
    "glassy island on water",
    "glacier",
  ],
  "lying on": [
    "marble surface",
    "reflective surface",
    "white surface",
    "wooden surface",
    "wet surface",
    "wooden table",
    "wooden cutting board",
    "plate",
    "placemat",
    "a marble block",
  ],
  "balancing on": [
    "circular platform",
    "circular beige platform",
    "circular glass platform",
    "circular marble platform",
    "smooth circular platform",
    "marble surface",
    "a tree stump",
    "dried wood",
    "a marble block",
    "flat stones",
    "rocks",
    "white rock",
    "mossy rock",
  ],
  "buried in": ["snowy", "sand", "desert sand", "soil", "glitter", "leaves", "autumn leaves"],
  "emerging from": ["rippling water", "splashing water", "water", "vortex", "ocean", "bubbles"],
  in: ["white sand", "golden sand", "the snow", "the desert sand", "the soil", "glitter"],
};

export const autocompleteSurroundingsNounsCommon = getUniqueArray([
  "wavy fabrics",
  "dried bark",
  "fabric blobs",
  "glass blobs",
  "blossoms",
  "cherry blossoms",
  "leaves",
  "autumn leaves",
  "fall leaves",
  "bubbles",
  "daisies",
  "violets",
  "petals",
  "white flowers",
  "pebbles",
  "crystals",
  "pearls",
  "coral",
  "sliced lemon",
  "sliced lime",
  "almonds",
  "cashews",
  "nuts",
  "crushed chocolate",
  "caramel cubes",
  "potted plant",
  "house plant",
  "golden bokeh",
  "golden sparkles",
]);

type AutocompleteSurroundingsPrefix = "with" | "next to" | "surrounded by";

export const autocompleteSurroundingsPrefixes: AutocompleteSurroundingsPrefix[] = [
  "with",
  "next to",
  "surrounded by",
];

export const autocompleteSurroundingsTemplate: Record<AutocompleteSurroundingsPrefix, string[]> = {
  "next to": getUniqueArray([
    "flowers",
    "houseplant",
    "orange",
    "sliced lemon",
    "sliced lime",
    "almonds",
    "cashews",
    "nuts",
    "caramel cubes",
    "potted plant",
    "house plant",
    "towels",
    "vase",
    "bowl",
    "cup",
    "mug",
    "pile of books",
    "lit candle",
    "fairy lights",
    "ornaments",
    "mistletoe",
    "pine needles",
    "pinecones",
    ...autocompleteSurroundingsNounsCommon,
  ]),
  "surrounded by": getUniqueArray([
    "trees",
    "leaves",
    "flowers",
    "autumn leaves",
    "cherry blossoms",
    "fall leaves",
    "bubbles",
    "confetti",
    ...autocompleteSurroundingsNounsCommon,
  ]),
  with: getUniqueArray(["golden bokeh", "golden sparkles", ...autocompleteSurroundingsNounsCommon]),
};

type AutocompleteBackgroundPrefix = "in front of" | "against" | "with";

export const autocompleteBackgroundPrefixes: AutocompleteBackgroundPrefix[] = [
  "in front of",
  "against",
  "with",
];

const autocompleteBackgroundNounsCommon = [
  "soft shadows",
  "the ocean and blue skies with clouds",
  "sunlight streaming down",
  "gradient background",
  "sunset in the background",
  "the amalfi coast in the background",
  "desert in the background",
  "mountains in the background.",
  "snowy mountains and sunny skies in the background.",
  "skyscrapers in the background",
  "marsh with sunlight streaming down in the background",
  "flowers",
  "forest in the background",
  "forest with sunlight streaming down in the background",
  "jungle in the background",
  "nature in the background",
  "garden in the background",
  "soft shadows in the background",
  "lighthouse by the sea in the background",
  "pink sunset and ocean in the background",
  "a vineyard in the background.",
  "orange sunset in the background",
  "fairy lights in the background",
  "luxury cafe in the background",
  "bokeh in the background",
  "a white circular window",
];

export const autocompleteBackgroundTemplate: Record<AutocompleteBackgroundPrefix, string[]> = {
  "in front of": autocompleteBackgroundNounsCommon,
  against: autocompleteBackgroundNounsCommon,
  with: autocompleteBackgroundNounsCommon,
};

export const promptAutocompleteTypeToValues: Record<
  PromptAutocompleteType,
  string[] | Record<string, string[]>
> = {
  subject: autocompleteSubjects,
  placement: autocompletePlacementTemplate,
  surrounding: autocompleteSurroundingsTemplate,
  background: autocompleteBackgroundTemplate,
  template: [],
  custom: [],
};

function getAutocompleteStringArray(object: Record<string, string[]>) {
  const array: string[] = [];
  Object.entries(object).forEach(([prefix, items]) => {
    items.forEach((item) => {
      array.push(`${prefix} ${item}`);
    });
  });
  return array;
}

export function getPromptAutocompleteValues(type: PromptAutocompleteType, prefix?: string) {
  if (!type) {
    return [];
  }
  const values = promptAutocompleteTypeToValues[type];
  if (!values) {
    return [];
  }
  if (Array.isArray(values)) {
    return values;
  }
  if (!prefix || !Object.prototype.hasOwnProperty.call(values, prefix)) {
    return getAutocompleteStringArray(values);
  }
  return values[prefix];
}

export const promptAutocompleteSurroundings = getAutocompleteStringArray(
  autocompleteSurroundingsTemplate,
).map((value) => ({ value }));

export const promptAutocompleteBackgrounds = getAutocompleteStringArray(
  autocompleteBackgroundTemplate,
).map((value) => ({ value }));

export function doesAutocompleteTypeHavePrefix(type: PromptAutocompleteType) {
  return type === "background" || type === "placement" || type === "surrounding";
}
