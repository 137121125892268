import React from "react";
import { createPortal } from "react-dom";
import { classNames } from "@/core/utils/classname-utils";
import { Tooltip } from "components/utils/tooltip";
import { editorContextStore } from "contexts/editor-context";
import { Minus, Plus, Redo, Undo } from "lucide-react";
import { clamp, toSafeInteger } from "lodash";
import { StateUpdater } from "@/core/common/types";
import { TryOnClothEditorPaintBrushZIndex } from "components/constants/zIndex";

export type TryOnTaskBarButtonProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  contentChildren: React.ReactNode;
  contentClassName?: string;
  isActive?: boolean;
  isDisabled?: boolean;
};

export function TryOnTaskBarButton({
  children,
  className = "",
  contentChildren,
  contentClassName = "",
  isActive = false,
  isDisabled = false,
  ...props
}: TryOnTaskBarButtonProps) {
  return (
    <Tooltip
      triggerProps={{
        asChild: true,
      }}
      triggerChildren={
        <button
          {...props}
          className={classNames(
            "min-h-[2rem] px-2 py-1 rounded transition-colors pointer-events-auto cursor-pointer",
            isDisabled
              ? "bg-zinc-800 text-zinc-600"
              : isActive
                ? "bg-zinc-700 hover:bg-zinc-500/50 active:bg-zinc-900/80 shadow-md"
                : "hover:bg-zinc-700 active:bg-zinc-900/80",
            className,
          )}
        >
          {children}
        </button>
      }
      contentChildren={contentChildren}
      contentClassName={contentClassName}
    />
  );
}

export function TryOnToolBarUndoButton(props: Partial<TryOnTaskBarButtonProps>) {
  return (
    <TryOnTaskBarButton
      {...props}
      className="flex items-center justify-center"
      contentClassName="text-zinc-300"
      contentChildren="Undo"
    >
      <Undo size={18} />
    </TryOnTaskBarButton>
  );
}

export function TryOnToolBarRedoButton(props: Partial<TryOnTaskBarButtonProps>) {
  return (
    <TryOnTaskBarButton {...props} contentClassName="text-zinc-300" contentChildren="Redo">
      <Redo size={18} />
    </TryOnTaskBarButton>
  );
}

export const TryOnPaintBrushCursor = React.forwardRef(
  (
    {
      id,
      className = "",
      style = {},
      ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    brushCursorRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return createPortal(
      <div
        ref={brushCursorRef}
        className={classNames(
          className,
          "absolute pointer-events-none border-2 rounded-full transition-opacity",
        )}
        style={{
          ...style,
          zIndex: TryOnClothEditorPaintBrushZIndex,
        }}
        {...props}
      />,
      document.body,
    );
  },
);

const MIN_BRUSH_SIZE = 5;
const MAX_BRUSH_SIZE = 100;
const DELTA_BRUSH_SIZE = 5;

export function TryOnBrushSizeAdjust({
  className = "",
  brushSize: inputBrushSize,
  setBrushSize: setInputBrushSize,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  brushSize: number;
  setBrushSize: (value: StateUpdater<number>) => void;
}) {
  const [brushSize, setBrushSize] = React.useState(inputBrushSize);
  return (
    <div className={classNames("flex flex-row items-center justify-center", className)}>
      <TryOnTaskBarButton
        className="px-1"
        contentChildren="Decrease brush size"
        isDisabled={brushSize <= MIN_BRUSH_SIZE}
        onClick={() => {
          setInputBrushSize((prevBrushSize) => {
            const newBrushSize = clamp(
              prevBrushSize - DELTA_BRUSH_SIZE,
              MIN_BRUSH_SIZE,
              MAX_BRUSH_SIZE,
            );
            setBrushSize(newBrushSize);
            return newBrushSize;
          });
        }}
      >
        <Minus size={16} />
      </TryOnTaskBarButton>
      <div className="w-1" />
      <input
        className={classNames(
          "w-12 px-1 py-1 text-center text-zinc-200 bg-zinc-500/10 rounded-md border border-solid border-zinc-500/20 hover:border-zinc-500/50 focus-visible:outline-none focus:border-lime-500 focus-visible:border-lime-500 transition-colors pointer-events-auto",
        )}
        type="number"
        value={brushSize}
        onChange={(e) => {
          setBrushSize(toSafeInteger(e.currentTarget.value));
        }}
        onBlur={() => {
          const value = clamp(brushSize, MIN_BRUSH_SIZE, MAX_BRUSH_SIZE);
          setBrushSize(value);
          setInputBrushSize(value);
        }}
      />
      <div className="w-1" />
      <TryOnTaskBarButton
        className="px-1"
        contentChildren="Increase brush size"
        isDisabled={brushSize >= MAX_BRUSH_SIZE}
        onClick={() => {
          setInputBrushSize((prevBrushSize) => {
            const newBrushSize = clamp(
              prevBrushSize + DELTA_BRUSH_SIZE,
              MIN_BRUSH_SIZE,
              MAX_BRUSH_SIZE,
            );
            setBrushSize(newBrushSize);
            return newBrushSize;
          });
        }}
      >
        <Plus size={16} />
      </TryOnTaskBarButton>
    </div>
  );
}

export function TryOnToolbarDividerVertical({
  className = "",
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return <div {...props} className={classNames("w-px h-8 mx-1 bg-zinc-700", className)} />;
}
