import { editorContextStore } from "contexts/editor-context";

export function getNumLetters(str: string) {
  return str?.replace(/\s+/g, "").length || 0;
}

export function getNumWords(str: string) {
  return str.trim().split(/\s+/).length;
}

let canvas: HTMLCanvasElement | undefined = undefined;

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text: string, font?: string) {
  if (font) {
    // re-use canvas object for better performance
    canvas = canvas || document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }
  }
  return getNumLetters(text) * 10;
}

export function getWordAtIndex(s: string, pos: number) {
  const n = s.substring(pos).match(/^[a-zA-Z0-9-_]+/);
  const p = s.substring(0, pos).match(/[a-zA-Z0-9-_]+$/);
  // if you really only want the word if you click at start or between
  // but not at end instead use if (!n) return
  if (!p && !n) {
    return "";
  }
  // @ts-ignore
  return (p || "") + (n || "");
}

export function removeWordAtIndex(s: string, pos: number) {
  let sRight = s.substring(pos);
  let sLeft = s.substring(0, pos);

  sLeft = sLeft.replace(/[a-zA-Z0-9-_]+$/, "");
  sRight = sRight.replace(/^[a-zA-Z0-9-_]+/, "");

  // @ts-ignore
  return (sLeft || "") + (sRight || "");
}

const dataUrlRegex =
  /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

export function isDataURL(s: string) {
  return !!s.match(dataUrlRegex);
}

export function isValidHttpUrl(string: string) {
  if (editorContextStore.getState().backend?.isStoragePathURL(string)) {
    return false;
  }

  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function isValidHttpsUrl(string: string) {
  if (editorContextStore.getState().backend?.isStoragePathURL(string)) {
    return false;
  }

  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "https:";
}

export function isValidFirebaseStoragePath(path: string) {
  if (editorContextStore.getState().backend?.isStoragePathURL(path)) {
    return true;
  }

  // Check if path contains invalid characters
  const invalidChars = ["#", "[", "]", "*", "?"];
  for (const char of invalidChars) {
    if (path.includes(char)) {
      return false;
    }
  }

  // Check if path starts with '/'
  if (path.startsWith("/")) {
    return false;
  }

  // Check if path uses consecutive slashes '//'
  if (path.includes("//")) {
    return false;
  }

  // Additional regex check for odd characters not allowed
  const regex = /[^a-zA-Z0-9!-_./]/;
  if (regex.test(path)) {
    return false;
  }

  return true;
}

export function isValidEmail(email: string) {
  return Boolean(
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ),
  );
}

function trimAny(str: string, chars: string[]) {
  let start = 0,
    end = str.length;

  while (start < end && chars.indexOf(str[start]) >= 0) {
    ++start;
  }

  while (end > start && chars.indexOf(str[end - 1]) >= 0) {
    --end;
  }

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}

export function cleanupText(text: string) {
  return trimAny(text, [" ", ".", ",", ":"])
    .replace(/[^a-z0-9\u0020\u0020-\u002F\u003A-\u0040]/gi, " ")
    .replace(/\s\s+/g, " ");
}

export function removeLineBreaks(text: string) {
  return text.replace(/(\r\n|\n|\r)/gm, "");
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uncapitalizeFirstLetter(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function concatTexts(texts: string[]) {
  texts = Array.from(new Set(texts.map((text) => text.trim())));
  if (texts.length <= 0) {
    return "";
  }
  if (texts.length === 1) {
    return texts[0];
  }
  return texts.join(" and ");
}

export function splitTexts(concatenatedText: string): string[] {
  // Check if the input is empty or contains only whitespace
  if (!concatenatedText.trim()) {
    return [];
  }

  // Split the input string by ' and ' to get the original texts
  const originalTexts = concatenatedText.split(" and ");

  // Assuming the original function removed leading and trailing spaces, we trim each text
  return originalTexts.map((text) => text.trim());
}

export function concatUrls(...urls: string[]) {
  return urls.filter(Boolean).join("/");
}

export function replaceMiddleWithAsterisks(input: string, totalCharsToReplace: number = 5): string {
  // Ensure totalCharsToReplace does not exceed the input length
  totalCharsToReplace = Math.min(totalCharsToReplace, input.length);

  // Handle the case where there's nothing to replace
  if (totalCharsToReplace <= 0) {
    return input;
  }

  // Calculate the start and end index for the replacement
  const startIndex = Math.floor((input.length - totalCharsToReplace) / 2);
  const endIndex = startIndex + totalCharsToReplace;

  // Replace the middle characters with '*'
  const replaced = input.split("");
  for (let i = startIndex; i < endIndex; i++) {
    replaced[i] = "*";
  }

  return replaced.join("");
}

export function maskApiKeyWithAsterisks(text: string, apiKey: string): string {
  // Escape special characters in the API key for use in a regular expression
  const escapedApiKey = apiKey.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Create a regular expression to find the API key
  const regex = new RegExp(escapedApiKey, "g");

  // Replace the API key with the first 3 characters followed by asterisks
  const maskedApiKey = apiKey.slice(0, 3) + apiKey.slice(3).replace(/./g, "*");
  const maskedText = text.replace(regex, maskedApiKey);

  return maskedText;
}

const tester =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export function validateEmail(email: string) {
  if (!email) {
    return false;
  }

  const emailParts = email.split("@");

  if (emailParts.length !== 2) {
    return false;
  }

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) {
    return false;
  } else if (address.length > 255) {
    return false;
  }

  const domainParts = address.split(".");

  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  ) {
    return false;
  }

  return tester.test(email);
}
