import { CustomModelPostProcessActionType } from "@/backend/custom-model-post-process";
import {
  AppUserSubscriptionTier,
  CustomModelPredictionInputBackendType,
  CustomModelTrainingStatus,
  getDisplayNameFromId,
  getModelTrainingMentionName,
  isCustomModelTrainingStatusActive,
  UiDisplayMessageEventHandler,
} from "@/core/common/types";
import { ApiInputType } from "@/core/common/types/api";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { sortByTimeModified } from "@/core/utils/time-utils";
import { getObjectEntries } from "@/core/utils/type-utils";
import { preprocessImageUrl } from "@/core/utils/url-utils";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import {
  CommonButtonClassName,
  DropdownClassName,
  OutputItemToolbarButtonClassName,
  PreviewImageContainerClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { FloatTagZIndex } from "components/constants/zIndex";
import { ApiInput } from "components/dashboard/api/api-input";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { downloadUrl } from "components/utils/data";
import { ImageComponent } from "components/utils/image";
import { Tooltip } from "components/utils/tooltip";
import { openAnimateVideoWindow } from "components/video/open-animate-image-window";
import { editorContextStore } from "contexts/editor-context";
import { Download, Settings, SquarePen, TriangleAlert } from "lucide-react";
import React from "react";
import { OptionsInput } from "../dashboard/api/options-input";
import { ToggleGroupInput } from "../dashboard/api/toggle-group";
import { displayUiMessage } from "../utils/display-message";
import { ImageToVideoIcon } from "../video/image-to-video-icon";
import {
  CustomModelImageEditorDialog,
  CustomModelImageEditorDialogTrigger,
} from "./custom-model-image-editor";
import {
  CustomModelImageEditorMode,
  useCustomModelImageEditorContext,
} from "./custom-model-image-editor-context";
import {
  customModelPlaygroundGenerateImageConfig,
  CustomModelPlaygroundGenerationMode,
  customModelPlaygroundGenerationModeNames,
  CustomModelPlaygroundStatus,
  useCustomModelPlayground,
} from "./custom-model-playground-context";
import { CustomModelPredictions } from "./custom-model-predictions";
import { CustomModelPromptEditor } from "./custom-model-prompt-editor";
import styles from "./custom-model.module.css";

function SubmitButton() {
  const { status, setStatus, apiState, generationMode, setOutputImages, setPredictionId } =
    useCustomModelPlayground();

  const isIdle = status === CustomModelPlaygroundStatus.Idle;

  const customModelTrainings = editorContextStore((state) => state.customModelTrainings);

  const successfulTraining = React.useMemo(() => {
    return Object.values(customModelTrainings).find(
      (training) => training.status === CustomModelTrainingStatus.Succeeded,
    );
  }, [customModelTrainings]);

  const hasSuccessfulTraining = React.useMemo(() => {
    return successfulTraining != null;
  }, [successfulTraining]);

  const handleGenerate = async () => {
    if (status !== CustomModelPlaygroundStatus.Idle) {
      return;
    }

    const { backend, eventEmitter } = editorContextStore.getState();

    if (!backend) {
      return;
    }

    if (!hasSuccessfulTraining) {
      eventEmitter.emit<UiDisplayMessageEventHandler>(
        "ui:display-message",
        "info",
        "Please train a custom model first before generating images. ",
      );
      return;
    }

    const { width, height, guidanceScale, numInferenceSteps, promptEditorState, numImages } =
      apiState;

    const { text, json, scaleConfigs } = promptEditorState;

    if (text.length <= 0) {
      return;
    }

    setStatus(CustomModelPlaygroundStatus.Rendering);

    setOutputImages([]);

    try {
      const processedScaleConfigs = {
        ...scaleConfigs,
      };

      if (
        successfulTraining?.input?.modelId &&
        scaleConfigs[successfulTraining?.input?.modelId] == null
      ) {
        processedScaleConfigs[successfulTraining?.input?.modelId] = {
          modelId: successfulTraining.input.modelId,
          trainingId: successfulTraining.id,
          scale: 0.1, // tone down the lora since we don't explicitly use it
        };
      }

      if (generationMode === CustomModelPlaygroundGenerationMode.Multstep) {
        const response = await backend.customModelMultistepGeneration({
          type: CustomModelPostProcessActionType.MultiStageGeneration,
          prompt: text,
          promptJson: json ?? undefined,
          customModelScaleConfigs: processedScaleConfigs,
          imageSize: {
            width,
            height,
          },
          numImages,
          maskDilationSize: 35,
        });

        if (!response.ok) {
          displayUiMessage(response.message ?? "Unknown error", "error");
          return;
        }

        const predictionId = response.predictionId;
        if (!predictionId) {
          return;
        }

        setPredictionId(response.predictionId ?? "");
        setStatus(CustomModelPlaygroundStatus.Idle);
      } else {
        const response = await backend.startCustomModelPrediction({
          input: {
            backendType: CustomModelPredictionInputBackendType.Fal,
            scaleConfigs: processedScaleConfigs,
            prompt: text,
            promptJson: json ?? undefined,
            image_size: {
              width,
              height,
            },
            num_images: numImages,
            num_inference_steps: numInferenceSteps,
            guidance_scale: guidanceScale,
          },
        });

        if (response.ok) {
          setPredictionId(response.predictionId);
        } else {
          if (!response.message?.includes("quota left")) {
            eventEmitter.emit<UiDisplayMessageEventHandler>(
              "ui:display-message",
              "error",
              response.message,
            );
          }

          setStatus(CustomModelPlaygroundStatus.Idle);
        }
      }
    } catch (error) {
      debugError("Error start custom model generation: ", error);
    } finally {
      setStatus(CustomModelPlaygroundStatus.Idle);
    }
  };

  return (
    <button
      className={classNames(
        isIdle
          ? hasSuccessfulTraining
            ? PrimaryButtonClassName
            : PrimaryButtonClassNameDisabled
          : PrimaryButtonClassNameLoading,
        CommonButtonClassName,
      )}
      onClick={() => {
        handleGenerate();
      }}
    >
      {!isIdle && <SimpleSpinner width={16} height={16} pathClassName="fill-lime-500" />}
      <span>Generate</span>
    </button>
  );
}

function AspectRatioSelector() {
  const { apiState, setApiState } = useCustomModelPlayground();

  const userQuotas = editorContextStore((state) => state.userQuotas);
  const canChangeAspectRatio =
    userQuotas?.tier === AppUserSubscriptionTier.Pro ||
    userQuotas?.tier === AppUserSubscriptionTier.Enterprise;

  return (
    <div>
      <OptionsInput
        type={ApiInputType.Options}
        id="aspect-ratio"
        name="Aspect Ratio"
        inputLabelClassName={classNames("font-semibold")}
        triggerClassName="h-[40px] rounded-md"
        value={`${apiState.width}x${apiState.height}`}
        onValueChange={(value) => {
          if (!canChangeAspectRatio) {
            return;
          }
          const [width, height] = value.split("x").map(Number);
          setApiState((state) => ({
            ...state,
            width,
            height,
          }));
        }}
        disabled={!canChangeAspectRatio}
        options={{
          "Aspect Ratio": canChangeAspectRatio
            ? [
                { name: "1:1", value: "1024x1024" },
                { name: "2:3", value: "832x1216" },
                { name: "3:2", value: "1216x832" },
                { name: "3:4", value: "896x1152" },
                { name: "4:3", value: "1152x896" },
                { name: "4:5", value: "896x1088" },
                { name: "5:4", value: "1088x896" },
                { name: "9:16", value: "768x1344" },
                { name: "9:21", value: "640x1536" },
                { name: "16:9", value: "1344x768" },
                { name: "21:9", value: "1536x640" },
              ]
            : [{ name: "1:1 - Upgrade plan to modify", value: "1024x1024" }],
        }}
      />
    </div>
  );
}

function NumberOfImagesSelector() {
  const { apiState, setApiState } = useCustomModelPlayground();

  return (
    <div className="flex flex-row border border-zinc-800 rounded-md">
      <ToggleGroupInput
        type="single"
        value={apiState.numImages || 2}
        onValueChange={(value) => {
          setApiState((state) => ({
            ...state,
            numImages: value,
          }));
        }}
        rootClassName="p-1 gap-1"
        itemClassName="h-[32px] flex items-center justify-center rounded border border-zinc-800 data-[state=on]:bg-lime-900/20 data-[state=on]:text-lime-400 font-semibold min-w-16 transition-colors"
        options={[
          { value: 1, name: "1" },
          { value: 2, name: "2" },
          { value: 4, name: "4" },
        ]}
      />
    </div>
  );
}

function CustomModelGenerationModeDropdown() {
  const { generationMode, setGenerationMode } = useCustomModelPlayground();
  return (
    <ApiInput
      type={ApiInputType.Options}
      id="custom-model-generation-mode"
      value={generationMode}
      name="Image generation mode"
      description="Select image generation backend type."
      onValueChange={(value) => setGenerationMode(value as CustomModelPlaygroundGenerationMode)}
      options={{
        Modes: [
          ...Object.values(CustomModelPlaygroundGenerationMode).map((mode) => ({
            name: customModelPlaygroundGenerationModeNames[mode],
            value: mode,
          })),
        ],
      }}
    />
  );
}

function ConfigureButton() {
  const { apiState, setApiState } = useCustomModelPlayground();

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className={classNames(SecondaryButtonClassNameInactive, CommonButtonClassName)}>
          <Settings size="18" />
          <span>Advanced Settings</span>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={styles.DialogOverlay}
          style={{
            zIndex: FloatTagZIndex,
          }}
        />
        <Dialog.Content
          className={classNames(
            styles.DialogContent,
            DropdownClassName,
            "py-2 w-[90vw] md:w-[50vw] lg:w-[600px] rounded-xl text-sm",
          )}
          style={{
            zIndex: FloatTagZIndex,
          }}
        >
          <div className="mb-4 flex flex-row items-center text-base">
            <Dialog.Title className="flex-1 text-zinc-500 font-semibold truncate">
              Advanced Settings
            </Dialog.Title>
            <Dialog.Close className="text-zinc-500 hover:text-zinc-300 transition-colors cursor-pointer">
              <Cross1Icon />
            </Dialog.Close>
          </div>
          <div className="pb-1 flex flex-col items-stretch gap-4">
            {getObjectEntries(customModelPlaygroundGenerateImageConfig)
              .filter(([key]) => !["width", "height", "numImages"].includes(key))
              .map(([key, props]) => (
                <ApiInput
                  {...props}
                  key={key}
                  value={apiState[key] as any}
                  onValueChange={(value: unknown) => {
                    setApiState((prevState) => {
                      return {
                        ...prevState,
                        [key]: value,
                      };
                    });
                  }}
                />
              ))}
            <CustomModelGenerationModeDropdown />
            <Dialog.Close className={PrimaryButtonClassName}>Apply settings</Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function PastGenerations() {
  return (
    <div className="flex flex-col items-stretch justify-center gap-4">
      <CustomModelPredictions />
    </div>
  );
}

type OutputItemContainerProps = React.PropsWithChildren & {
  width: number;
  height: number;
};

function OutputItemContainer({ width, height, children }: OutputItemContainerProps) {
  return (
    <AspectRatio.Root
      ratio={width / height}
      className={classNames(PreviewImageContainerClassName, "relative")}
    >
      {children}
    </AspectRatio.Root>
  );
}

function OutputItemProcessing() {
  return (
    <div className="flex flex-col items-center gap-4 justify-center text-zinc-500">
      <SimpleSpinner width={23} height={23} pathClassName="fill-lime-500" />
      <span className="truncate">Processing ...</span>
    </div>
  );
}

function OutputItemIdle() {
  return (
    <div className="hidden sm:flex max-w-[80%] flex-row items-center gap-4 justify-center text-zinc-500 text-center">
      No output yet! Press the "Submit" button to test your custom model.
    </div>
  );
}

interface OutputItemDisplayProps {
  width: number;
  height: number;
  imageUrl: string;
  imageIndex?: number;
}

function OutputItemDisplay({ width, height, imageUrl, imageIndex = 0 }: OutputItemDisplayProps) {
  const backend = editorContextStore((state) => state.backend);

  const { predictionId } = useCustomModelPlayground();

  const { setImageIndex, setImageUrl, setPrediction, setWidth, setHeight, setModes } =
    useCustomModelImageEditorContext();

  const handleOpenImageEditor = () => {
    setImageUrl(imageUrl);
    setImageIndex(imageIndex);
    setWidth(width);
    setHeight(height);

    setModes([CustomModelImageEditorMode.Default]);

    if (backend && predictionId) {
      backend?.getCustomModelPrediction(predictionId).then((prediction) => {
        setPrediction(prediction);
      });
    }
  };

  return (
    <CustomModelImageEditorDialogTrigger
      className="group/prediction relative w-full h-full cursor-pointer"
      onClick={() => {
        handleOpenImageEditor();
      }}
    >
      <ImageComponent
        src={preprocessImageUrl(imageUrl)}
        className="object-cover w-full h-full group-hover/prediction:scale-105 duration-300 transition-all"
      />
      <div
        className={classNames(
          "absolute right-0 bottom-0 m-4 flex flex-row items-center justify-center gap-2",
        )}
      >
        <Tooltip
          triggerProps={{
            asChild: true,
          }}
          triggerChildren={
            <div
              className={OutputItemToolbarButtonClassName}
              onClick={() => {
                handleOpenImageEditor();
              }}
            >
              <SquarePen size={18} />
            </div>
          }
          contentChildren={<div>Edit Image</div>}
        />
        <Tooltip
          triggerProps={{
            asChild: true,
          }}
          triggerChildren={
            <div
              className={OutputItemToolbarButtonClassName}
              onClick={() => {
                openAnimateVideoWindow({
                  width,
                  height,
                  imageUrl,
                });
              }}
            >
              <ImageToVideoIcon size={18} />
            </div>
          }
          contentChildren={<div>Animate image</div>}
        />
        <Tooltip
          triggerProps={{
            asChild: true,
          }}
          triggerChildren={
            <div
              className={OutputItemToolbarButtonClassName}
              onClick={() => {
                const url = imageUrl;

                debugLog(`Download image from url ${url}`);

                downloadUrl(url, `output-${imageIndex}`);
              }}
            >
              <Download size={18} />
            </div>
          }
          contentChildren={<div>Download image</div>}
        />
      </div>
    </CustomModelImageEditorDialogTrigger>
  );
}

function OutputItem({ width, height, imageUrl, ...props }: OutputItemDisplayProps) {
  const { status } = useCustomModelPlayground();
  const isRendering = React.useMemo(
    () => status === CustomModelPlaygroundStatus.Rendering,
    [status],
  );

  return (
    <OutputItemContainer width={width} height={height}>
      {isRendering ? (
        <OutputItemProcessing />
      ) : imageUrl ? (
        <OutputItemDisplay width={width} height={height} imageUrl={imageUrl} {...props} />
      ) : (
        <OutputItemIdle />
      )}
    </OutputItemContainer>
  );
}

function Output() {
  const { apiState, outputImages } = useCustomModelPlayground();

  const { width, height } = apiState;
  const numColumns =
    apiState.numImages === 1
      ? "grid-cols-1"
      : apiState.numImages === 2 || apiState.numImages === 4
        ? "grid-cols-2"
        : "grid-cols-3";

  return (
    <div className="flex flex-col items-stretch justify-center">
      <CustomModelImageEditorDialog
        ignoreDialogTrigger
        triggerChildren={
          <div className={classNames("grid auto-rows-auto gap-2", numColumns)}>
            {Array.from(Array(apiState.numImages).keys()).map((index) => (
              <OutputItem
                key={index}
                width={width}
                height={height}
                imageUrl={outputImages?.[index]}
                imageIndex={index}
              />
            ))}
          </div>
        }
      />
    </div>
  );
}

const triggerClassName = classNames(
  styles.TabsTrigger,
  "px-6 py-2 md:min-w-[4rem] xl:min-w-[8rem] transition-colors text-center",
);

function CustomModelPlaygroundPromptMessage() {
  const { apiState } = useCustomModelPlayground();

  const model = editorContextStore((state) => state.customModelInfo);

  const trainings = editorContextStore((state) => state.customModelTrainings);

  const message = React.useMemo(() => {
    const successfulTrainings = Object.values(trainings ?? {})
      .filter((training) => training.status === CustomModelTrainingStatus.Succeeded)
      .sort(sortByTimeModified);

    if (successfulTrainings.length <= 0) {
      return "Please train a model, before trying to generate an image.";
    }

    const training = successfulTrainings[0];

    const promptEditorState = apiState.promptEditorState;

    const text = promptEditorState.text;

    if (!text) {
      return "Please use the prompt editor to describe the image you want to see generated.";
    }

    const hasMentionedCustomModel = Object.keys(promptEditorState.scaleConfigs).length > 0;

    const modelId = model?.displayName ?? model?.id ?? training.modelId ?? "";

    const modelDisplayName = model?.displayName ?? getDisplayNameFromId(modelId);

    if (!hasMentionedCustomModel) {
      const mentionName = getModelTrainingMentionName({
        modelDisplayName,
        training,
      });

      return `Type ${mentionName} with detailed description to generate accurate images.`;
    }

    return "";
  }, [apiState, trainings, model]);

  return (
    <div
      className={classNames(
        "relative self-start w-full flex flex-row items-center gap-4 bg-lime-900/20 border border-lime-900/30 px-4 py-3 rounded-lg shadow-md text-lime-400 text-sm font-semibold transition-opacity",
        message ? "opacity-100" : "opacity-0 h-0 w-0 p-0",
      )}
    >
      <TriangleAlert size={16} />
      <div className="flex-1">{message}</div>
    </div>
  );
}
export function GenerateSettings() {
  const { apiState, setApiState } = useCustomModelPlayground();
  const modelId = editorContextStore((state) => state.customModelId);

  if (!modelId) {
    return null;
  }

  return (
    <form
      className="flex flex-col gap-4 text-sm"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="flex flex-col gap-1">
        <div className="text-base font-semibold">Image Generation</div>
        <div className="text-zinc-500 text-sm">
          <ul>
            <li>• Describe your model and background with a prompt</li>
            <li>• Use @ to tag and apply a trained custom model</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-zinc-300 font-semibold">Prompt</div>
        <CustomModelPlaygroundPromptMessage />
        <CustomModelPromptEditor
          editorState={apiState.promptEditorState}
          setEditorState={(editorState) =>
            setApiState((apiState) => ({
              ...apiState,
              promptEditorState: editorState,
            }))
          }
        />
      </div>
      <div className="flex flex-row justify-between gap-2">
        <div className="flex-col w-full">
          <AspectRatioSelector />
        </div>
        <div className="flex flex-col gap-2 py-1 whitespace-nowrap">
          <div className="text-zinc-300 font-semibold">Number of Images</div>
          <NumberOfImagesSelector />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <ConfigureButton />
      </div>

      <SubmitButton />
    </form>
  );
}
export function GenerateOutput() {
  const backend = editorContextStore((state) => state.backend);
  const modelId = editorContextStore((state) => state.customModelId);
  const { setStatus, setOutputImages, predictionId } = useCustomModelPlayground();

  React.useEffect(() => {
    if (!backend || !predictionId) {
      return;
    }

    return backend.onCustomModelPredictionUpdate({
      predictionId,
      callback: (prediction) => {
        debugLog(`Prediction ${predictionId} updated: `, prediction);

        if (prediction.output) {
          Promise.all(
            prediction.output.map((path) =>
              Assets.loadAssetFromPath({
                path,
                backend,
              }),
            ),
          ).then((output) => {
            setOutputImages(output.filter(Boolean) as string[]);
          });
        }

        const isPredictionActive = isCustomModelTrainingStatusActive(prediction.status);

        setStatus(
          isPredictionActive
            ? CustomModelPlaygroundStatus.Rendering
            : CustomModelPlaygroundStatus.Idle,
        );
      },
    });
  }, [backend, predictionId, setStatus, setOutputImages]);

  if (!modelId) {
    return null;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2 pt-4 md:pt-0">
        <div className="text-sm font-semibold">Generated Result</div>
        <Output />
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-sm font-semibold">Past Generations</div>
        <PastGenerations />
        <div className="w-full h-[20vh]" />
      </div>
    </div>
  );
}
