import React from "react";
import { PanelList } from "./panel-list";
import { classNames } from "@/core/utils/classname-utils";
import { useActiveObjectBottomPanel } from "@/hooks-mobile/use-active-object-bottom-panel";
import {
  ScrollAreaContainer,
  ScrollAreaContainerProps,
} from "@/components/scroll-area/scroll-area";

export function BottomPanelsList({ className = "", ...props }: Partial<ScrollAreaContainerProps>) {
  useActiveObjectBottomPanel();
  return (
    <ScrollAreaContainer
      {...props}
      id="bottom-panels-list"
      orientation="horizontal"
      className={classNames("w-screen", className)}
    >
      <div className="w-full bg-zinc-900 border-t border-zinc-800">
        <PanelList />
      </div>
    </ScrollAreaContainer>
  );
}
