import { CustomModelPostProcessActionType } from "@/backend/custom-model-post-process";
import { editorContextStore } from "@/contexts/editor-context";
import {
  getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput,
  getImageSizeFromCustomModelPredictionInput,
} from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { getPromptStateFromText } from "@/core/utils/text-utils";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { clamp } from "lodash";
import { useMemo } from "react";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
} from "../constants/class-names";
import { SimpleSpinner } from "../icons/simple-spinner";
import { NumberSlider } from "../panels/panel-items/components/number-slider";
import { PanelSwitchRoot } from "../panels/panel-items/components/switch";
import { ScrollAreaContainer } from "../scroll-area/scroll-area";
import { displayUiMessage } from "../utils/display-message";
import { ImageComponent } from "../utils/image";
import { InfoBox } from "../utils/info-box";
import { Tooltip } from "../utils/tooltip";
import { mainPanelClassName, toolbarPanelClassName } from "./classnames";
import { CustomModelImageEditorBackButton } from "./custom-model-editor-back-button";
import {
  CustomModelImageEditorMode,
  loadImageFromCustomModelPredictionOutput,
  useCustomModelImageEditorContext,
} from "./custom-model-image-editor-context";
import { useCustomModelCompareOutputContext } from "./custom-model-image-output-context";
import { getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState } from "./custom-model-mention-plugin";
import {
  CustomModelUpscaleFaceStatus,
  useCustomModelUpscaleFaceContext,
} from "./custom-model-upscale-face-context";

export function CustomModelUpscaleFace() {
  const backend = editorContextStore((state) => state.backend);

  const {
    status,
    setStatus,
    imageUrl,
    setImageUrl,
    faceUpsample,
    setFaceUpsample,
    backgroundEnhance,
    setBackgroundEnhance,
    creativity,
    setCreativity,
    upscale,
    setUpscale,
  } = useCustomModelUpscaleFaceContext();

  const { prediction, setModes } = useCustomModelImageEditorContext();
  const { setActiveOutputImageIndex, setSourceImageUrl, setOutputImageUrls, setWidth, setHeight } =
    useCustomModelCompareOutputContext();

  const predictionInput = useMemo(
    () =>
      prediction?.input &&
      getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction?.input),
    [prediction?.input],
  );

  const promptEditorState = useMemo(() => {
    const promptJson = predictionInput?.promptJson;

    if (!promptJson) {
      return;
    }

    try {
      return getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
        promptEditorState: JSON.parse(promptJson || getPromptStateFromText(predictionInput.prompt)),
      });
    } catch (error) {
      debugError(
        "Error setting prompt editor state with prediction input ",
        predictionInput,
        "\n",
        error,
      );
    }
  }, [predictionInput]);

  const handleUpscaleFace = async () => {
    if (!backend) {
      return;
    }

    if (status !== CustomModelUpscaleFaceStatus.Idle) {
      return;
    }

    setStatus(CustomModelUpscaleFaceStatus.Loading);

    try {
      const upscaleFactor = clamp(upscale, 2, 4);

      const codeformerFidelity = 1.0 - creativity / 10.0;

      debugLog("Upscale Face args:", {
        faceUpsample,
        backgroundEnhance,
        codeformerFidelity,
        upscale: upscaleFactor,
        prompt: promptEditorState?.text,
        promptJson: promptEditorState?.json,
      });

      const response = await backend.customModelUpscaleFace({
        type: CustomModelPostProcessActionType.UpscaleFace,
        prompt: promptEditorState?.text,
        promptJson: promptEditorState?.json,
        inputImageUrl: imageUrl,
        upscale: upscaleFactor,
        faceUpsample,
        backgroundEnhance,
        codeformerFidelity,
        usedModels: prediction?.usedModels ?? {},
      });

      if (!response.ok) {
        displayUiMessage(response.message ?? "Unknown error", "error");
        return;
      }

      const predictionId = response.predictionId;
      if (!predictionId) {
        return;
      }

      // Wait for the output images
      const outputImagePaths = await new Promise<string[]>((resolve) => {
        backend.onCustomModelPredictionUpdate({
          predictionId,
          callback: (predictionItem) => {
            const output = predictionItem?.output;
            if (!output) {
              return;
            }
            resolve(output);
          },
        });
      });

      const imageUrls = (
        await Promise.all(
          outputImagePaths.map((path) => {
            return Assets.loadAssetFromPath({
              path,
              backend,
            });
          }),
        )
      ).filter(Boolean) as string[];

      if (imageUrls.length > 0) {
        const { width, height } = prediction?.input
          ? getImageSizeFromCustomModelPredictionInput(prediction.input)
          : {
              width: 1024,
              height: 1024,
            };

        setSourceImageUrl(imageUrl);
        setOutputImageUrls(imageUrls);
        setActiveOutputImageIndex(0);
        setWidth(width);
        setHeight(height);

        setModes((modes) => [...modes, CustomModelImageEditorMode.CompareOutputs]);
      } else {
        debugError("Cannot load output images from upscale face");
      }
    } catch (error) {
      debugError("Error upscaling face: ", error);
    } finally {
      setStatus(CustomModelUpscaleFaceStatus.Idle);
    }
  };

  return (
    <div className="relative py-2.5 w-full h-full flex flex-col items-stretch md:flex-row gap-4 md:gap-2">
      {/* Main Panel: Display the image */}
      <div
        className={classNames(
          mainPanelClassName,
          "w-full h-fit max-h-[50%] md:w-auto md:h-full md:max-h-none flex-1",
        )}
      >
        <div className="rounded-md w-full h-full overflow-hidden bg-zinc-800/50">
          <ImageComponent
            src={imageUrl}
            className="object-contain w-full h-full"
            onError={(e) => {
              debugError(`Error loading image from url ${imageUrl}: `, e);
              if (backend && prediction) {
                loadImageFromCustomModelPredictionOutput({
                  backend,
                  prediction,
                }).then((url) => {
                  setImageUrl(url ?? "");
                });
              }
            }}
          />
        </div>
      </div>
      {/* Toolbar Panel */}
      <ScrollAreaContainer className={classNames(toolbarPanelClassName)}>
        <div className="flex flex-col items-stretch gap-4 pb-8">
          <CustomModelImageEditorBackButton />
          <InfoBox defaultValue="info" className="w-full">
            <ol className="list-decimal list-outside pl-5 flex flex-col gap-2 text-lime-200 text-sm leading-relaxed">
              <li>Use the sliders and toggles below to enhance the face in the image.</li>
              <li>
                (Optional) Use the "Creativity" slider to control how much detail you would like to
                add to the image.
              </li>
              <li>Click "Upscale Face" button and wait for the result to appear.</li>
            </ol>
          </InfoBox>
          <div className="flex flex-col items-stretch gap-4">
            <NumberSlider
              name={
                <Tooltip
                  triggerChildren={
                    <div className="mr-0 lg:min-w-[8rem] group flex flex-row items-center gap-2">
                      <span className="truncate">Creativity</span>
                      <QuestionMarkCircledIcon className="text-zinc-500 group-hover:text-zinc-300 transition-colors" />
                    </div>
                  }
                  contentChildren={
                    <div className="flex flex-col gap-2">
                      <div>
                        Creativity controls how much details to add to the image after upscaling.
                      </div>
                    </div>
                  }
                />
              }
              value={creativity}
              setValue={setCreativity}
              min={0}
              max={10}
              step={1}
            />
            <div className="flex items-center gap-2">
              <Tooltip
                triggerProps={{
                  className: "flex-1",
                }}
                triggerChildren={
                  <div className="mr-0 lg:min-w-[8rem] group flex flex-row items-center gap-2">
                    <span className="min-w-[50px] text-left text-sm font-semibold">
                      Enhance Background
                    </span>
                    <QuestionMarkCircledIcon className="text-zinc-500 group-hover:text-zinc-300 transition-colors" />
                  </div>
                }
                contentChildren={
                  <div className="flex flex-col gap-2">
                    <div>Upscale the background in addition to the face.</div>
                  </div>
                }
              />
              <PanelSwitchRoot checked={backgroundEnhance} onCheckedChange={setBackgroundEnhance} />
            </div>
            <button
              className={classNames(
                status === CustomModelUpscaleFaceStatus.Idle
                  ? PrimaryButtonClassName
                  : status === CustomModelUpscaleFaceStatus.Loading
                    ? PrimaryButtonClassNameLoading
                    : PrimaryButtonClassNameDisabled,
              )}
              onClick={handleUpscaleFace}
            >
              {status === CustomModelUpscaleFaceStatus.Loading ? (
                <div className="flex flex-row items-center justify-center gap-2">
                  <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
                  <span>Loading ...</span>
                </div>
              ) : (
                "Upscale Face"
              )}
            </button>
          </div>
        </div>
      </ScrollAreaContainer>
    </div>
  );
}
