import { ApiEditorState, ApiRenderState, getDefaultUserApiData } from "@/core/common/types/api";
import { SetEditorStateFunction, getUpdaterFunction } from "./editor-context-utils";

const noop = () => {};

export function getDummyApiEditorContextState(): ApiEditorState {
  return {
    userApiData: getDefaultUserApiData(),
    setUserApiData: noop,
    apiDashboardModelType: undefined,
    setApiDashboardModelType: noop,
  };
}

export function getDefaultApiEditorContextState(set: SetEditorStateFunction): ApiEditorState {
  return {
    userApiData: getDefaultUserApiData(),
    setUserApiData: getUpdaterFunction(set, "userApiData"),
    apiDashboardModelType: undefined,
    setApiDashboardModelType: getUpdaterFunction(set, "apiDashboardModelType"),
  };
}
