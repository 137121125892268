import React from "react";
import { fabric } from "fabric";
import { SecondaryButtonClassNameInactive } from "components/constants/class-names";
import { editorContextStore } from "contexts/editor-context";
import { classNames } from "@/core/utils/classname-utils";
import { isActiveSelection, isStaticImageObject } from "@/core/utils/type-guards";
import { LayoutGrid, Trash } from "lucide-react";
import { LeftPanelBarDividerSmall } from "../components/divider";
import { LeftPanelTitle } from "../components/left-panel-title";
import { DownloadImagesGroup } from "components/utils/download-image-button";
import { LeftPanelSectionContainer } from "../base";
import { arrangeSelectionInGrid } from "./edit-selection-utils";

function DownloadImages() {
  const activeObject = editorContextStore((state) => state.activeObject);

  const imageObjects = React.useMemo(() => {
    if (isActiveSelection(activeObject)) {
      return activeObject.getObjects().filter(isStaticImageObject) as any as fabric.StaticImage[];
    }
    return [];
  }, [activeObject]);

  return (
    <LeftPanelSectionContainer label="Download">
      <DownloadImagesGroup imageObjects={imageObjects} />
    </LeftPanelSectionContainer>
  );
}

export function EditSelectionPanel() {
  return (
    <div className="flex flex-col">
      <LeftPanelTitle>Edit Selection</LeftPanelTitle>
      <div
        className={classNames(SecondaryButtonClassNameInactive, "shadow-md")}
        onClick={() => {
          arrangeSelectionInGrid();
        }}
      >
        <span className="grow">Arrange in a grid</span>
        <LayoutGrid size={16} />
      </div>
      <LeftPanelBarDividerSmall />
      <div
        className={classNames(SecondaryButtonClassNameInactive, "shadow-md")}
        onClick={() => {
          const { editor, activeObject } = editorContextStore.getState();
          if (editor && activeObject) {
            editor.objects.remove(activeObject.id);
          }
        }}
      >
        <span className="grow">Delete</span>
        <Trash size={16} />
      </div>
      <LeftPanelBarDividerSmall />
      <LeftPanelBarDividerSmall />
      <DownloadImages />
      <LeftPanelBarDividerSmall />
    </div>
  );
}
