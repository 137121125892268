import { debugError, debugLog } from "@/core/utils/print-utilts";
import { httpsCallable, Functions, HttpsCallable } from "firebase/functions";
import debounce from "debounce-promise";
import { FalAnyLLMArgs, FalAnyLLMResponse } from "@/core/common/types/any-llm";

type AnyLLMFunction = HttpsCallable<FalAnyLLMArgs, FalAnyLLMResponse>;

function createAnyLLMFunction({
  firebaseFunctions,
}: {
  firebaseFunctions: Functions;
}): AnyLLMFunction {
  return httpsCallable<FalAnyLLMArgs, FalAnyLLMResponse>(
    firebaseFunctions,
    "callLanguageModelColabJuly24",
  );
}

export const callLanguageModel = debounce(async function ({
  firebaseFunctions,
  request,
}: {
  firebaseFunctions: Functions;
  request: FalAnyLLMArgs;
}): Promise<FalAnyLLMResponse> {
  const anyLLMFunction = createAnyLLMFunction({ firebaseFunctions });

  try {
    const result = await anyLLMFunction(request);

    debugLog("LLM response data: ", result.data);

    if (!result?.data) {
      throw new Error("No valid response text returned from LLM.");
    }
    return result.data;
  } catch (error) {
    debugError("Error calling LLM request:", error);
    return "";
  }
}, 500);
