import { StateUpdater } from "@/core/common/types";

export enum OutpaintStatus {
  Idle = "Idle",
  Rendering = "Rendering",
  Error = "Error",
}

export interface OutpaintContextState {
  status: OutpaintStatus;
  setStatus: (value: StateUpdater<OutpaintStatus>) => void;
  outpaintWidth: number;
  setOutpaintWidth: (value: StateUpdater<number>) => void;
  outpaintHeight: number;
  setOutpaintHeight: (value: StateUpdater<number>) => void;
  outputImageUrls: string[];
  setOutputImageUrls: (value: StateUpdater<string[]>) => void;
}
