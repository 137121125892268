import { PaintMaskCanvasControllerPaintBrushType } from "components/editor/paint-mask-canvas-controller";
import { StateUpdater } from "@/core/common/types";
import React, { createContext, useContext } from "react";

export enum CustomModelFixTextEditorMode {
  Idle = "Idle",
  Loading = "Loading",
}

export interface CustomModelFixTextSourceImageOption {
  imageUrl?: string;
  thumbnailUrl?: string;
  storagePath?: string;
}

interface CustomModelFixTextContextType {
  sourceImageId: string | undefined;
  setSourceImageId: (value: StateUpdater<string | undefined>) => void;
  sourceImageOptions: Record<string, CustomModelFixTextSourceImageOption>;
  setSourceImageOptions: (
    value: StateUpdater<Record<string, CustomModelFixTextSourceImageOption>>,
  ) => void;

  sourceImageUrl: string;
  setSourceImageUrl: (value: StateUpdater<string>) => void;
  sourceMaskUrl: string | undefined;
  setSourceMaskUrl: (value: StateUpdater<string | undefined>) => void;
  sourceMaskBrushSize: number;
  setSourceMaskBrushSize: (value: StateUpdater<number>) => void;
  sourceMaskBrushType: PaintMaskCanvasControllerPaintBrushType;
  setSourceMaskBrushType: (value: StateUpdater<PaintMaskCanvasControllerPaintBrushType>) => void;

  targetImageUrl: string;
  setTargetImageUrl: (value: StateUpdater<string>) => void;
  targetMaskUrl: string | undefined;
  setTargetMaskUrl: (value: StateUpdater<string | undefined>) => void;
  targetMaskBrushSize: number;
  setTargetMaskBrushSize: (value: StateUpdater<number>) => void;
  targetMaskBrushType: PaintMaskCanvasControllerPaintBrushType;
  setTargetMaskBrushType: (value: StateUpdater<PaintMaskCanvasControllerPaintBrushType>) => void;

  mode: CustomModelFixTextEditorMode;
  setMode: (value: StateUpdater<CustomModelFixTextEditorMode>) => void;
  strength: number;
  setStrength: (value: StateUpdater<number>) => void;
}

const CustomModelFixTextContext = createContext<CustomModelFixTextContextType | undefined>(
  undefined,
);

export function useCustomModelFixTextContext() {
  const context = useContext(CustomModelFixTextContext);

  if (!context) {
    throw new Error("useCustomModelFixTextContext must be used with in CustomModelFixTextContext");
  }

  return context;
}

export function CustomModelFixTextContextProvider({ children }: React.PropsWithChildren) {
  const [sourceImageId, setSourceImageId] = React.useState<string | undefined>(undefined);
  const [sourceImageOptions, setSourceImageOptions] = React.useState<
    Record<string, CustomModelFixTextSourceImageOption>
  >({});

  const [sourceImageUrl, setSourceImageUrl] = React.useState<string>("");

  const [sourceMaskUrl, setSourceMaskUrl] = React.useState<string | undefined>(undefined);

  const [sourceMaskBrushSize, setSourceMaskBrushSize] = React.useState(25);

  const [sourceMaskBrushType, setSourceMaskBrushType] = React.useState(
    PaintMaskCanvasControllerPaintBrushType.Paint,
  );

  const [targetImageUrl, setTargetImageUrl] = React.useState<string>("");

  const [targetMaskUrl, setTargetMaskUrl] = React.useState<string | undefined>(undefined);

  const [targetMaskBrushSize, setTargetMaskBrushSize] = React.useState(25);

  const [targetMaskBrushType, setTargetMaskBrushType] = React.useState(
    PaintMaskCanvasControllerPaintBrushType.Paint,
  );

  const [mode, setMode] = React.useState(CustomModelFixTextEditorMode.Idle);
  const [strength, setStrength] = React.useState(5);

  return (
    <CustomModelFixTextContext.Provider
      value={{
        sourceImageId,
        setSourceImageId,
        sourceImageOptions,
        setSourceImageOptions,
        sourceImageUrl,
        setSourceImageUrl,
        sourceMaskUrl,
        setSourceMaskUrl,
        sourceMaskBrushSize,
        setSourceMaskBrushSize,
        sourceMaskBrushType,
        setSourceMaskBrushType,
        targetImageUrl,
        setTargetImageUrl,
        targetMaskUrl,
        setTargetMaskUrl,
        targetMaskBrushSize,
        setTargetMaskBrushSize,
        targetMaskBrushType,
        setTargetMaskBrushType,
        mode,
        setMode,
        strength,
        setStrength,
      }}
    >
      {children}
    </CustomModelFixTextContext.Provider>
  );
}
