import {
  isSetSubscriptionProductIdQuotaResponse,
  SetSubscriptionProductIdQuotaParams,
  SetSubscriptionProductIdQuotaResponse,
} from "@/backend/base";
import {
  isStripePrice,
  isStripeProduct,
  StripePrice,
  StripeProduct,
} from "@/core/common/types/stripe";
import { debugError } from "@/core/utils/print-utilts";
import { collection, doc, Firestore, getDoc, getDocs, onSnapshot } from "firebase/firestore";
import { Functions, httpsCallable, HttpsCallable } from "firebase/functions";

export class StripeProductManager {
  private firestore: Firestore;

  private setSubscriptionProductIdQuotaCallable: HttpsCallable<
    SetSubscriptionProductIdQuotaParams,
    SetSubscriptionProductIdQuotaResponse
  >;

  constructor({
    firestore,
    firebaseFunctions,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;

    this.setSubscriptionProductIdQuotaCallable = httpsCallable(
      firebaseFunctions,
      "setSubscriptionProductIdQuotaColabJuly24",
    );
  }

  async setSubscriptionProductIdQuota(
    params: SetSubscriptionProductIdQuotaParams,
  ): Promise<SetSubscriptionProductIdQuotaResponse> {
    try {
      const response = await this.setSubscriptionProductIdQuotaCallable(params);

      const data = response.data;

      if (isSetSubscriptionProductIdQuotaResponse(data)) {
        return data;
      }
      return {
        success: false,
      };
    } catch (error) {
      debugError("Error creating new stripe product: ", error);

      return {
        success: false,
        message: String(error),
      };
    }
  }

  static ProductCollectionName = "products";
  static PriceCollectionName = "prices";

  private getProductCollectionRef() {
    return collection(this.firestore, StripeProductManager.ProductCollectionName);
  }

  private getProductDocRef(productId: string) {
    return doc(this.getProductCollectionRef(), productId);
  }

  private getProductPriceCollectionRef(productId: string) {
    return collection(this.getProductDocRef(productId), StripeProductManager.PriceCollectionName);
  }

  async getProductDoc(productId: string): Promise<StripeProduct | undefined> {
    try {
      const snapshot = await getDoc(this.getProductDocRef(productId));

      const data = snapshot.data();

      if (!isStripeProduct(data)) {
        return;
      }

      return data;
    } catch (error) {
      debugError("Error getting product doc ", error);
      return undefined;
    }
  }

  /**
   * Lists all Stripe products in the collection.
   * @returns A promise that resolves to an array of StripeProduct.
   */
  async listAllProducts(): Promise<StripeProduct[]> {
    try {
      const productsCollection = this.getProductCollectionRef();
      const snapshot = await getDocs(productsCollection);

      const products: StripeProduct[] = [];
      snapshot.forEach((docSnap) => {
        const data = { id: docSnap.id, ...docSnap.data() };
        if (isStripeProduct(data)) {
          products.push(data);
        } else {
          debugError(`Invalid StripeProduct data in document ${docSnap.id}:`, data);
        }
      });

      return products;
    } catch (error) {
      debugError("Error listing all products: ", error);
      return [];
    }
  }

  /**
   * Subscribes to real-time updates of the Stripe products collection.
   * @param callback A function that receives the updated list of StripeProduct.
   * @returns An unsubscribe function to stop listening to updates.
   */
  onProductsUpdate(callback: (products: StripeProduct[]) => void): () => void {
    const productsCollection = this.getProductCollectionRef();

    const unsubscribe = onSnapshot(
      productsCollection,
      (snapshot) => {
        const products: StripeProduct[] = [];
        snapshot.forEach((docSnap) => {
          const data = { id: docSnap.id, ...docSnap.data() };
          if (isStripeProduct(data)) {
            products.push(data);
          } else {
            debugError(`Invalid StripeProduct data in document ${docSnap.id}:`, data);
          }
        });
        callback(products);
      },
      (error) => {
        debugError("Error subscribing to products updates: ", error);
      },
    );

    return unsubscribe;
  }

  /**
   * Lists all Stripe prices for a specific product.
   * @param productId The ID of the product whose prices are to be listed.
   * @returns A promise that resolves to an array of StripePrice.
   */
  async listAllPrices(productId: string): Promise<StripePrice[]> {
    try {
      const pricesCollection = this.getProductPriceCollectionRef(productId);
      const snapshot = await getDocs(pricesCollection);

      const prices: StripePrice[] = [];
      snapshot.forEach((docSnap) => {
        const data = { id: docSnap.id, ...docSnap.data() };
        if (isStripePrice(data)) {
          prices.push(data);
        } else {
          debugError(`Invalid StripePrice data in document ${docSnap.id}:`, data);
        }
      });

      return prices;
    } catch (error) {
      debugError(`Error listing all prices for product ${productId}: `, error);
      return [];
    }
  }

  /**
   * Subscribes to real-time updates of the Stripe prices subcollection for a specific product.
   * @param productId The ID of the product whose prices are to be listened to.
   * @param callback A function that receives the updated list of StripePrice.
   * @returns An unsubscribe function to stop listening to updates.
   */
  onPricesUpdate(productId: string, callback: (prices: StripePrice[]) => void): () => void {
    const pricesCollection = this.getProductPriceCollectionRef(productId);

    const unsubscribe = onSnapshot(
      pricesCollection,
      (snapshot) => {
        const prices: StripePrice[] = [];
        snapshot.forEach((docSnap) => {
          const data = { id: docSnap.id, ...docSnap.data() };
          if (isStripePrice(data)) {
            prices.push(data);
          } else {
            debugError(`Invalid StripePrice data in document ${docSnap.id}:`, data);
          }
        });
        callback(prices);
      },
      (error) => {
        debugError(`Error subscribing to prices updates for product ${productId}: `, error);
      },
    );

    return unsubscribe;
  }
}
