import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { classNames } from "@/core/utils/classname-utils";
import {
  DashboardVideosContextProvider,
  DashboardVideosTab,
  isDashboardVideosTab,
  useDashboardVideosContext,
} from "./dashboard-video-context";
import {
  GenerateVideoEditorInner,
  GenerateVideoEditorStatus,
  handleStartVideoGenerationBackend,
  useGenerateButtonDisabledEffect,
} from "./generate-video-editor";
import StickyBox from "components/utils/sticky-box";
import { Clapperboard, History, Play, Sparkles } from "lucide-react";
import {
  DashboardTitleClassName,
  DropdownClassName,
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { CustomModelPlaygroundHeaderZIndex, FloatTagZIndex } from "components/constants/zIndex";
import styles from "./dashboard-video.module.css";
import { editorContextStore } from "contexts/editor-context";
import {
  defaultVideoImageUrl,
  getGenerateVideoKeyFramesWithUploadButtons,
  isGenerateVideoKeyFrames,
  isVideoGenerationStatusActive,
  LumaGenerationKeyframe,
  resetGenerateVideoEditorState,
  VideoGenerationDoc,
  VideoGenerationStatus,
} from "@/core/common/types/video";
import { MasonryGrid } from "components/utils/masonry-grid";
import { getObjectEntries } from "@/core/utils/type-utils";
import { getTimeDifferenceInSeconds, sortByTimeModified } from "@/core/utils/time-utils";
import { Backend } from "@/backend/base";
import { Assets } from "@/core/controllers/assets";
import { ImageComponent } from "components/utils/image";
import { ProgressHandler } from "components/utils/progress-handler";
import { clamp } from "lodash";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { capitalizeFirstLetter, isDataURL, isValidHttpsUrl } from "@/core/utils/string-utils";
import { useSearchParams } from "react-router-dom";
import { VideoComponent } from "./video-component";

import * as Dialog from "@radix-ui/react-dialog";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { Cross1Icon } from "@radix-ui/react-icons";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { canUserStartVideoGeneration } from "@/core/utils/video-utils";
import { UiDisplayMessageDialogEventHandler } from "@/core/common/types";
import {
  VideoPastGenerationProvider,
  VideoPastGenerationsMasonryGrid,
} from "./video-past-generations";
import { ImageToVideoIcon } from "./image-to-video-icon";

function DashboardPastGeneration() {
  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const setGenerateVideoPastGenerations = editorContextStore(
    (state) => state.setGenerateVideoPastGenerations,
  );

  React.useEffect(() => {
    if (!publicUserId) {
      return;
    }

    return backend?.onUserVideoGenerationsUpdate({
      publicUserId,
      callback: (generations) => {
        setGenerateVideoPastGenerations(generations);
      },
    });
  }, [backend, publicUserId, setGenerateVideoPastGenerations]);

  return (
    <div className="p-2 flex flex-col items-stretch">
      <VideoPastGenerationsMasonryGrid />
    </div>
  );
}

function DashboardGenerateVideo() {
  const userQuotas = editorContextStore((state) => state.userQuotas);

  const eventEmitter = editorContextStore((state) => state.eventEmitter);

  const canUserStart = React.useMemo(
    () =>
      canUserStartVideoGeneration({
        userQuotas,
      }),
    [userQuotas],
  );

  React.useEffect(() => {
    const { setGenerateVideoKeyFrames } = editorContextStore.getState();

    setGenerateVideoKeyFrames((keyFrames) =>
      getGenerateVideoKeyFramesWithUploadButtons({
        keyFrames,
        maxNumKeyFrames: 2,
      }),
    );
  }, []);

  const { setTab, editorStatus, setEditorStatus } = useDashboardVideosContext();

  const generateVideoPrompt = editorContextStore((state) => state.generateVideoPrompt);
  const generateVideoKeyFrames = editorContextStore((state) => state.generateVideoKeyFrames);

  const { generateButtonText } = useGenerateButtonDisabledEffect({
    setStatus: setEditorStatus,
    generateVideoPrompt,
    generateVideoKeyFrames,
  });

  return (
    <div>
      <GenerateVideoEditorInner className="p-4" />
      <StickyBox offsetBottom={0} bottom>
        <div className="p-4 flex flex-row items-center justify-start gap-4 bg-zinc-900 border-t border-zinc-800 ">
          <button
            className={classNames(
              editorStatus === GenerateVideoEditorStatus.Idle
                ? classNames(PrimaryButtonClassName, "cursor-pointer")
                : editorStatus === GenerateVideoEditorStatus.Processing
                  ? PrimaryButtonClassNameLoading
                  : PrimaryButtonClassNameDisabled,
              "px-4 flex flex-row items-center justify-center gap-2",
            )}
            onClick={() => {
              if (editorStatus !== GenerateVideoEditorStatus.Idle) {
                return;
              }

              if (!canUserStart) {
                eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
                  "ui:display-message-dialog",
                  "quota-subscribe",
                  {
                    title: "No video generation credits left.",
                    header:
                      "You have used all video generation credits. Please subscribe to generate more videos.",
                  },
                );
                return;
              }

              setEditorStatus(GenerateVideoEditorStatus.Processing);

              handleStartVideoGenerationBackend({
                ...editorContextStore.getState(),
              })
                .then((response) => {
                  debugLog(response);

                  if (response.ok) {
                    setTab(DashboardVideosTab.PastGenerations);
                  }
                })
                .finally(() => {
                  setEditorStatus(GenerateVideoEditorStatus.Idle);
                });
            }}
          >
            {editorStatus === GenerateVideoEditorStatus.Processing ? (
              <SimpleSpinner width={18} height={18} pathClassName="fill-lime-800" />
            ) : (
              <Sparkles size={18} className="hidden md:block" />
            )}
            <span className="truncate">Generate video</span>
          </button>
          <span className="text-zinc-500">
            {editorStatus === GenerateVideoEditorStatus.Disabled && generateButtonText}
          </span>
        </div>
      </StickyBox>
    </div>
  );
}

const triggerClassName = classNames(
  "w-fit flex flex-row items-center justify-center gap-2 cursor-pointer",
  styles.TabsTrigger,
  "group px-6 py-2 md:min-w-[4rem] xl:min-w-[8rem] transition-colors text-center truncate",
);

const triggerIconClassName = classNames(
  "text-zinc-500 group-hover:text-lime-600 transition-colors",
);

const iconSize = 16;

function DashboardVideosHeader() {
  return (
    <div className="w-full flex flex-row gap-2 items-center px-4 py-4">
      <ImageToVideoIcon size={18} className="text-zinc-500" />
      <div className={classNames(DashboardTitleClassName)}>Generate videos</div>
    </div>
  );
}

function DashboardVideosInner({ visible = true }: { visible?: boolean }) {
  const { tab, setTab } = useDashboardVideosContext();

  const [searchParams] = useSearchParams();

  const searchParamsTab = searchParams.get("tab");
  const searchParamsKeyFrames = searchParams.get("keyFrames");

  React.useEffect(() => {
    if (isDashboardVideosTab(searchParamsTab)) {
      debugLog(`Set dashboard video tab ${searchParamsTab}`);

      setTab(searchParamsTab);
    }
  }, [searchParamsTab, setTab]);

  React.useEffect(() => {
    if (!searchParamsKeyFrames) {
      return;
    }

    const parsedFrames = JSON.parse(searchParamsKeyFrames);

    if (isGenerateVideoKeyFrames(parsedFrames)) {
      const { setGenerateVideoKeyFrames } = editorContextStore.getState();

      setGenerateVideoKeyFrames(
        getGenerateVideoKeyFramesWithUploadButtons({
          keyFrames: parsedFrames.filter(Boolean),
        }),
      );
    } else {
      debugError("Search params key frames is invalid.");
    }
  }, [searchParamsKeyFrames]);

  return (
    <div className={classNames(visible ? "w-full flex flex-col items-stretch" : "hidden")}>
      <DashboardVideosHeader />
      <Tabs.Root
        value={tab}
        onValueChange={(value) => {
          if (isDashboardVideosTab(value)) {
            setTab(value);
          }
        }}
        defaultValue={DashboardVideosTab.Main}
        className="flex-1 flex-col items-stretch"
      >
        <StickyBox
          style={{
            zIndex: CustomModelPlaygroundHeaderZIndex,
          }}
        >
          <Tabs.List className="h-[42px] w-full text-md font-semibold border-b border-zinc-800 bg-zinc-900">
            <div className="flex flex-row items-center">
              {/* <Tabs.Trigger value={DashboardVideosTab.Main} className={triggerClassName}>
                                <SquarePlay size={iconSize} className={triggerIconClassName} />
                                <span>
                                    Examples
                                </span>
                            </Tabs.Trigger> */}
              <Tabs.Trigger value={DashboardVideosTab.Generate} className={triggerClassName}>
                <Sparkles size={iconSize} className={triggerIconClassName} />
                <span>Generate</span>
              </Tabs.Trigger>
              <Tabs.Trigger value={DashboardVideosTab.PastGenerations} className={triggerClassName}>
                <History size={iconSize} className={triggerIconClassName} />
                <span>Past Generations</span>
              </Tabs.Trigger>
            </div>
          </Tabs.List>
        </StickyBox>
        <Tabs.Content value={DashboardVideosTab.Generate} className="focus:outline-none">
          <DashboardGenerateVideo />
        </Tabs.Content>
        <Tabs.Content value={DashboardVideosTab.PastGenerations} className="focus:outline-none">
          <DashboardPastGeneration />
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
}

export function DashboardVideos({ visible = true }: { visible?: boolean }) {
  React.useEffect(() => {
    return () => {
      resetGenerateVideoEditorState(editorContextStore.getState());
    };
  }, []);

  return (
    <DashboardVideosContextProvider>
      <VideoPastGenerationProvider>
        <DashboardVideosInner visible={visible} />
      </VideoPastGenerationProvider>
    </DashboardVideosContextProvider>
  );
}
