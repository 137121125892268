import { IHistory } from "@/core/common/interfaces";
import { HistoryOperationType } from "@/core/common/types";
import { fabric } from "fabric";
import throttle from "lodash/throttle";
import { LayerType } from "@/core/common/layers";
import { Base } from "@/core/controllers/base";
import type {
  EditorObjectJson,
  EditorSnapshot,
  HistoryChangedEditorEventHandler,
} from "@/core/common/types";
import { addObjectToCanvas } from "components/utils/add-to-canvas-utils";

type HistoryCommand = {
  type: "UPDATE" | "redo" | "undo";
  snapshot: EditorSnapshot;
};

export class EditorHistory extends Base implements IHistory {
  private redos: HistoryCommand[] = [];
  private undos: HistoryCommand[] = [];
  private current: EditorSnapshot = {
    mainCanvas: [],
  };
  private isActive: boolean = false;

  public initialize = () => {
    this.saveMainCanvas();
  };

  public destroy() {}

  public getStatus = () => {
    return {
      hasUndo: this.undos.length >= 1,
      hasRedo: this.undos.length > 0,
    };
  };

  private saveMainCanvas() {
    const canvasJSON = this.canvas.toJSON(this.config.propertiesToInclude) as any as {
      objects: EditorObjectJson[];
    };
    canvasJSON.objects.forEach((object: EditorObjectJson) => {
      if (object.clipPath) {
        fabric.util.enlivenObjects(
          [object.clipPath],
          function (arg1: fabric.Object[]) {
            object.clipPath = arg1[0];
          },
          "",
        );
      }
    });
    this.current.mainCanvas = canvasJSON.objects;
  }

  public save = () => {
    try {
      if (this.current) {
        const json = { ...this.current };
        this.undos.push({
          type: "UPDATE",
          snapshot: json,
        });
        this.saveMainCanvas();
      }
    } catch (err) {
      console.log(err);
    }
    this.emitStatus("save");
  };

  public undo = throttle(() => {
    if (this.undos.length >= 1) {
      const undo = this.undos.pop();
      if (!undo) {
        return;
      }
      this.redos.push({
        type: "redo",
        snapshot: { ...this.current },
      });
      this.restore(undo);
    }
  }, 100);

  public redo = throttle(() => {
    const redo = this.redos.pop();
    if (!redo) {
      return;
    }
    this.undos.push({
      type: "undo",
      snapshot: { ...this.current },
    });
    this.restore(redo);
  }, 100);

  private restore = (transaction: HistoryCommand) => {
    if (!this.isActive) {
      this.editor.objects.clear();
      const objects = transaction.snapshot.mainCanvas;
      this.current = { ...transaction.snapshot };
      this.isActive = true;
      if (objects) {
        // console.log(`Restore ${objects.length} objects`);
        fabric.util.enlivenObjects(
          objects,
          (enlivenObjects: fabric.Object[]) => {
            enlivenObjects.forEach((enlivenObject: fabric.Object) => {
              if (enlivenObject.type === LayerType.FRAME) {
                // Ignore
              } else if (enlivenObject.type === LayerType.GENERATION_FRAME) {
                // Ignore
                const generationFrame = this.editor.objects.getGenerationFrame();
                if (generationFrame) {
                  generationFrame.left = enlivenObject.left;
                  generationFrame.top = enlivenObject.top;
                }
              } else {
                addObjectToCanvas({
                  canvas: this.canvas,
                  object: enlivenObject,
                });
              }
            });
            this.emitStatus("restore");
          },
          "",
        );
        this.editor.objects?.onShuffledStack();
      }
      this.isActive = false;
    }
  };

  public reset = () => {
    this.redos = [];
    this.undos = [];

    this.emitStatus("reset");
  };

  private emitStatus = (operation: HistoryOperationType) => {
    this.editor.emit<HistoryChangedEditorEventHandler>("history:changed", {
      hasUndo: this.undos.length >= 1,
      hasRedo: this.redos.length > 0,
      operation,
    });
  };
}
