import {
  query,
  startAfter,
  QueryConstraint,
  DocumentSnapshot,
  limit,
  Query,
  DocumentData,
  getDocs,
} from "firebase/firestore";

export class FirebaseDocQueryGenerator<T extends DocumentData> {
  batchSize: number;
  private isFinished = false;
  private lastVisible: DocumentSnapshot<T> | undefined = undefined;
  private baseQuery: Query<T>;
  private initialConstraints: QueryConstraint[];

  /**
   * Constructs a new FirebaseDocQueryGenerator.
   *
   * @param params - The constructor parameters.
   * @param params.baseQuery - The base query to start from.
   * @param params.batchSize - Number of documents to fetch per batch.
   * @param params.queryConstraints - An array of query constraints to apply. Should not include `limit()` or `startAfter()`.
   */
  constructor({
    baseQuery,
    batchSize,
    queryConstraints,
  }: {
    baseQuery: Query<T>;
    batchSize: number;
    queryConstraints: QueryConstraint[];
  }) {
    this.baseQuery = baseQuery;
    this.batchSize = batchSize;
    this.initialConstraints = queryConstraints;
  }

  /**
   * Fetches the next batch of documents.
   *
   * @returns A promise that resolves to an array of documents of type T.
   */
  async getNextBatch(): Promise<T[]> {
    if (this.isFinished) {
      return [];
    }

    // Build the query constraints, ensuring no conflicting `limit` or `startAfter` constraints
    const constraints: QueryConstraint[] = [...this.initialConstraints];

    if (this.lastVisible) {
      constraints.push(startAfter(this.lastVisible));
    }

    constraints.push(limit(this.batchSize));

    const newQuery = query(this.baseQuery, ...constraints);

    const docSnapshots = await getDocs(newQuery);

    if (docSnapshots.empty) {
      this.isFinished = true;
      return [];
    }

    this.lastVisible = docSnapshots.docs[docSnapshots.docs.length - 1];

    return docSnapshots.docs.map((doc) => doc.data());
  }
}
