import { LeftPanelTitleClassName } from "components/constants/class-names";
import { LEFT_PANELS_LIST_ITEMS } from "components/constants/editor-options";
import { useLeftPanelState } from "hooks/use-left-panel-state";
import { ChevronLeft } from "lucide-react";
import React from "react";
import { Tooltip } from "../../../utils/tooltip";

export function Navigate({
  children,
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const { activeLeftPanels, setActiveLeftPanels } = useLeftPanelState();
  const activeLeftPanel = activeLeftPanels[activeLeftPanels.length - 1];
  const item = LEFT_PANELS_LIST_ITEMS[activeLeftPanel];
  const name = children || item?.title || item?.name;
  const description =
    activeLeftPanels.length > 1
      ? LEFT_PANELS_LIST_ITEMS[activeLeftPanels[activeLeftPanels.length - 2]]?.name
      : undefined;

  return activeLeftPanels.length > 1 ? (
    <Tooltip
      triggerChildren={
        <div
          className={`group ${LeftPanelTitleClassName} font-semibold text-lg cursor-pointer hover:text-lime-500 transition-colors`}
          onClick={() => {
            // Pop the last element of the list
            // Immutable equivalent to panels.pop()
            setActiveLeftPanels((panels) => panels.slice(0, -1));
          }}
        >
          <ChevronLeft size={18} />
          <span className="ml-1">{name}</span>
        </div>
      }
      contentChildren={`Go back${description ? ` to ${description}` : ""}`}
      contentProps={{
        align: "start",
      }}
    />
  ) : (
    <div className={`${LeftPanelTitleClassName} font-semibold text-lg`}>{name}</div>
  );
}
