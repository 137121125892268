import React from "react";
import { MessageDialog, MessageDialogClose } from "./message-dialog";
import { classNames } from "@/core/utils/classname-utils";
import { DropdownClassName } from "components/constants/class-names";
import { editorContextStore } from "contexts/editor-context";
import { AppUserSubscriptionTierV2 } from "@/core/common/types";
import {
  AppSubscriptionPlanType,
  subscriptionPlanToPriceMonthly,
} from "@/core/common/types/subscription";
import { DisplaySubscriptionPlan, PlanCard } from "./manage-subscription-dialog";
import { subscribeToPlan } from "components/subscription/checkout-portal";
import { Cross1Icon } from "@radix-ui/react-icons";
import { appSubscriptionPlans } from "components/popup/message-dialog/manage-subscription-dialog";
import { subscriptionTierV2ToRecommendedPlans } from "components/popup/message-dialog/quota-subscribe-dialog";

function EmptySubscriptionPlans({
  message = "You have used all the generation quota.",
}: {
  message?: string;
}) {
  return (
    <div className="w-full flex flex-col items-center gap-8 max-w-[80vw] md:max-w-[500px]">
      <div className="text-2xl text-zinc-300">{message}</div>
      <div className="text-base text-zinc-300 mb-8">
        Thank you for using Flair! You have used all the quote for this month. If you need more
        quota, please contact{" "}
        <a href="mailto:mickey@flair.ai" className="text-lime-500 hover:text-lime-300">
          Flair Support
        </a>
        .
      </div>
    </div>
  );
}

function SubscriptionPlans({
  subscriptionPlans,
  message = "Upgrade subscription to increase your generate and upload quota.",
}: {
  subscriptionPlans: DisplaySubscriptionPlan[];
  message?: string;
}) {
  return (
    <div className="w-full flex flex-col items-center gap-8">
      <div className="text-2xl">{message}</div>
      <div
        className={classNames(
          "py-2 grid grid-flow-col auto-cols-auto items-stretch justify-center gap-4",
        )}
      >
        {subscriptionPlans.map((subscriptionPlan, index) => (
          <PlanCard
            key={subscriptionPlan.id || index}
            subscriptionPlan={subscriptionPlan}
            recommended={index === 0}
            disabled={false}
            canUnsubscribe={false}
            onClickPrimaryButton={async () => {
              const { id } = subscriptionPlan;

              if (
                !id ||
                id === AppSubscriptionPlanType.Free ||
                id === AppSubscriptionPlanType.Enterprise
              ) {
                return;
              }

              const priceId = subscriptionPlanToPriceMonthly[id];

              await subscribeToPlan(priceId);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export interface MobileQuotaSubscribeDialogContentProps {
  title?: string;
  header?: string;
}

export const MobileQuotaSubscribeDialogContent = React.forwardRef(
  function QuotaSubscribeDialogContent(
    { title = "No quota left.", header }: MobileQuotaSubscribeDialogContentProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) {
    const userQuotas = editorContextStore((state) => state.userQuotas);
    const tierV2 = userQuotas?.tierV2 || AppUserSubscriptionTierV2.Free;
    const subscriptionPlans = React.useMemo(
      () =>
        subscriptionTierV2ToRecommendedPlans[tierV2].map((planId) => appSubscriptionPlans[planId]),
      [tierV2],
    );

    return (
      <div
        ref={forwardedRef}
        className={classNames(
          DropdownClassName,
          "rounded-2xl max-h-[90vh] px-6 py-4 flex flex-col items-center overflow-hidden",
        )}
      >
        <MessageDialogClose className="absolute right-4">
          <Cross1Icon className="text-zinc-500 hover:text-zinc-200 cursor-pointer transition-colors" />
        </MessageDialogClose>
        <div className="w-full text-zinc-500 mb-6">{title}</div>
        {subscriptionPlans.length > 0 ? (
          <SubscriptionPlans subscriptionPlans={subscriptionPlans} message={header} />
        ) : (
          <EmptySubscriptionPlans message={header} />
        )}
      </div>
    );
  },
);

export interface QuotaSubscribeDialogProps extends MobileQuotaSubscribeDialogContentProps {
  onClose?: () => void;
}

export function QuotaSubscribeDialog({ onClose, ...props }: QuotaSubscribeDialogProps) {
  return (
    <MessageDialog
      defaultOpen={true}
      onOpenChange={(value) => {
        if (!value) {
          onClose?.();
        }
      }}
      contentChildren={<MobileQuotaSubscribeDialogContent {...props} />}
    />
  );
}
