import React from "react";
import { classNames } from "@/core/utils/classname-utils";

import { FlairGenerateTemplateItem } from "@/core/common/types/template-generator";
import { useInView } from "react-intersection-observer";
import { LeftPanelImageGridItem } from "../image-grid-item";
import {
  postprocessTemplatesBatch,
  useGenerateTemplatesContext,
} from "./generate-templates-context";
import {
  handleSelectFlairGenerateTemplateItem,
  getTemplateItemsArray,
  getTemplatesMaxIndex,
} from "components/panels/panel-items/components/generate-templates/generate-templates-grid";

const ImageGridItem = React.forwardRef(
  (
    {
      id,
      item,
    }: {
      id?: string;
      item: FlairGenerateTemplateItem;
    },
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <LeftPanelImageGridItem
        ref={forwardedRef}
        id={id}
        delayDuration={0}
        imgSrc={item.image_src}
        imgAlt={item.id}
        onClick={() => {
          handleSelectFlairGenerateTemplateItem(item);
        }}
        hoverCardContent={
          <>
            <span>{item.prompt}</span>
            <span className="mt-2 text-zinc-500">Click to use this prompt template.</span>
          </>
        }
      />
    );
  },
);

export const GenerateTemplatesImageGrid = React.forwardRef(
  (
    {
      className,
      ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { items, setItems, templatesGenerator, semanticItems, setSemanticItems, searchString } =
      useGenerateTemplatesContext();

    const [lastRowRef, lastRowInView] = useInView();

    React.useEffect(() => {
      if (lastRowInView && templatesGenerator && searchString === "") {
        templatesGenerator.getNextBatch()?.then((batch) => {
          setItems((prevItems) => ({
            ...prevItems,
            ...postprocessTemplatesBatch(batch, getTemplatesMaxIndex(prevItems)),
          }));
        });
      }
    }, [lastRowInView, setItems, templatesGenerator, searchString]);

    const templateItems = React.useMemo(() => getTemplateItemsArray(items), [items]);

    const templateSemanticItems = React.useMemo(
      () => getTemplateItemsArray(semanticItems),
      [semanticItems],
    );

    return (
      <div
        ref={forwardedRef}
        className={classNames("grid grid-cols-2 gap-2", className ?? "")}
        {...props}
      >
        {templateItems.map(([key, item], index) => (
          <ImageGridItem
            ref={index === templateItems.length - 1 ? lastRowRef : undefined}
            id={`generate-templates-${key}`}
            key={key}
            item={item}
          />
        ))}
        {templateSemanticItems.map(([key, item], index) => (
          <ImageGridItem
            ref={index === templateSemanticItems.length - 1 ? lastRowRef : undefined}
            id={`generate-templates-${key}`}
            key={key}
            item={item}
          />
        ))}
      </div>
    );
  },
);
