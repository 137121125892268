import type { FabricCanvas } from "@/core/common/interfaces";
import { LayerType } from "@/core/common/layers";
import { EditorCanvasJson, EditorConfig, EditorObjectJson } from "@/core/common/types";
import { Editor } from "@/core/editor";
import { addObjectToCanvas } from "components/utils/add-to-canvas-utils";
import { fabric } from "fabric";
import { isFabricObject, isStaticImageObject } from "./type-guards";

export type SaveMainCanvasArgs = {
  canvas: FabricCanvas<fabric.Canvas>;
  config: EditorConfig;
};

export async function saveCanvasJson({
  canvas,
  config,
}: SaveMainCanvasArgs): Promise<EditorCanvasJson> {
  const canvasJSON = canvas.toJSON(config.propertiesToInclude) as any as EditorCanvasJson;

  await Promise.all(
    canvasJSON.objects.map((object: EditorObjectJson) => {
      if (!object.clipPath) {
        return Promise.resolve();
      }

      return new Promise<void>((resolve) => {
        fabric.util.enlivenObjects(
          [object.clipPath],
          function (arg1: fabric.Object[]) {
            object.clipPath = arg1[0];

            resolve();
          },
          "",
        );
      });
    }),
  );

  return canvasJSON;
}

export type LoadCanvasJsonArgs = {
  editor: Editor;
  canvasJson: EditorCanvasJson;
};

export function loadCanvasJson({
  editor,
  canvasJson,
}: LoadCanvasJsonArgs): Promise<fabric.Object[]> {
  return new Promise((resolve, reject) => {
    try {
      editor.objects.clear();
      const objects = canvasJson.objects;

      if (!objects) {
        return resolve([]);
      }

      console.log(objects);

      // console.log(`Restore ${objects.length} objects`);
      fabric.util.enlivenObjects(
        objects,
        (enlivenObjects: fabric.Object[]) => {
          enlivenObjects.forEach((enlivenObject: fabric.Object) => {
            if (enlivenObject.type === LayerType.FRAME) {
              // Ignore
            } else if (enlivenObject.type === LayerType.GENERATION_FRAME) {
              const generationFrame = editor.objects.getGenerationFrame();
              if (generationFrame) {
                generationFrame.left = enlivenObject.left;
                generationFrame.top = enlivenObject.top;
              }
            } else {
              addObjectToCanvas({
                canvas: editor.canvas.canvas,
                object: enlivenObject,
              });
            }
          });

          editor.objects?.onShuffledStack();

          resolve(enlivenObjects);
        },
        "",
      );
    } catch (error) {
      reject(error);
    }
  });
}

export type UpdateObjectFromJsonArgs = {
  object: unknown;
  objectJson: EditorObjectJson;
};

function setImageObjectSrc(object: fabric.StaticImage, src: string) {
  return new Promise<fabric.StaticImage>((resolve, reject) => {
    try {
      object.setSrc(src, () => resolve(object));
    } catch (error) {
      reject(error);
    }
  });
}

export function updateObjectFromJson({ object, objectJson }: UpdateObjectFromJsonArgs) {
  if (!isFabricObject(object)) {
    return Promise.resolve(undefined);
  }

  const promises: Promise<unknown>[] = [];

  Object.entries(objectJson).forEach(([key, value]) => {
    if (key === "src" && isStaticImageObject(object)) {
      promises.push(setImageObjectSrc(object, value));
    } else {
      object.set(key as unknown as keyof fabric.Object, value);
    }
  });

  return Promise.all(promises).then(() => object);
}

export function updateObjectsFromCanvasJson({ editor, canvasJson }: LoadCanvasJsonArgs) {
  canvasJson.objects.forEach((objectJson) => {
    if (!objectJson) {
      return;
    }

    const { id } = objectJson;
    if (!id) {
      return;
    }

    const object = editor.objects.findOneById(id);

    if (!isFabricObject(object)) {
      return;
    }

    Object.entries(objectJson).forEach(([key, value]) => {
      if (key === "src" && isStaticImageObject(object)) {
        object.setSrc(value);
      } else {
        object.set(key as unknown as keyof fabric.Object, value);
      }
    });
  });
}
