import { fabric } from "fabric";
import { LayerType } from "@/core/common/layers";
import { IScene, SampleProjectScene } from "@/core/common/scene";
import {
  StaticImageElementType,
  StatcImageVideoGenerationMetadata,
  StaticImageObjectVideoGenerationResult,
} from "@/core/common/types/elements";
import { PromptAutocompleteType } from "components/text-editor/prompt-autocomplete";
import {
  type AppUserQuotas,
  type EditorAsset,
  type UserProject,
  type PastGeneration,
  type PromptWord,
  type PromptWordType,
  type PromptTemplate,
  type GenerateTemplate,
  type GenerateTemplateItem,
  type GenerateTemplateItemV1,
  type GenerateTemplateItemV2,
  type EditorImageAsset,
  type EditorAssetType,
} from "@/core/common/types";
import { StaticImageObjectHed } from "@/core/common/types/hed-image-element";
import { isVideoGenerationStatus } from "@/core/common/types/video";

export function isFabricObject(object: any): object is fabric.Object {
  return object && object.setCoords;
}

export function isFabricObjectWithAsset(
  object: any,
): object is fabric.Object & { asset: EditorAsset } {
  return isFabricObject(object) && (object as any).asset?.path;
}

export function isEditorImageAsset(asset: any): asset is EditorImageAsset {
  const validTypes: EditorAssetType[] = ["image-storage", "image-url"];

  return asset && validTypes.includes(asset.type) && typeof asset.path === "string";
}

export function isGenerationFrame(object: fabric.Object): object is fabric.GenerationFrame {
  return object?.type === LayerType.GENERATION_FRAME;
}

export function isBackgroundImage(object: fabric.Object): object is fabric.BackgroundImage {
  return object?.type === LayerType.BACKGROUND_IMAGE;
}

export function isImageObject(object: any): object is fabric.Image {
  return Boolean(object) && (object.type === "Image" || object.type === "StaticImage");
}

export function isStaticImageObject(object: any): object is fabric.StaticImage {
  return Boolean(object) && object.type === "StaticImage";
}

export function isStaticImageObjectGenerated(object: any): object is fabric.StaticImage & {
  generationId: string;
} {
  return isStaticImageObject(object) && Boolean(object.generationId);
}

export function isStaticImageObjectVideoGenerationResult(
  object: any,
): object is StaticImageObjectVideoGenerationResult {
  return (
    isStaticImageObject(object) &&
    typeof object?.metadata?.videoGenerationId === "string" &&
    isVideoGenerationStatus(object?.metadata?.videoGenerationStatus)
  );
}

export function isStaticImageObjectHed(object: any): object is StaticImageObjectHed {
  return isStaticImageObject(object) && object.metadata?.imageType === StaticImageElementType.Hed;
}

export function isStaticImageObjectColor(object: any): object is fabric.StaticImage {
  return isStaticImageObject(object) && object.metadata?.imageType === StaticImageElementType.Color;
}

export function isStaticImageObjectUploaded(object: any): object is fabric.StaticImage {
  return (
    isStaticImageObject(object) &&
    !object.generationId &&
    object.metadata?.imageType !== StaticImageElementType.Hed
  );
}

export function isGroupObject(object: any): object is fabric.Group {
  return Boolean(object) && object.type === LayerType.GROUP;
}

export function isActiveSelection(object: any): object is fabric.ActiveSelection {
  return Boolean(object) && object.type === LayerType.ACTIVE_SELECTION;
}

export function isUserProject(project: any): project is UserProject {
  return (
    project &&
    typeof project.id === "string" &&
    typeof project.displayName === "string" &&
    project.isDeleted !== true
  );
}

export function isAppUserQuota(quotas: any): quotas is AppUserQuotas {
  return (
    quotas &&
    quotas?.id &&
    typeof quotas?.numProjects === "number" &&
    typeof quotas?.numRenders === "number"
  );
}

export function isScene(scene: any): scene is IScene {
  return scene && typeof scene.id === "string" && typeof scene.version === "string";
}

export function getScene(scene: any) {
  if (!isScene(scene)) {
    return undefined;
  }
  if (!scene.objects) {
    scene.objects = {};
  }
  return scene;
}

export function isSampleProjectScene(scene: any): scene is SampleProjectScene {
  return scene && scene.objects;
}

export function isPastGeneration(object: any): object is PastGeneration {
  return (
    object &&
    typeof object.prompt === "string" &&
    typeof object.imgPath === "string" &&
    object.imgPath
  );
}

function isPromptWord(obj: any): obj is PromptWord {
  const validAutocompleteTypes: PromptAutocompleteType[] = [
    "template",
    "subject",
    "placement",
    "surrounding",
    "background",
    "custom",
  ];
  const validWordTypes: PromptWordType[] = ["input", "fixed"];

  return (
    obj &&
    validWordTypes.includes(obj.type) &&
    typeof obj.value === "string" &&
    (!obj.prefix || typeof obj.prefix === "string") &&
    (!obj.placeholder || typeof obj.placeholder === "string") &&
    (!obj.autocompleteType || validAutocompleteTypes.includes(obj.autocompleteType)) &&
    (!obj.autocompleteValues ||
      (Array.isArray(obj.autocompleteValues) &&
        obj.autocompleteValues.every((val: string) => typeof val === "string"))) &&
    (!obj.isAutoFilled || typeof obj.isAutoFilled === "boolean") &&
    (!obj.valueBeforeAutoFill || typeof obj.valueBeforeAutoFill === "string")
  );
}

export function isPromptTemplate(obj: any): obj is PromptTemplate {
  return (
    obj &&
    Array.isArray(obj.words) &&
    obj.words.every(isPromptWord) &&
    (!obj.style || typeof obj.style === "string")
  );
}

export function isGenerateTemplate(obj: any): obj is GenerateTemplate {
  return (
    obj &&
    isPromptTemplate(obj.prompt) &&
    (!obj.referenceImage || typeof obj.referenceImage === "string")
  );
}

export function isGenerateTemplateItemV1(obj: any): obj is GenerateTemplateItemV1 {
  return (
    obj &&
    typeof obj.imgSrc === "string" &&
    (!obj.alt || typeof obj.alt === "string") &&
    isGenerateTemplate(obj.template) &&
    (!obj.score || typeof obj.score === "number") &&
    (!obj.tags ||
      (Array.isArray(obj.tags) && obj.tags.every((tag: string) => typeof tag === "string")))
  );
}

export function isGenerateTemplateItemV2(obj: any): obj is GenerateTemplateItemV2 {
  return (
    obj &&
    typeof obj.id === "string" &&
    isPromptTemplate(obj.promptTemplate) &&
    typeof obj.sceneJSONFilePath === "string"
  );
}

export function isGenerateTemplateItem(obj: any): obj is GenerateTemplateItem {
  return isGenerateTemplateItemV1(obj) || isGenerateTemplateItemV2(obj);
}

export function isTouchEvent(event: any): event is TouchEvent {
  return (event as TouchEvent)?.type.includes("touch");
}
