import { CheckIcon, CopyIcon } from "@radix-ui/react-icons";
import { classNames } from "@/core/utils/classname-utils";
import { copyToClipboard } from "@/core/utils/clipboard-utils";
import React from "react";

export const CopyButton = React.forwardRef(
  (
    {
      textToCopy = "",
      className = "",
      onClick,
      ...props
    }: {
      textToCopy?: string;
    } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const [copied, setCopied] = React.useState(false);
    const timeoutRef = React.useRef<NodeJS.Timeout | undefined>();

    const handleCopy = React.useCallback(() => {
      copyToClipboard(textToCopy);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      setCopied(true);

      timeoutRef.current = setTimeout(() => {
        setCopied(false);
      }, 1000);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, [textToCopy]);

    return (
      <button
        ref={forwardedRef}
        className={classNames(
          "text-xs text-zinc-500 hover:text-zinc-300 flex flex-row items-center justify-center gap-1 px-1.5 py-1 bg-transparent hover:bg-zinc-700 active:bg-zinc-900 rounded transition-colors",
          className,
        )}
        onClick={(e) => {
          handleCopy();

          onClick?.(e);
        }}
        {...props}
      >
        {copied ? <CheckIcon /> : <CopyIcon />}
        {copied ? "Copied!" : "Copy"}
      </button>
    );
  },
);
