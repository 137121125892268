import React from "react";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { classNames } from "@/core/utils/classname-utils";

import styles from "./right-click-menu.module.css";
import { editorContextStore } from "contexts/editor-context";
import { fabric } from "fabric";
import { IEvent } from "fabric/fabric-impl";
import { RealTimeRenderCheckPointerOverHandler, RightClickMenuTypes } from "@/core/common/types";
import { EditorRightClickMenuObject } from "./object-menu";
import { EditorRightClickMenuImage } from "./image-menu";
import { EditorRightClickMenuDefault } from "./default-menu";
import { EditorRightClickMenuActiveSelection } from "./selections-menu";
import { Editor } from "@/core/editor";
import { EditorRightClickMenuRealTimeRenderResult } from "./realtime-render-result-menu";

const objectTypeToRightClickMenuType: Record<string, RightClickMenuTypes> = {
  StaticImage: "static-image",
  StaticVideo: "object",
  activeSelection: "active-selection",
  Group: "object",
  Object: "object",
};

const rightClickMenuTypeToMenu: Record<RightClickMenuTypes, (params: unknown) => JSX.Element> = {
  object: EditorRightClickMenuObject,
  "static-image": EditorRightClickMenuImage,
  default: EditorRightClickMenuDefault,
  "active-selection": EditorRightClickMenuActiveSelection,
  "realtime-render-result": EditorRightClickMenuRealTimeRenderResult,
};

function isHoverOnObject(canvas?: fabric.Canvas, e?: Event, object?: fabric.Object) {
  if (!canvas || !e || !object) {
    if (!canvas) {
      console.log("Canvas is invalid");
    }
    if (!e) {
      console.log("Event is invalid");
    }
    if (!object) {
      console.log("Object is invalid");
    }
    return false;
  }
  const pointer = canvas.getPointer(e);
  return object.containsPoint(new fabric.Point(pointer.x, pointer.y), undefined, true);
}

function isHoverOnRealTimeRenderResult() {
  return editorContextStore.getState().realtimeRenderIsPointerOverResultRef.current;
}

function getHoveredObject(event?: Event, canvas?: fabric.Canvas) {
  if (!event || !canvas) {
    if (!event) {
      console.log("Event is undefined");
    }
    if (!canvas) {
      console.log("Canvas is undefined");
    }
    return undefined;
  }
  return canvas.findTarget(event, false);
}

function handlePointerHover(
  canvas: fabric.Canvas,
  pointerEventRef: { current: Event | undefined },
): {
  menuType: RightClickMenuTypes;
  hoveredObject?: fabric.Object;
} {
  if (isHoverOnRealTimeRenderResult()) {
    return {
      menuType: "realtime-render-result",
    };
  }

  let menuType: RightClickMenuTypes = "default";
  const hoveredObject = getHoveredObject(pointerEventRef.current, canvas);
  const type = hoveredObject?.type;

  menuType = type ? (objectTypeToRightClickMenuType[type] ?? "object") : "default";

  return {
    menuType,
    hoveredObject,
  };
}

export function getEditorRightClickMenuFromObjectType(type?: string) {
  if (!type) {
    return null;
  }
  const rightClickMenuType = objectTypeToRightClickMenuType[type];
  return rightClickMenuTypeToMenu[rightClickMenuType];
}

export function EditorRightClickMenu({ children }: { children?: React.ReactNode }) {
  const editor = editorContextStore((state) => state.editor);

  const isOpenRef = React.useRef(false);
  const pointerEventRef = React.useRef<PointerEvent | undefined>();

  const [rightClickMenuType, setRightClickMenuType] =
    React.useState<RightClickMenuTypes>("default");

  React.useEffect(() => {
    const handleMouseOver = (e: IEvent<Event>) => {
      const canvas = editor?.canvas.canvas;
      if (e.target && canvas) {
        const contextTop = (canvas as any)?.contextTop;
        if (e.target !== canvas.getActiveObject()) {
          (e.target as any)._renderControls?.(contextTop, {
            hasControls: false,
          });
        }
      }
    };
    const handleMouseOut = (e: IEvent<Event>) => {
      const canvas = editor?.canvas.canvas;
      if (e.target && canvas) {
        const contextTop = (canvas as any)?.contextTop;
        if (e.target !== canvas.getActiveObject()) {
          canvas.clearContext(contextTop);
        }
      }
    };
    editor?.canvas.canvas.on("mouse:over", handleMouseOver);
    editor?.canvas.canvas.on("mouse:out", handleMouseOut);
    return () => {
      editor?.canvas.canvas.off("mouse:over", handleMouseOver);
      editor?.canvas.canvas.off("mouse:out", handleMouseOut);
    };
  }, [editor?.canvas.canvas]);

  const onOpenChange = React.useCallback((isOpen: boolean) => {
    isOpenRef.current = isOpen;
    if (isOpen) {
      const editor = editorContextStore.getState().editor;
      const objects = editor?.objects;
      const canvas = editor?.canvas.canvas;
      if (!objects || !canvas || !pointerEventRef.current) {
        return;
      }

      editor.emit<RealTimeRenderCheckPointerOverHandler>("realtime-render:check-pointer-over", {
        x: pointerEventRef.current.clientX,
        y: pointerEventRef.current.clientY,
      });

      const { menuType, hoveredObject } = handlePointerHover(canvas, pointerEventRef);

      // let menuType: RightClickMenuTypes = 'default';
      const activeObject = canvas.getActiveObject();
      // const hoveredObject = getHoveredObject(pointerEventRef.current, canvas);
      const id = hoveredObject?.id;
      const type = hoveredObject?.type;

      // menuType = type ? (
      //     objectTypeToRightClickMenuType[type] ?? 'object'
      // ) : 'default';

      if (id && type && id !== activeObject?.id) {
        objects.select(id);
      }

      setRightClickMenuType(menuType);
    }
  }, []);

  const MenuItems = rightClickMenuTypeToMenu[rightClickMenuType];

  React.useEffect(() => {
    const onPointerMove = (e: PointerEvent) => {
      pointerEventRef.current = e;
      if (isOpenRef.current) {
        (editor?.canvas.canvas as any)._onMouseMove?.(e);
      }
    };
    window.addEventListener("pointermove", onPointerMove);
    return () => {
      window.removeEventListener("pointermove", onPointerMove);
    };
  }, [editor?.canvas.canvas]);

  return (
    <ContextMenu.Root onOpenChange={onOpenChange}>
      <ContextMenu.Trigger asChild>{children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className={styles.ContextMenuContent}>
          <MenuItems />
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
}
