import { clamp } from "lodash";

import { CustomModelType, FrontendDisplayTemplateType } from "@/core/common/types";
import { getObjectEntries } from "@/core/utils/type-utils";
import { debugLog } from "@/core/utils/print-utilts";

export const ProductDatasetPlaceholderImages = [
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e0efac7d-10ff-4fc8-5f68-9d08e5b08c00/1024",
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3bff8963-7094-4c78-100a-17ad54fecf00/1024",
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5a9d9cf3-b83c-4f98-4860-ffd66c6bf600/1024",
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4745bee1-e765-4281-4a43-945233272b00/1024",
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/58c13a06-1dc2-4c11-9d93-2310d24a9a00/1024",
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8e47d645-00b0-4d61-054f-ca7a08423100/1024",
  "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b787bdbe-7708-404f-f6b0-6bd9a4a3b000/1024",
];

export function getCustomModelTrainingInputFromSliderValue({
  percent,
  sliderOptions,
}: {
  percent: number;
  sliderOptions: CustomModelTrainingInputSliderOptions;
}) {
  return Object.fromEntries(
    getObjectEntries(sliderOptions).map(([key, options]) => {
      const index = clamp(Math.round(percent * options.length - 1), 0, options.length - 1);

      debugLog(`Get training input ${key} from ${percent} at ${index}: ${options[index]}`);

      return [key, options[index]];
    }),
  ) as Record<keyof CustomModelTrainingInputSliderOptions, number>;
}

export interface CustomModelTrainingInputSliderOptions {
  learningRate: number[];
  steps: number[];
  iterMultipler: number[];
}

export interface CustomModelTrainingInputSliderConfigs {
  defaultValue: number;
  maxNumSteps: number;
  options: CustomModelTrainingInputSliderOptions;
}

export type CustomModelWorkflow = {
  label: string;
  subline: string;
  imageUrl: string;
  hoverText: string;
  dataTabLabel?: string;
  trainingSliderConfigs: CustomModelTrainingInputSliderConfigs;
  moodboardExampleUrls?: string[];
  moodboardInfoboxText?: string | string[];
  moodboardUploadLabel?: string;
  playgroundPrompt?: string;
  playgroundInfoboxModelDescription?: string;
};

export const defaultCustomModelTrainingInputSliderOptions: CustomModelTrainingInputSliderConfigs = {
  defaultValue: 5,
  maxNumSteps: 10,
  options: {
    learningRate: [2e-4, 1e-4, 8e-5],
    steps: [500, 1000, 1500, 2000, 2500],
    iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0],
  },
};

const defaultProductTrainingSliderConfig: CustomModelTrainingInputSliderConfigs = {
  defaultValue: 8,
  maxNumSteps: 10,
  options: {
    learningRate: [1e-4, 9e-5, 8e-5, 7e-5],
    steps: [2000, 2500, 3000, 3500, 4000, 4500],
    iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
  },
};

const defaultFashionTrainingSliderConfig: CustomModelTrainingInputSliderConfigs = {
  defaultValue: 9,
  maxNumSteps: 10,
  options: {
    learningRate: [1e-4, 9e-5, 8e-5, 7e-5],
    steps: [2000, 2500, 3000, 3500, 4000, 4500],
    iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0, 2.2, 2.5],
  },
};

const defaultStyleTrainingSliderConfig: CustomModelTrainingInputSliderConfigs = {
  defaultValue: 5,
  maxNumSteps: 10,
  options: {
    learningRate: [2e-4, 1e-4, 8e-5],
    steps: [500, 1000, 1500, 2000, 2500],
    iterMultipler: [1.0, 1.2, 1.5, 1.8, 2.0],
  },
};

const defaultMoodboardInfoboxText = (name: string) => {
  return [
    `Upload 3 or more photos of ${name}. You can take these photos yourself or find them online`,
    "Results get better with more photos from different angles",
  ];
};

const defaultHoverText = (name: string) => {
  return `Create a custom model that preserves the details of your ${name}.`;
};

export const customModelWorkflowData: {
  [type in FrontendDisplayTemplateType]?: CustomModelWorkflow;
} = {
  [FrontendDisplayTemplateType.Product]: {
    label: "Product",
    dataTabLabel: "Product photos",
    subline: "Train model based on your product",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/348762d6-a9b9-44dc-2986-e4aa74209c00/1024",
    hoverText: defaultHoverText("product"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("product"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/17bab463-c909-4f2e-9edd-9e1d5d8b7900/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d80c93e8-8ccd-4ace-9970-18ac45afdf00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/bce0a7d5-a4cd-44f7-cbc5-aa45a703e700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/54842d7f-ac67-4ee0-bf4a-706dc2db3000/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/77ee8f8b-f73d-47a2-78bb-b3bc22b4c600/1024",
    ],
    playgroundPrompt: "Photo of @MENTION_MODEL in front of a light brown background, 35mm lens",
  },
  [FrontendDisplayTemplateType.Fashion]: {
    label: "Fashion & Wearables",
    dataTabLabel: "Fashion photos",
    subline: "Train model based on your fashion item",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6ec46ec0-14d8-4930-f06f-8df49c0fa300/1024",
    hoverText: defaultHoverText("clothing"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("fashion"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/345d39d0-6ba5-47b5-ba5a-d8f31ed04a00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/cced9c26-897b-4dcc-a3ee-99d3bf2eaa00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/613e9b50-c5e3-4f88-33f9-00a3d2aab500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/26c9b3c5-5695-4b02-1d74-1fd122384700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6a832c02-b5a0-449c-4729-12394cc6da00/1024",
    ],
    playgroundPrompt:
      "Tall model wearing @MENTION_MODEL walking on the street in the city towards the camera, 80mm lens",
    playgroundInfoboxModelDescription: "a checkered beige scarf",
  },
  [FrontendDisplayTemplateType.Style]: {
    label: "Aesthetic",
    subline: "Create artistic model based on your style",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c5cd42e6-6332-4774-6676-0a665d3b6400/1024",
    hoverText: "Create a model that learns a style based on your moodboard.",
    trainingSliderConfigs: defaultStyleTrainingSliderConfig,
    moodboardInfoboxText:
      "Upload 3 photos with a consistent style aesthetic - results get better with more photos.",
    moodboardExampleUrls: [
      // TODO add moodboard examples
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/489322ea-7ab5-4a13-5cb6-b9839feb5500/512",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/880b3cfc-9f03-4d2f-cceb-30e94947e300/512",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d51956c0-48a7-478a-8918-d1eaf3777a00/512",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b9a52421-86be-4223-31df-d558bfd1ad00/512",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6105e5c1-ced2-42bf-367b-37c1fc303700/512",
    ],
  },
  [FrontendDisplayTemplateType.Furniture]: {
    label: "Furniture",
    subline: "Train model based on your furniture item",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/881cb760-9035-4df0-40c4-6e1819cd3e00/1024",
    hoverText: defaultHoverText("furniture"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("furniture item"),
    moodboardUploadLabel: "Add at least 3 furniture images",
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/62861f02-b5e0-49fa-4d52-9997bb6f8f00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e8b325d5-0172-4118-3d3c-413b934eb300/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b2d95928-fdcb-40da-e2eb-2fbc35f09100/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f65ba150-9a6e-4605-0276-906fe9372300/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/0ad9806a-3b23-4ab9-7db1-b8969aa9cb00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1dffa114-e53e-4ebc-c2fd-9f29ae5e9500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/33fe72a0-9117-48f3-58ff-25970aa54e00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a77dd2cd-9052-458b-dbda-762b20395f00/1024",
    ],
    playgroundPrompt:
      "a photo of @MENTION_MODEL [describe furniture] sitting in a large spacious modern room with natural lighting, 60 mm lens, professional product photography with contrasting highlight and shadows on the background",
    playgroundInfoboxModelDescription: "an upholstered lounge armchair",
  },
  [FrontendDisplayTemplateType.Tech]: {
    label: "Tech",
    subline: "Train model based on your tech item",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/02fe7e7b-bc83-4309-521f-e8624a47e500/1024",
    hoverText: defaultHoverText("tech product"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("tech product"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5c40a7e7-2040-4128-7fb4-818538531200/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/52e11097-f7c7-46a0-175c-2bb228fd8b00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4382334d-e3a1-43b9-c3a1-908e5407fc00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d85f8e0f-e85d-4a56-d730-d6490f2e6500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b0dae43f-b265-4778-e9aa-40d3c4263000/1024",
    ],
    playgroundPrompt:
      "photo of a @MENTION_MODEL [describe tech product model] the product is up in the air surrounded in air with different colored big and small shiny and matte balls flying around in front of a soft dark background, 60 mm lens, professional product photography with contrasting highlight and shadows on the background",
    playgroundInfoboxModelDescription: "a high tech mixed reality headset",
  },
  [FrontendDisplayTemplateType.Food]: {
    label: "Food",
    subline: "Train model based on your Food creation",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1cf4fae7-736a-4f7b-30b4-bf4157bb3000/1024",
    hoverText: defaultHoverText("food item"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("food item"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4841fcb4-b8b3-4915-2c7f-11ad7cee8900/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/828dbd1a-7093-4fc7-76e8-8e52c3cfc500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3a481908-1321-433a-cefa-1514d776fb00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/36627059-6e84-4c39-c64c-c19a30ba8000/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/fb1d99b3-429c-4f57-a7fc-e276a25c3c00/1024",
    ],
    playgroundPrompt:
      "photo of a @MENTION_MODEL [describe food] 60 mm lens, professional food photography with contrasting highlight and shadows on the background",
    playgroundInfoboxModelDescription: "a delicious looking cakepop",
  },
  [FrontendDisplayTemplateType.VirtualModel]: {
    label: "Virtual Model",
    subline: "Train model based on your model",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e1693755-2fb2-4b53-37d3-61061c3b2d00/1024",
    hoverText: defaultHoverText("model"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("model"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/90d0e30d-e575-4705-528a-e1dbd458f700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6042e274-71fc-48dd-d645-5fd8f80a2c00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/233a6fd9-12da-4f5e-afaa-9a0a3ccc1700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/34dda41c-aaad-4163-4f35-3b89e416e600/1024",
    ],
    playgroundPrompt:
      "a photo of a woman @MENTION_MODEL [describe model] posing on beige fabric against a beige vogue photoshoot in overexposed light and shadows, motion photography, old money 35mm lens, professional fashion photography",
  },
  [FrontendDisplayTemplateType.Footwear]: {
    label: "Footwear",
    subline: "Train model based on your shoes",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1fb13f64-30af-4c7d-3950-3fa18730ce00/1024",
    hoverText: defaultHoverText("shoes"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("shoes"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/aa81d199-14bf-4af1-ddaa-d14ca2971b00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/301dc9ab-824a-4a0a-9287-3b96d504c600/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ab4bae9d-e22c-4a90-bb47-ecdbe405b400/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/dc021344-1d9c-4b39-ca4d-5eb2a1346500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f8ce7d1d-237b-4ac9-5b88-00805b22a600/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1dba4ecd-6d5f-4cd1-cdf1-1df6a37dff00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/78ca2794-65ee-4144-778c-238a477af600/1024",
    ],
    playgroundPrompt:
      "a pair @MENTION_MODEL [describe shoes] shoes floating on a cloud front of a  light blue gradient  background with shadows on it. 60mm lens, professional studio photography",
    playgroundInfoboxModelDescription: "a futuristic looking running shoe",
  },

  [FrontendDisplayTemplateType.Jewelry]: {
    label: "Jewelry & Accessories",
    subline: "Train model based on your jewelry",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery//i1XPW6iC_chU01_6tBPo8Q/ab8afae4-f9ec-4f10-a8a1-4a0c024f8b00/1024",
    hoverText: defaultHoverText("jewelry"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("jewelry"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/dbef0885-c8c7-44fd-47e2-b7da6f154c00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a902fe7a-c21b-49c6-3308-a7419bf08700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a0525d9c-723b-4ebc-0ff7-f89db14de700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/005f31ad-6168-4efc-c93d-5e4ee64a6200/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/0aa69fca-2fcc-442b-d0f4-76dbafd34d00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/59c736e6-e71c-4aa1-d00c-6bb5c0dd0200/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5119411c-72ec-4ebe-f195-8c2080e28300/1024",
    ],
    playgroundPrompt:
      "photo of a @MENTION_MODEL sitting on a piece of white concrete slab surrounded by dry white branches and flowers in front of a soft white background, 60 mm lens, professional studio photography with contrasting highlight and shadows on the background",
    playgroundInfoboxModelDescription: "a diamond engagement ring",
  },
  [FrontendDisplayTemplateType.Bags]: {
    label: "Bags & Purses",
    subline: "Train model based on your bags design",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5fbbd38b-f680-4901-634a-d4f73f6e2e00/1024",
    hoverText: defaultHoverText("bag"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("bag"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2557731c-852a-47bd-b808-3b8eb00a8100/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/236e0054-6456-424a-cec6-5d0427e25200/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/bee96321-c0ab-46b3-f359-37c8371dd700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/343240a3-5c27-4f14-5c8d-66d08f041600/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9a512ad3-7dc2-4e3e-d3ee-c22739ac8d00/1024",
    ],
    playgroundPrompt:
      "photo of an [describe model] wearing a @MENTION_MODEL [describe bag] shoulder bag posing in front of a soft rust colored background, professional fashion photography",
    playgroundInfoboxModelDescription: "a cognac color leather handbag",
  },
  [FrontendDisplayTemplateType.HomeDecor]: {
    label: "Home Decor",
    subline: "Train model based on your home decor",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/73c51e30-5c43-4f8f-6ed2-fd1b11953f00/1024",
    hoverText: defaultHoverText("home decor"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("home decor"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ab5f9116-4870-4e89-eb9f-98fc53873c00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a0cc7d39-1987-497a-7e29-444bc35b3800/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1f239632-c4b6-4205-8b19-ca2583220200/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8a798be4-61ac-4fb7-78bb-5f97c5c08400/1024",
    ],
    playgroundPrompt:
      "photo of a @MENTION_MODEL [describe home decor] in a modern living room with a white background, 60mm lens, professional studio photography",
    playgroundInfoboxModelDescription: "a modern looking coffee table",
  },
  [FrontendDisplayTemplateType.Toys]: {
    label: "Toys",
    subline: "Train model based on your toy design",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b9974c02-a9fd-4e29-d264-5941fce0f800/1024",
    hoverText: defaultHoverText("toy"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("toy"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/bce04a5c-94ae-4abf-99ed-51c3b2a0db00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/8bfa5fc3-14e8-4b51-efc7-72728cc9b500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/cb3b0bb6-9e86-4dbd-543b-ab89ddc39900/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1217bef3-df21-43e7-4df9-5e9621da1b00/1024",
    ],
    playgroundPrompt:
      "photo of a @MENTION_MODEL [describe toy] on a playful colorful background, 60mm lens, professional product photography",
    playgroundInfoboxModelDescription: "a plush teddy bear",
  },
  [FrontendDisplayTemplateType.Hats]: {
    label: "Hats",
    subline: "Train model based on your hat design",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ac9aa951-4369-4e56-96a3-da681dc79b00/1024",
    hoverText: defaultHoverText("hat"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("hat"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5738ac3f-62d1-4f4a-36d7-074af4ab0400/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b661e67a-b6f9-458d-c88e-14bbaf3ef600/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a4813c96-8baa-4c73-aa4f-dfc95e332c00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f296da91-d0aa-4694-51bf-5b8a64a62d00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/7428b34c-eb85-4ef7-afcb-7635e1b78b00/1024",
    ],
    playgroundPrompt:
      "photo of a model wearing a @MENTION_MODEL [describe hat] in an urban setting, 85mm lens, fashion photography",
    playgroundInfoboxModelDescription: "a vintage baseball cap",
  },
  [FrontendDisplayTemplateType.OfficeSupplies]: {
    label: "Office Supplies",
    subline: "Train model based on your office supplies",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4134e395-cc99-479d-3fa0-92ab3e8eb000/1024",
    hoverText: defaultHoverText("office supply"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("office supply"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b83c9129-5fd4-40df-bb60-5870d6e51b00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/dcd845f6-d7de-446e-bb8e-489fb7360600/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4bb91e88-8662-4817-b320-f60ded6c6a00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/eade563b-7c2d-4a03-6eee-8fabd701f000/1024",
    ],
    playgroundPrompt:
      "photo of a @MENTION_MODEL [describe office supply] on a clean desk with minimal office accessories, 60mm lens, professional product photography",
    playgroundInfoboxModelDescription: "a modern desk organizer",
  },
  [FrontendDisplayTemplateType.Kitchenware]: {
    label: "Kitchenware",
    subline: "Train model based on your kitchenware",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9dc53736-fbfc-4108-f52c-f26de1973800/1024",
    hoverText: defaultHoverText("kitchenware"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("kitchenware"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/abedff47-0ff4-4e16-aebf-7be9fb1d9000/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/341ff6ca-1ec2-4143-02e5-cf10eecfb100/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5e9b7715-9789-4ea3-c23a-954ecb339000/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/81f37d16-35e0-4a09-13b5-d42573d11200/1024",
    ],
    playgroundPrompt:
      "photo of a @MENTION_MODEL [describe kitchenware] in a modern kitchen setting with marble countertops, 60mm lens, professional product photography",
    playgroundInfoboxModelDescription: "a sleek coffee maker",
  },
  [FrontendDisplayTemplateType.Vehicles]: {
    label: "Vehicles",
    subline: "Train model based on your vehicle",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/197750e8-c413-4710-bd5f-152e4d1fa800/1024",
    hoverText: defaultHoverText("vehicle"),
    trainingSliderConfigs: defaultProductTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("vehicle"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/704d849a-3d6b-4a0a-7df5-71ffe67e1600/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/89933084-b2cf-4b9e-b088-0cbd24272300/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6559f060-029f-45dd-f89b-b3f72d06ec00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c916c221-8639-4645-f348-926477322b00/1024",
    ],
    playgroundPrompt:
      "photo of a @MENTION_MODEL [describe vehicle] on an empty street with dramatic lighting, 35mm lens, automotive photography",
    playgroundInfoboxModelDescription: "a luxury sports car",
  },
  [FrontendDisplayTemplateType.MensFashion]: {
    label: "Men's Fashion",
    subline: "Train model based on your menswear",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e02dfadf-b5a4-4adc-76d9-0ae90f505400/1024",
    hoverText: defaultHoverText("menswear"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("menswear"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b25155e2-a198-4981-efec-44494178c000/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/c0f2a743-d94d-40b5-7e86-e2718fcc2300/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4425cf29-65e3-4d84-c458-de80dc8f1d00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1c432f87-04df-43e5-4153-a4637fa35b00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ee8cc733-7ed5-452e-85b4-a21f04932b00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d8df6e73-5555-4172-26cc-17afe70baf00/1024",
    ],
    playgroundPrompt:
      "photo of a male model wearing @MENTION_MODEL [describe menswear] in an urban setting, 85mm lens, fashion photography",
    playgroundInfoboxModelDescription: "a tailored suit",
  },
  [FrontendDisplayTemplateType.Dresses]: {
    label: "Dresses",
    subline: "Train model based on your dress design",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/7fe052de-bb74-4caa-cc6c-3fcc14c7cf00/1024",
    hoverText: defaultHoverText("dress"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("dress"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/345d39d0-6ba5-47b5-ba5a-d8f31ed04a00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/cced9c26-897b-4dcc-a3ee-99d3bf2eaa00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/613e9b50-c5e3-4f88-33f9-00a3d2aab500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/26c9b3c5-5695-4b02-1d74-1fd122384700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6a832c02-b5a0-449c-4729-12394cc6da00/1024",
    ],
    playgroundPrompt:
      "photo of a model wearing a @MENTION_MODEL [describe dress] in an elegant setting, 85mm lens, fashion photography",
    playgroundInfoboxModelDescription: "an evening gown",
  },
  [FrontendDisplayTemplateType.Outerwear]: {
    label: "Outerwear",
    subline: "Train model based on your outerwear",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3186fc0c-3343-4bd8-7094-d25cd2470800/1024",
    hoverText: defaultHoverText("outerwear"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("outerwear"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f76dac8d-0509-473e-3626-01aafa24cc00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1c5f0d38-1db8-4c3a-ccc9-55918e7ece00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/851d5238-1b64-4c94-4c6f-f54fb1ddb500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/f9263493-dba5-441e-3e1d-3b8b64c50700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a8b4c2a9-67eb-4169-bcae-fbef11072800/1024",
    ],
    playgroundPrompt:
      "photo of a model wearing a @MENTION_MODEL [describe outerwear] in an outdoor winter setting, 85mm lens, fashion photography",
    playgroundInfoboxModelDescription: "a winter coat",
  },
  [FrontendDisplayTemplateType.Glasses]: {
    label: "Glasses",
    subline: "Train model based on your eyewear",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b0e9db01-ff94-42fb-230b-0e83867b6900/1024",
    hoverText: defaultHoverText("glasses"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("glasses"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1a85b0e0-b9b6-47b2-c73a-7c8b17b97500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/69430e53-2b35-4051-8858-93aa05b53800/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/cbee784b-55e2-41ae-9c6a-68d5a58a1900/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9806dade-50b3-4bac-02eb-aaa624710d00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1d483b7c-f1c4-4b62-096e-8032a8e48400/1024",
    ],
    playgroundPrompt:
      "photo of a model wearing @MENTION_MODEL [describe glasses] in a modern urban setting, 85mm lens, fashion photography",
    playgroundInfoboxModelDescription: "designer sunglasses",
  },
  [FrontendDisplayTemplateType.Watches]: {
    label: "Watches",
    subline: "Train model based on your watch design",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/18f6b6e6-7db2-483e-5d19-056afb239300/1024",
    hoverText: defaultHoverText("watch"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("watch"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d7d8c331-6cdd-454e-0458-8ff1215e7700/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ad55c48c-0fae-4bf4-8fe0-e75f6e18ff00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/a2b7905a-7e26-4a2c-60cd-d917d996e400/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ca5f4a16-4794-4117-f5e2-5b5161b27b00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/11a93f8c-7fc5-482c-a444-aea223c4b000/1024",
    ],
    playgroundPrompt:
      "close-up photo of a @MENTION_MODEL [describe watch] on a marble surface with dramatic lighting, 60mm lens, luxury product photography",
    playgroundInfoboxModelDescription: "a luxury timepiece",
  },
  [FrontendDisplayTemplateType.Sweaters]: {
    label: "Sweaters",
    subline: "Train model based on your sweater design",
    imageUrl:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/11905452-6307-408b-7068-cb4e75721300/1024",
    hoverText: defaultHoverText("sweater"),
    trainingSliderConfigs: defaultFashionTrainingSliderConfig,
    moodboardInfoboxText: defaultMoodboardInfoboxText("sweater"),
    moodboardExampleUrls: [
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/124384dd-991f-4d2c-408d-8620e5936c00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9bc7c373-a064-44d0-f66a-eb97f480f500/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d4ea5f13-b74a-4e44-90b7-e0cf5d465a00/1024",
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d5c82b0a-841b-4e12-d337-37c4352a9500/1024",
    ],
    playgroundPrompt:
      "photo of a model wearing a @MENTION_MODEL [describe sweater] in a cozy indoor setting, 85mm lens, fashion photography",
    playgroundInfoboxModelDescription: "a cashmere sweater",
  },
};
