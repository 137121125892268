import { DropdownClassName, PrimaryButtonClassName } from "@/components/constants/class-names";
import { NEW_CUSTOM_MODEL, NEW_PROJECT } from "@/components/constants/routes";
import { Tool, Tools, ToolType } from "@/components/constants/user-project-type-styles";
import { classNames } from "@/core/utils/classname-utils";
import { Cross1Icon } from "@radix-ui/react-icons";
import { CheckCircle2Icon } from "lucide-react";
import React, { createContext } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  MessageDialog,
  MessageDialogClose,
  MessageDialogProps,
  MessageDialogTitle,
} from "./message-dialog";

interface CreateGeneralProjectDialogContextType {
  open: boolean;
  setOpen: (value: boolean) => void;
}

function CreateGeneralProjectDialogTitle() {
  return (
    <MessageDialogTitle className="w-full text-sm 2xl:text-lg pb-4 2xl:pb-6">
      Select a tool based on your project
    </MessageDialogTitle>
  );
}

function openTool(type: ToolType, navigate: NavigateFunction) {
  switch (type) {
    case ToolType.DragAndDropCanvas:
      navigate(`/${NEW_PROJECT}`, { replace: true });
      break;

    case ToolType.CustomModel:
      navigate(`/${NEW_CUSTOM_MODEL}`, { replace: true });
      break;
  }
}

function ToolHeader({
  image,
  title,
  description,
}: {
  image: string;
  title: string;
  description: string;
}) {
  return (
    <div className="flex flex-col items-center gap-4 2xl:gap-8 2xl:pt-8">
      <div className="flex flex-col gap-0 2xl:gap-2">
        <div className="text-lg 2xl:text-xl font-semibold text-center">{title}</div>
        <div className="text-sm 2xl:text-base text-zinc-400 text-center">{description}</div>
      </div>
      <img
        src={image}
        alt={title}
        className="w-full h-full max-h-[24vh] lg:max-h-[32vh] object-cover rounded-md"
      />
    </div>
  );
}

function ToolBenefits({ benefits }: { benefits: string[] }) {
  return (
    <div className="space-y-2 2xl:space-y-4 text-sm 2xl:text-lg flex-grow">
      <div className="font-medium text-center">Works best when</div>
      <div className="space-y-2 2xl:space-y-4">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex items-start text-left space-x-2 px-4 text-zinc-400">
            <div className="text-zinc-500 mt-1">
              <CheckCircle2Icon size={14} />
            </div>
            <div>{benefit}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ToolItem({ tool, navigate }: { tool: Tool; navigate: NavigateFunction }) {
  return (
    <div
      className={classNames(
        "relative min-w-[256px] max-w-[512px] lg:min-w-[320px] lg:max-w-[640px] flex flex-col p-4 lg:p-8 gap-6 2xl:gap-8 rounded-lg transition-colors",
        "hover:border-lime-500 hover:shadow-[0_0_25px_rgba(0,0,0,0.1)] hover:shadow-lime-500/10",
        "border border-zinc-800 shadow-xl",
      )}
    >
      {tool.recommended && (
        <div className="absolute -top-3 left-1/2 -translate-x-1/2 px-3 py-1 2xl:px-4 2xl:py-2 rounded-full bg-lime-500 text-xs 2xl:text-lg text-zinc-900 truncate shadow-[0_0_10px_rgba(132,204,22,0.5)]">
          Recommended
        </div>
      )}
      <ToolHeader image={tool.image} title={tool.title} description={tool.description} />
      <ToolBenefits benefits={tool.benefits} />
      <button
        className={classNames(PrimaryButtonClassName, "w-full sticky bottom-0 2xl:text-lg")}
        onClick={() => openTool(tool.type, navigate)}
      >
        Use {tool.title}
      </button>
    </div>
  );
}

function CreateGeneralProject() {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      <div className="text-center justify-center">
        <div className="flex flex-col lg:flex-row gap-4 2xl:gap-8 mt-8 2xl:mt-10 justify-center items-center">
          {Tools.map((tool) => (
            <ToolItem key={tool.title} tool={tool} navigate={navigate} />
          ))}
        </div>
      </div>
    </div>
  );
}

const CreateGeneralProjectDialogContent = React.forwardRef(
  function CreateGeneralProjectDialogContent(
    { className = "", ...props }: React.HTMLAttributes<HTMLDivElement>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) {
    console.log("CreateGeneralProjectDialogContent");
    return (
      <div
        {...props}
        ref={forwardedRef}
        className={classNames(
          DropdownClassName,
          "min-w-[90vw] md:min-w-0 w-[95vw] 2xl:w-[1600px] md:h-[min(1000px,95vh)] 2xl:h-[min(1200px,95vh)]",
          "rounded-2xl flex flex-col px-0 items-center overflow-hidden",
          className,
        )}
      >
        <div className="w-full px-6 lg:px-12 pt-2 lg:pt-4 border-b border-zinc-800 flex justify-center">
          <MessageDialogClose className="absolute right-4">
            <Cross1Icon className="text-zinc-500 hover:text-zinc-200 cursor-pointer transition-colors" />
          </MessageDialogClose>
          <div>
            <CreateGeneralProjectDialogTitle />
          </div>
        </div>
        <div className="w-full px-6 lg:px-12 pb-4 lg:pb-8 overflow-y-auto">
          <CreateGeneralProject />
        </div>
      </div>
    );
  },
);

// Create a context with an undefined default value. We will never actually use this value directly;
// instead, we will always use the provider below to wrap our components, which provides an actual value.
const CreateGeneralProjectDialogContext = createContext<CreateGeneralProjectDialogContextType>({
  open: false,
  setOpen: () => {},
});

export function useCreateGeneralProjectDialogContext() {
  return React.useContext(CreateGeneralProjectDialogContext);
}

// Define the props for the provider, including the children it will wrap
interface CreateGeneralProjectDialogProviderProps {
  children: React.ReactNode;
}

// Create a provider component
export const CreateGeneralProjectDialogProvider: React.FC<
  CreateGeneralProjectDialogProviderProps
> = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  // The value that will be provided to the descendants of this provider
  const value = { open, setOpen };

  return (
    <CreateGeneralProjectDialogContext.Provider value={value}>
      {children}
    </CreateGeneralProjectDialogContext.Provider>
  );
};

export function CreateGeneralProjectDialog(props: MessageDialogProps) {
  const { open, setOpen } = useCreateGeneralProjectDialogContext();

  return (
    <MessageDialog
      {...props}
      open={open}
      onOpenChange={setOpen}
      contentChildren={<CreateGeneralProjectDialogContent />}
    />
  );
}
