import { EditorAsset } from "@/core/common/types";
import { fabric } from "fabric";
import { loadImage, LoadImageOptions } from "@/core/utils/image-loader";
import type { StaticImageElementMetadata } from "@/core/common/types/elements";

export interface StaticImageOptions extends fabric.IImageOptions {
  id?: string;
  name?: string;
  description?: string;
  subtype?: string;
  src?: string;
  asset: EditorAsset;
  generationId?: string;
  metadata?: StaticImageElementMetadata;
}

export class StaticImageObject extends fabric.Image {
  static type = "StaticImage";

  public role: string = "regular";

  public metadata?: StaticImageElementMetadata;

  public asset?: EditorAsset;

  public generationId?: string;

  //@ts-ignore
  initialize(element, options: StaticImageOptions) {
    this.role = element.role;
    options.type = "StaticImage";
    //@ts-ignore
    super.initialize(element, options);
    this.cornerStyle = "circle";
    this.cornerColor = "#ffffff";
    this.transparentCorners = false;
    this.asset = options.asset;
    this.generationId = options.generationId;
    this.metadata = options.metadata;
    return this;
  }

  static fromObject(options: any, callback: Function) {
    fabric.util.loadImage(
      options.src,
      function (img) {
        try {
          if (!options?.filters) {
            // @ts-ignore
            return callback && callback(new fabric.StaticImage(img, options));
          }
          // @ts-ignore
          fabric.StaticImage.prototype._initFilters.call(
            img,
            options.filters,
            function (filters: fabric.IAllFilters[]) {
              options.filters = filters || [];
              if (!options.resizeFilter) {
                callback(new fabric.StaticImage(img, options));
                return;
              }
              // @ts-ignore
              fabric.StaticImage.prototype._initFilters.call(
                options,
                [options.resizeFilter],
                function (resizeFilters: fabric.IAllFilters[]) {
                  options.resizeFilter = resizeFilters?.[0];
                  fabric.util.enlivenObjects(
                    [options.clipPath],
                    function (enlivedProps: fabric.Object[]) {
                      options.clipPath = enlivedProps?.[0];
                      callback(new fabric.StaticImage(img, options));
                    },
                    "",
                  );
                },
              );
            },
          );
        } catch (error) {
          console.error(error);
        }
      },
      null,
      // @ts-ignore
      { crossOrigin: "anonymous" },
    );
  }

  static async fromURL(
    url: string,
    imgOptions?: (StaticImageOptions & LoadImageOptions) | undefined,
  ) {
    const asset: EditorAsset = {
      type: "image-url",
      path: url,
    };
    const options = imgOptions || {};
    return await loadImage(url, options).then(function (img) {
      return new fabric.StaticImage(img, {
        asset,
        ...options,
      });
    });
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude);
  }

  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude);
  }
}

fabric.StaticImage = fabric.util.createClass(StaticImageObject, {
  type: StaticImageObject.type,
});
fabric.StaticImage.fromObject = StaticImageObject.fromObject;
fabric.StaticImage.fromURL = StaticImageObject.fromURL;

declare module "fabric" {
  namespace fabric {
    class StaticImage extends StaticImageObject {
      asset: EditorAsset;
      constructor(
        element: string | HTMLCanvasElement | HTMLImageElement | HTMLVideoElement,
        options?: StaticImageOptions | undefined,
      );
    }
  }
}
