import { Route, Routes } from "react-router-dom";
import { FinishEmailLogin } from "./auth/finish-email-login";
import { FullScreenInviteCodeInput } from "./auth/invite-code";
import { LoginFullscreen } from "./auth/login-fullscreen";
import { MobileRedirectErrorFullscreen, MobileRedirectFullscreen } from "./auth/require-desktop";
import { NewCheckoutSession } from "./checkout/checkout-session";
import { SetProductQuota } from "./checkout/set-product-quotas";
import {
  CUSTOM_MODELS,
  EMAIL_LINK_SIGNIN,
  NEW_CUSTOM_MODEL,
  NEW_PROJECT,
  NEW_TRYON_PROJECT,
  ONBOARDING,
  PROJECTS,
  TEMPLATES,
  VIDEOS,
} from "./constants/routes";
import { CreateCustomModel } from "./custom-model/create-custom-model";
import { CustomModelEditor } from "./custom-model/custom-model-editor";
import { ApiPlaygroundRouter } from "./dashboard/api";
import { CreateNewProject } from "./dashboard/create-new-project";
import { CreateNewTryOnProject } from "./dashboard/create-new-tryon-project";
import { Dashboard } from "./dashboard/dashboard";
import { EditorInternal } from "./editor/canvas-editor";
import { EditorRouter } from "./editor/editor-router";
import { NotFound } from "./not-found";
import { Onboarding } from "./onboard/onboarding";
import { GenerateVideoEditorDebug } from "./video/generate-video-editor";

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard dashboardType="templates" />} />
      <Route path={`/${TEMPLATES}`} element={<Dashboard dashboardType="templates" />} />
      <Route path="/login" element={<LoginFullscreen />} />
      <Route path="/mobile" element={<MobileRedirectFullscreen />} />
      <Route path="/mobileError" element={<MobileRedirectErrorFullscreen />} />
      <Route path={`/${EMAIL_LINK_SIGNIN}`} element={<FinishEmailLogin />} />
      <Route path="inviteCode" element={<FullScreenInviteCodeInput />} />
      <Route path={`/${PROJECTS}`} element={<Dashboard dashboardType="projects" />} />
      <Route path={`/${PROJECTS}/:projectId`} element={<EditorRouter />} />
      <Route path="/api" element={<Dashboard dashboardType="manage-api" />} />
      <Route path="/api/generate-image" element={<Dashboard dashboardType="manage-api" />} />
      <Route path="/api/:apiId" element={<ApiPlaygroundRouter />} />
      <Route path="/editorDebug" element={<EditorInternal />} />
      <Route path={`/${NEW_PROJECT}`} element={<CreateNewProject />} />
      <Route path={`/${NEW_PROJECT}/:projectType`} element={<CreateNewProject />} />
      <Route path={`/${NEW_TRYON_PROJECT}`} element={<CreateNewTryOnProject />} />
      <Route path={`/${NEW_CUSTOM_MODEL}`} element={<CreateCustomModel />} />
      <Route path={`/${NEW_CUSTOM_MODEL}/:workflow`} element={<CreateCustomModel />} />
      <Route path={`/${CUSTOM_MODELS}`} element={<Dashboard dashboardType="models" />} />
      <Route path={`/${CUSTOM_MODELS}/:modelId`} element={<CustomModelEditor />} />
      <Route path={`/${VIDEOS}`} element={<Dashboard dashboardType="videos" />} />
      <Route path="/generateVideoDebug" element={<GenerateVideoEditorDebug />} />
      <Route path="/newCheckout" element={<NewCheckoutSession />} />
      <Route path="/adminSetProductQuota" element={<SetProductQuota />} />
      <Route path={`/${ONBOARDING}`} element={<Onboarding />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
