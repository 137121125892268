import {
  FlairGenerateTemplateItem,
  FlairTemplateGenerator,
  FlairTemplateGeneratorBatch,
  FlairTemplateGeneratorSearchConstraints,
  GetGenerateTemplatesTypesenseProps,
} from "@/core/common/types/template-generator";

import {
  Firestore,
  query,
  collection,
  startAfter,
  orderBy,
  limit,
  DocumentSnapshot,
  DocumentData,
  QueryDocumentSnapshot,
  QueryConstraint,
  getDocs,
  doc,
  getDoc,
  where,
  setDoc,
} from "firebase/firestore";
import { searchTemplates } from "./typesense-utils";
import { isGenerateTemplateItem } from "@/core/utils/type-guards";
import { GenerateTemplateItem, UserProjectType } from "@/core/common/types";
import { removeUndefinedFromObject } from "@/core/utils/object-utils";
import { cloneDeep } from "lodash";
import { getSemanticSearchResults } from "@/backend/firebase/semantic-search";
import { appendUniqueItems } from "@/core/utils/array-utils";
import { isProjectDashboardCarouselTemplate } from "@/components/constants/user-project-type-styles";

export type GetTemplatesFirestoreProps = {
  limitSize?: number;
  lastVisible?: DocumentSnapshot<unknown>;
};

type FirebaseGenerateTemplateGeneratorGetTemplatesHandler = (
  props: GetGenerateTemplatesTypesenseProps,
) => Promise<FlairTemplateGeneratorBatch> | undefined;

type pineconeSearchHandler = (
  searchString: string,
  amount: number,
) => Promise<FlairTemplateGeneratorBatch> | undefined;

class FirebaseGenerateTemplateGenerator implements FlairTemplateGenerator {
  batchSize = 10;
  page = 1;
  getTemplatesHandler: FirebaseGenerateTemplateGeneratorGetTemplatesHandler;
  pineconeSearchHandler: pineconeSearchHandler;

  constructor({
    batchSize = 10,
    getTemplatesHandler,
    pineConeSearchHandler,
  }: {
    batchSize?: number;
    getTemplatesHandler: FirebaseGenerateTemplateGeneratorGetTemplatesHandler;
    pineConeSearchHandler: pineconeSearchHandler;
  }) {
    this.batchSize = batchSize;
    this.getTemplatesHandler = getTemplatesHandler;
    this.pineconeSearchHandler = pineConeSearchHandler;
  }

  reset() {
    // Reset the batch
    this.page = 1;
  }

  getNextBatch() {
    return this.getTemplatesHandler({
      page: this.page++,
      perPage: this.batchSize,
    });
  }

  setConstraints({ searchString, tags }: FlairTemplateGeneratorSearchConstraints) {
    this.reset();
    return this.getTemplatesHandler({
      page: this.page++,
      perPage: this.batchSize,
      searchString,
      tags,
    });
  }

  pineconeSearch(searchString: string, amount: number) {
    return this.pineconeSearchHandler(searchString, amount);
  }
}

function stringfySearchProps(props: GetGenerateTemplatesTypesenseProps) {
  // 1. Remove properties with undefined values
  const cleanedProps = removeUndefinedFromObject(props);

  // 2. Sort arrays
  const arrayKeys: ["tags", "includeFields", "excludeFields"] = [
    "tags",
    "includeFields",
    "excludeFields",
  ];
  arrayKeys.forEach((key) => {
    const prop = cleanedProps[key];
    if (prop && Array.isArray(prop)) {
      cleanedProps[key] = prop!.sort();
    }
  });

  // 3. Convert to JSON string.
  // Using JSON.stringify with its replacer function (second argument) to ensure properties
  // are added to the JSON string in a consistent order. Object.keys provides keys in a sorted order.
  return JSON.stringify(cleanedProps, Object.keys(cleanedProps).sort());
}

type SearchCacheHandler = (
  props: GetGenerateTemplatesTypesenseProps,
) => Promise<FlairGenerateTemplateItem[]> | undefined;

class SearchCache {
  private itemCache: Record<string, FlairGenerateTemplateItem> = {};

  private searchCache: Record<string, string[]> = {};

  private pineconeSearchCache: Record<string, string[]> = {};

  private tagsCache: Record<string, string[]> = {};

  private searchHandler: SearchCacheHandler;

  private maxCacheSize: number;

  constructor({
    searchHandler,
    maxCacheSize = 100, // default value, you can change it
  }: {
    searchHandler: SearchCacheHandler;
    maxCacheSize?: number;
  }) {
    this.searchHandler = searchHandler;
    this.maxCacheSize = maxCacheSize;
  }

  private checkCacheSize() {
    // Check cache size and remove the oldest entry if necessary
    if (Object.keys(this.searchCache).length >= this.maxCacheSize) {
      const oldestKey = Object.keys(this.searchCache)[0];
      delete this.searchCache[oldestKey];
      // TODO: Also delete the items cache
    }
  }

  private checkPineconeCacheSize() {
    // Check cache size and remove the oldest entry if necessary
    if (Object.keys(this.pineconeSearchCache).length >= this.maxCacheSize) {
      const oldestKey = Object.keys(this.pineconeSearchCache)[0];
      delete this.pineconeSearchCache[oldestKey];
    }
  }

  async search(searchProps: GetGenerateTemplatesTypesenseProps) {
    const searchPropsIndex = stringfySearchProps(searchProps);

    const prevSearchResult = this.searchCache[searchPropsIndex];

    if (prevSearchResult) {
      return prevSearchResult.map((id) => cloneDeep(this.itemCache[id]));
    }

    const searchResultItems = await this.searchHandler(searchProps);

    if (!searchResultItems) {
      return [];
    }

    this.checkCacheSize();

    const searchResult = searchResultItems.map((item) => item.id);

    searchResultItems.forEach((item) => {
      this.itemCache[item.id] = cloneDeep(item);
    });

    this.searchCache[searchPropsIndex] = searchResult;

    return searchResultItems;
  }

  async pineconeSearch(searchString: string, amount: number) {
    const prevSearchResult = this.pineconeSearchCache[searchString];

    if (prevSearchResult) {
      return prevSearchResult.map((id) => cloneDeep(this.itemCache[id]));
    }

    const searchResultItems = await getSemanticSearchResults(searchString, amount);

    if (!searchResultItems) {
      return [];
    }

    this.checkPineconeCacheSize();

    const searchResult = searchResultItems.map((item) => item.id);

    searchResultItems.forEach((item) => {
      this.itemCache[item.id] = cloneDeep(item);
    });

    this.pineconeSearchCache[searchString] = searchResult;

    return searchResultItems;
  }

  async getTags() {
    const tags = Object.keys(this.tagsCache);
    return tags;
  }
}

export class TypesenseGenerateTemplateManager {
  static PER_PAGE = 24;

  private firestore: Firestore;

  private searchCache: SearchCache;

  private firestoreTemplatesCache: Record<string, GenerateTemplateItem> = {};

  private defaultTemplates: FlairGenerateTemplateItem[] | undefined;

  constructor({ firestore }: { firestore: Firestore }) {
    this.firestore = firestore;
    this.searchCache = new SearchCache({
      searchHandler: searchTemplates,
    });
    this.loadDefaultTemplates().then((templates) => {
      this.defaultTemplates = templates;
    });
  }

  getTemplatesCollectionRef() {
    return collection(this.firestore, "/assets/generateTemplates/templatesV1");
  }

  async loadDefaultTemplates() {
    if (this.defaultTemplates) {
      return this.defaultTemplates;
    }
    this.defaultTemplates = await this.searchCache.search({
      page: 1,
      perPage: TypesenseGenerateTemplateManager.PER_PAGE,
    });
    return this.defaultTemplates;
  }

  getTemplates: FirebaseGenerateTemplateGeneratorGetTemplatesHandler = (
    props: GetGenerateTemplatesTypesenseProps,
  ) => {
    return this.searchCache.search(props);
  };
  getPineconeTemplates: pineconeSearchHandler = (searchString: string, amount: number) => {
    return this.searchCache.pineconeSearch(searchString, amount);
  };

  getGenerator({ batchSize = TypesenseGenerateTemplateManager.PER_PAGE }: { batchSize?: number }) {
    return new FirebaseGenerateTemplateGenerator({
      batchSize,
      getTemplatesHandler: this.getTemplates,
      pineConeSearchHandler: this.getPineconeTemplates,
    });
  }

  getFirestoreTemplateDocRef(id: string) {
    return doc(this.firestore, `/assets/generateTemplates/templatesV1/${id}`);
  }

  async getFirestoreTemplate(id: string) {
    const cachedDoc = this.firestoreTemplatesCache[id];
    if (cachedDoc) {
      return cloneDeep(cachedDoc);
    }

    const snapshot = await getDoc(this.getFirestoreTemplateDocRef(id));

    if (!snapshot.exists()) {
      return;
    }

    const data = snapshot.data();

    if (isGenerateTemplateItem(data)) {
      this.firestoreTemplatesCache[id] = cloneDeep(data);
      return data;
    }
  }

  getTemplatesTagsCollectionRef() {
    return collection(this.firestore, "/assets/generateTemplates/tagsV1");
  }
  private prevTagDoc: QueryDocumentSnapshot<DocumentData> | undefined;
  private isDocDone: boolean = false;
  private getTagsLock: boolean = false;
  tagsCache: { data: DocumentData[] } | null = null;
  tagsOrderCache: { data: string[] } | null = null;
  async getTagsInOrder(projectType?: UserProjectType) {
    if (this.getTagsLock) {
      return [];
    }
    if (!this.tagsCache) {
      const tagsRef = this.getTemplatesTagsCollectionRef();
      const allTags = query(tagsRef, orderBy("score", "desc"));
      const allDocs = await getDocs(allTags);
      const unsortedTags = allDocs.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => ({
        id: doc.id,
        data: doc.data(),
      }));

      this.tagsCache = { data: unsortedTags };
    }

    // debugLog('getTagsInOrder', {cache: this.tagsCache, projectType});

    return this.getSortedTagsByProjectType(projectType);
  }

  private getProjectTypeScoreKey(projectType: UserProjectType) {
    return projectType + "Score";
  }

  private getSortedTagsByProjectType(projectType?: UserProjectType): string[] {
    if (!this.tagsCache) {
      return [];
    }
    if (!projectType || !isProjectDashboardCarouselTemplate(projectType)) {
      return this.tagsCache.data.map((tagData) => tagData.id);
    } else {
      const scoreKey = this.getProjectTypeScoreKey(projectType);
      const specialTags = this.tagsCache.data.filter(
        (tagData) => typeof tagData.data[scoreKey] !== "undefined",
      );
      specialTags.sort((a, b) => b.data[scoreKey] - a.data[scoreKey]);

      const otherTags = this.tagsCache.data.filter(
        (tagData) => typeof tagData.data[scoreKey] === "undefined",
      );

      const combinedTags = [...specialTags, ...otherTags];

      // debugLog('getSortedTagsByProjectType', {
      //     projectType,
      //     cache: this.tagsCache.data,
      //     specialTags,
      //     otherTags
      // });

      return combinedTags.map((tagData) => tagData.id);
    }
  }

  private tagManagement = {
    prevDocPerTag: {} as Record<string, DocumentSnapshot | undefined>,
    tagTemplatesCache: {} as Record<string, GenerateTemplateItem[]>,
    tagDone: {} as Record<string, boolean>,
    tagLocks: {} as Record<string, boolean>,
  };

  async getFirestoreTemplatesByTag(tag: string) {
    const cachedTemplates = this.tagManagement.tagTemplatesCache[tag];
    if (cachedTemplates) {
      return cachedTemplates;
    }

    return cachedTemplates;
  }

  async setFirestoreTemplatesByTagNextBatch(tag: string, pageSize: number = 10) {
    if (this.tagManagement.tagLocks[tag]) {
      return false;
    }
    if (this.tagManagement.tagDone[tag]) {
      return false;
    }
    this.tagManagement.tagLocks[tag] = true;

    const prevDoc = this.tagManagement.prevDocPerTag[tag];
    const querySnapshot = await this.getFirestoreTemplatesByTagQuery(tag, pageSize, prevDoc);

    const templates: GenerateTemplateItem[] = [];

    if (!querySnapshot.empty) {
      this.tagManagement.prevDocPerTag[tag] = querySnapshot.docs[querySnapshot.docs.length - 1];
    } else {
      this.tagManagement.tagDone[tag] = true;
      this.tagManagement.tagLocks[tag] = false;
      return false;
    }

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      // @ts-expect-error
      data.id = doc.id;
      if (isGenerateTemplateItem(data)) {
        if (data.id !== undefined && !this.firestoreTemplatesCache[data.id]) {
          this.firestoreTemplatesCache[data.id] = data;
        }

        templates.push(data);
      }
    });
    if (!this.tagManagement.tagTemplatesCache[tag]) {
      this.tagManagement.tagTemplatesCache[tag] = [];
    }
    if (templates) {
      this.tagManagement.tagTemplatesCache[tag] = appendUniqueItems(
        this.tagManagement.tagTemplatesCache[tag],
        templates,
      );
    }
    this.tagManagement.tagLocks[tag] = false;
    return true;
  }

  async getFirestoreTemplatesByTagQuery(tag: string, pageSize: number, prevDoc?: DocumentSnapshot) {
    let q;
    if (prevDoc) {
      q = query(
        this.getTemplatesCollectionRef(),
        where("tags", "array-contains", tag),
        orderBy("score", "desc"),
        startAfter(prevDoc),
        limit(pageSize),
      );
    } else {
      q = query(
        this.getTemplatesCollectionRef(),
        where("tags", "array-contains", tag),
        orderBy("score", "desc"),
        limit(pageSize),
      );
    }
    const querySnapshot = await getDocs(q);

    return querySnapshot;
  }
}
