import { editorContextStore } from "contexts/editor-context";
import { PastGeneration } from "@/core/common/types";
import React from "react";

export function useActiveObjectPastGeneration() {
  const [generation, setGeneration] = React.useState<PastGeneration | undefined>();
  const editor = editorContextStore((state) => state.editor);
  const backend = editorContextStore((state) => state.backend);
  const activeObject = editorContextStore((state) => state.activeObject);
  const pastGenerations = editorContextStore((state) => state.pastGenerations);
  const generationId: string = (activeObject as any)?.generationId;

  React.useEffect(() => {
    if (!backend || !editor) {
      return;
    }
    editor.assets?.getPastGeneration(generationId).then(setGeneration);
  }, [editor, backend, generationId, pastGenerations]);

  return generation;
}
