import { MobileAppRoutes } from "@/components-mobile/app-routes";
import { AppRoutes } from "@/components/app-routes";
import { InviteCodeContextProvider } from "@/components/auth/invite-code";
import { NetworkStatusWrapper } from "@/components/network-status-error";
import { MessageToast } from "@/components/popup/message-toast";
import { editorContextStore, initEditorContextVanillaStore } from "@/contexts/editor-context";
import { ProjectInitSceneContextProvider } from "@/contexts/project-init-scene-context";
import { useAuthUpdateEffect } from "@/hooks/use-auth-update";
import { useFeatureFlagsEffect } from "@/hooks/use-featureFlags";
import { useCheckMobileEffect } from "@/hooks/use-mobile-context";
import { initObjects } from "@/objects";
import * as Tooltip from "@radix-ui/react-tooltip";
import React from "react";

import { EditorPopups as MobileEditorPopups } from "@/components-mobile/popup/popups";
import { EditorPopups as DesktopEditorPopups } from "@/components/popup/popups";
import { usePricingConfigUpdateEffect } from "@/hooks/use-pricing-config-update";
import { useUserStripeSubscriptionUpdateEffect } from "@/hooks/use-user-stripe-subscription-update";

initObjects();
initEditorContextVanillaStore();

function Routes() {
  const isMobile = editorContextStore((state) => state.isMobile);

  return isMobile ? <MobileAppRoutes /> : <AppRoutes />;
}

function Popups() {
  const isMobile = editorContextStore((state) => state.isMobile);

  return isMobile ? <MobileEditorPopups /> : <DesktopEditorPopups />;
}

function App() {
  React.useEffect(() => {
    const handleWheelEvent = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault(); //prevent zoom
      }
      // prevent horizontal scrolling
      if (e.deltaX !== 0) {
        e.preventDefault();
      }
    };
    const handleKeyEvent = (e: KeyboardEvent) => {
      if (!e.ctrlKey) {
        return;
      }
      if (e.code === "Minus" || e.code === "Equal") {
        e.preventDefault();
      }
    };
    document.body.addEventListener("wheel", handleWheelEvent, { passive: false });
    window.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.body.removeEventListener("wheel", handleWheelEvent);
      window.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);

  useAuthUpdateEffect();

  useCheckMobileEffect();

  useFeatureFlagsEffect();

  useUserStripeSubscriptionUpdateEffect();

  usePricingConfigUpdateEffect();

  // React.useEffect(() => {
  //   if (process.env.NODE_ENV === 'development') {
  //     editorContextStore.getState().eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
  //       'ui:display-message-dialog',
  //       'quota-subscribe',
  //       {
  //           title: 'No custom model credits left.',
  //           header: "You have used all custom model credits.",
  //       },
  //   );
  //   }
  // }, []);

  return (
    <NetworkStatusWrapper>
      <Tooltip.Provider>
        <InviteCodeContextProvider>
          <ProjectInitSceneContextProvider>
            <Routes />
            <MessageToast />
            <Popups />
          </ProjectInitSceneContextProvider>
        </InviteCodeContextProvider>
      </Tooltip.Provider>
    </NetworkStatusWrapper>
  );
}

export default App;
