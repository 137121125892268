import { InputBoxClassName } from "components/constants/class-names";
import { classNames } from "@/core/utils/classname-utils";
import { getColorHexDisplay, isColorHexValid } from "@/core/utils/color-utils";
import React from "react";
import {
  DropdownOptionItem,
  DropdownOptionsWithTrigger,
  getDropdownOptionItemsFromArray,
} from "components/utils/dropdown-options";
import styles from "./edit-color.module.css";

export function ColorPicker({
  colorHex,
  setColorHex,
}: {
  colorHex: string;
  setColorHex: (value: string) => void;
  disabled?: boolean;
}) {
  const [inputColor, setInputColor] = React.useState("");
  React.useEffect(() => {
    setInputColor(colorHex);
  }, [colorHex]);
  return (
    <div className="flex flex-row items-center justify-center text-zinc-300">
      <input
        type="color"
        className={classNames(styles.ColorSwatch, "w-[36px] h-[36px] mr-2 p-0")}
        value={colorHex}
        onChange={(e) => {
          setColorHex(e.currentTarget.value);
        }}
      />
      <input
        maxLength={7}
        className={classNames(InputBoxClassName, "max-w-[90px]")}
        value={inputColor.toUpperCase()}
        onChange={(e) => {
          setInputColor(e.currentTarget.value);
        }}
        onBlur={() => {
          if (isColorHexValid(inputColor)) {
            setColorHex(inputColor);
          } else {
            setInputColor(colorHex);
          }
        }}
      />
    </div>
  );
}

export type ColorFilterMode = "add" | "multiply" | "overlay" | "tint" | "none";

const colorFilterTypeOptions: Record<ColorFilterMode, DropdownOptionItem<ColorFilterMode>> = {
  add: {
    name: "Add",
    value: "add",
  },
  multiply: {
    name: "Multiply",
    value: "multiply",
  },
  overlay: {
    name: "Overlay",
    value: "overlay",
  },
  tint: {
    name: "Tint",
    value: "tint",
  },
  none: {
    name: "None",
    value: "none",
  },
};

export function ColorFilterModeDropdown({
  value,
  setValue,
}: {
  value: ColorFilterMode;
  setValue: (value: ColorFilterMode) => void;
}) {
  return (
    <DropdownOptionsWithTrigger
      value={colorFilterTypeOptions[value]?.name}
      options={colorFilterTypeOptions}
      onSelectItem={(item) => {
        setValue(item.value);
      }}
    />
  );
}
