export function triggerContextMenuEvent({
  element,
  clientX,
  clientY,
}: {
  element?: HTMLDivElement | null;
  clientX?: number;
  clientY?: number;
}) {
  if (!element) {
    return;
  }

  const { x, y, width, height } = element.getBoundingClientRect();

  clientX = clientX ?? x + width * 0.5;

  clientY = clientY ?? y + height * 0.5;

  const rightClickContextMenuEvent = new MouseEvent("contextmenu", {
    bubbles: true,
    cancelable: false,
    view: window,
    button: 2,
    buttons: 0,
    clientX,
    clientY,
  });

  element.dispatchEvent(rightClickContextMenuEvent);

  // Complete dispatch event for mouse up and context menu
}
