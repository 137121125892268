import React from "react";

export function LeftPanelLineDivider({
  height = 1,
  className = "",
}: {
  height?: number;
  className?: string;
}) {
  return (
    <div
      className={`w-full my-2 rounded-full bg-zinc-800/50 ${className}`}
      style={{
        height,
      }}
    />
  );
}

export function LeftPanelBarDividerSmall() {
  return <div className="h-2" />;
}
