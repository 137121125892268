import type { GenerateTemplateItem } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { capitalizeFirstLetter } from "@/core/utils/string-utils";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { InputBoxClassName } from "components-mobile/constants/class-names";
import { GenerateSearchBarZIndex } from "components-mobile/constants/zIndex";
import {
  GenerateTemplatesExpandedHorizontalImageGrid,
  GenerateTemplatesHorizontalImageGrid,
} from "components/panels/panel-items/components/generate-templates/generate-templates-horizontal";
import { HorizontalImageGridTitle } from "components/panels/panel-items/elements";
import { BackButton } from "components/panels/panel-items/generate/past-generations";
import StickyBox from "components/utils/sticky-box";
import { editorContextStore } from "contexts/editor-context";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGenerateTemplatesContext } from "./generate-templates-context";
import { GenerateTemplatesImageGrid } from "./generate-templates-grid";

function LeftPanelGenerateExamplesSearch() {
  const { searchString, setSearchString } = useGenerateTemplatesContext();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  return (
    <div
      id="generate-search-bar"
      className="relative w-full mt-0.5 py-2 bg-zinc-900 flex flex-row items-center justify-start"
    >
      <MagnifyingGlassIcon className="absolute m-2.5 pointer-events-none" width={18} height={18} />
      <input
        ref={inputRef}
        className={classNames(InputBoxClassName, "w-full pl-8 pr-2 py-2")}
        placeholder="Search by keyword ('flowers', 'sunset', etc)"
        value={searchString}
        onChange={(e) => {
          const value = e.currentTarget.value;
          setSearchString(value);
        }}
      />
    </div>
  );
}

function LeftPanelSectionImageGrid({
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className={classNames("flex flex-col", className ?? "")} {...props}>
      <GenerateTemplatesImageGrid />
    </div>
  );
}

async function fetchTags(
  setTags: React.Dispatch<React.SetStateAction<string[]>>,
  setTemplatesByTag: React.Dispatch<React.SetStateAction<Record<string, GenerateTemplateItem[]>>>,
) {
  const fetchedTags = await editorContextStore.getState().backend?.getTagsInOrder();
  if (!fetchedTags) {
    return;
  }
  setTags(fetchedTags);

  const newTemplatesByTag: Record<string, GenerateTemplateItem[]> = {};

  const promises = fetchedTags.map(async (tag) => {
    await editorContextStore.getState().backend?.setFirestoreTemplatesByTagNextBatch(tag, 8);
    const temps = await editorContextStore.getState().backend?.getFirestoreTemplatesByTag(tag);
    const resolvedTemplates = temps?.filter((template) => template != null) ?? [];
    newTemplatesByTag[tag] = resolvedTemplates;
  });

  await Promise.all(promises);
  setTemplatesByTag(newTemplatesByTag);
}

function GenerateTemplateTagDisplay({
  tag,
  setIsAnyExpanded,
  setCurrentTag,
  lastRowRef,
}: {
  tag: { name: string; templates: GenerateTemplateItem[] };
  setIsAnyExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTag: React.Dispatch<React.SetStateAction<string | undefined>>;
  lastRowRef?: (node?: Element | null) => void;
}) {
  const { editor } = editorContextStore.getState();

  return (
    <div ref={lastRowRef}>
      <HorizontalImageGridTitle
        title={capitalizeFirstLetter(tag.name)}
        onClickViewAll={() => {
          setCurrentTag(tag.name);
          setIsAnyExpanded(true);
        }}
        displayViewAll={true}
        backButton={
          <BackButton
            onClick={() => {
              setCurrentTag("");
              setIsAnyExpanded(false);
            }}
          />
        }
      />
      <GenerateTemplatesHorizontalImageGrid
        editor={editor}
        templateItems={tag?.templates ?? []}
        onClickViewAll={() => {
          setCurrentTag(tag.name);
          setIsAnyExpanded(true);
        }}
        tagName={tag.name}
      />
    </div>
  );
}

enum GenerateTemplatesPanelDislayState {
  NotExpanded = "NotExpanded",
  Expanded = "Expanded",
  Search = "Search",
}

type TemplatesByTag = Record<string, GenerateTemplateItem[]>;

function LeftPanelSectionGenerateTemplateNotExpanded({
  tags,
  setIsAnyExpanded,
  templatesByTag,
  setCurrentTag,
  lastRowRef,
}: {
  tags: string[];
  templatesByTag: TemplatesByTag;
  setIsAnyExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTag: React.Dispatch<React.SetStateAction<string | undefined>>;
  lastRowRef?: (node?: Element | null) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      {tags.map((tagVal, i) => (
        <GenerateTemplateTagDisplay
          key={tagVal}
          tag={{ name: tagVal, templates: templatesByTag[tagVal] }}
          setIsAnyExpanded={setIsAnyExpanded}
          setCurrentTag={setCurrentTag}
          {...(i === tags.length - 1 ? { lastRowRef } : {})}
        />
      ))}
    </div>
  );
}

function LeftPanelSectionGenerateTemplateExpanded({
  setIsAnyExpanded,
  setCurrentTag,
  templatesByTag,
  setTemplatesByTag,
  tag,
}: {
  setIsAnyExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  templatesByTag: Record<string, GenerateTemplateItem[]>;
  setTemplatesByTag: React.Dispatch<React.SetStateAction<Record<string, GenerateTemplateItem[]>>>;
  setCurrentTag: React.Dispatch<React.SetStateAction<string | undefined>>;
  tag?: string;
}) {
  if (!tag) {
    return null;
  }

  const templateItems = templatesByTag[tag];

  return (
    <div>
      <HorizontalImageGridTitle
        title={capitalizeFirstLetter(tag)}
        onClickViewAll={() => {
          setCurrentTag(tag);
          setIsAnyExpanded(true);
        }}
        displayViewAll={false}
        backButton={
          <BackButton
            onClick={() => {
              setCurrentTag("");
              setIsAnyExpanded(false);
            }}
          />
        }
      />
      <GenerateTemplatesExpandedHorizontalImageGrid
        setIsAnyExpanded={setIsAnyExpanded}
        templateItems={templateItems}
        templatesByTag={templatesByTag}
        setTemplatesByTag={setTemplatesByTag}
        tag={tag}
      />
    </div>
  );
}

function LeftPanelSectionGenerateTemplateSearch() {
  return <LeftPanelSectionImageGrid />;
}

export const LeftPanelSectionGenerateTemplate = React.memo(
  function LeftPanelSectionGenerateTemplate() {
    const { searchString } = useGenerateTemplatesContext();

    const [templatesByTag, setTemplatesByTag] = useState<TemplatesByTag>({});
    const [tags, setTags] = useState<string[]>([]);
    const [isAnyExpanded, setIsAnyExpanded] = useState(false);

    const [displayState, setDisplayState] = React.useState(
      GenerateTemplatesPanelDislayState.NotExpanded,
    );
    const [currentTag, setCurrentTag] = useState<string | undefined>();

    const [lastRowRef, lastRowInView] = useInView();

    useEffect(() => {
      fetchTags(setTags, setTemplatesByTag);
    }, []);

    useEffect(() => {
      if (lastRowInView) {
        fetchTags(setTags, setTemplatesByTag);
      }
    }, [lastRowInView]);

    React.useEffect(() => {
      if (!searchString) {
        setCurrentTag(undefined);
      }
    }, [searchString, setCurrentTag]);

    React.useEffect(() => {
      if (searchString) {
        setDisplayState(GenerateTemplatesPanelDislayState.Search);
      } else if (isAnyExpanded && currentTag) {
        setDisplayState(GenerateTemplatesPanelDislayState.Expanded);
      } else {
        setDisplayState(GenerateTemplatesPanelDislayState.NotExpanded);
      }
    }, [searchString, isAnyExpanded, currentTag]);

    return (
      <>
        <div className="h-2" />
        <div className="w-full flex flex-row justify-start items-center font-semibold">
          Select a template below.
        </div>
        <StickyBox
          style={{
            zIndex: GenerateSearchBarZIndex,
          }}
        >
          <LeftPanelGenerateExamplesSearch />
        </StickyBox>
        {displayState === GenerateTemplatesPanelDislayState.Expanded ? (
          <LeftPanelSectionGenerateTemplateExpanded
            setCurrentTag={setCurrentTag}
            setIsAnyExpanded={setIsAnyExpanded}
            templatesByTag={templatesByTag}
            setTemplatesByTag={setTemplatesByTag}
            tag={currentTag}
          />
        ) : displayState === GenerateTemplatesPanelDislayState.NotExpanded ? (
          <LeftPanelSectionGenerateTemplateNotExpanded
            tags={tags}
            templatesByTag={templatesByTag}
            setIsAnyExpanded={setIsAnyExpanded}
            setCurrentTag={setCurrentTag}
            lastRowRef={lastRowRef}
          />
        ) : displayState === GenerateTemplatesPanelDislayState.Search ? (
          <LeftPanelSectionGenerateTemplateSearch />
        ) : null}
      </>
    );
  },
);
