import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  Active,
  UniqueIdentifier,
  DropAnimation,
  defaultDropAnimationSideEffects,
  DragOverlay,
  DragEndEvent,
  DragCancelEvent,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface SortableItemProps {
  id: UniqueIdentifier;
  className?: string;
}

export function SortableItem({
  children,
  id,
  className = "",
}: PropsWithChildren<SortableItemProps>) {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className={className} {...attributes} {...listeners}>
      {children}
    </div>
  );
}

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.2",
      },
    },
  }),
};

interface SortableOverlayProps {}

export function SortableOverlay({ children }: PropsWithChildren<SortableOverlayProps>) {
  return <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>;
}

interface SortableListBaseItem {
  id: UniqueIdentifier;
}

interface SortableListProps<T extends SortableListBaseItem> {
  items: T[];
  onChange: (items: T[]) => void;
  onDragStart?: (event: DragStartEvent) => void;
  onDragEnd?: (event: DragEndEvent) => void;
  onDragCancel?: (event: DragCancelEvent) => void;
  renderItem(args: {
    item: T;
    itemIndex: number;
    totalNumItems: number;
    isOverlay: boolean;
  }): ReactNode;
  className?: string;
}

export function SortableList<T extends SortableListBaseItem>({
  items,
  onChange,
  onDragStart,
  onDragEnd,
  onDragCancel,
  renderItem,
  className = "",
}: SortableListProps<T>) {
  const [active, setActive] = React.useState<Active | null>(null);
  const activeItem = React.useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items],
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={(event) => {
        const { active } = event;

        setActive(active);

        onDragStart?.(event);
      }}
      onDragEnd={(event) => {
        const { active, over } = event;

        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);

        onDragEnd?.(event);
      }}
      onDragCancel={(event) => {
        setActive(null);

        onDragCancel?.(event);
      }}
    >
      <SortableContext items={items}>
        <div className={className} role="application">
          {items.map((item, itemIndex) => (
            <React.Fragment key={item.id}>
              {renderItem({
                item,
                itemIndex,
                totalNumItems: items.length,
                isOverlay: false,
              })}
            </React.Fragment>
          ))}
        </div>
      </SortableContext>
      <SortableOverlay>
        {activeItem
          ? renderItem({
              item: activeItem,
              itemIndex: 0,
              totalNumItems: 1,
              isOverlay: true,
            })
          : null}
      </SortableOverlay>
    </DndContext>
  );
}
