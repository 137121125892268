import { noop } from "lodash";
import { PublicId, isPublicId } from "./public-id";
import { AppRoleType } from "./user-roles";
import { StateUpdater } from "./utils";
import { getUpdaterFunction, SetEditorStateFunction } from "@/contexts/editor-context-utils";

export const publicTeamIdSuffix = "_tpuid";

export type PublicTeamId = PublicId<typeof publicTeamIdSuffix>;

export function isPublicTeamId(id: string | undefined | null): id is PublicTeamId {
  return isPublicId(id, publicTeamIdSuffix);
}

export interface TeamEditorState {
  currentTeamId: PublicTeamId | null;
  setCurrentTeamId: (value: PublicTeamId | null) => void;
  teamRoles: Record<PublicTeamId, AppRoleType>;
  setTeamRoles: (value: StateUpdater<Record<PublicTeamId, AppRoleType>>) => void;
}

export function getDummyTeamEditorState(): TeamEditorState {
  return {
    currentTeamId: null,
    setCurrentTeamId: noop,
    teamRoles: {},
    setTeamRoles: noop,
  };
}

export function getDefaultTeamEditorState(set: SetEditorStateFunction): TeamEditorState {
  return {
    currentTeamId: null,
    setCurrentTeamId: getUpdaterFunction(set, "currentTeamId"),
    teamRoles: {},
    setTeamRoles: getUpdaterFunction(set, "teamRoles"),
  };
}
