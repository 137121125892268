import React, { useState, createContext, useContext } from "react";
import { StateUpdater } from "@/core/common/types";

export enum CustomModelVirtualTryOnStatus {
  Idle = "Idle",
  Loading = "Loading",
}

interface CustomModelVirtualTryOnContextType {
  clothImageStoragePath: string | undefined;
  setClothImageStoragePath: (value: StateUpdater<string | undefined>) => void;
  status: CustomModelVirtualTryOnStatus;
  setStatus: (value: StateUpdater<CustomModelVirtualTryOnStatus>) => void;
}

// Create the context
const CustomModelVirtualTryOnContext = createContext<
  CustomModelVirtualTryOnContextType | undefined
>(undefined);

// Create a custom hook to use the context
export function useCustomModelVirtualTryOnContext() {
  const context = useContext(CustomModelVirtualTryOnContext);

  if (!context) {
    throw new Error(
      "useCustomModelVirtualTryOnContext must be used within a CustomModelVirtualTryOnContextProvider",
    );
  }

  return context;
}

// Create the context provider component
export function CustomModelVirtualTryOnContextProvider({ children }: React.PropsWithChildren) {
  const [clothImageStoragePath, setClothImageStoragePath] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState(CustomModelVirtualTryOnStatus.Idle);

  return (
    <CustomModelVirtualTryOnContext.Provider
      value={{
        clothImageStoragePath,
        setClothImageStoragePath,
        status,
        setStatus,
      }}
    >
      {children}
    </CustomModelVirtualTryOnContext.Provider>
  );
}
