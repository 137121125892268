import React from "react";
import { HtmlCanvasController } from "./html-canvas-controller";
import {
  PaintMaskCanvasController,
  PaintMaskCanvasControllerPaintBrushType,
  PaintMaskCanvasHistory,
  PaintMaskCanvasHistorySnapshot,
} from "./paint-mask-canvas-controller";

import { StateUpdater } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { getColorRGBTupleFromHex } from "@/core/utils/color-utils";
import { imageProcessingUtils } from "@/core/utils/image-processing-utils";
import { debugError } from "@/core/utils/print-utilts";
import { TryOnClothEditorPaintBrushZIndex } from "components/constants/zIndex";
import { Tooltip } from "components/utils/tooltip";
import { clamp, toSafeInteger } from "lodash";
import { Minus, Plus, Redo, Undo } from "lucide-react";
import { createPortal } from "react-dom";

type Point = { x: number; y: number };

export interface UsePaintMaskCanvasControllerReturnType {
  // Refs
  canvasContainerRef: React.RefObject<HTMLDivElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  maskCanvasRef: React.RefObject<HTMLCanvasElement>;
  brushCursorRef: React.RefObject<HTMLDivElement>;

  // Event handlers
  onPointerDown: (e: React.PointerEvent<HTMLDivElement>) => void;
  onPointerMove: (e: React.PointerEvent<HTMLDivElement>) => void;
  onPointerUp: () => void;
  onPointerEnter: () => void;
  onPointerLeave: () => void;
  onWheel: (e: React.WheelEvent<HTMLDivElement>) => void;

  // State and setters
  isCanvasMoving: boolean;
  isPointerOver: boolean;
  isBrushVisible: boolean;
  isPointerOverToolbarRef: React.MutableRefObject<boolean>;
  isMaskEmpty: boolean;

  // Undo/Redo methods
  undo: () => void;
  redo: () => void;
  numRedos: number;
  numUndos: number;

  // Helper functions
  clearMask: () => void;
  saveCanvas: () => void;
  getCanvasMaskUrl: () => string | undefined;
  checkMaskEmpty: () => boolean;
  exportHistory: () => PaintMaskCanvasHistorySnapshot;
  importHistory: (snapshot: PaintMaskCanvasHistorySnapshot) => void;
}

export function usePaintMaskCanvasController({
  imageUrl,
  maskImageUrl,
  brushSize = 10,
  brushType = PaintMaskCanvasControllerPaintBrushType.Paint,
  brushColor = "#FFFFFF",
  zoomScrollSensitivity = 0.2,
}: {
  imageUrl: string;
  maskImageUrl?: string;
  brushSize?: number;
  brushType?: PaintMaskCanvasControllerPaintBrushType;
  brushColor?: string;
  zoomScrollSensitivity?: number;
}): UsePaintMaskCanvasControllerReturnType {
  // Refs
  const canvasContainerRef = React.useRef<HTMLDivElement | null>(null);
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const maskCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const canvasContextRef = React.useRef<CanvasRenderingContext2D | null>(null);
  const maskCanvasContextRef = React.useRef<CanvasRenderingContext2D | null>(null);

  const brushCursorRef = React.useRef<HTMLDivElement | null>(null);

  // State to hold history and controller
  const [history, setHistory] = React.useState<PaintMaskCanvasHistory | null>(null);
  const [controller, setController] = React.useState<PaintMaskCanvasController | null>(null);

  // Initialize history and controller in useEffect
  React.useEffect(() => {
    const newHistory = new PaintMaskCanvasHistory();
    setHistory(newHistory);

    const newController = new PaintMaskCanvasController({
      historyRef: { current: newHistory },
    });
    setController(newController);

    // Cleanup on unmount
    return () => {
      newController.destroy();
      newHistory.destroy();
    };
  }, []);

  // States
  const [numUndos, setNumUndos] = React.useState(0);
  const [numRedos, setNumRedos] = React.useState(0);

  // State variable to track if the mask is empty
  const [isMaskEmpty, setIsMaskEmpty] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!controller) {
      return;
    }

    setIsMaskEmpty(controller.isMaskEmpty());
  }, [controller, maskImageUrl]);

  React.useEffect(() => {
    if (!history) {
      return;
    }

    const updateNumUndoRedo = () => {
      setNumUndos(history.numUndos());
      setNumRedos(history.numRedos());
    };

    // Subscribe to history events
    history.on("undo", updateNumUndoRedo);
    history.on("redo", updateNumUndoRedo);
    history.on("save", updateNumUndoRedo);

    // Initial update
    updateNumUndoRedo();

    return () => {
      history.off("undo", updateNumUndoRedo);
      history.off("redo", updateNumUndoRedo);
      history.off("save", updateNumUndoRedo);
    };
  }, [history]);

  // States
  const [isCanvasMoving, setIsCanvasMoving] = React.useState(false);
  const [isPointerOver, setIsPointerOver] = React.useState(false);

  const isBrushVisible =
    brushType === PaintMaskCanvasControllerPaintBrushType.Paint ||
    brushType === PaintMaskCanvasControllerPaintBrushType.Erase;

  // Other refs
  const resizeObserverRef = React.useRef<ResizeObserver>();
  const renderFunctionRef = React.useRef<() => void>(() => {});
  const isLeftPointerDownRef = React.useRef(false);
  const isMiddlePointerDownRef = React.useRef(false);
  const pointerDownLocationRef = React.useRef<Point>({ x: 0, y: 0 });
  const pointerDownCanvasOriginRef = React.useRef<Point>({ x: 0, y: 0 });
  const pointerPositionRef = React.useRef<Point>({ x: 0, y: 0 });
  const isRenderingRef = React.useRef(false);
  const isPaintingRef = React.useRef(false);
  const isErasingRef = React.useRef(false);
  const isPointerOverToolbarRef = React.useRef(false);

  // Update painting/erasing refs

  React.useEffect(() => {
    isPaintingRef.current = brushType === PaintMaskCanvasControllerPaintBrushType.Paint;
    isErasingRef.current = brushType === PaintMaskCanvasControllerPaintBrushType.Erase;
  }, [brushType]);

  // Initialize canvas controller
  React.useEffect(() => {
    if (!controller) {
      return;
    }

    const canvas = canvasRef.current;
    const container = canvasContainerRef.current;

    if (!container || !canvas) {
      return;
    }

    resizeObserverRef.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0]?.contentRect || {};

      if (width && height) {
        canvas.width = width;
        canvas.height = height;

        if (maskCanvasRef.current) {
          maskCanvasRef.current.width = width;
          maskCanvasRef.current.height = height;
        }

        controller.init();
        renderFunctionRef.current();
      }
    });

    resizeObserverRef.current.observe(container);

    canvasContextRef.current = canvas.getContext("2d");
    maskCanvasContextRef.current = maskCanvasRef.current?.getContext("2d") || null;

    if (canvasContextRef.current) {
      controller.setContext(canvasContextRef.current);

      if (maskCanvasContextRef.current) {
        controller.setMaskContext(maskCanvasContextRef.current);
      }
    }

    // Initialize render function
    renderFunctionRef.current = () => {
      const ctx = canvasContextRef.current;

      if (ctx) {
        controller.render();

        if (isRenderingRef.current) {
          requestAnimationFrame(renderFunctionRef.current);
        }
      }
    };

    renderFunctionRef.current();

    return () => {
      resizeObserverRef.current?.unobserve(container);
    };
  }, [controller]);

  // Load image
  React.useEffect(() => {
    if (!controller) {
      return;
    }

    if (imageUrl) {
      controller.setBackgroundImageFromUrl(imageUrl);
    }
  }, [controller, imageUrl]);

  // Load mask image
  React.useEffect(() => {
    if (!controller) {
      return;
    }

    controller.clearMask();

    if (maskImageUrl) {
      imageProcessingUtils
        .getColorAlphaMaskImage({
          maskImage: maskImageUrl,
          color: getColorRGBTupleFromHex(brushColor),
        })
        .then(async (processedMaskImageUrl) => {
          if (!processedMaskImageUrl) {
            return;
          }

          controller.clearMask();

          await controller.setMaskImageFromUrl(processedMaskImageUrl);

          renderFunctionRef.current?.();
        })
        .finally(() => {
          setIsMaskEmpty(controller.isMaskEmpty());
        });
    }
  }, [controller, maskImageUrl, brushColor]);

  // Event handlers
  const onPointerDown = React.useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (!controller) {
        return;
      }

      const container = canvasContainerRef.current;
      if (!container) {
        return;
      }

      const mouseButton = e.button;

      if (mouseButton === 1) {
        // Middle button (Pan)
        isMiddlePointerDownRef.current = true;
        isRenderingRef.current = true;

        const { x, y } = HtmlCanvasController.getPointerEventLocation(e.nativeEvent, {
          x: container.offsetLeft,
          y: container.offsetTop,
        });

        pointerDownLocationRef.current.x = x;
        pointerDownLocationRef.current.y = y;

        pointerDownCanvasOriginRef.current = controller.getOrigin({
          ...pointerDownCanvasOriginRef.current,
        });

        renderFunctionRef.current();

        //should not mutate props like this
        // eslint-disable-next-line react-compiler/react-compiler
        controller.isCanvasMoving = true;
        setIsCanvasMoving(true);
      } else if (mouseButton === 0) {
        // Left button (Paint/Erase)
        isLeftPointerDownRef.current = true;

        if (maskCanvasRef.current && !isPointerOverToolbarRef.current) {
          isRenderingRef.current = true;

          const { left, top } = maskCanvasRef.current.getBoundingClientRect();

          const point = HtmlCanvasController.getPointerEventLocation(e.nativeEvent, {
            x: left,
            y: top,
          });

          controller.startBrushStroke({
            point,
            brushType,
            color: brushColor,
            lineWidth: brushSize,
          });

          renderFunctionRef.current();
        }
      }
    },
    [controller, brushType, brushSize, brushColor],
  );

  const onPointerMove = React.useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      const canvasController = controller;

      if (!canvasController) {
        return;
      }

      const container = canvasContainerRef.current;
      if (!container) {
        return;
      }

      if (isLeftPointerDownRef.current) {
        if (maskCanvasRef.current && !isPointerOverToolbarRef.current) {
          const { left, top } = maskCanvasRef.current.getBoundingClientRect();

          const point = HtmlCanvasController.getPointerEventLocation(e.nativeEvent, {
            x: left,
            y: top,
          });
          canvasController.moveBrushStroke(point);
        }
      } else if (isMiddlePointerDownRef.current) {
        const { x, y } = HtmlCanvasController.getPointerEventLocation(e.nativeEvent, {
          x: container.offsetLeft,
          y: container.offsetTop,
        });

        const dx = x - pointerDownLocationRef.current.x;
        const dy = y - pointerDownLocationRef.current.y;

        const { x: x0, y: y0 } = pointerDownCanvasOriginRef.current;

        canvasController.setOrigin(x0 + dx, y0 + dy);
      }

      if (isPaintingRef.current || isErasingRef.current) {
        if (brushCursorRef.current) {
          const { clientX, clientY } = e;
          brushCursorRef.current.style.left = `${clientX - brushSize * 0.5}px`;
          brushCursorRef.current.style.top = `${clientY - brushSize * 0.5}px`;

          pointerPositionRef.current.x = clientX;
          pointerPositionRef.current.y = clientY;
        }
      }
    },
    [brushSize, controller],
  );

  const onPointerUp = React.useCallback(() => {
    const canvasController = controller;
    if (!canvasController) {
      return;
    }

    if (isLeftPointerDownRef.current) {
      canvasController.endBrushStroke();
    }

    isMiddlePointerDownRef.current = false;
    isLeftPointerDownRef.current = false;
    isRenderingRef.current = false;
    renderFunctionRef.current();

    if (canvasController) {
      canvasController.isCanvasMoving = false;
    }

    setIsCanvasMoving(false);

    // Update isMaskEmpty when the user finishes interacting
    setIsMaskEmpty(canvasController.isMaskEmpty());
  }, [controller]);

  const onPointerEnter = React.useCallback(() => {
    setIsPointerOver(true);
  }, []);

  const onPointerLeave = React.useCallback(() => {
    isMiddlePointerDownRef.current = false;
    isRenderingRef.current = false;
    renderFunctionRef.current();
    setIsPointerOver(false);
  }, []);

  // Update brush cursor on brush size change
  React.useEffect(() => {
    if (brushCursorRef.current) {
      brushCursorRef.current.style.width = `${brushSize}px`;
      brushCursorRef.current.style.height = `${brushSize}px`;
      brushCursorRef.current.style.left = `${pointerPositionRef.current.x - brushSize * 0.5}px`;
      brushCursorRef.current.style.top = `${pointerPositionRef.current.y - brushSize * 0.5}px`;
    }
  }, [brushSize]);

  // Wheel event for zooming and panning
  const onWheel = React.useCallback(
    (e: React.WheelEvent<HTMLDivElement>) => {
      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const canvasController = controller;

      if (!canvasController) {
        return;
      }

      if (e.ctrlKey) {
        const { left, top } = canvas.getBoundingClientRect();

        const pointerPos = HtmlCanvasController.getPointerEventLocation(e.nativeEvent, {
          x: left,
          y: top,
        });

        canvasController.scaleAt(pointerPos, Math.exp((-e.deltaY / 120) * zoomScrollSensitivity));

        renderFunctionRef.current();
      } else {
        const deltaX = e.deltaX;
        const deltaY = e.deltaY;

        canvasController.move(-deltaX, -deltaY);

        renderFunctionRef.current();
      }
    },
    [controller, zoomScrollSensitivity],
  );

  // Expose methods for undo/redo
  const undo = React.useCallback(() => {
    history?.undo();
    renderFunctionRef.current();

    setIsMaskEmpty(controller?.isMaskEmpty() ?? true);
  }, [history, controller]);

  const redo = React.useCallback(() => {
    history?.redo();
    renderFunctionRef.current();

    setIsMaskEmpty(controller?.isMaskEmpty() ?? true);
  }, [history, controller]);

  const clearMask = React.useCallback(() => {
    controller?.clearMask();
    renderFunctionRef.current();

    setIsMaskEmpty(controller?.isMaskEmpty() ?? true);
  }, [controller]);

  const saveCanvas = React.useCallback(() => {
    controller?.saveCanvas();
    renderFunctionRef.current();
  }, [controller]);

  const getCanvasMaskUrl = React.useCallback(() => {
    if (!controller) {
      debugError("Controller is undefined.");
      return undefined;
    }

    try {
      const { maskDataUrl } = controller.getCanvasDataUrl({
        getImage: false,
        getMask: true,
      });

      return maskDataUrl;
    } catch (error) {
      debugError("Error getting canvas data url: ", error);
      return undefined;
    }
  }, [controller]);

  const checkMaskEmpty = React.useCallback(() => {
    return controller?.isMaskEmpty();
  }, [controller]);

  const exportHistory = React.useCallback(() => {
    return (
      history?.export() ?? {
        actions: [],
        currentIndex: -1,
      }
    );
  }, [history]);

  const importHistory = React.useCallback(
    (snapshot: PaintMaskCanvasHistorySnapshot) => {
      history?.import(snapshot);

      if (controller) {
        setIsMaskEmpty(controller.isMaskEmpty());
      }
    },
    [history, controller],
  );

  // Return the refs, handlers, and state
  return {
    // Refs
    canvasContainerRef,
    canvasRef,
    maskCanvasRef,
    brushCursorRef,

    // Event handlers
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onPointerEnter,
    onPointerLeave,
    onWheel,

    // State and setters
    isCanvasMoving,
    isPointerOver,
    isBrushVisible,
    isPointerOverToolbarRef,
    isMaskEmpty,

    // Undo/Redo methods
    undo,
    redo,
    numRedos,
    numUndos,

    // Helper functions
    clearMask,
    saveCanvas,
    getCanvasMaskUrl,
    checkMaskEmpty,
    exportHistory,
    importHistory,
  };
}

export const PaintBrushCursor = React.forwardRef(
  (
    {
      id,
      className = "",
      style = {},
      ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    brushCursorRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return createPortal(
      <div
        ref={brushCursorRef}
        className={classNames(
          className,
          "absolute pointer-events-none border-2 rounded-full transition-opacity",
        )}
        style={{
          ...style,
          zIndex: TryOnClothEditorPaintBrushZIndex,
        }}
        {...props}
      />,
      document.body,
    );
  },
);

export type PaintMaskCanvasTaskBarButtonProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  contentChildren: React.ReactNode;
  contentClassName?: string;
  isActive?: boolean;
  isDisabled?: boolean;
};

export function PaintMaskCanvasTaskBarButton({
  children,
  className = "",
  contentChildren,
  contentClassName = "",
  isActive = false,
  isDisabled = false,
  ...props
}: PaintMaskCanvasTaskBarButtonProps) {
  return (
    <Tooltip
      triggerProps={{
        asChild: true,
      }}
      triggerChildren={
        <button
          {...props}
          className={classNames(
            "min-h-[1.6rem] px-2 py-1 rounded transition-colors pointer-events-auto cursor-pointer",
            isDisabled
              ? "bg-zinc-800 text-zinc-600"
              : isActive
                ? "bg-zinc-700 hover:bg-zinc-500/50 active:bg-zinc-900/80 shadow-md"
                : "hover:bg-zinc-700 active:bg-zinc-900/80",
            className,
          )}
        >
          {children}
        </button>
      }
      contentChildren={contentChildren}
      contentClassName={contentClassName}
    />
  );
}

export function PaintMaskCanvasToolBarUndoButton({
  size = 18,
  ...props
}: Partial<PaintMaskCanvasTaskBarButtonProps> & {
  size?: number;
}) {
  return (
    <PaintMaskCanvasTaskBarButton
      {...props}
      className="flex items-center justify-center"
      contentClassName="text-zinc-300"
      contentChildren={props.isDisabled ? "No undo left" : "Undo"}
    >
      <Undo size={size} />
    </PaintMaskCanvasTaskBarButton>
  );
}

export function PaintMaskCanvasToolBarRedoButton({
  size = 18,
  ...props
}: Partial<PaintMaskCanvasTaskBarButtonProps> & {
  size?: number;
}) {
  return (
    <PaintMaskCanvasTaskBarButton
      {...props}
      contentClassName="text-zinc-300"
      contentChildren={props.isDisabled ? "No redo left" : "Redo"}
    >
      <Redo size={size} />
    </PaintMaskCanvasTaskBarButton>
  );
}

export function PaintBrushSizeAdjust({
  className = "",
  brushSize: inputBrushSize,
  setBrushSize: setInputBrushSize,
  minBrushSize = 5,
  maxBrushSize = 100,
  deltaBrushSize = 5,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  brushSize: number;
  minBrushSize?: number;
  maxBrushSize?: number;
  deltaBrushSize?: number;
  setBrushSize: (value: StateUpdater<number>) => void;
}) {
  const [brushSize, setBrushSize] = React.useState(inputBrushSize);
  return (
    <div {...props} className={classNames("flex flex-row items-center justify-center", className)}>
      <PaintMaskCanvasTaskBarButton
        className="px-1"
        contentChildren="Decrease brush size"
        isDisabled={brushSize <= minBrushSize}
        onClick={() => {
          setInputBrushSize((prevBrushSize) => {
            const newBrushSize = clamp(prevBrushSize - deltaBrushSize, minBrushSize, maxBrushSize);
            setBrushSize(newBrushSize);
            return newBrushSize;
          });
        }}
      >
        <Minus size={16} />
      </PaintMaskCanvasTaskBarButton>
      <div className="w-1" />
      <input
        className={classNames(
          "w-12 px-1 py-1 text-center text-zinc-200 bg-zinc-500/10 rounded-md border border-solid border-zinc-500/20 hover:border-zinc-500/50 focus-visible:outline-none focus:border-lime-500 focus-visible:border-lime-500 transition-colors pointer-events-auto",
        )}
        type="number"
        value={brushSize}
        onChange={(e) => {
          setBrushSize(toSafeInteger(e.currentTarget.value));
        }}
        onBlur={() => {
          const value = clamp(brushSize, minBrushSize, maxBrushSize);
          setBrushSize(value);
          setInputBrushSize(value);
        }}
      />
      <div className="w-1" />
      <PaintMaskCanvasTaskBarButton
        className="px-1"
        contentChildren="Increase brush size"
        isDisabled={brushSize >= maxBrushSize}
        onClick={() => {
          setInputBrushSize((prevBrushSize) => {
            const newBrushSize = clamp(prevBrushSize + deltaBrushSize, minBrushSize, maxBrushSize);
            setBrushSize(newBrushSize);
            return newBrushSize;
          });
        }}
      >
        <Plus size={16} />
      </PaintMaskCanvasTaskBarButton>
    </div>
  );
}

export function PaintMaskCanvasToolbarDividerVertical({
  className = "",
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div {...props} className={classNames("w-px h-8 mx-1 bg-zinc-700", className)} />;
}
