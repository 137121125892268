// Enums

import type { CustomModelScaleConfigs, ImageSizeFalType } from "@/core/common/types";

export enum CustomModelPostProcessActionType {
  GetMaskFromPrompt = "GetMaskFromPrompt",
  FixProductDetails = "FixProductDetails",
  GenerateFromPose = "GenerateFromPose",
  RegenerateHuman = "RegenerateHuman",
  GetHumanMask = "GetHumanMask",
  UpscaleFace = "UpscaleFace",
  UpscaleCreative = "UpscaleCreative",
  ClarityUpscale = "UpscaleCreativeV2",
  InContextVariations = "ICV",
  MultiStageGeneration = "MultiStageGeneration",
}

export enum PoseImageType {
  OpenPose = "OpenPose",
  Image = "Image",
}

export enum OutputFormatFalType {
  JPEG = "jpeg",
  PNG = "png",
}

// Interfaces

export interface ImageSize {
  width: number;
  height: number;
}

export interface PoseCondition {
  poseImageType: PoseImageType;
  poseImageUrl: string;
  poseMaskImageUrl?: string | null;
}

export interface ModelTrainingPair {
  modelId: string;
  trainingId: string;
  scale?: number | null;
}

export interface FixDetailsFalArgsOverride {
  num_inference_steps?: number | null;
  guidance_scale?: number | null;
  num_images?: number | null;
  enable_safety_checker?: boolean | null;
  output_format?: string | null;
  strength?: number | null;
  seed?: number | null;
}

export interface GetMaskFromPromptInput {
  type: CustomModelPostProcessActionType.GetMaskFromPrompt;
  prompt: string;
  imageStoragePath: string;
}

export type GetMaskFromPromptResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      maskImageUrl: string;
    };

export interface GenerateFromPoseInput {
  type: CustomModelPostProcessActionType.GenerateFromPose;
  scaleConfigs: { [key: string]: ModelTrainingPair };
  prompt: string;
  promptJson?: string | null;
  promptEditorState?: string | null;
  seed?: number | null;
  sync_mode: boolean;
  image_size?: ImageSize | null;
  guidance_scale?: number | null;
  num_inference_steps?: number | null;
  poseConditions: PoseCondition[];
}

export interface GenerateFromPoseResponse {
  ok: boolean;
  message: string;
  outputImageUrl: string;
}

export interface FixProductDetailsInput {
  type: CustomModelPostProcessActionType.FixProductDetails;
  inputImageUrl: string;
  inputMaskImageUrl?: string | null;
  customModelScaleConfigs: { [key: string]: ModelTrainingPair };
  shortPrompt: string;
  fullPrompt: string;
  promptJson?: string | null;
  extraPredictionArgs: FixDetailsFalArgsOverride;
}

export type FixProductDetailsResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      prediction_id?: string | null;
    };

export interface SwapFaceInput {
  type: CustomModelPostProcessActionType.RegenerateHuman;
  inputImageUrl: string;
  faceImageUrl?: string;
  faceMaskImageUrl?: string;
  bodyMaskImageUrl?: string;
  scaleConfigs?: CustomModelScaleConfigs;
  prompt: string;
  promptJson?: string;
  promptEditorState?: string;
  seed?: number;
  imageSize?: ImageSizeFalType;
  guidanceScale?: number;
  numInferenceSteps?: number;
  inpaintStrength?: number;
  inpaintEndPercentage?: number;
  img2imgStrength?: number;
}

export type SwapFaceResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

export interface GetHumanMaskInput {
  type: CustomModelPostProcessActionType.GetHumanMask;
  imageStoragePath: string;
}

export type GetHumanMaskResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

export interface ClarityUpscaleInput {
  type: CustomModelPostProcessActionType.ClarityUpscale;
  inputImageUrl: string;
  preserveMaskImageUrl?: string;
  upscale_factor?: number;
  prompt?: string;
  promptJson?: string;
  promptEditorState?: string;
  customModelScaleConfigs?: CustomModelScaleConfigs;
  negative_prompt?: string;
  creativity?: number;
  resemblance?: number;
  guidance_scale?: number;
  num_inference_steps?: number;
  enable_safety_checker?: boolean;
}

export type ClarityUpscaleResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

// InContextVariations Interfaces

export interface InContextVariationsInput {
  type: CustomModelPostProcessActionType.InContextVariations;
  prompt?: string;
  promptJson?: string | null;
  promptEditorState?: string | null;
  modelScaleConfigs: CustomModelScaleConfigs;
  triggerWords?: string[] | null;
  sourceImageUrl: string;
  sourceImagePrompt: string;
  firstPassInpaintConditioningScale?: number | null;
  firstPassInpaintEndPercentage?: number | null;
  firstPassSkipLoras?: boolean | null;
  firstPassLoraScale?: number;
  cropTolerance?: number | null;
  cropOutlierPercent?: number | null;
  cropMaxBorderLengthRatio?: number | null;
  secondPassInputImageSize?: number | null;
  secondPassConditioningScale?: number | null;
  secondPassEndPercentage?: number | null;
  secondPassSkipLoras?: boolean | null;
  secondPassLoraScale?: number;
}

export type InContextVariationsResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

// Upscale face interfaces

export interface FaceUpscalerInput {
  type: CustomModelPostProcessActionType.UpscaleFace;
  prompt?: string;
  promptJson?: string | null;
  promptEditorState?: string | null;
  usedModels?: Record<string, boolean>;
  inputImageUrl: string;
  upscale?: number;
  faceUpsample?: boolean;
  backgroundEnhance?: boolean;
  codeformerFidelity?: number;
}

export type FaceUpscalerResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      predictionId?: string;
    };

// Multi-step generation interfaces

// The image size interface
export interface MultiStageGenerationInputImageSize {
  width: number;
  height: number;
}

// The main input interface
export interface MultiStageGenerationInput {
  type: CustomModelPostProcessActionType.MultiStageGeneration;
  prompt?: string | null;
  promptJson?: string | null;
  customModelScaleConfigs?: CustomModelScaleConfigs | null;
  imageSize?: MultiStageGenerationInputImageSize | null;
  maskDilationSize?: number | null;
  secondPassStrength?: number | null;
  maskImageUrl?: string | null;
  firstPassOutputImageUrl?: string | null;
  secondPassControlNetConditioningScale?: number | null;
  secondPassControlNetEndPercentage?: number | null;
  numImages?: number | null;
}

// The response interface
export interface MultiStageGenerationResponse {
  ok: boolean;
  message?: string;
  predictionId?: string;
}

// Type Unions

export type CustomModelPostProcessInput =
  | GetMaskFromPromptInput
  | FixProductDetailsInput
  | GenerateFromPoseInput
  | SwapFaceInput
  | ClarityUpscaleInput
  | FaceUpscalerInput
  | InContextVariationsInput
  | MultiStageGenerationInput;

export type CustomModelPostProcessResponse =
  | GetMaskFromPromptResponse
  | FixProductDetailsResponse
  | GenerateFromPoseResponse
  | SwapFaceResponse
  | ClarityUpscaleResponse
  | FaceUpscalerResponse
  | InContextVariationsResponse
  | MultiStageGenerationResponse;
