import React from "react";
import { fabric } from "fabric";
import { MessageDialog } from "./message-dialog";
import {
  MessageDialogContentInner,
  MessageDialogContentInnerProps,
} from "./message-dialog-content";
import { isStaticImageObject } from "@/core/utils/type-guards";
import { getDataUrlFromImageElement, getRawDataUrlFromImageObject } from "@/core/utils/image-utils";
import { DropdownClassName } from "components/constants/class-names";
import { classNames } from "@/core/utils/classname-utils";
import { UiCloseMessageDialogProcessUploadedImageResponse } from "@/core/common/types";

export function ProcessUploadedImageDialog({
  image,
  onClose,
}: {
  image: HTMLImageElement | fabric.StaticImage;
  onClose?: (response: UiCloseMessageDialogProcessUploadedImageResponse) => void;
}) {
  const [open, setOpen] = React.useState(true);
  const [step, setStep] = React.useState(0);
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();
  const [imageNoBgUrl, setImageNoBgUrl] = React.useState<string | undefined>();
  const [caption, setCaption] = React.useState<string>("");
  const [removeBg, setRemoveBg] = React.useState(true);

  const handleOpenChange = React.useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);

      if (!isOpen) {
        console.log("Close process uploaded image dialog");
        onClose?.({
          type: "process-uploaded-image",
          caption,
          imageUrl: removeBg ? imageNoBgUrl : imageUrl,
        });
      }
    },
    [onClose, caption, removeBg, imageUrl, imageNoBgUrl],
  );

  React.useEffect(() => {
    if (isStaticImageObject(image)) {
      getRawDataUrlFromImageObject({
        object: image,
      }).then(setImageUrl);
    } else {
      getDataUrlFromImageElement({
        from: image,
      }).then(setImageUrl);
    }
  }, [image]);

  const messageDialogProps: MessageDialogContentInnerProps[] = React.useMemo(
    () => [
      {
        type: "remove-background-v2",
        imageUrl,
        setOpen,
        imageNoBgUrl,
        setImageNoBgUrl,
        onClickNext: () => {
          setStep((i) => i + 1);
        },
        onClickPrev: () => {
          setRemoveBg(false);
          setStep((i) => i + 1);
        },
      },
      {
        type: "caption-subject-v2",
        imageUrl: removeBg ? imageNoBgUrl : imageUrl,
        setOpen,
        setCaption,
        onClickNext: () => {
          handleOpenChange(false);
        },
        onClickPrev: () => {
          setStep((i) => i - 1);
        },
      },
    ],
    [imageUrl, removeBg, imageNoBgUrl, handleOpenChange],
  );

  return (
    <MessageDialog
      open={open}
      onOpenChange={handleOpenChange}
      contentChildren={
        <div
          className={classNames(
            DropdownClassName,
            "min-w-[80vw] md:min-w-[680px] max-h-[80vh] px-6 py-5 flex flex-col items-center",
          )}
        >
          <MessageDialogContentInner
            {...messageDialogProps[step % messageDialogProps.length]}
            step={step}
            isEnd={step >= messageDialogProps.length - 1}
          />
        </div>
      }
    />
  );
}
